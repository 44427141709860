import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface TravelCity {
    Id: number;
    Name: string;
    TerritoryId: number;
    MarketId: number;
    Deleted: string;
}

export interface TravelCityForListComponent extends TravelCity {
    TerritoryNameForTerritoryId: string;
    MarketNameForMarketId: string;
}

@Injectable({
    providedIn: 'root',
})
export class TravelCityService {

    constructor(private baseHttp: BaseHttpService) { }

    getTravelCities(forceRefresh: boolean = false): Observable<TravelCity[]> {
        return this.baseHttp.getCacheOrData(`/travelcity`, 5 * 60 * 1000, forceRefresh);
    }
    getTravelCityById(travelCityId: number): Observable<TravelCity> {
        return this.baseHttp.getData(`/travelcity/id/${travelCityId}`);
    }

    createOrUpdateTravelCity(travelCity: TravelCity): Observable<TravelCity> {
        return this.baseHttp.postData(`/travelcity`, travelCity);
    }
}
