import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {TabsModule} from "ngx-bootstrap/tabs";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {ActivityListComponent} from "./ActivityList/activity-list.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {EesActivityFormBaseComponent} from "./EESActivityForm/ees-activity-form-base.component";
import {EesActivityFormComponent} from "./EESActivityForm/ees-activity-form.component";
import {EesActivityListComponent} from "./EESActivityList/ees-activity-list.component";
import {RouterModule} from "@angular/router";
import {activityRoutes} from "./activity.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {ActivityFormFieldsetComponent} from "./ActivityForm/activity-form-fieldset.component";
import {ActivityFormComponent} from "./ActivityForm/activity-form.component";
import {ActivityEmailFormComponent} from "./ActivityForm/activity-email-form.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        ButtonsModule.forRoot(),
        BsDropdownModule.forRoot(),
        RouterModule.forChild(activityRoutes),
        TabsModule,
    ],
    declarations: [
        ActivityListComponent,
        ActivityEmailFormComponent,
        EesActivityFormBaseComponent,
        EesActivityFormComponent,
        EesActivityListComponent,
        ActivityFormFieldsetComponent,
        ActivityFormComponent,
    ],
    exports: [
        EesActivityFormBaseComponent,
        RouterModule,
        ActivityFormFieldsetComponent,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class ActivityModule {
    constructor(){
        console.log("registering the ActivityModule");
    }
}
