import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";
import * as  _ from "lodash";
import { map } from "rxjs/operators";
import { ContactService } from "../../../Shared/Services/contact.service";
import {PresentationNamePipe} from "../../../Shared/Pipes/PresentationName/presentation-name.pipe";

@Component({
    selector: "app-contact-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ContactPickerComponent)
})
export class ContactPickerComponent extends BasePickerComponent<any> implements OnInit, OnChanges {

    @Input()
    alignRight: boolean = false;

    @Input()
    accountId: number;
    
    
    constructor(fb: UntypedFormBuilder,
        private contactService: ContactService,
        private presentationNamePipe: PresentationNamePipe
    ) {
        super(fb);
        
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.placeHolder = "Select Contact";
        this.customSearchable = true;
        this.showSelectClearAll = false;
        this.showTagCountLabel = false;
        this.closeOnSelect = true;
        this.multiple = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadContacts();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.loadContacts();
    }
    
    private loadContacts(): void {
        if (!this.accountId) return;
        
        this.items$ = this.contactService.getAccountSimpleContacts(this.accountId).pipe(
            map(contacts => contacts.sort((a,b) => {
                if (a.LastName === b.LastName) {
                    return a.FirstName.localeCompare(b.FirstName);
                }
                return a.LastName.localeCompare(b.LastName);
            })),
            map(contacts => contacts.map(c => { return { Id: c.Id, Name: this.presentationNamePipe.transform(c) }
            }))
        );
    }
}
