import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors, Validators
} from "@angular/forms";
import * as _ from "lodash";

@Component({
    selector: "app-string-array-input",
    template: `<input type="search" class="form-control input-sm" [formControl]="stringControl" placeholder="{{ placeholder }}"/>`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StringArrayInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => StringArrayInputComponent),
            multi: true
        }
    ]
})
export class StringArrayInputComponent implements  OnInit, ControlValueAccessor {
    
    @Input()
    placeholder: string;
    
    @Input()
    emailValues: boolean = false;
    
    onChange = (updatedData: string[]) => {};
    onTouched = () => {};
    
    stringControl = this.fb.control('', [_.bind(this.isStringsValid, this)]);
    
    constructor(private fb: UntypedFormBuilder){}
    
    ngOnInit(): void {
        this.stringControl.valueChanges.subscribe((data: string) => {
            let values = this.getControlStrings(data)
            this.onChange(values);
            
            //this.onChange(data.split(/[\s,]+/).map(v => v.trim()));
        });
    }

    registerOnChange(fn: (updatedData: string[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: string[]): void {
        
        this.stringControl.patchValue(obj.join(", "));
        
        this.onChange(obj);
    }

    getControlStrings(data: string): string[] {
        return _.chain(data.split(/[\s,;]+/))
            .map(d => d.trim())
            .filter(d => !_.isEmpty(d))
            .value();
    }
    
    isStringsValid(control: AbstractControl): ValidationErrors | null {
        if (this.emailValues) {
            let validationErrors = this.getControlStrings(control.value)
                .map(email => Validators.email(this.fb.control(email)))
                .filter(errors => errors);

            if (validationErrors.length > 0) {
                return { invalidEmail: true };
            }
        }
        
        return null;
    }
    
    validate({ value }: UntypedFormControl) {
        if (this.stringControl.invalid) {
            return {stringArrayInputInvalid: true};
        }
        return null;
    }
}
