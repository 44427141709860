import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {User} from "../../../Models/User";
import {ReportService} from "../../Shared/Services/report.service";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {
    MonthlyCommission,
    MonthlyExpense,
} from "../../../Models/AccountMonthlyCommission";
import * as moment from "moment";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {CommissionService} from "../../Shared/Services/commission.service";
import * as _ from 'lodash';
import {AccountService} from "../../Shared/Services/account.service";
import {CommissionCategory} from "../../../Services/CommissionCategoryService";
import {UserService} from "../../Shared/Services/user.service";
import {Account} from "../../../Models/Account";
import {RevenueTargetService} from "../../Shared/Services/revenue-target.service";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {ReportRoutePaths} from "../../Reports/report-route-paths";

@Component({
    selector: "app-account-commissions",
    templateUrl: "./account-commissions.component.html"
})
export class AccountCommissionsComponent implements OnInit {

    @Input()
    accountId: number;
    account: Account;

    @Input()
    commissionDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    latestReleaseDate$: Observable<string>;
    data$: Observable<{
        commissions: MonthlyCommission[],
        pnl: MonthlyCommission[],
        expenses: MonthlyExpense[],
        categories: CommissionCategory[],
        relativePay: number[],
        revenueTargets: number[],
    }>;
    years: number[];
    yearsDisplayed: number[];
    categoryGroups: Record<string, string>;
    accountGroups: Record<string, string>;
    quarterGroups: Record<number, string> = { 1: "Q1", 2: "Q2", 3: "Q3", 4: "Q4" };
    userHasAccountCoverage: boolean = true;
    hasAccessToAccountCommissions: boolean = true;
    hasRevenueTargets: boolean = false;
    parentComdolId: string;
    isLoading: boolean = false;
    isLoadingFirstTime: boolean = true;

    commissionForm: UntypedFormGroup = this.fb.group({
        includeParent: this.fb.control(false),
        relativePayDateType: this.fb.control(1),
        chart: this.fb.control('Category'),
        categories: this.fb.control([]),
        showCategories: this.fb.control(false),
        yearsRange: this.fb.control(5)
    });

    parentYearOverYearCommissionReportBasePath = `${ReportRoutePaths.ParentYearOverYearCommissionReport}`;
    parentTradeDetailReportBasePath = `${ReportRoutePaths.ParentTradeDetailReport}`;

    constructor(private fb: UntypedFormBuilder,
                private reportService: ReportService,
                private commissionService: CommissionService,
                private accountService: AccountService,
                private userService: UserService,
                private revenueTargetService: RevenueTargetService,
                ) {
    }

    ngOnInit(): void {
        this.latestReleaseDate$ = this.reportService.getLatestReleaseDate();
        this.years = [...Array(10).keys()].map(i => moment().year() - i).reverse();
        this.yearsDisplayed = this.years

        const commissionCategories = this.commissionService.getCommissionCategories()
        this.commissionForm.controls['categories'].patchValue(commissionCategories.map(cc => cc.code));

        let commissionDataChanged$ = this.commissionDataChanged.pipe(startWith(true));
        combineLatest([
            this.userService.getCurrentUser(),
            this.accountService.getAccountById(this.accountId),
            commissionDataChanged$
        ]).subscribe(([user, account, _]) => {
            this.isLoading = true;
            this.parentComdolId = account.ParentComdolId;
            this.userHasAccountCoverage = user && User.isMyAccount(user, account.Tags);
            this.hasRevenueTargets = user && this.userService.hasUserFeature(user, UserFeatureName.RevenueTarget)
            if (!this.hasRevenueTargets) {
                this.commissionForm.controls['relativePayDateType'].setValue(1);
            }
            if (this.userHasAccountCoverage) {
                this.getData();
            }
        })
        tap(() => this.isLoading = false);
    }

    getData(): void {
        this.isLoading = true;
        const formCategories$ = this.commissionForm.controls["categories"].valueChanges.pipe(
            startWith(this.commissionForm.controls["categories"].value)
        );

        const showCategories$ = this.commissionForm.controls["showCategories"].valueChanges.pipe(
            startWith(this.commissionForm.get(["showCategories"]).value)
        );

        const yearsRange$ = this.commissionForm.controls["yearsRange"].valueChanges.pipe(
            startWith(this.commissionForm.controls["yearsRange"].value)
        );
        const dateType$ = this.commissionForm.controls["relativePayDateType"].valueChanges.pipe(
            startWith(this.commissionForm.get('relativePayDateType').value)
        );
        const clientSearch$ = combineLatest([formCategories$, showCategories$, yearsRange$, dateType$]);

        const includeParent$ = this.commissionForm.controls["includeParent"].valueChanges.pipe(
            startWith(this.commissionForm.get('includeParent').value)
        );

        let revTargets$ = this.hasRevenueTargets ? this.revenueTargetService.getByAccount(this.accountId) : of([]);

        const rawData$ = includeParent$.pipe(
            tap(() => this.isLoading = true),
            switchMap((includeParent) => forkJoin([
                this.commissionService.getAccountMonthlyCommissions(this.years, this.accountId, includeParent),
                this.commissionService.getAccountPnL(this.years, this.accountId, includeParent),
                this.accountService.getAccountExpenses(this.years, this.accountId, includeParent),
                this.accountService.getAccountRelativePay(this.years, this.accountId, true),
                revTargets$
            ]))
        );

        this.data$ = combineLatest([clientSearch$, rawData$]).pipe(
            map(([clientSearch, rawData]) => {
                const [accountCommissions, accountPnL, accountExpenses, accountRelativePay, revTargets] = rawData;
                const [formCategories, showCategories, yearsRange, dateType] = clientSearch;
                let categories = this.commissionService.getCommissionCategories();
                this.yearsDisplayed = this.years.slice(-yearsRange);
                if (showCategories && formCategories.length > 0) {
                    categories = categories.filter(c => formCategories.includes(c.code))
                }
                const commissions = _.flatMap(accountCommissions, ac => {
                    return ac.MonthlyCommissions.map(c => {
                        c.Location = `${ac.City} (${ac.ComdolId})`
                        return c;
                    })
                        .filter(c => categories.map(cat => cat.code).includes(c.Category))
                        .filter(dt => dt.DateType === dateType);
                });
                const pnl = _.flatMap(accountPnL, ac => {
                    return ac.MonthlyCommissions.map(c => {
                        c.Location = `${ac.City} (${ac.ComdolId})`
                        return c;
                    });
                });
                const expenses = _.flatMap(accountExpenses, ac => {
                    return ac.MonthlyExpenses.map(c => {
                        c.Location = `${ac.City} (${ac.ComdolId})`
                        return c;
                    });
                });

                if (!accountRelativePay) {
                    this.hasAccessToAccountCommissions = false;
                }

                const relativePay: number[] = this.years.map(year => {
                    const record = accountRelativePay?.find(rp => rp.Year === year && rp.DateType === dateType);
                    return record ? record.Value : 0;
                }).filter(value => value !== null);

                const revenueTargets: number[] = this.hasRevenueTargets ? this.years
                    .map(year => revTargets
                        .filter(rt => rt.Year === year &&
                            (this.commissionForm.get('includeParent').value || rt.IsMine))
                        .reduce((a,c) => a + c.Target, 0)) : [];

                return {
                    commissions,
                    pnl,
                    expenses,
                    categories,
                    relativePay,
                    revenueTargets,
                };
            }),
            tap(({ commissions, categories }) => {
                this.accountGroups = commissions.reduce((groups, c) => {
                    groups[c.Location] = c.Location;
                    return groups;
                }, {});
                this.categoryGroups = categories.reduce((groups, { code, description }) => {
                    groups[code] = description;
                    return groups;
                }, {});
                this.isLoading = false;
                this.isLoadingFirstTime = false;
            })
        );
    }

    protected readonly parent = parent;
}
