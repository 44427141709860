import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {Observable, of } from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {InvestorStrategies} from "../../Account/AccountProfile/investor-strategies";
import {InvestorTypes} from "../../Account/AccountProfile/investor-types";
import {NumberRange} from "../NumberRange/number-range";
import {DealPlayer} from "../../Shared/Models/deal-player";
import {Orientation} from "../../Shared/Models/orientation";
import {InvestorStyles} from "../../Shared/Models/investor-styles";
import {QueryGroup, QueryOperation} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import * as _ from "lodash";

@Component({
    selector: 'app-more-filters',
    templateUrl: "./more-filters-modal.component.html"
})
export class MoreFiltersModalComponent implements OnInit {

    @Input()
    queryGroups: QueryGroup[] = [];

    @Input()
    showActivityFilters: boolean = true;

    @Input()
    title: string = "More Filter"

    @Output()
    queryGroupsUpdated = new EventEmitter<QueryGroup[]>();

    includeActivityHeader: string;

    filtersForm: UntypedFormGroup = this.fb.group({
        Regions: this.fb.control([]),
        InvestorTypes: this.fb.control([]),
        Markets: this.fb.control([]),
        InvestorStrategies: this.fb.control([]),
        PrimaryBrokers: this.fb.control([]),
        AumRange: this.fb.control({}),
        EquityAumRange: this.fb.control({}),
        MarketCapRange: this.fb.control({}),
        MarketingEmails: this.fb.control([]),
        ResearchEmails: this.fb.control([]),
        ResearchVms: this.fb.control(null),
        PersonalBrokers: this.fb.control([]),
        Roles: this.fb.control([]),
        BairdContacts: this.fb.control([]),
        InvestorStyles: this.fb.control([]),
        GlobalInvestors: this.fb.control(null),
        Orientations: this.fb.control([]),
        PrivateInvestments: this.fb.control(null),
        DealPlayers: this.fb.control([]),
        DoNotContacts: this.fb.control(null),
        ActivityStartDays: this.fb.control(''),
        ActivityCategories: this.fb.control([]),
        BlueMatrix: this.fb.control(''),
        SavedContactListId: this.fb.control(null)
    });

    dealPlayerValues$: Observable<string[]> = of(DealPlayer);
    dealPlayerHeader: string = "Deal Player(s)";
    orientationValues$: Observable<string[]> = of(Orientation);
    orientationHeader: string = "Orientation(s)";
    investorTypeValues$: Observable<string[]> = of(InvestorTypes);
    investorTypesHeader: string = "Investor Type(s)";
    investorStrategyValues$: Observable<string[]> = of(InvestorStrategies);
    investorStrategyHeader: string = "Investor Strategy(s)";
    investorStyleValues$: Observable<string[]> = of(InvestorStyles);
    investorStyleHeader: string = "Investor Style(s)";

    constructor(private bsModalRef: BsModalRef,
                private fb: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.showActivityFilters ? this.includeActivityHeader = "Activities / Interests": this.includeActivityHeader = "Interests"

        if (this.queryGroups) {
            this.setFormField('Regions', this.queryGroups, 'Account', 'Region', "contains", x => +x);
            this.setFormField('InvestorTypes', this.queryGroups, 'Account', 'Investor Type');
            this.setFormField('Markets', this.queryGroups, 'Account', 'Market', "contains", x => +x);
            this.setFormField('InvestorStrategies', this.queryGroups, 'Account', 'Investor Strategy');
            this.setFormField('PrimaryBrokers', this.queryGroups, 'Account', 'Primary Broker');
            this.setFormField('BairdContacts', this.queryGroups, 'Account', 'Contact Groups', 'not contains');
            this.setRangeFormField('AumRange', this.queryGroups, 'Account', 'AUM');
            this.setRangeFormField('EquityAumRange', this.queryGroups, 'Account', 'AUM Equity');
            this.setYesNoFormField('MarketingEmails', this.queryGroups, 'Holding', 'Marketing Email');
            this.setYesNoFormField('ResearchEmails', this.queryGroups, 'Holding', 'Research Email');
            this.setYesNoFormField('ResearchVms', this.queryGroups, 'Contact', 'Research Voicemail');
            this.setFormField('PersonalBrokers', this.queryGroups, 'Contact', 'Personal Broker');
            this.setFormField('Roles', this.queryGroups, 'Contact', 'Roles');
            this.setFormField('InvestorStyles', this.queryGroups, 'Contact', 'Investor Style');
            this.setYesNoFormField('GlobalInvestors', this.queryGroups, 'Contact', 'Global Investor');
            this.setYesNoFormField('Orientations', this.queryGroups, 'Contact', 'Orientation');
            this.setYesNoFormField('PrivateInvestments', this.queryGroups, 'Contact', 'Private Investment');
            this.setFormField('DealPlayers', this.queryGroups, 'Contact', 'Deal Player');
            this.setYesNoFormField('DoNotContacts', this.queryGroups, 'Contact', 'Do Not Contact', 'is');
            this.setRangeFormField('MarketCapRange', this.queryGroups, 'Contact', 'Market Cap High');
            this.setRangeFormField('MarketCapRange', this.queryGroups, 'Contact', 'Market Cap Low');
            this.setFormField('BlueMatrix', this.queryGroups, 'Holding', 'Blue Matrix', 'contains');
            this.setFormField('ActivityStartDays', this.queryGroups, 'Activity', 'Call Within Days', 'is');
            this.setFormField('ActivityCategories', this.queryGroups, 'Activity', 'Category');
            this.setFormField('SavedContactListId', this.queryGroups, 'Contact', 'List', 'is');
        }
    }

    private setFormField<T>(controlName: string, queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation = 'contains', valueMapper: (x) => T = x => x): void {
        let group = _.find(queryGroups, g =>
            g.Queries[0].Object === object &&
            g.Queries[0].Field === field &&
            g.Queries[0].Operation === operation);
        if (group) {
            if (['contains', 'not contains'].includes(operation)) {
                this.filtersForm.patchValue({
                    [controlName]: (group.Queries[0].Values as any[]).map(valueMapper)
                });
            } else {
                this.filtersForm.patchValue({
                    [controlName]: valueMapper(group.Queries[0].Value)
                });
            }
        }
    }

    private setYesNoFormField(controlName: string, queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation = 'is'): void {
        let group = _.find(queryGroups, g =>
            g.Queries[0].Object === object &&
            g.Queries[0].Field === field &&
            g.Queries[0].Operation === operation);
        if (group) {
            this.filtersForm.patchValue({
                [controlName]: group.Queries[0].Value === 'true' ? 'Y' : 'N'
            });
        }
    }

    private setRangeFormField(controlName: string, queryGroups: QueryGroup[], object: string, field: string): void {
        queryGroups
            .filter(g => g.Queries[0].Object === object && g.Queries[0].Field === field)
            .forEach(g => {
                if (g.Queries[0].Operation === 'greater than or equal') {
                    let range = this.filtersForm.get(controlName).value;
                    range.low = g.Queries[0].Value;
                    this.filtersForm.patchValue({
                        [controlName]: range
                    });
                }
                if (g.Queries[0].Operation === 'less than or equal') {
                    let range = this.filtersForm.get(controlName).value;
                    range.high = g.Queries[0].Value;
                    this.filtersForm.patchValue({
                        [controlName]: range
                    });
                }
            });
    }

    submit(): void {

        if (this.queryGroups) {
            const value = this.filtersForm.value;
            const queryGroups = MoreFiltersModalComponent.mapMoreFiltersToQueryGroups(value)
            this.queryGroupsUpdated.emit(queryGroups);
        }

        this.bsModalRef.hide();
    }

    close(): void {
        this.bsModalRef.hide();
    }

    static mapMoreFiltersToQueryGroups(filters: InterestListModalFilters): QueryGroup[] {
        const queryGroups: QueryGroup[] = [];

        const addQueryGroupValue = (queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation, value: string | number) => value && QueryTranslator.AddQueryGroupValue(queryGroups, object, field, operation, value);
        const addQueryGroupValues = (queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation, values: string[] | number[]) => values?.length > 0 && QueryTranslator.AddQueryGroupValues(queryGroups, object, field, operation, values);

        addQueryGroupValues(queryGroups, 'Account', 'Region', 'contains', filters.Regions);
        addQueryGroupValues(queryGroups, 'Account', 'Investor Type', 'contains', filters.InvestorTypes);
        addQueryGroupValues(queryGroups, 'Account', 'Market', 'contains', filters.Markets);
        addQueryGroupValues(queryGroups, 'Account', 'Investor Strategy', 'contains', filters.InvestorStrategies);
        addQueryGroupValues(queryGroups, 'Account', 'Primary Broker', 'contains', filters.PrimaryBrokers);
        addQueryGroupValues(queryGroups, 'Account', 'Contact Groups', 'not contains', filters.BairdContacts);
        addQueryGroupValue(queryGroups, "Account", "AUM", "greater than or equal", filters.AumRange.low);
        addQueryGroupValue(queryGroups, "Account", "AUM", "less than or equal", filters.AumRange.high);
        addQueryGroupValue(queryGroups, "Account", "AUM Equity", "greater than or equal", filters.EquityAumRange.low);
        addQueryGroupValue(queryGroups, "Account", "AUM Equity", "less than or equal", filters.EquityAumRange.high);

        if (filters.ResearchVms?.length === 1) {
            addQueryGroupValue(queryGroups, 'Contact', 'Research Voicemail', 'is', filters.ResearchVms[0] === 'Y' ? 'true' : 'false');
        }
        addQueryGroupValues(queryGroups, 'Contact', 'Roles', 'contains', filters.Roles);
        addQueryGroupValues(queryGroups, 'Contact', 'Personal Broker', 'contains', filters.PersonalBrokers);
        addQueryGroupValues(queryGroups, 'Contact', 'Investor Style', 'contains', filters.InvestorStyles);
        if (filters.GlobalInvestors?.length === 1) {
            addQueryGroupValue(queryGroups, 'Contact', 'Global Investor', 'contains', filters.GlobalInvestors[0] === 'Y' ? 'true' : 'false');
        }
        addQueryGroupValues(queryGroups, 'Contact', 'Orientation', 'contains', filters.Orientations);
        if (filters.PrivateInvestments?.length === 1) {
            addQueryGroupValue(queryGroups, 'Contact', 'Private Investment', 'contains', filters.PrivateInvestments[0] === 'Y' ? 'true' : 'false');
        }
        addQueryGroupValues(queryGroups, 'Contact', 'Deal Player', 'contains', filters.DealPlayers);
        if (filters.DoNotContacts?.length === 1) {
            addQueryGroupValue(queryGroups, 'Contact', 'Do Not Contact', 'is', filters.DoNotContacts[0] === 'Y' ? 'true' : 'false');
        }
        addQueryGroupValue(queryGroups, "Contact", "Market Cap High", "greater than or equal", filters.MarketCapRange.low);
        addQueryGroupValue(queryGroups, "Contact", "Market Cap Low", "less than or equal", filters.MarketCapRange.high);
        if (filters.BlueMatrix?.length === 1) {
            addQueryGroupValues(queryGroups, 'Holding', 'Blue Matrix', 'contains', filters.BlueMatrix);
        }
        if (filters.ResearchEmails?.length === 1) {
            addQueryGroupValue(queryGroups, 'Holding', 'Research Email', 'is', filters.ResearchEmails[0] === 'Y' ? 'true' : 'false');
        }
        if (filters.MarketingEmails?.length === 1) {
            addQueryGroupValue(queryGroups, 'Holding', 'Marketing Email', 'is', filters.MarketingEmails[0] === 'Y' ? 'true' : 'false');
        }
        addQueryGroupValue(queryGroups, 'Activity', 'Call Within Days', 'is', filters.ActivityStartDays);
        addQueryGroupValues(queryGroups, 'Activity', 'Category', 'contains', filters.ActivityCategories);
        if (filters.SavedContactListId) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "List", "is", filters.SavedContactListId);
        }

        return queryGroups;
    }
}

export interface InterestListModalFilters {
    Regions: number[];
    InvestorTypes: string[];
    Markets: number[];
    InvestorStrategies: string[];
    PrimaryBrokers: string[];
    MarketingEmails: string[];
    ResearchEmails: string[];
    ResearchVms: string[];
    PersonalBrokers: string[];
    Roles: string[];
    BairdContacts: string[];
    InvestorStyles: string[];
    GlobalInvestors: string[];
    Orientations: string[];
    PrivateInvestments: string[];
    DealPlayers: string[];
    // ActivityStartDays: Date;
    ActivityStartDays: number;
    ActivityCategories: string[];
    BlueMatrix: string[];
    AumRange: NumberRange,
    EquityAumRange: NumberRange,
    MarketCapRange: NumberRange,
    DoNotContacts: string[];
    SavedContactListId: number;
}
