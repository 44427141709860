<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">Edit Account Profile</h3>
    </div>
    <form [formGroup]="profileForm">
        <div class="modal-body">
            <div class="row">
                <div class="form-group col-xs-6">
                    <label>Investor Type(s)</label>
                    <ng-select [items]="investorTypes$ | async"
                               bindLabel="Label"
                               bindValue="Label"
                               placeholder="Select Types"
                               appendTo="body"
                               [multiple]="true"
                               [selectOnTab]="true"
                               [searchable]="true"
                               [closeOnSelect]="false"
                               formControlName="investorTypes">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-search="searchTerm">
                            <input type="checkbox" [checked]="item$.selected" /> <span [ngOptionHighlight]="search">{{item.Label}}</span>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="col-xs-6 form-group">
                    <label>AUM Total (Bn)</label>
                    <input class="form-control input-sm" type="number" formControlName="aumTotal" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6 form-group">
                    <label>Investor Strategy</label>
                    <select class="form-control input-sm" formControlName="investorStrategy">
                        <option *ngFor="let strat of investorStrategy$ | async" [ngValue]="strat">{{strat}}</option>
                    </select>
                </div>
                <div class="col-xs-6 form-group">
                    <label>Equity AUM Total (Bn)</label>
                    <input class="form-control input-sm" type="number" formControlName="aumEquityTotal" />
                </div>
            </div>
            <div class="row">
                <div class="col-xs-6">
                </div>
                <div class="col-xs-6 form-group">
                    <label>AUM Date</label>
                    <input type="date" class="form-control input-sm" formControlName="aumDate" value="{{ profile.AumDate | date:'yyyy-MM-dd' }}" />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xs-12">
                    <label>Description</label>
                    <textarea class="form-control input-sm" formControlName="description"></textarea>
                </div>
            </div>
            <div class="row" *ngIf="canEditRateCard">
                <div class="form-group col-xs-12">
                    <label>Rate Card</label>
                    <textarea class="form-control input-sm" formControlName="rateCard"></textarea>
                </div>
            </div>
            <div class="row" *ngIf="canEditNotes">
                <div class="form-group col-xs-12">
                    <label>General Notes</label>
                    <textarea class="form-control input-sm" formControlName="notes" [readOnly]="notesReadOnlyForUser"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox">
                <button type="button" class="btn btn-primary flex" style="margin: 15px;" (click)="save()">Save</button>
                <button type="button" class="btn btn-default flex" style="margin: 15px;" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

