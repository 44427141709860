<div class="flex flex-vbox">

    <div style="margin-bottom: 5px;">
        <form [formGroup]="form">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-sm btn-default" [disabled]="!contactId && !accountId" (click)="addActivity()"><span class="fa fa-plus"></span></button>
                <input formControlName="searchTerm" type="search" placeholder="Search" class="flex form-control input-sm" />
                <app-date-range-picker formControlName="dateRange"
                                       [options]="dateRangeOptions"></app-date-range-picker>
                <app-ees-activity-status-picker formControlName="statuses"
                                                style="width: 120px;"
                                                placeHolder="Status"
                                                [alignRight]="true"
                    >
                </app-ees-activity-status-picker>
                <app-ees-activity-category-picker formControlName="types"
                                                  style="width: 120px;" 
                                                  placeHolder="Type"
                                                  [alignRight]="true"
                                                  [appEventTracker]='{"category":"Activity Category","action":"Click"}'>
                </app-ees-activity-category-picker>
            </div>
            <div *ngIf="notShowingAllRecords" class="small">
                <em style="font-weight: bold; color: red">(Not showing all records. Add filters.)</em>
            </div>
        </form>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowClassRules]="rowClassRules"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
    >
    </ag-grid-angular>

</div>