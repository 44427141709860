export class EventRoutePaths {
  static readonly BasePath: string = "events";

  static readonly EventRegistrationListPathFragment: string = "registrations";
  static readonly EventCalendarPathFragment: string = "calendar";
  static readonly OldRequestDetailPathFragment: string = "oldrequestdetail";
  static readonly EventDetailPathFragment: string = "detail";
  static readonly RequestDetailPathFragment: string = "requestdetail";

  static readonly Events: string = EventRoutePaths.BasePath;
  static readonly EventRegistrationList: string = `${EventRoutePaths.BasePath}/${EventRoutePaths.EventRegistrationListPathFragment}`;
  static readonly EventCalendar: string = `${EventRoutePaths.BasePath}/${EventRoutePaths.EventCalendarPathFragment}`;
  static readonly OldRequestDetail: string = `${EventRoutePaths.BasePath}/${EventRoutePaths.OldRequestDetailPathFragment}`;
  static readonly EventDetail: string = `${EventRoutePaths.BasePath}/${EventRoutePaths.EventDetailPathFragment}`;
  static readonly RequestDetail: string = `${EventRoutePaths.BasePath}/${EventRoutePaths.RequestDetailPathFragment}`;
}
