import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventService} from "../../../Shared/Services/event.service";
import {ConfirmModalComponent} from "../../../Widget/ConfirmModal/confirm-modal.component";
import {CalendarEvent, EventMeeting} from "../../../../Components/Events/CalendarEvent";
import * as moment from "moment";
import {combineLatest, Observable} from "rxjs";
import { ActivityService } from "../../../Shared/Services/activity.service";
import {UserService} from "../../../Shared/Services/user.service";
import { User } from "../../../../Models/User";
import {tap} from "rxjs/operators";

@Component({
    selector: "app-meeting-form",
    templateUrl: "./meeting-form.component.html",
    styleUrls: ["./meeting-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class MeetingFormComponent implements OnInit {

    @Input()
    meetingId: number;

    @Input()
    eventId: number;

    @Input()
    meetingTabWebcastViewOnlyToggle: boolean = false;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    meetingForm = this.fb.group({
        MeetingId: this.fb.control(0),
        EventId: this.fb.control(null),
        CompanyIds: this.fb.control([]),
        AnalystIds: this.fb.control([]),
        WebcastId: this.fb.control("", [Validators.maxLength(100), this.webcastIdAlreadyInUseValidator()]),
        Date: this.fb.control("", [Validators.required]),
        StartTime: this.fb.control(""),
        EndTime: this.fb.control(""),
        TimeZoneId: this.fb.control(null),
        Duration: this.fb.control(null, [Validators.min(0), Validators.max(999)]),
        DisplayName: this.fb.control(""),
        MeetingType: this.fb.control(""),
        CancelledNoShowOverride: this.fb.control(""),
        ActivityCategory: this.fb.control("", [Validators.required]),
        Tickers: this.fb.control([]),
        Location: this.fb.control("")
    }, {validators: this.timeValidator('StartTime', 'EndTime')});

    user: User;
    eventMeeting: EventMeeting;

    title: string = "";
    titleEventMeetingName: string = null;

    timeCssClass = "";

    webcastIdsAlreadyInUse: string[] = null;

    formLoading: boolean = false;
    formSaving: boolean = false;

    activityCommentPreview: string = '';

    eventName: string = '';

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private eventService: EventService,
                private activityService: ActivityService,
                private userService: UserService,
                private toastrService: ToastrService,
    ) {
    }

    ngOnInit(): void {
        this.formLoading = true;

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;

            if (this.meetingId) {
                this.title = this.userIsEventAdmin() && !this.meetingTabWebcastViewOnlyToggle ? "Edit Meeting" : "View Meeting";
                this.eventService.getEventMeetingById(this.meetingId)
                    .subscribe(m => {
                        this.eventMeeting = m;
                        m.Date = moment(m.Date).format('YYYY-MM-DD');
                        this.meetingForm.patchValue(m);
                        this.titleEventMeetingName = m.DisplayName;
                        this.getWebcastIdsAlreadyInUse(this.eventMeeting.WebcastId);
                        this.formLoading = false;
                    });

            } else if (this.eventId) {
                this.title = "Add Meeting";
                this.eventService.getEvent(this.eventId)
                    .subscribe((e: CalendarEvent) => {
                        this.meetingForm.get('EventId').patchValue(e.Id);
                        this.meetingForm.get('Date').patchValue(moment(e.BeginDate).format('YYYY-MM-DD'));
                        this.meetingForm.get('DisplayName').patchValue(e.Name);
                        this.eventName = e.Name;
                        this.meetingForm.get('AnalystIds').patchValue(e.AnalystUserIds);
                        this.getWebcastIdsAlreadyInUse();
                        this.formLoading = false;
                    });
            }

            this.meetingForm.get('ActivityCategory').valueChanges
                .subscribe((category: string) => {
                    let durationFormControl = this.meetingForm.get('Duration');
                    if ((durationFormControl.value ?? 0) === 0 && category.trim().toUpperCase() === "N") {
                        durationFormControl.patchValue(60);
                    }
                });

            this.meetingForm.get('DisplayName').valueChanges
                .subscribe((value) => this.generateActivityCommentPreview(value));

            let startTimeChanges$: Observable<(moment.Moment | string)> = this.meetingForm.get('StartTime').valueChanges;
            let endTimeChanges$: Observable<(moment.Moment | string)> = this.meetingForm.get('EndTime').valueChanges;
            combineLatest([startTimeChanges$, endTimeChanges$])
                .subscribe(([startTime, endTime]) => {
                    let durationControl = this.meetingForm.get('Duration');
                    let duration = durationControl.value ?? 0;

                    if (duration === 0 && startTime && endTime) {
                        durationControl.patchValue(
                            Math.round(moment.duration(moment(endTime).diff(moment(startTime))).asMinutes())
                        );
                    }
                });

        });
    }

    getWebcastIdsAlreadyInUse(webcastIdToExclude: string = null) {
        this.eventService.getEventMeetingWebcastIds(
            moment('2000-01-01'),
            moment('2100-01-01')
        ).subscribe(webcastIds => {
            this.webcastIdsAlreadyInUse = webcastIds;
            if (webcastIdToExclude) {
                const index = this.webcastIdsAlreadyInUse.indexOf(webcastIdToExclude);
                this.webcastIdsAlreadyInUse.splice(index, 1);
            }
        })
    }

    isWebcastIdAlreadyInUse(): boolean {
        return this.webcastIdsAlreadyInUse ?
            !!this.webcastIdsAlreadyInUse.find(x => x === this.meetingForm.get('WebcastId').value) :
            false;
    }

    webcastIdAlreadyInUseValidator(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return (control.value && control.value !== "" && this.isWebcastIdAlreadyInUse()) ?
                { webcastIdAlreadyInUse: true } :
                null;
        }
    }

    userIsEventAdmin(): boolean {
        return this.user && this.userService.hasUserFeature(this.user, 'EventAdmin');
    }

    addTickers(newTickers: string[]) {
        let tickers: string[] = this.meetingForm.get('Tickers').value;
        let tickersToAdd = newTickers.filter(nt => !tickers.includes(nt));
        tickers.push(...tickersToAdd);
        this.meetingForm.get('Tickers').patchValue(tickers.slice());
        this.meetingForm.get('Tickers').updateValueAndValidity({
            emitEvent: true
        });
    }

    save() {
        this.formSaving = true;
        let meeting: EventMeeting = this.meetingForm.value;

        meeting.StartTime = meeting.StartTime ? moment(meeting.StartTime).format('LT') : null;
        meeting.EndTime = meeting.EndTime ? moment(meeting.EndTime).format('LT') : null;

        let oldWebcastId = this.eventMeeting?.WebcastId?.trim();
        let newWebcastId = meeting?.WebcastId?.trim();
        if (this.eventMeeting && oldWebcastId && newWebcastId !== oldWebcastId) {

            this.activityService.getActivitiesByEventMeetingId(this.meetingId).subscribe(activities => {
                let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
                    initialState: {
                        message: `You are changing the Webcast ID this meeting (old "${oldWebcastId}", new "${newWebcastId}"). Changing the Webcast ID will delete all ${activities.length} attendee(s) from this meeting. Are you sure you want to continue with saving this meeting?`
                    },
                    animated: false,
                    keyboard: false,
                    backdrop: 'static',
                });

                confirmModalRef.content.action.subscribe(_ => {
                    this.eventService.addUpdateEventMeeting(meeting)
                        .subscribe(_ => {
                            this.formSaving = false;
                            this.toastrService.success("Meeting Saved");
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        });
                });

                confirmModalRef.content.declineAction.subscribe(_ => {
                    this.formSaving = false;
                });
            });
        } else {
            this.eventService.addUpdateEventMeeting(meeting)
                .subscribe(_ => {
                    this.formSaving = false;
                    this.toastrService.success("Meeting Saved");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
        }
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        this.formSaving = true;
        this.activityService.getActivitiesByEventMeetingId(this.meetingId).subscribe(activities => {
            let numberOfAttendees = activities.filter(a => a.Status === "Attended" || a.Status === "Registered" || a.Status === "Invited").length;

            let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
                initialState: {
                    message: `${numberOfAttendees} attendee(s) have registered, been invited to, or attended this meeting. Are you sure you want to delete this meeting?`
                },
                animated: false,
                keyboard: false,
                backdrop: 'static',
            });

            confirmModalRef.content.action.subscribe(_ => {
                this.eventService.deleteEventMeeting(this.meetingId)
                    .subscribe(_ => {
                        this.formSaving = false;
                        this.toastrService.success("Image Deleted");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            });
        });
    }

    timeValidator(startTimeControl, endTimeControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let start = moment(control.get(startTimeControl).value);
            let end = moment(control.get(endTimeControl).value);

            if (!start.isValid() || !end.isValid() || start <= end) {
                this.timeCssClass = '';
                return null;
            }

            this.timeCssClass = 'required-time';
            return {'time': true};
        }
    }

    generateActivityCommentPreview(value) {
        if (this.meetingForm.get('DisplayName').value)
        {
            this.activityCommentPreview = '{Status} - ' + value;
            if (this.activityCommentPreview.length > 80)
            {
                this.activityCommentPreview = this.activityCommentPreview.substring(0,77) + '...'
            }
        }
        else
        {
            if (!this.eventName)
            {
                this.eventService.getEvent(this.eventId).pipe(
                    tap((e) => {
                        this.activityCommentPreview = '{Status} - ' + e.Name;
                    })
                ).subscribe();
            }
            else
            {
                 this.activityCommentPreview = '{Status} - ' + this.eventName;
            }
            if (this.activityCommentPreview.length > 80)
            {
                this.activityCommentPreview = this.activityCommentPreview.substring(0,77) + '...'
            }
        }
    }

}
