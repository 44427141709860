import {Injectable} from "@angular/core";
import {EmailDraft} from "../../../Models/EmailDraft";

@Injectable({
    providedIn: 'root',
})
export class EmailDraftService {

    constructor() {}

    public openDraftEmail(subject: string, body: string, to: string[]): void {
        let draft = new EmailDraft();
        draft.Subject = subject;
        draft.Body = body;
        draft.To = to;
        window.open(draft.MailToLink(), '_self');
    }
}