import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";
import {UntypedFormBuilder} from "@angular/forms";
import {CorpTravelUserRole, CorpTravelUserService} from "./corp-travel-user.service";
import {map} from "rxjs/operators";

@Component({
    selector: "app-corp-travel-user-role-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(CorpTravelUserRolePickerComponent)
})
export class CorpTravelUserRolePickerComponent extends BasePickerComponent<CorpTravelUserRole> {

    constructor(fb: UntypedFormBuilder, private corpTravelUserService: CorpTravelUserService){
        super(fb);
        this.name = "Selected";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
        this.customSearchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.items$ = this.corpTravelUserService.getCorpTravelUserRoles();
    }
}
