import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {getGridDisplayWords, isNoContactContact, isNoContactReadOnlyContact} from "../../Shared/ag-grid-options";
import {Contact} from "../../../Models/Contact";
import {ContactService} from "../../Shared/Services/contact.service";
import {
    contactFirstNameAndAliasValueGetter,
    contactHasResearchRenderer,
    emailRenderer, yesBlankCellRenderer
} from "../../Shared/ag-grid-cell-renderers";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {PhoneCellRenderer} from '../../Widget/CellRenderers/phone-renderer.component';
import {ColDef, ColGroupDef, RowNode, ValueGetterParams} from "ag-grid-community";
import {Query, QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {AccountService} from "../../Shared/Services/account.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ContactFormComponent} from "../../Contact/ContactForm/contact-form.component";
import {ContactResearchDto, ContactResearchService} from "../../Shared/Services/contact-research.service";
import {combineLatest} from "rxjs";
import {Account} from "../../../Models/Account";
import {Person} from "../../../Models/Person";
import { ResearchStatus } from "../../../Models/ResearchStatus";
import {Router} from "@angular/router";
import {EntitlementService, VendorEntitlementDtoShow} from "../../Shared/Services/entitlement.service";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";

@Component({
    selector: "app-account-contact-list",
    templateUrl: "./account-contact-list.component.html",
    styleUrls: ['./account-contact-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountContactListComponent extends BaseGridComponent<ContactWithResearch> implements OnInit {
    @Input()
    accountId: number;
    @Input()
    accountDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    showResearchControl = this.fb.control(false);

    private user: User;

    columnDefs: (ColDef | ColGroupDef)[];

    frameworkComponents = {
        phoneRenderer: PhoneCellRenderer,
    };

    rowClassRules = {
        'do-not-contact': (params) => isNoContactContact(params.data),
        'do-not-contact-readonly': (params) => isNoContactReadOnlyContact(params.data),
    };

    cellClassRules = {
        'hasResearch-yes': (params) => params.value
    }

    contactSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(''),
        contactTeamName: this.fb.control([]),
        isParentSelect: this.fb.control(false),
        roleName: this.fb.control([])
    });

    hasContacts: boolean = false;

    queries: Query[];

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private contactService: ContactService,
                private contactResearchService: ContactResearchService,
                private accountService: AccountService,
                private modalService: BsModalService,
                private entitlementService: EntitlementService,
                private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.overlayNoRowsTemplate = `<span>Search contact list</span>`;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
            });
    }

    onGridReady(params) {
        super.onGridReady(params);

        this.entitlementService.getVendorEntitlementsList().pipe(
            tap(vendorEntitlements => this.setupColumnDefinitions(vendorEntitlements)),
            switchMap(() => {
                return this.showResearchControl.valueChanges.pipe(
                    startWith(this.showResearchControl.value),
                    tap(() => {
                        this.setResearchColumnsVisible();
                    })
                );
            })
        ).subscribe();

        let account$ = this.accountDataChanged.pipe(
            startWith(true),
            switchMap(() => this.accountService.getAccountById(this.accountId)));

        let searchForm$ = this.contactSearchForm.valueChanges.pipe(
            startWith(this.contactSearchForm.getRawValue()),
            debounceTime(300));

        this.rowData$ = combineLatest([account$, searchForm$]).pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(([account, searchForm]) => {
                let queryGroups = this.convertToQuery(searchForm, account.ParentComdolId);
                this.queries = [{QueryGroups: queryGroups} as Query];

                let contacts$ = this.contactService.getContactsByQuery(queryGroups);
                let contactsResearch$ = this.contactResearchService
                    .getContactResearchByQuery(queryGroups);

                return combineLatest([contacts$, contactsResearch$]);
            }),
            map(([contacts, contactsResearch]) =>
                this.mapToContactsWithResearch(contacts, contactsResearch)),
            tap(rowData => this.hasContacts = rowData.length > 0),
        );
    }

    convertToQuery(form: any, parentComdolId: string): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];

        if (!form.isParentSelect || !parentComdolId) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Instnum", "contains", [this.accountId]);
        } else {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Parent Comdol ID", "contains", [parentComdolId]);
        }
        if (form.searchTerm) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "Search", "contains", form.searchTerm);
        }
        if (form.contactTeamName.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Teams", "contains", form.contactTeamName);
        }
        if (form.roleName.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Roles", "contains", form.roleName);
        }

        return queryGroups;
    }

    getDisplayWords(): string {
        return getGridDisplayWords(this.gridApi);
    }

    onCellClicked(event: any): void {
        let contact: ContactWithResearch = event.data;
        this.router.navigate([ContactRoutePaths.ContactDetail, contact.Id]);
    }

    addContact() {
        let initialState = {
            accountId: this.accountId,
            defaultTab: "Details",
        };

        let modalRef = this.modalService.show(ContactFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-lg'
        });

        modalRef.content.dataUpdated
            .subscribe(() => {
                this.accountDataChanged.emit(true);
            });
    }

    exportToExcel() {
        if (!this.hasContacts) return;

        this.contactService.getContactsExport(this.queries, 'AccountContacts').subscribe();
    }

    private setupColumnDefinitions(vendorEntitlements: VendorEntitlementDtoShow[]) {
        let nonResearchColumns: ColDef[] = [
            {field: 'IsResearch', headerName: 'Research', width: 36, sortingOrder: ['desc', 'asc'], headerClass: 'hide-header', cellRenderer: contactHasResearchRenderer, tooltipValueGetter: (params) => params.value ? "Research Access": "",},
            {field: 'LastName', headerName: 'Last', sort: 'asc', flex: 1, tooltipValueGetter: (p) => p.data.LastName},
            {field: 'Name', headerName: 'First', flex: 1, valueGetter: contactFirstNameAndAliasValueGetter, tooltipValueGetter: contactFirstNameAndAliasValueGetter},
            {field: 'PhoneNumber', headerName: 'Phone', flex: 1, cellRenderer: "phoneRenderer", tooltipValueGetter: p => p.data.PhoneNumber},
            {field: 'Email', flex: 1, cellRenderer: emailRenderer, tooltipValueGetter: p => p.data.Email},
            {headerName: 'PersBk', valueGetter: p => p.data.Broker?.LastName ?? "", flex: 1, tooltipValueGetter: p => p.data.Broker?.LastName},
            {field: 'Teams', headerName: 'Teams', flex: 1, tooltipValueGetter: (p) => p.data.Teams},
        ];

        let researchColumns: ColDef[] = [
            {field: 'IsBlueMatrix', headerName: 'BM', headerTooltip: 'Interests/Specialty Mailings', maxWidth: 52,},
            {field: 'IsResearchOnline', headerName: 'BOL', headerTooltip: 'Research Online', maxWidth: 52,},
        ];

        vendorEntitlements
            .forEach(e => {
                let colDef = {
                    colId: "VendorEntitlements" + e.Code,
                    field: e.Code,
                    headerName: e.ShortName,
                    headerTooltip: e.Description,
                    maxWidth: 52,
                    valueGetter: params => this.getVendorEntitlementsByCode(params, e.Code),
                } as ColDef;

                researchColumns.push(colDef);
            });

        researchColumns.push(...[
            {field: 'IsMarketingEmail', headerName: 'MKT', headerTooltip: 'Marketing Email', maxWidth: 52,},
            {field: 'IsResearchEmail', headerName: 'RES EM', headerTooltip: 'Research Email', maxWidth: 71,},
            {field: 'IsResearchVoicemail', headerName: 'VM', headerTooltip: 'Research Voicemail', maxWidth: 52,},
        ]);

        researchColumns
            .forEach(c => {
                c.cellClassRules = this.cellClassRules;
                c.sortingOrder = ['desc', 'asc'];
                c.cellRenderer = yesBlankCellRenderer;
            });

        this.columnDefs = [
            ...nonResearchColumns,
            ...researchColumns,
        ];

        this.gridApi.setColumnDefs(this.columnDefs);
    }

    private getVendorEntitlementsByCode(params: ValueGetterParams<ContactWithResearch>, code: string): boolean {
        return params.data.VendorEntitlements.find(ve => ve.Code == code)?.IsSubscribed ?? false;
    }

    private setResearchColumnsVisible(): void {
        let nonResearchColumns = [
            "IsResearch",
            "PhoneNumber",
            "Email",
            "Broker.LastName",
            "Teams",
        ];
        this.gridColumnApi.setColumnsVisible(nonResearchColumns, !this.showResearchControl.value);

        let vendorEntitlementColumns = this.gridColumnApi.getColumns()
            .filter(c => c.getColId().startsWith("VendorEntitlements"))
            .map(c => c.getColId());

        let researchColumns = [
            "IsBlueMatrix",
            "IsResearchOnline",
            "IsMarketingEmail",
            "IsResearchEmail",
            "IsResearchVoicemail",
            ...vendorEntitlementColumns,
        ];
        this.gridColumnApi.setColumnsVisible(researchColumns, this.showResearchControl.value);
    }

    private mapToContactsWithResearch(contacts: Contact[], contactsResearch: ContactResearchDto[]) {
        return contacts.map(c => {
            let contactResearch = contactsResearch.find(cr => cr.ContactId == c.Id);
            return {
                Id: c.Id,
                FirstName: c.FirstName,
                LastName: c.LastName,
                Alias: c.Alias,
                PhoneNumber: c.PhoneNumber,
                Email: c.Email,
                Broker: c.Broker,
                Teams: c.Teams,
                Account: c.Account,
                IsResearch: !!contactResearch,
                IsBlueMatrix: contactResearch?.IsBlueMatrix ?? false,
                IsResearchOnline: contactResearch?.IsResearchOnline ?? false,
                IsMarketingEmail: contactResearch?.IsMarketingEmail ?? false,
                IsResearchEmail: contactResearch?.IsResearchEmail ?? false,
                IsResearchVoicemail: contactResearch?.IsResearchVoicemail ?? false,
                ResearchStatus: c.ResearchStatus,
                VendorEntitlements: contactResearch?.VendorEntitlements.map(ve => {
                    return {
                        Code: ve,
                        IsSubscribed: true
                    } as ContactWithVendorEntitlement;
                }) ?? [],
            } as ContactWithResearch;
        });
    }
}

interface ContactWithResearch {
    Id: number;
    LastName: string;
    FirstName: string;
    Alias: string;
    PhoneNumber: string;
    Email: string;
    Broker: Person;
    Teams: string[];
    Account: Account;
    IsResearch: boolean;
    IsBlueMatrix: boolean;
    IsResearchOnline: boolean;
    IsMarketingEmail: boolean;
    IsResearchEmail: boolean;
    IsResearchVoicemail: boolean;
    ResearchStatus: ResearchStatus;
    VendorEntitlements: ContactWithVendorEntitlement[];
}

interface ContactWithVendorEntitlement {
    Code: string;
    IsSubscribed: boolean
}
