import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AgGridModule} from "ag-grid-angular-legacy";
import {ContactListComponent} from "./ContactList/contact-list.component";
import {PhoneCellRenderer} from "../Widget/CellRenderers/phone-renderer.component";
import {ContactProfileComponent} from "./ContactProfile/contact-profile.component";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {NgSelectModule} from "@ng-select/ng-select";
import {AccountTagRenderer} from "../Widget/CellRenderers/account-tag-renderer.component";
import {ContactListsComponent} from "./ContactLists/contact-lists.component";
import {ContactListsFormComponent} from "./ContactLists/contact-lists-form.component";
import {ContactDetailComponent} from "./ContactDetail/contact-detail.component";
import {ActivityModule} from "../Activity/activity.module";
import {ContactReadershipComponent} from "./ContactReadership/contact-readership.component";
import {ContactNoteComponent} from "./ContactNote/contact-note.component";
import {UnsubscribeResearchModalComponent} from "./UnsubscribeResearch/unsubscribe-research-modal.component";
import {ContactPreferencesComponent} from "./ContactPreferences/contact-preferences.component";
import {ContactDetailsComponent} from "./ContactDetails/contact-details.component";
import {ContactFormComponent} from "./ContactForm/contact-form.component";
import {ContactSubscriptionListComponent} from "./ContactSubscriptions/contact-subscription-list.component";
import {ContactSubscriptionFormComponent} from "./ContactSubscriptions/contact-subscription-form.component";
import {DuplicateContactModalComponent} from "./ContactForm/duplicate-contact-modal.component";
import {ContactSpecialtyMailingFormComponent} from "./ContactSubscriptions/contact-specialty-mailing-form.component";
import {ContactNameComponent} from "./ContactName/contact-name.component";
import {ConfirmDeleteSubscriptionComponent} from "./ContactSubscriptions/confirm-delete-subscription.component";
import {ContactDetailReportsComponent} from "./ContactDetailReports/contact-detail-reports.component";
import {PipesModule} from "../Shared/Pipes/pipes.module";
import {PresentationNamePipe} from "../Shared/Pipes/PresentationName/presentation-name.pipe";
import {InterestListComponent} from "./InterestList/interest-list.component";
import {FilterSelectionDisplayComponent} from "./InterestList/filter-selection-display.component";
import {ResearchOnlineFormComponent} from "./ResearchOnlineForm/research-online-form.component";
import {ContactImageFormComponent} from "./ContactImageForm/contact-image-form.component";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {contactRoutes} from "./contact.routes";
import {ReassignContactFormComponent} from "./ContactForm/reassign-contact-form-component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([PhoneCellRenderer, AccountTagRenderer]),
        ButtonsModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        TabsModule,
        ActivityModule,
        PopoverModule.forRoot(),
        PipesModule,
        TooltipModule.forRoot(),
        BsDropdownModule,
        RouterModule.forChild(contactRoutes),
    ],
    declarations: [
        ContactListComponent,
        ContactProfileComponent,
        ContactFormComponent,
        ContactListsComponent,
        ContactListsFormComponent,
        ContactDetailComponent,
        ContactReadershipComponent,
        ContactNoteComponent,
        UnsubscribeResearchModalComponent,
        ContactDetailsComponent,
        ContactPreferencesComponent,
        ContactSpecialtyMailingFormComponent,
        ContactSubscriptionListComponent,
        ContactSubscriptionFormComponent,
        DuplicateContactModalComponent,
        ContactNameComponent,
        ConfirmDeleteSubscriptionComponent,
        ContactDetailReportsComponent,
        InterestListComponent,
        FilterSelectionDisplayComponent,

        ResearchOnlineFormComponent,
        ContactImageFormComponent,
        ReassignContactFormComponent,
    ],
    exports: [
        RouterModule,
        InterestListComponent,
    ],
    providers: [
        PresentationNamePipe,
    ]
})
export class ContactModule {
    constructor(){
        console.log("registering the ContactModule");
    }
}
