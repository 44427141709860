import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class CacheService {

    cache = {};
    
    constructor() {
    }

    setValue<T>(key: string, value: T): void{
        this.cache[key] = value;
    }

    getValue<T>(key: string): T {
        return this.cache[key];
    }
    
    removeKey(key: string): void {
        delete this.cache[key];
    }
    
    getLocalStorage<T>(key: string): T {
        return JSON.parse(localStorage.getItem(key));
    }
    
    setLocalStorage<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value));
    }
}