import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService, HttpOptions} from "./base-http.service";
import {Subscription} from "../Models/subscription";
import * as moment from "moment";

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {

    constructor(private baseHttp: BaseHttpService) {
    }

    

    getSubscriptions(contactId: number): Observable<any> {
        return this.baseHttp.getData(`/subscriptions/${contactId}`);
    }

    getSubscription(subscriptionId: number): Observable<Subscription> {
        return this.baseHttp.getData(`/subscriptions/id/${subscriptionId}`);
    }

    updateSubscription(subscriptionId: number, subscription: Subscription): Observable<Subscription> {
        return this.baseHttp.putData(`/subscriptions/id/${subscriptionId}`, subscription);
    }

    deleteSubscription(subscriptionId: number): Observable<any> {
        return this.baseHttp.deleteData(`/subscriptions/id/${subscriptionId}`);
    }

    deleteSubscriptionInterests(subscriptionId: number): Observable<any> {
        return this.baseHttp.deleteData(`/subscriptions/id/${subscriptionId}/interests`);
    }

    deleteSubscriptionContactSpecialtyMailings(subscriptionId: number): Observable<any> {
        return this.baseHttp.deleteData(`/subscriptions/id/${subscriptionId}/contactspecialtymailings`);
    }

    createSubscription(subscription: Subscription): Observable<Subscription> {
        return this.baseHttp.postData(`/subscriptions`, subscription);
    }

    getSpecialtyMailings(isActive: boolean | null = null): Observable<SpecialtyMailing[]> {
        return this.baseHttp.getDataWithConfig(`/specialtymailings`, {params: { isActive: isActive?.toString() } });
    }

    getContactSpecialtyMailings(contactId: number): Observable<any> {
        return this.baseHttp.getData(`/contactspecialtymailings/${contactId}`);
    }

    createContactSpecialtyMailing(contactId: number, code: string, isBlueMatrix: boolean): Observable<SpecialtyMailing> {
        return this.baseHttp.postData(
            '/contactspecialtymailings',
            {
                contactId: contactId,
                code: code,
                isBlueMatrix: isBlueMatrix
            });
    }

    updateContactSpecialtyMailing(contactId: number, code: string, isBlueMatrix: boolean): Observable<SpecialtyMailing> {
        return this.baseHttp.putData(
            `/contactspecialtymailings`,
            {
                contactId: contactId,
                code: code,
                isBlueMatrix: isBlueMatrix
            });
    }

    deleteContactSpecialtyMailing(contactId: number, code: string): Observable<any> {
        return this.baseHttp.deleteData(`/contactspecialtymailings/${contactId}/${code}`)
    }
}


export interface SpecialtyMailing {
    Code: string;
    Analyst: string;
    AnalystEmail: string;
    Symbol: string;
    Description: string;
    IsActive: boolean;
}

export interface ContactSpecialtyMailing {
    ContactId: number;
    Code: string;
}



