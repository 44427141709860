import {Component, Output, EventEmitter} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-exclude-contacts-modal",
    templateUrl: "./exclude-contacts-modal.component.html"
})
export class ExcludeContactsModalComponent {
    @Output()
    excludeContacts: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    constructor(public modalRef: BsModalRef) {}
    
    submit(excludeContacts: boolean): void {
        this.excludeContacts.emit(excludeContacts);
        this.modalRef.hide();
    }
}
