import {Component, OnInit } from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserTeam, UserTeamAdminService} from "./user-team-admin.service";
import {UserTeamFormComponent} from "./user-team-form.component";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {debounceTime, map, startWith} from "rxjs/operators";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {combineLatest} from "rxjs";


@Component({
    selector: "app-admin-user-team-list",
    templateUrl: "./user-team-list.component.html"
})
export class UserTeamsListComponent extends BaseGridComponent<UserTeamRow> implements OnInit {

    columnDefs = [
        { field: 'Role', width: 200 },
        { field: 'Team', width: 200 },
        { field: 'IsActive', headerName: 'Active', width: 100, cellRenderer: yesNoCellRenderer },
        { field: 'ActiveUserIds', flex: 1 },
        { field: 'InactiveUserIds', flex: 1 },
    ];

    userTeamSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control('')
    });

    constructor(private userTeamService: UserTeamAdminService,
                private fb: UntypedFormBuilder,
                private modalService: BsModalService) {
        super();
    }

    ngOnInit(): void {
        this.setRowData();
    }

    setRowData(): void {
        let allTeams$ = this.userTeamService.getAllUserTeams();
        let formValues$ = this.userTeamSearchForm.valueChanges.pipe(
            startWith(this.userTeamSearchForm.value),
            debounceTime(250)
        );

        this.rowData$ = combineLatest([allTeams$, formValues$]).pipe(
            map(([userTeams, formValues]) => {
                return userTeams
                    .map(ut => {
                        let parentTeam = userTeams.find(t => t.Id === ut.ParentId);
                        return {
                            Id: ut.Id,
                            Role: parentTeam ? parentTeam.Description : '',
                            Team: ut.Description,
                            ActiveUserIds: ut.ActiveUserIds,
                            InactiveUserIds: ut.InactiveUserIds,
                            IsActive: ut.IsActive,
                        }
                    })
                    .filter(ut => formValues.searchTerm ?
                        ut.Role.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) > -1 ||
                        ut.Team.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) > -1 :
                        true);
            })
        )
    }

    onGridReady(params) {
        params.columnApi.applyColumnState({
            state: [
                { colId: 'Role', sort: 'asc', sortIndex: 0 },
                { colId: 'Team', sort: 'asc', sortIndex: 1 },
            ]
        });

        super.onGridReady(params);
    }

    onRowClicked($event: any) {
        this.openModalForm($event.data.Id);
    }

    addUserTeam() {
        this.openModalForm(-1);
    }

    openModalForm(id: number): void {
        const initialState = {
            userTeamId: id
        };
        let modalRef = this.modalService.show(UserTeamFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static' });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }
}

interface UserTeamRow {
    Id: number;
    Role: string;
    Team: string;
    ActiveUserIds: string[];
    InactiveUserIds: string[];
    IsActive: boolean;
}
