import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl, FormGroup, Validators} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment";
import {Activity} from "../../../Models/Activity";
import {ActivityService} from "../../Shared/Services/activity.service";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Observable, of, Subscription} from "rxjs";
import {Meeting, MeetingService} from "../../Shared/Services/meeting.service";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {EmailDraftService} from "../../Shared/Services/email-draft.service";
import {AccountService} from "../../Shared/Services/account.service";
import {map, switchMap, tap} from "rxjs/operators";
import {User} from "../../../Models/User";
import {EESActivity, EESActivityCreateBy, EESActivityService} from "../../Shared/Services/ees-activity.service";

@Component({
    selector: "app-ees-activity-form",
    templateUrl: "./ees-activity-form.component.html"
})
export class EesActivityFormComponent implements OnInit {

    @Input()
    contactIds: number[];

    @Input()
    accountId: number;

    @Input()
    activityId: number;

    @Output()
    dataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    createdBy: UntypedFormControl = this.fb.control({value:"", disabled:true});
    eesActivity: UntypedFormControl = this.fb.control({} as EESActivity);
    disabled: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private toastrService: ToastrService,
                private modalRef: BsModalRef,
                private userService: UserService,
                private contactService: ContactService,
                private accountService: AccountService,
                private modalService: BsModalService,
                private eesActivityService: EESActivityService,
    ) {}

    ngOnInit() {
        if (this.activityId) {
            this.eesActivityService.getActivityById(this.activityId)
                .subscribe(activity => {
                    this.eesActivity.patchValue(activity); //TODO patch value so that participants and baird particpants are filled
                    this.createdBy.patchValue(`${activity.CreatedBy.Id} ${moment(activity.CreatedDate).format("LL")}`);
                });
        } else if (this.contactIds?.length > 0) {
            let user$ = this.userService.getCurrentUser();
            let bairdContacts$ = this.contactService.getBairdContacts();
            let contacts$ = this.contactService.getContactsByIds(this.contactIds);
            combineLatest([user$, bairdContacts$, contacts$])
                .subscribe(([user, bairdContacts, contacts]) => {
                    this.accountId = contacts[0].Account.Id;
                    let currentUserContact = bairdContacts.find(c => c.Email === user.Email);
                    this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);
                    this.initEmptyActivity(moment().toDate(), user, this.contactIds, currentUserContact ? [currentUserContact.Id] : []);
                });
        } else if (this.accountId) {
            let user$ = this.userService.getCurrentUser();
            let bairdContacts$ = this.contactService.getBairdContacts();
            combineLatest([user$, bairdContacts$])
                .subscribe(([user, bairdContacts]) => {
                    let currentUserContact = bairdContacts.find(c => c.Email === user.Email);
                    this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);
                    this.initEmptyActivity(moment().toDate(), user, [], currentUserContact ? [currentUserContact.Id] : []);
                });
        }
        else {
            let user$ = this.userService.getCurrentUser();
            let bairdContacts$ = this.contactService.getBairdContacts();
            combineLatest([user$, bairdContacts$])
                .subscribe(([user, bairdContacts]) => {
                    let currentUserContact = bairdContacts.find(c => c.Email === user.Email);
                    this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);
                    this.initEmptyActivity(moment().toDate(), user, [], currentUserContact ? [currentUserContact.Id] : []);
                });
        }
    }

    initEmptyActivity(createdDate: Date, createdBy: User, clientContactIds: number[], bairdContactIds: number[]) : void {
        this.eesActivity.patchValue({
            Id: 0,
            ActivityDate: moment().toDate(),
            ActivityType: 'C',
            Status: 'O',
            DueDate: moment().toDate(),
            BairdParticipants: bairdContactIds.map(cid => { return { Id: cid }}),
            ClientParticipants: clientContactIds.map(cid => { return { Id: cid }}),
            Comment: '',
            Baird: createdBy.Id,
            CreatedDate: createdDate,
            CreatedBy: {Id: createdBy.Id} as EESActivityCreateBy,
        } as Partial<EESActivity>);
    }

    close() {
        this.modalRef.hide();
    }

    save() {
        let createUpdate$: Observable<any>;
        let saveMessage: string = '';

        if (this.activityId) {
            createUpdate$ = this.eesActivityService.updateActivity(this.eesActivity.value);
            saveMessage = "Activity Updated";
        } else {
            createUpdate$ = this.eesActivityService.createActivity(this.eesActivity.value);
            saveMessage = "Activity Created";
        }

        createUpdate$
            .subscribe(activity => {
                this.closeForm(saveMessage);
            });
    }

    closeForm(message: string): void {
        this.toastrService.success(message);
        this.dataUpdated.emit(true);
        this.modalRef.hide();
    }

    delete() {
        if (this.activityId) {
            this.confirmThenDelete("Activity", this.eesActivityService.deleteActivity(this.activityId))
        }
    }

    confirmThenDelete(type: string, deleteAction$: Observable<any>): void {
        let confirmRef = this.modalService.show(ConfirmModalComponent, {
            ignoreBackdropClick: true,
            backdrop: true,
            initialState: {message: `Are you sure you want to delete this ${type}`}
        });

        confirmRef.content.action
            .subscribe(() => {
                deleteAction$
                    .subscribe(() => {
                        this.toastrService.success(`${type} Deleted`);
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    })
            });
    }

    formValid(): boolean {
        return !this.disabled &&
            this.eesActivity.valid
    }
}
