import {Injectable} from '@angular/core';
import {CacheService} from "./cache.service";

@Injectable({
    providedIn: 'root',
})
export class ConfigService {

    private appConfig;

    static readonly BuyBackUserRoutes: string[] = [
        "admin",
        "accessdenied",
        "events",
        "reports/tradedetail",
    ];

    static readonly IsApiExternalCacheKey: string = "IsApiExternal";

    constructor(private cacheService: CacheService) { }

    loadAppConfig() {
        if ((<any>window).instiselconfig) {
            this.appConfig = (<any>window).instiselconfig;
        }
    }

    getActiveApiUrl(): string {
        return this.getApiIsExternal() ? this.getExternalApiUrl() : this.getApiUrl();
    }

    getApiUrl(): string {
        return this.appConfig.API_URL;
    }

    getExternalApiUrl(): string {
        return this.appConfig.API_URL_EXTERNAL;
    }

    getAzureAdConfig(): Record<string, any> {
        return this.appConfig.AzureAd;
    }

    getInstiselUrl() {
        return this.appConfig.InstiselUrl;
    }
    
    getSiteIsExternal(): boolean {
        return this.appConfig.SiteIsExternal;
    }
    
    setApiIsExternal(value: boolean): void {
        this.cacheService.setValue(ConfigService.IsApiExternalCacheKey, value);
    }
    
    getApiIsExternal(): boolean {
        return this.cacheService.getValue(ConfigService.IsApiExternalCacheKey);
    }
    
    getInstiselUpdateUrl(): string {
        return this.appConfig.InstiselUpdateUrl;
    }

    getCorpTravelUrl(): string {
        return this.appConfig.CorpTravelUrl;
    }

    getAppFeatures(): string[] {
        return this.appConfig.ReleaseFeatures;
    }

    getResearchDashboardEventListTabUsers(): string[] {
        return this.appConfig.ResearchDashboardEventListTabUsers;
    }

    getBuyBackUsers(): string[] {
        return this.appConfig.BuyBackUsers;
    }
    
    getEnvironment(): string {
        return this.appConfig.Environment;
    }

    getPowerBIReportsUrl(): string {
        return this.appConfig.PowerBIReportsURL;
    }

    public getBuildVersion(): string {
        return this.appConfig.BuildVersion;
    }

    public IsProduction() {
        return this.getEnvironment() === "production";
    }
}
