import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import * as _ from "lodash";
import {map, tap} from "rxjs/operators";
import {TravelMarket, TravelMarketService} from "../Services/travel-market.service";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";
import {of} from "rxjs";
import {TravelCity, TravelCityService} from "../Services/travel-city.service";

@Component({
    selector: "app-travel-market-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(TravelMarketPickerComponent)
})
export class TravelMarketPickerComponent extends BasePickerComponent<any> implements OnChanges {

    @Input()
    setMaxItems: number;

    @Input()
    multipleSelect: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    showTagCount: boolean = true;

    @Input()
    closeAfterSelection: boolean = false;

    @Input()
    includeNullOption: boolean = false;
    @Input()
    travelTerritoryIdIsNull: boolean = false;

    @Input()
    placeHolderLabel: string = "";
    
    @Input()
    currentTravelTerritoryId: number;
    
    @Input()
    allTravelMarkets: TravelMarket[]
    unassignedTravelMarketIds: number[];
    travelCities: TravelCity[];
    travelTerritoryIdsToTravelMarketIds: Object;
    travelMarketIds: number[];

    constructor(
        fb: UntypedFormBuilder,
        private travelMarketService: TravelMarketService,
        private travelCityService: TravelCityService,
    ) {
        super(fb);

        this.name = "Market(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;

        this.travelTerritoryIdsToTravelMarketIds = {};
        this.travelMarketIds = [];
        this.unassignedTravelMarketIds = [];
        this.currentTravelTerritoryId = null;
        this.allTravelMarkets = [];
    }

    ngOnInit() {
        this.travelCityService.getTravelCities(true)
            .subscribe((travelCities) => {
                this.travelMarketService.getTravelMarkets()
                    .subscribe((travelMarkets) => {
                        this.unassignedTravelMarketIds = travelMarkets.filter(
                            x => !travelCities.some(y => y.MarketId === x.Id)
                        ).map(x => x.Id)
                        this.travelCities = travelCities
                        travelCities.forEach(travelCity =>
                        {
                            let marketIds = this.travelTerritoryIdsToTravelMarketIds[travelCity.TerritoryId];
                            if (marketIds)
                            {
                                if (!marketIds.some(x => travelCity.MarketId === x))
                                {
                                    marketIds.push(travelCity.MarketId);
                                }
                            }
                            else
                            {
                                this.travelTerritoryIdsToTravelMarketIds[travelCity.TerritoryId] = [travelCity.MarketId];
                            }
                        });
                        this.getMarketsForDropdown();
                    })
            });
        
        this.multiple = this.multipleSelect;
        this.showSelectClearAll = this.selectClearAll;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = true;
        this.searchable = false;
        
        this.customOnChange = (data: any) => {
            if(data == "NoTravelMarketSelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }
        
        this.items$ = this.travelMarketService.getTravelMarkets().pipe(
            tap((travelMarkets) => {this.allTravelMarkets = travelMarkets}),
            map(travelMarkets => _.sortBy(travelMarkets, m => m.Name)),
            map(travelMarkets => {
                if (this.includeNullOption) {
                    travelMarkets.unshift({Id: null, Name: "",});
                }
                return travelMarkets;
            })
        );
        
        super.ngOnInit();
    }
    
    ngOnChanges(changes: SimpleChanges) {
        this.getMarketsForDropdown();
    }
    
    getMarketsForDropdown()
    {
        this.travelMarketIds = this.travelTerritoryIdsToTravelMarketIds[this.currentTravelTerritoryId];
        if (this.travelMarketIds)
        {
            this.travelMarketIds = this.travelMarketIds.concat(this.unassignedTravelMarketIds);
        }
        else {
            this.travelMarketIds = this.unassignedTravelMarketIds;
        }
        
        if (this.travelTerritoryIdIsNull)
        {
            this.items$ = of(this.allTravelMarkets).pipe(
                map(filteredTravelMarkets => _.sortBy(filteredTravelMarkets, m => m.Name)),
                map(filteredTravelMarkets => {
                    if (this.includeNullOption) {
                        filteredTravelMarkets.unshift({Id: null, Name: "",});
                    }
                    return filteredTravelMarkets;
                })
            )
        }
        else
        {
            this.items$ = of(this.allTravelMarkets
                .filter(x => this.travelMarketIds.some(y => x.Id === y))).pipe(
                map(filteredTravelMarkets => _.sortBy(filteredTravelMarkets, m => m.Name)),
                map(filteredTravelMarkets => {
                    if (this.includeNullOption) {
                        filteredTravelMarkets.unshift({Id: null, Name: "",});
                    }
                    return filteredTravelMarkets;
                })
            )
        }
    }
}
