import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmModalComponent} from "./ConfirmModal/confirm-modal.component";
import {PageBannerComponent} from "./PageBanner/page-banner.component";
import {ClickToDialComponent} from "./ClickToDial/click-to-dial.component";
import {PhoneCellRenderer} from "./CellRenderers/phone-renderer.component";
import {FormErrorDisplayComponent} from "./FormErrorDisplay/form-error-display.component";
import {CheckboxListComponent} from "./CheckboxList/checkbox-list.component";
import {TextboxListComponent} from "./TextboxList/textbox-list.component";
import {DateRangePickerComponent} from "./DateRangePicker/date-range-picker.component";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {FileUploadComponent} from "./FileUpload/file-upload.component";
import {EventTrackerDirective} from "./EventTrackers/event-tracker.directive";
import {AccountTagRenderer} from "./CellRenderers/account-tag-renderer.component";
import {MultiContactSelectComponent} from "./MultiContactSelect/multi-contact-select.component";
import {MultiAccountSelectComponent} from "./MultiAccountSelect/multi-account-select.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {MultiUserSelectComponent} from "./MultiUserSelect/multi-user-select.component";
import {NumberRangeComponent} from "./NumberRange/number-range.component";
import {ClearFiltersButtonComponent} from "./Buttons/ClearFiltersButton/clear-filters-button.component";
import {IfNotUserFeatureDirective, IfUserFeatureDirective} from "./Directives/if-user-feature.directive";
import {StringArrayInputComponent} from "./StringArrayInput/string-array-input.component";
import {TickerVolumeChartComponent} from "./Charts/ticker-volume-chart.component";
import {TabActivityListComponent} from "./Activities/TabActivityList/tab-activity-list.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {PickersModule} from "./Pickers/pickers.module";
import {FilesUploadComponent} from "./FileUpload/files-upload.component";
import {CheckBoxRenderer} from "./CellRenderers/checkbox-renderer.component";
import {TextBoxRenderer} from "./CellRenderers/textbox-renderer.component";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {PopoverModule} from "ngx-bootstrap/popover";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {TabsModule} from "ngx-bootstrap/tabs";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {TextModalComponent} from "./TextModal/text-modal.component";
import {ReadershipModalComponent} from "./Email/ReadershipModal/readership-modal.component";
import {ReadershipModalService} from "./Email/ReadershipModal/readership-modal-service";
import {RequiresFeatureDirective, RequiresNotFeatureDirective} from "./Directives/requires-feature.directive";
import {YesNoSingleSelectComponent} from "./Pickers/YesNoSingleSelect/yes-no-single-select.component";
import {ContactPopoverComponent} from "./Popovers/ContactPopover/contact-popover.component";
import {PipesModule} from "../Shared/Pipes/pipes.module";
import {ContactFavoriteComponent} from "./ContactFavorite/contact-favorite.component";
import {ContactListsPopoverComponent} from "./Popovers/ContactListsPopover/contact-lists-popover.component";
import {CommissionAccountRenderer} from "./CellRenderers/commission-account-renderer";
import {PercentageRenderer} from "./CellRenderers/percentage-renderer.component";
import {ExportsDropdownComponent} from "./DropdownMenus/ExportsDropdown/exports-dropdown.component";
import {CollapsibleHeaderRenderer} from "./CellRenderers/collapsible-header-renderer.component";
import {DoNotContactBannerComponent} from "./DoNotContactBanner/do-not-contact-banner.component";
import {AddContactsModalComponent} from "./MultiContactSelect/add-contacts-modal.component";
import {EditContactsModalComponent} from "./MultiContactSelect/edit-contacts-modal.component";
import {IconHeaderRendererComponent} from "./CellRenderers/icon-header-renderer.component";
import {ContactListRenderer} from "./CellRenderers/contact-list-renderer.component";
import {HtmlEditorComponent} from "./HtmlEditor/html-editor.component";
import {CKEditorModule} from "ckeditor4-angular";
import {TabInterestListComponent} from "./Interests/TabInterestList/tab-interest-list.component";
import {ContactNameCellRenderer} from "./CellRenderers/contact-name-renderer.component";
import {MoreFiltersModalComponent} from "./MoreFilters/more-filters-modal.component";
import {MultiTickerSelectComponent} from "./MultiTickerSelect/multi-ticker-select.component";
import {QueryGroupsInputComponent} from "./Query/query-groups-input.component";
import {QueryCriteriaComponent} from "./Query/query-criteria.component";
import {DateBetweenInputComponent} from "./Query/date-between-input.component";
import {QueryGroupsPopoverComponent} from "./Query/query-groups-popover.component";
import {QuerySelectModalComponent} from "./Query/query-select-modal.component";
import {ContactListSaveModalComponent} from "./ContactList/contact-list-save-modal.component";
import {ContactListOpenModalComponent} from "./ContactList/contact-list-open-modal.component";
import {TabEESActivityListComponent} from "./EES/TabEESActivity/tab-ees-activity-list.component";
import { RelativePayComponent } from "./RvC/relative-pay.component";
import { RvcBottomComponent } from "./RvC/rvc-bottom.component";
import { RvcTopComponent } from "./RvC/rvc-top.component";
import { RvcComponent } from "./RvC/rvc.component";
import { YoyComponent } from "./RvC/yoy.component";
import {AddTickersModalComponent} from "./MultiTickerSelect/add-tickers-modal.component";
import {ExportContactsComponent} from "./ExportContacts/export-contacts.component";
import {ProgressModalComponent} from "./ProgressModal/progress-modal.component";
import {LoadingComponent} from "./Loading/loading.component";
import {ExcludeContactsModalComponent} from "./ExcludeContactsModal/exclude-contacts-modal.component";
import {ContactSelectModalComponent} from "./ContactSelectModal/contact-select-modal.component";
import {EmailSelectModalComponent} from "./EmailSelectModal/email-select-modal.component";
import {LoadingModalComponent} from "./Loading/loading-modal.component";
import {AccountSelectModalComponent} from "./AccountSelectModal/account-select-modal.component";
import {ResearchAccessComponent} from "./ResearchAccess/research-access.component";
import {RejectModalComponent} from "./RejectModal/reject-modal.component";
import {CompanyPresenterListRenderer} from "./CellRenderers/company-presenter-list-renderer.component";
import {QueryGroupsReadonlyComponent} from "./Query/query-groups-readonly-component";
import {MultiEventCompanySelectComponent} from "./MultiEventCompanySelect/multi-event-company-select.component";
import {ExcelUploadComponent} from "./DataUpload/excel-upload.component";
import {EventNameCellRenderer} from "./CellRenderers/event-name-renderer.component";
import {EmailSelectListComponent} from "./EmailSelectModal/email-select-list-component";
import {RouterModule} from "@angular/router";
import {CompanyTickerListRenderer} from "./CellRenderers/company-ticker-list-renderer.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule,
        NgSelectModule,
        NgOptionHighlightModule,
        ReactiveFormsModule,
        AgGridModule,
        PickersModule,
        ButtonsModule,
        PopoverModule.forRoot(),
        PipesModule,
        BsDropdownModule.forRoot(),
        CKEditorModule,
        TabsModule,
        ProgressbarModule.forRoot(),
        RouterModule,
    ],
    declarations: [
        AccountTagRenderer,
        AddContactsModalComponent,
        AddTickersModalComponent,
        CheckBoxRenderer,
        CheckboxListComponent,
        ClearFiltersButtonComponent,
        ClickToDialComponent,
        CollapsibleHeaderRenderer,
        CommissionAccountRenderer,
        CompanyPresenterListRenderer,
        CompanyTickerListRenderer,
        ConfirmModalComponent,
        ContactFavoriteComponent,
        ContactListRenderer,
        ContactListsPopoverComponent,
        ContactNameCellRenderer,
        ContactPopoverComponent,
        ContactListSaveModalComponent,
        DateBetweenInputComponent,
        DateRangePickerComponent,
        DoNotContactBannerComponent,
        EditContactsModalComponent,
        EventNameCellRenderer,
        EventTrackerDirective,
        ExcelUploadComponent,
        ExportsDropdownComponent,
        FileUploadComponent,
        FilesUploadComponent,
        FormErrorDisplayComponent,
        HtmlEditorComponent,
        IconHeaderRendererComponent,
        IfNotUserFeatureDirective,
        IfUserFeatureDirective,
        MoreFiltersModalComponent,
        MultiAccountSelectComponent,
        MultiContactSelectComponent,
        MultiEventCompanySelectComponent,
        MultiUserSelectComponent,
        NumberRangeComponent,
        PageBannerComponent,
        PercentageRenderer,
        PhoneCellRenderer,
        QueryCriteriaComponent,
        QueryGroupsInputComponent,
        QueryGroupsPopoverComponent,
        QueryGroupsReadonlyComponent,
        QuerySelectModalComponent,
        ReadershipModalComponent,
        RejectModalComponent,
        RequiresFeatureDirective,
        RequiresNotFeatureDirective,
        ResearchAccessComponent,
        StringArrayInputComponent,
        TabActivityListComponent,
        TabInterestListComponent,
        TextBoxRenderer,
        TextModalComponent,
        TextboxListComponent,
        TickerVolumeChartComponent,
        YesNoSingleSelectComponent,
        ContactNameCellRenderer,
        EventNameCellRenderer,
        MoreFiltersModalComponent,
        RelativePayComponent,
        RvcBottomComponent,
        RvcTopComponent,
        RvcComponent,
        YoyComponent,
        MultiTickerSelectComponent,
        ExportContactsComponent,
        ProgressModalComponent,
        LoadingComponent,
        ContactListOpenModalComponent,
        ExcludeContactsModalComponent,
        ContactSelectModalComponent,
        EmailSelectModalComponent,
        EmailSelectListComponent,
        LoadingModalComponent,
        TabEESActivityListComponent,
        AccountSelectModalComponent,
    ],
    exports: [
        AccountTagRenderer,
        AddContactsModalComponent,
        AddTickersModalComponent,
        CheckBoxRenderer,
        CheckboxListComponent,
        ClearFiltersButtonComponent,
        ClickToDialComponent,
        CollapsibleHeaderRenderer,
        CommissionAccountRenderer,
        CompanyPresenterListRenderer,
        CompanyTickerListRenderer,
        ConfirmModalComponent,
        ContactFavoriteComponent,
        ContactListSaveModalComponent,
        ContactListsPopoverComponent,
        ContactNameCellRenderer,
        ContactPopoverComponent,
        DateBetweenInputComponent,
        DateRangePickerComponent,
        DoNotContactBannerComponent,
        EditContactsModalComponent,
        EventNameCellRenderer,
        EventTrackerDirective,
        ExcelUploadComponent,
        ExportsDropdownComponent,
        FileUploadComponent,
        FilesUploadComponent,
        FormErrorDisplayComponent,
        HtmlEditorComponent,
        IfNotUserFeatureDirective,
        IfUserFeatureDirective,
        MoreFiltersModalComponent,
        MultiAccountSelectComponent,
        MultiContactSelectComponent,
        MultiEventCompanySelectComponent,
        MultiUserSelectComponent,
        NumberRangeComponent,
        PageBannerComponent,
        PercentageRenderer,
        PhoneCellRenderer,
        PickersModule,
        QueryCriteriaComponent,
        QueryGroupsInputComponent,
        QueryGroupsPopoverComponent,
        QueryGroupsReadonlyComponent,
        QuerySelectModalComponent,
        ReadershipModalComponent,
        RejectModalComponent,
        RequiresFeatureDirective,
        RequiresNotFeatureDirective,
        ResearchAccessComponent,
        StringArrayInputComponent,
        TabActivityListComponent,
        TabInterestListComponent,
        TextBoxRenderer,
        TextModalComponent,
        TextboxListComponent,
        TickerVolumeChartComponent,
        YesNoSingleSelectComponent,
        ContactNameCellRenderer,
        EventNameCellRenderer,
        MoreFiltersModalComponent,
        RvcComponent,
        MultiTickerSelectComponent,
        ExportContactsComponent,
        ProgressModalComponent,
        LoadingComponent,
        ExcludeContactsModalComponent,
        ContactSelectModalComponent,
        EmailSelectModalComponent,
        EmailSelectListComponent,
        LoadingModalComponent,
        TabEESActivityListComponent,
        AccountSelectModalComponent,
    ],
    providers: [
        ReadershipModalService
    ]
})
export class WidgetModule {
    constructor(){}
}
