import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from "@angular/core";
import {combineLatest, Observable, of} from "rxjs";
import {ContactService} from "../../Shared/Services/contact.service";
import {AccountService} from "../../Shared/Services/account.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {debounceTime, filter, map, startWith, switchMap, tap} from "rxjs/operators";
import {Contact} from "../../../Models/Contact";
import {Account} from "../../../Models/Account";
import {TickerService} from "../../Shared/Services/ticker.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {ActivityFormComponent} from "../../Activity/ActivityForm/activity-form.component";
import {InterestFormComponent} from "../../Interest/InterestForm/interest-form.component";
import {UserService} from "../../Shared/Services/user.service";
import {GlobalSearchService} from "../../Shared/Services/global-search.service";
import {AnalyticsService} from "../../Shared/Services/analytics.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";
import {AccountRoutePaths} from "../../Account/account-route-paths";
import {TickerRoutePaths} from "../../Ticker/ticker-route-paths";

@Component({
    selector: "app-global-search",
    templateUrl: "./global-search.component.html",
    styleUrls: ["./global-search.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class GlobalSearchComponent implements OnInit {

    resultsState: 'none' | 'loading' | 'results' = 'none';
    searchResults$: Observable<SearchResult[]>;

    details: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();
    detailState: 'none' | 'loading' | 'account' | 'contact' | 'ticker' = 'none';
    detailAccount: Account;
    detailContact: Contact;
    detailTicker: any;

    searchForm: UntypedFormGroup = this.fb.group({
        type: this.fb.control('All'),
        query: this.fb.control('')
    });

    searchTypes: Observable<string[]>;
    selectedIndex: number = 0;
    searchResults: SearchResult[];

    @ViewChildren("result")
    resultElements: QueryList<ElementRef<HTMLDivElement>>;

    constructor(private fb: UntypedFormBuilder,
                private searchService: GlobalSearchService,
                private accountService: AccountService,
                private contactService: ContactService,
                private tickerService: TickerService,
                private modalService: BsModalService,
                private userService: UserService,
                private analyticsService: AnalyticsService,
                private route: ActivatedRoute,
                private router: Router,
    ) {}

    ngOnInit() {

        let user$ = this.userService.getCurrentUser();
        let formValues$ = this.searchForm.valueChanges.pipe(
            startWith(this.searchForm.value),
            debounceTime(100),
        );

        this.searchResults$ = combineLatest([user$, formValues$]).pipe(
            tap(([user, formValue]) => {
                this.resultsState = 'none';
                this.detailState = 'none';

                let types = ['All','Accounts','Contacts'];
                if (this.userService.hasUserFeature(user, 'TickerPage')) {
                    types.push('Tickers');
                }
                this.searchTypes = of(types);
            }),
            filter(([user, formValue]) => formValue.query.trim().length > 0),
            tap(() => {
                this.selectedIndex = 0;
                this.resultsState = 'loading';
            }),
            switchMap(([user, formValue]) => {
                let accounts$ = ['All', 'Accounts'].includes(formValue.type) ? this.searchService.searchAccounts(formValue.query) : of([]);
                let contacts$ = ['All', 'Contacts'].includes(formValue.type) ?  this.searchService.searchContacts(formValue.query) : of([]);
                let tickers$ = !this.userService.hasUserFeature(user, 'TickerPage') ? of([]) :
                               ['All', 'Tickers'].includes(formValue.type) ?  this.searchService.searchTickers(formValue.query) :
                                   of([]);

                return combineLatest([accounts$, contacts$, tickers$]).pipe(
                    map(([accounts, contacts, tickers]) => {

                        let results: SearchResult[] = [];

                        results.push(...accounts.slice(0, formValue.type === 'Accounts' ? 30 : 10).map(a => {
                            return {
                                id: a.Id,
                                text: a.Description,
                                text2: a.Location,
                                type: 'account'
                            } as SearchResult
                        }));

                        results.push(...contacts.slice(0, formValue.type === 'Contacts' ? 30 : 10).map(c => {
                            return {
                                id: c.Id,
                                text: c.Description,
                                text2: c.Account,
                                type: 'contact'
                            } as SearchResult
                        }));

                        results.push(...tickers.slice(0, formValue.type === 'Tickers' ? 30 : 10).map(t => {
                            return {
                                id: t.Name,
                                text: t.CompanyName,
                                text2: t.Name,
                                type: 'ticker'
                            } as SearchResult
                        }));

                        return results;
                    })
                )
            }),
            tap(results => {
                this.resultsState = 'results';
                this.searchResults = results;
                this.analyticsService.logEvent("view_search_results", {
                    search_term: this.searchForm.value.query,
                    search_success: results.length > 0,
                })
            })
        );

        this.details.pipe(
            debounceTime(100),
        )
            .subscribe(searchResult => {

                this.detailState = 'none';

                if (searchResult.type === 'account' && ['All', 'Accounts'].includes(this.searchForm.get('type').value)) {
                    this.accountService.getAccountById(searchResult.id)
                        .subscribe(account => {
                            this.detailAccount = account;
                            this.detailState = 'account';
                        });
                } else if (searchResult.type === 'contact' && ['All', 'Contacts'].includes(this.searchForm.get('type').value)) {
                    this.contactService.getContactById(searchResult.id)
                        .subscribe(contact => {
                            this.detailContact = contact;
                            this.detailState = 'contact';
                        })
                } else if (searchResult.type === 'ticker' && ['All', 'Tickers'].includes(this.searchForm.get('type').value)) {
                    this.tickerService.getTickerDetails(searchResult.id)
                        .subscribe(ticker => {
                            this.detailTicker = ticker;
                            this.detailState = 'ticker';
                        })
                }
            });
    }

    showDetails(searchResult: SearchResult, index: number) {
        this.selectedIndex = index;
        this.detailState = 'none';
        this.details.emit(searchResult);
    }

    onClickResult(searchResult: SearchResult) {
        this.searchForm.get("query").patchValue('');
        if (searchResult.type === 'account') {
            this.router.navigate([AccountRoutePaths.AccountDetail, searchResult.id]);
        } else if (searchResult.type === 'contact') {
            this.router.navigate([ContactRoutePaths.ContactDetail, searchResult.id]);
        } else if (searchResult.type === 'ticker') {
            this.router.navigate([TickerRoutePaths.TickerDetail, searchResult.id]);
        }
    }

    addActivity(Id: number, accountId: number) {
        let modalRef = this.modalService.show(ActivityFormComponent, {
            animated: false,
            keyboard: false,
            backdrop: 'static',
            initialState: {
                contactIds: [Id],
                accountId: accountId,
            },
        });
    }

    addHolding(Id: number) {
        let modalRef = this.modalService.show(InterestFormComponent, {
            initialState: {contactId: Id},
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-md' });
    }

    @HostListener("keydown", ["$event"])
    navigateList(event: KeyboardEvent): void {
        if (event.key === "ArrowDown") {
            this.selectedIndex = this.selectedIndex + 1 <= this.searchResults.length - 1 ? this.selectedIndex + 1 : 0;
            this.showDetails(this.searchResults[this.selectedIndex], this.selectedIndex);
            this.triggerScrolling(this.selectedIndex);
        } else if (event.key === "ArrowUp") {
            this.selectedIndex = this.selectedIndex - 1 >= 0 ? this.selectedIndex - 1 : this.searchResults.length - 1;
            this.showDetails(this.searchResults[this.selectedIndex], this.selectedIndex);
            this.triggerScrolling(this.selectedIndex);
        } else if (event.key === "Enter") {
            this.onClickResult(this.searchResults[this.selectedIndex]);
        }
    }

    triggerScrolling(index: number): void {
        const item = this.resultElements.toArray()[index].nativeElement;
        const itemRect = item.getBoundingClientRect();
        const parentRect = item.parentElement.getBoundingClientRect();
        if (itemRect.bottom >= parentRect.bottom) {
            item.scrollIntoView(false);
        } else if (itemRect.top <= parentRect.top) {
            item.scrollIntoView(true);
        }
    }
}

interface SearchResult {
    id: any;
    text: string;
    text2: string;
    type: 'ticker' | 'contact' | 'account',
    selected: boolean;
}
