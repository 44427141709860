<style>
    .form-inline .form-group:not(:last-child) {
        margin-right: 5px;
    }
</style>

<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span></span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="eventSearchForm">
            <div class="form-group">
                <app-event-category-picker formControlName="category"></app-event-category-picker>
            </div>
            <div class="form-group">
                <input type="search"
                       class="form-control input-sm"
                       style="width: 200px;"
                       formControlName="searchNameAndLocation"
                       placeholder="Search Name & Location" />    
            </div>
            <div class="form-group">
                <app-analyst-picker formControlName="analyst"></app-analyst-picker>
            </div>
            <div class="form-group">
                <input type="search"
                       class="form-control input-sm"
                       style="width: 120px;"
                       formControlName="searchTicker"
                       placeholder="Search Ticker" />
            </div>
            <div class="form-group">
                <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
            </div>
            <div class="form-group" appIfUserFeature="EventAdmin">
                <button type="button" (click)="addEvent()" class="btn btn-primary btn-sm">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </form>
    </ng-template>
    
    <app-page-banner 
            pagetitle= "Equities Marketing Calendar" 
            faicon="fa-calendar-o" 
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">
        <full-calendar #calendar
            [options]="calendarOptions">
        </full-calendar>
    </div>
</div>
