import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {EventService} from "../../../Shared/Services/event.service";
import * as moment from "moment";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {combineLatest, of} from "rxjs";
import { getEventDisplayName } from "../../../../Helpers/DisplayStringHelper";
import {CalendarEvent} from "../../../../Components/Events/CalendarEvent";

@Component({
    selector: "app-event-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(EventPickerComponent)
})
export class EventPickerComponent extends BasePickerComponent<any> {

    @Input()
    multiple: boolean = true;
    
    @Input()
    closeOnSelect: boolean = false;
    
    @Input() 
    showTagCountLabel: boolean = true;

    @Input()
    showSelectClearAll: boolean = true;
    
    constructor(fb: UntypedFormBuilder, private eventService: EventService){
        super(fb);
        
        this.name = "Event(s)";

        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = false;
        
        this.customWriteValue = (obj: number) => {
            this.setupEvents(obj);
            this.pickerControl.patchValue(obj, { emitEvent: false });
        }
    }
    
    ngOnInit() {
        super.ngOnInit();

        this.setupEvents(0);
    }
    
    setupEvents(eventId: number): void {
        
        let event$ = eventId > 0 ?
                        this.eventService.getEvent(eventId) :
                        of(null);
        
        let events$ = this.eventService.searchEvents(moment().add(-3, 'months'), moment().add(24, 'months')).pipe(
            map(events => _.chain(events)
                .filter(e => e.Id > 0)
                .value()
            )
        );
        
        this.items$ = combineLatest([events$, event$]).pipe(
            map(([events, event]) => {
                if (event && !events.map(e => e.Id).includes(event.Id)) {
                    events.push({ Id: event.Id, IsActive: event.IsActive, Name: event.Name } as CalendarEvent);
                }
                if (!this.multiple) {
                    events.unshift({ Id: null, IsActive: true, Name: ' ' } as CalendarEvent);
                }

                return _.chain(events)
                    .sortBy(e => e.Name)
                    .map(e => { return { Id: e.Id, Name: getEventDisplayName(e)}})
                    .value();
            })
        );
    }
}
