import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as  _ from "lodash";
import {Feature} from "../../../../Models/Feature";
import {UserService} from "../../../Shared/Services/user.service";
import {ConfigService} from "../../../Shared/Services/config.service";
import {User} from "../../../../Models/User";
import { map } from "rxjs/operators";
import {ActivityService} from "../../../Shared/Services/activity.service";

@Component({
    selector: "app-activity-category-picker",
    templateUrl : "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ActivityCategoryPickerComponent)
})
export class ActivityCategoryPickerComponent extends BasePickerComponent<any> implements OnInit, OnChanges {

    @Input()
    searchable: boolean = true;

    @Input()
    placeHolder: string = "Cat(s)";

    @Input()
    alignRight: boolean = false;

    @Input()
    categories: string[];

    @Input()
    excludeCategories: string[];

    @Input()
    name: string = "Cat(s)"

    @Input()
    groupByFn: (item: { Id: string; Name: string }) => string;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    categoryByRole: boolean = false;

    @Input()
    showCorpTravelActivityTypes: boolean = false;

    user: User;

    constructor(fb: UntypedFormBuilder,
                private userService: UserService,
                private activityService: ActivityService,
                private configService: ConfigService) {
        super(fb);

        this.name = "Cat(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }

    ngOnChanges() {
        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
                this.setupPickerItems(null);
            });
    }

    ngOnInit() {
        super.ngOnInit();
        this.showSelectClearAll = this.selectClearAll;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
                this.setupPickerItems(null);
            });

        this.customWriteValue = (codes: string | string[]) => {

            if (!Array.isArray(codes)) {
                this.setupPickerItems(codes);
            }

            this.pickerControl.patchValue(codes);
        }
    }

    private setupPickerItems(code: string): void {

        let categories$ = this.activityService.getActivityCategories();

        this.items$ = categories$.pipe(
            map((categories) => {
                let categoriesToDisplay = [...categories];

                if (this.categoryByRole) {
                    categoriesToDisplay = categories
                        .filter(c => c.UserHasAccess)
                        .filter(c => this.excludeCategories ? !this.excludeCategories.includes(c.Code) : true)
                        .filter(c => !this.showCorpTravelActivityTypes ? !['A', 'K'].includes(c.Code) : true);
                }

                if (code && !categoriesToDisplay.map(c => c.Code).includes(code)) {
                    categoriesToDisplay.push(
                        categories.find(c => c.Code === code) ?? {
                            Code: code,
                            Description: "Unknown Category",
                            UserHasAccess: undefined
                        }
                    );
                }

                return _.chain(categoriesToDisplay)
                    .filter(c => this.categories ? _.includes(this.categories, c.Code) : true)
                    .sortBy(c => c.Description)
                    .map(c => {
                        return {
                            Id: c.Code,
                            Name: `${c.Code} - ${c.Description}`
                        };
                    })
                    .value();
            })
        );
    }
}
