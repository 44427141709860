<div [formGroup]="queryFiltersForm" *ngIf="schema">
    <div formArrayName="queryGroups">
        <div *ngFor="let queryGroup of queryFiltersForm.get('queryGroups').controls; let i = index;" [formGroupName]="i" class="query-group">
            <div formArrayName="queryCriteria">
                <div *ngFor="let queryCriteria of queryGroup.get('queryCriteria').controls; let j = index" class="query-criteria">
                    <label>{{ i === 0 && j === 0 ? 'Where' : j === 0 ? 'And' : 'Or' }}</label>
                    <app-query-criteria [schema]="schema" [criteriaFormGroup]="queryCriteria" class="criteria"></app-query-criteria>
                    <div class="actions">
                        <button type="button" class="btn btn-sm btn-danger" (click)="deleteCriteria(i, j)">
                            <span class="fa fa-trash"></span>
                        </button>
                        <button type="button" class="btn btn-sm btn-default" (click)="addCriteria(i)">Or</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <button type="button" class="btn btn-sm btn-default" (click)="addQueryGroup()">And</button>
</div>