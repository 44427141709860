import {Component, OnInit} from "@angular/core";
import {ColDef, GridOptions} from "ag-grid-community";
import {defaultColumnDef, defaultGridOptions, getGridDisplayWords} from "../../Shared/ag-grid-options";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {combineLatest} from "rxjs";
import {MifidService} from "./mifid-accounts.service";
import {MifidAccount} from "./accounts";
import {map, startWith} from "rxjs/operators";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {MifidAccountFormComponent} from "./mifid-account-form.component";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";

@Component({
    selector: "app-admin-mifid-accounts",
    templateUrl: "./mifid-accounts.component.html"
})
export class MifidAccountsComponent extends BaseGridComponent<MifidAccount> implements OnInit {

    columnDefs: ColDef[] = [
        {headerName: 'Account', field: 'AccountName', sort: 'asc', flex: 1,
            cellRenderer: (params) => `<a>${params.value}</a>` },
        {headerName: 'Email Recipients', field: 'EmailRecipients', sortable: false, flex: 1 },
        {headerName: 'Monikers', field: 'Monikers', sortable: false, flex: 1 },
        {headerName: 'Delivery Preference', field: 'DeliveryPreference', sortable: false, flex: 1 }
    ];

    bsModalRef: BsModalRef;
    config: ModalOptions<MifidAccountFormComponent> = { ignoreBackdropClick: true, backdrop: true, initialState: {}};

    mifidSearchBox: UntypedFormControl = this.fb.control('');

    gridOptions: GridOptions = defaultGridOptions;
    defaultColDef = defaultColumnDef;

    constructor(
        private fb: UntypedFormBuilder,
        private mifidService: MifidService,
        private modalService: BsModalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.loadData();
    }

    openAccountform(MifidAccount: MifidAccount) {
        this.config.initialState = {mifidAccount: MifidAccount} as Partial<MifidAccountFormComponent>;
        this.bsModalRef = this.modalService.show(MifidAccountFormComponent, this.config);

        this.bsModalRef.content.dataUpdated.subscribe((isDataUpdated) => {
            this.loadData();
        });
    }

    loadData(): void {
         const account$ = this.mifidService.getMifidAccounts().pipe(map(accounts => accounts.map(a => {
             a.AccountName = `${a.AccountName} (${a.AccountId})`;
             return a;
         })));

        const formUpdate$ = this.mifidSearchBox.valueChanges.pipe(startWith(''));

        this.rowData$ = combineLatest([account$, formUpdate$]).pipe(
            map(([accounts, searchTerm]) => accounts.filter(a => objectSearchPredicate(a, searchTerm)))
        );
    }

    onCellClicked(event: any) {

        if(event.colDef.field === "AccountName"){
            this.openAccountform(event.data);
        }
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }
}
