<div class="modal-content request-attendee-form">
    <div class="modal-header">
        <div class="flex-hbox">
            <div>
                <div style="margin-bottom: 5px;" *ngIf="requestContact"><h3
                        class="modal-title">View Attendance
                    for {{requestContact.LastName}}, {{requestContact.FirstName}}
                    ({{requestContact.Account}}) </h3></div>
            </div>
        </div>
    </div>

    <div class="modal-body">
        <tabset>
            <tab heading="Attendance">
                <div class="flex-hbox request-attendance-header">
                    <div class="flex-1">
                        Date
                    </div>
                    <div class="flex-1">
                        Time {{timeZone}}
                    </div>
                    <div class="flex-1">
                        Meeting Type
                    </div>
                    <div class="flex-1">
                        Status
                    </div>
                    <div class="flex-1">
                        Sub Category
                    </div>
                </div>
                <div class="form-group flex-hbox request-attendance-row"
                     *ngFor="let requestEventMeeting of requestEventMeetingsNoReplay">
                    <div class="flex-1">
                        {{requestEventMeeting.Date}}
                    </div>
                    <div class="flex-1">
                        {{requestEventMeeting.Time}}
                    </div>
                    <div class="flex-1">
                        {{requestEventMeeting.MeetingType}}
                    </div>
                    <div class="flex-1">
                        {{requestEventMeeting.Status}}
                    </div>
                    <div class="flex-1">
                        {{requestEventMeeting.Subcategory}}
                    </div>
                </div>
            </tab>
            <tab heading="Replays">
                <div class="flex-hbox request-attendance-header">
                    <div class="flex-2">
                        Date
                    </div>
                    <div class="flex-2">
                        Time {{timeZone}}
                    </div>
                    <div class="flex-1">
                        Replay Date
                    </div>
                </div>
                <div class="form-group flex-hbox request-attendance-row"
                     *ngFor="let requestEventMeeting of requestEventMeetingsReplay">
                    <div class="flex-2">
                        {{requestEventMeeting.Date}}
                    </div>
                    <div class="flex-2">
                        {{requestEventMeeting.Time}}
                    </div>
                    <div class="flex-1">
                        {{requestEventMeeting.ReplayDate}}
                    </div>
                </div>
            </tab>
        </tabset>
    </div>

    <div class="modal-footer">
        <div>
            <button type="button" class="btn btn-default btn-sm" (click)="close()">Close</button>
        </div>
    </div>
</div>