<div class="modal-content">
    <form [formGroup]="contactReassignForm" id="contactReassignForm">
        <div class="modal-header">
            <h3 class="modal-title">
                Reassign Activities
            </h3>
        </div>
        <div class="modal-body">
            <div class="flex-vbox" style="padding: 10px">
                <div class="flex-hbox"><label>From:&nbsp;</label><div>{{contactName}}</div></div>
                <label>Choose New Contact:</label>
                <app-multi-contact-select formControlName="contact" 
                                          [showEditButtons]="false" 
                                          [contactRequired]="true"
                                          [maxItems]="1" 
                                          [showContactId]="true">
                </app-multi-contact-select>
            </div>
            <div class="flex-1 flex-vbox" style="padding: 10px">
                <label>Account Override:</label>
                <ag-grid-angular
                        style="width: 100%; height: 300px"
                        class="ag-theme-balham"
                        [gridOptions]="gridOptions"
                        [rowData]="rowData$ | async"
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        (gridReady)="onGridReady($event)"
                >
                </ag-grid-angular>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox">
                <button class="btn btn-sm btn-primary flex" (click)="save()" [disabled]="!formValid()">Reassign</button>
                <button class="btn btn-sm btn-default flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>