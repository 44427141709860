import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Observable, of} from "rxjs";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {UserTeam, UserTeamAdminService} from "./user-team-admin.service";

@Component({
    selector: "app-admin-user-team-form",
    templateUrl: "./user-team-form.component.html"
})
export class UserTeamFormComponent implements OnInit {

    title: string;
    userTeamId: number;

    userTeam: UserTeam;
    canDelete: boolean = false;

    userTeamForm: UntypedFormGroup = this.fb.group({
        description: this.fb.control('', [Validators.required]),
        parentId: this.fb.control(null),
        isActive: this.fb.control(true),
    });

    parentTeamIds: Observable<any[]> = of([]);

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private userTeamAdminService: UserTeamAdminService,
                private toastr: ToastrService) { }

    ngOnInit(): void {

        this.title = `${ this.userTeamId ? "Edit" : "Add" } User Team`;

        this.userTeamAdminService.getAllUserTeams()
            .subscribe(userTeams => {
                let parentTeams = [];
                parentTeams.push({ ParentId: null, Description: '-- None --' });
                parentTeams.push(...userTeams
                    .filter(ut => !ut.ParentId)
                    .filter(ut => ut.Id !== this.userTeamId)
                    .sort((a, b) => a.Description < b.Description ? -1 : 1)
                    .map(ut => { return { ParentId: ut.Id, Description: ut.Description } }));
                this.parentTeamIds = of(parentTeams);

                this.initializeFormValues();
            });
    }

    initializeFormValues(): void {
        if (this.userTeamId >= 0) {
            this.userTeamAdminService.getUserTeamById(this.userTeamId)
                .subscribe(userTeam => {
                    this.userTeam = userTeam;

                    this.userTeamForm.patchValue({
                        description: userTeam.Description,
                        parentId: userTeam.ParentId,
                        isActive: userTeam.IsActive,
                    });
                })
        }
    }

    close(): void {
        this.modalRef.hide();
    }

    submit() {
        if (this.userTeamForm.valid) {
            let formVals = this.userTeamForm.value;
            let userTeam = {
                Id: this.userTeamId,
                Description: formVals.description,
                ParentId: formVals.parentId,
                IsActive: formVals.isActive,
                ActiveUserIds: [],
            } as UserTeam;

            this.userTeamAdminService.saveUserTeam(userTeam)
                .subscribe(_ => {
                    this.toastr.success('User Team Saved');
                    this.dataUpdated.emit(true);
                    this.close();
                });
        }
    }

    delete() {
        const initialState = {
            message: `Are you sure you want to delete the team "${this.userTeam.Description}"`,
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.userTeamAdminService.deleteUserTeam(this.userTeam.Id)
                        .subscribe(() => {
                            this.toastr.success("User Team Deleted");
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        });
                }
            });
    }
}
