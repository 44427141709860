import {Injectable} from "@angular/core";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {Holding} from "../../../Models/Holding";
import {QueryGroup} from "../../../Services/QueryService";
import * as _ from "lodash";

@Injectable({
    providedIn: 'root',
})
export class HoldingService {

    constructor(private baseHttp: BaseHttpService) { }
    
    getHoldingCategories(): Observable<HoldingCategory[]> {
        return of([
            { Id: "H", Name: "Holdings" },
            { Id: "I", Name: "Interested" },
            { Id: "N", Name: "Not Interested" },
        ]);
    }
    
    getHolding(holdingId: number): Observable<Holding> {
        return this.baseHttp.getData(`/holdings/${holdingId}`)
    }

    getHoldingsByIds(holdingIds: number[]): Observable<Holding[]> {
        return this.baseHttp.getData(`/holdings`)
    }
    
    getContactHoldings(contactId: number): Observable<Holding[]>{
        return this.baseHttp.getData(`/contact/${contactId}/holdings`)
    }

    searchHoldings(query: QueryGroup[]): Observable<Holding[]> {
        const params = { QueryGroups: query };
        return this.baseHttp.postData(`/holding/query`, params);
    }
    
    downloadHoldingsWithQuery(query: QueryGroup[], fileName: string): Observable<Holding[]> {
        const params = { QueryGroups: query };
        return this.baseHttp.getFileFromPost(`/holding/query/download`, fileName, FileAcceptType.Excel, params);
    }
    
    createHoldings(holdings: Holding[]): Observable<any>{
        return this.baseHttp.postData(`/holdinglist`, holdings);
    }
    
    updateHoldings(holdings: Holding[]): Observable<Holding[]> {
        return this.baseHttp.putData(`/holdings`, holdings);
    }

    deleteHoldings(holdingIds: number[]): Observable<any[]> {
        
        let chunks$ = _.chain(holdingIds)
            .chunk(100)
            .map(hids => this.baseHttp.deleteDataWithConfig(`/holdingids`, { params: { ids: hids.map(hid => hid.toString()) }}))
            .value();
            
        return combineLatest(chunks$);
        
        //return this.baseHttp.deleteDataWithConfig(`/holdingids`, { params: { ids: holdingIds.map(hid => hid.toString()) }})
    }
}

export interface HoldingCategory {
    Id: string;
    Name: string;
}