import {Routes} from "@angular/router";
import {AccountListComponent} from "./AccountList/account-list.component";
import {AccountDetailComponent} from "./AccountDetail/account-detail.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {AccountRoutePaths} from "./account-route-paths";
import {MsalGuard} from "@azure/msal-angular";

export const accountRoutes: Routes = [
    {
        path: AccountRoutePaths.AccountList,
        component: AccountListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Accounts",
        },
    },
    {
        path: `${AccountRoutePaths.AccountDetail}/:id`,
        component: AccountDetailComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Account Details",
        },
    },
];
