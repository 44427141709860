import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {QueryGroup, QueryPredicate} from "../../../Services/QueryService";
import * as _ from "lodash";
import {QueryService} from "../../Shared/Services/query.service";
import type {QuerySchema} from "../../Shared/Services/query.service";

@Component({
    selector: "app-query-groups-input",
    templateUrl: "./query-groups-input.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QueryGroupsInputComponent),
            multi: true
        }
    ]
})
export class QueryGroupsInputComponent implements  OnInit, ControlValueAccessor {

    
    queryFiltersForm: UntypedFormGroup = this.fb.group({
        queryGroups: this.fb.array([])
    });
    
    @Input()
    schema: QuerySchema;

    onChange = (queryGroups: QueryGroup[]) => {};
    onTouched = () => {};

    disabled: boolean = false;

    constructor(private fb: UntypedFormBuilder, 
                private userService: UserService,
                private queryService: QueryService
    ){}

    ngOnInit(): void {
        
        if (!this.schema) {
            this.queryService.getQuerySchema()
                .subscribe(schema => {
                    this.schema = schema;
                });
        }
        
        this.queryFiltersForm.valueChanges
            .subscribe(formValue => {
                let formGroups = formValue.queryGroups
                    .map(qg => {
                        return {
                            Queries: qg.queryCriteria.map(c => {
                                    return {
                                        Object: c.object,
                                        Field: c.field,
                                        Operation: c.operation,
                                        Value: c.value,
                                        Values: c.values && _.isString(c.values) && c.values.split(',') ||
                                            c.values ||
                                            []
                                    }
                                })
                                .filter(c => c.Operation === "is" || c.Operation === "is not" || !_.isEmpty(c.Value) || !_.isEmpty(c.Values))
                        }
                    })
                    .filter(qg => qg.Queries.length > 0);
                this.onChange(formGroups);
            });
    }

    registerOnChange(fn: (queryGroups: QueryGroup[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(groups: QueryGroup[]): void {
        this.queryFiltersForm.patchValue({
            queryGroups: []
        });
        _.forEach(groups, g => {
            let group = this.fb.group({
                queryCriteria: this.fb.array([])
            });

            _.forEach(g.Queries, q => {
                (group.controls["queryCriteria"] as UntypedFormArray)
                    .push(this.createQueryCriteria(q.Object, q.Field, q.Operation, q.Value, q.Values));
            });

            (this.queryFiltersForm.get('queryGroups') as UntypedFormArray).push(group);
        })
    }

    addQueryGroup(): void {
        (this.queryFiltersForm.get('queryGroups') as UntypedFormArray).push(this.createQueryGroup());
        //this.queryGroups.push(this.createQueryGroup());
    }

    createQueryGroup(): UntypedFormGroup {
        return this.fb.group({
            queryCriteria: this.fb.array([this.createInitialQueryCriteria()])
        });
    }

    createInitialQueryCriteria(): UntypedFormGroup {
        return this.createQueryCriteria('','','','',[]);
    }

    createQueryCriteria(object: string, field: string, operation: string, value: any, values: any[]): UntypedFormGroup {
        return this.fb.group({
            object: this.fb.control(object),
            field: this.fb.control(field),
            operation: this.fb.control(operation),
            value: this.fb.control(value),
            values: this.fb.control(values)
        });
    }

    deleteCriteria(groupIndex: number, criteriaIndex: number): void {

        let groups = ((this.queryFiltersForm.get('queryGroups') as UntypedFormArray).controls as UntypedFormGroup[]);

        let criteria = ((groups[groupIndex].controls["queryCriteria"] as UntypedFormArray).controls as UntypedFormGroup[]);

        if (criteria.length > 1) {
            (groups[groupIndex].controls["queryCriteria"] as UntypedFormArray).removeAt(criteriaIndex);
        } else {
            (this.queryFiltersForm.get('queryGroups') as UntypedFormArray).removeAt(groupIndex);
        }
    }

    addCriteria(groupIndex: number): void {
        let groups = (this.queryFiltersForm.get('queryGroups') as UntypedFormArray).controls as UntypedFormGroup[];

        (groups[groupIndex].controls["queryCriteria"] as UntypedFormArray).push(this.createInitialQueryCriteria());
    }
}
