import {Component, OnInit, OnDestroy, ViewEncapsulation} from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {User} from "../../../Models/User";
import {ConfigService} from "../../Shared/Services/config.service";
import * as _ from "lodash";
import {ReportService} from "../../Shared/Services/report.service";
import {Router, RoutesRecognized} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {filter, map, takeUntil, tap} from "rxjs/operators";
import {AdminRoutePaths} from "../../Admin/admin-route-paths";
import {AccountRoutePaths} from "../../Account/account-route-paths";
import {ActivityRoutePaths} from "../../Activity/activity-route-paths";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";
import {CorpTravelAdminRoutePaths} from "../../CorpTravelAdmin/corp-travel-admin-route-paths";
import {DashboardRoutePaths} from "../../Dashboard/dashboard-route-paths";
import {EmailRoutePaths} from "../../Email/email-route-paths";
import {EventRoutePaths} from "../../Event/event-route-paths";
import {InterestRoutePaths} from "../../Interest/interest-route-paths";
import {QueryRoutePaths} from "../../Query/query-route-paths";
import {BaseRoutePaths} from "../../../app-routing.module";
import {EntitlementRoutePaths} from "../../Entitlements/entitlements-route-paths";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {AuthenticationResult, EventMessage, EventType, InteractionStatus} from "@azure/msal-browser";
import {Subject} from "rxjs";

@Component({
    selector: "app-shell",
    templateUrl: "./shell.component.html",
    styleUrls: ["./shell.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ShellComponent implements OnInit, OnDestroy {
    canSalesDashboardUserFeature: string = "DashboardSales";

    open: boolean = false;
    navLinks: NavLink[] = [];
    buildVersion;

    instiselUpdateUrl: string = this.configService.getInstiselUpdateUrl();
    powerBIReportsUrl = this.configService.getPowerBIReportsUrl();

    hideShell: boolean = false;
    private readonly _destroying$ = new Subject<void>();

    loadingCurrentUser: boolean = true;

    constructor(
        private userService: UserService,
        private configService: ConfigService,
        private reportService: ReportService,
        private titleService: Title,
        private router: Router,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService
    ) {
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };
    }

    ngOnInit() {
        this.buildVersion = this.configService.getBuildVersion();

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {

                if (this.authService.instance.getAllAccounts().length > 0) {
                    this.setPageTitleAndShellVisibility();
                    this.userService.getCurrentUser()
                        .subscribe(user => {
                            if (user.Id) {
                                this.configureShell(user);
                                this.loadingCurrentUser = false;
                            }
                        });
                }
            });
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private setPageTitleAndShellVisibility(): void {
        const titlePrefix = 'Instisel';

        this.router.events.pipe(
            filter(event => event instanceof RoutesRecognized),
            tap((event: RoutesRecognized) => {
                this.hideShell = !!event.state.root.firstChild.data.hideShell;
            }),
            map((event) => {
                let routeSnapshot = event.state.root;

                while (routeSnapshot.firstChild) {
                    routeSnapshot = routeSnapshot.firstChild;
                }

                let title = titlePrefix;

                if (!!routeSnapshot.data.title) {
                    title += ` - ${routeSnapshot.data.title}`;
                }

                return title;
            }),
        ).subscribe((title: string) => {
            this.titleService.setTitle(title);
        });
    }

    configureShell(user: User): void {
        let adminNavLink = this.getAdminLink(user);

        this.navLinks = [
            {
                Name: "Home",
                Route: BaseRoutePaths.Home,
                Url: "",
                Icon: "fa-home",
                SubNavs: []
            },
            ...(
                this.canSeeSalesDashboard(user) ?
                    [{
                        Name: "Dashboard",
                        Route: DashboardRoutePaths.SalesDashboard,
                        Url: "",
                        Icon: "fa-tachometer",
                        SubNavs: [{
                            Name: "Sales Dashboard",
                            Route: DashboardRoutePaths.SalesDashboard,
                            Url: ""
                        }]
                    }] : []
            ),
            {
                Name: "Accounts",
                Route: AccountRoutePaths.AccountList,
                Url: "",
                Icon: "fa-sitemap",
                SubNavs: []
            }, {
                Name: "Contacts",
                Route: ContactRoutePaths.Contacts,
                Url: "",
                Icon: "fa-users",
                SubNavs: []
            }, {
                Name: "Contact Lists",
                Route: ContactRoutePaths.ContactLists,
                Url: "",
                Icon: "fa-address-book",
                SubNavs: []
            }, {
                Name: "Activities",
                Route: ActivityRoutePaths.Activities,
                Url: "",
                Icon: "fa-comments",
                SubNavs: [
                    {
                        Name: "Activities",
                        Route: ActivityRoutePaths.Activities,
                        Url: ""
                    },
                    ...(
                        user.Features.includes(UserFeatureName.EES) ?
                            [{
                                Name: "EES",
                                Route: ActivityRoutePaths.EesActivityList,
                                Url: ""
                            }] : []
                    )
                ]
            }, {
                Name: "Call List",
                Route: InterestRoutePaths.CallList,
                Url: "",
                Icon: "fa-phone",
                SubNavs: []
            }, {
                Name: "Interests",
                Route: ContactRoutePaths.InterestList,
                Url: "",
                Icon: "fa-line-chart",
                SubNavs: []
            }, {
                Name: "Reports",
                Route: this.reportService.getDefaultReportForUser(user).Link,
                Url: "",
                Icon: "fa-bar-chart",
                SubNavs: [
                    ...this.reportService.getReportList(user).map(r => {
                        return {
                            Name: r.Name,
                            Route: r.Link,
                            Url: ""
                        }
                    }),
                    {
                        Name: "Power BI Reports",
                        Route: "",
                        Url: this.powerBIReportsUrl
                    },
                ]
            }, {
                Name: "Marketing Calendar",
                Route: EventRoutePaths.Events,
                Url: "",
                Icon: "fa-calendar-o",
                SubNavs: [
                    {
                        Name: "Calendar",
                        Route: EventRoutePaths.EventCalendar,
                        Url: ""
                    }, {
                        Name: "List",
                        Route: EventRoutePaths.BasePath,
                        Url: ""
                    }, {
                        Name: "Registrations",
                        Route: EventRoutePaths.EventRegistrationList,
                        Url: ""
                    }, {
                        Name: "Corp Access/Conf Reports",
                        Route: "",
                        Url: this.powerBIReportsUrl
                    },
                ]
            }, {
                Name: "Email",
                Route: EmailRoutePaths.Readership,
                Url: "",
                Icon: "fa-envelope",
                SubNavs: this.getEmailSubNavs(user)
            },
            ...(
                user.Features.includes(UserFeatureName.QueryBuilder) ?
                    [{
                        Name: "Query Builder",
                        Route: QueryRoutePaths.QueryList,
                        Url: "",
                        Icon: "fa-search-plus",
                        SubNavs: []
                    }] : []
            ),
            ...(!!adminNavLink ? [adminNavLink] : []),
            ...(
                user.Features.includes(UserFeatureName.CorpTravelAdmin) ?
                    [{
                        Name: "Corp Travel Admin",
                        Route: CorpTravelAdminRoutePaths.CorpTravelAdminDashboard,
                        Url: "",
                        Icon: "fa-plane",
                        SubNavs: []
                    }] : []
            ),
        ];
    }

    private getEmailSubNavs(user: User): SubNavLink[] {
        let emailSubNavs: SubNavLink[] = [];

        if (user.Features.includes(UserFeatureName.MailMerge)) {
            emailSubNavs.push({
                Name: "Compose Email",
                Route: EmailRoutePaths.ComposeEmail,
                Url: ""
            });
        }

        emailSubNavs.push({
            Name: "Readership",
            Route: EmailRoutePaths.Readership,
            Url: ""
        });

        if (user.Features.includes(UserFeatureName.SharedEmails)) {
            emailSubNavs.push({
                Name: "Shared Emails",
                Route: EmailRoutePaths.SharedEmails,
                Url: ""
            });
        }
        return emailSubNavs;
    }

    private getAdminLink(user: User): NavLink {
        let adminLink: NavLink;
        if (user.Features.includes(UserFeatureName.IsAdmin)) {
            adminLink = {
                Name: "Admin",
                Route: AdminRoutePaths.AdminDashboard,
                Url: "",
                Icon: "fa-gear",
                SubNavs: this.getAdminSubNavs(user)
            };
        } else if (user.Features.includes(UserFeatureName.InstiselUpdate)) {
            adminLink = {
                Name: "Admin",
                Route: "",
                Url: this.instiselUpdateUrl,
                Icon: "fa-gear",
                SubNavs: []
            };
        }
        return adminLink;
    }

    private getAdminSubNavs(user: User): SubNavLink[] {
        let adminSubNavs: SubNavLink[] = [];

        adminSubNavs = [
            {
                Name: "Users",
                Route: AdminRoutePaths.UserList,
                Url: ""
            },
            {
                Name: "User Groups",
                Route: AdminRoutePaths.UserGroups,
                Url: ""
            },
            {
                Name: "List Groups",
                Route: AdminRoutePaths.ListGroups,
                Url: ""
            },
        ];

        if (user.Features.includes(UserFeatureName.InstiselUpdate)) {
            adminSubNavs.push({
                Name: "Instisel Update",
                Route: "",
                Url: this.instiselUpdateUrl
            });
        }
        return adminSubNavs;
    }

    private canSeeSalesDashboard(user: User): boolean {
        return _.includes(user.Features, this.canSalesDashboardUserFeature);
    }
}

interface NavLink {
    Name: string;
    Route: string;
    Url: string;
    Icon: string;
    SubNavs: SubNavLink[];
}

interface SubNavLink {
    Name: string;
    Route: string;
    Url: string;
}
