import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {AccountService} from "../../Shared/Services/account.service";
import {ToastrService} from "ngx-toastr";
import {debounceTime} from "rxjs/operators";

@Component({
    selector: "app-account-note",
    templateUrl: "./account-notes.component.html"
})

export class AccountNotesComponent implements OnInit {

    @Input()
    accountId: number;

    @Input()
    noteType: string;

    note = this.fb.control('');

    constructor(private fb: UntypedFormBuilder,
                private accountService: AccountService,
                private toastr: ToastrService) {}

    ngOnInit(): void {
        this.accountService.getAccountNote(this.accountId, this.noteType).subscribe(notes => {
            this.note.patchValue(notes);

            this.note.valueChanges.pipe(
                debounceTime(1000)
            ).subscribe(noteValue => {
                this.accountService.updateAccountNote(this.accountId, this.noteType, noteValue).subscribe(() => {
                    this.toastr.success(`Saved ${this.noteType} Note`, "Success");
                });
            });
        });
    }
}
