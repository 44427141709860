export class ReportRoutePaths {
  static readonly BasePath: string = "reports";

  static readonly AccountActivityReportPathFragment: string = "accountactivity";
  static readonly ChecksReportPathFragment: string = "checks";
  static readonly CommissionSplitsReportPathFragment: string = "splitcommission";
  static readonly CommissionTotalsReportPathFragment: string = "commissiontotal";
  static readonly AccountSummaryByYearReportPathFragment: string = "accountsummarybyyear";
  static readonly PrintReportsPathFragment: string = "printreports";
  static readonly TradeDetailReportPathFragment: string = "tradedetail";
  static readonly ParentTradeDetailReportPathFragment: string = "tradedetail";
  static readonly YearOverYearCommissionReportPathFragment: string = "yoy";
  static readonly ParentYearOverYearCommissionReportPathFragment: string = "yoy";

  static readonly AccountActivityReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.AccountActivityReportPathFragment}`;
  static readonly ChecksReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.ChecksReportPathFragment}`;
  static readonly CommissionSplitsReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.CommissionSplitsReportPathFragment}`;
  static readonly CommissionTotalsReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.CommissionTotalsReportPathFragment}`;
  static readonly AccountSummaryByYearReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.AccountSummaryByYearReportPathFragment}`;
  static readonly PrintReports: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.PrintReportsPathFragment}`;
  static readonly TradeDetailReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.TradeDetailReportPathFragment}`;
  static readonly ParentTradeDetailReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.ParentTradeDetailReportPathFragment}`;
  static readonly YearOverYearCommissionReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.YearOverYearCommissionReportPathFragment}`;
  static readonly ParentYearOverYearCommissionReport: string = `${ReportRoutePaths.BasePath}/${ReportRoutePaths.ParentYearOverYearCommissionReportPathFragment}`;
}
