import {Component} from "@angular/core";

@Component({
    selector: "app-page-not-found",
    template: `
        <div>
            <h3 style="margin-left: 20px">
                Page Not Found
            </h3>
        </div>
    `
})
export class PageNotFoundComponent {
    constructor() { }
}
