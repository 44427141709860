import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {UntypedFormBuilder} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {QueryDto, QueryGroup} from "../../../Services/QueryService";
import {QueryService} from "../../Shared/Services/query.service";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {map} from "rxjs/operators";

@Component({
    selector: "app-query-select-modal",
    templateUrl: "./query-select-modal.component.html"
})
export class QuerySelectModalComponent extends BaseGridComponent<QueryDto> implements OnInit {

    @Input()
    exportObject: string;

    @Output()
    queryGroupsSelected = new EventEmitter<QueryGroup[]>();

    columnDefs = [
        {field: 'Name', headerName: 'Name', sort: 'asc', flex: 2 },
        {field: 'Description', headerName: 'Description', flex: 2 },
        {field: 'ListGroupPermission', headerName: 'Permission', flex: 1, valueGetter: this.renderPermission },
        {field: 'Query.ExportObject', headerName: 'Select', flex: 1 },
        {field: 'CreatedBy', headerName: 'Created By', flex: 1 },
        {field: 'UpdatedBy', headerName: 'Modified By', flex: 1, valueGetter: (p) => p.data.UpdatedBy || '' },
        {field: 'UpdatedAt', headerName: 'Modified Date', flex: 1, valueGetter: (p) => p.data.UpdatedAt || p.data.CreatedAt , cellRenderer: dateRenderer }
    ];

    form = this.fb.group({
        search: this.fb.control(''),
        queries: this.fb.control([]),
    });

    constructor(public modalRef: BsModalRef,
                private queryService: QueryService,
                private fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.rowSelection = "single";

        this.queryService.getAllQueries()
            .subscribe(queries => {
                this.form.patchValue({
                    queries: queries
                });
            });

        this.rowData$ = this.form.valueChanges.pipe(
            map(formValues => formValues.queries.filter(q => !formValues.search || q.Name.toLowerCase().indexOf(formValues.search.toLowerCase()) > -1))
        );
    }

    select(): void {

        let rows = this.gridApi.getSelectedRows();
        if (rows.length > 0){
            this.queryGroupsSelected.emit(rows[0].Query.QueryGroups);
        }

        this.modalRef.hide();
    }

    cancel(): void {
        this.modalRef.hide();
    }

    renderPermission(params: any): string {
        return params.data.ListGroupPermission === 'View' ? 'Group View'
            : params.data.ListGroupPermission === 'Edit' ? 'Group View - Edit'
                : 'Private';
    }
}
