import {Component, OnInit, Output, EventEmitter, ViewEncapsulation} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { NotificationService } from "../../Shared/Services/notification.service";
import * as moment from "moment";
import { forkJoin, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { DocumentsService } from "../../Shared/Services/documents.service";
import { Document } from "../../../Models/Document";
import * as _ from "lodash";

@Component({
    selector: "app-manage-notifications-documents-modal",
    templateUrl: "./manage-notifications-documents-modal.component.html",
    styleUrls: ["./manage-notifications-documents-modal.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ManageNotificationsDocumentsModalComponent implements OnInit {

    @Output()
    notificationsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    manageGroup: UntypedFormGroup = this.fb.group({
        notifications: this.fb.array([]),
        documents: this.fb.group({
            whatsnew: this.fb.control(null),
            userguide: this.fb.control(null),
            outlookguide: this.fb.control(null)
        })
    });

    get notifications(): UntypedFormArray {
        return this.manageGroup.get("notifications") as UntypedFormArray;
    }

    get documents(): UntypedFormGroup {
        return this.manageGroup.get("documents") as UntypedFormGroup;
    }

    constructor(
        public bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private notificationService: NotificationService,
        private documentService: DocumentsService,
        private toastrService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.notificationService.getNotifications().subscribe(notifications => {
            _.orderBy(notifications, n => moment(n.NotificationDate), "desc")
                .forEach(notification => {
                    this.notifications.push(this.fb.group({
                        Id: this.fb.control(notification.Id),
                        NotificationDate: this.fb.control(moment(notification.NotificationDate).format("yyyy-MM-DD")),
                        Description: this.fb.control(notification.Description),
                        Deleted: this.fb.control(false)
                    }));
                });
        });
    }

    addNotification(): void {
        const newNotification = this.fb.group({
            NotificationDate: this.fb.control(moment().format("yyyy-MM-DD")),
            Description: this.fb.control(""),
            Deleted: this.fb.control(false)
        });
        this.notifications.insert(0, newNotification);
    }

    deleteNotification(index: number): void {
        const notification = this.notifications.at(index);
        notification.get("Deleted").patchValue(true);
        notification.markAsDirty();
    }

    saveNotifications(): void {
        const saveActions: Observable<any>[] = [];
        this.notifications.controls.forEach((control, i) => {
            if (!control.dirty || !control.value.Description) {
                return;
            }
            if (control.value.Id) {
                if (control.value.Deleted) {
                    const action = this.notificationService.deleteNotification(control.value.Id).pipe(
                        tap(() => {
                            this.notifications.removeAt(i);
                        })
                    );
                    saveActions.push(action);
                } else {
                    const action = this.notificationService.updateNotification(control.value);
                    saveActions.push(action);
                }
            } else {
                const action = this.notificationService.addNotification(control.value).pipe(
                    tap(newNotification => {
                        (control as UntypedFormGroup).addControl("Id", this.fb.control(newNotification.Id));
                    })
                );
                saveActions.push(action);
            }
        });
        forkJoin(saveActions).subscribe(() => {
            this.toastrService.success("Notifications Updated", "Success");
            this.notifications.markAsPristine();
            this.notificationsUpdated.emit(true);
            this.bsModalRef.hide();
        });
    }

    saveDocuments(): void {
        const saveActions: Observable<Document>[] = Object.entries(this.documents.value)
            .filter(([name, file]) => !!file)
            .map(([name, file]: [string, File]) => {
                let formData = new FormData();
                formData.append('file', file, file.name);
                return this.documentService.addUpdateDocument(name, formData);
            });
        forkJoin(saveActions).subscribe(() => {
            this.toastrService.success("Documents Updated", "Success");
            this.documents.markAsPristine();
            this.bsModalRef.hide();
        });
    }
}
