import {Component, EventEmitter, Input, OnInit, SimpleChange, SimpleChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {currencyRenderer, dateRenderer, numberRenderer} from "../../Shared/ag-grid-cell-renderers";
import {BackOfficeOverviewModalComponent} from "./back-office-overview-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {of} from "rxjs";
import * as _ from "lodash";
import {BackOfficeAccount} from "./trade-detail.component";
import {Router} from "@angular/router";
import {AccountRoutePaths} from "../../Account/account-route-paths";

@Component({
    selector: "app-non-split-trade-detail-grid",
    templateUrl: "./non-split-trade-detail-grid.component.html"
})
export class NonSplitTradeDetailGridComponent extends BaseGridComponent<NonSplitTradeDetailRow> implements  OnInit {

    @Input()
    nonSplitTradeDetailRows: NonSplitTradeDetailRow[];

    @Input()
    dataLoading: EventEmitter<any>;

    @Input()
    isCollapsed: boolean = false;

    columnDefs = [
        { field: 'ParentName', flex: 2, headerName: 'Parent Account', tooltipValueGetter: (p) => p.data.ParentName, aggFunc: this.firstIfAllTheSameAggFunc, rowGroup: true, hide: true },
        { field: 'Ticker', width: 95 },
        { field: 'TradeDate', headerName: 'Trade Date', width: 125, valueFormatter: dateRenderer },
        { field: 'Side', headerName: 'Side', width: 50 },
        { field: 'Category', headerName: 'Cat', width: 50 },
        { field: 'Price', flex: 1, type: 'numericColumn', valueFormatter: (params) => currencyRenderer(params, 4) },
        { field: 'Rate', flex: 1, valueGetter: this.rateValueGetter, aggFunc: this.rateAggFunc, type: 'numericColumn', cellStyle: this.colorCell },
        { field: 'Quantity', flex: 1, aggFunc: 'sum', type: 'numericColumn', cellStyle: this.colorCell, valueFormatter: numberRenderer },
        { field: 'Commission', flex: 1, aggFunc: 'sum', type: 'numericColumn', cellStyle: this.colorCell, valueFormatter: currencyRenderer },
    ];

    pinnedTopRowData: NonSplitTradeDetailRow[];

    autoGroupColumnDef = {
        sort: 'asc',
        flex: 2,
        cellRendererParams: {
            suppressCount: true
        }
    };

    dateRangeOptions: any;

    constructor(
        private modalService: BsModalService,
        private router: Router,
    ) { super(); }

    ngOnInit(): void {
        this.gridOptions.groupDefaultExpanded = 1;
        this.gridOptions.overlayNoRowsTemplate = `<span>No Trades Found</span>`;
        this.defaultColDef.tooltipValueGetter = (params) => params.value;

        this.dataLoading.subscribe(() => { this.gridApi?.showLoadingOverlay(); });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isCollapsed) {

            if (this.isCollapsed) {
                this.gridApi?.collapseAll();
            } else {
                this.gridApi?.expandAll();
            }
        }

        if (changes.nonSplitTradeDetailRows) {
            this.rowData = this.nonSplitTradeDetailRows;

            this.pinnedTopRowData = [{
                Quantity: Math.round(_.sumBy(this.nonSplitTradeDetailRows, d => d.Quantity)),
                Commission: Math.round(_.sumBy(this.nonSplitTradeDetailRows, d => d.Commission)),
            } as NonSplitTradeDetailRow ];

            this.rowData$ = of(this.rowData);
        }
    }

    onGridReady(params) {
        params.columnApi.applyColumnState({
            state: [
                { colId: 'TradeDate', sort: 'asc', sortIndex: 0 },
                { colId: 'Ticker', sort: 'asc', sortIndex: 1 },
            ]
        });

        super.onGridReady(params);
    }

    showLoadingOverlay(): void {
        this.gridApi?.showLoadingOverlay();
    }

    colorCell(params: any): any {
        if(params.node.level == 0 && !params.node.rowPinned) return { backgroundColor: '#d9edf7' };
        if(params.node.level !== 0 && !params.node.rowPinned) return { backgroundColor: '#dff0d8' };
    }

    rateValueGetter(params: any): any {
        if (!params.node.group && !params.node.rowPinned) {
            return {
                Commission: params.data.Commission,
                Quantity: params.data.Quantity,
                toString: () => {
                    return (params.data.Commission && params.data.Quantity) ? (params.data.Commission / params.data.Quantity).toFixed(4) : 0.0000;
                }
            };
        }
    }

    rateAggFunc(params: any): any {
        let commissionSum = 0;
        let quantitySum = 0;
        params.values.forEach(value => {
            if(value && value.Commission) {
                commissionSum += value.Commission;
            }
            if(value && value.Quantity) {
                quantitySum += value.Quantity;
            }
        });
        return {
            Commission: commissionSum,
            Quantity: quantitySum,
            toString: () => {
                return (commissionSum && quantitySum) ? (commissionSum / quantitySum).toFixed(4) : 0.0000;
            }
        };
    }

    firstIfAllTheSameAggFunc(params: any): any {
        return params.values.length > 0 && params.values.every((val, i, arr) => val === arr[0]) ? params.values[0] : null;
    }

    renderBasicInfo(params: any): string {
        if(params.node.group) return params.value;
        return '';
    }

    onRowClicked($event: any): void {
        if(!$event.node.group &&  $event.node.data.Quantity !== 0) {
            const initialState = { backOfficeAccounts: $event.data.BackOfficeAccounts };
            this.modalService.show(BackOfficeOverviewModalComponent, { initialState: initialState, class: 'modal-md', animated: false, keyboard: true, backdrop: true });
        } else if($event.node.group) {
            this.router.navigate([AccountRoutePaths.AccountDetail, $event.node.childrenAfterGroup[0].data.ParentAccountId]);
        }
    }
}

export interface NonSplitTradeDetailRow {
    ParentAccountId: number;
    ParentName: string;
    Ticker: string;
    TradeDate: string;
    Side: string;
    Category: string;
    My: boolean;
    Price: number;
    Rate: undefined;
    Quantity: number;
    Commission: number;
    BackOfficeAccounts: BackOfficeAccount[]
}
