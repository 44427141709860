import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validators
} from "@angular/forms";
import * as moment from "moment";
import {EESActivity, EESActivityParticipant} from "../../Shared/Services/ees-activity.service";
import {Observable, of} from "rxjs";

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EesActivityFormBaseComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EesActivityFormBaseComponent),
            multi: true
        }
    ],
    selector: "app-ees-activity-form-base",
    templateUrl: "./ees-activity-form-base.component.html"
})
export class EesActivityFormBaseComponent implements OnInit, ControlValueAccessor {

    @Input()
    accountId: number;
    
    EesActivityForm: UntypedFormGroup = this.fb.group({
        id: this.fb.control(null),
        activityDate: this.fb.control(null),
        activityType: this.fb.control("C"),
        status: this.fb.control("O"),
        dueDate: this.fb.control(null),
        bairdParticipants: this.fb.control([]),
        clientParticipants: this.fb.control([]),
        comment: this.fb.control("", Validators.maxLength(4000)),
        baird: this.fb.control([]),
        createdDate: this.fb.control(""),
        createdBy: this.fb.control("")
    });

    initialActivityDate: Date;
    initialDueDate: Date;

    onChange: (obj: any) => void = () => {};

    constructor(private fb: UntypedFormBuilder,
    ) {}

    ngOnInit() {
        this.EesActivityForm.valueChanges.subscribe(formData => {
            this.onChange({
                Id: formData.id,
                ActivityDate: moment(formData.activityDate).format("LL"),
                ActivityType: formData.activityType,
                Status: formData.activityType === 'F' ? formData.status : null,
                DueDate: formData.activityType === 'F' ? moment(formData.dueDate).format("LL") : null,
                BairdParticipants: formData.bairdParticipants.map(p => {return{Id: p}}) as EESActivityParticipant[] || [],
                ClientParticipants: formData.clientParticipants.map(p => {return{Id: p}}) as EESActivityParticipant[] || [],
                Comment: formData.comment,
                Baird: formData.baird[0],
                CreatedDate: formData.createdDate,
                CreatedBy: formData.createdBy,
            } as EESActivity);
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: EESActivity): void {
        if (!obj) return;
        this.initialActivityDate = moment(obj.ActivityDate).toDate();
        this.initialDueDate = moment(obj.DueDate).toDate();
        this.EesActivityForm.patchValue({
            id: obj.Id,
            activityDate: obj.ActivityDate,
            activityType: obj.ActivityType,
            status: obj.Status ?? "O",
            dueDate: obj.DueDate ?? obj.ActivityDate,
            clientParticipants: obj.ClientParticipants?.map(p => p.Id) || [],
            bairdParticipants: obj.BairdParticipants?.map(p => p.Id) || [],
            comment: obj.Comment,
            baird: [obj.Baird],
            createdDate: obj.CreatedDate,
            createdBy: obj.CreatedBy,
        });
    }

    validate({ value }: UntypedFormControl) {
        if (this.EesActivityForm.invalid) {
            return {invalid: true};
        }
        return null;
    }
}
