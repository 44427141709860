import {Pipe, PipeTransform} from "@angular/core";
import {Account} from "../../../Models/Account";

@Pipe({
    name: 'accountLocation'
})
export class AccountLocationPipe implements PipeTransform {
    transform(account: Account, ...args): any {
        let location = "";

        if (account) {
            if (account.City) {
                location += account.City.trim();
            }

            if (account.State) {
                if (location.length > 0) {
                    location += ", ";
                }

                location += account.State.trim();
            }
            
            if (account.Country) {
                if (location.length > 0) {
                    location += account.State ? " " : ", ";
                }

                location += account.Country.trim();
            }
        }

        return location;
    }
}