<div>
    <div class="title pull-left" *ngIf="titleTemplate">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    </div>
    <div appRequiresFeature="mult-contact-edit-bulk" *ngIf="maxItems > 1 && !disabled && showEditButtons">
        <button type="button" style="margin-bottom: 3px;" class="btn btn-xs btn-danger pull-right" (click)="clear()">Clear</button>
        <button type="button" style="margin-bottom: 3px;" class="btn btn-xs btn-default pull-right" (click)="edit()" style="margin-right: 5px;">Edit</button>
        <button type="button" style="margin-bottom: 3px;" class="btn btn-xs btn-primary pull-right" (click)="add()" style="margin-right: 5px;">Add</button>
    </div>
    <span *ngIf="invalidPastedEmails.length > 0 ||
                invalidPastedIds.length > 0 || 
                invalidPastedTerms.length > 0 || 
                deduplicatedIds.length > 0 || 
                deduplicatedEmails.length > 0">
            <ng-template #invalidPopover>
                <div class="paste-errors" *ngIf="invalidPastedTerms.length > 0">
                    <strong> These terms are invalid: </strong>
                    <div> {{ invalidPastedTerms.join(', ') }} </div>
                </div>
                
                <div class="paste-errors" *ngIf="invalidPastedIds.length > 0">
                    <strong> These Ids are invalid: </strong>
                    <div> {{ invalidPastedIds.join(', ') }} </div>
                </div>
                
                <div class="paste-errors" *ngIf="invalidPastedEmails.length > 0">
                    <strong> These emails are invalid: </strong>
                    <div> {{ invalidPastedEmails.join(', ') }} </div>
                </div>

                <div class="paste-errors" *ngIf="deduplicatedIds.length > 0">
                    <strong> These Ids were deduplicated: </strong>
                    <div> {{ deduplicatedIds.join(', ') }}</div>
                </div>
                
                <div class="paste-errors" *ngIf="deduplicatedEmails.length > 0">
                    <strong> These emails were deduplicated: </strong>
                    <div> {{ deduplicatedEmails.join(', ') }}</div>
                </div>
            </ng-template>
            <i class="fa fa-exclamation text-danger" 
               [popover]="invalidPopover" 
               popoverTitle="Paste Errors" 
               [outsideClick]="true" 
               placement="right" 
               tooltip="Click to show errors" 
               tooltipPlacement="right"></i>
    </span>
    <div style="clear: both;">
        <ng-select #contactsNgSelect
                   [items]="contacts$ | async"
                   (paste)="onPaste($event)"
                   bindLabel="text"
                   [placeholder]="placeholder"
                   [multiple]="true"
                   [selectOnTab]="true"
                   [hideSelected]="true"
                   [minTermLength]="2"
                   [maxSelectedItems]="maxItems"
                   [typeahead]="userInput$"
                   [formControl]="contacts"
                   [readonly]="readOnly">
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <span [ngOptionHighlight]="search">{{item.text}}</span>
            </ng-template>
        </ng-select>    
    </div>
    <div style="margin-top: 3px;" *ngIf="contacts.value.length > 1">
        <span style="margin-right: 5px;">{{contacts.value.length}} Selected</span>
    </div>

</div>