import {Component, OnInit, TemplateRef} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ActivityService} from "../../Shared/Services/activity.service";
import * as moment from "moment";
import * as _ from "lodash";
import {BlueMatrixService} from "../../Shared/Services/bluematrix.service";
import {ToastrService} from "ngx-toastr";
import {UntypedFormBuilder} from "@angular/forms";
import {ExcelUploadComponent} from "../../Widget/DataUpload/excel-upload.component";
import {AdminRoutePaths} from "../admin-route-paths";

@Component({
    selector: "app-page-admin-dashboard",
    templateUrl: "./admin-dashboard.component.html"
})
export class AdminDashboardComponent implements OnInit{

    selected1Year: number;
    selectedYear: number;
    resourceYears: number[];
    templateModalRef: BsModalRef;

    bluematrixContactIds: number[] = [];
    bluematrixNewContactIds: string = '';
    researchFiscalYear = this.fb.control('');

    adminUserListPath = AdminRoutePaths.UserList;
    adminUserFeaturesPath = AdminRoutePaths.UserFeatures;
    adminUserGroupsPath = AdminRoutePaths.UserGroups;
    adminListGroupsPath = AdminRoutePaths.ListGroups;
    adminUserTeamsListPath = AdminRoutePaths.UserTeamsList;
    adminMifidAccountsPath = AdminRoutePaths.MifidAccounts;
    adminQueueMeetMaxEventsPath = AdminRoutePaths.QueueMeetMaxEvents;

    constructor(private modalService: BsModalService,
                private blueMatrixService: BlueMatrixService,
                private activityService: ActivityService,
                private fb: UntypedFormBuilder,
                private toastrService: ToastrService,
    ) {}

    ngOnInit(): void {
        let currentYear = moment().year();
        this.resourceYears = _.range(currentYear, 2009, -1);
        this.selectedYear = _.first(this.resourceYears);
    }

    openHoldingsModal() {
        this.openExcelModal({
            fileTitle: "Holdings",
            fileTemplatePath: "/holdings/update",
            fileUploadPath: "/holdings/update",
        });
    }

    openMcLaganModal() {
        this.openExcelModal({
            fileTitle: "McLagan",
            fileTemplatePath: "/commissionRank/template",
            fileUploadPath: "/commissionRank/upload",
        });
    }

    openRelativePayModal() {
        this.openExcelModal({
            fileTitle: "Relative Pay",
            fileTemplatePath: "/relativepay/update",
            fileUploadPath: "/relativepay/update",
        });
    }

    openSubscriptionModal() {
        this.openExcelModal({
            fileTitle: "Subscriptions",
            fileTemplatePath: "/subscriptions/template",
            fileUploadPath: "/subscriptions/upload",
        });
    }

    openAccountBrokerTrader() {
        this.openExcelModal({
            fileTitle: "Account Broker Trader",
            fileTemplatePath: "/accountupload/template",
            fileUploadPath: "/accountupload/update",
        });
    }

    openContactBroker() {
        this.openExcelModal({
            fileTitle: "Contacts",
            fileTemplatePath: "/contacts/template",
            fileUploadPath: "/contacts/update",
        });
    }

    openAccountHistory() {
        this.openExcelModal({
            fileTitle: "Account History",
            fileTemplatePath: "/accounthistoryupload/template",
            fileUploadPath: "/accounthistoryupload/update",
        });
    }

    openSpecMailing() {
        this.openExcelModal( {
            fileTitle: "Spec Mailing Subscriptions",
            fileTemplatePath: "/specmailingsubscriptionupload/template",
            fileUploadPath: "/specmailingsubscriptionupload/update",
        })
    }

    openContactHistory() {
        this.openExcelModal({
            fileTitle: "Contact History",
            fileTemplatePath: "/contacthistoryupload/template",
            fileUploadPath: "/contacthistoryupload/update",
        });
    }

    openExcelModal(initialState: any): void {
        this.modalService.show(ExcelUploadComponent, { initialState: initialState, ignoreBackdropClick: true, keyboard: false, animated: false });
    }

    openModalTemplate(template: TemplateRef<any>) {
        this.templateModalRef = this.modalService.show(template);
    }

    downloadActivityResources() {
        this.activityService.getActivityResourcePoints(this.selectedYear).subscribe();
        this.templateModalRef.hide();
    }

    downloadActivityResourcesNew() {
        this.activityService.getActivityResourcePointsNew(this.selectedYear).subscribe();
        this.templateModalRef.hide();
    }

    saveBlueMatrixContactIds() {
        let newValues = this.bluematrixNewContactIds
            .split(/[\s,]+/)
            .filter(p => _.isFinite(_.toNumber(p)))
            .map(p => _.toNumber(p))
            .filter(p => p > 0);

        this.blueMatrixService.addBlueMatrixContact(newValues)
            .subscribe(contactIds => {
                this.toastrService.success("Contact Ids will be included in next BlueMatrix file");
                this.bluematrixNewContactIds = '';
                this.bluematrixContactIds = contactIds;
            });
    }

    clearBlueMatrixContactids() {
        this.blueMatrixService.clearAllSendContact()
            .subscribe(x => {
                this.toastrService.success("Cleared BlueMatrix Contact Ids");
                this.bluematrixContactIds = [];
            });
    }

    openBlueMatrixContactsModalTemplate(bluematrixTemplate: TemplateRef<any>) {
        this.blueMatrixService.getBlueMatrixSendContact()
            .subscribe(contactIds => {
                this.bluematrixContactIds = contactIds;
                this.openModalTemplate(bluematrixTemplate);
            });
    }

    openResearchFiscalYearModalTemplate(bluematrixTemplate: TemplateRef<any>) {
        this.blueMatrixService.getCurrentFiscalYear()
            .subscribe(year => {
                this.researchFiscalYear.patchValue(year);
                this.openModalTemplate(bluematrixTemplate);
            });
    }

    saveResearchFiscalYear() {
        this.blueMatrixService.updateCurrentFiscalYear(this.researchFiscalYear.value)
            .subscribe(() => {
                this.toastrService.success("Fiscal Year Saved");
                this.templateModalRef.hide()
            });
    }
}
