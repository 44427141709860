<style>
    .form-inline .form-group:not(:last-child) {
        margin-right: 5px;
    }
</style>

<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getPagingDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="eventSearchForm">
            <div class="form-group">
                <div class="btn btn-primary btn-sm btn-toggle"
                     btnCheckbox
                     formControlName="upcomingEvents">
                    Ex NDR/Analyst Visits
                </div>
            </div>
            <div class="form-group">
                <app-event-category-picker
                    formControlName="category"
                >
                </app-event-category-picker>
            </div>
            <div class="form-group">
                <input type="search"
                       class="form-control input-sm"
                       style="width: 200px;"
                       formControlName="searchNameAndLocation"
                       placeholder="Search Name & Location" />    
            </div>
            <div class="form-group">
                <app-analyst-picker formControlName="analyst"></app-analyst-picker>
            </div>
            <div class="form-group">
                <input type="search"
                       class="form-control input-sm"
                       style="width: 120px;"
                       formControlName="searchTicker"
                       placeholder="Search Ticker" />
            </div>
            <div class="form-group">
                <app-date-range-picker formControlName="listDateRange"></app-date-range-picker>
            </div>
            <div class="form-group">
                <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
            </div>
            <div class="form-group" appIfUserFeature="EventUpcomingEmail">
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" aria-controls="dropdown-basic">
                        <span class="fa fa-gear"></span> <span class="caret" style="margin-left: 2px"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                        role="menu" aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="openUpcomingEventsModal()">Upcoming Events</a>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="form-group" appIfUserFeature="EventAdmin">
                <button type="button" (click)="addEvent()" class="btn btn-primary btn-sm">
                    <span class="fa fa-plus"></span>
                </button>
            </div>
        </form>
    </ng-template>
    
    <app-page-banner 
            pagetitle="Equities Marketing Calendar" 
            faicon="fa-calendar-o" 
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [rowClassRules]="rowClassRules"
                [defaultColDef]="defaultColDef"
                [getContextMenuItems]="getContextMenuItems"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
                rowSelection="single"
        >
        </ag-grid-angular>
        
    </div>
</div>
