import {Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {ColDef, GridReadyEvent, ITooltipParams, RowClickedEvent} from "ag-grid-community";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {BairdRepAnalystForListComponent, BairdRepService} from "../Services/baird-rep.service";
import {BairdRepAnalystFormComponent} from "./baird-rep-analyst-form.component";
import {BairdAnalystService} from "../Services/baird-analyst.service";

@Component({
    selector: "app-baird-rep-list",
    templateUrl: "./baird-rep-analyst-list.component.html"
})
export class BairdRepAnalystListComponent extends BaseGridComponent<BairdRepAnalystForListComponent> implements OnChanges, OnInit {

    columnDefs: ColDef[] = [
        { field: "Type", flex: 1, tooltipValueGetter: (params: ITooltipParams<BairdRepAnalystForListComponent>) => params.data.Type},
        { field: "First", valueGetter: (params) => params.data.FirstName?.trim(), tooltipValueGetter: (p) => p.data.FirstName, flex: 1 },
        { field: "Middle", valueGetter: (params) => params.data.MiddleInitial?.trim(), tooltipValueGetter: (p) => p.data.MiddleInitial, width: 100 },
        { field: "Last", sort: "asc", valueGetter: (params) => params.data.LastName?.trim(), tooltipValueGetter: (p) => p.data.LastName, flex: 1 },
        { field: "Title", valueGetter: (params) => params.data.Title?.trim(), tooltipValueGetter: (p) => p.data.Title, flex: 1 },
        { field: "Phone", flex: 1, tooltipValueGetter: (params: ITooltipParams<BairdRepAnalystForListComponent>) => params.data.Phone },
        { field: "Mobile", flex: 1, tooltipValueGetter: (params: ITooltipParams<BairdRepAnalystForListComponent>) => params.data.Mobile },
        { field: "Email", flex: 1, tooltipValueGetter: (params: ITooltipParams<BairdRepAnalystForListComponent>) => params.data.Email },
        { field: "Deleted", width: 100, tooltipValueGetter: (params: ITooltipParams<BairdRepAnalystForListComponent>) => params.data.Deleted, cellRenderer: yesNoCellRenderer},
    ];

    repAnalystSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(""),
        searchType: this.fb.control(null)
    });

    constructor(
        private fb: UntypedFormBuilder,
        private providerService: BairdRepService,
        private analystService: BairdAnalystService,
        private modalService: BsModalService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setRowData();
    }

    ngOnChanges(changes: SimpleChanges): void { }

    setRowData(): void {
        const allProviders$ = this.providerService.getProviders();
        const allAnalysts$ = this.analystService.getAnalysts();
        const formUpdate$ = this.repAnalystSearchForm.valueChanges.pipe(startWith(this.repAnalystSearchForm.getRawValue()));

        let AllReps$ = combineLatest([allProviders$, formUpdate$]).pipe(
            map(([allProviders, formUpdate]) =>
                allProviders
                    .map(x => {
                        return {
                            Type: "Rep",
                            ...x,
                        } as BairdRepAnalystForListComponent })
                    .filter(x => this.showRepAnalyst(x, formUpdate.searchTerm, formUpdate.searchType)),
            ),
        );
        let AllAnalysts$ = combineLatest([allAnalysts$, formUpdate$]).pipe(
            map(([allAnalysts, formUpdate]) =>
                allAnalysts
                    .map(x => {
                        return {
                            Type: "Analyst",
                            ...x,
                        } as BairdRepAnalystForListComponent })
                    .filter(x => this.showRepAnalyst(x, formUpdate.searchTerm, formUpdate.searchType)),
            ),
        );

        this.rowData$ = combineLatest([AllReps$, AllAnalysts$])
            .pipe(
            map(([AllReps, AllAnalysts]) => AllReps.concat(AllAnalysts)),
            );
    }

    getRepAnalystTypeDisplayName(): string {
        return "Baird Rep & Analyst";
    }

    getRepAnalystTypeDisplayIcon(): string {
        return "fa-user";
    }

    showRepAnalyst(repAnalyst: BairdRepAnalystForListComponent, searchTerm: string, searchType: string): boolean {
        return ((searchType === "" || searchType === null) ? true : searchType === repAnalyst.Type) && objectSearchPredicate(repAnalyst, searchTerm);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked(rowClickedEvent: RowClickedEvent<BairdRepAnalystForListComponent>) {
        this.openModalForm(rowClickedEvent.data.Id, rowClickedEvent.data.Type, false);
    }

    addRepAnalyst() {
        this.openModalForm(0, "Rep", true);
    }

    openModalForm(repAnalystId: number, type: String, newEntry: boolean): void {
        const initialState = {
            RepAnalystId: repAnalystId,
            type: type,
            newForm : newEntry
        } as Partial<BairdRepAnalystFormComponent>;

        let modalRef =
            this.modalService.show(BairdRepAnalystFormComponent, {
                initialState: initialState,
                animated: false,
                keyboard: false,
                backdrop: "static",
                class: "modal-lg"
            });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }
}
