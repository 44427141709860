<style>
    .form-inline .form-group:not(:last-child) {
        margin-right: 5px;
    }
</style>

<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>As of {{ latestDate }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="filterForm">

            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn">
                        <span btnCheckbox formControlName="my" class="btn btn-sm btn-toggle">
                            <span class="fa fa-sitemap"></span>
                        </span>
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 200px;"
                           formControlName="searchTerm"
                           placeholder="Search" />
                </div>
            </div>

            <div class="form-group">
                <app-number-picker
                    [items$]="selectableYears$"
                    formControlName="years"
                    [alignRight]="true"
                    [showSelectClearAll]="true"
                    [showTagCountLabel]="true"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    placeHolder="Year(s)"
                    name="Year(s)"
                ></app-number-picker>
            </div>

            <div class="form-group">
                <app-commission-category-picker formControlName="categories"></app-commission-category-picker>
            </div>

            <div appIfUserFeature="ShowAnnualization" class="form-group">
                <div btnCheckbox class="btn btn-primary btn-sm btn-toggle" formControlName="annualize">
                    Show Annualized
                </div>
            </div>

            <div class="form-group">
                <div btnCheckbox class="btn btn-primary btn-sm btn-toggle" formControlName="groupByParent">
                    Group By Parent
                </div>
            </div>

            <div class="form-group">
                <span class="btn btn-sm btn-default"
                      (click)="toggleGroupCollapse()">
                    <i class="fa"
                       [class.fa-compress]="!groupingIsCollapsed"
                       [class.fa-expand]="groupingIsCollapsed">
                    </i>
                </span>
            </div>

        </form>
    </ng-template>

    <app-page-banner
            pagetitle="Account Summary By Year"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [subTitleTemplate]="bannerSubTitleTemplate"
            [bannerFormTemplate]="bannerFormTemplate"
    >
    </app-page-banner>

    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                rowClass="non-clickable-row"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [pinnedTopRowData]="pinnedTopRowData"
                (gridReady)="onGridReady($event)"
                rowSelection="single"
        >
        </ag-grid-angular>

    </div>
</div>
