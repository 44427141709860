import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular-legacy";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";

@Component({
    selector: 'app-event-account-presenters-renderer',
    template: `
            <app-company-people-picker
                    [formControl]="peopleFormControl"
                    [companyId]="params.data.CompanyId"
                    [peopleIds]="params.data.PeopleIds">
            </app-company-people-picker>
`,
})
export class EventAccountPresentersRenderer implements ICellRendererAngularComp {
    params: any;

    peopleFormControl: UntypedFormControl = this.fb.control([]);

    constructor(private fb: UntypedFormBuilder) {
    }

    agInit(params: any): void {
        this.params = params;
        this.peopleFormControl.patchValue(params.data.PeopleIds);

        this.peopleFormControl.valueChanges.subscribe(pids => {
            let colId = this.params.column.colId;
            this.params.node.setDataValue(colId, pids);
        });

    }

    refresh(params: any): boolean {
        this.peopleFormControl.patchValue(params.data.PeopleIds);
        return true;
    }
}
