<style>
    .popout-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>

<div class="popout-content flex-vbox">
    
    <div class="flex-hbox ticker-header" style="margin: 5px 5px 0;">
        <div class="flex">
            <input [formControl]="searchTerm"
                   typeaheadOptionField="CompanyName"
                   [typeahead]="tickers$"
                   [typeaheadAsync]="true"
                   [typeaheadMinLength]="1"
                   (typeaheadOnSelect)="goToTicker($event)"
                   placeholder="Search Tickers"
                   class="form-control input-sm">
        </div>
        <div class="flex-4" *ngIf="ticker" style="padding: 5px 10px 0;">
            <div><strong>Company:</strong> {{tickerDetails.CompanyName}} ({{tickerDetails.Name}})</div>
            <div class="flex-hbox">
                <div class="flex"><strong>Analyst:</strong> {{tickerDetails.Analyst && tickerDetails.Analyst.LastName}}</div>
                <div class="flex"><strong>Trader:</strong> {{tickerDetails.MarketMaker && tickerDetails.MarketMaker.LastName}}</div>
            </div>
        </div>
        <div class="flex">
            <app-backups-picker [formControl]="backups"></app-backups-picker>
        </div>
    </div>
    
    <div class="flex flex-vbox" style="padding: 5px;">
        <tabset class="flex-tabset">
            <tab heading="Account Summary">
                <app-ticker-account-summary
                        class="flex flex-vbox" 
                        [ticker]="ticker" 
                        [backups]="backupIds">
                </app-ticker-account-summary>
            </tab>
            <tab heading="Trade Volume">
                <app-ticker-volume-chart
                        class="flex flex-vbox" 
                        [ticker]="ticker">
                </app-ticker-volume-chart>
            </tab>
            <tab heading="Activities">
                <app-tab-activity-list
                        class="flex flex-vbox" 
                        [dateRange]="defaultDateRange"
                        [categories]="categories"
                        [ticker]="ticker">
                </app-tab-activity-list>
            </tab>
        </tabset>
    </div>
    
</div>
