import * as _ from "lodash";
import * as moment from "moment";
import { Contact } from "../../Models/Contact";
import { ContactNote } from "../../Models/ContactNote";
import { TDocumentDefinitions } from "pdfmake/build/pdfmake";

export class TearSheetDocumentDefinitions {
    
    static getByContacts(contacts: Contact[], notes: ContactNote[]): TDocumentDefinitions {
        
        let docDef = this.createBaseTearSheet();
        
        _.forEach(contacts, (contact, index) => {
            
            const contactNotes = _.find(notes, note => note.ContactId === contact.Id);
            
            this.addContactAndNotes(docDef, contact, contactNotes);
            
            if (index < contacts.length - 1) {
                docDef.content[(docDef.content as any[]).length - 1].pageBreak = 'after';
            }
        });
        
        return docDef;
    }
    
    static get(contact: Contact, notes: ContactNote): TDocumentDefinitions {
        
        let docDef = this.createBaseTearSheet();
        
        this.addContactAndNotes(docDef, contact, notes);
        
        return docDef;
    }
    
    private static createBaseTearSheet(): TDocumentDefinitions {
        return {
            footer: (current: number, count: number) => {
                return {
                    margin: [46, 0],
                    columns: [
                        {
                            fontSize: 10,
                            text: `${moment().format("L")}`
                        },
                        {
                            fontSize: 10,
                            alignment: "right",
                            text: `Page ${current} of ${count}`
                        }
                    ]
                };
            },
            content: [],
            pageSize: "LETTER",
            pageOrientation: "portrait"
        } as TDocumentDefinitions;
    }

    private static addContactAndNotes(docDef: TDocumentDefinitions, contact: Contact, notes: ContactNote): void {

        const contactContent = [{
            table: {
                widths: ["50%", "auto", "*", "auto", "*", "auto", "*"],
                    body: [
                    [
                        {
                            bold: true,
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: !_.isNil(contact.Account) ? contact.Account.Name || "" : "",
                        },
                        {
                            bold: true,
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: "Tier:",
                        },
                        {
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: !_.isNil(contact.Account) ? contact.Account.Tier || "" : "",
                        },
                        {
                            bold: true,
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: "PrBk:",
                        },
                        {
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: !_.isNil(contact.Account) ? !_.isNil(contact.Account.PrimaryBroker) ? contact.Account.PrimaryBroker.LastName || "" : "" : "",
                        },
                        {
                            bold: true,
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: "PrTr:",
                        },
                        {
                            border: [false, true, false, false],
                            fontSize: 10,
                            noWrap: true,
                            text: !_.isNil(contact.Account) ? !_.isNil(contact.Account.PrimaryTrader) ? contact.Account.PrimaryTrader.LastName || "" : "" : "",
                        }
                    ]
                ]
            }
        },
        {
            table: {
                widths: ["100%"],
                    body: [
                    [
                        {
                            width: "50%",
                            border: [false, false, false, true],
                            fontSize: 10,
                            margin: [0, -3, 0, 0],
                            noWrap: true,
                            text:
                                !_.isNil(contact.Account) && !_.isNil(contact.Account.Address) ?
                                    (!_.isNil(contact.Account.Address.Route) && !_.isEmpty(contact.Account.Address.Route) ? contact.Account.Address.Route + " " : "") +
                                    (!_.isNil(contact.Account.Address.Locality) && !_.isEmpty(contact.Account.Address.Locality) ? contact.Account.Address.Locality + ", " : "") +
                                    (!_.isNil(contact.Account.Address.AdministrativeArea2) && !_.isEmpty(contact.Account.Address.AdministrativeArea2) ? contact.Account.Address.AdministrativeArea2 + " " : "") +
                                    (!_.isNil(contact.Account.Address.PostalCode) && !_.isEmpty(contact.Account.Address.PostalCode) ? contact.Account.Address.PostalCode : "") : ""
                        },
                    ]
                ]
            }
        },
        {
            columns: [
                {
                    columns: [
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 5, 25, 5],
                            noWrap: true,
                            text: _.join(_.filter([contact.LastName, contact.FirstName], it => !_.isNil(it) && !_.isEmpty(it)), ", "),
                            width: "45.7%"
                        },
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 5, 5, 5],
                            noWrap: true,
                            text: "Title:",
                            width: "auto"
                        },
                        {
                            fontSize: 10,
                            margin: [5, 5, 5, 5],
                            noWrap: true,
                            text: contact.Title || "",
                            width: "auto"
                        },
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 5, 5, 5],
                            noWrap: true,
                            text: "PersBk:",
                            width: "auto"
                        },
                        {
                            fontSize: 10,
                            margin: [5, 5, 5, 5],
                            noWrap: true,
                            text: !_.isNil(contact.Broker) ? contact.Broker.LastName || "" : "",
                            width: "auto"
                        }
                    ]
                }
            ]
        },
        {
            columns: [
                {
                    columns: [
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: "P:",
                            width: "auto"
                        },
                        {
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: contact.PhoneNumber || "",
                            width: "auto"
                        },
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: "M:",
                            width: "auto"
                        },
                        {
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: contact.MobilePhoneNumber || "",
                            width: "auto"
                        },
                        {
                            bold: true,
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: "Email:",
                            width: "auto"
                        },
                        {
                            fontSize: 10,
                            margin: [5, 0, 5, 5],
                            noWrap: true,
                            text: contact.Email || "",
                            width: "auto"
                        }
                    ]
                }
            ]
        }];

        docDef.content = _.concat((docDef.content as any[]), contactContent);
        
        if (notes && !_.isNil(notes.Professional) && !_.isEmpty(notes.Professional)) {
            const notesContent = [
                {
                    bold: true,
                    fontSize: 10,
                    margin: [6, 5, 0, 5],
                    text: "Notes:"
                },
                {
                    fontSize: 10,
                    margin: [6, 5, 0, 10],
                    text: notes.Professional || ""
                },
            ];

            docDef.content = _.concat((docDef.content as any[]), notesContent);
        }

        if (notes && !_.isNil(notes.Team) && !_.isEmpty(notes.Team)) {
            const teamNotesContent = [
                {
                    bold: true,
                    fontSize: 10,
                    margin: [6, 5, 0, 5],
                    text: "Team Notes:"
                },
                {
                    fontSize: 10,
                    margin: [6, 5, 0, 10],
                    text: notes.Team || ""
                }
            ];

            docDef.content = _.concat((docDef.content as any[]), teamNotesContent);
        }
        
    }
}