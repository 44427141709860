<div class="modal-content user-form">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="userForm">
        <div class="modal-body">
            
            <tabset>
                <tab heading="Basic Info">
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>
                                Id
                                <span *ngIf="userId" (click)="renameUserId()" class="fa fa-pencil"></span>
                            </label>
                            <input class="form-control input-sm" type="text" formControlName="Id" />
                            <div *ngIf="userForm.controls['Id'].hasError('userIdExists')">
                                <span class="text-danger">User Id Exists</span>
                            </div>
                        </div>
                        <div class="form-group flex">
                            <label>First Name</label>
                            <input class="form-control input-sm" type="text" formControlName="FirstName" />
                        </div>
                        <div class="form-group flex">
                            <label>Last Name</label>
                            <input class="form-control input-sm" type="text" formControlName="LastName" />
                        </div>
                        <div class="form-group flex">
                            <label>Email</label>
                            <input class="form-control input-sm" type="text" formControlName="Email" />
                        </div>
                    </div>

                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Domain</label>
                            <select class="form-control input-sm" formControlName="Domain">
                                <option value="EU">EU</option>
                                <option value="US">US</option>
                            </select>
                        </div>
                        <div class="form-group flex">
                            <label>NetworkId</label>
                            <input class="form-control input-sm" type="text" formControlName="NetworkId" />
                        </div>
                        <div class="form-group flex-2">
                            <label>Title</label>
                            <input class="form-control input-sm" type="text" formControlName="Title" />
                        </div>
                    </div>

                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Phone</label>
                            <input class="form-control input-sm" type="text" formControlName="Phone" />
                        </div>
                        <div class="form-group flex">
                            <label>PhoneExt</label>
                            <input class="form-control input-sm" type="text" formControlName="PhoneExt" />
                        </div>
                        <div class="form-group flex">
                            <label>Mobile</label>
                            <input class="form-control input-sm" type="text" formControlName="MobilePhone" />
                        </div>
                        <div class="form-group flex">
                        </div>
                    </div>

                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Role/Team</label>
                            <select class="form-control input-sm" formControlName="TeamId">
                                <option *ngFor='let team of teams$ | async' [ngValue]="team.TeamId">{{ formatTeamName(team) }}</option>
                            </select>
                        </div>
                        <div class="form-group flex">
                            <label>Instisel Group</label>
                            <select class="form-control input-sm" formControlName="UserGroupId">
                                <option *ngFor='let group of groups$ | async' [ngValue]="group.GroupId">{{ group.GroupName }}</option>
                            </select>
                        </div>
                        <div class="form-group flex">
                            <label>Comdol Role</label>
                            <select class="form-control input-sm" formControlName="ComdolRole">
                                <option *ngFor='let comdolRole of comdolRoles$ | async' [value]="comdolRole">{{ comdolRole }}</option>
                            </select>
                        </div>
                        <div class="form-group flex">
                            <label>Comdol Group</label>
                            <select class="form-control input-sm" formControlName="ComdolGroupId">
                                <option *ngFor='let group of groups$ | async' [ngValue]="group.GroupId">{{ group.GroupName }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Coverage</label>
                            <select class="form-control input-sm" formControlName="Coverage">
                                <option value=""></option>
                                <option value="My Accounts">My Accounts</option>
                            </select>
                        </div>
                        <div class="form-group flex-3">
                            <div class="pull-left" style="margin-right: 10px;">
                                <label>Trader</label>
                                <select class="form-control input-sm" formControlName="IsTrader">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                            <div class="pull-left" style="margin-right: 10px;">
                                <label>Broker</label>
                                <select class="form-control input-sm" formControlName="IsBroker">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                            <div class="pull-left" style="margin-right: 10px;">
                                <label>Default Analyst</label>
                                <select class="form-control input-sm" formControlName="IsDefaultAnalyst">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                            <div class="pull-left" style="margin-right: 10px;">
                                <label>Email from Analyst</label>
                                <select class="form-control input-sm" formControlName="IsEmailFromAnalyst">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                            <div class="pull-left" style="margin-right: 9px">
                                <label>Beta Rep Code</label>
                                <input class="form-control input-sm" type="text" formControlName="BetaRepCode">
                            </div>
                            <div class="pull-right">
                                <label>Active</label>
                                <select class="form-control input-sm" formControlName="IsActive">
                                    <option [ngValue]="true">Yes</option>
                                    <option [ngValue]="false">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Notes</label>
                            <textarea class="form-control input-sm" formControlName="Notes" rows="2"></textarea>
                        </div>
                    </div>
                </tab>
                <tab heading="User Relations">
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <p>Users to be notified when save and send email used on activities</p>
                            <app-multi-user-select formControlName="TeamUserIds"></app-multi-user-select>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <p>Give ability of this user to mail merge from/as any of the below</p>
                            <app-multi-user-select formControlName="SendAsIds"></app-multi-user-select>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <p>Give ability of this user to mail merge on behalf of the below</p>
                            <app-multi-user-select formControlName="SendOnBehalfOfIds"></app-multi-user-select>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <p>Users to backup</p>
                            <app-multi-user-select formControlName="UserBackups"></app-multi-user-select>
                        </div>
                    </div>
                </tab>
                <tab heading="User Features">
                    <div class="user-features">
                        <div class="item" *ngFor="let userFeature of userFeatures$ | async">
                            <button type="button" 
                                    class="btn btn-sm btn-toggle" 
                                    (click)="userFeatureClick(userFeature)"
                                    [class.active]="userForm.get('UserFeatures').value.includes(userFeature)">{{ userFeature }}</button>
                        </div>
                    </div>
                </tab>
                <tab heading="System Mappings">
                    <div class="form-group flex">
                        <p>User Fidessa ID</p>
                        <ng-select
                                formControlName="FidessaIds"
                                [addTag]="addTag"
                                [multiple]="true"
                        ></ng-select>
                    </div>
                </tab>
                <tab heading="CorpTravel">
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label style="margin-right: 5px;">
                                <input type="checkbox" formControlName="IsCorpTravelUser" /> Active CorpTravel User
                            </label>
                        </div>
                    </div>
                    
                    <ng-container formGroupName="CorpTravelForm" *ngIf="this.userForm.get('IsCorpTravelUser').value">
                        <div class="flex-hbox">
                            <div class="form-group flex">
                                <label>User Type</label>
                                <app-corp-travel-user-type-picker formControlName="CorpTypeId"></app-corp-travel-user-type-picker>
                            </div>
                            <div class="form-group flex">
                                <label>Territory(s)</label>
                                <app-travel-territory-picker formControlName="CorpTerritoryIds"></app-travel-territory-picker>
                            </div>
                            <div class="form-group flex">
                                <label>Role(s)</label>
                                <app-corp-travel-user-role-picker formControlName="CorpRoleIds"></app-corp-travel-user-role-picker>
                            </div>
                            <div class="form-group flex flex-vbox">
                                <div class="flex"></div>
                                <div class="flex">
                                    <label>
                                        <input type="checkbox" formControlName="CorpMeetingCoordinator"> Meeting Coordinator
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="flex-hbox">
                            <div class="form-group flex">
                                <label>Email Signature</label>
                                <textarea class="form-control input-sm" formControlName="CorpEmailSignature" rows="2"></textarea>
                            </div>
                        </div>
                    </ng-container>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" [disabled]="userForm.invalid" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-danger" *ngIf="userId && !isMyUserId" (click)="delete()">Delete</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

