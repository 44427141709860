import "core-js/es6";
import "core-js/es7/reflect";
import "zone.js";
import { AppModule as AppModuleAngular } from './app.module';
import "../styles/main.scss";
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {LicenseManager} from "ag-grid-enterprise";

LicenseManager.setLicenseKey("Using_this_{AG_Grid}_Enterprise_key_{AG-058142}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Baird_Funds,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JAVA_Enterprise}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{JAVA_Enterprise}_need_to_be_licensed___{JAVA_Enterprise}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{22_June_2025}____[v3]_[01]_MTc1MDU0NjgwMDAwMA==449d78bec9951d6f5deccedcc80ddbef");

window.addEventListener("load", function() {
    platformBrowserDynamic().bootstrapModule(AppModuleAngular).then(platformRef => {

    });
});

if (window["instiselconfig"].MiniProfiler.Active) {
    window.onload = function() {
        var s = document.createElement('script');
        s.async = true;
        s.src = window["instiselconfig"].MiniProfiler.Path + '/mini-profiler-resources/includes.min.js?v=4.2.22+4563a9e1ab';
        s.id = 'mini-profiler';
        s.setAttribute('data-version', 'includes.min.js?v=4.2.22+4563a9e1ab');
        s.setAttribute('data-path', window["instiselconfig"].MiniProfiler.Path + '/mini-profiler-resources/');
        s.setAttribute('data-current-id', '');
        s.setAttribute('data-ids', '');
        s.setAttribute('data-position', 'BottomLeft');
        s.setAttribute('data-scheme', 'Auto');
        s.setAttribute('data-authorized', 'true');
        s.setAttribute('data-controls', 'true');
        s.setAttribute('data-max-traces', '10');
        s.setAttribute('data-toggle-shortcut', 'Alt+P');
        s.setAttribute('data-trivial-milliseconds', '2.0');
        s.setAttribute('data-ignored-duplicate-execute-types', 'Open,OpenAsync,Close,CloseAsync');
        document.body.appendChild(s);
    }
}
