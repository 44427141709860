import {CommonModule} from "@angular/common";
import {WidgetModule} from "../Widget/widget.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AgGridModule} from "ag-grid-angular-legacy";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {NgModule} from "@angular/core";
import {TickerTrendlineChartComponent} from "./Sales/ticker-trendline-chart.component";
import {TrendingTickerChartComponent} from "./Sales/trending-ticker-chart.component";
import {SalesDashboardComponent} from "./Sales/sales-dashboard.component";
import {CallActivityChartComponent} from "./Research/call-activity-chart.component";
import {CallDurationChartComponent} from "./Research/call-duration-chart.component";
import {BespokeResearchInteractionChartComponent} from "./Research/bespoke-research-interaction-chart.component";
import {ActivityAllocationChartComponent} from "./Research/activity-allocation-chart.component";
import {TimeAllocationChartComponent} from "./Research/time-allocation-chart.component";
import {ClientEventsChartComponent} from "./Research/client-events-chart.component";
import {ResearchDashboardEventListComponent} from "./Research/research-dashboard-event-list.component";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {dashboardRoutes} from "./dashboard.routes";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        ButtonsModule.forRoot(),
        NgOptionHighlightModule,
        TooltipModule,
        RouterModule.forChild(dashboardRoutes),
    ],
    providers: [
        UserCanActivateRoute,
    ],
    declarations: [
        SalesDashboardComponent,
        TrendingTickerChartComponent,
        TickerTrendlineChartComponent,
        CallActivityChartComponent,
        CallDurationChartComponent,
        BespokeResearchInteractionChartComponent,
        ActivityAllocationChartComponent,
        TimeAllocationChartComponent,
        ClientEventsChartComponent,
        ResearchDashboardEventListComponent
    ],
    exports: [
        RouterModule,
        CallActivityChartComponent,
        CallDurationChartComponent,
        BespokeResearchInteractionChartComponent,
        ActivityAllocationChartComponent,
        TimeAllocationChartComponent,
        ClientEventsChartComponent,
        ResearchDashboardEventListComponent
    ]
})
export class DashboardModule {
    constructor(){
        console.log("registering Sales & Research Dashboard");
    }
}
