import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {TravelCity, TravelCityService} from "../Services/travel-city.service";
import {TravelMarketService} from "../Services/travel-market.service";

@Component({
    selector: "app-travel-city-form",
    templateUrl: "./travel-city-form.component.html",
    styleUrls: ["./travel-city-form.component.scss"]
})
export class TravelCityFormComponent implements OnInit{

    @Output()
    dataUpdated = new EventEmitter<boolean>();
    travelCityId: number = null;
    title: string;
    travelCities: TravelCity[];
    initialCityName: string;
    currentTravelTerritoryId: number;

    travelCityForm: UntypedFormGroup = this.fb.group({
        Name: this.fb.control('', [Validators.maxLength(50), this.emptyOrWhiteSpaceValidatorForNonNullField(),
            this.duplicateCityValidatorForNewCities()]),
        Deleted: this.fb.control(false),
        TerritoryId: this.fb.control(null, [Validators.required]),
        MarketId: this.fb.control(null, [Validators.required]),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private travelCityService: TravelCityService,
        private travelMarketService: TravelMarketService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {

        this.travelCityService.getTravelCities()
            .subscribe((travelCities) => {
                this.travelCities = travelCities
            });

        if (this.travelCityId) {
            this.loadTravelCity(this.travelCityId);
        } else {
            this.title = 'New City';
        }

        this.travelCityForm.get('TerritoryId').valueChanges.subscribe(changedTerritoryId => {
            this.currentTravelTerritoryId = changedTerritoryId;
            this.travelCityForm.patchValue({MarketId: ""});
        });

    }

    loadTravelCity(travelCityId: number) {

        const travelCity$ = this.travelCityService.getTravelCityById(travelCityId);

        travelCity$
            .subscribe((travelCity) => {
                this.travelCityForm.patchValue({
                    Name: travelCity.Name,
                    Deleted: travelCity.Deleted,
                    TerritoryId: travelCity.TerritoryId,
                    MarketId: travelCity.MarketId,
                });

                this.title = `Edit City: ${travelCity.Name}`;

                this.initialCityName = travelCity.Name;

                this.travelCityForm.get("Name").updateValueAndValidity({emitEvent: false});

            });
    }

    submit() {

        if (this.travelCityForm.valid) {
            let travelCity: TravelCity = {
                Id: this.travelCityId,
                Name: this.travelCityForm.get('Name').value.trim(),
                TerritoryId: this.travelCityForm.get('TerritoryId').value,
                MarketId: this.travelCityForm.get('MarketId').value,
                Deleted: this.travelCityForm.get('Deleted').value,
            };

            let updatedTravelCity$ = this.travelCityService.createOrUpdateTravelCity(travelCity);

            updatedTravelCity$.subscribe(updatedTravelCity => {
                this.toastr.success('Saved City');
                this.dataUpdated.emit(true);
                this.modalRef.hide();
            });
        }
    }

    close() {
        this.modalRef.hide();
    }

    emptyOrWhiteSpaceValidatorForNonNullField(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return (control.value.trim().length === 0) ?
                { emptyOrWhiteSpace: true } :
                null;
        }
    }

    duplicateCityValidatorForNewCities(): ValidatorFn {
        return (control:AbstractControl) : ValidationErrors | null => {
            return (this.travelCities?.some(x => control.value?.trim()?.toLowerCase() === x.Name?.trim()?.toLowerCase())
                && (control.value?.trim()?.toLowerCase() !== this.initialCityName?.trim()?.toLowerCase())) ?
                { duplicateCity: true } :
                null;
        }
    }
}
