import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface Airport {
    Id?: number;
    CityId: number;
    TypeId: number;
    Name: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    Phone: string;
    Notes: string;
    Deleted: string;
}

export interface AirportForListComponent extends Airport {
    CityNameForCityId: string;
    TypeNameForTypeId: string;
}

@Injectable({
    providedIn: 'root',
})
export class AirportService {

    constructor(private baseHttp: BaseHttpService) { }

    getAirports(): Observable<Airport[]> {
        return this.baseHttp.getData(`/airport`);
    }

    getAirportById(airportId: number): Observable<Airport> {
        return this.baseHttp.getData(`/airport/id/${airportId}`);
    }

    createOrUpdateAirport(airport: Airport): Observable<Airport> {
        return this.baseHttp.postData(`/airport`, airport);
    }
}
