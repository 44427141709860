<div class="flex flex-vbox">
    <ag-grid-angular
        class="ag-theme-balham"
        style="width: 100%; height: 26px"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [pivotMode]="true"
        [headerHeight]="0"
        [autoGroupColumnDef]="autoGroupColumnDef"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
</div>