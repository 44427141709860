<span class="fa fa-bell notifications-icon" 
      *ngIf="notifications$ | async as notifications"
      [ngClass]="{'unseen-notifications': unseenNotifications}"
      [popover]="notificationsPopover" 
      [popoverContext]="{ notifications: notifications }"
      (onShown)="onPopoverOpened(notifications)"
      [outsideClick]="true"
      containerClass="notifications-popover"
      placement="bottom"></span>

<ng-template #notificationsPopover let-notifications="notifications">
    <div class="popover-title popover-header">
        <span>Notifications</span>
        <div *ngIf="isAdmin$ | async" class="pull-right manage" (click)="manage()">Manage</div>
    </div>
    <div class="notification" *ngFor="let notification of notifications">
        <div class="date">What's New (as of {{notification[0] | date: 'shortDate'}})</div>
        <div class="item" *ngFor="let item of notification[1]">
            {{item.Description}}
        </div>
    </div>
    <a class="whats-new-pdf-link" (click)="getWhatsNewPdf()">More information<span class="fa fa-external-link"></span></a>
</ng-template>