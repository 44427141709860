import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {EventService, UpcomingEventAttendance} from "../../Shared/Services/event.service";
import {getNoRowsOverlayTemplate} from "../../Shared/ag-grid-options";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {ColDef} from "ag-grid-community";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {Query, QueryGroup} from "../../../Services/QueryService";
import * as _ from "lodash";
import {ContactNameCellRenderer} from "../../Widget/CellRenderers/contact-name-renderer.component";
import {ICellRendererParams} from "ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer";
import {EventNameCellRenderer} from "../../Widget/CellRenderers/event-name-renderer.component";
import {nullableValueComparator} from "../../Shared/ag-grid-cell-comparators";
import {CacheService} from "../../Shared/Services/cache.service";
import {getEventDisplayNameByValues} from "../../../Helpers/DisplayStringHelper";

@Component({
    selector: "app-registration-list",
    templateUrl: "./registration-list.component.html"
})
export class RegistrationListComponent extends BaseGridComponent<UpcomingEventAttendance> implements OnInit {
    columnDefs: ColDef[] = [
        {
            field: 'EventName',
            cellRenderer: EventNameCellRenderer,
            cellRendererParams: (params: ICellRendererParams<UpcomingEventAttendance>) => {
                return {
                    EventId: params.data.EventId,
                    EventName: params.data.EventName,
                    IsActive: params.data.IsActive
                };
            },
            sort: "asc", sortIndex: 2,
            flex: 2,
            tooltipValueGetter: (p) => getEventDisplayNameByValues(p.data.IsActive, p.data.EventName),
        },
        { field: 'EventMeetingName', headerName: 'Meeting Name', sort: "asc", sortIndex: 3, flex: 2, tooltipValueGetter: (p) => p.data.EventMeetingName,},
        { field: 'Category', minWidth: 105, flex: 1, tooltipValueGetter: (p) => p.data.Category,},
        { field: 'AccountName', headerName: "Account", sort: "asc", sortIndex: 4, flex: 2, tooltipValueGetter: (p) => p.data.AccountName,},
        {
            field: 'Contact',
            headerName: "Contact",
            sort: "asc", 
            cellRenderer: ContactNameCellRenderer,
            sortIndex: 5,
            flex: 2,
            tooltipValueGetter: (p) => p.data.ClientName,
            valueGetter: p => `${p.data.Contact?.LastName}, ${p.data.Contact?.FirstName}`},
        { field: 'Market', flex: 1, tooltipValueGetter: (p) => p.data.Market,},
        { 
            field: 'SalesTeam',
            flex: 1,
            minWidth: 125,
            valueGetter: p => this.userService.formatTeamName(p.data.SalesRole, p.data.SalesTeam),
            tooltipValueGetter: (p) => this.userService.formatTeamName(p.data.SalesRole, p.data.SalesTeam),
            comparator: nullableValueComparator
        },
        { field: 'Broker', flex: 1, tooltipValueGetter: (p) => p.data.Broker, comparator: nullableValueComparator },
        { field: 'BeginDate', headerName: 'Start Date', sort: "asc", sortIndex: 1, minWidth: 85, flex: 1, cellRenderer: dateRenderer, tooltipValueGetter: dateRenderer },
        { field: 'EndDate', headerName: 'End Date', flex: 1, minWidth: 85, cellRenderer: dateRenderer, tooltipValueGetter: dateRenderer, },
        { field: 'Status', width: 90, tooltipValueGetter: (p) => p.data.Status,},
        { field: 'Interested', headerName: "Int", flex: 1, maxWidth: 50, type: "numericColumn", tooltipValueGetter: (p) => p.data.Interested,},
        { field: 'Registered', headerName: "Reg", flex: 1, maxWidth: 50, type: "numericColumn", tooltipValueGetter: (p) => p.data.Registered,},
    ];
    
    private cacheKey = "RegistrationListFilters";

    eventSearchForm: UntypedFormGroup = this.fb.group({
        categories: this.fb.control([]),
        searchTerm: this.fb.control(''),
        accountSearchTerm: this.fb.control(''),
        brokers: this.fb.control([]),
        myAccounts: this.fb.control(false),
        salesTeams: this.fb.control([]),
        attendeeStatus: this.fb.control([]),
    });
    
    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private eventService: EventService,
                private cacheService: CacheService) { super(); }
    
    ngOnInit(): void {
        let savedFilters = this.cacheService.getValue<any>(this.cacheKey);

        if (savedFilters) {
            this.eventSearchForm.patchValue(savedFilters);
        }
        
        this.rowData$ = this.eventSearchForm.valueChanges
            .pipe(
                startWith(this.eventSearchForm.value),
                debounceTime(500),
                tap(formValues => this.cacheService.setValue(this.cacheKey, formValues)),
                tap(() => this.gridApi.showLoadingOverlay()),
                switchMap(() => {
                    let searchForm = this.eventSearchForm.value;
                    let queryGroups = this.buildQuery(this.eventSearchForm.value);
                    let query = {QueryGroups: queryGroups} as Query;
                    return this.eventService.getUpcomingEventAttendance(query)
                        .pipe(
                            map((upcomingEvents) => upcomingEvents
                                .filter(x => this.filterEvents(searchForm.searchTerm, [x.EventName, x.EventMeetingName]))
                                .filter(x => this.filterEvents(searchForm.accountSearchTerm, [x.AccountName]))
                                .filter(x => searchForm.categories.length === 0 || searchForm.categories.includes(x.Category))
                            )
                        );
                }),
                tap(() => this.gridApi.hideOverlay()),
            );

        this.gridOptions.overlayNoRowsTemplate = getNoRowsOverlayTemplate('Events');
        this.gridOptions.suppressContextMenu = false;
    }
    
    buildQuery(eventSearchForm: any): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];
        
        const invitedStatusId = 1;
        QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Status", "not contains", [invitedStatusId.toString()]);

        if (eventSearchForm.brokers.length > 0)
        {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Personal Broker", "contains", eventSearchForm.brokers);
        }
        if (eventSearchForm.myAccounts)
        {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Account", "My Account", "is", "true");
        }
        if (eventSearchForm.salesTeams.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Sales Team", "contains", eventSearchForm.salesTeams);
        }
        if (eventSearchForm.attendeeStatus.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Status", "contains", eventSearchForm.attendeeStatus);
        }
        
        
        return queryGroups;
    }

    filterEvents(searchTerm: string, columnsToSearch: string[]): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        
        columnsToSearch.forEach(column => {
            if(_.startsWith(column?.toLocaleLowerCase(), search)) {
                return true;
            }
        })

        const words = _.words(search, /[^,.\s;]+/g);

        const query = (columnsToSearch: string[]): boolean => {

            const fieldsContains = _.map(columnsToSearch, f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };
        
        return query(columnsToSearch);
    }

    clearFilters() {
        this.eventSearchForm.patchValue({
            categories: [],
            searchTerm: '',
            accountSearchTerm: '',
            brokers: [],
            myAccounts: false,
            salesTeams: [],
            attendeeStatus: [],
        });
    }

    getContextMenuItems(params) {
        return [
            {
                name: 'Export All',
                subMenu: [
                    {
                        name: 'CSV Export',
                        action: () => {
                            params.api.exportDataAsCsv();
                        }
                    },
                    {
                        name: 'Excel Export (.xlsx)',
                        action: () => {
                            params.api.exportDataAsExcel();
                        }
                    },

                ]
            }
        ];
    }
}
