export const InvestorStyles = [
    'Core',
    'Dividend',
    'ESG',
    'GARP',
    'Growth',
    'Income',
    'Index',
    'Quant',
    'Risk Arb/Spec Situations',
    'Value'
]