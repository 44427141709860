import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as  _ from "lodash";
import {map} from "rxjs/operators";
import {AccountService} from "../../../Shared/Services/account.service";

@Component({
    selector: "app-contact-teams-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ContactTeamsPickerComponent)
})
export class ContactTeamsPickerComponent extends BasePickerComponent<any> implements OnInit {
    
    @Input()
    alignRight: boolean = false;
    
    @Input()
    accountId: number;

    constructor(fb: UntypedFormBuilder,
                private accountService: AccountService) {
        super(fb);

        this.name = "Team(s)";
        this.placeHolder = "Team(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.customSearchable = true;
    }

    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.accountService.GetAccountContactAvailableTeams(this.accountId).pipe(
            map(teams => _.sortBy(teams, t => t.toUpperCase())),
            map(teams => _.map(teams, t => { return {Id: t, Name: t} }))
        );
    }
}
