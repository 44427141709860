import {Component, Input} from "@angular/core";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import {TearSheetDocumentDefinitions} from "../../../Helpers/Pdf/TearSheetDocumentDefinitions";
import * as _ from "lodash";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact} from "../../../Models/Contact";

@Component({
    selector: "app-contact-detail-reports",
    templateUrl: "./contact-detail-reports.component.html"
})
export class ContactDetailReportsComponent {
    @Input()
    contact: Contact;

    constructor(private contactService: ContactService) { }

    downloadContactTearSheet(): void {
        (pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;


        this.contactService.getContactNote(this.contact.Id)
            .subscribe(notes => {
                const documentDefinitions = TearSheetDocumentDefinitions.get(this.contact, notes);

                const name= _.join(
                    _.filter(
                        [this.contact.FirstName, this.contact.LastName],
                        it => !_.isNil(it) && !_.isEmpty(it)
                    ),
                    ""
                );

                const filename = _.join(
                    _.filter(
                        ["TearSheet", name],
                        it => !_.isNil(it) && !_.isEmpty(it)
                    ),
                    "-"
                );

                pdfMake.createPdf(documentDefinitions).download(`${filename}.pdf`);
            });
    }
}
