import {Injectable} from "@angular/core";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class CorpTravelUserService {
    constructor(private baseHttp: BaseHttpService) { }
    
    getCorpTravelUser(userId: string): Observable<CorpTravelUser> {
        return this.baseHttp.getData(`/corptraveluser/${userId}`)
    }
    
    saveCorpTravelUser(user: CorpTravelUser) : Observable<any> {
        return this.baseHttp.postData('/corptraveluser', user);
    }
    
    deleteCorpTravelUser(userId: string): Observable<any> {
        return this.baseHttp.deleteData(`/corptraveluser/${userId}`);
    }
    
    deactivateCorpTravelUser(userId: string) : Observable<any> {
        return this.baseHttp.postData(`/corptraveluser/${userId}/deactivate`, undefined)
    }
    
    getCorpTravelUserRoles(): Observable<CorpTravelUserRole[]> {
        return this.baseHttp.getData(`/corptraveluser/roles`)
    }
    
    getCorpTravelUserTypes(): Observable<CorpTravelUserType[]> {
        return this.baseHttp.getData(`/corptraveluser/types`)
    }
}

export interface CorpTravelUser {
    Id: string;
    NetworkId: string;
    FirstName: string;
    LastName: string;
    TypeId: string;
    TerritoryIds: number[];
    RoleIds: number[];
    Mobile: string;
    WorkPhone: string;
    MeetingCoordinator: boolean;
    EmailSignature: string;
    Email: string;
    Title: string;
}

export interface CorpTravelUserRole {
    Id: number;
    Name: string;
}

export interface CorpTravelUserType {
    Id: string;
    Name: string;
}