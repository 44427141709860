import {Component, OnInit} from "@angular/core";
import {ChecksReportService} from "./checks-report.service";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import * as _ from "lodash";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import * as moment from "moment";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UserService} from "../../Shared/Services/user.service";
import {RoleName} from "../../../Models/Role";
import {ColDef, ColGroupDef} from "ag-grid-community";
import {CellClassParams} from "ag-grid-community/dist/lib/entities/colDef";

@Component({
    selector: "app-reports-checks",
    templateUrl: "./checks-report.component.html"
})
export class ChecksReportComponent extends BaseGridComponent<CheckReportData> implements OnInit {
    columnDefs: ColDef[] = [
        {field: 'ParentName', rowGroup: true, hide: true},
        {field: 'Name', rowGroup: false, hide: true},
        {field: 'Location', flex: 2, aggFunc: 'first', cellRenderer: _.bind(this.renderBasicInfo, this)},
        {field: 'PrBk', flex: 2, aggFunc: 'first', cellRenderer: _.bind(this.renderBasicInfo, this)},
        {field: 'PrTr', flex: 2, aggFunc: 'first', cellRenderer: _.bind(this.renderBasicInfo, this)},
        {field: 'Date', flex: 2, cellStyle: this.colorCell},
        {field: 'Comment', flex: 5, cellStyle: this.colorCell, tooltipValueGetter: this.getComment},
        {
            field: 'Received',
            flex: 2,
            aggFunc: 'sum',
            cellStyle: this.colorCell,
            valueFormatter: this.currencyFormatter,
            type: 'numericColumn'
        },
        {
            field: 'Outstanding',
            flex: 2,
            aggFunc: 'sum',
            cellStyle: this.colorCell,
            valueFormatter: this.currencyFormatter,
            type: 'numericColumn'
        },
        {
            field: 'Total',
            flex: 2,
            aggFunc: 'sum',
            valueFormatter: this.currencyFormatter,
            type: 'numericColumn'
        },
    ];

    autoGroupColumnDef: ColDef = {
        headerName: 'Account',
        flex: 5,
        tooltipValueGetter: (params) => params.value,
        sort: 'asc',
        cellRendererParams: {
            suppressCount: true
        },
    };


    pinnedTopRowData;

    checksSearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(false),
        searchTerm: this.fb.control(''),
        groupByParent: this.fb.control(true),
    });

    yearControl: UntypedFormControl = this.fb.control('');

    years: number[];

    constructor(private fb: UntypedFormBuilder,
                private checksReportService: ChecksReportService,
                private userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.pagination = true;

        let currentYear = new Date().getFullYear();
        this.yearControl.patchValue(currentYear.toString());
        this.years = _.range(2018, currentYear + 1);

        const checkAccounts$ = combineLatest([
            this.userService.getCurrentUser(),
            this.yearControl.valueChanges.pipe(
                startWith(currentYear)
            )
        ]).pipe(
            tap(([user, year]) => {
                this.checksSearchForm.get("myAccounts").patchValue(user.Role.Name === RoleName.Sales || user.Role.Name === RoleName.Traders, {emitEvent: false})
            }),
            switchMap(([user, year]) => this.checksReportService.getCommissionChecks(year))
        );

        const formUpdate$ = this.checksSearchForm.valueChanges.pipe(
            startWith(this.checksSearchForm.getRawValue())
        );
        this.checksSearchForm.get("groupByParent").valueChanges.subscribe(val => {
            if(this.gridColumnApi) {
                if(val) {
                    this.defaultSorting();
                } else {
                    this.nameGroupedSorting();
                }
            }
        })

        this.rowData$ = combineLatest([checkAccounts$, formUpdate$]).pipe(
            map(([checkAccounts, searchForm]) => {
                return _.flatMap(checkAccounts, ca => {
                    return ca.Checks.map(c => {
                        return {
                            ParentName: searchForm.groupByParent ? `${ca.ParentName}` : `${ca.ParentName} (${ca.ParentComdolId})`,
                            Name: `${ca.Name} (${ca.ComdolId})`,
                            Location: searchForm.groupByParent ? '' : ca.City ? ca.City + ', ' + ca.Country : '',
                            Instnum: ca.Id,
                            ComdolId: ca.ComdolId,
                            ParentComdolId: ca.ParentComdolId,
                            Status: ca.Status,
                            My: ca.My,
                            PrTr: searchForm.groupByParent ? '' : ca.PrimaryTrader,
                            PrBk: searchForm.groupByParent ? '' : ca.PrimaryBroker,
                            Date: moment(c.TradeDate).format('LL'),
                            Comment: c.Description,
                            Received: c.IsReceived ? c.TradeCommission : 0,
                            Outstanding: c.IsReceived ? 0 : c.TradeCommission,
                            Total: c.TradeCommission,
                        } as CheckReportData
                    });
                })
                .filter(c => this.showCheck(c, searchForm.searchTerm, searchForm.myAccounts))
                .reduce((acc, curr) => {
                    let findAggFunc = (c) =>
                        (searchForm.groupByParent ? c.ParentName === curr.ParentName : c.Name === curr.Name) &&
                        c.Date === curr.Date &&
                        c.Comment === curr.Comment;

                    if (searchForm.groupByParent && acc.some(findAggFunc)) {
                        let existingCheck = acc.find(findAggFunc);
                        existingCheck.Received += curr.Received;
                        existingCheck.Outstanding += curr.Outstanding;
                        existingCheck.Total += curr.Total;
                    } else {
                        acc.push(curr);
                    }
                    return acc;
                }, [])
                .sort((c1, c2) => moment(c1.Date).isBefore(c2.Date) ? 1 : -1);
            }),
            tap((data: CheckReportData[]) => {
                this.pinnedTopRowData = [{
                    Received: _.sumBy(data, d => d.Received),
                    Outstanding: _.sumBy(data, d => d.Outstanding),
                    Total: _.sumBy(data, d => d.Total),
                }]
            })
        );
    }

    showCheck(check: CheckReportData, searchTerm: string, myAccounts: boolean): boolean {
        if (myAccounts && !check.My) return false;

        return this.checkFilter(check, searchTerm);
    }

    private checkFilter(check: CheckReportData, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        if (_.startsWith(check.Name.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (c: CheckReportData): boolean => {

            const fieldsContains = _.map([check.ParentName, check.Name, check.Location], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }));
            });
        };

        return query(check);
    }

    onRowClicked(params) {
        params.node.setExpanded(!params.node.expanded);
    }


    onGridReady(params) {
        super.onGridReady(params);
        this.defaultSorting();
    }

    currencyFormatter(params: any): string {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        if (params.node.hasChildren() || params.node.rowPinned) return formatter.format(params.value);
        else if (params.column.colId !== 'Total') return params.value === 0 ? '' : formatter.format(params.value);
        else return ''
    }

    colorCell(params: CellClassParams) {
        if (!params.node.hasChildren() && !params.node.rowPinned) return {backgroundColor: '#d9edf7'};
    }

    getComment(params: any): string {
        return params.value;
    }

    renderBasicInfo(params: any): string {
        if (!this.checksSearchForm.get('groupByParent').value && params.node.level === 1) {
            return params.value;

        }
        return '';
    }

    defaultSorting() {
        this.gridColumnApi.applyColumnState({
            state: [
                { colId: 'ParentName', sort: 'asc', sortIndex: 0 },
                { colId: 'Date', sort: 'desc', sortIndex: 1 },
                { colId: 'Name', rowGroup: false, hide: true},
            ]
        });
    }

    nameGroupedSorting() {
        this.gridColumnApi.applyColumnState({
            state: [
                {colId: 'ParentName', sort: 'asc', sortIndex: 0},
                {colId: 'Name', rowGroup: true, hide: true},
                {colId: 'Date', sort: 'desc', sortIndex: 1},
            ]
        });
    }
}

export interface CheckReportData {
    ParentName: string;
    Name: string;
    Location: string;
    Instnum: number
    ComdolId: string;
    ParentComdolId: string;
    Status: string;
    My: boolean;
    PrTr: string;
    PrBk: string;
    Date: string;
    Comment: string;
    Received: number;
    Outstanding: number;
    Total: number;
}
