<div *ngFor="let item of state; trackBy:trackByFn; let i=index" class="row form-group">
    <span>
        <input type="text" 
               id="textbox-list{{i}}" 
               [ngModel]="item" 
               (ngModelChange)="handleChange(i, $event)" 
               [ngModelOptions]="options" 
               class="form-control col-sm-6" 
               style="display: inline; width: 80%" >
        <label [for]="i" class="col-form-label col-sm-1" style="margin-top: 3px">
            <a (click)="remove(i)" title="Remove">
                <i class="fa fa-trash-o"></i>
            </a>
        </label>
    </span>
</div>
<div *ngIf="allowAdd">
    <span class="btn btn-success" (click)="add()">Add</span>
</div>