import { NgModule } from "@angular/core";
import {TickerDetailComponent} from "./TickerDetail/ticker-detail.component";
import {WidgetModule} from "../Widget/widget.module";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TickerAccountSummaryComponent} from "./TickerAccountSummary/ticker-account-summary.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TickerPopoutComponent} from "./TickerPopout/ticker-popout.component";
import {TypeaheadModule} from "ngx-bootstrap/typeahead";
import {RouterModule} from "@angular/router";
import {tickerRoutes} from "./ticker.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";


@NgModule({
    imports: [
        WidgetModule,
        TabsModule,
        AgGridModule,
        CommonModule,
        ReactiveFormsModule,
        TypeaheadModule,
        RouterModule.forChild(tickerRoutes)
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ],
    declarations: [
        TickerDetailComponent,
        TickerAccountSummaryComponent,
        TickerPopoutComponent
    ],
})
export class TickerModule {
    constructor(){
        console.log("registering the TickerModule");
    }
}
