export class AdminRoutePaths {
  static readonly BasePath: string = "admin";

  static readonly UserGroupsPathFragment: string = "usergroups";
  static readonly ListGroupsPathFragment: string = "listgroups";
  static readonly MifidAccountsPathFragment: string = "mifidaccounts";
  static readonly UserFeaturesPathFragment: string = "userfeatures";
  static readonly UserListPathFragment: string = "users";
  static readonly UserTeamsListPathFragment: string = "userteams";
  static readonly QueueMeetMaxEventsPathFragment: string = "queuemeetmaxevents";

  static readonly AdminDashboard: string = AdminRoutePaths.BasePath;
  static readonly UserGroups: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.UserGroupsPathFragment}`
  static readonly ListGroups: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.ListGroupsPathFragment}`
  static readonly MifidAccounts: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.MifidAccountsPathFragment}`
  static readonly UserFeatures: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.UserFeaturesPathFragment}`
  static readonly UserList: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.UserListPathFragment}`
  static readonly UserTeamsList: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.UserTeamsListPathFragment}`
  static readonly QueueMeetMaxEvents: string = `${AdminRoutePaths.BasePath}/${AdminRoutePaths.QueueMeetMaxEventsPathFragment}`
}
