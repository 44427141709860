import * as _ from "lodash";


export const objectSearchPredicate = (item: any, searchTerm: string): boolean => {
    if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
        return true;
    }
    let allPropsRecursive = (item) => {
        var props = [];
        if (_.isObject(item)) {
            props = props.concat(_.map(_.valuesIn(item), i => allPropsRecursive(i)));
        } else if (_.isNil(item) || (!_.isNumber(item) && _.isEmpty(item))) {
            return props;
        } else {
            props.push(item);
        }

        return props;
    };
    let words = _.filter(_.split((searchTerm.toLowerCase()).trim(), " "), (q) => q !== "");
    let theQuery = (item): boolean => {
        let fields = _.map(allPropsRecursive(item), f => (f || "").toString().toLowerCase());
        return _.some(words, (q) => {
            return _.some(fields, (prop) => {
                return _.includes(prop, q);
            })
        })
    };
    return theQuery(item);
};