import {Component, OnInit} from "@angular/core";


@Component({
    selector: "app-yes-no-single-select",
    templateUrl: "./yes-no-single-select.component.html"
})
export class YesNoSingleSelectComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {

    }
}
