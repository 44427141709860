import { UserService } from '../Services/user.service';
import {Injectable} from "@angular/core";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";

@Injectable()
export class InstiselHttpHeaderInterceptor implements HttpInterceptor {

    constructor(private userService: UserService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // Get the auth token from the service.
        const teamIdOverride = this.userService.teamIdOverride;
        
        if (teamIdOverride) {
            const teamIdReq = req.clone({
                headers: req.headers.set('TeamId', teamIdOverride.toString())
            });
            return next.handle(teamIdReq);
        }
        
        // send cloned request with header to the next handler.
        return next.handle(req);
    }
}