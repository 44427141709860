import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, Validators} from "@angular/forms";

@Component({
    selector: "app-account-select-modal",
    templateUrl: "./account-select-modal.component.html"
})
export class AccountSelectModalComponent implements OnInit {

    @Input()
    message: string;

    @Input()
    excludeAccountId: number;

    @Output()
    accountId$ = new EventEmitter<number>();

    accountIdControl = this.fb.control([], [Validators.required]);

    constructor(public bsConfirmModalRef: BsModalRef, private fb: UntypedFormBuilder) { }

    ngOnInit(): void { }

    confirm(): void {
        this.bsConfirmModalRef.hide();
        this.accountId$.emit(this.accountIdControl.value[0]);
    }

    decline(): void {
        this.bsConfirmModalRef.hide();
    }
}
