<div class="modal-content">
    <form [formGroup]="researchOnlineFormGroup" id="contactForm">
        <div class="modal-header">
            <h3 class="modal-title">{{updateType}} Research Online Entitlement</h3>
            <app-contact-name [contactId]="contactId"></app-contact-name>
        </div>
        <div class="modal-body">
            <div class="flex-vbox flex-gap" *ngIf="!isLoading">
                <div class="flex alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="form-group">
                    <button *ngIf="!isUsernameAndPasswordActive"
                            type="button"
                            class="btn btn-primary btn-xs pull-right"
                            (click)="activateUsernameAndPassword()">
                        Change Username and Password
                    </button>
                    <label>Username</label>
                    <input type="text" class="form-control input-sm" formControlName="username" [placeholder]="placeholderUsername"/>
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input type="text" class="form-control input-sm" formControlName="password"/>
                </div>
                <div class="form-group">
                    <label>Notes</label>
                    <textarea type="text" formControlName="notes" maxlength="8000" rows="3" class="form-control"></textarea>
                </div>
                <ng-container *ngIf="updateType !== UpdateType.Create">
                    <div class="form-group">
                        <label>Last Updated</label>
                        <input type="text" class="form-control input-sm" formControlName="lastUpdated" />
                    </div>
                    <div class="form-group">
                        <label>Created</label>
                        <input type="text" class="form-control input-sm" formControlName="created" />
                    </div>
                </ng-container>
            </div>
            <app-loading loadingText="Loading" *ngIf="isLoading"></app-loading>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button"
                        class="btn btn-sm btn-primary flex"
                        [disabled]="researchOnlineFormGroup.invalid || isLoading"
                        (click)="save()">
                    Save
                </button>
                <button type="button"
                        *ngIf="updateType === UpdateType.Edit"
                        class="btn btn-sm btn-danger flex"
                        [disabled]="isLoading"
                        (click)="delete()">
                    Remove
                </button>
                <button 
                        type="button"
                        class="btn btn-sm btn-default flex"
                        (click)="cancel()">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>