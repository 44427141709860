import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventService} from "../../Shared/Services/event.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {
    CalendarEvent, UpcomingEventEmailTemplateTypes
} from "../../../Components/Events/CalendarEvent";
import * as _ from "lodash";
import {ColDef} from "ag-grid-community";
import {User} from "../../Shared/Models/user";
import {getNoRowsOverlayTemplate} from "../../Shared/ag-grid-options";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {nullableValueComparator} from "../../Shared/ag-grid-cell-comparators";
import {getEventDisplayName} from "../../../Helpers/DisplayStringHelper";
import {UserService} from "../../Shared/Services/user.service";
import { EventEmailTemplate } from "../../../Models/EventEmailTemplate";
import {combineLatest, of} from "rxjs";
import {startWith} from "rxjs/operators";

@Component({
    selector: "app-event-form",
    templateUrl: "./upcoming-events-modal.component.html"
})
export class UpcomingEventsModal extends BaseGridComponent<CalendarEvent> implements OnInit {

    includeConferencesEmailSection: boolean = true;

    loadingEvents: boolean = false;
    loadingTemplates: boolean = false;
    loadingExcludedEventIds: boolean = false;
    loadingConferencesEmailSection: boolean = false;
    loadingAllUsers: boolean = false;
    saving: boolean = false;
    downloading: boolean = false;

    allUsers: User[] = [];
    eventEmailTemplates: EventEmailTemplate[];

    upcomingEventsForm = this.fb.group({
        eventEmailTemplateId: this.fb.control(1),
        conferencesEmailSection: this.fb.control(''),
    });

    columnDefs: ColDef[] = [
        {
            field: 'isEditable',
            headerName: '',
            width: 36,
            headerCheckboxSelection: () => true,
            checkboxSelection: () => true,
        },
        {
            field: 'BeginDate',
            headerName: 'Start Date',
            maxWidth: 108,
            headerTooltip: 'Start Date',
            sort: 'asc',
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: 'EndDate',
            headerName: 'End Date',
            maxWidth: 85,
            headerTooltip: 'End Date',
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: 'Name',
            headerName: 'Event Name',
            headerTooltip: 'Event Name',
            flex: 1,
            valueGetter: (params) => getEventDisplayName(params.data),
            tooltipValueGetter: (params) => getEventDisplayName(params.data),
            comparator: nullableValueComparator
        },
        {
            field: 'Type',
            headerName: 'Category',
            maxWidth: 115,
            headerTooltip: 'Category',
            flex: 1,
            tooltipValueGetter: (params) => params.data.Type,
            comparator: nullableValueComparator
        },
        {
            field: 'Location',
            headerName: 'Location/Time',
            maxWidth: 215,
            headerTooltip: 'Location/Time',
            flex: 1,
            valueGetter: (params) => params.data.Location || '',
            tooltipValueGetter: (params) => params.data.Location,
            comparator: nullableValueComparator
        },
        {
            field: 'AnalystUserIds',
            headerName: 'Analyst(s)/Broker(s)',
            maxWidth: 155,
            headerTooltip: 'Analyst(s)/Broker(s)',
            flex: 1,
            valueGetter: _.bind(this.renderAnalystsAndBrokers, this),
            tooltipValueGetter: _.bind(this.renderAnalystsAndBrokers, this),
            comparator: nullableValueComparator
        },
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private eventService: EventService,
        private userService: UserService,
        private toastrService: ToastrService,
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.overlayNoRowsTemplate = getNoRowsOverlayTemplate('Upcoming Events');

        this.gridOptions.rowSelection = "multiple";
        this.gridOptions.rowMultiSelectWithClick = true;
        this.gridOptions.getRowId = params => `${params.data.Id}`;

        this.loadingAllUsers = true;
        this.userService.getUsers()
            .subscribe(users => {
                this.allUsers = users;
                this.loadingAllUsers = false;
            });

        this.loadingEvents = true;
        this.eventService.getUpcomingEvents().subscribe((upcomingEvents) => {
            this.loadingEvents = false;
            this.rowData$ = of(upcomingEvents);

            this.loadingTemplates = true;
            this.eventService.getEventEmailTemplates().subscribe((eventEmailTemplates) => {
                this.loadingTemplates = false;
                this.eventEmailTemplates = eventEmailTemplates;
            });

            this.upcomingEventsForm.get("eventEmailTemplateId").valueChanges
                .pipe(startWith(1))
                .subscribe((templateId) => {
                    this.includeConferencesEmailSection = templateId === UpcomingEventEmailTemplateTypes.General;

                    this.loadingExcludedEventIds = true;
                    this.loadingConferencesEmailSection = this.includeConferencesEmailSection;

                    this.eventService.getEventEmailTemplateExcludedEventIds(templateId).subscribe((excludedEventIds) => {
                        this.loadingExcludedEventIds = false;

                        this.gridApi.forEachNode(function (node) {
                            node.setSelected(!!excludedEventIds.find(e => e === node.data.Id));
                        });
                    });

                    if (this.includeConferencesEmailSection) {
                        this.eventService.getEventEmailTemplateConferencesHtml(templateId).subscribe((eventEmailTemplateConferencesHtmlDto) => {
                            this.loadingConferencesEmailSection = false;

                            this.upcomingEventsForm.patchValue({
                                conferencesEmailSection: eventEmailTemplateConferencesHtmlDto.Html
                            })
                        });
                    } else {
                        this.upcomingEventsForm.patchValue({
                            conferencesEmailSection: null
                        })
                    }
                });
        });
    }

    onGridReady(params) {
        super.onGridReady(params);
    }

    isLoading() {
        return (
            this.loadingEvents ||
            this.loadingTemplates ||
            this.loadingExcludedEventIds ||
            this.loadingConferencesEmailSection ||
            this.loadingAllUsers
        );
    }

    saveTemplate() {
        this.saving = true;

        let templateId =  this.upcomingEventsForm.get("eventEmailTemplateId").value;
        let templateName = this.eventEmailTemplates.find(t => t.Id === templateId)?.Name;
        let eventIds = this.gridApi.getSelectedRows().map(r => r.Id);
        let conferencesEmailSection =  this.upcomingEventsForm.get("conferencesEmailSection").value;

        let updateExclusions$ = this.eventService.updateEventEmailTemplateExclusions(templateId, eventIds);
        let updateConferencesHtml$ = this.eventService.updateEventEmailTemplateConferencesHtml(templateId, conferencesEmailSection);
        
        combineLatest([updateExclusions$, updateConferencesHtml$])
            .subscribe(
                () => {
                    this.saving = false;
                    this.toastrService.success(`Successfully updated "${templateName}" Template`);
                },
                (error) => {
                    this.saving = false;
                    this.toastrService.error(`Failed to update "${templateName}" Template: ${error.statusText}`);
                }
            );
    }

    downloadTemplate() {
        if (this.upcomingEventsForm.valid) {
            this.downloading = true;

            const eventEmailTemplateId = this.upcomingEventsForm.get("eventEmailTemplateId").value as number;
            const excludeEventIds = this.gridApi.getSelectedRows().map(r => r.Id);
            const conferencesEmailSection = this.upcomingEventsForm.get("conferencesEmailSection").value as string;

            this.eventService
                .getUpcomingEventsEmail(eventEmailTemplateId, excludeEventIds, conferencesEmailSection)
                .subscribe(
                    () => {
                        this.downloading = false;
                        this.toastrService.success("Download complete");
                    },
                    (error) => {
                        this.downloading = false;
                        this.toastrService.error(`Download failed: ${error.statusText}`);
                    }
                );
        }
    }

    closeModal() {
        this.modalRef.hide();
    }

    renderAnalystsAndBrokers(params: any): string {
        return _.chain(this.allUsers)
            .filter(u => _.includes(params.data.AnalystUserIds?.concat(params.data.BrokerUserIds || []) || [], u.Id))
            .map(u => u.LastName)
            .uniq()
            .sortBy(ln => ln)
            .join(', ')
            .value();
    }
}
