import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {AccountVote} from "../../Account/AccountVotes/account-vote";

@Injectable({
    providedIn: 'root',
})

export class AccountVoteService {

    constructor(private baseHttp: BaseHttpService) { }

    getByAccount(id: number): Observable<AccountVote[]> {
        return this.baseHttp.getData(`/accountvote/accountid/${id}`);
    }

    getById(id: number): Observable<AccountVote> {
        return this.baseHttp.getData(`/accountvote/id/${id}`);
    }

    createAccountVote(accountVote: AccountVote): Observable<AccountVote> {
        return this.baseHttp.postData(`/accountvote`, accountVote)
    }

    updateAccountVote(accountVote: AccountVote): Observable<AccountVote> {
        return this.baseHttp.putData(`/accountvote/id/${accountVote.Id}`, accountVote);
    }

    deleteAccountVote(id: number): Observable<void> {
        return this.baseHttp.deleteData(`/accountvote/voteid/${id}`)
    }

    getAttachment(id: number): Observable<void> {
        return this.baseHttp.getFileWithApiFileName(`/accountvote/attachment/id/${id}`, FileAcceptType.Any, {});
    }

    uploadAttachment(accountVoteId: number, file: any): Observable<any> {
        return this.baseHttp.postData(`/accountvote/id/${accountVoteId}/attachment`, file);
    }
}
