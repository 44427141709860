
<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline">
            <div class="input-group">
                <div class="input-group-btn">
                    <div class="btn btn-primary btn-sm" (click)="addQuery()"><span class="fa fa-plus"></span></div>
                </div>
                <input type="search"
                       class="form-control input-sm"
                       style="width: 250px;"
                       [formControl]="querySearchBox"
                       placeholder="Search" />
            </div>
        </form>
    </ng-template>

    <app-page-banner
            pagetitle="Queries"
            faicon="fa-search-plus"
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>

    </div>
</div>
