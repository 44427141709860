import { Component, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";

import {EntitlementService} from "../../../Shared/Services/entitlement.service";
import { map } from "rxjs/operators";

@Component({
    selector: "app-business-unit-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(BusinessUnitPickerComponent)
})
export class BusinessUnitPickerComponent extends BasePickerComponent<BusinessUnitDropdownOption> {

    @Input()
    alignRight: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    showSelectClearAll: boolean;

    @Input()
    placeHolder: string;

    constructor(fb: UntypedFormBuilder, private entitlementService: EntitlementService) {
        super(fb);

        this.name = "Business Unit(s)";
        this.placeHolder = "Business Unit(s)";

        this.bindLabel = "Name";
        this.bindValue = "Value";
    }

    ngOnInit() {
        super.ngOnInit();
        this.showSelectClearAll = this.multiple;
        this.items$ = this.entitlementService.getBusinessUnits().pipe(map((businessUnits) => {
            let businessUnitsWithBlankOption = businessUnits.map(bu => {
                return {
                    Name: bu.Name,
                    Value: bu.Name
                } as BusinessUnitDropdownOption;
            });

            businessUnitsWithBlankOption.push({
                Name: '',
                Value: null
            } as BusinessUnitDropdownOption);

            return businessUnitsWithBlankOption;
        }));
    }
}

export interface BusinessUnitDropdownOption {
    Name: string;
    Value: string | null;
}
