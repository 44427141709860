import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../Shared/Models/user";
import {combineLatest} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {ColDef} from "ag-grid-community";
import {BaseGridComponent} from "../../Shared/base-grid.component";

@Component({
    selector: "app-user-list",
    templateUrl: "./user-list.component.html"
})
export class UserListComponent extends BaseGridComponent<User> implements OnInit {

    columnDefs: ColDef[] = [
        { field: 'Id', sort: 'asc', flex: 1 },
        { field: 'FirstName', flex: 1 },
        { field: 'LastName', flex: 1 },
        { field: 'Email', flex: 1 },
        { colId: 'Team', headerName: 'Team', flex: 1, valueGetter: this.teamValueGetter },
        { headerName: 'Status', field: 'IsActive', flex: 1, valueFormatter: this.userActiveFormatter },
    ];

    userSearchBox: UntypedFormControl = this.fb.control('');

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        const allUsers$ = this.userService.getUsers();
        const formUpdate$ = this.userSearchBox.valueChanges.pipe(startWith(''));

        this.rowData$ = combineLatest([allUsers$, formUpdate$]).pipe(
            map(([users, searchTerm]) => users.filter(u => this.showUser(u, searchTerm)))
        );
    }

    showUser(user: User, searchTerm: string): boolean {

        if (!user.FirstName) return false;

        return objectSearchPredicate(user, searchTerm);
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    teamValueGetter(params): string {
        let role = params.data.Role && params.data.Role.Name || '';
        let team = params.data.Team && params.data.Team.Name || '';
        return role === team ? role : `${role}: ${team}`;
    }

    userActiveFormatter(params) {
        return params.value ? `Active` : `Inactive`;
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }
}
