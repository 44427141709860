import * as moment from "moment";
import {CalendarEventAttendee} from "./CalendarEventAttendee";
import {CalendarEventAccount} from "./CalendarEventAccount";
import {Query} from "../../Services/QueryService";

export class CalendarEvent {
    Id: number;
    RequestId: number;
    Year: number;
    BeginDate?: Date | moment.Moment | string;
    EndDate?: Date | moment.Moment | string;
    Name: string;
    EventConferenceId: number;
    Tickers: string[];
    Type: string;
    EventTypeId: number;
    PresenterTypeId: number;
    SubCategory: string;
    Cost: number;
    AnalystUserIds: string[];
    BrokerUserIds: string[];
    CoordinatorUserIds: string[];
    Notes: string;
    Location: string;
    Coordinator: string;
    AllCompanies: boolean;
    IsPublic : boolean;
    PrivateNotes: string;
    Attendees: CalendarEventAttendee[];
    Accounts: CalendarEventAccount[];
    StatusCounts: EventStatusCount[];
    InviteQuery: Query;
    IsActive : boolean;
    Presenters: CalendarEventPresenter[];
    IERRegistrationLink?: string;
    GIBRegistrationLink?: string;
    MaxAttendance?: string;
    WebcastId?: string;
    MeetMaxId?: string;
    LastInviteSent?: Date | moment.Moment | string;
    DryRunScheduled?: string;
    TiersSectorsInvited?: string;
    InviteLink?: string;
    TimeZoneId?: number;
    NumberOfAttendees: number;
    NumberOfInterested: number;
    AgendaLink?: string;
    MeetingCoordinator?: TravelRequestCoordinator[];
    Status: string;
    Sales: CalendarEventPresenter[];
    Researchers: CalendarEventPresenter[];
    SectorId?: number;
    EmailCategory: string;
    MarketingLink?: string;
}

export class EventType {
    Id: number;
    Name: string;
}

export class EventPresenterType {
    Id: number;
    Name: string;
}

export class EventCompany {
    Id: number;
    Ticker: string;
    Name: string;
    AccountId: number;
}

export class EventConference {
    Id: number;
    Name: string;
}

export class EventStatusCount {
    Name: string;
    Count: number;
}

export class CompanyPerson {
    Id: number;
    FirstName: string;
    LastName: string;

    CompanyId: number;
    label: string;
}

export class CalendarEventPresenter {
    FirstName: string;
    LastName: string;
    Title: string;
    CompanyName: string;
}

export interface EventMeeting {
    MeetingId: number,
    EventId: number,
    CompanyIds: number[],
    AnalystIds: string[],
    WebcastId?: string,
    Date: string | moment.Moment,
    StartTime: string | moment.Moment,
    EndTime: string | moment.Moment,
    Duration: number,
    DisplayName: string,
    DisplayTimeZone: string,
    MeetingType: string,
    CancelledNoShowOverride: string,
    ActivityCategory: string,
    Tickers: string[],
    EventPresenters: CalendarEventPresenter[],
    NumberOfInterested: number,
    NumberOfAttendees: number,
    TimeZoneId?: number,
    EventTimeZoneId?: number,
    ActivityStatusCounts: ActivityStatusCount[],
    Location: string,
}

export interface RequestEventMeeting {
    Id: number,
    MeetingDate: string | moment.Moment,
    MeetingTime: string | moment.Moment,
    Duration: number,
    MeetingType: string,
    NumberOfInterested: number,
    NumberOfAttendees: number,
    Activities: CalendarEventAttendee[]
}

export interface ActivityStatusCount {
    ActivityStatus: string;
    Count: string;
    MyCount: string;
}

export interface TravelRequestCoordinator{
    FirstName: string;
    LastName: string;
}

export enum UpcomingEventEmailTemplateTypes {
    General = 1
}
