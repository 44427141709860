<div class="page-content flex-vbox contact-detail">

    <ng-template #bannerSubTitleTemplate>
        <div *ngIf="contact" class="contact-links">
            <div class="flex-hbox">
                <div class="contact-picker">
                    <app-contact-picker [formControl]="selectedContactId" [accountId]="contact.Account?.Id"></app-contact-picker>
                </div>
                <div class="icons">
                    <button
                        type="button"
                        class="btn btn-sm btn-default"
                        appIfUserFeature="EditContact"
                        appRequiresFeature="contact-add-new"
                        (click)="addContact()"
                        tooltip="Add New Contact"
                        placement="bottom"
                    >
                        <span class="fa fa-plus"></span>
                    </button>
                    <app-contact-favorite [contactId]="contact.Id"></app-contact-favorite>
                    <app-contact-lists-popover [contactId]="contact.Id"></app-contact-lists-popover>
                    <app-contact-detail-reports [contact]="contact"></app-contact-detail-reports>

                    <app-research-access
                        [showPopover]="true"
                        [contactId]="contact.Id"
                    >
                    </app-research-access>

                </div>
            </div>
            <div class="account-link">
                <i *ngIf="isVote" class="label-vote fa fa-check-circle icon vote" tooltip="Vote" placement="bottom" style="padding-right: 4px" ></i>
                <a class="pointer" (click)="goToAccount()">{{ contact.Account?.Name }} <span *ngIf="contact.Account?.Name2 !== ''">{{'(' + contact.Account?.Name2 + ')'}}</span> - {{ contact.Account?.City }}, {{ contact.Account?.State }} {{ contact.Account?.Country }}</a>
            </div>
        </div>
    </ng-template>
    <ng-template #bannerFormTemplate>
        <div *ngIf="contact" class="flex-hbox overflow-ellipsis">
            <div class="flex-4 left overflow-ellipsis">
                <div>
                    <span class="bold">Title: </span>{{ contact.Title }}
                </div>
                <div>
                    <span class="bold">Email: </span><a [href]="'mailto:' + contact.Email">{{ contact.Email }}</a>
                </div>
<!--                Hide PrBk but not removing as we may want to add this back in later.-->
                <div style="display: none;">
                    <span class="bold">PrBk: </span>{{ contact.Account?.PrimaryBroker?.LastName }}
                </div>
            </div>
            <div class="flex-4 left overflow-ellipsis">
                <div>
                    <span class="bold">Phone: </span><app-click-to-dial [phone]="contact.PhoneNumber" [extension]="contact.PhoneNumberExtension"></app-click-to-dial>
                </div>
                <div>
                    <span class="bold">Mobile: </span><app-click-to-dial [phone]="contact.MobilePhoneNumber"></app-click-to-dial>
                </div>
                <div>
                    <span class="bold">PrTr: </span>{{ contact.Account?.PrimaryTrader?.LastName }}
                </div>
            </div>
            <div class="flex-4 left overflow-ellipsis">
                <div>
                    <span class="bold">PersBk: </span>{{ contact?.Broker?.LastName }}
                </div>
                <div>
                    <span class="bold">Assistant(s): </span>
                    <app-contact-popover
                            [contactIds]="assistantIds"
                            valueForMultiple="Multiple"
                            title="Assistant Overview"
                            placement="bottom">
                    </app-contact-popover>
                </div>
                <div>
                    <span class="bold">Contact Tier: </span>{{ contact.Tier }}
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #iconTemplate>
        <div style="position: relative; cursor: pointer; height: 50px; width: 50px;"
             appRequiresFeature="contact-image"
             (click)="openContactImageModal()">
            <img *ngIf="contactImageSrc"
                 [src]="contactImageSrc"
                 alt="contact profile image"
                 style="position: absolute; top: 0; left: 0; max-height: 50px; max-width: 50px; border-radius: 3px;" />
            <div class="fa fa-2x fa-users list-heading-badge"
                 *ngIf="!contactImageSrc"
                 [style.background-color]="'#1e90ff'"
                 style="color: white;"></div>
        </div>
        <div class="fa fa-2x fa-users list-heading-badge"
             appRequiresNotFeature="contact-image"
             [style.background-color]="'#1e90ff'"
             style="color: white;"></div>
    </ng-template>

    <app-page-banner
            [iconTemplate]="iconTemplate"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate"
            bannerClass="contact-detail-banner">
    </app-page-banner>

    <app-do-not-contact-banner [reason]="contact?.Account?.ResearchStatus?.Reason || contact?.ResearchStatus?.Reason"></app-do-not-contact-banner>

    <div class="page-body flex flex-hbox flex-gap">

        <div class="flex flex-vbox">
            <tabset class="flex-tabset">
                <tab heading="Activities" [active]="defaultTab === 'Activities'">
                    <app-tab-activity-list
                        class="flex flex-vbox"
                        [contactId]="contactId"
                        [showContactShortName]="false"
                        [showAccountName]="false"
                        [dateRange]="defaultDateRange"
                        [dateRangeCenterAlign]="true"
                        [activityDataChanged]="activityDataChanged"
                        [showActivityReassign]="true"
                    >
                    </app-tab-activity-list>
                </tab>
                <tab heading="Details" [active]="defaultTab === 'Details'">
                    <app-contact-details
                        class="flex flex-vbox"
                        [contactId]="contactId"
                        [contactDataChanged]="contactDateChanged"
                        [activityDataChanged]="activityDataChanged"
                    >
                    </app-contact-details>
                </tab>
                <tab heading="Profile" [active]="defaultTab === 'Profile'">
                    <app-contact-profile
                        class="flex flex-vbox"
                        [contactId]="contactId"
                        [contactDataChanged]="contactDateChanged"
                    >
                    </app-contact-profile>
                </tab>
                <tab heading="EES" [active]="defaultTab === 'EES'" *ngIf="isEESVisible">
                    <app-tab-ees-activity-list
                        class="flex flex-vbox"
                        [contactId]="contactId"
                        [showContactShortName]="false"
                        [showAccountName]="false"
                        [dateRange]="defaultDateRange"
                        [dateRangeCenterAlign]="true"
                    >
                    </app-tab-ees-activity-list>
                </tab>
            </tabset>
        </div>

        <div class="flex flex-vbox flex-gap">

            <div class="flex flex-vbox">
                <tabset class="flex-tabset">
                    <tab heading="Professional">
                        <app-contact-note class="flex flex-vbox"
                                          [contactId]="contactId"
                                          [noteType]="'Professional'">
                        </app-contact-note>
                    </tab>
                    <tab heading="Personal">
                        <app-contact-note class="flex flex-vbox"
                                          [contactId]="contactId"
                                          [noteType]="'Personal'">
                        </app-contact-note>
                    </tab>
                    <tab heading="Exceptions">
                        <app-contact-note class="flex flex-vbox"
                                          [contactId]="contactId"
                                          [noteType]="'Exceptions'">
                        </app-contact-note>
                    </tab>
                    <tab *ngIf="hasResearchTeam" heading="Team - {{ teamName }}">
                        <app-contact-note class="flex flex-vbox"
                                          [contactId]="contactId"
                                          [noteType]="'Team'">
                        </app-contact-note>
                    </tab>
                    <tab *ngIf="isEESVisible" heading="EES">
                        <app-contact-note class="flex flex-vbox"
                                          [contactId]="contactId"
                                          [noteType]="'EES'">
                        </app-contact-note>
                    </tab>
                </tabset>
            </div>

            <div class="flex-3 flex-vbox">
                <tabset class="flex-tabset">
                    <tab heading="Interests">
                        <app-tab-interest-list class="flex flex-vbox"
                                               [contactDataChanged]="contactDateChanged"
                                               [interestDataChanged]="interestDataChanged"
                                               [contactId]="contactId"
                                               [allowEditing]="true">
                        </app-tab-interest-list>
                    </tab>
                    <tab heading="Subscriptions">
                        <app-contact-subscription-list class="flex flex-vbox"
                                                       [contactDataChanged]="contactDateChanged"
                                                       [contactId]="contactId"
                                                       [interestDataChanged]="interestDataChanged"
                        ></app-contact-subscription-list>
                    </tab>
                    <tab heading="Readership">
                        <app-contact-readership class="flex flex-vbox"
                                                [contactId]="contactId"></app-contact-readership>
                    </tab>
                    <tab heading="Preferences">
                        <app-contact-preferences class="flex flex-vbox"
                                                 [contactId]="contactId"
                                                 [interestDataChanged]="interestDataChanged"
                                                 [contactDataChanged]="contactDateChanged"></app-contact-preferences>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>
