import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface TravelTerritory {
    Id: number;
    Name: string;
}

@Injectable({
    providedIn: 'root',
})
export class TravelTerritoryService {

    constructor(private baseHttp: BaseHttpService) { }

    getTravelTerritories(): Observable<TravelTerritory[]> {
        return this.baseHttp.getCacheOrData(`/travelterritory`, 5 * 60 * 1000);
    }
}
