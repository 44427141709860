import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { UserService } from './Modules/Shared/Services/user.service';
import {User} from "./Models/User";
import {filter, map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import { ConfigService } from './Modules/Shared/Services/config.service';
import {PageService} from "./Modules/Shared/Services/page.service";

@Injectable()
export class UserCanActivateRoute implements CanActivate {

    constructor(
        private userService: UserService,
        private toastrService: ToastrService,
        private router: Router,
        private configService: ConfigService,
        private pageService: PageService
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree {

        if (this.pageService.newVersionAvailable()) {
            window.location.reload();
        }

        return this.userService.getCurrentUser().pipe(
            filter(user => !!user.Id),
            map(user => {
                if (user.Id === "INVALID" && state.url !== "/accessdenied") {
                    this.router.navigate(["accessdenied"]);
                    return false;
                }

                if (!this.userHasRequiredFeaturesToActivateRoute(route, user)) {
                    this.toastrService.error("Access Denied to that Page");
                    this.router.navigate(["home"]);
                    return false;
                }

                if (
                    this.configService.getBuyBackUsers().find(buyBackUser => buyBackUser === user.Id) &&
                    !ConfigService.BuyBackUserRoutes.find(buyBackUserRoute => state.url.startsWith(`/${buyBackUserRoute}`))
                ) {
                    this.router.navigate(["reports/tradedetail"]);
                    return false;
                }

                return true;
            })
        );
    }

    userHasRequiredFeaturesToActivateRoute(
        route: ActivatedRouteSnapshot,
        user: User
    ): boolean {
        if (
            !route.data ||
            !route.data.requiredFeatures ||
            route.data.requiredFeatures.length === 0
        ) {
            return true;
        }

        return (
            route.data.requiredFeatures.length ===
            route.data.requiredFeatures.filter(f => user.Features.find(uf => uf === f)).length
        );
    }
}