import {Component, Input, ViewEncapsulation} from "@angular/core";
import {QueryGroup, QueryPredicate} from "../../../Services/QueryService";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
    selector: "app-query-groups-readonly",
    template: `
        <div class="query-groups-readonly">
            <div *ngFor="let group of queryGroups; index as i; last as lastGroup; first as FirstGroup;">
                <div *ngFor="let query of group.Queries; index as j; last as lastQuery; first as FirstQuery;">
                    <span class="where" *ngIf="FirstGroup && FirstQuery">Where</span>
                    <span *ngIf="FirstQuery && !FirstGroup" class="and">And</span>
                    <span *ngIf="!FirstQuery && !FirstGroup" class="or">Or</span>
                    <span class="query-display-block">{{ query.Object }}</span>
                    <span class="query-display-block">{{ query.Field }}</span>
                    {{ query.Operation }}
                    <span class="query-display-block">{{ getValue(query) }}</span>
                </div>
            </div>
        </div>
    `,
    styleUrls: ["./query-groups-readonly-component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class QueryGroupsReadonlyComponent {
    @Input()
    queryGroups: QueryGroup[];

    constructor() { }

    getValue(query: QueryPredicate): string {
        if (query.Value) {
            return `"${this.convertBoolean(query)}"`
        }

        if (!_.isNil(query.Values) && !_.isEmpty(query.Values)) {
            return `(${_.join(_.map(query.Values, it => `"${it}"`), ", ")})`;
        }

        return null;
    }

    private convertBoolean(query: QueryPredicate): string | number | moment.Moment {
        if (_.isEqual(query.Object, "Account")) {
            if (_.isEqual(query.Field, "Grid") || _.isEqual(query.Field, "My Account")) {
                return _.isEqual(query.Value, "true") ? "Y" : _.isEqual(query.Value, "false") ? "N" : query.Value;
            }
        }

        if (_.isEqual(query.Object, "Contact")) {
            if (_.includes(["Favorite", "Marketing Email", "Research Email", "Research Voicemail", "My", "Do Not Contact"], query.Field)){
                return _.isEqual(query.Value, "true") ? "Y" : _.isEqual(query.Value, "false") ? "N" : query.Value;
            }
        }

        return query.Value;
    }
}
