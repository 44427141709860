import {Component, OnInit} from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";
import * as _ from "lodash";
import {User} from "../../../Models/User";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {UserFormComponent} from "../../Admin/Users/user-form.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfigService} from "../../Shared/Services/config.service";
import {MsalService} from "@azure/msal-angular";

@Component({
    selector: "app-user",
    templateUrl: "./user.component.html",
    styles: [`
        :host ::ng-deep .popover {
          width: 200px;
          font-family: "Segoe UI",Helvetica,Arial,sans-serif;
        }
    `]
})
export class UserComponent implements OnInit{

    user: User;

    team: UntypedFormControl = this.fb.control(null);

    constructor(private userService: UserService,
                private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private configService: ConfigService,
                private authService: MsalService,
    ) {}

    ngOnInit(): void {
        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
            });

        this.team.valueChanges.subscribe((id) => {
            this.userService.setTeamIdOverride(id);
        })
    }

    enableJoinTeamFeature(): boolean {
        return (this.user && this.user.Role && this.user.Role.Name === "Research" && _.includes(this.user.Features, "JGUsr_ResearchAdmin"));
    }

    canEditSelf(): boolean {
        return (this.user && _.includes(this.user.Features, "IsAdmin") && !this.configService.IsProduction());
    }

    openModalForm(): void {
        const initialState = {
            userId: this.user.Id
        };
        this.modalService.show(UserFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-lg' });
    }

    logout(): void {
        this.authService.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }
}
