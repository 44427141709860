import {Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter} from "@angular/core";
import {BaseGridComponent} from "../../../Shared/base-grid.component";
import {UntypedFormBuilder} from "@angular/forms";
import type {DateRange} from "../../DateRangePicker/date-range";
import * as moment from "moment";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {QueryGroup} from "../../../../Services/QueryService";
import {QueryTranslator} from "../../../../Helpers/QueryTranslator";
import {catchError, debounceTime, startWith, switchMap, tap} from "rxjs/operators";
import {Activity} from "../../../../Models/Activity";
import {dateRenderer, activityParticipantsRenderer} from "../../../Shared/ag-grid-cell-renderers";
import * as _ from "lodash";
import {durationHeaderTemplate, activityParticipantsValueGetter, participantsToolTip} from "../../../Shared/ag-grid-options";
import {UserService} from "../../../Shared/Services/user.service";
import {DatePickerOptionsBuilder} from "../../../../DatePickerOptionsBuilder";
import {User} from "../../../../Models/User";
import {BsModalService} from "ngx-bootstrap/modal";
import {TextModalComponent} from "../../TextModal/text-modal.component";
import {AccountService} from "../../../Shared/Services/account.service";
import {Account} from "../../../../Models/Account";
import {combineLatest, of} from "rxjs";
import * as daterangepicker from "bootstrap-daterangepicker";
import {ActivityFormComponent} from "../../../Activity/ActivityForm/activity-form.component";
import {ConfigService} from "../../../Shared/Services/config.service";
import { UserTeam } from "../../../../Models/UserTeam";
import {ReassignContactFormComponent} from "../../../Contact/ContactForm/reassign-contact-form-component";

@Component({
    selector: "app-tab-activity-list",
    templateUrl: "./tab-activity-list.component.html"
})
export class TabActivityListComponent extends BaseGridComponent<any> implements OnInit, OnChanges {

    @Input()
    ticker: string;

    @Input()
    contactId: number;

    @Input()
    accountId: number;

    @Input()
    categories: string[];

    @Input()
    dateRange: DateRange;

    @Input()
    dateRangeCenterAlign: boolean = false;

    @Input()
    showAccountName: boolean = true;

    @Input()
    showContactShortName: boolean = true;

    @Input()
    appliedQueryGroups: QueryGroup[] = [];

    @Input()
    activityDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    dateRangeTopAlign: boolean = false;

    @Input()
    showActivityReassign: boolean = false;

    notShowingAllRecords: boolean = false;
    dateRangeOptions: daterangepicker.Options;
    filteredCategories: string[];
    user: User;
    account: Account;
    users: User[];
    userTeams: UserTeam[];
    exporting: Boolean = false;
    canEditContacts: boolean = false;

    columnDefs = [
        { field: 'ContactShortName', headerName: 'Name', width: 80, cellRenderer: this.contactRenderer, tooltipValueGetter: this.contactTooltip },
        { field: 'AccountName', headerName: 'Account', flex: 2, tooltipValueGetter: (p) => p.data.AccountName },
        { field: 'Category', headerName: 'C', width: 50, headerTooltip: 'Category' },
        {
            field: 'Participants',
            headerName: 'Baird',
            width: 80,
            valueGetter: (params) => activityParticipantsValueGetter(params.data),
            cellRenderer: (params) => activityParticipantsRenderer(params.data, this.userTeams, this.users),
            tooltipValueGetter: (params) => participantsToolTip(params.data, this.users)
        },
        { field: 'Duration', headerName: '', headerTooltip: 'Duration', width: 50, headerComponentParams: {template: durationHeaderTemplate} },
        { field: 'CallDate', headerName: 'Date', width: 90, sort: 'desc', cellRenderer: dateRenderer, comparator: this.callDateComparator },
        { field: 'Comment', headerName: 'Comment', flex: 2, tooltipValueGetter: (p) => p.data.Comment },
        { field: 'Tickers', headerName: 'Tickers', flex: 1, tooltipValueGetter: (p) => p.Tickers, headerTooltip: 'Tickers' }
    ];

    rowClassRules = {
        'moved-contact': (params) => params.data.Tags && _.includes(params.data.Tags, 'Inactive'),
        'deleted-contact': (params) => params.data.Tags && _.includes(params.data.Tags, 'DeletedContact'),
    };

    form = this.fb.group({
        isParentSelect: this.fb.control(false),
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control({ start: moment().add(-3, "month"), end: moment() } as DateRange),
        categories: this.fb.control([]),
        ticker: this.fb.control(''),
        contactId: this.fb.control(0),
        accountId: this.fb.control(0),
        teamIds: this.fb.control([]),
        myActivities: this.fb.control(false),
    });

    constructor(private fb: UntypedFormBuilder,
                private activityService: ActivityService,
                private userService: UserService,
                private accountService: AccountService,
                private modalService: BsModalService,
                private configService: ConfigService) {
        super();

        this.gridOptions.pagination = true;
    }

    ngOnInit(): void {
        const optionsBuilder = new DatePickerOptionsBuilder();
        optionsBuilder.setRangesToPredefinedList(moment());
        if(this.dateRangeCenterAlign) {
            optionsBuilder.setToCenterAligned()
        }
        if (this.dateRangeTopAlign) {
            optionsBuilder.setToTopAligned();
        }
        this.dateRangeOptions = optionsBuilder.buildOptions();

        this.gridOptions.overlayNoRowsTemplate = `<span>No Activities Found</span>`;
        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;
            this.canEditContacts = this.userService.hasUserFeature(user, "EditContact");
        });

        if(!_.isNil(this.accountId)){
            this.accountService.getAccountById(this.accountId).subscribe(account => this.account = account);
        }

        if (this.dateRange) {
            this.form.patchValue({
                dateRange: this.dateRange
            });
        }

        let dateRange$ = this.form.get("dateRange").valueChanges.pipe(
            startWith(this.form.controls["dateRange"].value)
        );
        let isParentSelect$ = this.form.get("isParentSelect").valueChanges.pipe(
            startWith(this.form.controls["isParentSelect"].value)
        );

        combineLatest([dateRange$, isParentSelect$]).pipe(
            debounceTime(200),
            switchMap(() => this.activityService.getActivityCategoriesByQueryGroups(this.convertToCategoriesQuery()))
        ).subscribe(categories => {
            this.filteredCategories = categories;
            let selectedCategories = this.form.controls['categories'].value.filter(c => this.filteredCategories.includes(c));

            if(!_.isEqual(this.form.controls['categories'].value.sort(), selectedCategories.sort())) {
                this.form.patchValue({categories: selectedCategories});
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ticker) {
            this.form.controls["ticker"].patchValue(changes.ticker.currentValue);
        }
        if (changes.contactId) {
            this.form.controls["contactId"].patchValue(changes.contactId.currentValue);
        }
        if (changes.accountId) {
            this.form.controls["accountId"].patchValue(changes.accountId.currentValue);
        }
        if (changes.appliedQueryGroups) {
            this.activityDataChanged.emit(true);
        }
    }

    convertToQuery(form: any): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];

        if (form.ticker) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Tickers", "contains", [form.ticker]);
        }
        if (form.contactId) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Persnum", "contains", [form.contactId]);
        }
        if (form.accountId) {
            if (form.isParentSelect) {
                QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Parent Comdol ID", "contains", [this.account.ParentComdolId]);
            } else {
                QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Instnum", "contains", [form.accountId]);
            }
        }

        QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Call Date", "between", [form.dateRange.start, form.dateRange.end]);

        if (form.searchTerm) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Activity", "Search", "contains", form.searchTerm);
        }

        if (form.categories && form.categories.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Category", "contains", form.categories);
        }

        if (this.categories && this.categories.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Category", "contains", this.categories);
        }

        if (form.myActivities) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Baird Caller", "contains", [this.user.Id]);
        }

        if (form.teamIds && form.teamIds.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Baird Caller Team", "contains", form.teamIds);
        }

        queryGroups.push(...this.appliedQueryGroups);

        return queryGroups;
    }

    convertToCategoriesQuery(): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];
        let form = this.form.getRawValue();

        if (form.ticker) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Tickers", "contains", [form.ticker]);
        }
        if (form.contactId) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Persnum", "contains", [form.contactId]);
        }
        if (form.accountId) {
            if (form.isParentSelect) {
                QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Parent Comdol ID", "contains", [this.account.ParentComdolId]);
            } else {
                QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Instnum", "contains", [form.accountId]);
            }
        }

        QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Call Date", "between", [form.dateRange.start, form.dateRange.end]);

        if (this.categories?.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Category", "contains", this.categories);
        }

        return queryGroups;
    }

    onRowClicked($event: any) {
        if($event.data.Tags && _.includes($event.data.Tags, 'DeletedContact')) {
            let initialState = { message: 'Contact has been deleted or moved to an account you do not have access to view.' };
            let modalRef = this.modalService.show(TextModalComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-sm' });
        } else {
            this.openActivityForm($event.data.Id, $event.data.Category);
        }
    }

    addActivity() {
        this.openActivityForm(undefined, undefined);
    }

    openActivityForm(activityId: number, category: string): void {
        let initialState = {
            activityId: category === "M-T" || category === "M-S" ? null : activityId,
            meetingId: category === "M-T" || category === "M-S" ? activityId : null,
            contactIds: this.contactId ? [this.contactId] : [],
            accountId: this.accountId,
        }
        let modalRef = this.modalService.show(ActivityFormComponent, {
            animated: false,
            keyboard: false,
            backdrop: 'static',
            initialState: initialState,
        });

        modalRef.content.dataUpdated
            .subscribe(() => {
                this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
                this.form.get('dateRange').updateValueAndValidity({onlySelf: false, emitEvent: true});
            });
    }

    callDateComparator(valueA, valueB, nodeA: { data: Activity }, nodeB: { data: Activity}) {
        return nodeA.data.CallDate < nodeB.data.CallDate ? -1 :
               nodeA.data.CallDate > nodeB.data.CallDate ? 1 :
               nodeA.data.CreatedTime < nodeB.data.CreatedTime ? -1 : 1;
    }

    contactRenderer(params: any) {
        return params.data.Tags && _.includes(params.data.Tags, 'DeletedContact') ? '' : params.data.ContactShortName;
    }

    contactTooltip(params: any) {
        return params.data.Tags && _.includes(params.data.Tags, 'DeletedContact') ? 'Contact Deleted' : `${params.data.ContactLastName}, ${params.data.ContactFirstName}`;
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.gridColumnApi.setColumnVisible('AccountName', this.showAccountName);
        this.gridColumnApi.setColumnVisible('ContactShortName', this.showContactShortName);

        let userTeams$ = this.userService.getUserTeams();
        let users$ = this.userService.getUsers();

        combineLatest(([userTeams$, users$])).subscribe(([userTeams, users]) => {
            this.userTeams = userTeams;
            this.users = users;

            this.rowData$ = combineLatest([
                this.form.valueChanges.pipe(startWith(this.form.getRawValue())),
                this.activityDataChanged.pipe(startWith(true))
            ])
                .pipe(
                debounceTime(200),
                tap(() => this.gridApi.showLoadingOverlay()),
                switchMap(([form]) =>
                    (form.contactId || form.accountId || form.ticker) ?
                        this.activityService.searchActivities(this.convertToQuery(form)) :
                        of([])
                ),
                tap(a => this.notShowingAllRecords = a.length >= 16000)
            );
        });
    }

    exportToExcel() {
        if (!this.exporting) {
            this.exporting = true;
            let query = this.convertToQuery(this.form.value);
            this.activityService.exportActivities(query).pipe(
                catchError(x => of(''))
            ).subscribe(x => {
                this.exporting = false;
            });
        }
    }

    openReassignModal() {
        let modalRef = this.modalService.show(ReassignContactFormComponent, {
            initialState: {contactId: this.contactId},
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-lg'
        });
        modalRef.content.dataUpdated.subscribe(() => {
            this.activityDataChanged.emit(true);
        })
    }
}
