import {Component, OnInit} from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ColDef} from "ag-grid-community";
import {map, startWith} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {SavedContactList} from "../../Admin/ListGroups/saved-contact-list";
import {ContactListsFormComponent} from "./contact-lists-form.component";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {ContactListsService} from "./contact-lists.service";
import * as _ from "lodash";
import {BaseGridComponent} from "../../Shared/base-grid.component";

@Component({
    selector: "app-contact-lists",
    templateUrl: "./contact-lists.html"
})
export class ContactListsComponent extends BaseGridComponent<SavedContactList> implements OnInit {

    columnDefs: ColDef[] = [
        {field: 'Name', headerName: 'Name', sort: 'asc', flex: 2 },
        {field: 'Description', headerName: 'Description', flex: 2 },
        {field: 'Permission', headerName: 'Permission', flex: 1, cellRenderer: this.renderPermission },
        {field: 'PermissionGroup.Name', headerName: 'Group', flex: 1 },
        {field: 'CreatedBy', headerName: 'Created By', flex: 1 },
        {field: 'ModifiedBy', headerName: 'Modified By', flex: 1 },
        {field: 'ModifiedDate', headerName: 'Modified Date', flex: 1, cellRenderer: dateRenderer },
        {field: 'Size', headerName: 'Contacts', flex: 1 },
    ];


    listSearchBox: UntypedFormControl = this.fb.control('');

    constructor(
        private fb: UntypedFormBuilder,
        private contactListsService: ContactListsService,
        private modalService: BsModalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.pagination = true;
        this.setRowData();
    }

    setRowData(): void {
        const allLists$ = this.contactListsService.getAllLists();
        const formUpdate$ = this.listSearchBox.valueChanges.pipe(startWith(this.listSearchBox.value));

        this.rowData$ = combineLatest([allLists$, formUpdate$]).pipe(
            map(([lists, searchTerm]) => lists.filter(l => this.showList(l, searchTerm)))
        );
    }

    showList(list: SavedContactList, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        if(_.startsWith(list.Name.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (l: SavedContactList): boolean => {

            const fieldsContains = _.map([list.Name, list.Description], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };

        return query(list);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked($event: any) {
        this.openModalForm($event.data.Id);
    }

    addContactList() {
        this.openModalForm(0);
    }

    openModalForm(id: number): void {
        const initialState = {
            listId: id
        };
        let modalRef = this.modalService.show(ContactListsFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-lg' });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }

    renderPermission(params: any): string {
        return params.data.Permission === 'G' ? 'Group View'
            : params.data.Permission === 'E' ? 'Group View - Edit'
                : 'Private';
    }
}
