<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">Edit Specialty Mailing</h3>
        <div><app-contact-name [contactId]="contactId"></app-contact-name></div>
    </div>
    <div class="modal-body" style="overflow-y: auto; max-height: 500px;">
        <form [formGroup]="specialtyMailingForm" class="form-inline">
            <div *ngFor="let groupedMailings of specialtyMailingForm.get('groupedMailings')['controls']">
                <div style="font-weight: bold">
                    {{groupedMailings.get('analyst').value}}
                </div>
                <div *ngFor="let specialtyMailing of groupedMailings.get('specialtyMailings')['controls']">
                    <div [formGroup]="specialtyMailing" >
                        <label style="font-weight: normal">
                            <select style="margin-right: 5px" class="form-control input-sm" formControlName="isBlueMatrix">
                                <option value=""></option>
                                <option value="Yes">Y</option>
                                <option value="No">N</option>
                            </select>
                            {{specialtyMailing.get('description').value}} ({{specialtyMailing.get('symbol').value}})
                        </label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="!formLoading">
        <div class="flex-hbox">
            <button type="button" class="flex btn btn-sm btn-primary" (click)="submit()" [disabled]="specialtyMailingForm.invalid">Save</button>
            <button type="button" class="flex btn btn-sm btn-default" (click)="close()">Cancel</button>
        </div>
    </div>
</div>
