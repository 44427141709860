import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef} from "ag-grid-community";
import * as _ from "lodash";
import {RVCSummary} from "../../../Models/RVCSummary";

@Component({
    selector: "app-rvc-top",
    templateUrl: "./rvc-top.component.html"
})
export class RvcTopComponent extends BaseGridComponent<RVCSummary> implements OnChanges {

    @Input()
    rvcData: RVCSummary[];

    @Input()
    conferences: boolean;

    @Input()
    year: number;
    
    openGroups: string[] = [];
    
    columnDefs: ColDef[] = [
        {field: 'CategoryName', flex: 1, enableRowGroup: true, rowGroup: true, hide: true },
        {field: 'Conference', flex: 1, enableRowGroup: true, rowGroup: false, hide: true},
        {field: 'SubCategory', flex: 1, enableRowGroup: true, rowGroup: true, hide: true, sort: "asc"},
        {
            field: 'MeetingCount',
            headerName: 'Mtgs',
            headerTooltip: "Meetings",
            flex: 1,
            sortable: false,
            tooltipValueGetter: params => params.value.Year + ": Mtgs",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    IncludedCount: params.data?.MeetingCount ?? 0,
                    toString: () => params.data?.DisplayMeetingCount ?? 0
                }
            },
            valueFormatter: params => {
                if (this.conferences) {
                    return params.node.key === "Attended" || params.node.key === "Attd/Cxld/NoShw" ? "" : params.value;
                }
                return (params.node.key === "Attended" || params.node.key === "Attd/Cxld/NoShw") && params.node.parent.key == "Conferences" ? "" : params.value
            }
            ,
            aggFunc: params => {
                const includedCount = _.sum(params.values.map(v => v.IncludedCount));
                if (params.rowNode.level == 0) {
                    return {
                        Year: params.values[0]?.Year,
                        IncludedCount: includedCount,
                        toString: () => includedCount ?? 0
                    }
                }
                return {
                    Year: params.values[0]?.Year,
                    IncludedCount: includedCount,
                    toString: () => _.sum(params.values.map(v => +v.toString())) ?? 0
                }
            },
        },
        {
            field: 'SeatCount',
            headerName: 'Seats',
            headerTooltip: "Seats",
            flex: 1,
            tooltipValueGetter: params => params.value.Year + ": Seats",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    IncludedCount: params.data?.SeatCount ?? 0,
                    toString: () => params.data?.DisplaySeatCount ?? 0
                }
            },
            aggFunc: params => {
                const includedCount = _.sum(params.values.map(v => v.IncludedCount));
                if (params.rowNode.level == 0) {
                    return {
                        Year: params.values[0]?.Year,
                        IncludedCount: includedCount,
                        toString: () => includedCount ?? 0
                    }
                }
                return {
                    Year: params.values[0]?.Year,
                    IncludedCount: includedCount,
                    toString: () => _.sum(params.values.map(v => v.toString())) ?? 0
                }
            },
            sortable: false
        },
        {
            field: 'Year',
            headerName: 'Year',
            flex: 1,
            pivot: true,
            hide: true,
            enableRowGroup: true
        }
    ];

    autoGroupColumnDef: ColDef = {
        headerName: 'Category',
        sortable: false,
        cellRendererParams: {suppressCount: true},
        sort: "asc",
        width: 130,
        tooltipValueGetter: params => {
            if (params.value === "NDRs") {
                return "NDR";
            }
            if (params.value === "Anly Visits") {
                return "Analyst Visits"
            }
            if (params.value === "Conf Calls") {
                return "Conference Calls";
            }
            if (params.value === "Fld Trip/CV") {
                return "Field Trips and Company Visits";
            }
            if (params.value === "GCTS") {
                return "Global Cons, Tech and Svcs Conference";
            }
            if (params.value === "Healthcare") {
                return "Healthcare Conference";
            }
            if (params.value === "Industrials") {
                return "Industrial Conference";
            }
            if (params.value === "Sustainability") {
                return "Sustainability Conference";
            }
            if (params.value === "VT&M") {
                return "Vehicle Tech and Mobility Conference";
            }
            if (params.value === "HC ESG") {
                return "Healthcare ESG";
            }
            if (params.value === "Govt Svc Def") {
                return "Govt Svcs and Defense Conference";
            }
            if (params.value === "Pvt Tech Svc") {
                return "Private Tech and Svcs Conference";
            }
            if (params.value === "Other") {
                return "Other Conferences";
            }
            if (params.value === "Total") {
                return "Total Conferences";
            }
            if (params.value === "Mtg/Cxld/NoShw") {
                return "Meeting Cxld/No Show"
            }
            if (params.value === "Attd/Cxld/NoShw") {
                return "Attended Cxld/No Show"
            }
            return params.value;
        },
        comparator: (valueA, valueB) => {
            const conferencesSort = ["GCTS", "Healthcare", "Industrials", "Sustainability", "VT&M", "HC ESG", "Govt Svc Def", "Pvt Tech Svc", "Other", "Total"];
            const nonConferencesSort = ["Conferences", "NDRs", "Anly Visits", "Conf Calls", "Fld Trip/CV"];
            const sortOrder = [...(this.conferences ? conferencesSort : nonConferencesSort), "1x1", "2x1", "3x1", "4x1", "Group", "Dinners", "Webcast", "Attended", "Mtg/Cxld/NoShw", "Attd/Cxld/NoShw", "Cxld/No Show", "Replay"];
            return sortOrder.indexOf(valueA) - sortOrder.indexOf(valueB);
        }
    };

    constructor() {
        super();
        this.gridOptions.groupRemoveSingleChildren = true;
        this.gridOptions.getRowStyle = (params) => {
            if (params.node.level && !params.node.footer && !params.node.rowPinned) {
                return {background: '#ecf0f1'};
            }
        }
        this.gridOptions.isGroupOpenByDefault = params => this.openGroups.includes(params.key)
        this.gridOptions.pagination = false;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.openGroups = [];
        this.gridApi?.forEachNode(node => {
            if (node.group && node.expanded) {
                this.openGroups.push(node.key);
            }
        });
        this.gridOptions.groupIncludeTotalFooter = !this.conferences;
        let yearRange = _.range(+this.year - 4, +this.year + 1);
        const rowData = this.rvcData ? this.rvcData
            .filter(a =>
                ['C', 'K', 'A', 'N', 'I', 'V', ""].includes(a.Category)
                && a.Year !== yearRange[0]
                && (this.conferences ? !!a.Conference && a.SubCategory !== "Cxld/No Show" : true)
            ) : [];
        
        this.gridColumnApi?.removeRowGroupColumn('SubCategory');
        if (this.conferences) {
            const totals = rowData.map(r => {
                return {
                    ...r,
                    Conference: "Total"
                };
            });
            this.rowData = [...rowData, ...totals];
            this.gridColumnApi?.removeRowGroupColumn('CategoryName');
            this.gridColumnApi?.addRowGroupColumn('Conference');
        } else {
            this.rowData = rowData;
            this.gridColumnApi?.removeRowGroupColumn('Conference');
            this.gridColumnApi?.addRowGroupColumn('CategoryName');
        }
        this.gridColumnApi?.addRowGroupColumn('SubCategory');
    }

}
