import {Routes} from "@angular/router";
import {EventListComponent} from "./EventList/event-list.component";
import {RegistrationListComponent} from "./RegistrationList/registration-list.component";
import {EventCalendarComponent} from "./EventCalendar/event-calendar.component";
import {OldRequestDetailComponent} from "./RequestDetail/old-request-detail.component";
import {EventDetailComponent} from "./EventDetail/event-detail.component";
import {RequestDetailComponent} from "./NewRequestDetail/request-detail.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {MsalGuard} from "@azure/msal-angular";
import {EventRoutePaths} from "./event-route-paths";
export const eventRoutes: Routes = [
    {
        path: EventRoutePaths.BasePath,
        canActivate: [UserCanActivateRoute, MsalGuard],
        canActivateChild: [MsalGuard],
        children: [
            {
                path: EventRoutePaths.EventRegistrationListPathFragment,
                component: RegistrationListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Registration List"
                },
            },
            {
                path: EventRoutePaths.EventCalendarPathFragment,
                component: EventCalendarComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Event Calendar"
                },
            },
            {
                path: EventRoutePaths.RequestDetailPathFragment,
                component: RequestDetailComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Request Details"
                },
            },
            {
                path: `${EventRoutePaths.EventDetailPathFragment}/:eventId`,
                component: EventDetailComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Event Details"
                },
            },
            {
                path: EventRoutePaths.OldRequestDetailPathFragment,
                component: OldRequestDetailComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Old Request Details"
                },
            },
            {
                path: "",
                component: EventListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Event List"
                },
            },
        ],
    },
];
