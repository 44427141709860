import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

@Injectable({
    providedIn: 'root',
})
export class UserTeamAdminService {

    constructor(private baseHttpService: BaseHttpService) { }

    getAllUserTeams(): Observable<UserTeam[]> {
        return this.baseHttpService.getData(`/userteam`);
    }
    
    getUserTeamById(id: number): Observable<UserTeam> {
        return this.baseHttpService.getData(`/userteam/${id}`);
    }
    
    saveUserTeam(userTeam: UserTeam): Observable<UserTeam> {
        return this.baseHttpService.postData(`/userteam/${userTeam.Id}`, userTeam);
    }
    
    deleteUserTeam(id: number): Observable<void> {
        return this.baseHttpService.deleteData(`/userteam/${id}`);
    }
}

export interface UserTeam {
    Id: number;
    ParentId?: number;
    Description: string;
    IsActive: boolean;
    ActiveUserIds: string[];
    InactiveUserIds: string[];
}