import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ActiveElement, ChartData, ChartEvent, Chart} from "chart.js";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {ChartType} from "ag-grid-community";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {BaseChartComponent, BaseChartOptions} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {ActivityWeekSum} from "../../../Models/ActivityWeekSum";
import {TierService} from "../../Shared/Services/tier.service";
import {DateRange} from "../../../Models/DateRange";
import {Router} from "@angular/router";
import {ActivityRoutePaths} from "../../Activity/activity-route-paths";
import {ActivityListComponent} from "../../Activity/ActivityList/activity-list.component";
import * as moment from "moment";
import {CacheService} from "../../Shared/Services/cache.service";

@Component({
    selector: "app-activity-allocation-chart",
    templateUrl: "./research-dashboard-base-chart.component.html"
})
export class ActivityAllocationChartComponent extends BaseChartComponent implements OnChanges {
    @Input()
    chartData: ActivityWeekSum[];

    @Input()
    team: number;

    @Input()
    dateRange: DateRange

    categories: string[] = ['P', 'X', 'N'];
    title: string = "Activity Allocation";
    info: string = "Include activities: Phone (P), Email Exchange (X), and Conf Call (N)";
    type: ChartType = 'doughnut';
    tierGroups: string[][];
    hasData: boolean = false;

    chartDataControl: UntypedFormControl = this.fb.control([]);

    constructor(private activityService: ActivityService,
                private cacheService: CacheService,
                private fb: UntypedFormBuilder,
                private tierService: TierService,
                private router: Router,
    ) {
        super();
        this.tierGroups = this.tierService.getActivityChartTierGroups();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartData) {
            this.chartDataControl.patchValue(this.chartData);
        }
    }

    configureChart(): void {
        this.options = {
            scales: {
                x: {
                    display: false,
                },
                y: {
                    display: false
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (item) {
                            const datasetLabel = item.label || '';
                            return `${datasetLabel}: ${item.raw.toLocaleString()} activities`;
                        }
                    }
                },
            },
            onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart): void => {
                if (elements.length === 0) {
                    return;
                }

                let tiers = this.tierGroups[elements[0].index];

                this.cacheService.setValue(ActivityListComponent.NavigatedFiltersCacheKey, {
                    startDate: this.dateRange.start.startOf("week"),
                    endDate: this.dateRange.end,
                    categories: this.categories,
                    callerTeams: this.team ? [this.team] : null,
                    tiers: tiers
                });

                this.router.navigate([ActivityRoutePaths.Activities]);
            },
        };

        this.configureData()
            .subscribe(chartData => {
                if (this.hasData) {
                    if (this.chart) {
                        this.updateChart(chartData);
                    } else {
                        this.data = chartData;
                        this.runChart();
                    }
                } else {
                    this.chart?.destroy();
                    this.chart = null;
                }
            });
    }

    configureData(): Observable<ChartData> {
        return this.chartDataControl.valueChanges.pipe(
            startWith(this.chartDataControl.value),
            map((chartData: ActivityWeekSum[]) => {
                this.hasData = chartData.length > 0;

                const labels = ['Tier 1 & 2', 'Tier 3', 'Tier 4', 'Lowest Priority'];
                const allocationCounts = _.chain(chartData)
                    .groupBy(cd => this.tierGroups.findIndex(tg => tg.includes(cd.Tier)))
                    .filter((cd, key) => key !== "-1")
                    .map(cd => _.chain(cd).filter(c => this.categories.includes(c.Category)).map(c => c.TotalCount).sum().value())
                    .value();

                return {
                    labels: labels,
                    datasets: [{
                        data: allocationCounts,
                        backgroundColor: BaseChartOptions.tierColors(),
                        hoverBackgroundColor: BaseChartOptions.tierHoverColors(),
                        borderColor: "rgba(255, 255, 255, 1)",
                    }]
                };
            })
        )
    }
}
