import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {DateRange} from "../../../Models/DateRange";
import {Activity} from "../../../Models/Activity";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ChartData, ChartType} from "chart.js";
import {Observable, of} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: "app-activities-by-ticker-chart",
    templateUrl: "./activities-by-ticker-chart.component.html"
})
export class ActivitiesByTickerChartComponent extends BaseChartComponent implements OnChanges {

    @Input()
    teamId: number;

    @Input()
    dateRange: DateRange;

    chartControls: UntypedFormGroup = this.fb.group({
        teamId: this.fb.control(null),
        dateRange: this.fb.control(null)
    });

    categories: string[] = ["P", "X", "D", "A", "C", "N", "I"];

    type: ChartType = "bar";
    stacked: boolean = true;
    hasData: boolean = false;
    isLoading: boolean = true;

    constructor(
        private fb: UntypedFormBuilder,
        private activityService: ActivityService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.teamId) {
            this.chartControls.get("teamId").patchValue(this.teamId);
        }
        if (changes.dateRange) {
            this.chartControls.get("dateRange").patchValue(this.dateRange);
        }
    }

    configureChart(): void {
        this.options = {
            plugins: {
                tooltip: {
                    xAlign: "left",
                    yAlign: "center",
                },
            },
            scales: {
                x: {
                    stacked: true,
                    ticks: {
                        precision: 0
                    }
                },
                y: {
                    stacked: true,
                },
            },
            indexAxis: "y",
        };
        this.configureData().subscribe(chartData => {
            if (this.hasData) {
                if (this.chart) {
                    this.updateChart(chartData);
                } else {
                    this.data = chartData;
                    this.runChart();
                }
            } else {
                this.chart?.destroy();
                this.chart = null;
            }
        })

    }

    configureData(): Observable<ChartData> {
        return this.chartControls.valueChanges.pipe(
            startWith(this.chartControls.getRawValue()),
            switchMap(({teamId, dateRange}) => {
                this.isLoading = true;
                if (!teamId || !dateRange) {
                    return of([]);
                }
                const query: QueryGroup[] = [];
                QueryTranslator.AddQueryGroupValues(query, "Activity", "Baird Caller Team", "contains", [teamId]);
                QueryTranslator.AddQueryGroupValues(query, "Activity", "Category", "contains", this.categories);
                QueryTranslator.AddQueryGroupValues(query, "Activity", "Call Date", "between", [dateRange.start.toISOString(), dateRange.end.toISOString()])
                return this.activityService.searchActivities(query);
            }),
            map((activities: Activity[]) => {
                const activitiesByTicker = new Map<string, TickerActivityCount>();
                activities.forEach(a => {
                    a.Tickers.split(",")
                        .filter(ticker => !!ticker)
                        .map(ticker => ticker.trim())
                        .forEach(ticker => {
                            if (activitiesByTicker.has(ticker)) {
                                activitiesByTicker.get(ticker)[a.Category]++;
                            } else {
                                const counts: TickerActivityCount = {
                                    P: 0,
                                    X: 0,
                                    D: 0,
                                    A: 0,
                                    C: 0,
                                    N: 0,
                                    I: 0
                                };
                                counts[a.Category]++;
                                activitiesByTicker.set(ticker, counts);
                            }
                        });
                });
                const activitiesByTickerDescByCount = [...activitiesByTicker.entries()]
                    .sort((a, b) => {
                        return _.sum(Object.values(b[1])) - _.sum(Object.values(a[1]));
                    });
                this.hasData = activitiesByTickerDescByCount.length > 0;
                return {
                    labels: activitiesByTickerDescByCount.map(([ticker]) => ticker),
                    datasets: this.categories.map(cat => {
                        return {
                            label: this.mapCategoryCodeToLabel(cat),
                            data: activitiesByTickerDescByCount.map(([ticker, counts]) => counts[cat]),
                        }
                    })
                };
            }),
            tap(() => this.isLoading = false)
        )
    }
    
    mapCategoryCodeToLabel(categoryCode: string): string {
        return {
            A: "Analyst Visits",
            N: "Conference Calls",
            C: "Conferences",
            X: "Email Exchange",
            D: "Excel Model",
            I: "Field Trips",
            P: "Phone",
        }[categoryCode];
    }
}

interface TickerActivityCount {
    P: number;
    X: number;
    D: number;
    A: number;
    C: number;
    N: number;
    I: number;
}
