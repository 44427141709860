import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {ICellRendererParams} from "ag-grid-community";

@Component({
    selector: 'ag-grid-commission-account',
    template: `
    <ng-container *ngIf="!isGroup; else groupRow">
        <div class="flex-hbox no-wrap">
            <span [tooltip]="accountTooltip" [isDisabled]="disabledTooltip" container="body" placement="right" class="flex-1 ellipsis">{{ displayValue }}</span>
            <span *ngIf="account?.BrokerSplitStatus === 'Add'" [tooltip]="'Added in ' + reportYear" container="body" placement="auto" style="max-width: 22px; margin-left: 5px;"> (<i class="fa fa-fw fa-plus label-icon"></i>)</span>
            <span *ngIf="account?.BrokerSplitStatus === 'Remove'" [tooltip]="'Removed in ' + reportYear" container="body" placement="auto" style="max-width: 22px; margin-left: 5px;"> (<i class="fa fa-fw fa-minus label-icon"></i>)</span>
        </div>
        <ng-template #accountTooltip>
            <div *ngIf="account" class="text-left">
                <div>Parent ID: {{ account.ParentComdolId }}</div>
                <div>Comdol ID: {{ account.ComdolId }}</div>
                <div>Instnum: {{ account.AccountId }}</div>
                <div>Status: {{ account.Status }}</div>
            </div>
        </ng-template>
    </ng-container>
    <ng-template #groupRow>
        {{ value }}
    </ng-template>
     `
})
export class CommissionAccountRenderer implements ICellRendererAngularComp {
    value: string;
    displayValue: string;
    account: AccountTooltipData;
    reportYear: number;
    disabledTooltip: boolean = false;
    isGroup: boolean = false;
    groupCount: number;

    agInit(params: ICellRendererParams & { reportYear: number }): void {
        this.value = params.valueFormatted ?? params.value;
        this.account = params.data;
        this.displayValue = this.value + (this.account?.ComdolId ? ` (${this.account?.ComdolId})` : "");
        this.reportYear = params.reportYear;
        this.disabledTooltip = !this.account || params.node.rowPinned === "top" || params.node.group;
        this.isGroup = params.node.group;
        this.groupCount = params.node.childrenAfterFilter?.length;
    }

    refresh(): boolean {
        return true;
    }
}

interface AccountTooltipData {
    ParentComdolId: string;
    ComdolId: string;
    AccountId: number;
    Status: string;
    BrokerSplitStatus: string;
}
