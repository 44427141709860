import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface Provider {
    Id?: number;
    TypeId: number;
    Name: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    Phone: string;
    Fax: string;
    Email: string;
    ContactName: string;
    Notes: string;
    CityId: number;
    Deleted: string;
}

export interface ProviderForListComponent extends Provider {
    CityNameForCityId: string;
}

export enum ProviderType {
    Hotel = 1,
    Restaurant = 2
}

@Injectable({
    providedIn: 'root',
})
export class ProviderService {

    constructor(private baseHttp: BaseHttpService) { }

    getProviders(typeId: ProviderType): Observable<Provider[]> {
        return this.baseHttp.getData(`/provider/typeid/${typeId}`);
    }

    getProviderById(providerId: number): Observable<Provider> {
        return this.baseHttp.getData(`/provider/id/${providerId}`);
    }

    createOrUpdateProvider(provider: Provider): Observable<Provider> {
        return this.baseHttp.postData(`/provider`, provider);
    }
}
