<ng-container *ngIf="contacts$ | async as contacts">
    <span 
            [popover]="contactPopover"
            [placement]="placement"
            [adaptivePosition]="adaptivePosition"
            [outsideClick]="true"
            [popoverTitle]="title"
            container="body"
            (scroll)="onScrollForPopOver($event)">
        <a class="pointer">{{ getLinkText(contacts) }}</a>
    </span>

    <ng-template #contactPopover>
        <div class="popover-scroll-container">
            <ng-container *ngFor="let contact of contacts">
                <div *ngIf="contact" class="contact-popover">
                    <div class="info">
                        <h4>
                            <a class="pointer" (click)="goToContact(contact.Id)">{{ contact | presentationName }}</a>
                        </h4>
                        <div>{{ contact.Title }}</div>

                        <ng-container *ngIf="showEmail">
                            <h5>Email</h5>
                            <div>
                                <a class="pointer" [href]="'mailto:' + contact.Email">{{ contact.Email }}</a>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>

</ng-container>