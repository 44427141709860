import {Component, Input, OnInit} from "@angular/core";
import {ContactService} from "../../../Shared/Services/contact.service";
import {CalendarEventAttendee} from "../../../../Components/Events/CalendarEventAttendee";

@Component({
    selector: "app-request-meeting-form-attendee-tab",
    template: `
        <div class="flex-vbox">
            <div class="flex-hbox form-group" style="font-weight: 600; margin-top: 10px;">
                <div class="flex-4">Contact</div>
                <div class="flex-3">Account</div>
                <div class="flex-1">Tier</div>
                <div class="flex-1">Status</div>
            </div>

            <div class="form-group flex-hbox" *ngFor="let attendee of attendees">
                <div class="flex-4">
                    {{attendee.Name}}
                </div>
                <div class="flex-3">
                    {{attendee.Account}}
                </div>
                <div class="flex-1">
                    {{attendee.Tier}}
                </div>
                <div class="flex-1">
                    {{attendee.ActivityStatus}}
                </div>
            </div>
        </div>
    `
})
export class RequestMeetingFormAttendeeTabComponent implements OnInit {

    @Input()
    activities: CalendarEventAttendee[];

    attendees: AttendeeInfo[] = [];

    constructor(
        private contactService: ContactService,
    ) {
    }

    ngOnInit(): void {
        let contactIds = this.activities.filter(a => a.Status !== "Replay").map(a => a.ContactId).filter((value, index, array) => array.indexOf(value) === index);
        let contacts$ = this.contactService.getContactsByIds(contactIds).subscribe(contacts => {
            contacts.forEach((contact) => {
                let activity = this.activities.filter(a => a.Status !== "Replay").find(a => a.ContactId === contact.Id);
                this.attendees.push({
                    Name: `${contact?.LastName}, ${contact?.FirstName}`,
                    Account: contact?.Account?.Name,
                    Tier: contact?.Tier,
                    ActivityStatus: activity?.Status,
                })
            });
        });
    }
}

interface AttendeeInfo {
    Name: string,
    Account: string,
    Tier: string,
    ActivityStatus: string
}
