<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">Edit Company for this Event</h3>
    </div>
    <form [formGroup]="companyForm">
        <div class="modal-body">
            <div class="form-group">
                <label>Company</label>
                <app-multi-account-select></app-multi-account-select>
            </div>
            <div class="form-group">
                <label>Status</label>
                <input class="form-control" />
            </div>
            <div class="form-group">
                <label>Owned by Rep</label>
                <input class="form-control" />
            </div>
            <div class="form-group">
                <label>Invited By</label>
                <input class="form-control" />
            </div>
            <div class="form-group">
                <label>Notes</label>
                <input class="form-control" />
            </div>
            <div class="form-group">
                <label>Presenters</label>
                <select class="form-control">
                    <option>Presenter 1</option>
                    <option>Presenter 2</option>
                </select>
            </div>
            
            
            <div class="form-group">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" /> Create Deault Meeting
                    </label>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-primary btn-sm flex" (click)="save()">Save</button>
                <button type="button" class="btn btn-default btn-sm flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

