import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from "lodash";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {MonthlyCommission} from "../../../Models/AccountMonthlyCommission";
import {currencyFormatter} from "../../Shared/ag-grid-cell-renderers";
import * as moment from "moment";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ChartData, ChartDataset, Tooltip } from "chart.js";

@Component({
    selector: "app-commission-chart",
    templateUrl: "../../Widget/Charts/BaseChart/base-chart.component.html"
})
export class CommissionChartComponent extends BaseChartComponent implements OnChanges {

    @Input()
    type: string = "bar";

    @Input()
    stacked: boolean = true;

    @Input()
    relativePays: any[];

    @Input()
    years: number[];

    @Input()
    commissions: MonthlyCommission[];

    @Input()
    revenueTargets: number[];

    @Input()
    property: any;

    @Input()
    groupLabels: Record<any, string>;

    @Input()
    parent: boolean ;

    constructor() {
        super();
        Chart.register(ChartDataLabels, Tooltip);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["commissions"] && !changes["commissions"].isFirstChange()) {
            this.configureChart(true);
        }

        if (changes["parent"] && !changes["parent"].isFirstChange()) {
            this.configureChart(true);
        }
    }

    configureChart(update: boolean = false): void {
        this.options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (item) => {
                            if (item.dataset.label === 'Relative Pay') {
                                return null;
                            }
                            return `${item.dataset.label}: ${currencyFormatter(item.raw, 0)}`;
                        },
                        footer: (tooltipItems) => {
                            let total = 0;
                            tooltipItems.forEach(item => {
                                if (item.dataset.label !== 'Relative Pay' && item.dataset.label !== 'Target') {
                                    total += +item.raw;
                                }
                            });
                            return `Total: ${currencyFormatter(total, 0)}`;
                        }
                    },
                    mode: 'index',
                    position: 'nearest'
                },
            },

            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    type: 'linear',
                    position: 'left',
                    ticks: {
                        callback: val => currencyFormatter(val, 0)
                    },
                },
                y2: {
                    display: this.parent,
                    type: 'linear',
                    position: 'right',
                    stacked: true,
                    ticks: {
                        callback: val => currencyFormatter(val, 2)
                    },
                    suggestedMax: this.getMaxValue(),
                }
            }
        };

        const data = this.configureData();
        if (update) {
            this.updateChart(data);
            this.chart.options = this.options;
        } else {
            this.data = data;
            this.runChart();
        }
    }

    configureData(): ChartData {
        const commissionsByCategory: Record<string, number[]> = {};
        const yearlyCommissions = _.groupBy(this.commissions, g => g.Year);

        this.years.forEach(year => {
            yearlyCommissions[year] = (yearlyCommissions[year] ?? []).map(m => {
                m.Category = m.Category === "P-E" ? "P" : m.Category;
                return m;
            });
            const groupedCommissions = _.groupBy(yearlyCommissions[year], group => group[this.property]);
            Object.entries(this.groupLabels).forEach(([code, description]) => {
                if (commissionsByCategory[description]) {
                    commissionsByCategory[description].push(this.sum(groupedCommissions[code], year));
                } else {
                    commissionsByCategory[description] = [this.sum(groupedCommissions[code], year)];
                }
            });
        });

        let chartData = {
            labels: this.years.map(year => year === moment().year() ? `${year} YTD` : year),
            datasets: Object.entries(commissionsByCategory).map(([key, data]) => {
                return {
                    label: key,
                    data: data,
                    datalabels: { display: false},
                    borderWidth: 1,
                    stack: '1'
                } as ChartDataset
            })
        }

        if (this.revenueTargets && this.revenueTargets.length > 0) {
            chartData.datasets.push({
                label: 'Target',
                type: 'line',
                yAxisID: 'y',
                stepped: "middle",
                fill: false,
                data: this.revenueTargets.slice(-this.years.length),
                borderWidth: 1,
                borderColor: "rgba(178, 34, 34, 1)",
                backgroundColor: "rgba(178, 34, 34, 1)",
                stack: '2',
                datalabels:{
                    display: false
                }
            });
        }

        if (this.relativePays && this.relativePays.length > 0 && this.parent) {
            chartData.datasets.push({
                label: 'Relative Pay',
                type: 'scatter',
                showLine: true,
                data: this.relativePays.slice(-this.years.length),
                borderWidth: 2,
                borderColor: "rgb(51, 122, 183)",
                backgroundColor: "rgb(51, 122, 183)",
                yAxisID: 'y2',
                pointStyle: 'rect',
                pointBorderColor: 'darkblue',
                pointBackgroundColor: "rgb(51, 122, 183)",
                datalabels: {
                    display: true,
                    align: 'center',
                    anchor: 'center',
                    backgroundColor: 'white',
                    borderColor: "rgb(51, 122, 183)",
                    borderRadius: 0,
                    borderWidth: 2,
                    color: 'black',
                    font: {
                        weight: 'bold'
                    },
                    formatter: (value) => value === 0 ? null : `$${value.toFixed(2)}`,
                    padding: 4
                }
            });
        }

        return chartData;
    }

    private sum(monthlyCommissions: MonthlyCommission[], year: number): number {
        return _.chain(monthlyCommissions)
            .filter(x => x.Year === year)
            .map(x => x.Commission)
            .sum()
            .value();
    };

    private getMaxValue(): number {
        if (this.years.length === 5) {
            const relevantRelativePays = this.relativePays.slice(-5);
            return Math.max(...relevantRelativePays) * 1.05;
        } else{
            return Math.max(...this.relativePays) * 1.2;
        }
    }
}

