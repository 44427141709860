<div class="modal-header">
    <div class="flex-hbox">
        <div style="width: 300px">
            <div style="margin-bottom: 5px;"><h3 class="modal-title">{{title}}</h3></div>
            <div *ngIf="eventMeeting"><span style="font-weight:700">Event Meeting ID:</span> {{eventMeeting?.MeetingId}}</div>
            <div *ngIf="eventMeeting"><span style="font-weight:700">Display Name:</span> {{eventMeeting?.DisplayName}}</div>
        </div>
        <div *ngIf="eventMeeting" style="width: 220px">
            <div><span style="font-weight:700">Date:</span> {{formatDate(eventMeeting?.Date)}}</div>
            <div><span style="font-weight:700">Time{{timeZoneForDisplay}}:</span> {{formatTimeRange(eventMeeting?.StartTime, eventMeeting?.EndTime)}}</div>
        </div>

        <div
            class="flex"
            style="display: flex; flex-wrap: wrap; max-height: 75px; flex-direction: column;"
        >
            <div
                *ngFor="let activityStatusCount of activityStatusCounts"
                style="display: flex; padding-right: 15px"
            >
                <div class="flex">
                    <span style="font-weight:700">{{activityStatusCount.ActivityStatus}}:</span>
                    <span style="float: right; padding-right: 20px">{{activityStatusCount.Count}}</span>
                </div>
            </div>
        </div>
    </div>
</div>