import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {TransportationProvider, TransportationProviderService, TransportationType} from "../Services/transportation-provider.service";
import {TravelCity, TravelCityService} from "../Services/travel-city.service";

@Component({
    selector: "app-provider-form",
    templateUrl: "./transportation-provider-form.component.html",
    styleUrls: ["../Providers/provider-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TransportationProviderFormComponent implements OnInit{

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    providerId: number = null;
    providerTypes: TransportationType[];
    providerTypeLabel: string;
    title: string;
    travelCities: TravelCity[];

    providerForm: UntypedFormGroup = this.fb.group({
        Name: this.fb.control('', [Validators.maxLength(50), this.emptyOrWhiteSpaceValidatorForNonNullField()]),
        TypeId: this.fb.control(null, [Validators.required]),
        Phone: this.fb.control('', [Validators.maxLength(20)]),
        Fax: this.fb.control('', [Validators.maxLength(20)]),
        Email: this.fb.control('', [Validators.maxLength(60)]),
        ContactName: this.fb.control('', [Validators.maxLength(50)]),
        AccountNumber: this.fb.control('', [Validators.maxLength(20)]),
        Deleted: this.fb.control(false),
        Address1: this.fb.control('', [Validators.maxLength(50)]),
        Address2: this.fb.control('', [Validators.maxLength(50)]),
        AddressCity: this.fb.control('', [Validators.maxLength(30)]),
        State: this.fb.control('', [Validators.maxLength(2)]),
        Zip: this.fb.control('', [Validators.maxLength(10)]),
        Country: this.fb.control('', [Validators.maxLength(25)]),
        CityId: this.fb.control(null, [Validators.required]),
        Notes: this.fb.control(null),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private providerService: TransportationProviderService,
        private travelCityService: TravelCityService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.providerTypeLabel = "Transportation"
        if (this.providerId) {
            this.loadProvider(this.providerId);
        } else {
            this.title = `New ${this.providerTypeLabel}`;
        }
    }

    loadProvider(providerId: number) {
        const provider$ = this.providerService.getProviderById(providerId);

        provider$
            .subscribe((provider) => {
                this.providerForm.patchValue({
                    Name: provider.Name,
                    TypeId: provider.TypeId,
                    Phone: provider.Phone,
                    Fax: provider.Fax,
                    Email: provider.Email,
                    ContactName: provider.ContactName,
                    Deleted: provider.Deleted,
                    Address1: provider.Address1,
                    Address2: provider.Address2,
                    AddressCity: provider.City,
                    State: provider.State,
                    Zip: provider.Zip,
                    Country: provider.Country,
                    CityId: provider.CityId,
                    Notes: provider.Notes,
                    AccountNumber: provider.AccountNumber,
                });

                this.title = `Edit ${this.providerTypeLabel}: ${provider.Name}`;

                this.travelCityService.getTravelCities()
                    .subscribe((cities) => {
                        this.travelCities = cities
                    });
                this.providerService.getProviderType()
                    .subscribe((types) => {
                        this.providerTypes = types
                    })
            });
    }

    submit() {
        if (this.providerForm.valid) {
            let provider: TransportationProvider = {
                Id: this.providerId,
                TypeId: this.providerForm.get('TypeId').value,
                Name: this.providerForm.get('Name').value.trim(),
                Address1: this.providerForm.get('Address1').value.trim(),
                Address2: this.providerForm.get('Address2').value.trim(),
                City: this.providerForm.get('AddressCity').value.trim(),
                CityId: this.providerForm.get('CityId').value,
                State: this.providerForm.get('State').value.trim(),
                Zip: this.providerForm.get('Zip').value.trim(),
                Country: this.providerForm.get('Country').value.trim(),
                Phone: this.providerForm.get('Phone').value.trim(),
                Fax: this.providerForm.get('Fax').value.trim(),
                Email: this.providerForm.get('Email').value.trim(),
                ContactName: this.providerForm.get('ContactName').value.trim(),
                AccountNumber: this.providerForm.get('AccountNumber').value.trim(),
                Notes: this.providerForm.get('Notes').value?.trim(),
                Deleted: this.providerForm.get('Deleted').value,
            };

            let updatedProvider$ = this.providerService.createOrUpdateProvider(provider);

            updatedProvider$.subscribe(updatedProvider => {
                this.toastr.success(`Saved ${this.providerTypeLabel}`);
                this.dataUpdated.emit(true);
                this.modalRef.hide();
            });
        }
    }

    close() {
        this.modalRef.hide();
    }

    emptyOrWhiteSpaceValidatorForNonNullField(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return (control.value.trim().length === 0) ?
                { emptyOrWhiteSpace: true } :
                null;
        }
    }
}
