import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";

@Component({
    selector: 'app-grid-phone',
    template: `<app-click-to-dial [phone]="params.data.PhoneNumber" [extension]="params.data.PhoneNumberExtension"></app-click-to-dial>`
})
export class PhoneCellRenderer implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return true;
    }
}