<div class="flex flex-vbox" style="height: 100%; min-height: 0; min-width: 0;">

    <div style="padding: 5px; border-bottom: 1px solid #DDD;">
        <form [formGroup]="commissionForm">
            <div class="flex-hbox flex-gap" style="align-items: center;">
                <div *ngIf="latestReleaseDate$ | async as latestReleaseDate" style="margin-right: 5px;">
                    As Of: {{ latestReleaseDate | date: "M/dd/yy" }}
                </div>
                <div *ngIf="parentComdolId" class="checkbox" style="margin: 0; padding-right: 5px">
                    <label style="font-weight: bold;">
                        <input type="checkbox" formControlName="includeParent" />Parent
                    </label>
                </div>
                <a *ngIf="parentComdolId" 
                   appIfUserFeature="CommissionYOYReport"
                   [routerLink]="[parentYearOverYearCommissionReportBasePath, parentComdolId]"
                   [relativeTo]="null"
                   target="_blank"
                   class="btn btn-sm btn-default">YoY</a>
                <a *ngIf="parentComdolId" 
                   appIfUserFeature="CommissionTradeDetail"
                   [routerLink]="[parentTradeDetailReportBasePath, parentComdolId]"
                   [relativeTo]="null"
                   target="_blank"
                   class="btn btn-sm btn-default">Trade Detail</a>
                <app-commission-category-picker *ngIf="commissionForm.get('showCategories').value"
                                                formControlName="categories">
                </app-commission-category-picker>
                <select *ngIf="commissionForm.controls['chart'].value != 'Total'" formControlName="yearsRange" class="form-control input-sm flex" style="max-width: 8%; padding: 0; text-align: center">
                    <option [ngValue]="5">5 Yrs</option>
                    <option [ngValue]="10">10 Yrs</option>
                </select>
                <select *ngIf="this.hasRevenueTargets" formControlName="relativePayDateType" class="form-control input-sm flex" style="max-width: 10%; padding: 0; text-align: center">
                    <option [ngValue]="1">Trade Date</option>
                    <option [ngValue]="2">Service Date</option>
                </select>
                <div class="btn-group" style="margin-left: auto;" btnRadioGroup formControlName="chart">
                    <div class="btn btn-primary btn-xs btn-toggle" btnRadio="Category" (click)="commissionForm.get('showCategories').setValue(false)" style="font-size: 14px;">Category</div>
                    <div class="btn btn-primary btn-xs btn-toggle" btnRadio="Qtrly" (click)="commissionForm.get('showCategories').setValue(true)" style="font-size: 14px;">Qtrly</div>
                    <div *ngIf="parentComdolId" class="btn btn-primary btn-xs btn-toggle" btnRadio="Accts" (click)="commissionForm.get('showCategories').setValue(true)" style="font-size: 14px;">Accts</div>
                    <div class="btn btn-primary btn-xs btn-toggle" btnRadio="Total" (click)="commissionForm.get('showCategories').setValue(false)" style="font-size: 14px;">Total</div>
                    <div class="btn btn-primary btn-xs btn-toggle" btnRadio="Net" (click)="commissionForm.get('showCategories').setValue(false)" style="font-size: 14px;">Net</div>
                </div>
            </div>
        </form>
    </div>
    

    <ng-container *ngIf="userHasAccountCoverage && hasAccessToAccountCommissions; else noAccess">
        <app-loading class="flex" *ngIf="isLoadingFirstTime"></app-loading>
        <div *ngIf="data$ | async as data" [ngSwitch]="commissionForm.controls['chart'].value" style="flex: 1; position: relative; height: calc(100% - 37px);">
            <app-loading class="flex" *ngIf="isLoading"></app-loading>
            <app-commission-chart class="flex" 
                                  *ngSwitchCase="'Category'" 
                                  [years]="yearsDisplayed" 
                                  [commissions]="data.commissions" 
                                  [revenueTargets]="data.revenueTargets"
                                  [relativePays]="data.relativePay"
                                  [groupLabels]="categoryGroups"
                                  [parent]="commissionForm.get('includeParent').value"
                                  property="Category"></app-commission-chart>
            <app-commission-chart class="flex" 
                                  *ngSwitchCase="'Qtrly'" 
                                  [years]="yearsDisplayed" 
                                  [commissions]="data.commissions"
                                  [revenueTargets]="data.revenueTargets"
                                  [relativePays]="data.relativePay"
                                  [groupLabels]="quarterGroups"
                                  [parent]="commissionForm.get('includeParent').value"
                                  property="Quarter"></app-commission-chart>
            <app-commission-chart class="flex" 
                                  *ngSwitchCase="'Accts'" 
                                  [years]="yearsDisplayed" 
                                  [commissions]="data.commissions"
                                  [revenueTargets]="data.revenueTargets"
                                  [relativePays]="data.relativePay"
                                  [groupLabels]="accountGroups"
                                  [parent]="commissionForm.get('includeParent').value"
                                  property="Location"></app-commission-chart>
            <app-account-commission-totals class="flex" 
                                           *ngSwitchCase="'Total'" 
                                           [years]="yearsDisplayed" 
                                           [yearsRange]="5"
                                           [commissions]="data.commissions"
                                           [pnl]="data.pnl" 
                                           [expenses]="data.expenses" 
                                           [categoryGroups]="categoryGroups"></app-account-commission-totals>
            <app-net-commission-chart class="flex" 
                                      *ngSwitchCase="'Net'" 
                                      [years]="yearsDisplayed" 
                                      [commissions]="data.commissions" 
                                      [pnl]="data.pnl" 
                                      [expenses]="data.expenses"></app-net-commission-chart>
        </div>
    </ng-container>

    <ng-template #noAccess>
        <div class="flex justify-center">
            No Access to Commissions
        </div>
    </ng-template>
</div>