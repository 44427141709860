import {Contact} from "../../Models/Contact";
import {Account} from "../../Models/Account";

export const isContactable = (contact: Contact): boolean => {
    return !getContactResearchStatus(contact);
} 

export const getContactResearchStatus = (contact: Contact): any => {
    return (contact && contact.ResearchStatus) || (contact && contact.Account && contact.Account.ResearchStatus);
};

export const getAccountResearchStatus = (account: Account): any => {
    return account && account.ResearchStatus;
};