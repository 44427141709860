import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Airport, AirportService} from "../Services/airport.service";
import {TravelCity, TravelCityService} from "../Services/travel-city.service";

@Component({
    selector: "app-airport-form",
    templateUrl: "./airport-form.component.html",
    styleUrls: ["./airport-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AirportFormComponent implements OnInit{

    @Output()
    dataUpdated = new EventEmitter<boolean>();
    airportId: number = null;
    title: string;
    cityName: string;
    travelCities: TravelCity[];

    airportForm: UntypedFormGroup = this.fb.group({
        Name: this.fb.control('', [Validators.maxLength(50), this.emptyOrWhiteSpaceValidatorForNonNullField()]),
        Phone: this.fb.control('', [Validators.maxLength(20)]),
        Deleted: this.fb.control(false),
        Address1: this.fb.control('', [Validators.maxLength(50)]),
        Address2: this.fb.control('', [Validators.maxLength(50)]),
        AddressCity: this.fb.control('', [Validators.maxLength(30)]),
        State: this.fb.control('', [Validators.maxLength(2)]),
        Zip: this.fb.control('', [Validators.maxLength(10)]),
        Country: this.fb.control('', [Validators.maxLength(25)]),
        CityId: this.fb.control(null, [Validators.required]),
        TypeId: this.fb.control(null, [Validators.required]),
        Notes: this.fb.control(null),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private airportService: AirportService,
        private travelCityService: TravelCityService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {

        if (this.airportId) {
            this.loadAirport(this.airportId);
        } else {
            this.title = `New Airport`;
        }
    }

    loadAirport(airportId: number) {
        const airport$ = this.airportService.getAirportById(airportId);

        airport$
            .subscribe((airport) => {
                this.airportForm.patchValue({
                    TypeId: airport.TypeId,
                    Name: airport.Name,
                    Phone: airport.Phone,
                    Deleted: airport.Deleted,
                    Address1: airport.Address1,
                    Address2: airport.Address2,
                    AddressCity: airport.City,
                    State: airport.State,
                    Zip: airport.Zip,
                    Country: airport.Country,
                    CityId: airport.CityId,
                    Notes: airport.Notes
                });

                this.title = `Edit Airport: ${airport.Name}`;

                this.travelCityService.getTravelCities()
                    .subscribe((cities) => {
                        this.travelCities = cities
                    });
            });
    }

    submit() {
        if (this.airportForm.valid) {
            let airport: Airport = {
                Id: this.airportId,
                TypeId: this.airportForm.get('TypeId').value,
                Name: this.airportForm.get('Name').value.trim(),
                Address1: this.airportForm.get('Address1').value.trim(),
                Address2: this.airportForm.get('Address2').value.trim(),
                City: this.airportForm.get('AddressCity').value.trim(),
                CityId: this.airportForm.get('CityId').value,
                State: this.airportForm.get('State').value.trim(),
                Zip: this.airportForm.get('Zip').value.trim(),
                Country: this.airportForm.get('Country').value.trim(),
                Phone: this.airportForm.get('Phone').value.trim(),
                Notes: this.airportForm.get('Notes').value?.trim(),
                Deleted: this.airportForm.get('Deleted').value,
            };

            let updatedAirport$ = this.airportService.createOrUpdateAirport(airport);

            updatedAirport$.subscribe(updatedAirport => {
                this.toastr.success(`Saved Airport`);
                this.dataUpdated.emit(true);
                this.modalRef.hide();
            });
        }
    }

    close() {
        this.modalRef.hide();
    }

    emptyOrWhiteSpaceValidatorForNonNullField(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return (control.value.trim().length === 0) ?
                { emptyOrWhiteSpace: true } :
                null;
        }
    }
}
