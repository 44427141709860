import {Component, OnInit} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {debounceTime, map, startWith, tap} from "rxjs/operators";
import {UserService} from "../../Shared/Services/user.service";
import {Router} from "@angular/router";
import {ContactEntitlementDto, EntitlementService} from "../../Shared/Services/entitlement.service";
import {combineLatest, Observable, of} from "rxjs";
import {getNoRowsOverlayTemplate} from "../../Shared/ag-grid-options";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {FullNameFilter} from "../../../Filters/FullNameFilter";
import {CacheService} from "../../Shared/Services/cache.service";
import {ExcelUploadComponent} from "../../Widget/DataUpload/excel-upload.component";
import {BsModalService} from "ngx-bootstrap/modal";
import { nullableValueComparatorWithNullsLast } from "../../Shared/ag-grid-cell-comparators";
import {EntitlementFormComponent} from "../EntitlementForm/entitlement-form.component";
import {ColDef, RowClassParams, RowClassRules} from "ag-grid-community";

@Component({
    selector: "app-entitlement-list",
    templateUrl: "./entitlement-list.component.html",
    styleUrls: ["./entitlement-list.component.scss"]
})

export class EntitlementListComponent extends BaseGridComponent<ContactEntitlementDto> implements OnInit {

    columnDefs: ColDef[] = [
        {
            field: 'AccountName',
            tooltipValueGetter: (params) => params.data.AccountName,
            comparator: nullableValueComparatorWithNullsLast,
            flex: 1,
            sort: 'asc',
            sortIndex: 0,
        },
        {
            field: 'ContactName',
            valueGetter: this.contactNameValueGetter,
            tooltipValueGetter: this.contactNameValueGetter,
            comparator: (valueA, valueB, nodeA, nodeB) => nullableValueComparatorWithNullsLast(
                this.contactNameValueGetter(nodeA),
                this.contactNameValueGetter(nodeB)
            ),
            flex: 1,
            sort: 'asc',
            sortIndex: 1,
        },
        {
            field: 'VendorId',
            tooltipValueGetter: (params) => params.data.VendorId,
        },
        {
            field: 'Vendor',
            tooltipValueGetter: (params) => params.data.Vendor,
            flex: 1,
        },
        {
            field: 'VendorName',
            tooltipValueGetter: (params) => params.data.VendorName,
        },
        {
            field: 'EntitlementStatus',
            tooltipValueGetter: (params) => params.data.EntitlementStatus,
        },
        {
            field: 'UserStatus',
            tooltipValueGetter: (params) => params.data.UserStatus,
        },
        {
            field: 'EntitlementType',
            tooltipValueGetter: (params) => params.data.EntitlementType,
        },
        {
            field: 'BusinessUnit',
            tooltipValueGetter: (params) => params.data.BusinessUnit,
            comparator: nullableValueComparatorWithNullsLast,
        },

    ];

    rowClassRules : RowClassRules = {
        'pending-delete': (params: RowClassParams<ContactEntitlementDto>) => {
            return !!params.data.PendingDeleteId;
        }
    };

    mostRecentUpdateDate: string | null = null;

    entitlementSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(''),
        statuses: this.fb.control([]),
        businessUnits: this.fb.control([])
    });

    constructor(private entitlementService: EntitlementService,
                private userService: UserService,
                private fb: UntypedFormBuilder,
                private router: Router,
                private cacheService: CacheService,
                private modalService: BsModalService,
    ) {
        super();
    }


    statusOptions$: Observable<string[]> = of(["Active", "Inactive"]);

    ngOnInit(): void { }

    onGridReady(params) {
        super.onGridReady(params);

        this.gridOptions.overlayNoRowsTemplate = getNoRowsOverlayTemplate('Entitlements');

        let cachedFilters = this.cacheService.getValue<any>("EntitlementListFilters");

        if (cachedFilters) {
            this.entitlementSearchForm.patchValue(cachedFilters)
        }

        this.setRowData();
    }

    setRowData(): void {
        const allEntitlements$ = this.entitlementService.getContactEntitlements();

        const formUpdate$ = this.entitlementSearchForm.valueChanges.pipe(startWith(this.entitlementSearchForm.getRawValue()));

        this.entitlementService.getContactEntitlements().subscribe(entitlements => {
            const factSetVendorName = 'FactSet';

            let mostRecentUpdate = entitlements.filter(x => x.VendorName === factSetVendorName && x.UpdatedDate)
                .reduce((a, b) => {
                    return a.UpdatedDate > b.UpdatedDate ? a : b;
                });

            this.mostRecentUpdateDate = new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            }).format(new Date(mostRecentUpdate.UpdatedDate.toString()));
        });

        this.rowData$ = combineLatest([formUpdate$, allEntitlements$]).pipe(
            tap(() => this.gridApi?.showLoadingOverlay()),
            debounceTime(200),
            tap(([formValues]) => {
                this.cacheService.setValue("EntitlementListFilters", formValues);
            }),
            map(([formUpdate, entitlements]) => entitlements
                .filter(e => objectSearchPredicate(e, formUpdate.searchTerm))
                .filter(e => formUpdate.statuses.length === 0 ? true : formUpdate.statuses.includes(e.EntitlementStatus))
                .filter(e => formUpdate.businessUnits.length === 0 ? true : formUpdate.businessUnits.includes(e.BusinessUnit))
            )
        );
    }

    contactNameValueGetter(p: any): string {
        var name = FullNameFilter.filter(p.data.ContactFirstName, p.data.ContactLastName, p.data.ContactAlias);

        if (name == "Contact deleted") {
            return ''
        }
        return name
    }

    onCellClicked(event: any): void {
        const initialState = {
            vendorId: event.data.VendorId,
            vendorCode: event.data.VendorCode,
        };
        const config = { initialState: initialState, ignoreBackdropClick: true, keyboard: false, animated: false };
        let modalRef = this.modalService.show(EntitlementFormComponent, config);

        modalRef.content.dataUpdated.subscribe(() => {
            this.setRowData();
        });
    }

    openUploadModal() {
        this.openExcelModal({
            fileTitle: "Entitlement",
            fileTemplatePath: `/contactentitlements/template`,
            fileUploadPath: `/contactentitlements/update`,
        })
    }

    openExcelModal(initialState: any): void {
        let modalRef = this.modalService.show(ExcelUploadComponent,
            { initialState: initialState, ignoreBackdropClick: true, keyboard: false, animated: false });

        modalRef.content.dataUpdated.subscribe(() => {
            this.setRowData();
        });
    }
}



