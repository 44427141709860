import {Component, Input, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {Observable} from "rxjs";

@Component({
    selector: "app-number-picker",
    templateUrl:  "../BasePicker/base-picker.component.html",
    providers: pickerProviders(NumberPickerComponent),
    encapsulation: ViewEncapsulation.None,
})
export class NumberPickerComponent extends BasePickerComponent<number> {

    @Input()
    showTagCountLabel: boolean;

    @Input()
    items$: Observable<number[]>;

    @Input()
    name: string;

    @Input()
    placeHolder: string;

    @Input()
    alignRight: boolean;

    @Input()
    multiple: boolean;

    @Input()
    showSelectClearAll: boolean;

    @Input()
    customSearchable: boolean;

    @Input()
    closeOnSelect: boolean;

    constructor(fb: UntypedFormBuilder){
        super(fb);
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
