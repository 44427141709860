<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="form">
        <div class="modal-body">
            <div class="row">
                <div class="alert alert-info">{{message}}</div>
                <div [hidden]="!hasErrors" class="alert alert-danger">
                    <p [hidden]="!errorMessage" >{{errorMessage}}</p>
                    <p [hidden]="!newUserId.errors?.required">User Id is required</p>
                    <p [hidden]="!newUserId.errors?.maxlength && !newUserId.errors?.minlength">User Id must be 4 characters</p>
                </div>
            </div>
            <div class="row">
                <div class="form-group">
                    <label for="newUserId">New User Id</label>
                    <input id="newUserId" type="text" formControlName="newUserId" class="form-control input-sm" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-sm btn-primary flex" [disabled]="wait" (click)="changeUserId()">Save</button>
                <button type="button" class="btn btn-sm btn-default flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
