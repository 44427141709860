import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
    selector: "app-email-form",
    templateUrl: "./email-form.component.html"
})
export class EmailFormComponent implements OnInit{

    emailForm: UntypedFormGroup = this.fb.group({

    });

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService
                ) {}

    ngOnInit(): void {

    }

    send() {

    }

    sendTest() {

    }

    saveDraft() {

    }

    close() {
        this.modalRef.hide();
    }
}
