<div class="modal-content provider-form">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="RepAnalystForm">
        <div class="modal-body">
            <div class="flex-hbox">
                
                <div class="form-group flex">
                    <label>Type</label>
                    <select class="form-control input-sm" formControlName="Type">
                        <option [ngValue]="0">Rep</option>
                        <option [ngValue]="1">Analyst</option>
                    </select>
                </div>
                <div class="form-group flex-3">
                    <label>First Name</label>
                    <input class="form-control input-sm" type="text" formControlName="FirstName" />
                </div>
                <div class="form-group flex-3">
                    <label>Middle Initial</label>
                    <input class="form-control input-sm" type="text" formControlName="MiddleInitial" />
                </div>
                <div class="form-group flex-3">
                    <label>Last Name</label>
                    <input class="form-control input-sm" type="text" formControlName="LastName" />
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex-2">
                    <label>Title</label>
                    <input class="form-control input-sm" type="text" formControlName="Title" />
                </div>
                <div class="form-group flex">
                    <label>Deleted</label>
                    <select class="form-control input-sm" formControlName="Deleted">
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex">
                    <label>Phone</label>
                    <input class="form-control input-sm" type="text" formControlName="Phone" />
                </div>
                <div class="form-group flex">
                    <label>Mobile</label>
                    <input class="form-control input-sm" type="text" formControlName="Mobile" />
                </div>
                <div class="form-group flex">
                    <label>Email</label>
                    <input class="form-control input-sm" type="text" formControlName="Email" />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" [disabled]="RepAnalystForm.invalid" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

