import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityService, MeetingContext} from "../../../Shared/Services/activity.service";

@Component({
    selector: "app-meeting-context-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(MeetingContextPickerComponent)
})
export class MeetingContextPickerComponent extends BasePickerComponent<MeetingContext> {

    @Input()
    searchable: boolean = true;
    
    @Input()
    placeHolder: string = "";
    
    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);
        
        this.name = "Meeting Context(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.activityService.getMeetingContexts();
    }
}
