import {Component, Input, OnChanges, OnInit, SimpleChanges, EventEmitter} from "@angular/core";
import {BaseGridComponent} from "../../../Shared/base-grid.component";
import {UntypedFormBuilder} from "@angular/forms";
import type {DateRange} from "../../DateRangePicker/date-range";
import * as moment from "moment";
import {dateRenderer, participantsRenderer} from "../../../Shared/ag-grid-cell-renderers";
import {dateComparator} from "../../../Shared/ag-grid-cell-comparators";
import * as _ from "lodash";
import {UserService} from "../../../Shared/Services/user.service";
import {DatePickerOptionsBuilder} from "../../../../DatePickerOptionsBuilder";
import {User} from "../../../../Models/User";
import {BsModalService} from "ngx-bootstrap/modal";
import {TextModalComponent} from "../../TextModal/text-modal.component";
import {AccountService} from "../../../Shared/Services/account.service";
import {Account} from "../../../../Models/Account";
import * as daterangepicker from "bootstrap-daterangepicker";
import {EesActivityFormComponent} from "../../../Activity/EESActivityForm/ees-activity-form.component";
import {ConfigService} from "../../../Shared/Services/config.service";
import {EESActivity, EESActivityService} from "../../../Shared/Services/ees-activity.service";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {combineLatest, of} from "rxjs";
import { ColDef } from "ag-grid-community";
import { UserTeam } from "../../../../Models/UserTeam";
import {FullNameFilter} from "../../../../Filters/FullNameFilter";

@Component({
    selector: "app-tab-ees-activity-list",
    templateUrl: "./tab-ees-activity-list.component.html"
})

export class TabEESActivityListComponent extends BaseGridComponent<EESActivity> implements OnInit, OnChanges {

    @Input()
    contactId: number;

    @Input()
    accountId: number;

    @Input()
    dateRange: DateRange;

    @Input()
    dateRangeCenterAlign: boolean = false;

    @Input()
    showAccountName: boolean = true;

    @Input()
    showContactShortName: boolean = true;

    @Input()
    activityDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    dateRangeTopAlign: boolean = false;

    notShowingAllRecords: boolean = false;
    dateRangeOptions: daterangepicker.Options;
    user: User;
    account: Account;
    users: User[];
    userTeams: UserTeam[];

    columnDefs: ColDef[] = [
        { field: 'Contact', headerName: 'Name', width: 80, cellRenderer: this.contactRenderer, tooltipValueGetter: this.contactTooltip },
        {
            field: 'Baird',
            headerName: 'Baird',
            width: 80,
            cellRenderer: (params) => participantsRenderer([{ UserId: params.data.Baird, TeamId: params.data.BairdTeamId }], this.userTeams),
            tooltipValueGetter: (params) => this.participantsToolTip(params),
        },
        {
            field: 'ActivityDate',
            headerName: 'Call Date',
            width: 130,
            sort: 'desc',
            cellRenderer: dateRenderer,
            comparator: (valueA, valueB, nodeA, nodeB) => dateComparator(nodeA.data.ActivityDate, nodeB.data.ActivityDate)
        },
        { field: 'Comment', flex: 2, tooltipValueGetter: (p) => p.data.Comment },
        { field: 'Status', flex: 1, cellRenderer: this.statusRenderer },
        {
            field: 'DueDate',
            headerName: 'Due Date',
            width: 130,
            cellRenderer: dateRenderer,
            comparator: (valueA, valueB, nodeA, nodeB) => dateComparator(nodeA.data.DueDate, nodeB.data.DueDate)
        },
    ];

    rowClassRules = {
        'moved-contact': (params) => params.data.Contact && params.data.Contact.Account.Id !== params.data.EesActivityAccount.Id,
        'deleted-contact': (params) => !params.data.Contact,
    };

    form = this.fb.group({
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control({ start: moment().add(-3, "month"), end: moment() } as DateRange),
        types: this.fb.control([]),
        statuses: this.fb.control([]),
        contactId: this.fb.control(null),
        accountId: this.fb.control(null),
    });

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private accountService: AccountService,
                private modalService: BsModalService,
                private configService: ConfigService,
                private eesActivityService: EESActivityService) {
        super();

        this.gridOptions.pagination = true;
    }

    ngOnInit(): void {
        const optionsBuilder = new DatePickerOptionsBuilder();
        optionsBuilder.setRangesToPredefinedList(moment());
        if(this.dateRangeCenterAlign) {
            optionsBuilder.setToCenterAligned()
        }
        if (this.dateRangeTopAlign) {
            optionsBuilder.setToTopAligned();
        }
        this.dateRangeOptions = optionsBuilder.buildOptions();

        this.gridOptions.overlayNoRowsTemplate = `<span>No Activities Found</span>`;
        this.userService.getCurrentUser().subscribe(user => this.user = user);

        if(!_.isNil(this.accountId)){
            this.accountService.getAccountById(this.accountId).subscribe(account => this.account = account);
        }

        if (this.dateRange) {
            this.form.patchValue({
                dateRange: this.dateRange
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactId) {
            this.form.controls["contactId"].patchValue(changes.contactId.currentValue);
        }
        if (changes.accountId) {
            this.form.controls["accountId"].patchValue(changes.accountId.currentValue);
        }
    }

    onRowClicked($event: any) {
        if($event.data.Tags && _.includes($event.data.Tags, 'DeletedContact')) {
            let initialState = { message: 'Contact has been deleted or moved to an account you do not have access to view.' };
            let modalRef = this.modalService.show(TextModalComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-sm' });
        } else {
            this.openActivityForm($event.data.Id);
        }
    }

    addActivity() {
        this.openActivityForm(undefined);
    }

    openActivityForm(activityId: number): void {
        let initialState = {
            activityId: activityId,
            contactIds: this.contactId ? [this.contactId] : [],
            accountId: this.accountId,
        }
        let modalRef = this.modalService.show(EesActivityFormComponent, {
            animated: false,
            keyboard: false,
            backdrop: 'static',
            initialState: initialState,
        });

        modalRef.content.dataUpdated
            .subscribe(() => {
                this.form.updateValueAndValidity({onlySelf: false, emitEvent: true});
                this.form.get('dateRange').updateValueAndValidity({onlySelf: false, emitEvent: true});
            });
    }

    statusRenderer(params: any): string {
        return params.value === 'O' ? 'Open' :
               params.value == 'C' ? 'Completed' : '';
    }

    contactRenderer(params: any) {
        return !params.data.Contact
            ? ''
            : params.data.Contact.ShortName;
    }

    contactTooltip(params: any) {
        return !params.data.Contact
            ? 'Contact Deleted'
            : FullNameFilter.filter(params.data.Contact.FirstName, params.data.Contact.LastName, params.data.Contact.Alias);
    }

    participantsToolTip(params: any) {
        return _.filter(this.users, u => _.includes([params.data.Baird], u.Id)).map(bc => bc.LastName).sort().join(",");
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.gridColumnApi.setColumnVisible('ContactShortName', this.showContactShortName);

        let activities$ = this.form.valueChanges.pipe(
            startWith(this.form.value),
            switchMap((val: any) => this.eesActivityService.searchActivities(
                val.accountId,
                val.contactId,
                val.dateRange.start,
                val.dateRange.end,
                val.searchTerm,
                null,
                val.types,
                val.statuses,
            ))
        );

        let users$ = this.userService.getUsers();
        let userTeams$ = this.userService.getUserTeams();

        this.rowData$ = combineLatest([activities$, users$, userTeams$]).pipe(
            tap(([activities, users, userTeams]) => {
                this.userTeams = userTeams;
                this.users = users;
            }),
            map(([activities, users, userTeams]) => {
                activities.forEach(a => {
                    a.BairdTeamId = users.find(u => u.Id === a.Baird)?.Team?.Id;
                    a.Status = a.Status ?? '';
                });
                return activities;
            })
        )

    }
}
