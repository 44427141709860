import {Component} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-text-modal",
    templateUrl: "./text-modal.component.html"
})
export class TextModalComponent {

    message: string;

    constructor(public bsModalRef: BsModalRef) { }

    confirm(): void {
        this.bsModalRef.hide();
    }
}
