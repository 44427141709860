<app-loading class="flex" *ngIf="isLoading"></app-loading>
<ng-container *ngIf="rowData$ | async as rowData">
    <ag-grid-angular
        *ngIf="!isLoading"
        style="width: 100%; height: 100%;"
        class="ag-theme-balham"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        (rowClicked)="onRowClicked($event)"
    >
    </ag-grid-angular>
</ng-container>
