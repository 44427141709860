import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {SavedContactList} from "../../../Admin/ListGroups/saved-contact-list";
import {ContactListsService} from "../../../Contact/ContactLists/contact-lists.service";

@Component({
    selector: "app-saved-contact-lists-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(SavedContactListsPickerComponent)
})
export class SavedContactListsPickerComponent extends BasePickerComponent<SavedContactList> {
    @Input()
    multiple: boolean;
    
    constructor(fb: UntypedFormBuilder, private contactListsService: ContactListsService){
        super(fb);

        this.name = "Contact List(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }

    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.contactListsService.getAllLists();
    }
}
