import {Component,OnInit} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import * as _ from "lodash";


@Component({
    selector: 'app-back-office-overview-modal',
    templateUrl: "./back-office-overview-modal.component.html"
})
export class BackOfficeOverviewModalComponent implements OnInit {

    backOfficeAccounts: BackOfficeAccount[];

    constructor(private modalService: BsModalService,
                private bsModalRef: BsModalRef){
    }

    ngOnInit(): void {
        _.sortBy(this.backOfficeAccounts, b => {
            return b.Name;
        });
    }


    dismiss(): void {
        this.bsModalRef.hide();
    }

}

interface BackOfficeAccount {
    Id: string;
    Name: string;
    Quantity: number;
    Percentage: number;
}
