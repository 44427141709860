import {FeatureManager} from "./FeatureManager";
import {Feature} from "./Models/Feature";
import {ActivityCategory} from "./Models/ActivityCategory";
import {ActivityCategoryFilter} from "./Filters/ActivityCategoryFilter";
import * as _ from "lodash";
import {TeamName} from "./Models/Team";

export class ActivityFeatureManager extends FeatureManager {
    
    constructor(features: Array<Feature>, private role: string, private team: string) {
        super(features);
    }

    getCategories(): ActivityCategory[] {
        let categories: ActivityCategory[] = [];

        if (this.team === TeamName.MarketMaker) {
            return _.concat(categories, this.getMarketMakerCategories());
        }

        if (this.role === 'Research' || this.role === 'Conference Services') {
            categories = _.concat(categories, this.getResearchCategories());
        } else {
            categories = _.concat(categories, this.getNonResearchCategories());

            if (this.hasFeature('CanEditResearchCallType')) {
                categories = _.concat(categories, this.getResearchCategories());
            }
        }

        if (this.hasFeature('CanEditCorpAccessCallType')) {
            categories = _.concat(categories, this.getCorpAccessCategories());
        }
        
        return categories;
    }

    getAllCategories(): ActivityCategory[] {
        return _.chain([])
            .concat(this.getResearchCategories())
            .concat(this.getCorpAccessCategories())
            .concat(this.getNonResearchCategories())
            .sortBy(c => c.Description)
            .concat(this.getMeetingCategories())
            .value();
    }

    isCorpAccess(category: string): boolean {
        return _.includes(['A','K','C','V','I','N','0','-'], category) || (category === 'E' && this.role === 'Conference Services');
    }

    getMeetingCategories(): ActivityCategory[] {
        return this.getActivityCategories(['M-S','M-T']);
    }

    getResearchCategories(): ActivityCategory[] {
        return this.getActivityCategories(['P','S','W','E','X','D']);
    }

    getCorpAccessCategories(): ActivityCategory[] {
        return this.getActivityCategories(['A','K','C','V','I','N','0','-']);
    }

    getNonResearchCategories(): ActivityCategory[] {
        return this.getActivityCategories(['H','M','G','Z','J','R']);
    }

    getMarketMakerCategories(): ActivityCategory[] {
        return this.getActivityCategories(['P','E','X']);
    }
    
    getSalesDashboardCategories(): ActivityCategory[] {
        return this.getActivityCategories(['D','P','X']);
    }
    
    getTickerResearchCategories(): ActivityCategory[] {
        return this.getActivityCategories(['A','P','X','D']);
    }

    getTickerCorpCategories(): ActivityCategory[] {
        return this.getActivityCategories(['K','C','V','I','N']);
    }
    
    private getActivityCategories(catgories: string[]): ActivityCategory[] {
        return _.map(catgories, category => new ActivityCategory(category, ActivityCategoryFilter.filter(category)));
    }
}