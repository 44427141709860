import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventService} from "../../Shared/Services/event.service";
import {QueryService} from "../../Shared/Services/query.service";
import {ConfigService} from "../../Shared/Services/config.service";
import {NewMeetingService} from "./new-meeting.service";
import {combineLatest} from "rxjs";

@Component({
    selector: "app-company-form",
    templateUrl: "./company-form.component.html"
})
export class CompanyFormComponent implements OnInit {

    @Input()
    eventId: number;

    @Input()
    companyId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    company: any = {};

    companyForm = this.fb.group({

    });

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private meetingService: NewMeetingService,
                private modalService: BsModalService,
                private eventService: EventService,
                private queryService: QueryService,
                private toastrService: ToastrService,
                private configService: ConfigService) {
    }

    ngOnInit(): void {

        if (this.eventId) {
            // add new company
        } else if (this.companyId) {
            this.meetingService.getEventCompany(this.eventId, this.companyId)
                .subscribe(company => {
                    this.company = company;
                })
        }

    }

    save() {
        this.modalRef.hide();
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        this.modalRef.hide();
    }
}
