import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "app-exports-dropdown",
    templateUrl: "./exports-dropdown.component.html",
    styleUrls: ["./exports-dropdown.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ExportsDropdownComponent implements OnInit {
    @Input()
    features: string[];

    canExportCSV: boolean = false;

    @Output()
    exportToPDF: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    exportToCSV: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
        this.canExportCSV = this.features.includes("CommissionExport");
    }

    onExportToPDF(): void {
        this.exportToPDF.emit();
    }

    onExportToCSV(): void {
        this.exportToCSV.emit();
    }
}
