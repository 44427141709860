import {Component, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

@Component({
    selector: "app-sales-dashboard",
    templateUrl: "./sales-dashboard.component.html",
    styleUrls: ["./sales-dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SalesDashboardComponent {

    salesDashboard: UntypedFormGroup = this.fb.group({
        categories: this.fb.control(["D", "P", "X"]),
        selectedTicker: this.fb.control(null),
    });

    constructor(private fb: UntypedFormBuilder) {}

    onCategorySelected(categories: string[]): void {
        this.salesDashboard.get("categories").patchValue(categories);
    }

    onTickerSelected(ticker: string): void {
        this.salesDashboard.get("selectedTicker").patchValue(ticker);
    }
}
