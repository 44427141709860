import {Routes} from "@angular/router";
import {TickerDetailComponent} from "./TickerDetail/ticker-detail.component";
import {TickerPopoutComponent} from "./TickerPopout/ticker-popout.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {MsalGuard} from "@azure/msal-angular";
import {TickerRoutePaths} from "./ticker-route-paths";

export const tickerRoutes: Routes = [
    {
        path: `${TickerRoutePaths.TickerDetail}/:ticker`,
        component: TickerDetailComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Ticker Details"
        },
    },
    {
        path: `${TickerRoutePaths.TickerPopout}/:ticker`,
        component: TickerPopoutComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Ticker Popout",
            hideShell: true
        },
    }
];
