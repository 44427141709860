import * as _ from "lodash";
import {Feature} from "./Models/Feature";

export class FeatureManager {
    private features: Feature[];

    constructor(features: Feature[]) {
        this.features = features;
    }

    protected hasFeature(name: string) {
        return _.includes(
            _.map(
                this.features,
                (f: Feature) => f.Name),
            name);
    }
}