<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="repAnalystSearchForm">
            <div class="form-group" style="min-width: 175px;">
                <app-baird-rep-analyst-type-picker
                        formControlName="searchType"
                        [selectClearAll]="false"
                        [multipleSelect]="false"
                        [showTagCount]="false"
                        [closeAfterSelection]="true"
                        [setMaxItems]="1"
                        [includeNullOption]="true"
                        [placeHolderLabel]="'Select Type'"
                >
                </app-baird-rep-analyst-type-picker>
            </div>
            <div class="form-group">
                <input
                        type="search"
                        class="form-control input-sm"
                        style="width: 250px;"
                        formControlName="searchTerm"
                        placeholder="Search"
                />
            </div>
            <div class="form-group">
                <div class="btn btn-primary btn-sm" (click)="addRepAnalyst()"><span class="fa fa-plus"></span></div>
            </div>
        </form>
    </ng-template>

    <app-page-banner
            pagetitle="{{ getRepAnalystTypeDisplayName() }} Admin"
            faicon="{{ getRepAnalystTypeDisplayIcon() }}"
            faiconcolor="#800000"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>
