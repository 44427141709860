import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {HomeComponent} from "./Home/home.component";
import {ContactModule} from "../Contact/contact.module";
import {ResearchLandingComponent} from "./Research/research-landing.component";
import {TabsModule} from "ngx-bootstrap/tabs";
import {DashboardModule} from "../Dashboard/dashboard.module";
import {WidgetModule} from "../Widget/widget.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NewSubscribersComponent} from "./NewSubscribers/new-subscribers.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {ActivitiesByTickerChartComponent} from "./ActivitiesByTickerChart/activities-by-ticker-chart.component";
import {RegionActivitySummaryComponent} from "./RegionActivitySummary/region-activity-summary.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        ContactModule,
        TabsModule,
        DashboardModule,
        AgGridModule.withComponents([]),
        TooltipModule,
    ],
    declarations: [
        HomeComponent,
        ResearchLandingComponent,
        NewSubscribersComponent,
        ActivitiesByTickerChartComponent,
        RegionActivitySummaryComponent,
    ],
})
export class HomeModule {

}
