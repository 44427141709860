<div class="modal-body">
    <div class="row">
        <div class="col-xs-12">
            <p *ngIf="message">{{ message }}</p>
            <p *ngFor="let message of messages">{{ message }}</p>
        </div>
        <div class="col-xs-6">
            <button type="button" class="col-xs-12 btn btn-danger" (click)="confirm()" >Yes</button>
        </div>
        <div class="col-xs-6">
            <button type="button" class="col-xs-12 btn btn-default" (click)="decline()" >No</button>
        </div>
    </div>
</div>