import {Component, EventEmitter, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UserService} from "../../Shared/Services/user.service";
import {CacheService} from "../../Shared/Services/cache.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {combineLatest, forkJoin, iif, Observable, of} from "rxjs";
import * as moment from "moment";
import {accountTagsVoteRenderer, favoriteCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {
  accountTagComparator,
  getGridDisplayWords,
  isNoContactContact,
  isNoContactReadOnlyContact
} from "../../Shared/ag-grid-options";
import {ToastrService} from "ngx-toastr";
import {Contact} from "../../../Models/Contact";
import {
  InterestListModalFilters,
  MoreFiltersModalComponent
} from "../../Widget/MoreFilters/more-filters-modal.component";
import {Query, QueryGroup} from "../../../Services/QueryService";
import {debounceTime, map, startWith, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {QuerySelectModalComponent} from "../../Widget/Query/query-select-modal.component";
import {ContactListSaveModalComponent} from "../../Widget/ContactList/contact-list-save-modal.component";
import {ContactService} from "../../Shared/Services/contact.service";
import {FullNameFilter} from "../../../Filters/FullNameFilter";
import * as _ from "lodash";
import {DateRange} from "../../Widget/DateRangePicker/date-range";
import {ColDef, NavigateToNextCellParams, RowClassRules, ValueGetterParams} from "ag-grid-community";
import {IFilterStateOptions} from "../../../Models/FilterStateOptions";
import {InterestListFormValues} from "./interest-list-form-values";
import {ContactListOpenModalComponent} from "../../Widget/ContactList/contact-list-open-modal.component";
import {ExcludeContactsModalComponent} from "../../Widget/ExcludeContactsModal/exclude-contacts-modal.component";
import {ActivityFormComponent} from "../../Activity/ActivityForm/activity-form.component";
import {User} from "../../../Models/User";
import {isContactable} from "../../Shared/research-status";
import {ClipboardService} from "../../Shared/Services/clipboard.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactRoutePaths} from "../contact-route-paths";
import {AccountRoutePaths} from "../../Account/account-route-paths";

@Component({
  selector: "app-interest-list",
  templateUrl: "./interest-list.component.html",
  styleUrls: ["./interest-list.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class InterestListComponent extends BaseGridComponent<Contact> implements OnInit {

    static readonly FiltersCacheKey: string = "InterestListFilters";
    static readonly NavigatedFiltersCacheKey: string = "InterestListNavigatedFilters";

    contactUpdated: EventEmitter<void> = new EventEmitter<void>();

    columnDefs: ColDef[] = [
        {
            field: "Account.Tags",
            headerName: "",
            width: 50,
            cellRenderer: accountTagsVoteRenderer,
            comparator: accountTagComparator,
            headerTooltip: "Grid"
        },
        {field: "Favorite", headerName: "", width: 50, cellRenderer: favoriteCellRenderer, headerTooltip: "Favorites"},
        {
            field: "Name",
            flex: 2,
            valueGetter: this.contactNameValueGetter,
            sort: "asc",
            comparator: (a, b) => {
                const aVal = a.trim();
                const bVal = b.trim();
                if (_.startsWith(aVal, ",") && _.startsWith(bVal, ",")) {
                    return 0;
                }
                if (_.startsWith(aVal, ",") && !_.startsWith(bVal, ",")) {
                    return 1
                }
                if (_.startsWith(bVal, ",") && !_.startsWith(aVal, ",")) {
                    return -1;
                }
                return aVal.localeCompare(bVal);
            }
        },
        {field: "Account.Name", headerName: "Account", flex: 2},
        {field: "Tier", flex: 1},
        {
            colId: "FromHoldingQuery",
            width: 60,
            headerName: "H&I",
            cellRenderer: p => p.data.FromHoldingQuery ? `<span class="fa fa-circle" style="color: #ccc;"></span>` : "",
            comparator: (a, b, aNode, bNode, isInverted) => {
                if (aNode.data.FromHoldingQuery && !bNode.data.FromHoldingQuery) {
                    return 1;
                }
                if (!aNode.data.FromHoldingQuery && bNode.data.FromHoldingQuery) {
                    return -1;
                }
                if (isInverted) {
                    if (aNode.data.FromActivityQuery && !bNode.data.FromActivityQuery) {
                        return 1;
                    }
                } else {
                    if (aNode.data.FromActivityQuery && !bNode.data.FromActivityQuery) {
                        return -1;
                    }
                }
                return 0;
            }
        },
        {
            colId: "FromActivityQuery",
            width: 60,
            headerName: "Act",
            cellRenderer: p => p.data.FromActivityQuery ? `<span class="fa fa-circle" style="color: #ccc;"></span>` : "",
            comparator: (a, b, aNode, bNode, isInverted) => {
                if (aNode.data.FromActivityQuery && !bNode.data.FromActivityQuery) {
                    return 1;
                }
                if (!aNode.data.FromActivityQuery && bNode.data.FromActivityQuery) {
                    return -1;
                }
                if (isInverted) {
                    if (aNode.data.FromHoldingQuery && !bNode.data.FromHoldingQuery) {
                        return 1;
                    }
                } else {
                    if (aNode.data.FromHoldingQuery && !bNode.data.FromHoldingQuery) {
                        return -1;
                    }
                }
                return 0;
            }
        },
    ];

    rowClassRules: RowClassRules = {
        "do-not-contact": params => isNoContactContact(params.data),
        "do-not-contact-readonly": params => isNoContactReadOnlyContact(params.data),
    };

    holdingSearchForm: UntypedFormGroup = this.fb.group({
        grid: this.fb.control(false),
        favorites: this.fb.control(false),
        myAccounts: this.fb.control(false),
        contactSearch: this.fb.control(""),
        tiers: this.fb.control([]),
        tickers: this.fb.control([]),
        instiselSectors: this.fb.control([]),
        analysts: this.fb.control([]),
        categories: this.fb.control([]),
        searchBy: this.fb.control(""),
        savedContactListId: this.fb.control(null),
        moreFilters: this.fb.control([])
    });

    applyFiltersInterests: UntypedFormControl = this.fb.control(true);
    applyFiltersActivities: UntypedFormControl = this.fb.control(false);

    selectedContactId: number;
    contact: Contact;
    assistantIds: number[];

    queries: Query[];
    holdingQueryGroups: QueryGroup[] = [];
    activityQueryGroups: QueryGroup[] = [];

    defaultDateRange: DateRange = {
        start: moment().add(-2, "year"),
        end: moment()
    };

    hasContacts: boolean = false;

    includeDoNotContactContacts: boolean = true;

    activityDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    contactDetailBaseBath = ContactRoutePaths.ContactDetail;
    accountDetailBaseBath = AccountRoutePaths.AccountDetail;

    constructor(private fb: UntypedFormBuilder,
                private contactService: ContactService,
                private userService: UserService,
                private cacheService: CacheService,
                private modalService: BsModalService,
                private toastrService: ToastrService,
                private clipboardService: ClipboardService,
                private route: ActivatedRoute,
                private router: Router) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.rowSelection = "single";
        this.gridOptions.suppressCellFocus = false;
        this.gridOptions.overlayNoRowsTemplate = `<span>No Contacts Found</span>`;
        this.gridOptions.navigateToNextCell = (params: NavigateToNextCellParams) => {
            const suggestedNextCell = params.nextCellPosition;

            // this is some code
            const KEY_UP = 'ArrowUp';
            const KEY_DOWN = 'ArrowDown';

            const noUpOrDownKeyPressed = params.key !== KEY_DOWN && params.key !== KEY_UP;
            if (noUpOrDownKeyPressed) {
                return suggestedNextCell;
            }

            this.gridApi.forEachNode(node => {
                if (node.rowIndex === suggestedNextCell.rowIndex) {
                    node.setSelected(true);
                }
            });

            return suggestedNextCell;
        };

        const formValuesFromParams = this.getInterestListFormValuesFromNavigation();
        const cachedFilters = this.cacheService.getValue<InterestListFormValues>(InterestListComponent.FiltersCacheKey);

        let filters$: Observable<any>;

        if (Object.values(formValuesFromParams).some(p => !!p)) {
            filters$ = of(formValuesFromParams);
        } else if (!!cachedFilters) {
            filters$ = of(cachedFilters);
        } else {
            filters$ = this.getSavedFilters()
        }

        filters$.pipe(
            withLatestFrom(this.userService.getCurrentUser()),
            switchMap(([userPreferenceFilters, user]) => {
                if (!userPreferenceFilters) {
                    return this.userService.getAnalysts().pipe(
                        map(analysts => {
                            const defaultAnalyst = user.Team.Name.split(",")[0];
                            return {
                                ...this.getDefaultFilters(),
                                grid: false,
                                analysts: analysts.filter(a => a.LastName === defaultAnalyst).map(a => a.Id)
                            };
                        })
                    );
                }
                return of(userPreferenceFilters);
            })
        )
            .subscribe(userPreferenceFilters => {
                this.resetFilters(userPreferenceFilters);
                this.holdingSearchForm.markAsPristine();
                this.setRowData();
            });
    }

    private getInterestListFormValuesFromNavigation(): InterestListFormValues {
        const interestListFormValues = {} as InterestListFormValues;

        const moreFilters = this.cacheService.getValue<QueryGroup[]>(InterestListComponent.NavigatedFiltersCacheKey);

        if (moreFilters) {
            interestListFormValues.moreFilters = moreFilters;
            this.cacheService.removeKey(InterestListComponent.NavigatedFiltersCacheKey);
        }

        return interestListFormValues;
    }

    private getSavedFilters(): Observable<InterestListFormValues> {
        return this.userService.getCurrentUser().pipe(
            tap((user: User) => {
                this.includeDoNotContactContacts = this.userService.canViewContactEmailWithDoNotContact(user);
            }),
            switchMap(user => this.userService.getFilters(user.Id)),
            switchMap(savedFilters => {
                if (savedFilters?.NewInterestList) {
                    if (!savedFilters.NewInterestList.moreFilters.some(mf => mf.Queries.some(q => q.Field === "Call Within Days"))) {
                        QueryTranslator.AddQueryGroupValue(savedFilters.NewInterestList.moreFilters, "Activity", "Call Within Days", "is", 365);
                    }

                    savedFilters.NewInterestList.moreFilters = savedFilters.NewInterestList.moreFilters
                        .map(queryGroup => {

                            queryGroup.Queries = queryGroup.Queries
                                .map(q => {
                                    if (q.Object == "Contact" && q.Field == "Research Email") {
                                        q.Object = "Holding";
                                    }
                                    if (q.Object == "Contact" && q.Field == "Marketing Email") {
                                        q.Object = "Holding";
                                    }
                                    return q;
                                })

                            return queryGroup;
                        });

                    return of(savedFilters?.NewInterestList);
                } else if (savedFilters?.InterestList) {
                    return this.convertOldUserPreferenceFilters(savedFilters?.InterestList).pipe(
                        tap(userPreferenceFilters => this.saveFilters(userPreferenceFilters, false))
                    )
                }
                return of(null);
            })
        );
    }

    private convertOldUserPreferenceFilters(filters: IFilterStateOptions): Observable<InterestListFormValues> {
        return this.userService.getAnalysts().pipe(
            map(analysts => {
                const newUserPreferenceFilters: InterestListFormValues = {
                    grid: filters.gridEnabled,
                    favorites: filters.favoriteEnabled,
                    myAccounts: filters.myEnabled,
                    contactSearch: filters.search,
                    tiers: filters.tiers,
                    tickers: filters.tickerSearch ? filters.tickerSearch.split(",").map(t => t.trim()) : [],
                    instiselSectors: filters.sectors,
                    analysts: filters.analysts.map(fa => analysts.find(a => a.LastName === fa)?.Id).filter(fa => !!fa),
                    categories: filters.categories,
                    searchBy: filters.searchBy,
                    savedContactListId: +filters.savedContactListId,
                    moreFilters: []
                };
                const moreFiltersMapping: InterestListModalFilters = {
                    Regions: filters.regions?.map(r => +r) ?? [],
                    InvestorTypes: filters.investorTypes,
                    Markets: filters.markets?.map(r => +r) ?? [],
                    InvestorStrategies: filters.investorStrategies,
                    PrimaryBrokers: filters.brokers,
                    MarketingEmails: filters.marketingEmails?.length === 1 ? filters.marketingEmails : [],
                    ResearchEmails: filters.researchEmails?.length === 1 ? filters.researchEmails : [],
                    ResearchVms: filters.researchVoiceMails?.length === 1 ? filters.researchVoiceMails : [],
                    PersonalBrokers: filters.personalBrokers,
                    Roles: filters.roles,
                    BairdContacts: filters.bairdFilter.map(f => {
                        if (f === "RWB") {
                            return "IER";
                        }
                        if (f === "PCG") {
                            return "PWM";
                        }
                        return f;
                    }),
                    InvestorStyles: filters.investorStyles,
                    GlobalInvestors: filters.globalInvestors?.length === 1 ? filters.globalInvestors : [],
                    Orientations: filters.orientations,
                    PrivateInvestments: filters.privateInvestments?.length === 1 ? filters.privateInvestments : [],
                    DealPlayers: filters.dealPlayers,
                    ActivityStartDays: filters.activityStartDays,
                    ActivityCategories: filters.activityCategories,
                    BlueMatrix: filters.blueMatrix?.length === 1 ? filters.blueMatrix : [],
                    AumRange: {low: +filters.aumMin, high: +filters.aumMax},
                    EquityAumRange: {low: +filters.equityAumMin, high: +filters.equityAumMax},
                    MarketCapRange: {low: +filters.marketCapMin, high: +filters.marketCapMax},
                    DoNotContacts: [],
                    SavedContactListId: null
                }

                newUserPreferenceFilters["moreFilters"] = MoreFiltersModalComponent.mapMoreFiltersToQueryGroups(moreFiltersMapping);
                return newUserPreferenceFilters;
            })
        )
    }

    private setRowData(): void {
        this.rowData$ = combineLatest([
            this.holdingSearchForm.valueChanges.pipe(
                startWith(this.holdingSearchForm.getRawValue())
            ),
            this.contactUpdated.pipe(
                startWith(true)
            )
        ]).pipe(
            debounceTime(200),
            tap(([formValues]) => {
                this.cacheService.setValue(InterestListComponent.FiltersCacheKey, formValues);
                this.gridApi?.showLoadingOverlay();
                this.gridOptions.columnApi.setColumnsVisible(["FromHoldingQuery", "FromActivityQuery"], formValues.searchBy === "Both");
                this.selectedContactId = null;
                this.contact = null;
                this.assistantIds = [];
            }),
            switchMap(([formValues]) => {
                this.holdingQueryGroups = this.getHoldingQueryGroups(formValues);
                this.activityQueryGroups = this.getActivityQueryGroups(formValues);

                this.queries = [];
                if (["Holding", "Both"].includes(formValues.searchBy)) {
                    this.queries.push({QueryGroups: this.holdingQueryGroups} as Query)
                }
                if (["Activity", "Both"].includes(formValues.searchBy)) {
                    this.queries.push({QueryGroups: this.activityQueryGroups} as Query)
                }
                return this.queries.length > 0 && this.hasEnoughFilters(formValues) ?
                    this.contactService.getContactsByQueries(this.queries) :
                    of([]);
            }),
            tap(rowData => this.hasContacts = rowData.length > 0)
        );
    }

    contactNameValueGetter(p: ValueGetterParams): string {
        return FullNameFilter.filter(p.data.FirstName, p.data.LastName, p.data.Alias);
    }

    getDisplayWords(): string {
        return getGridDisplayWords(this.gridApi);
    }

    hasEnoughFilters(formValues): boolean {
        return !_.isEqualWith(formValues, this.getDefaultFilters(), this.equalCustomizer);
    }

    equalCustomizer(thisVal, othVal, key): undefined | boolean {
        if (key === "searchBy") {
            return true;
        }
    }

    private addContactAccountQueryGroups(formValues): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];

        if (formValues.grid) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Account", "Grid", "is", `${formValues.grid}`);
        }

        if (formValues.favorites) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "Favorite", "is", `${formValues.favorites}`);
        }

        if (formValues.myAccounts) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Account", "My Account", "is", formValues.myAccounts);
        }

        if (formValues.contactSearch) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "Search", "contains", formValues.contactSearch);
        }

        if (formValues.tiers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Contact Tier", "contains", formValues.tiers);
        }

        if (formValues.savedContactListId) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "List", "is", formValues.savedContactListId);
        }

        return queryGroups;
    }

    private getHoldingQueryGroups(formValues): QueryGroup[] {
        let queryGroups = this.addContactAccountQueryGroups(formValues);

        if (formValues.tickers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Ticker", "contains", formValues.tickers);
        }

        if (formValues.instiselSectors.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Sector", "contains", formValues.instiselSectors);
        }

        if (formValues.analysts.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Analyst", "contains", formValues.analysts);
        }

        if (formValues.categories.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Category", "contains", formValues.categories);
        }

        queryGroups.push(...formValues.moreFilters.filter(g => g.Queries[0].Object !== "Activity"));

        return queryGroups;
    }

    private getActivityQueryGroups(formValues): QueryGroup[] {
        let queryGroups = this.addContactAccountQueryGroups(formValues);

        if (formValues.tickers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Tickers", "contains", formValues.tickers);
        }

        if (formValues.instiselSectors.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Sector", "contains", formValues.instiselSectors);
        }

        if (formValues.analysts.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Activity", "Ticker Coverage", "contains", formValues.analysts);
        }

        queryGroups.push(...formValues.moreFilters.filter(g => g.Queries[0].Object !== "Holding"));

        return queryGroups;
    }

    resetFilters(override: Partial<InterestListFormValues> = {}) {
        this.holdingSearchForm.patchValue({...this.getDefaultFilters(), ...override});
    }

    private getDefaultFilters(): InterestListFormValues {
        let defaultMoreFilters: QueryGroup[] = [];
        QueryTranslator.AddQueryGroupValues(defaultMoreFilters, "Account", "Contact Groups", "not contains", ["IER", "PWM"]);
        QueryTranslator.AddQueryGroupValue(defaultMoreFilters, "Activity", "Call Within Days", "is", 365);
        QueryTranslator.AddQueryGroupValues(defaultMoreFilters, "Activity", "Category", "contains", ["P", "X", "D", "A", "V", "N", "C", "I", "K"]);
        return {
            grid: false,
            favorites: false,
            myAccounts: false,
            contactSearch: "",
            tiers: [],
            tickers: [],
            instiselSectors: [],
            analysts: [],
            categories: [],
            searchBy: "Holding",
            savedContactListId: null,
            moreFilters: defaultMoreFilters
        };
    }

    copyEmailToClipboard() {
        const rowData = this.getRowData();

        const emails = rowData
            .filter(contact => this.includeDoNotContactContacts || isContactable(contact))
            .map(r => r.Email)
            .filter(email => email)
            .filter((email, index, arr) => arr.indexOf(email) === index);

        if (emails.length > 0) {
            this.clipboardService.writeText(emails.join(";"))
                .subscribe(_ => {
                    this.toastrService.success(`${emails.length} Emails Copied to Clipboard`);
                });
        }
    }

    exportToExcel() {
        const rowData = this.getRowData();
        if (rowData.length === 0) return;

        this.contactService.getContactsExport(this.queries, 'InterestList').subscribe();
    }

    moreFilters() {
        const modalRef = this.modalService.show(MoreFiltersModalComponent, {
            initialState: {
                title: "Interest List Advanced Filter",
                queryGroups: [...this.holdingSearchForm.get("moreFilters").value],
            },
            ignoreBackdropClick: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });

        modalRef.content.queryGroupsUpdated
            .subscribe((updatedQueryGroups: QueryGroup[]) => {
                this.holdingSearchForm.patchValue({
                    moreFilters: updatedQueryGroups
                });
            });
    }

    openQuery(): void {
        const modalRef = this.modalService.show(QuerySelectModalComponent, {
            initialState: {
                exportObject: "Holding"
            },
            ignoreBackdropClick: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });

        modalRef.content.queryGroupsSelected
            .subscribe(queryGroups => {
                const moreFilters = this.holdingSearchForm.get("moreFilters").value.filter(f => f.Queries[0].Field !== "Contact Groups");
                this.resetFilters({
                    moreFilters: [...moreFilters, ...queryGroups]
                });
            });
    }

    saveFilters(updatedFilters: InterestListFormValues = null, toastMessage: boolean = true): void {
        if (!updatedFilters) {
            updatedFilters = this.holdingSearchForm.getRawValue();
        }
        this.userService.getCurrentUser().pipe(
            switchMap(user => forkJoin([
                of(user),
                this.userService.getFilters(user.Id)
            ])),
            switchMap(([user, filters]) => {
                const stringifiedFilters = JSON.stringify({...filters, NewInterestList: updatedFilters})
                return this.userService.saveFilters(user.Id, stringifiedFilters);
            })
        ).subscribe(() => {
            this.cacheService.removeKey(InterestListComponent.FiltersCacheKey);
            this.holdingSearchForm.markAsPristine();
            if (toastMessage) {
                this.toastrService.success("Succcess", "Default preferences updated");
            }
        });
    }

    saveContactList(): void {
        const rowData = this.getRowData();
        const contactIds = [...new Set(rowData.map(c => c.Id))];
        if (contactIds.length === 0) return;

        this.modalService.show(ContactListSaveModalComponent, {
            initialState: {
                contactIds: contactIds
            },
            ignoreBackdropClick: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });
    }

    openContactList(): void {
        const modalRef = this.modalService.show(ContactListOpenModalComponent, {
            ignoreBackdropClick: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });

        modalRef.content.contactListUpdated.subscribe(id => {
            this.holdingSearchForm.get("savedContactListId").patchValue(id);
        });
    }

    createActivities() {
        this.modalService.show(ExcludeContactsModalComponent, {
            backdrop: "static",
        }).content.excludeContacts
            .subscribe((excludeContacts) => {
                const contacts = this.getRowData();
                let initialState = {
                    contactIds: contacts
                        .filter(c => excludeContacts ? c.Email : true)
                        .map(c => c.Id)
                };
                let activityFormModalRef = this.modalService.show(ActivityFormComponent, {
                    animated: false,
                    keyboard: false,
                    backdrop: 'static',
                    initialState: initialState,
                });

                activityFormModalRef.content.dataUpdated
                    .subscribe(() => {
                        this.activityDataChanged.emit(true);
                    });
            });
    }

    onRowDataChanged() {
        this.gridApi.forEachNode(node => {
            if (node.rowIndex === 0) {
                node.setSelected(true);
            }
        });
    }

    selectContact(contactId: number): void {
        this.selectedContactId = contactId;
        this.contact = null;
        this.assistantIds = [];
        if (this.selectedContactId) {
            this.contactService.getContactById(this.selectedContactId)
                .subscribe(contact => {
                    this.contact = contact;
                    this.assistantIds = this.getAssistantIds(contact);
                });
        }
    }

    onSelectionChanged() {
        let selectedRows = this.gridApi.getSelectedRows();
        if (selectedRows.length === 1) {
            this.selectContact(selectedRows[0].Id);
        } else {
            this.selectContact(null);
        }
    }

    onFavoriteChanged(isFavorite: boolean): void {
        const node = this.gridApi.getSelectedNodes()?.[0];
        node?.setDataValue("Favorite", isFavorite);
    }

    private getAssistantIds(contact: Contact): number[] {
        return this.contact.Assistants?.map(assistant => assistant.Id);
    }

    private getRowData(): Contact[] {
        const rowData = [];
        this.gridApi.forEachNode(node => rowData.push(node.data));
        return rowData;
    }
}
