import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {combineLatest} from "rxjs";
import {ActivityService} from "../../../Shared/Services/activity.service";
import type {ActivityStatusCount, EventMeeting} from "../../../../Components/Events/CalendarEvent";
import * as moment from "moment";
import {TimeZoneService} from "../../../Shared/Services/time-zone.service";
import {orderActivityStatusesByDefaultDisplayOrder} from "../../../../Helpers/ActivityStatusHelper";

@Component({
    selector: "app-event-meeting-header",
    templateUrl: "./event-meeting-header.component.html"
})
export class EventMeetingHeaderComponent implements OnChanges {

    @Input()
    eventMeeting: EventMeeting;

    @Input()
    title: string;

    timeZoneForDisplay: string;
    activityStatusCounts: ActivityStatusCount[] = [];

    constructor(
        private activityService: ActivityService,
        private timeZoneService: TimeZoneService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.eventMeeting) {
            let timeZones$ = this.timeZoneService.getTimeZones();
            let activityStatuses$ = this.activityService.getActivityStatuses();

            combineLatest([timeZones$, activityStatuses$])
                .subscribe(([timeZones, activityStatuses]) => {

                    if (this.eventMeeting) {
                        let timeZone = timeZones.find(tz => tz.Id === this.eventMeeting.TimeZoneId) ?? 
                            timeZones.find(tz => tz.Id === this.eventMeeting.EventTimeZoneId);
                        this.timeZoneForDisplay = timeZone ? ` (${timeZone.Abbreviation})` : '';
                    }

                    this.activityStatusCounts = orderActivityStatusesByDefaultDisplayOrder(activityStatuses).map(s => {
                        return {
                            ActivityStatus: s.Name,
                            Count: this.eventMeeting?.ActivityStatusCounts?.find(c => c.ActivityStatus === s.Name)?.Count ?? 0,
                            MyCount: this.eventMeeting?.ActivityStatusCounts?.find(c => c.ActivityStatus === s.Name)?.MyCount ?? 0
                        } as ActivityStatusCount;
                    });
                });
        }
    }

    formatDate(date: string | moment.Moment): string {
        if (!date) {
            return '';
        }

        return moment(date).format('L');
    }

    formatTimeRange(startTime: string | moment.Moment, endTime: string | moment.Moment): string {
        return `${startTime ? moment(startTime).format('h:mm A',) : ''} - ${endTime ? moment(endTime).format('h:mm A',) : ''}`;
    }
}
