import * as moment from "moment";
import { QueryExportField } from "../Components/Query/QueryExportField";

export interface Query {
    QueryGroups: QueryGroup[],// { Queries: { Object: string; Field: string; Operation: string; Value: string | undefined; Values: string[] | undefined; }[] }[];
    ExportObject: string;
    ExportFields: QueryExportField[];
}
export interface QueryGroup {
    Queries: QueryPredicate[]
}
export type QueryOperation = "contains" | "between" | "is" | "is not" | "not contains" | "greater than or equal" | "less than or equal" | "";
export interface QueryPredicate {
    Object: string,
    Field: string,
    Operation: QueryOperation,
    Value?: string | number | moment.Moment | (moment.Moment | string),
    Values?: string[] | number[] | moment.Moment[] | (moment.Moment | string)[]
}

export class QueryDto {
    Id: number;
    Name: string;
    Description: string;
    ListGroupId: number;
    ListGroupPermission: string;
    Query: Query;
    CreatedAt: string;
    CreatedBy: string;
    UpdatedAt: string;
    UpdatedBy: string;
    IsEditable: boolean;
    IsDeletable: boolean;
    IsEmailable: boolean;
}
