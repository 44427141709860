 <div class="modal-body">
    {{ message }}
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap form-group">
        <button
            type="button"
            class="flex btn btn-danger"
            (click)="confirmDelete()"
        >
            Yes, Delete Only Subscription
        </button>
        <button
            type="button"
            class="flex btn btn-danger"
            style="white-space: normal"
            (click)="confirmDeleteInterestsAndContactSpecialtyMailings()"
        >
            Yes, Delete Subscription and Interests
            and Specialty Mailings
        </button>
    </div>
    <button
        type="button"
        class="btn btn-default"
        style="width: 100%"
        (click)="decline()"
    >
        Cancel
    </button>
</div>