<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="userTeamForm">
        <div class="modal-body">
            <div class="form-group">
                <label class="control-label">Team</label>
                <input type="text" class="form-control" formControlName="description" />
            </div>

            <div class="form-group">
                <label class="control-label">Role</label>
                <select class="form-control" formControlName="parentId">
                    <option *ngFor="let parentTeam of parentTeamIds | async" [ngValue]="parentTeam.ParentId">{{parentTeam.Description}}</option>
                </select>
            </div>

            <div class="checkbox">
                <label>
                    <input type="checkbox" formControlName="isActive"> Active
                </label>
            </div>
            <div *ngIf="!userTeamForm.value.isActive && userTeam.ActiveUserIds?.length > 0" class="text-danger" style="font-weight: bold;">
                There are active users associated with this team.
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" [disabled]="userTeamForm.invalid" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-danger" *ngIf="canDelete" (click)="delete()">Delete</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
