import {Routes} from "@angular/router";
import {AdminDashboardComponent} from "./Dashboard/admin-dashboard.component";
import {UserGroupsComponent} from "./UserGroups/user-groups.component";
import {ListGroupsComponent} from "./ListGroups/list-groups.component";
import {MifidAccountsComponent} from "./MifidAccounts/mifid-accounts.component";
import {UserFeaturesComponent} from "./UserFeatures/user-features.component";
import {AdminUserListComponent} from "./Users/admin-user-list.component";
import {UserTeamsListComponent} from "./UserTeams/user-team-list.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {UserFeatureName} from "./UserFeatures/user-features.service";
import {MsalGuard} from "@azure/msal-angular";
import { QueueMeetMaxEventsListComponent } from "./QueueMeetMaxEvents/queue-meetmax-events-list.component";
import {AdminRoutePaths} from "./admin-route-paths";

export const adminRoutes: Routes = [
    {
        path: AdminRoutePaths.BasePath,
        canActivate: [UserCanActivateRoute, MsalGuard],
        canActivateChild: [MsalGuard],
        data: {
            requiredFeatures: [UserFeatureName.IsAdmin]
        },
        children: [
            {
                path: AdminRoutePaths.UserGroupsPathFragment,
                component: UserGroupsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - User Groups"
                },
            },
            {
                path: AdminRoutePaths.ListGroupsPathFragment,
                component: ListGroupsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - List Groups"
                },
            },
            {
                path: AdminRoutePaths.MifidAccountsPathFragment,
                component: MifidAccountsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - Mifid Accounts"
                },
            },
            {
                path: AdminRoutePaths.UserFeaturesPathFragment,
                component: UserFeaturesComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - User Features"
                },
            },
            {
                path: AdminRoutePaths.UserListPathFragment,
                component: AdminUserListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - Users"
                },
            },
            {
                path: AdminRoutePaths.UserTeamsListPathFragment,
                component: UserTeamsListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - User Teams"
                },
            },
            {
                path: AdminRoutePaths.QueueMeetMaxEventsPathFragment,
                component: QueueMeetMaxEventsListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - Queue MeetMax Events"
                },
            },
            {
                path: "",
                component: AdminDashboardComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Admin - Dashboard"
                },
            }
        ],
    },
];
