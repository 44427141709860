<div class="flex flex-vbox">

    <div style="margin-bottom: 5px;">
        <form [formGroup]="form">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-sm btn-default" [disabled]="!contactId && !accountId" (click)="addActivity()">
                    <span class="fa fa-plus"></span>
                </button>

                <label *ngIf="account && account.ParentComdolId" style="margin-top: 4px; margin-bottom: 0;" appRequiresFeature="tab-activity-list-parent">
                    <input type="checkbox" formControlName="isParentSelect" />
                    Parent
                </label>
                <input formControlName="searchTerm" type="search" placeholder="Search" class="flex form-control input-sm" />
                <app-date-range-picker 
                        formControlName="dateRange"
                        [options]="dateRangeOptions"
                ></app-date-range-picker>
                <app-activity-category-picker 
                        formControlName="categories"
                        style="width: 120px;"
                        [categories]="filteredCategories"
                        [searchable]="false"
                        [alignRight]="true"
                ></app-activity-category-picker>
                <app-team-picker
                        formControlName="teamIds"
                        [alignRight]="true"
                ></app-team-picker>
                <div class="btn btn-primary btn-sm btn-toggle" formControlName="myActivities" btnCheckbox>My</div>
                <button *ngIf="showActivityReassign && canEditContacts" appRequiresFeature="contact-reassign-activities" type="button" class="btn btn-default btn-sm" (click)="openReassignModal()">Reassign</button>
                <button class="btn btn-sm btn-default"
                        appRequiresFeature="tab-activity-list-export"
                        type="button"
                        (click)="exportToExcel()"
                        tooltip="Export Activities">
                    <span class="fa fa-download fa-lg"></span>
                </button>
            </div>
            <div *ngIf="notShowingAllRecords" class="small">
                <em style="font-weight: bold; color: red">(Not showing all records. Add filters.)</em>
            </div>
        </form>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowClassRules]="rowClassRules"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
    >
    </ag-grid-angular>

</div>



