import * as moment from "moment";

export const dateComparator = (
    valueA: string | Date | moment.Moment,
    valueB: string | Date | moment.Moment
): number => {
    return !valueA && !valueB ? 0 :
        !valueA && valueB ? -1 :
        valueA && !valueB ? 1 :
        valueA < valueB ? -1 :
        valueA > valueB ? 1 :
        0;
}

export const nullableValueComparator = (
    valueA: any,
    valueB: any
): number => {
    return !valueA && !valueB ? 0 :
        !valueA && valueB ? -1 :
        valueA && !valueB ? 1 :
        valueA < valueB ? -1 :
        valueA > valueB ? 1 :
        0;
}

export const nullableValueComparatorWithNullsLast = (
    valueA: any,
    valueB: any
): number => {
    return !valueA && !valueB ? 0 :
        !valueA && valueB ? 1 :
        valueA && !valueB ? -1 :
        valueA < valueB ? -1 :
        valueA > valueB ? 1 :
        0;
}

export const timeComparator = (time1: string, time2: string): number => {
    return moment(time1) < moment(time2) ? -1 : 1;
}