import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import { EventRoutePaths } from "../../Event/event-route-paths";

@Component({
    selector: 'app-grid-event-name',
    template: `<a [routerLink]="[eventDetailBasePath, eventId]" [relativeTo]="null">{{eventName}}</a>`
})
export class EventNameCellRenderer implements ICellRendererAngularComp {

    public eventId: number;
    public eventName: string;
    public isActive: boolean;

    eventDetailBasePath = EventRoutePaths.EventDetail;

    constructor() {
    }

    agInit(params: any): void {
        this.eventId = params.EventId;
        this.eventName = params.IsActive ? params.EventName : `(Canceled) ${params.EventName}`;
    }

    refresh(): boolean {
        return true;
    }
}
