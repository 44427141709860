import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {CommissionService} from "../../../Shared/Services/commission.service";
import {CommissionCategory} from "../../../../Services/CommissionCategoryService";
import {map} from "rxjs/operators";
import {ConfigService} from "../../../Shared/Services/config.service";
import {UserService} from "../../../Shared/Services/user.service";

@Component({
    selector: "app-commission-category-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(CommissionCategoryPickerComponent)
})
export class CommissionCategoryPickerComponent extends BasePickerComponent<CommissionCategory> {

    @Input()
    alignRight: boolean = false;
    
    constructor(fb: UntypedFormBuilder, 
                private commissionService: CommissionService, 
                private configService: ConfigService,
                private userService: UserService){
        super(fb);
        
        this.name = "Category(s)";
        this.placeHolder = "Category"
        this.bindValue = "code";
        this.bindLabel = "description";
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        
        this.items$ = this.userService.getCurrentUser().pipe(
            map(user => {
                let categories = this.commissionService.getCommissionCategories();
                if (this.configService.getBuyBackUsers().includes(user.Id)) {
                    return categories.filter(c => c.code !== 'S');
                } else {
                    return categories;
                }
            })
        );
        
        // const categories = combineLatest([this.commissionService.getCommissionCategories(), this.userService.getCurrentUser()]).pipe(
        //     map(([cats, user]) => {
        //         return cats;
        //     })
        // );
        
        // const categories = this.commissionService.getCommissionCategories().pipe(
        //     filter(cats => this.configService.getBuyBackUsers().includes())
        // );
        
        //this.items$ = of(categories);
    }
}
