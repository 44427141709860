import {Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {TransportationProvider, TransportationProviderForListComponent, TransportationProviderService} from "../Services/transportation-provider.service";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {ColDef, GridReadyEvent, ITooltipParams, RowClickedEvent} from "ag-grid-community";
import {ICellRendererParams} from "ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer";
import {TransportationProviderFormComponent} from "./transportation-provider-form.component";
import { TravelCity, TravelCityService } from "../Services/travel-city.service";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";

@Component({
    selector: "app-transportation-provider-list",
    templateUrl: "./transportation-provider-list.component.html"
})
export class TransportationProviderListComponent extends BaseGridComponent<TransportationProviderForListComponent> implements OnChanges, OnInit {

    columnDefs: ColDef[] = [
        { field: "Name", sort: "asc", valueGetter: (params) => params.data.Name?.trim(), tooltipValueGetter: (p) => p.data.Name, flex: 1 },
        { field: "CityNameForCityId", headerName: "City", tooltipValueGetter: (p) => p.data.CityNameForCityId, width: 150 },
        { field: "TypeNameForTypeId", headerName: "Type", width: 100, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.TypeNameForTypeId},
        { field: "Address1", headerName: "Address", flex: 1, cellRenderer:  this.providerAddressRender, tooltipValueGetter: this.providerAddressToolTipValueGetter, autoHeight: true },
        { field: "Phone", width: 100, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.Phone },
        { field: "Fax", width: 100, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.Fax },
        { field: "Email", width: 150, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.Email },
        { field: "ContactName", width: 150, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.ContactName },
        { field: "AccountNumber", width: 150, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.AccountNumber },
        { field: "Notes", width: 150, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.Notes },
        { field: "Deleted", width: 75, tooltipValueGetter: (params: ITooltipParams<TransportationProviderForListComponent>) => params.data.Deleted, cellRenderer: yesNoCellRenderer },
    ];

    providerSearchForm: UntypedFormGroup = this.fb.group({
        cityId: this.fb.control(null),
        searchTerm: this.fb.control("")
    });

    constructor(
        private fb: UntypedFormBuilder,
        private providerService: TransportationProviderService,
        private travelCityService: TravelCityService,
        private modalService: BsModalService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setRowData();
    }

    ngOnChanges(changes: SimpleChanges): void { }

    setRowData(): void {
        const allProviders$ = this.providerService.getProviders();
        const formUpdate$ = this.providerSearchForm.valueChanges.pipe(startWith(this.providerSearchForm.getRawValue()));
        const typeIds$ = this.providerService.getProviderType();
        const travelCities$ = this.travelCityService.getTravelCities();

        this.rowData$ = combineLatest([allProviders$, formUpdate$, travelCities$, typeIds$]).pipe(
            map(([allProviders, formUpdate, travelCities, typeId]) =>
                allProviders
                    .filter(x => this.showProvider(x, formUpdate.searchTerm, formUpdate.cityId))
                    .map(x => {
                        return {
                            ...x,
                            CityNameForCityId: travelCities.find(tc => tc.Id === x.CityId)?.Name,
                            TypeNameForTypeId: typeId.find(tid => tid.Id === x.TypeId)?.Name
                        } as TransportationProviderForListComponent })
            )
        );
    }

    providerAddressRender(params: ICellRendererParams<TransportationProviderForListComponent>) {
        return params.data.Address1 +
            (params.data.Address2 ? ", " +  params.data.Address2 : "") +
            ", " + params.data.City +
            ", " + params.data.State + " " + params.data.Zip +
            (params.data.Country ? ", " + params.data.Country : "");
    }

    providerAddressToolTipValueGetter(params: ITooltipParams<TransportationProviderForListComponent>) {
        return params.data.Address1 + "\n" +
            (params.data.Address2 ? params.data.Address2 + "\n" : "") +
            params.data.City  + ", " + params.data.State + " " + params.data.Zip + "\n" +
            params.data.Country;
    }

    getProviderTypeDisplayName(): string {
            return "Transportation";
    }

    getProviderTypeDisplayIcon(): string {
        return "fa-bus";
    }

    showProvider(provider: TransportationProvider, searchTerm: string, cityId: number): boolean {
        return (cityId === null ? true : cityId === provider.CityId) && objectSearchPredicate(provider, searchTerm);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked(rowClickedEvent: RowClickedEvent<TransportationProviderForListComponent>) {
        this.openModalForm(rowClickedEvent.data.Id);
    }

    addProvider() {
        this.openModalForm(0);
    }

    openModalForm(providerId: number): void {
        const initialState = {
            providerId: providerId,
        };

        let modalRef =
            this.modalService.show(TransportationProviderFormComponent, {
                initialState: initialState,
                animated: false,
                keyboard: false,
                backdrop: "static",
                class: "modal-lg"
            });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }
}
