<ag-grid-angular
        style="width: 100%; height: 100%;"
        class="ag-theme-balham"
        rowClass="non-clickable-row"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [tooltipShowDelay]="0"
        [pagination]="false"
        (rowGroupOpened)="onRowGroupOpenedClosed($event)"
        (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
