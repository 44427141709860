<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">Upcoming Events</h3>
    </div>
    <form [formGroup]="upcomingEventsForm">
        <div class="modal-body">
            <div class="form-group flex-hbox flex-gap">
                <div class="flex">
                    <label>
                        Email Template
                    </label>
                    <select
                        class="form-control input-sm"
                        formControlName="eventEmailTemplateId"
                    >
                        <option
                            *ngFor="let template of eventEmailTemplates"
                            [ngValue]="template.Id"
                        >
                            {{template.Name}}
                        </option>
                    </select>
                </div>
                <div class="flex" style="align-self: end;">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm flex"
                        [disabled]="isLoading() || saving || downloading"
                        (click)="saveTemplate()"
                    >
                        Save Template
                    </button>
                </div>
                <div class="flex"></div>
            </div>

            <tabset>
                <tab heading="Upcoming">
                    <div class="form-group">
                        Select Events to Exclude from Email Template
                    </div>

                    <div style="height: 400px">
                        <ag-grid-angular
                            style="width: 100%; height: 100%;"
                            class="ag-theme-balham"
                            [gridOptions]="gridOptions"
                            [rowData]="rowData$ | async"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            (gridReady)="onGridReady($event)"
                        >
                        </ag-grid-angular>
                    </div>
                </tab>

                <tab heading="Conferences" *ngIf="includeConferencesEmailSection">
                    <app-html-editor formControlName="conferencesEmailSection"></app-html-editor>
                </tab>
            </tabset>
            
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button
                    type="button"
                    class="btn btn-primary btn-sm flex"
                    [disabled]="isLoading() || saving || downloading"
                    (click)="downloadTemplate()"
                >
                    Download Email Template
                </button>

                <button
                    type="button"
                    class="btn btn-default btn-sm flex"
                    (click)="closeModal()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>

