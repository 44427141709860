import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {PrintReportsService} from "./print-reports.service";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {of} from "rxjs";

@Component({
    selector: "app-report-print-reports",
    templateUrl: "./print-reports.component.html"
})
export class PrintReportsComponent implements OnInit {

    reports: string[];

    printReportForm: UntypedFormGroup = this.fb.group({
        reportName: this.fb.control('Client Tear Sheet'),
        contactIds: this.fb.control([]),
        accountIds: this.fb.control([])
    });

    constructor(private fb: UntypedFormBuilder,
                private printReportService: PrintReportsService,
                private modalService: BsModalService) {
    }

    ngOnInit(): void {
        this.reports = ["Client Tear Sheet", "Holding List"];
    }

    generateReport(): void {
        const formValue = this.printReportForm.value;

        let loadingModal = this.modalService.show(LoadingModalComponent, {
            initialState: { message: 'Generating Report' },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        const generateReport$ =
            formValue.reportName === "Client Tear Sheet" ?
                this.printReportService.generateClientTearSheetReport(formValue.contactIds, formValue.accountIds) :
            formValue.reportName === "Holding List" ?
                this.printReportService.generateHoldingListReport(formValue.contactIds, formValue.accountIds) :
                of(true);

        generateReport$.subscribe(() => {
            loadingModal.hide();
        });
    }
}
