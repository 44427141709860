<ng-select #myPicker
           [appendTo]="appendTo"
           [items]="items$ | async"
           [placeholder]="placeHolder"
           [bindLabel]="bindLabel"
           [bindValue]="bindValue"
           [closeOnSelect]="closeOnSelect"
           [class.align-right]="alignRight"
           [multiple]="multiple"
           [groupBy]="groupByFn"
           [selectableGroupAsModel]="false"
           [selectableGroup]="true"
           [searchable]="searchable"
           [maxSelectedItems]="maxItems"
           [ngStyle]="ngStyle"
           (close)="closePicker()"
           (open)="openPicker()"
           (click)="clickPicker()"
           [formControl]="pickerControl">
    <ng-template ng-optgroup-tmp let-item$="item$">
        <input type="checkbox" [checked]="item$.selected" [indeterminate]="isIndeterminate(item$.children)"/>
        <span>{{item$.label}}</span>
    </ng-template>
    <ng-container *ngIf="showTagCountLabel">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div>
                <span>{{items.length}} {{name}}</span>
            </div>
        </ng-template>    
    </ng-container>
    <ng-container *ngIf="showPermanentPlaceHolder">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div>
                <span>{{placeHolder}}</span>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="showSelectClearAll || customSearchable">
        <ng-template ng-header-tmp>
            <button type="button" *ngIf="showSelectClearAll" class="btn btn-link btn-sm" (click)="onSelectAll()">Select All</button>
            <button type="button" *ngIf="showSelectClearAll" class="btn btn-link btn-sm" (click)="onClearAll()">Clear All</button>
            <div *ngIf="customSearchable" style="padding: 3px;">
                <input type="search" class="form-control input-sm" placeholder="Search" [formControl]="searchField" />
            </div>
        </ng-template>    
    </ng-container>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-search="searchTerm">
        <input type="checkbox" [checked]="item$.selected" /> <span [ngOptionHighlight]="search">{{ getItemLabel(item) }}</span>
    </ng-template>
</ng-select>