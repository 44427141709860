import {NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AgGridModule} from "ag-grid-angular-legacy";
import {SharedEmailsComponent} from "./SharedEmails/shared-emails.component";
import {SharedEmailFormComponent} from "./SharedEmails/shared-email-form.component";
import {ReadershipComponent} from "./Readership/readership.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {EmailFormComponent} from "./EmailForm/email-form.component";
import {ComposeEmailComponent} from "./ComposeEmail/compose-email.component";
import {ActivityModule} from "../Activity/activity.module";
import { CKEditorModule } from 'ckeditor4-angular';
import {EmailSignatureFormComponent} from "./ComposeEmail/email-signature-form.component";
import {PipesModule} from "../Shared/Pipes/pipes.module";
import {EditButtonRenderer} from "./SharedEmails/edit-button-renderer.component";
import {RouterModule} from "@angular/router";
import { emailRoutes } from "./email.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
        NgSelectModule,
        TabsModule,
        ActivityModule,
        CKEditorModule,
        PopoverModule,
        PipesModule,
        RouterModule.forChild(emailRoutes),
    ],
    declarations: [
        SharedEmailsComponent,
        SharedEmailFormComponent,
        ReadershipComponent,
        EmailFormComponent,
        ComposeEmailComponent,
        EmailSignatureFormComponent,
        EditButtonRenderer,
    ],
    exports: [
        SharedEmailFormComponent,
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class EmailModule {
    constructor(){
        console.log("registering the EmailModule");
    }
}
