<div class="modal-body">
    <p>
        Do you want to create activities for contacts without an email address?
    </p>
    <p>
        <small>
            <strong>
                Select No if logging a Blast Email.
            </strong>
        </small>
    </p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" style="width:60px" (click)="submit(false)">Yes</button>
    <button type="button" class="btn btn-danger btn-sm" style="width:60px" (click)="submit(true)">No</button>
    <button type="button" class="btn btn-default btn-sm" style="width:60px" (click)="modalRef.hide()">Cancel</button>
</div>