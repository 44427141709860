import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable, of, Subject} from "rxjs";
import {AccountSearchResult, GlobalSearchService} from "../../Shared/Services/global-search.service";
import {catchError, distinctUntilChanged, switchMap, map} from "rxjs/operators";
import {Account} from "../../../Models/Account";
import * as _ from "lodash";
import {AccountService} from "../../Shared/Services/account.service";

@Component({
    selector: "app-multi-account-select",
    templateUrl: "./multi-account-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiAccountSelectComponent),
            multi: true
        }
    ]
})
export class MultiAccountSelectComponent implements  OnInit, ControlValueAccessor {
    
    @Input()
    requireComdolId: boolean = false;
    
    @Input()
    excludeAccountId: number;
    
    @Input()
    maxItems: number = 999;
    
    accounts$: Observable<AutocompleteAccountItem[]>;
    userInput$ = new Subject<string>();

    accountIdsControl: UntypedFormControl = this.fb.control([]);
    
    onChange = (accountIds: number[]) => {};
    onTouched = () => {};
    
    constructor(private fb: UntypedFormBuilder, 
                private globalSearchService: GlobalSearchService,
                private accountService: AccountService,
    ){}
    
    ngOnInit(): void {
        this.accounts$ = this.userInput$.pipe(
            distinctUntilChanged(),
            switchMap(term => this.globalSearchService.searchAccounts(term).pipe(
                catchError(() => of([])),
                map((accounts: Account[]) => {
                    return _.chain(accounts)
                        .filter(a => this.requireComdolId ? !_.isEmpty(a.ComdolId) : true)
                        .filter(a => this.excludeAccountId !== a.Id)
                        .map(_.bind(this.getAutocompleteAccount, this))
                        .take(10)
                        .value();
                })
            ))
        );
        
        this.accountIdsControl.valueChanges
            .subscribe(accountIds => {
                if (this.onChange) {
                    this.onChange(accountIds.map(a => a.id));    
                }
            })
    }

    getAutocompleteAccount(account: AccountSearchResult): AutocompleteAccountItem {
        return {
            id: account.Id,
            text: this.getLabelText(account.Description, account.Location, account.ComdolId)
        };
    }

    getLabelText(name: string, city: string, comdolId: string): string {
        let label = name;
        if (city || comdolId) {
            label += ` (${ [city, comdolId].filter(x => x).join(' - ') })`
        }
        return label;
    }
    
    registerOnChange(fn: (cids: number[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(accountIds: number[]): void {
        if (accountIds && accountIds.length > 0) {
            this.accountService.getAccountsByIds(accountIds)
                .subscribe(accounts => {
                    let autoCompleteItems: AutocompleteAccountItem[] = accounts
                        .map(account => {
                            return {
                                id: account.Id,
                                text: this.getLabelText(account.Name, account.City, account.ComdolId)
                            }
                        });
                    this.accountIdsControl.patchValue(autoCompleteItems);
                })
        } else {
            this.accountIdsControl.patchValue([]);
        }
    }
}

export class AutocompleteAccountItem {
    id: number;
    text: string;
}
