import {CommonModule} from "@angular/common";
import {WidgetModule} from "../Widget/widget.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AgGridModule} from "ag-grid-angular-legacy";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {NgModule} from "@angular/core";
import {CallListComponent} from "./CallList/call-list.component";
import {ContactNameCellRenderer} from "../Widget/CellRenderers/contact-name-renderer.component";
import {InterestFormComponent} from "./InterestForm/interest-form.component";
import {RouterModule} from "@angular/router";
import {interestRoutes} from "./interest.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([ContactNameCellRenderer]),
        ButtonsModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        TabsModule,
        TooltipModule,
        BsDropdownModule,
        RouterModule.forChild(interestRoutes),
    ],
    declarations: [
        CallListComponent,
        InterestFormComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class InterestModule {
    constructor(){
        console.log("registering the InterestModule");
    }
}
