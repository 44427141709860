import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {SharedEmail} from "../../../Services/SharedEmailService";


@Component({
    selector: 'app-grid-edit-button',
    template: `
        <a class="btn btn-xs btn-default" style="line-height: 1;" [href]="emailUrl">
            <span class="fa fa-envelope"></span>
        </a>
        <ng-container *ngIf="isAdminOrOwner">
            <button class="btn btn-xs btn-default" style="line-height: 1;" (click)="openModalForm(params.data)">
                <span class="fa fa-times" style="color: darkred"></span>
            </button>
        </ng-container>
    `
})
export class EditButtonRenderer implements ICellRendererAngularComp {
    public params: any;    
    isAdminOrOwner: boolean;
    emailUrl: string;

    agInit(params: any): void {
        this.params = params;
        this.isAdminOrOwner = params.context.componentParent.canAdmin(params.data);
        this.emailUrl = `${params.context.componentParent.baseApiUrl}/sharedemail/id/${params.data.Id}/draft`;
    }
    
    openModalForm(sharedEmail: SharedEmail) {
        this.params.context.componentParent.openModalForm(sharedEmail);
    }

    refresh(): boolean {
        return true;
    }
}