<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <div style="display: inline-block;">
            <form class="form-inline" [formGroup]="holdingSearchForm">
                <div class="form-group">
                    <app-string-array-input formControlName="tickers" [placeholder]="'Tickers'" style="width: 100px;"></app-string-array-input>
                </div>

                <div class="form-group">
                    <app-analyst-picker formControlName="analysts"></app-analyst-picker>
                </div>

                <div class="form-group">
                    <app-instisel-sector-picker formControlName="instiselSectors" placeHolder="Sector(s)"></app-instisel-sector-picker>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="grid">
                                Grid
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="favorites">
                                <span class="fa fa-star"></span>
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="myAccounts">
                                <span class="fa fa-sitemap"></span>
                            </div>
                        </div>
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 145px;"
                               formControlName="comment"
                               placeholder="Search Comment" />
                    </div>
                </div>
                <div class="form-group">
                    <app-tier-picker formControlName="tiers"></app-tier-picker>
                </div>

                <div class="form-group">
                    <app-holding-category-picker formControlName="categories" placeHolder="Cat(s)" name="Cat(s)"></app-holding-category-picker>
                </div>

                <div class="form-group">
                    <app-broker-picker formControlName="personalBrokers" placeHolderLabel="PersBk"></app-broker-picker>
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-sm btn-default" (click)="moreFilters()">
                        <span class="fa fa-filter" style="font-size: 14px;"></span>
                        ({{ holdingSearchForm.get('moreFilters').value.length }})
                    </button>
                </div>

                <div class="form-group">
                    <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-sm btn-primary" (click)="addToCallList()" [disabled]="loading">
                        <span class="fa fa-plus"></span>
                    </button>
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-sm btn-danger" (click)="clearCallList()" [disabled]="loading">
                        <span class="fa fa-trash"></span>
                    </button>
                </div>

                <div class="form-group">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" aria-controls="dropdown-basic">
                            <span class="fa fa-gear"></span> <span class="caret" style="margin-left: 2px"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="copyEmailToClipboard()" [style.opacity]="rowData.length === 0 ? 0.5 : 1">Copy emails to clipboard</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="exportToExcel()" [style.opacity]="rowData.length === 0 ? 0.5 : 1">Export to Excel</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="exportToPdf()" [style.opacity]="rowData.length === 0 ? 0.5 : 1">Export to PDF</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="saveContactList()" [style.opacity]="rowData.length === 0 ? 0.5 : 1">Create/Update Contact List</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="sendBlastEmail()">Send Blast Email</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </form>
            <span class="pull-left small" *ngIf="holdingSearchForm.invalid">
                Enter tickers, analysts, or sectors
            </span>
            <span class="pull-left small" *ngIf="holdingSearchForm.valid">
                {{ addPreview }} holdings match current criteria.
                <app-query-groups-popover [queryGroups]="queryGroups"></app-query-groups-popover>
            </span>
        </div>
    </ng-template>

    <app-page-banner
            pagetitle="Call List"
            faicon="fa-phone"
            faiconcolor="blue"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                rowClass="non-clickable-row"
                [gridOptions]="gridOptions"
                [rowData]="rowData"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [components]="components"
                (gridReady)="onGridReady($event)"
                [rowClassRules]="rowClassRules"
                (rowDataUpdated)="onRowDataChanged($event)"
        >
        </ag-grid-angular>

    </div>
</div>
