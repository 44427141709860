import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
    ControlValueAccessor,
    UntypedFormBuilder, UntypedFormControl,
    UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidatorFn
} from "@angular/forms";

@Component({
    selector: "app-account-vote-input",
    template: `
        <div [formGroup]="form">
            <div class="flex-hbox">
                <label class="flex">{{ name }} Vote</label>
                <label class="flex">Type</label>
            </div>
            <div class="input-group flex-hbox" [ngClass]="{'has-error': form.errors?.invalid}">
                <input class="form-control flex" style="border-right-width: 0" type="number" formControlName="vote"/>
                <select class="form-control flex" formControlName="type">
                    <option value=""></option>
                    <option value="Rank">Rank</option>
                    <option value="Tier">Tier</option>
                </select>
            </div>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AccountVoteInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AccountVoteInputComponent),
            multi: true
        }
    ]
})
export class AccountVoteInputComponent implements  OnInit, ControlValueAccessor {

    @Input()
    name: string = ''
    
    onChange = (updatedData: { vote: number, type: string }) => {};
    onTouched = () => {};

    validate({ value }: UntypedFormControl) {
        if (this.form.invalid) {
            return {invalid: true};
        }
        return null;
    }

    formValidator: ValidatorFn = (group: UntypedFormGroup) => {
        let vote = group.get('vote').value;
        let type = group.get('type').value;
        
        if(vote === null && !type || vote !== null && type) {
            return null;
        }
        
        return {invalid: true};
    };

    form = this.fb.group({
        vote: this.fb.control(''),
        type: this.fb.control('')
    }, { validator: this.formValidator });

    constructor(private fb: UntypedFormBuilder){}

    ngOnInit(): void {
        this.form.valueChanges.subscribe(formValues => {
           this.onChange(formValues); 
        });
    }

    registerOnChange(fn: (updatedData: { vote: number, type: string }) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: { vote: number, type: string }): void {

        if (obj) {
            this.form.patchValue(obj);
        }

        this.onChange(obj);
    }
}
