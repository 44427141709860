<div class="modal-body">
    <div class="form-group">
        {{ message }}
    </div>
    <div class="form-group">
        <app-multi-account-select [required]="true"
                                  [excludeAccountId]="excludeAccountId"
                                  [maxItems]="1"
                                  [formControl]="accountIdControl">
        </app-multi-account-select>
    </div>
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap">
        <button type="button" class="btn btn-sm btn-primary flex" (click)="confirm()" [disabled]="accountIdControl.invalid">Select</button>
        <button type="button" class="btn btn-sm btn-default flex" (click)="decline()" >Cancel</button>
    </div>
</div>