import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {combineLatest} from "rxjs";
import {EventService, StagingWebcastAttendee } from "../../Shared/Services/event.service";
import { UserService } from "../../Shared/Services/user.service";
import { User } from "../../../Models/User";
import {EventMeeting} from "../../../Components/Events/CalendarEvent";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "app-webcast-attendee-form",
    template: `
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title">
                    Edit Webcast Attendee
                </h3>
            </div>

            <div class="modal-body">
                <form [formGroup]="webcastAttendeeForm">

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-2">
                            <label>First Name</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.FirstName }}">{{ stagingWebcastAttendee?.FirstName }}</div>
                        </div>
                        <div class="flex-1">
                            <label>Last Name</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.LastName }}">{{ stagingWebcastAttendee?.LastName }}</div>
                        </div>
                        <div class="flex-1">
                            <label>PersNum</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.ContactId }}">{{ stagingWebcastAttendee?.ContactId }}</div>
                        </div>
                    </div>

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-1">
                            <label>Email</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.Email }}">{{ stagingWebcastAttendee?.Email }}</div>
                        </div>
                        <div class="flex-1">
                            <label>Company</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.Company }}">{{ stagingWebcastAttendee?.Company }}</div>
                        </div>
                    </div>

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-1">
                            <label>Webcast ID</label>
                            <div class="webcast-attendee-form-field" title="{{ stagingWebcastAttendee?.WebcastId }}">{{ stagingWebcastAttendee?.WebcastId }}</div>
                        </div>
                        <div class="flex-1">
                            <label>Meeting Display Name</label>
                            <div class="webcast-attendee-form-field" title="{{ eventMeeting?.DisplayName }}">{{ eventMeeting?.DisplayName }}</div>
                        </div>
                    </div>

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex-2">
                            <label>Contact</label>
                            <app-multi-contact-select
                                formControlName="contactIds"
                                [showEditButtons]="false"
                                [maxItems]="1"
                            >
                            </app-multi-contact-select>
                        </div>
                    </div>

                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           formControlName="isIgnored"/>
                                    Ignore Contact
                                </label>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox"
                                           formControlName="cancelPrior"/>
                                    Cancel Prior
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Notes</label>
                        <textarea class="form-control input-sm"  formControlName="notes"></textarea>
                    </div>

                </form>
            </div>

            <div class="modal-footer">
                <div class="flex-hbox flex-gap">
                    <button
                        appIfUserFeature="EventAdmin"
                        type="button"
                        class="btn btn-primary btn-sm flex"
                        [disabled]="webcastAttendeeForm.invalid || formSaving || formLoading"
                        (click)="save()"
                    >
                        Save
                    </button>
                    <button type="button" class="btn btn-default btn-sm flex" (click)="close()">Cancel</button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ["./webcast-attendee-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class WebcastAttendeeFormComponent implements OnInit {

    @Input()
    stagingWebcastAttendeeId: number;
    @Input()
    eventMeetingId: number;
    @Output()
    dataUpdated = new EventEmitter<boolean>();

    stagingWebcastAttendee: StagingWebcastAttendee;
    eventMeeting: EventMeeting;

    formLoading: boolean = false;
    formSaving: boolean = false;

    user: User;

    webcastAttendeeForm: UntypedFormGroup = this.fb.group({
        contactIds: this.fb.control(null),
        isIgnored: this.fb.control(false),
        cancelPrior: this.fb.control(false),
        notes: this.fb.control('')
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private toastrService: ToastrService,
        private eventService: EventService,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.formLoading = true;

        let user$ = this.userService.getCurrentUser();

        let stagingWebcastAttendee$ = this.eventService.getStagingWebcastAttendee(this.stagingWebcastAttendeeId);

        let eventMeeting$ = this.eventService.getEventMeetingById(this.eventMeetingId);

        combineLatest([user$, stagingWebcastAttendee$, eventMeeting$])
            .subscribe(([user, stagingWebcastAttendee, eventMeeting]) => {
                this.user = user;
                this.stagingWebcastAttendee = stagingWebcastAttendee;
                this.eventMeeting = eventMeeting;

                this.webcastAttendeeForm.patchValue({
                    contactIds: stagingWebcastAttendee.ContactId ? [stagingWebcastAttendee.ContactId] : [],
                    isIgnored: stagingWebcastAttendee.IsIgnored,
                    cancelPrior: stagingWebcastAttendee.CancelPrior,
                    notes: stagingWebcastAttendee.Notes
                });

                this.formLoading = false;
            });
    }

    save() {
        this.formSaving = true;

        let stagingWebcastAttendee = {
            ...this.stagingWebcastAttendee,
            ContactId: this.webcastAttendeeForm.get("contactIds").value[0],
            IsIgnored: this.webcastAttendeeForm.get("isIgnored").value,
            CancelPrior: this.webcastAttendeeForm.get("cancelPrior").value,
            Notes: this.webcastAttendeeForm.get("notes").value,
        } as StagingWebcastAttendee;

        let save$ = this.eventService.updateStagingWebcastAttendee(stagingWebcastAttendee);

        save$.subscribe((_) => {
            this.formSaving = false;
            this.dataUpdated.emit(true);
            this.closeFormWithMessage("Webcast Attendee Updated");
        });
    }

    closeFormWithMessage(message: string): void {
        this.toastrService.success(message);
        this.modalRef.hide();
    }

    close() {
        this.modalRef.hide();
    }
}
