import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import * as moment from "moment";
import {AccountVoteFormComponent} from "./account-vote-form.component";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {AccountService} from "../../Shared/Services/account.service";
import {AccountVoteService} from "../../Shared/Services/account-vote.service";
import {AccountVoteAttachmentCellRenderer} from "./account-vote-attachment-render.component";
import {ToastrService} from "ngx-toastr";
import {AccountVoteRow} from "./account-vote";

@Component({
    selector: "app-account-votes",
    templateUrl: "./account-votes.component.html"
})
export class AccountVotesComponent extends BaseGridComponent<AccountVoteRow> implements OnInit {

    @Input()
    accountId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    columnDefs = [
        { field: 'Date', width: 90, tooltipValueGetter: (p) => p.data.VoteComment, autoHeight: true, wrapText: true, sortable: false },
        { field: 'US', flex: 1, sortable: false },
        { field: 'Global', flex: 1, sortable: false },
        { field: 'Sales', flex: 1, sortable: false },
        { field: 'Research', flex: 1, sortable: false },
        { field: 'Corp', flex: 1, sortable: false },
        { field: 'Trader', flex: 1, sortable: false },
        { field: 'Attachments', flex: 1, cellRenderer: 'accountVoteAttachmentRenderer', autoHeight: true, sortable: false},
    ];

    frameworkComponents = { accountVoteAttachmentRenderer: AccountVoteAttachmentCellRenderer };

    noteType: string = 'vote';
    accountComment: UntypedFormControl = this.fb.control('');

    constructor(private fb: UntypedFormBuilder,
                private accountService: AccountService,
                private accountVoteService: AccountVoteService,
                private modalService: BsModalService,
                private toastr: ToastrService) { super(); }

    ngOnInit(): void {
        this.accountService.getAccountNote(this.accountId, this.noteType).subscribe(note => {
            this.accountComment.patchValue(note);
        });

        this.setRowData();
    }

    setRowData(): void {
        this.rowData$ = this.accountVoteService.getByAccount(this.accountId).pipe(
            map(account => _.map(account, a => {
                    return {
                        Id: a.Id,
                        Date: `${moment(a.StartDate).format('L')} - ${moment(a.EndDate).format('L')}`,
                        US: `${a.USVote !== null ? a.USVote : ''} ${a.USVoteType || ''}`,
                        Global: `${a.GlobalVote !== null ? a.GlobalVote : ''} ${a.GlobalVoteType || ''}`,
                        Sales: `${a.SalesVote !== null ? a.SalesVote : ''} ${a.SalesVoteType || ''}`,
                        Research: `${a.ResearchVote !== null ? a.ResearchVote : ''} ${a.ResearchVoteType || ''}`,
                        Corp: `${a.CorpAccessVote !== null ? a.CorpAccessVote : ''} ${a.CorpAccessVoteType || ''}`,
                        Trader: `${a.TraderVote !== null ? a.TraderVote : ''} ${a.TraderVoteType || ''}`,
                        VoteComment: a.VoteComment,
                        Attachments: a.Attachments,
                    } as AccountVoteRow;
                })
            )
        );
    }

    onRowClicked($event: any): any {
        if(this.gridApi.getFocusedCell().column.getColId() !== 'Attachments') {
            this.openModalForm($event.data.Id);
        }
    }

    save(): void {
        this.accountService.updateAccountNote(this.accountId, this.noteType, this.accountComment.value).subscribe(() => {
                this.toastr.success("Saved");
            });
    }

    create(): void {
        this.openModalForm(undefined);
    }

    openModalForm(accountVoteId: number): void {
        const initialState = { accountId: this.accountId, accountVoteId: accountVoteId };
        let modalRef = this.modalService.show(AccountVoteFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-md' });

        modalRef.content.dataUpdated.subscribe(() => {
            this.setRowData();
        });
    }

    refreshGrid(): void {
        this.setRowData();
    }
}
