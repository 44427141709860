import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {TabDirective} from "ngx-bootstrap/tabs";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Observable} from "rxjs";
import {
    isNoContactContact,
    isNoContactReadOnlyContact
} from "../../Shared/ag-grid-options";
import {ColDef} from "ag-grid-community";
import * as _ from "lodash";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {SavedContactList} from "../../Admin/ListGroups/saved-contact-list";
import * as moment from "moment";
import {SavedContactListGroup} from "../../Admin/ListGroups/saved-contact-list-group";
import {map} from "rxjs/operators";
import {ContactListsService} from "./contact-lists.service";
import {ListGroupsService} from "../../Admin/ListGroups/list-groups.service";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../Shared/Models/user";
import {Contact} from "../../../Models/Contact";
import {BaseGridComponent} from "../../Shared/base-grid.component";

@Component({
    selector: "app-contact-list-form",
    templateUrl: "./contact-lists-form.component.html"
})
export class ContactListsFormComponent extends BaseGridComponent<Contact> implements OnInit {

    columnDefs: ColDef[] = [
        {field: 'LastName', headerName: 'Last Name', sort: 'asc', flex: 2, checkboxSelection: true },
        {field: 'FirstName', headerName: 'First Name', flex: 2 },
        {field: 'Account.Name', headerName: 'Account Name', flex: 4 },
    ];

    rowClassRules = {
        'do-not-contact': (params) => isNoContactContact(params.data),
        'do-not-contact-readonly': (params) => isNoContactReadOnlyContact(params.data),
    };

    currentUser: User;
    listId: number;

    canDelete: boolean;
    isContactListEditable: boolean;
    isPrivatePermission: boolean;

    title: string;
    permissions: SavedContactListGroup[] = [
        { Id: "G", Name: "Group View"},
        { Id: "E", Name: "Group View - Edit"},
        { Id: "P", Name: "Private"}
    ] as SavedContactListGroup[];

    groups$: Observable<SavedContactListGroup[]>;

    listForm: UntypedFormGroup = this.fb.group({
        Name: this.fb.control('', [Validators.required, Validators.maxLength(50)]),
        Description: this.fb.control('', [Validators.maxLength(50)]),
        Permission: this.fb.control(''),
        Group: this.fb.control(''),
        CreatedBy: this.fb.control('', [Validators.minLength(4), Validators.maxLength(4)]),
        ModifiedBy: this.fb.control('', [Validators.minLength(4), Validators.maxLength(4)]),
    });

    includeDoNotContactContacts = true;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private contactListsService: ContactListsService,
        private listGroupsService: ListGroupsService,
        private userService: UserService,
        private toastr: ToastrService
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.rowSelection = 'multiple';
        this.gridOptions.rowMultiSelectWithClick = true;
        this.gridOptions.pagination = false;

        this.isContactListEditable = true;

        this.userService.getCurrentUser().subscribe(
            user => {
                this.currentUser = user;
                this.includeDoNotContactContacts = this.userService.canViewContactEmailWithDoNotContact(user);
            });

        this.groups$ = this.listGroupsService.getGroups().pipe(
            map(name => _.sortBy(name, n => n.Name))
        );

        if (this.listId) {
            this.canDelete = true;
            this.setRowData();
            this.loadList(this.listId);
        } else {
            this.canDelete = false;
            this.isPrivatePermission = true;
            this.listForm.patchValue({
                Permission: 'P',
            });

            this.listForm.get('CreatedBy').disable();
            this.listForm.get('ModifiedBy').disable();

            this.title = 'New List';
        }

        this.listForm.get('Permission').valueChanges
            .subscribe(value => {
                if (value === 'P') {
                    this.listForm.get('Group').reset();
                    this.listForm.get('Group').clearValidators();
                    this.listForm.get('Group').updateValueAndValidity();
                } else {
                    this.listForm.get('Group').setValidators(Validators.required);
                }
                this.isPrivatePermission = value === 'P';
            });
    }

    setRowData(): void {
        this.contactListsService.getListContacts(this.listId).subscribe(
            data => {
                this.rowData = data;
            }
        )
    }

    loadList(listId: number) {
        const currentList$ = this.contactListsService.getCurrentList();
        const editList$ = this.contactListsService.getListById(listId);

        combineLatest([currentList$, editList$])
            .subscribe(([currentList, list]) => {
                this.isContactListEditable = list.IsEditable;

                this.listForm.patchValue({
                    Name: list.Name,
                    Description: list.Description,
                    Permission: list.Permission,
                    Group: list.PermissionGroup ? list.PermissionGroup.Id : '',
                    CreatedBy: list.CreatedBy,
                    ModifiedBy: list.ModifiedBy + ' ' + moment(list.ModifiedDate).format('LL'),
                });

                this.listForm.get('CreatedBy').disable();
                this.listForm.get('ModifiedBy').disable();

                this.title = `Edit List: ${list.Name}`;
            });
    }

    submit() {
        if (this.listForm.valid) {
            let list: SavedContactList = {
                Id: this.listId,
                Name: this.listForm.get('Name').value,
                Description: this.listForm.get('Description').value,
                Permission: this.listForm.get('Permission').value,
                PermissionGroup: this.listForm.get('Permission').value === 'P' ? null : {
                    Id: this.listForm.get('Group').value,
                },
                Contacts: this.rowData ? this.rowData.map(sc => {
                    return {Id: sc.Id}
                }) : null
            } as SavedContactList;

            let updateList$ = this.listId ?
                this.contactListsService.updateContactList(list) :
                this.contactListsService.createContactList(list);

            updateList$.subscribe(() => {
                this.toastr.success("Saved Contact List");
                this.dataUpdated.emit(true);
                this.modalRef.hide();
            });
        }
    }

    close() {
        this.gridOptions.rowSelection = 'single';
        this.modalRef.hide();
    }

    deleteList() {
        const initialState = {
            message: 'Are you sure you want to delete this Contact List?',
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.contactListsService.deleteList(this.listId)
                        .subscribe(() => {
                            this.toastr.success("Contact List Deleted");
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        });
                }
            });
    }

    removeSelectedContacts() {
        let selectedContactIds = this.gridApi.getSelectedRows().map(c => c.Id);
        this.rowData = this.rowData.filter(sc => !selectedContactIds.includes(sc.Id));
    }

    selectTab(tab: TabDirective) {
        this.canDelete = tab.heading !== 'Contacts'
    }
}
