import {Injectable} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserRenameComponent} from "./user-rename.component";
import {User} from "../../Shared/Models/user";

@Injectable()
export class UserRenameModalService {
    private bsModalRef: BsModalRef;

    constructor(private modalService: BsModalService) {
    }

    open(user: User): void {
        const initialState = {
            title: `Rename User ID for ${user.FirstName} ${user.LastName} (${user.Id})`,
            user: user
        };
        this.bsModalRef = this.modalService.show(UserRenameComponent, { initialState: initialState, ignoreBackdropClick: true, keyboard: false });
    }
}
