import {Component, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import * as daterangepicker from "daterangepicker";
import {combineLatest, Observable} from "rxjs";
import {ActivityWeekSum} from "../../../Models/ActivityWeekSum";
import {UserService} from "../../Shared/Services/user.service";
import {ActivityService} from "../../Shared/Services/activity.service";
import {startWith, switchMap, tap} from "rxjs/operators";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {RoleName} from "../../../Models/Role";
import {DateRange} from "../../../Models/DateRange";
import {ConfigService} from "../../Shared/Services/config.service";
import {CacheService} from "../../Shared/Services/cache.service";

@Component({
    selector: "app-research-landing",
    templateUrl: "./research-landing.component.html",
    styleUrls: ["./research-landing.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ResearchLandingComponent {
    researchDashboard: UntypedFormGroup = this.fb.group({
        team: this.fb.control(null),
        dateRange: this.fb.control(ResearchLandingComponent.getInitialDateRange()),
    });

    datePickerOptions: daterangepicker.Options = {
        maxDate: moment()
    };

    chartData$: Observable<ActivityWeekSum[]>
    canChangeTeam: boolean = false;
    isLoading: boolean = true;
    canViewTickerActivities: boolean;
    canViewEventListTab: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private activityService: ActivityService,
        private configService: ConfigService,
        private cacheService: CacheService
    ) {}

    ngOnInit(): void {
        const cacheKey = "ResearchLandingFilters";
        const cachedFilters = this.cacheService.getValue(cacheKey);

        this.canViewTickerActivities = this.configService.getAppFeatures().includes("research-home-ticker-activities");

        const defaultAnalyst$ = combineLatest([
            this.userService.getCurrentUser(),
            this.userService.getAllTeams(),
        ]).pipe(
            tap(([user, analysts]) => {
                this.canChangeTeam = user.Features?.includes(UserFeatureName.DashboardChangeTeam);

                const researchDashboardEventListTabUsers = this.configService.getResearchDashboardEventListTabUsers();

                this.canViewEventListTab = !researchDashboardEventListTabUsers ||
                    researchDashboardEventListTabUsers.length === 0 ||
                    researchDashboardEventListTabUsers.includes(user.Id);

                if (cachedFilters) {
                    this.researchDashboard.patchValue(cachedFilters);
                }
                else {
                    const defaultAnalyst = analysts.find(a => a.Name === user.Team?.Name)?.Id;
                    if (defaultAnalyst) {
                        this.researchDashboard.get("team").patchValue(defaultAnalyst);
                    } else {
                        analysts.sort((a, b) => a.Name.localeCompare(b.Name));
                        this.researchDashboard.get("team").patchValue(analysts[0]?.Id);
                    }
                }
            })
        );

        this.chartData$ = defaultAnalyst$.pipe(
            switchMap(() => this.researchDashboard.valueChanges.pipe(
                startWith(this.researchDashboard.getRawValue()),
                tap(formValues => this.cacheService.setValue(cacheKey, formValues))
            )),
            tap(() => this.isLoading = true),
            switchMap(({team, dateRange}) => {
                return this.activityService.getTeamActivities(team, dateRange.start, dateRange.end);
            }),
            tap(() => this.isLoading = false)
        );
    }

    private static getInitialDateRange(): DateRange {
        const currentYear = moment().year();
        return {
            start: moment(`11/1/${currentYear - 1}`),
            end: moment(`10/31/${currentYear}`)
        };
    }
}
