import {Component, Input, OnChanges, ViewEncapsulation} from "@angular/core";
import {ContactService} from "../../../Shared/Services/contact.service";
import {Contact} from "../../../../Models/Contact";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../../../Contact/contact-route-paths";

@Component({
    selector: "app-contact-popover",
    templateUrl: "./contact-popover.component.html",
    styleUrls: ['./contact-popover.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactPopoverComponent implements OnChanges {
    @Input()
    placement: "top" | "bottom" | "left" | "right" | "auto" = "auto";

    @Input()
    adaptivePosition: boolean = true;

    @Input()
    title: string;

    @Input()
    contactIds: number[];

    @Input()
    value?: any;

    @Input()
    showEmail: boolean = true;

    @Input()
    valueForMultiple?: any;

    contacts$: Observable<Contact[]>;

    constructor(
        private contactService: ContactService,
        private router: Router,
    ) { }

    ngOnChanges(): void {
        this.contacts$ = this.contactService.getContactsByIds(this.contactIds).pipe(
            map(contacts => contacts.sort((a,b) => a.LastName.localeCompare(b.LastName)))
        );
    }

    goToContact(contactId: number): void {
        this.router.navigate([ContactRoutePaths.ContactDetail, contactId]);
    }

    getLinkText(contacts: Contact[]): any {
        if (this.value) {
            return this.value;
        }

        if (contacts.length <= 1) {
            return contacts[0]?.LastName ?? "";
        }

        return contacts.length > 1 && this.valueForMultiple ?
            this.valueForMultiple :
            contacts.length;
    }

    onScrollForPopOver($event) { }
}
