export const InvestorTypes = [
    'Alpha Capture',
    'Broker/Dealer',
    'Corporate',
    'Family Office',
    'Hedge Fund',
    'Investment Advisor',
    'Pension Fund',
    'Private Equity',
    'Registered Investment Advisor (RIA)',
    'Sovereign Wealth Fund',
    'Venture Capital'
];
