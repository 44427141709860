import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface TravelMarket {
    Id: number;
    Name: string;
}

@Injectable({
    providedIn: 'root',
})
export class TravelMarketService {

    constructor(private baseHttp: BaseHttpService) { }

    getTravelMarkets(): Observable<TravelMarket[]> {
        return this.baseHttp.getCacheOrData(`/travelmarket`, 5 * 60 * 1000);
    }
}
