<div class="page-content no-scroll flex-vbox">

    <app-page-banner
            pagetitle="Commission Totals"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="accountCommissionSearchForm">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn">
                        <div class="btn btn-primary btn-sm btn-toggle"
                             btnCheckbox
                             formControlName="myAccounts">
                            <span class="fa fa-sitemap"></span>
                        </div>    
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 145px;"
                           formControlName="searchTerm"
                           placeholder="Account Name"/>
                </div>
            </div>
            <div class="form-group" *ngIf="accountCommissionSearchForm.controls['dateRange'].value">
                <app-date-range-picker formControlName="dateRange" view="text" [enableRanges]="true" [options]="datePickerOptions"></app-date-range-picker>
            </div>
            <div class="form-group">
                <app-commission-category-picker formControlName="categories" [alignRight]="true"></app-commission-category-picker>
            </div>
        </form>
        <app-selection-display-list name="Categories" [selectionKeyValues]="categoryKeyValues" [selectionControl]="this.accountCommissionSearchForm.controls['categories']"></app-selection-display-list>
    </ng-template>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [pinnedTopRowData]="pinnedTopRowData"
                [frameworkComponents]="frameworkComponents"
                [tooltipShowDelay]="0"
                (gridReady)="onGridReady($event)"
                (rowClicked)="rowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>