import {Injectable} from "@angular/core";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable, of} from "rxjs";
import {CalendarEventPresenter} from "../../../Components/Events/CalendarEvent";

@Injectable({
    providedIn: 'root',
})
export class NewMeetingService {

    events: any[] = [];
    companies: any[] = [];
    meetings: any[] = [];
    attendees: any[] = [];
    
    constructor(private baseHttp: BaseHttpService) {
        
        this.events.push({
            Id: 1,
            Name: 'Event with Meetings'
        });

        this.events.push({
            Id: 2,
            Name: 'New Event'
        });
        
        this.companies.push({
            Id: 1,
            CompanyId: 1,
            EventId: 1,
            Name: 'Harley Davidson',
            Ticker: 'HOG',
            StatusId: 1,
            Status: 'Invited',
            OwnedByRep: 'Y',
            InvitedBy: 'Wolff',
            Notes: '',
            Presenters: [
                { FirstName: 'Smith', LastName: 'Kennison', Title: 'CEO' } as CalendarEventPresenter
            ],
            Meetings: 0,
        });
        this.companies.push({
            Id: 2,
            CompanyId: 2,
            EventId: 1,
            Name: 'Target',
            Ticker: 'TGT',
            StatusId: 4,
            Status: 'Accepted',
            OwnedByRep: 'Y',
            InvitedBy: 'Wolff',
            Notes: 'Some longer notes that will expand off the column. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            Presenters: [
                { FirstName: 'Sue', LastName: 'Nelson', Title: 'CIO' } as CalendarEventPresenter
            ],
            Meetings: 0,
        });
        this.companies.push({
            Id: 3,
            CompanyId: 3,
            EventId: 1,
            Name: 'TechTarget',
            Ticker: 'TTGT',
            StatusId: 4,
            Status: 'Accepted',
            OwnedByRep: 'N',
            InvitedBy: 'Wolff',
            Notes: '',
            Presenters: [
                { FirstName: 'Peter', LastName: 'Blais', Title: 'CEO' } as CalendarEventPresenter,
                { FirstName: 'Janice', LastName: 'Keliher', Title: 'CFO' } as CalendarEventPresenter,
                { FirstName: 'Paul', LastName: 'Foo', Title: 'CIO' } as CalendarEventPresenter,
                { FirstName: 'Laura', LastName: 'Palmer', Title: 'Analyst' } as CalendarEventPresenter,
                { FirstName: 'Laura', LastName: 'Palmer', Title: 'Analyst' } as CalendarEventPresenter,
                { FirstName: 'Laura', LastName: 'Palmer', Title: 'Analyst' } as CalendarEventPresenter,
            ],
            Meetings: 2,
        });
        
        this.meetings.push({
            Id: 1,
            EventId: 1,
            Date: '2/8/23',
            StartTime: '9:00am',
            EndTime: '9:40am',
            Name: 'First Meeting with (HOG)',
            Location: 'Conference Room B',
            Attendees: 2,
            Presenters: 1
        },
        {
            Id: 2,
            EventId: 1,
            Date: '2/8/23',
            StartTime: '10:00am',
            EndTime: '10:20am',
            Name: 'Super Bowl Connection (TSLA)',
            Location: 'Conference Room C',
            Attendees: 4,
            Presenters: 2
        },
        {
            Id: 3,
            EventId: 1,
            Date: '2/8/23',
            StartTime: '11:00am',
            EndTime: '11:40am',
            Name: 'Second Meeting with (HOG)',
            Location: 'Conference Room B',
            Attendees: 2,
            Presenters: 1
        }
        );
        
        
        this.attendees.push({
            ContactId: 2038629,
            EventId: 1,
            Name: 'Adam Wolff',
            Account: 'IT IER Capital Markets',
            Sub: '0',
            Inv: '0',
            Reg: '0',
            Cnx: '0',
            NoSho: '0',
            Att: '1',
        },
        {
            ContactId: 2038657,
            EventId: 1,
            Name: 'Andrew Kenth',
            Account: 'IT IER Capital Markets',
            Sub: '0',
            Inv: '0',
            Reg: '1',
            Cnx: '0',
            NoSho: '0',
            Att: '0',
        },
        {
            ContactId: 2062941,
            EventId: 1,
            Name: 'Nicole Kiedrowski',
            Account: 'IT IER Capital Markets',
            Sub: '0',
            Inv: '0',
            Reg: '0',
            Cnx: '0',
            NoSho: '0',
            Att: '1',
        });
    }

    getEventById(eventId: number): Observable<any> {
        return of(this.events.find(e => e.Id === eventId) ?? null);
    }
    
    getMeetingById(meetingId: number): Observable<any> {
        return of(this.meetings.find(m => m.Id === meetingId) ?? null);
    }
    
    getMeetingsByEventId(eventId: number): Observable<any[]> {
        return of(this.meetings.filter(m => m.EventId === eventId));
    }
    
    saveMeeting(meeting: any): Observable<any> {
        
        let existing = this.meetings.find(m => m.Id === meeting.Id);
        
        if (existing) {
            existing.Name = meeting.Name;
            existing.Date = meeting.Date;
            existing.StartTime = meeting.StartTime;
            existing.EndTime = meeting.EndTime;
            existing.Location = meeting.Location;
        } else {
            meeting.Id = Math.max(...this.meetings.map(m => m.Id)) + 1;
            this.meetings.push(meeting);
        }
        
        return of(meeting);
    }

    getEventAttendeeById(eventId: number, contactId: number): Observable<any> {
        return of(this.attendees.find(a => a.EventId === eventId && a.ContactId === contactId) ?? null);
    }

    getEventAttendeesByEventId(eventId: number): Observable<any[]> {
        return of(this.attendees.filter(a => a.EventId === eventId));
    }


    getCompaniesByEventId(eventId: number): Observable<CompanyEvent[]> {
        return of(this.companies.filter(a => a.EventId === eventId));
    }

    getEventCompany(eventId: number, companyId: number): Observable<any>{
        return of(this.companies.find(c => c.Id === companyId && c.EventId === eventId) ?? null);
    }
}

export class CompanyEvent
{
    Id: number;
    CompanyId: number;
    EventId: number;
    Name: string;
    Ticker: string;
    StatusId: number;
    Status: string;
    OwnedByRep: string;
    InvitedBy: string;
    Notes: string;
    Presenters: CalendarEventPresenter[];
    Meetings: number;
}