<div class="modal-header">
    Edit Contacts ({{ this.form.get("contacts").value.length }})
</div>
<div [formGroup]="form" class="modal-body">

    <div class="form-group">
        <input formControlName="search" type="search" class="form-control input-sm" placeholder="Search" />
    </div>
    
    <div class="form-group" style="height: 300px;">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>    
    </div>
    
    <div class="form-group">
        <button type="button" class="btn btn-sm btn-danger" (click)="removeSelected()" style="width: 100%;">Remove Selected</button>
    </div>
    
    <div class="form-group">
        <button type="button" class="btn btn-sm btn-default pull-right" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="save()">Save</button>
    </div>
</div>