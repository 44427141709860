<div *ngIf="account" class="window-body flex flex-vbox form-underline tab-account-details">
    
    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Street Address</label>
                <div>{{ account.Address?.Route }}</div>
                <div> <span *ngIf="account.Address?.Locality">{{ account.Address.Locality }}</span><span *ngIf="account.Address?.AdministrativeArea2">, </span>{{ account.Address.AdministrativeArea2}} {{ account.Address.PostalCode }}</div>
                <div> {{ account.Country }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Mailing Address</label>
                <div>{{ account.MailingAddress?.Route }}</div>
                <div><span *ngIf="account.MailingAddress?.Locality">{{ account.MailingAddress.Locality}}</span><span *ngIf="account.MailingAddress?.AdministrativeArea2">, </span>{{ account.MailingAddress.AdministrativeArea2}} {{ account.MailingAddress.PostalCode }}</div>
                <div>{{ account.MailingAddress?.Country }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Phone Number</label>
                <div><app-click-to-dial [phone]="account.PhoneNumber"></app-click-to-dial></div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Fax Number</label>
                <div>{{ account.FaxNumber }}</div>
            </div>
        </div>
    </div>
    
    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Region</label>
                <div>{{ account.Market?.Region }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Market</label>
                <div>{{ account.Market?.Market }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Primary Broker</label>
                <div>{{ account.PrimaryBroker?.LastName }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Primary Trader</label>
                <div>{{ account.PrimaryTrader?.LastName }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Secondary Broker</label>
                <div>{{ account.SecondaryBroker?.LastName }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Secondary Trader</label>
                <div>{{ account.SecondaryTrader?.LastName }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Tertiary Broker</label>
                <div>{{ account.TertiaryBroker?.LastName }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Tertiary Trader</label>
                <div>{{ account.TertiaryTrader?.LastName }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>RR</label>
                <div>{{ account.AccountRep }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>InstNum</label>
                <div>{{ account.Id }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Comdol ID</label>
                <div>{{ account.ComdolId }}</div>
            </div>
        </div>

        <div class="flex">
            <div class="form-group">
                <label>Parent Comdol ID</label>
                <div>{{ account.ParentComdolId }}</div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex">
            <div class="form-group">
                <label>Comdol Inactive</label>
                <div>{{ account.ComdolInactive ? 'Inactive' : 'Active' }}</div>
            </div>
        </div>
        <div class="flex">
            <div class="form-group">
                <label>BM Secure Links</label>
                <div>
                    <select class="form-control input-sm" [formControl]="accountBluematrixSecureLinks">
                        <option [ngValue]="true">Y</option>
                        <option [ngValue]="false">N</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-hbox">
        <div class="flex-1">
            <div class="form-group" [formGroup]="noContactForm">
                <label>No Contact</label>
                <select class="form-control input-sm" formControlName="noContact">
                    <option [ngValue]="true">Y</option>
                    <option [ngValue]="false">N</option>
                </select>
                <div style="margin-top: 10px" *ngIf="noContactForm.controls['noContact'].value && !noContactForm.disabled">
                    <app-string-picker formControlName="noContactReason"
                                       [items$]="noContactReasons$"
                                       [multiple]="false"
                                       [showSelectClearAll]="false"
                                       [showTagCountLabel]="false"
                                       [placeHolder]="'Select Reason'">
                    </app-string-picker>
                    <textarea class="form-control input-sm" formControlName="noContactNote" style="margin-top: 10px; resize: vertical"></textarea>
                </div>
            </div>
        </div>

        <div class="flex-1">
            <div *ngIf="attributionCodeEnabled" class="form-group" [formGroup]="attributionForm">
                <label>Attribution Code</label>
                <span class="fa fa-pencil attribution-editable" btnCheckbox formControlName="attributionEditable"></span>
                <div *ngIf="!attributionForm.controls['attributionEditable'].value; else attributionInput">{{ account.AttributionCode }}</div>
                <ng-template #attributionInput>
                    <div>
                        <input type="text" formControlName="attributionCode" />
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>