import {Component, Input, OnChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {RVCSummary} from "../../../Models/RVCSummary";
import * as _ from "lodash";
import {ColDef} from "ag-grid-community";

@Component({
    selector: "app-rvc-bottom",
    templateUrl: "./rvc-bottom.component.html"
})
export class RvcBottomComponent extends BaseGridComponent<any> implements OnChanges {

    @Input()
    rvcData: RVCSummary[];

    @Input()
    year: number;

    columnDefs: ColDef[] = [
        { field: 'CategoryName', flex: 1, rowGroup: true },
        { field: 'SubCategory', flex: 1, enableRowGroup: true, rowGroup: true, hide: true, sort: "asc" },
        { field: 'Duration', headerName: 'Dur', flex: 1, aggFunc: 'sum', sortable: false, valueFormatter: params => params.value?.toLocaleString() },
        {
            field: 'MeetingCount',
            headerName: 'Int',
            headerTooltip: "Interactions",
            flex: 1,
            sortable: false,
            valueGetter: params => {
                return {
                    IncludedCount: params.data?.SeatCount ?? 0,
                    toString: () => params.data?.SeatCount ?? 0
                }
            },
            aggFunc: params => {
                const includedCount = _.sum(params.values.map(v => v.IncludedCount));
                if (params.rowNode.level == 0) {
                    return {
                        IncludedCount: includedCount,
                        toString: () => includedCount ?? 0
                    }
                }
                return {
                    IncludedCount: includedCount,
                    toString: () => _.sum(params.values.map(v => +v.toString()))?.toLocaleString() ?? 0
                }
            }
        },
        { field: 'Year', headerName: 'Year', flex: 1, pivot: true, hide: true },
    ];

    autoGroupColumnDef : ColDef = {
        headerName: '',
        sortable: false,
        cellRendererParams: { suppressCount: true },
        sort: "asc",
        width: 130,
        tooltipValueGetter: params => {
            if (params.value === "Anly Time"){
                return "Phone / In Person And Email Inbound / Exchange";
            }
            if (params.value === "Models") {
                return "Excel Model";
            }
            return params.value;
        },
        comparator: (valueA, valueB) => {
            const sortOrder = ["Anly Time", "Models", "Research", "HC Sector Spec", "Tech Sector Spec", "Other"];
            return sortOrder.indexOf(valueA) - sortOrder.indexOf(valueB);
        }
    };

    constructor() {
        super();
        this.gridOptions.pagination = false;
        this.gridOptions.groupRemoveSingleChildren = true;
    }

    ngOnChanges(): void {
        let yearRange = _.range(+this.year - 4,+this.year + 1);
        this.rowData = this.rvcData ?
            this.rvcData
                .filter(a => ['P','X','D'].includes(a.Category) && a.Year !== yearRange[0])
                .map(a => {
                    if (!a.SubCategory) {
                        a.SubCategory = a.CategoryName;
                    }
                    return a;
                })
            : [];
    }
}
