import {Injectable} from '@angular/core';
import {combineLatest, Observable, throwError} from "rxjs";
import {HttpOptions} from "../../Shared/Services/base-http.service";
import {User} from "../../Shared/Models/user";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ConfigService} from "../../Shared/Services/config.service";
import {CorpTravelUser} from "../Users/corp-travel-user.service";

@Injectable({
    providedIn: 'root'
})
export class UserRenameService {
    
    constructor(private http: HttpClient, private configService: ConfigService) { 
    }

    renameUser(userId: string, newUserId: string): Observable<User> {
        const path = `/user/id/${userId}/to/${newUserId}`;
        const corpTravelPath = `/corptraveluser/${userId}/to/${newUserId}`;
        const config = {withCredentials: true} as HttpOptions;
        let user$ = this.http.put<User>(this.getUrlFromPath(path), {}, config);
        let corpTravelUser$ = this.http.put<CorpTravelUser>(this.getUrlFromPath(corpTravelPath), {}, config);
        return combineLatest([user$, corpTravelUser$]).pipe(
            map(([user, corpTravelUser])=> user),
            catchError(this.handleError)
        );
    }
    
    private getUrlFromPath(path: string): string {
        return `${this.configService.getActiveApiUrl()}${path}`;
    }
    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
            return throwError(`An error occurred: ${error.error.message}`);
        } 
        if(error.status === 400) {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.message}`);
            return throwError(error.error);
        }
        return throwError("Something (an error) happened; please try again ask the support team for help");
    }
}
