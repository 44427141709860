import {Component, Input, OnChanges} from "@angular/core";
import {AccountService} from "../../Shared/Services/account.service";
import {UserService} from "../../Shared/Services/user.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {BillingInfoFormComponent} from "./billing-info-form.component";
import {AccountBillingInfo} from "../../../Models/AccountBillingInfo";
import {ContactService} from "../../Shared/Services/contact.service";

@Component({
    selector: "app-billing-info",
    templateUrl: "./billing-info.component.html"
})
export class BillingInfoComponent implements OnChanges{

    @Input()
    accountId: number;

    billingLines : string[];
    invoicePath : string;
    invoiceContacts: string[];
    isHardDollar: boolean;
    hardDollarNotes: string;

    constructor(private accountService: AccountService,
                private userService: UserService,
                private bsModalService: BsModalService,
                private contactService: ContactService) { }

    ngOnChanges(): void {
        this.accountService.getAccountBillingInfo(this.accountId)
            .subscribe(info => {
                this.setBillingInfo(info);
            })
    }

    setBillingInfo(info: AccountBillingInfo):void {
        this.billingLines = info.BillingLines?.split("\n");
        this.invoicePath = info.InvoicePath;
        this.isHardDollar = info.IsHardDollar;
        this.hardDollarNotes = info.HardDollarNotes;
        this.getInvoiceContactNames(info.InvoiceContacts.map(c => c.ContactId));
    }

    getInvoiceContactNames(contactIds: number[]): void {
        this.contactService.getContactsByIds(contactIds)
            .subscribe(contacts => {
                this.invoiceContacts = contacts.map(c => `${c.LastName}, ${c.FirstName} - ${c.AccountName}`).sort();
            })
    }

    openEditModal() {
        let modalRef = this.bsModalService.show(BillingInfoFormComponent,
            {
                initialState: { accountId: this.accountId },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-lg'
            });

        modalRef.content.billingInfoEmitter.subscribe(info => {
            this.setBillingInfo(info);
        });
    }
}
