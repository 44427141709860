import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface BairdRep {
    Id?: number;
    FirstName: string;
    MiddleInitial: string;
    LastName: string;
    Title: string;
    Phone: string;
    Mobile: string;
    Email: string;
    Deleted: string;
}

export interface BairdRepAnalystForListComponent {
    Type: string;
    Id?: number;
    FirstName: string;
    MiddleInitial: string;
    LastName: string;
    Title: string;
    Phone: string;
    Mobile: string;
    Email: string;
    Deleted: string;
}

@Injectable({
    providedIn: 'root',
})
export class BairdRepService {

    constructor(private baseHttp: BaseHttpService) { }

    getProviders(): Observable<BairdRep[]> {
        return this.baseHttp.getData(`/bairdrep`);
    }

    getProviderById(repId: number): Observable<BairdRep> {
        return this.baseHttp.getData(`/bairdrep/id/${repId}`);
    }

    createOrUpdateRep(rep: BairdRep): Observable<BairdRep> {
        return this.baseHttp.postData(`/bairdrep`, rep);
    }
}
