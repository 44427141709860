import * as moment from "moment";

export class DatePickerOptionsBuilder {
    private ranges: any;
    private locale: any = { format: moment.localeData().longDateFormat("LL") };
    private opens = "left";
    private drops = "auto";
    private applyEventHandler: (ev: any, picker: any) => void;
    private cancelEventHandler: (ev: any, picker: any) => void;
    private minDate: moment.Moment;
    private maxDate: moment.Moment;

    public static EarliestActivityDate: moment.Moment = moment().add(-61, "months").date(1);
    
    public setLocale(locale: any): DatePickerOptionsBuilder {
        this.locale = locale;
        return this;
    }

    public setRanges(ranges: any): DatePickerOptionsBuilder {
        this.ranges = ranges;
        return this;
    }

    public setApplyHandler(applyHandler: (ev: any, picker: any) => void): DatePickerOptionsBuilder {
        this.applyEventHandler = applyHandler;
        return this;
    }

    public setCancelHandler(cancelHandler: (ev: any, picker: any) => void): DatePickerOptionsBuilder {
        this.cancelEventHandler = cancelHandler;
        return this;
    }

    public setToRightAligned(): DatePickerOptionsBuilder {
        this.opens = "right";
        return this;
    }

    public setToLeftAligned(): DatePickerOptionsBuilder {
        this.opens = "left";
        return this;
    }

    public setToCenterAligned(): DatePickerOptionsBuilder {
        this.opens = "center";
        return this;
    }
    
    public setToTopAligned(): DatePickerOptionsBuilder {
        this.drops = "up";
        return this;
    }

    public setMinMaxDates(minDate: moment.Moment, maxDate: moment.Moment): DatePickerOptionsBuilder {
        this.minDate = minDate;
        this.maxDate = maxDate;
        return this;
    }
    
    public setRangesToPredefinedList(currentDate: moment.Moment): DatePickerOptionsBuilder {
        return this.setRanges({
            "Today": [currentDate, currentDate],
            "Last 30 Days": [currentDate.clone().add(-30, "day"), currentDate],
            "Month to Date (MTD)": [currentDate.clone().startOf("month"), currentDate],
            "Last 12 Months": [currentDate.clone().add(-12, "month"), currentDate],
            "Current Year (YTD)": [currentDate.clone().startOf("year"), currentDate],
            "Last Year": [
                currentDate.clone().add(-1, "year").startOf("year"), currentDate.clone().add(-1, "year").endOf("year")
            ],
            "Current Quarter": [currentDate.clone().startOf("quarter"), currentDate],
            "Last Quarter": [
                currentDate.clone().add(-1, "quarter").startOf("quarter"),
                currentDate.clone().add(-1, "quarter").endOf("quarter")
            ]
        });
    }

    public buildOptions(): any {
        return {
            locale: this.locale,
            opens: this.opens,
            drops: this.drops,
            clearLabel: "Reset",
            linkedCalendars: false,
            ranges: this.ranges,
            eventHandlers: {
                "apply.daterangepicker": this.applyEventHandler,
                "cancel.daterangepicker": this.cancelEventHandler
            },
            minDate: this.minDate,
            maxDate: this.maxDate,
        };
    }

}
