<div class="modal-content attendee-form">
    <app-event-meeting-header
        [eventMeeting]="eventMeeting"
        [title]="title"
    >
    </app-event-meeting-header>
    
    <form [formGroup]="attendanceForm">
        <tabset>
            <tab heading="Attendance">
                <div class="modal-header">
                    <div
                            class="form-group flex-hbox attendance-row"
                            *ngIf="!formLoading"
                    >
                        <div class="flex-4">
                            <app-multi-contact-select
                                    formControlName="contactIdsForNewAttendees"
                                    [showEditButtons]="false"
                            >
                            </app-multi-contact-select>
                        </div>

                        <div class="flex-1">
                            <app-activity-status-picker
                                    formControlName="activityStatusForNewAttendee"
                                    [selectClearAll]="false"
                                    [multiple]="false"
                                    [showTagCountLabel]="false"
                                    [closeOnSelect]="true"
                                    [useNameAsId]="true"
                                    [excludeReplay]="true"
                            >
                            </app-activity-status-picker>
                        </div>

                        <div class="flex flex-hbox flex-gap" style="text-align: left">
                            <button
                                    type="button"
                                    [disabled]="attendanceForm.errors?.requiredNewAttendeeFields"
                                    [title]="attendanceForm.errors?.requiredNewAttendeeFields ? 'At least one contact and a status must be selected in order to add contacts to the meeting' : 'Add contacts to the meeting'"
                                    class="btn btn-success btn-sm flex"
                                    style="min-width: 52px; max-width: 52px; max-height: 30px"
                                    (click)="addNewAttendeeRows()"
                            >
                                <span class="fa fa-plus"></span>
                            </button>

                            <button
                                    type="button"
                                    class="btn btn-default btn-sm flex"
                                    style="min-width: 52px; max-width: 52px; max-height: 30px"
                                    (click)="clearNewAttendeeFormControls()"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <div *ngIf="saveMessage" style="color: green;">
                        {{ saveMessage }}
                    </div>
                </div>
                <div
                        class="modal-body"
                        *ngIf="!formLoading"
                >
                    <div class="flex-hbox flex-gap">
                        <button class="btn btn-primary btn-xs" (click)="onAddAllClick('')">Add All</button>
                        <button class="btn btn-primary btn-xs" (click)="onAddAllClick('Registered')">Add All - Registered</button>
                    </div>
                    <div>
                        <div class="flex-hbox attendance-header">
                            <div class="flex-2">
                                Contact
                            </div>

                            <div class="flex-2">
                                Account
                            </div>

                            <div class="flex-1">
                                Status
                            </div>

                            <div class="flex-1">
                                Sub Category
                            </div>

                            <div class="flex-1">
                            </div>
                        </div>
                        <div style="overflow-y: auto; max-height: 400px; min-height: 300px;">
                            <div
                                    class="form-group flex-hbox attendance-row"
                                    *ngFor="let meetingAttendanceGroup of attendanceForm.get('meetingAttendance').controls"
                                    [formGroup]="meetingAttendanceGroup"
                            >
                                <div class="flex-2">
                                    {{meetingAttendanceGroup.get("contactName").value}}
                                    <span style="color: green;" *ngIf="!meetingAttendanceGroup.value.activityId">(Added)</span>
                                    <span style="color: green;" *ngIf="
                                        meetingAttendanceGroup.value.activityId && 
                                        meetingAttendanceGroup.value.initialActivityStatus !== meetingAttendanceGroup.value.activityStatus">(Updated)</span>
                                </div>

                                <div class="flex-2">
                                    {{meetingAttendanceGroup.get("accountName").value}}
                                </div>

                                <div class="flex-1">
                                    <app-activity-status-picker
                                            formControlName="activityStatus"
                                            [selectClearAll]="false"
                                            [multiple]="false"
                                            [showTagCountLabel]="false"
                                            [closeOnSelect]="true"
                                            [useNameAsId]="true"
                                            [appendTo]="'body'"
                                            [excludeReplay]="true"
                                    >
                                    </app-activity-status-picker>
                                </div>

                                <div class="flex-1">
                                    <app-activity-sub-category-picker
                                            formControlName = "subCategory"
                                            [multiple]="false"
                                            [showSelectClearAll]="false"
                                            [appendTo]="'body'"
                                    >
                                    </app-activity-sub-category-picker>
                                </div>

                                <div class="flex">
                                    <button
                                            type="button"
                                            [title]="'Remove contact from the meeting'"
                                            class="btn btn-danger btn-sm flex"
                                            style="min-width: 52px;
                            max-width: 52px"
                                            (click)="clearAttendance(meetingAttendanceGroup)"
                                    >
                                        <span class="fa fa-times"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab heading="Replays">
                <div class="modal-header">
                    <div
                            class="form-group flex-hbox attendance-row"
                            *ngIf="!formLoading"
                    >
                        <div class="flex-4">
                            <app-multi-contact-select
                                    formControlName="contactIdsForNewReplays"
                                    [showEditButtons]="false"
                            >
                            </app-multi-contact-select>
                        </div>

                        <div class="flex-1">
                            <input type="date" class="form-control input-sm" formControlName="replayDateForNewAttendee" />
                        </div>

                        <div class="flex flex-hbox flex-gap" style="text-align: left">
                            <button
                                    type="button"
                                    [disabled]="attendanceForm.errors?.requiredNewReplayFields"
                                    [title]="attendanceForm.errors?.requiredNewReplayFields ? 'At least one contact and a status must be selected in order to add contacts to the meeting' : 'Add contacts to the meeting'"
                                    class="btn btn-success btn-sm flex"
                                    style="min-width: 52px; max-width: 52px; max-height: 30px"
                                    (click)="addNewReplayAttendeeRows()"
                            >
                                <span class="fa fa-plus"></span>
                            </button>

                            <button
                                    type="button"
                                    class="btn btn-default btn-sm flex"
                                    style="min-width: 52px; max-width: 52px; max-height: 30px"
                                    (click)="clearNewAttendeeFormControls()"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                    <div *ngIf="saveMessageReplay" style="color: green;">
                        {{ saveMessageReplay }}
                    </div>
                </div>
                <div
                        class="modal-body"
                        *ngIf="!formLoading"
                >
                    <div class="flex-hbox attendance-header">
                        <div class="flex-2">
                            Contact
                        </div>

                        <div class="flex-2">
                            Account
                        </div>

                        <div class="flex-1">
                            Replay Date
                        </div>

                        <div class="flex-1">
                        </div>
                    </div>
                    <div style="overflow-y: auto; max-height: 400px;">
                        <div
                                class="form-group flex-hbox attendance-row"
                                *ngFor="let replayAttendanceGroup of attendanceForm.get('replayAttendance').controls"
                                [formGroup]="replayAttendanceGroup"
                        >
                            <div class="flex-2">
                                {{replayAttendanceGroup.get("contactName").value}}
                                <span style="color: green;" *ngIf="!replayAttendanceGroup.value.activityId">(Added)</span>
                                <span style="color: green;" *ngIf="
                                        replayAttendanceGroup.value.activityId && 
                                        replayAttendanceGroup.value.initialReplayDate !== replayAttendanceGroup.value.replayDate">(Updated)</span>
                            </div>

                            <div class="flex-2">
                                {{replayAttendanceGroup.get("accountName").value}}
                            </div>

                            <div class="flex-1">
                                <input type="date" class="form-control input-sm" formControlName="replayDate" />
                            </div>

                            <div class="flex">
                                <button
                                        type="button"
                                        [title]="'Remove contact from the meeting'"
                                        class="btn btn-danger btn-sm flex"
                                        style="min-width: 52px;
                            max-width: 52px"
                                        (click)="clearAttendance(replayAttendanceGroup)"
                                >
                                    <span class="fa fa-times"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
        </tabset>

        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button
                        type="button"
                        class="btn btn-primary btn-sm flex"
                        [disabled]="formSaving || formLoading"
                        (click)="save()"
                >
                    Save
                </button>

                <button
                        type="button"
                        class="btn btn-default btn-sm flex"
                        (click)="close()"
                >
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>
