<div class="flex flex-vbox">

    <div style="margin-bottom: 5px;">
        <form [formGroup]="contactSearchForm">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-sm btn-default" appIfUserFeature="EditContact" appRequiresFeature="contact-add-new" (click)="addContact()"><span class="fa fa-plus"></span></button>
                <div class="btn btn-primary btn-sm btn-toggle" [formControl]="showResearchControl" btnCheckbox>
                    <span class="fa fa-newspaper-o"></span>
                </div>
                <div class="checkbox" style="margin: 5px;" appRequiresFeature="AccountContactsParentToggle">
                    <label style="font-weight: bold">
                        <input type="checkbox" formControlName="isParentSelect" /> Parent
                    </label>
                </div>
                <input formControlName="searchTerm" type="search" placeholder="Search" class="flex form-control input-sm" />
                <app-contact-teams-picker formControlName="contactTeamName" [alignRight]="true" [accountId]=accountId></app-contact-teams-picker>
                <app-role-picker formControlName="roleName" placeHolder="Role(s)" [alignRight]="true"></app-role-picker>
                <button class="pull-right btn btn-sm btn-default"
                        type="button"
                        [appRequiresFeature]="'download-holdings'"
                        [disabled]="!hasContacts"
                        (click)="exportToExcel()"
                        tooltip="Export Contacts"><span class="fa fa-download fa-lg"></span></button>
                
            </div>
        </form>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [frameworkComponents]="frameworkComponents"
            [defaultColDef]="defaultColDef"
            [rowClassRules]="rowClassRules"
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
            rowSelection="single">
    </ag-grid-angular>
    
</div>