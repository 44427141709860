import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";

@Component({
    selector: 'app-company-ticker-list',
    styles: [`
        .note-container {
            border: 1px solid #ebebeb;
            padding: 2px 14px;
        }

        .note-details {
            font-size: 12px;
        }
    `],
    template: `
        <ng-container>
             <span *ngIf="hasNotes"
                   [popover]="notesPopover"
                   [placement]="placement"
                   adaptivePosition="true"
                   [outsideClick]="true"
                   container="body"
                   [popoverTitle]="popoverTitle">
                <a class="pointer">{{ ticker }}</a>
            </span>
            <span *ngIf="!hasNotes">
                {{ ticker }}
            </span>

            <ng-template #notesPopover>
                <div style="max-height: 70vh; overflow: auto;">
                    <ng-container>
                        <div *ngIf="notes">
                            <div class="note-container">
                                <div class="note-details">
                                    {{ notes }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>`
})

export class CompanyTickerListRenderer implements ICellRendererAngularComp {
    params: any;
    notes: string;
    ticker: string;
    popoverTitle: string;
    hasNotes: boolean;
    placement: 'top' | 'bottom' | 'left' | 'right' | 'auto';

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.notes = params.data.Notes;
        this.ticker = params.HeaderName;
        this.popoverTitle = `Notes`;
        this.hasNotes = params.HasNotes;
        this.placement = params.Placement;
    }

    refresh(): boolean {
        return true;
    }
}