import {Component, OnInit} from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";
import {Observable} from "rxjs";
import { map } from 'rxjs/operators';
import {RoleName} from "../../../Models/Role";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html"
})
export class HomeComponent implements OnInit {
    
    userRole$: Observable<string>;

    constructor(private userService: UserService) {
    }
    
    ngOnInit(): void {
        this.userRole$ = this.userService.getCurrentUser().pipe(
            map(user => user?.Role?.Name)
        );
    }
}
