import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityService, MeetingMeetingType} from "../../../Shared/Services/activity.service";

@Component({
    selector: "app-meeting-meeting-type-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(MeetingMeetingTypePickerComponent)
})
export class MeetingMeetingTypePickerComponent extends BasePickerComponent<MeetingMeetingType> {

    @Input()
    searchable: boolean = true;
    
    @Input()
    placeHolder: string = "";
    
    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);
        
        this.name = "Meeting Type(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.activityService.getMeetingMeetingTypes();
    }
}
