<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{ subscriptionId ? 'Edit' : 'Add' }} Subscription</h3>
        <div><app-contact-name [contactId]="contactId"></app-contact-name></div>
    </div>
    <div class="modal-body" *ngIf="!formLoading">
        <form [formGroup]="subscriptionForm">
            <div class="form-group flex-hbox flex-gap">
                <div class="flex">
                    <label class="control-label">Type</label>
                    <div class="btn-group" style="width: 100%;" btnRadioGroup formControlName="type">
                        <label btnRadio="analyst" class="btn btn-sm btn-toggle">Analyst</label>
                        <label btnRadio="sector" class="btn btn-sm btn-toggle">Sector</label>
                    </div>
                </div>
                <div class="flex-2" *ngIf="subscriptionForm.controls['type'].value === 'analyst'">
                    <label class="control-label">Analyst</label>
                    <app-analyst-picker formControlName="analysts" [showTagCountLabel]="false"></app-analyst-picker>
                    <div *ngIf="subscriptionForm.errors?.missingAnalyst" style="color: red;">Analyst Required</div>
                    <div *ngIf="!subscriptionId && teamUsers.length > 0">
                        <span class="help-block">Also create subscription for team member(s):</span>
                        <div *ngFor="let teamUser of teamUsers" class="checkbox">
                            <label>
                                <input type="checkbox" checked (change)="changeStatus($event, teamUser.Id)"/>
                                {{teamUser.LastName}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="flex-2" *ngIf="subscriptionForm.controls['type'].value === 'sector'">
                    <label class="control-label">Sector</label>
                    <app-instisel-sector-picker formControlName="sectors" placeHolder="Sector(s)"></app-instisel-sector-picker>
                    <div *ngIf="subscriptionForm.errors?.missingSector" style="color: red;">Sector Required</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Market Cap Range (in Billions)</label>
                <app-number-range formControlName="marketCapRange"></app-number-range>
            </div>
            <div class="form-group flex-hbox flex-gap">
                <div class="flex">
                    <label class="control-label">Category</label>
                    <div class="btn-group-vertical" style="width: 100%;" btnRadioGroup formControlName="category">
                        <label btnRadio="H" class="btn btn-sm btn-toggle">Holdings</label>
                        <label btnRadio="I" class="btn btn-sm btn-toggle">Interested</label>
                        <label btnRadio="N" class="btn btn-sm btn-toggle">NOT Interested</label>
                    </div>
                </div>
                <div class="flex" style=" padding-left: 10px">
                    <label>Distribution Channels</label>
                    <div style="margin-bottom: 15px;">
                        <div class="btn-group pull-right" btnRadioGroup formControlName="blueMatrix">
                            <label btnRadio="Y" class="btn btn-sm btn-toggle">Yes</label>
                            <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                        </div>
                        BlueMatrix
                    </div>

                    <div style="margin-bottom: 15px;">
                        <div class="btn-group pull-right" btnRadioGroup formControlName="marketingEmails">
                            <label btnRadio="Y" class="btn btn-sm btn-toggle" >Yes</label>
                            <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                        </div>
                        Marketing Emails
                    </div>

                    <div style="margin-bottom: 15px;">
                        <div class="btn-group pull-right" btnRadioGroup formControlName="researchEmails">
                            <label btnRadio="Y" class="btn btn-sm btn-toggle" >Yes</label>
                            <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                        </div>
                        Research Emails
                    </div>

                </div>
            </div>
            <div class="form-group">
                <label class="control-label">Comment</label>
                <input type="text" class="form-control input-sm" formControlName="comment" />
            </div>
        </form>
    </div>
    <div class="modal-footer" *ngIf="!formLoading">
        <div class="flex-hbox">
            <button type="button" class="flex btn btn-sm btn-primary" (click)="submit()" [disabled]="subscriptionForm.invalid">Save</button>
            <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()" *ngIf="subscriptionId">Delete</button>
            <button type="button" class="flex btn btn-sm btn-default" (click)="close()">Cancel</button>
        </div>
    </div>
</div>

