import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {of} from "rxjs";
import {catchError, debounceTime, startWith, switchMap, tap} from "rxjs/operators";
import {
    accountTagComparator,
    durationHeaderTemplate,
    getGridDisplayWords,
    activityParticipantsValueGetter,
    participantsToolTip
} from "../../Shared/ag-grid-options";
import {ActivityService} from "../../Shared/Services/activity.service";
import {Activity, ActivityParticipant} from "../../../Models/Activity";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import * as moment from "moment";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import { DatePickerOptionsBuilder } from '../../../DatePickerOptionsBuilder';
import { UserService } from '../../Shared/Services/user.service';
import { User } from '../../../Models/User';
import * as _ from "lodash";
import {accountTagsVoteRenderer, participantsRenderer, dateRenderer, favoriteCellRenderer, activityParticipantsRenderer} from "../../Shared/ag-grid-cell-renderers";
import {FullNameFilter} from "../../../Filters/FullNameFilter";
import { RoleName } from '../../../Models/Role';
import {CacheService} from "../../Shared/Services/cache.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { UserTeam } from "../../../Models/UserTeam";
import {ColDef} from "ag-grid-community";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: "app-activity-list",
    templateUrl: "./activity-list.component.html",
    styleUrls: ["./activity-list.scss", './activity-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ActivityListComponent extends BaseGridComponent<Activity> implements OnInit {

    static readonly FiltersCacheKey: string = "ActivityListFilters";
    static readonly NavigatedFiltersCacheKey: string = "ActivityListNavigatedFilters";

    modalRef: BsModalRef;

    @ViewChild('inactiveContactTemplate', { static: true})
    public inactiveContactTemplate: TemplateRef<any>;

    columnDefs: ColDef[] = [

        {field: 'Tags', headerName: 'Tags', headerClass: 'hide-header', width: 40, headerTooltip: 'Votes', cellRenderer: accountTagsVoteRenderer, comparator: accountTagComparator },
        {field: 'Favorite', headerName: 'Favorite', headerClass: 'hide-header', width: 40, headerTooltip: 'Favorites', cellRenderer: favoriteCellRenderer },
        {field: 'ContactLastName', headerName: 'Name', flex: 3, valueGetter: this.contactNameValueGetter },
        {field: 'AccountName', headerName: 'Account', flex: 4 },
        {field: 'ContactTier', headerName: 'Tier', flex: 1 },
        {field: 'Category', headerName: 'Cat', flex: 1 },
        {
            field: 'Participants',
            headerName: 'Baird',
            width: 75,
            valueGetter: (params) => activityParticipantsValueGetter(params.data),
            cellRenderer: (params) => activityParticipantsRenderer(params.data, this.userTeams, this.users),
            tooltipValueGetter: (params) => participantsToolTip(params.data, this.users)
        },
        {field: 'Duration', headerName: '', headerTooltip: 'Duration', flex: 1, headerComponentParams: {template: durationHeaderTemplate}},
        {field: 'CallDate', headerName: 'Date', width: 90, sort: 'desc', cellRenderer: dateRenderer},
        {field: 'Comment', flex: 5, tooltipValueGetter: (params) => params.data.Comment },
        {field: 'Tickers', flex: 2 },
    ];

    rowClassRules = {
        'moved-contact': (params) => !params.data.Tags || !_.includes(params.data.Tags, 'Active'),
        'deleted-contact': (params) => params.data.Tags && _.includes(params.data.Tags, 'DeletedContact'),
    };

    datePickerOptions = new DatePickerOptionsBuilder()
        .setRangesToPredefinedList(moment())
        .setToLeftAligned()
        .buildOptions();

    activitySearchForm: UntypedFormGroup = this.fb.group({
        showMyAccounts: this.fb.control(false),
        showFavorites: this.fb.control(false),
        showAccountsWithGrid: this.fb.control(false),
        accountSearchTerm: this.fb.control(''),
        commentSearchTerm: this.fb.control(''),
        searchTickers: this.fb.control([]),
        categories: this.fb.control([]),
        callers: this.fb.control([]),
        tiers: this.fb.control([]),
        callerTeams: this.fb.control([]),
        myActivities: this.fb.control(false),
        dateRange: this.fb.control({ start: moment().startOf('year'), end: moment().startOf('day') }),
        moreFilters: this.fb.control([])
    });

    user: User;
    users: User[];
    userTeams: UserTeam[];
    exporting: boolean = false;
    notShowingAllRecords: boolean = false;
    additionalFiltersNotShown: QueryGroup[];


    constructor(private fb: UntypedFormBuilder,
                private activityService: ActivityService,
                private userService: UserService,
                private cacheService: CacheService,
                private modalService: BsModalService,
                private route: ActivatedRoute,
                private router: Router,
    ) { super(); }

    ngOnInit(): void {
        this.userService.getUsers().subscribe(users => this.users = users);
        this.userService.getUserTeams().subscribe(userTeams => this.userTeams = userTeams);

        const cachedFilters = this.cacheService.getValue(ActivityListComponent.FiltersCacheKey);

        const cachedFiltersFromNavigation = this.cacheService.getValue(ActivityListComponent.NavigatedFiltersCacheKey) as any;
        if (cachedFiltersFromNavigation) {

            const startDate = cachedFiltersFromNavigation.startDate;
            const endDate = cachedFiltersFromNavigation.endDate;
            if (startDate && endDate) {
                this.activitySearchForm.get("dateRange").patchValue({ start: startDate, end: endDate });
            }

            const categories = cachedFiltersFromNavigation.categories;
            if (categories) {
                this.activitySearchForm.get("categories").patchValue(categories);
            }

            const tiers = cachedFiltersFromNavigation.tiers;
            if (tiers) {
                this.activitySearchForm.get('tiers').patchValue(tiers);
            }

            const callerTeams = cachedFiltersFromNavigation.callerTeams;
            if (callerTeams) {
                this.activitySearchForm.get("callerTeams").patchValue(callerTeams);
            }

            const comment = cachedFiltersFromNavigation.comment;
            if (comment) {
                this.activitySearchForm.get("commentSearchTerm").patchValue(comment);
            }

            const moreFilters = cachedFiltersFromNavigation.filters;

            if (moreFilters) {
                this.activitySearchForm.get("moreFilters").patchValue(moreFilters);
            }

            this.cacheService.removeKey(ActivityListComponent.NavigatedFiltersCacheKey);
        } else if (cachedFilters) {
            this.activitySearchForm.patchValue(cachedFilters);
        }

        this.gridOptions.overlayNoRowsTemplate = `<span>No Activities Found</span>`;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;
            if (this.hasResearchRole(user) && this.activitySearchForm.get("callerTeams").value.length === 0) {
                this.activitySearchForm.patchValue({callerTeams: [user.Team.Id]});
            }

            this.rowData$ = this.activitySearchForm.valueChanges.pipe(
                startWith(this.activitySearchForm.getRawValue()),
                debounceTime(100),
                tap(() => this.gridApi.showLoadingOverlay()),
                tap(formValues => this.cacheService.setValue(ActivityListComponent.FiltersCacheKey, formValues)),
                tap(formValues => this.additionalFiltersNotShown = formValues.moreFilters),
                switchMap(form =>
                    this.hasEnoughFilters(form)
                        ? this.activityService.searchActivities(this.convertToQuery(form, user))
                        : of([] as Activity[])
                ),
                tap(x => this.gridApi.hideOverlay()),
                tap(x => this.notShowingAllRecords = x.length >= 16000)
            );
        });
    }

    participantsCellRenderer(activity: Activity) {
        let participantsToDisplay = [];

        if (activity.Category === "B") {
            let singleParticipant: ActivityParticipant = {
                UserId: activity.ContactedBy,
                TeamId: this.users.find(u => u.Id === activity.ContactedBy)?.Team?.Id
            };

            participantsToDisplay = [ singleParticipant ];
        } else {
            participantsToDisplay = activity.Participants;
        }

        return participantsRenderer(participantsToDisplay, this.userTeams);
    }

    participantsToolTip(activity: Activity) {
        let participants = [];

        if (activity.Category === "B") {
            let singleParticipant: ActivityParticipant = {
                UserId: activity.ContactedBy,
                TeamId: null
            };

            participants = [ singleParticipant ];
        } else {
            participants = activity.Participants;
        }

        return _.filter(this.users, u => _.includes(participants.map(p => p.UserId), u.Id)).map(bc => bc.LastName).sort().join(",");
    }

    contactNameValueGetter(p: any): string {
        return FullNameFilter.filter(p.data.ContactFirstName, p.data.ContactLastName, p.data.ContactAlias);
    }

    hasEnoughFilters(form: any): boolean {
        return form.showMyAccounts ||
            form.showFavorites ||
            form.showAccountsWithGrid ||
            form.accountSearchTerm ||
            form.commentSearchTerm ||
            (form.tiers && form.tiers.length > 0) ||
            (form.searchTickers && form.searchTickers.length > 0) ||
            (form.categories && form.categories.length > 0) ||
            (form.callers && form.callers.length > 0) ||
            (form.callerTeams && form.callerTeams.length > 0) ||
            form.myActivities ||
            !form.dateRange.start.isSame(moment().startOf('year')) ||
            !form.dateRange.end.isSame(moment().startOf('day'));
    }

    convertToQuery(form: any, user: User): QueryGroup[] {
        let groups = [];

        if (form.showAccountsWithGrid) {
            QueryTranslator.AddQueryGroupValue(groups, "Account", "Grid", "is", form.showAccountsWithGrid);
        }

        if (form.showFavorites) {
            QueryTranslator.AddQueryGroupValue(groups, "Contact", "Favorite", "is", form.showFavorites);
        }

        if (form.showMyAccounts) {
            QueryTranslator.AddQueryGroupValue(groups, "Account", "My Account", "is", form.showMyAccounts);
        }

        if (form.commentSearchTerm) {
            QueryTranslator.AddQueryGroupValue(groups, "Activity", "Comment", "contains", form.commentSearchTerm);
        }

        if (form.accountSearchTerm) {
            QueryTranslator.AddQueryGroupValue(groups, "Account", "Name", "contains", form.accountSearchTerm);
        }

        if (form.searchTickers && _.filter(form.searchTickers, t => t != '').length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Tickers", "contains", form.searchTickers);
        }

        QueryTranslator.AddQueryGroupValues(groups, "Activity", "Call Date", "between", [form.dateRange.start, form.dateRange.end]);

        if (form.categories && form.categories.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Category", "contains", form.categories);
        }

        if (form.tiers && form.tiers.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Contact", "Contact Tier", "contains", form.tiers);
        }

        if (form.callers && form.callers.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Baird Caller", "contains", form.callers);
        }

        if (form.callerTeams && form.callerTeams.length > 0) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Baird Caller Team", "contains", form.callerTeams);
        }

        if (form.moreFilters && form.moreFilters.length > 0) {
            groups.push(...form.moreFilters);
        }

        if (form.myActivities) {
            QueryTranslator.AddQueryGroupValues(groups, "Activity", "Baird Caller", "contains", [user.Id]);
        }

        return groups;
    }

    rowClicked($event: any): void {
        if ($event.data.ContactFirstName && $event.data.ContactLastName) {
            this.router.navigate([`contact`, $event.data.ContactId]);
        } else {
            this.modalRef = this.modalService.show(this.inactiveContactTemplate);
        }
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    clearFilters() {
        this.activitySearchForm.patchValue({
            showMyAccounts: false,
            showFavorites: false,
            showAccountsWithGrid: false,
            accountSearchTerm: '',
            commentSearchTerm: '',
            searchTickers: [],
            categories: [],
            callers: [],
            tiers: [],
            callerTeams: [],
            myActivities: false,
            dateRange: { start: moment().startOf('year'), end: moment().startOf('day') }
        });
    }

    canExport(): boolean {
        if (!this.user) return false;

        if (!this.hasEnoughFilters(this.activitySearchForm.getRawValue())) return false;

        return this.hasResearchRole(this.user) || _.includes(this.user.Features, "CanExportQuery");
    }

    hasResearchRole(user: User): boolean {
        return user.Role.Name === RoleName.Research;
    }

    exportExcel(): void {
        if (!this.exporting && this.canExport()) {
            this.exporting = true;
            let query = this.convertToQuery(this.activitySearchForm.getRawValue(), this.user);
            this.activityService.exportActivities(query).pipe(
                catchError(x => of(''))
            ).subscribe(x => {
                this.exporting = false;
            });
        }
    }
}
