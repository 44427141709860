<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">Network Connection Settings</h3>
    </div>
    <div class="modal-body">
        <div style="margin-bottom: 5px;">
            Use the internal connection if you are on Baird's network or on VPN via a Baird device.
        </div>
        <div style="margin-bottom: 15px;">
            Use the external connection if you are not on VPN.
        </div>
        <div>
            <label>Connection</label>
            <div style="margin-bottom: 15px;">
                <div class="btn-group" btnRadioGroup [formControl]="isExternalApi">
                    <label btnRadio="false" class="btn btn-sm btn-toggle">Internal</label>
                    <label btnRadio="true" class="btn btn-sm btn-toggle">External</label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox flex-gap">
            <button
                type="button"
                class="flex btn btn-primary"
                [disabled]="saving"
                (click)="submit()"
            >
                Save
            </button>
            <button
                type="button"
                class="flex btn btn-default"
                [disabled]="saving"
                (click)="close()"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
