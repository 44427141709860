<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="contactSearchForm">
            <div class="input-group">
                <input type="search"
                       class="form-control input-sm"
                       style="width: 250px;"
                       formControlName="searchTerm"
                       placeholder="Search" />    
            </div>
        </form>
    </ng-template>
    
    <app-page-banner 
            pagetitle="Contacts" 
            faicon="fa-users" 
            faiconcolor="#1e90ff"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [frameworkComponents]="frameworkComponents"
                [defaultColDef]="defaultColDef"
                [rowClassRules]="rowClassRules"
                (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)"
                rowSelection="single"
        >
        </ag-grid-angular>
        
    </div>
</div>
