<div class="chart-wrapper flex-vbox flex">
    <div class="chart-header">
        {{ title }}
        <span class="fa fa-info-circle" [tooltip]="info"></span>
    </div>
    <div *ngIf="!hasData" class="flex flex-vbox no-data-chart">No Chart Data</div>
    <div class="chart flex">
        <canvas class="chart-canvas" #myChart></canvas>
    </div>
</div>
