import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-reject-modal",
    templateUrl: "./reject-modal.component.html"
})
export class RejectModalComponent implements OnInit {

    message: string;

    @Output()
    action = new EventEmitter<boolean>();

    constructor(public bsConfirmModalRef: BsModalRef) { }

    ngOnInit(): void { }

    decline(): void {
        this.bsConfirmModalRef.hide();
    }
}
