<div class="modal-header">
    Manage Notifications & Documents
</div>
<div class="modal-body manage-notifications-documents" [formGroup]="manageGroup">
  <tabset>
      <tab heading="Notifications" customClass="manage-notifications">
          <button type="button" class="btn btn-sm btn-primary add-notification" (click)="addNotification()" tooltip="Add Notification">
              <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
          <div class="notifications" formArrayName="notifications">
              <ng-container *ngFor="let notification of notifications['controls']; let i = index">
                  <div *ngIf="!notification['controls']['Deleted'].value" class="notification" [formGroupName]="i">
                      <input class="pull-left" type="date" formControlName="NotificationDate" />
                      <button type="button" class="btn btn-sm btn-danger pull-right" (click)="deleteNotification(i)">
                        <span class="fa fa-trash"></span>
                      </button>
                      <input type="text" formControlName="Description" />
                  </div>
              </ng-container>
          </div>
          <div class="actions">
              <button type="button" class="btn btn-sm btn-primary" (click)="saveNotifications()" [disabled]="!notifications.dirty">Save Notifications</button>
              <button type="button" class="btn btn-sm btn-default" (click)="bsModalRef.hide()">Cancel</button>
          </div>
      </tab>
      <tab heading="Documents" customClass="manage-documents">
          <div class="documents" formGroupName="documents">
              <div class="document">
                  <div class="name">What's New?</div>
                  <app-file-upload formControlName="whatsnew"></app-file-upload>
              </div>
              <div class="document">
                  <div class="name">User Guide</div>
                  <app-file-upload formControlName="userguide"></app-file-upload>
              </div>
              <div class="document">
                  <div class="name">Outlook Add-in Guide</div>
                  <app-file-upload formControlName="outlookguide"></app-file-upload>
              </div>
          </div>
          <div class="actions">
              <button type="button" class="btn btn-sm btn-primary" (click)="saveDocuments()" [disabled]="!documents.dirty">Save Documents</button>
              <button type="button" class="btn btn-sm btn-default" (click)="bsModalRef.hide()">Cancel</button>
          </div>
      </tab>
  </tabset>
</div>