<div class="page-content no-scroll flex-vbox">
    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <app-page-banner
            pagetitle="Mifid Accounts"
            faicon="fa-gear"
            faiconcolor="#708090"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline">
            <div class="input-group">
                <div class="input-group-btn">
                    <label class="btn btn-default" (click)="openAccountform()">
                        <span class="fa fa-plus"></span>
                    </label>
                </div>
                <input type="search"
                       class="form-control"
                       style="width: 250px;"
                       [formControl]="mifidSearchBox"
                       placeholder="Search" />
            </div>
        </form>
    </ng-template>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (cellClicked)="onCellClicked($event)"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>

</div>

