import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";
import {of} from "rxjs";

@Component({
    selector: "app-airport-type-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(AirportTypePickerComponent)
})
export class AirportTypePickerComponent extends BasePickerComponent<any> {

    @Input()
    setMaxItems: number;

    @Input()
    showTagCount: boolean = true;

    @Input()
    closeAfterSelection: boolean = false;

    @Input()
    placeHolderLabel: string = "";
    
    airportTypes = [{Id: 1, Name: "Commercial"}, {Id: 2, Name: "Private"}];

    constructor(
        fb: UntypedFormBuilder,
    ) {
        super(fb);

        this.name = "Airport Type";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }

    ngOnInit() {
        this.showSelectClearAll = false;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = false;
        this.searchable = false;
        this.multiple = false;

        super.ngOnInit();
        this.customOnChange = (data: any) => {
            if(data == "NoAirportTypeSelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }

        this.items$ = of(this.airportTypes);
    }
}
