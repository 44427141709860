import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {combineLatest, of} from "rxjs";
import {ColDef} from "ag-grid-community";
import {map, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ExchangeEmailService} from "../../Shared/Services/exchange-email.service";
import {UntypedFormBuilder} from "@angular/forms";
import * as moment from "moment/moment";

@Component({
    selector: "app-email-select-list",
    templateUrl: "./email-select-list-component.html"
})
export class EmailSelectListComponent extends BaseGridComponent<SelectedEmail> implements OnInit {

    @Input()
    mailboxName: string;

    @Input()
    showToOrFrom : "To" | "From" = "From" ;

    @Input()
    previewEmail$ : EventEmitter<SelectedEmail>;

    selectedEmail: SelectedEmail;

    inboxColumnDefs : ColDef [] = [
        {
            flex: 1,
            field: this.showToOrFrom,
            headerValueGetter: (x) => this.showToOrFrom,
            valueGetter: (x) => {
                if (this.showToOrFrom === "To") {
                    return x.data.To;
                } else {
                    return x.data.From
                }
            },
            tooltipValueGetter: (x) => {
                if (this.showToOrFrom === "To") {
                    return x.data.To;
                } else {
                    return x.data.From
                }
            }
        },
        {
            flex: 2,
            field: 'Subject',
            headerName: 'Subject',
            tooltipValueGetter: (x) => x.data.Subject
        },
        {
            flex: 1,
            field: 'CreatedAt',
            headerName: 'Date',
            tooltipValueGetter: (x) => this.formatDate(x.data.CreatedAt),
            cellRenderer: (x) => this.formatDate(x.data.CreatedAt),
            sort: 'desc'
        }
    ];

    emailSearchFormInbox = this.fb.group({
        searchTerm: this.fb.control('')
    });

    constructor(public bsConfirmModalRef: BsModalRef,
                private exchangeEmailService: ExchangeEmailService,
                private fb: UntypedFormBuilder) {
        super();
    }

    formatDate(date) {
        return moment(date).format('YYYY-MM-DD HH:mm')
    }

    ngOnInit(): void {
        this.getMailboxMail();
    }

    getMailboxMail() {
        const formUpdate$ = this.emailSearchFormInbox.valueChanges.pipe(
            startWith(this.emailSearchFormInbox.getRawValue()));


        this.exchangeEmailService.getMailFolders().subscribe(mailFolders => {
            const inboxFolder = mailFolders.filter(x => x.displayName === this.mailboxName)[0];
            const messages$ = this.exchangeEmailService.getMessagesWithoutBody(inboxFolder.id);

            this.rowData$ = combineLatest([messages$, formUpdate$]).pipe(
                map(([messages, formUpdate]) => {

                    const tableContents = messages.map((element) => {
                        return {
                            MessageId: element.id,
                            CreatedAt: element.createdDateTime,
                            Subject: element.subject,
                            To: element.toRecipients.length > 0 && element.toRecipients[0].emailAddress.address,
                            From: element.from.emailAddress.address
                        } as SelectedEmail;
                    });

                    return tableContents.filter(email => this.SelectedEmailSubjectFilter(email, formUpdate.searchTerm));
                })
            );

        });
    }

    private SelectedEmailSubjectFilter(selectedEmail: SelectedEmail, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        if(_.startsWith(selectedEmail.Subject.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (sa: SelectedEmail): boolean => {

            const fieldsContains = _.map([selectedEmail.Subject], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.every(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };
        return query(selectedEmail);
    }

    rowClicked($event: any) {
        combineLatest([
            this.exchangeEmailService.getMessage($event.data.MessageId),
            this.exchangeEmailService.getMessageAttachments($event.data.MessageId)
        ]).subscribe(([message, attachments]) => {
            this.selectedEmail = $event.data;
            this.selectedEmail.Body = this.subsitutedAttachmentsWithEncodedImages(message.body.content, attachments);
            this.previewEmail$.emit(this.selectedEmail);
        });
    }

    subsitutedAttachmentsWithEncodedImages(html: string, attachments: any[]): string {

        let attachmentDictionary = { };

        attachments.forEach(attachment => {
            attachmentDictionary[attachment.contentId] = attachment.contentBytes;
        });

        let htmlElement = document.createElement( 'html' );
        htmlElement.innerHTML = html;

        let imgElements = htmlElement.getElementsByTagName('img');
        for(let i = 0; i < imgElements.length; i++)
        {
            if (imgElements[i].src.startsWith('cid:')) {

                const attachmentContentId = imgElements[i].src.substring(imgElements[i].src.indexOf('cid:') + 4);

                let matchingAttachment = attachmentDictionary[attachmentContentId];

                if (matchingAttachment) {
                    const extensionRegex = /(?:\.([^.]+))?(@|$)/i;
                    const extension = extensionRegex.exec(imgElements[i].src)[1];

                    imgElements[i].src = `data:image/${extension};base64, `.concat(matchingAttachment);
                }
            }
        }

        return htmlElement.innerHTML;
    }
}

export interface SelectedEmail {
    MessageId: string;
    CreatedAt: string,
    Subject: string,
    To: string,
    From: string,
    Body: string
}

