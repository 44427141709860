import {Component, EventEmitter, Input, OnInit, TemplateRef} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {ContactFormComponent} from "../ContactForm/contact-form.component";
import {ContactService} from "../../Shared/Services/contact.service";
import * as _ from "lodash";
import {Observable} from "rxjs";
import {UnsubscribeResearchModalComponent} from "../UnsubscribeResearch/unsubscribe-research-modal.component";
import {map} from "rxjs/operators";
import {CustomEditEntitlements} from "../ContactForm/custom-edit-entitlements";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {EntitlementService} from "../../Shared/Services/entitlement.service";

@Component({
    selector: "app-contact-preferences",
    templateUrl: "./contact-preferences.component.html",
})

export class ContactPreferencesComponent implements OnInit {
    @Input()
    contactId: number;

    @Input()
    contactDataChanged: EventEmitter<boolean>;

    @Input()
    interestDataChanged: EventEmitter<boolean>;

    entitlements$: Observable<any[]>;
    preference: any = {};

    canEditResearchOnlineEntitlementFeature = UserFeatureName.CanEditResearchOnlineEntitlement;

    constructor(private bsModalService: BsModalService,
                private contactService: ContactService,
                private entitlementService: EntitlementService) {}

    ngOnInit(): void {
        this.getPreferences();
        this.contactDataChanged.subscribe(() => this.getPreferences());
    }

    getPreferences(): void {
        this.entitlements$ = this.entitlementService.getVendorEntitlementsForContact(this.contactId)
            .pipe(
                map((entitlements) => {
                    return entitlements.map(entitlement => {
                        const subscribedInfo = entitlement.EntitlementInfo.find(info => info.IsSubscribed);
                        if (!subscribedInfo) {
                            return {Description: entitlement.Description, EntitlementInfo: null, ModalComponent: null};
                        }
                        return {
                            Description: entitlement.Description,
                            EntitlementInfo: entitlement.EntitlementInfo,
                            ModalComponent: CustomEditEntitlements
                                .find(c => c.Code == entitlement.Code)?.ModalComponent
                        };
                    }).filter(entitlement => entitlement !== null);
                })
            );

        this.contactService.getContactById(this.contactId).subscribe( contact => {
            this.preference = {
                NoContact: contact.ResearchStatus && contact.ResearchStatus.Reason ? 'Y' : 'N',
                SecureLinks: contact.BlueMatrixSecureLinks ? 'Y' : 'N',
                AccountSecureLinks: contact.Account && contact.Account.BluematrixSecureLinks || false,
                ReportType: _.chain(contact.BlueMatrixReportPreferences).pickBy(v => v === 'Y').keys().size().value(),
                AutoSubscribe: contact.BlueMatrixReportPreferences && contact.BlueMatrixReportPreferences.AllTickers === ' ' ? 'N' : 'Y',
                OverrideEmail: contact.OverrideEmailFromAnalyst ? 'Y' : 'N',
                BlastVoiceMailAllowed: contact.BlastVoiceMailAllowed
            }
        });
    }

    openEditModal() {
        let modalRef = this.bsModalService.show(ContactFormComponent,
            {
                initialState: { contactId: this.contactId, defaultTab: "Preferences" },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-lg'
            });

        modalRef.content?.dataUpdated.subscribe(() => {
            this.contactDataChanged.emit(true);
        });
    }

    openUnsubscribeModal() {
        let unsubModalRef = this.bsModalService.show(UnsubscribeResearchModalComponent, {
                initialState: { contactId: this.contactId },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-md'
            });

        unsubModalRef.content.dataUpdated
            .subscribe(() => {
                this.contactDataChanged.emit(true);
                this.interestDataChanged.emit(true);
            });
    }

    openCustomEditModal(component: any): void {
        let modalRef = this.bsModalService.show(component, {
            initialState: { contactId: this.contactId },
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-md'
        });

        // @ts-ignore
        modalRef.content.dataUpdated
            .subscribe(() => {
                this.contactDataChanged.emit(true);
            });
    }
}
