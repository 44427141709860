import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn,} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventMeetingAttendeeDto, EventService, UpdateEventAttendanceDto} from "../../../Shared/Services/event.service";
import {combineLatest, of} from "rxjs";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {EventMeeting} from "../../../../Components/Events/CalendarEvent";
import * as moment from "moment";
import {Activity} from "../../../../Models/Activity";
import {ContactService} from "../../../Shared/Services/contact.service";
import {Contact} from "../../../../Models/Contact";
import {UserService} from "../../../Shared/Services/user.service";
import {User} from "../../../../Models/User";
import {MeetingType} from "../../../../Models/MeetingType";
import * as _ from "lodash";
import {ConfirmModalComponent} from "../../../Widget/ConfirmModal/confirm-modal.component";
import {startWith} from "rxjs/operators";
import {TimeZoneService} from "../../../Shared/Services/time-zone.service";
import {time} from "@ngtools/webpack/src/benchmark";

@Component({
    selector: "app-attendee-form",
    templateUrl: "./attendee-form.component.html",
    styleUrls: ["./attendee-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AttendeeFormComponent implements OnInit {

    @Input()
    eventId: number;

    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    meetings: EventMeeting[] = [];
    meetingTypes: MeetingType[];

    attendeeForm: UntypedFormGroup = this.fb.group({
        contactIds: this.fb.control([]),
        meetingAttendance: this.fb.array([]),
        replayAttendance: this.fb.array([]),
        attendeeNotes: this.fb.control(null),
        confirmed: this.fb.control('N'),
        updateActivityAccountToCurrentAccount: this.fb.control(false),
    }, {validators: this.requiredFields()});

    activities: Activity[];
    contact: Contact;
    user: User;
    subCategory: string;

    isAdd: boolean;
    contactHasActivitiesForOldAccount: boolean;

    formLoading: boolean = false;
    formSaving: boolean = false;

    users: User[];
    alreadyAttendedContactIds: number[];

    timeZoneForEvent: string;

    private replayStatus = "Replay";

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private eventService: EventService,
        private contactService: ContactService,
        private activityService: ActivityService,
        private userService: UserService,
        private toastrService: ToastrService,
        private timeZoneService: TimeZoneService
    ) { }

    ngOnInit(): void {
        this.formLoading = true;

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;

        });
        let attendanceForm$ = this.attendeeForm.get('meetingAttendance').valueChanges
            .pipe(startWith(this.attendeeForm.get('meetingAttendance').value));
        let replayForm$ = this.attendeeForm.get('replayAttendance').valueChanges
            .pipe(startWith(this.attendeeForm.get('replayAttendance').value));
        combineLatest([attendanceForm$, replayForm$])
            .subscribe(([attendanceForm, replayForm]) => {
                if (!this.hasAttendance(attendanceForm) && !this.hasAttendance(replayForm)) {
                    this.attendeeForm.get('attendeeNotes').disable();
                    this.attendeeForm.get('confirmed').disable();
                }
                else {
                    this.attendeeForm.get('attendeeNotes').enable();
                    this.attendeeForm.get('confirmed').enable();
                }
            });

        this.isAdd = !this.contactId;

        let meetings$ = this.eventService.getEventMeetings(this.eventId);

        let activitiesForEvent$ = this.activityService.getActivitiesByEventId(this.eventId)

        let contact$ = this.contactId ? this.contactService.getContactById(this.contactId) : of(null);

        let users$ = this.userService.getUsers();

        let eventContactInfo$ = this.eventService.getEventContactInfo(this.eventId, this.contactId);

        let event$ = this.eventService.getEvent(this.eventId);

        let timeZones$ = this.timeZoneService.getTimeZones();


        combineLatest([meetings$, activitiesForEvent$, contact$, users$, eventContactInfo$, event$, timeZones$])
            .subscribe(([meetings, activitiesForEvent, contact, users, eventContactInfo, event, timeZones]) => {

                this.subCategory = event.SubCategory

                let timeZone = timeZones.find(tz => tz.Id === event.TimeZoneId)?.Abbreviation
                this.timeZoneForEvent = timeZone ? `(${timeZone})` : '';

                this.meetings = _.sortBy(meetings, ["Date", "StartTime", "EndTime"]);
                this.activities = activitiesForEvent;
                this.contact = contact;
                this.users = users;
                this.alreadyAttendedContactIds = (activitiesForEvent as Activity[])
                    .filter(a => a.Status !== "Invited")
                    .filter(a => a.EventMeetingId)
                    .map(a => a.ContactId).filter((value, index, array) => array.indexOf(value) === index);

                this.attendeeForm.get('attendeeNotes').setValue(eventContactInfo?.Notes);
                this.attendeeForm.get('confirmed').setValue(eventContactInfo?.IsConfirmed ? "Y" : "N");
                let activitiesForEditContact = this.contactId ? activitiesForEvent.filter(a => a.ContactId === this.contactId) : [];

                let meetingAttendance = this.attendeeForm.get('meetingAttendance') as UntypedFormArray;
                let replayAttendance = this.attendeeForm.get('replayAttendance') as UntypedFormArray;

                this.contactHasActivitiesForOldAccount = (
                    this.contact &&
                    activitiesForEditContact.find(a => a.ActivityAccountId !== this.contact.Account.Id)
                );

                for (let rowIndex = 0; rowIndex < this.meetings.length; rowIndex++) {
                    let meeting = this.meetings[rowIndex];

                    let meetingTimeZone = ( event.TimeZoneId !== meeting.TimeZoneId) ? timeZones.find(tz => tz.Id === meeting.TimeZoneId) ?.Abbreviation : ""



                    let baseFormGroup = {
                        rowIndex: rowIndex,
                        meetingId: this.fb.control(meeting.MeetingId),
                        name: this.fb.control(meeting.DisplayName),
                        date: this.fb.control(moment(meeting.Date).format('L')),
                        timeRange: this.fb.control(this.formatTimeRange(meeting.StartTime, meeting.EndTime, meetingTimeZone)),
                        activityStatus: this.fb.control({value: "", disabled: meeting.WebcastId && meeting.WebcastId !== ""}),
                        hasWebcastId: this.fb.control(meeting.WebcastId && meeting.WebcastId !== ""),
                    }

                    let attendeeGroup = this.fb.group({
                        ...baseFormGroup,
                        activityId: this.fb.control(""),
                        activityStatus: this.fb.control(""),
                        subCategory: this.fb.control(event.SubCategory)
                    })

                    let replayGroup = this.fb.group({
                        ...baseFormGroup,
                        activityId: this.fb.control(""),
                        activityStatus: this.fb.control(""),
                        replayDate: this.fb.control(""),
                    });

                    let activities = activitiesForEditContact.filter(x => x.EventMeetingId === meeting.MeetingId);

                    activities.forEach(activity => {
                        if (activity.Status === this.replayStatus) {
                            replayGroup.get("activityId").patchValue(activity.Id);
                            replayGroup.get("activityStatus").patchValue(activity.Status);
                            replayGroup.get("replayDate").patchValue(moment(activity.CallDate).format("yyyy-MM-DD"));
                        } else {
                            attendeeGroup.get("activityId").patchValue(activity.Id);
                            attendeeGroup.get("activityStatus").patchValue(activity.Status);
                            attendeeGroup.get("subCategory").patchValue(activity.SubCategory);
                        }
                        attendeeGroup.get("hasWebcastId").patchValue(meeting.WebcastId && meeting.WebcastId !== "");
                    });

                    meetingAttendance.push(attendeeGroup);
                    replayAttendance.push(replayGroup);
                }

                this.attendeeForm.patchValue({
                    contactIds: this.contactId ? [this.contactId] : []
                })

                this.formLoading = false;
            });
    }

    userIsEventAdmin(): boolean {
        return this.user && this.userService.hasUserFeature(this.user, 'EventAdmin');
    }

    formatTimeRange(startTime: string | moment.Moment,
                   endTime: string | moment.Moment,
                    timeZone: string): string {
        if (!startTime && !endTime) {
            return '';
        }

        let startTimeFormatted = startTime ?
            moment(startTime).format('h:mm A',) : '';
        let endTimeFormatted = endTime ?
            moment(endTime).format('h:mm A') : '';

        let timeFormat = `${startTimeFormatted} - ${endTimeFormatted}`

        if (timeFormat && timeZone){
            timeFormat += ` (${timeZone})`
        }
        return `${timeFormat}`;
    }

    getContactText(contact: Contact): string {
        if (!contact) {
            return '';
        }

        let contactInformation = `${contact.LastName}, ${contact.FirstName}`;
        if (contact.AccountName) {
            contactInformation +=  ` (${contact.AccountName})`;
        }

        return contactInformation;
    }

    save() {
        this.formSaving = true;
        let meetingAttendances: EventMeetingAttendeeDto[] = (this.attendeeForm.get('meetingAttendance') as UntypedFormArray).controls.map(control => {
            return {
                ActivityId: control.value.activityId,
                EventMeetingId: control.value.meetingId,
                ActivityStatus: control.value.activityStatus,
                CallDate: null,
                SubCategory: control.value.subCategory
            };
        });

        let replayAttendances: EventMeetingAttendeeDto[] = (this.attendeeForm.get('replayAttendance') as UntypedFormArray).controls.map(control => {
            return {
                ActivityId: control.value.activityId,
                EventMeetingId: control.value.meetingId,
                ActivityStatus: this.replayStatus,
                CallDate: control.value.replayDate,
                SubCategory: '',
            };
        });

        if (this.contactId) {
            let contacts$ = this.contactService.getContactsByIds([this.contactId]);
            let updateActivityAccountToCurrentAccount = this.attendeeForm.get('updateActivityAccountToCurrentAccount').value;

            contacts$.subscribe((contacts) => {

                let adds = this.getAdds(meetingAttendances, replayAttendances, contacts);

                let removes = this.getRemoves(meetingAttendances, replayAttendances);

                let updates = this.getUpdates(meetingAttendances, replayAttendances);

                if (!this.hasAttendance(this.attendeeForm.get('meetingAttendance').value) &&
                    !this.hasAttendance(this.attendeeForm.get('replayAttendance').value)) {

                    const confirmModalInitialState = {
                        message: `Are you sure you want to delete all attendance from this event for ${this.getContactText(contacts[0])}?`
                    };

                    let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
                        initialState: confirmModalInitialState,
                        animated: false,
                        keyboard: false,
                        backdrop: 'static'
                    });

                    confirmModalRef.content.action.subscribe(isConfirmed => {
                        if (isConfirmed) {
                            this.saveAttendanceWithContactInfo(updateActivityAccountToCurrentAccount, adds, updates, removes, contacts);
                        }
                    });

                    confirmModalRef.content.declineAction.subscribe(isDeclined => {
                        this.formSaving = false;
                    });
                } else {

                    this.saveAttendanceWithContactInfo(updateActivityAccountToCurrentAccount, adds, updates, removes, contacts);
                }
            });
        } else {
            let contacts$ = this.contactService.getContactsByIds(this.attendeeForm.get("contactIds").value);

            contacts$.subscribe((contacts) => {
                let adds = this.getAdds(meetingAttendances, replayAttendances, contacts);

                this.saveAttendanceWithContactInfo(false, adds, [], [], contacts);
            });
        }
    }

    clearAttendance(meetingAttendanceGroup: AbstractControl) {
        let activityStatusControl = meetingAttendanceGroup.get("activityStatus");

        activityStatusControl.patchValue("");

        let subCategory = meetingAttendanceGroup.get("subCategory");
        subCategory.patchValue(this.subCategory);
    }

    clearReplay(replayAttendanceGroup: AbstractControl) {
        let replayControl = replayAttendanceGroup.get("replayDate");

        replayControl.patchValue("");
    }

    closeFormWithMessage(message: string): void {
        this.dataUpdated.emit(true);
        this.toastrService.success(message);
        this.modalRef.hide();
        this.formSaving = false;
    }

    close() {
        this.modalRef.hide();
    }

    requiredFields(): ValidatorFn {
        return (form: UntypedFormGroup) => {
            let hasContacts = form.get('contactIds').value.length > 0;
            let hasAttendance = this.hasAttendance(form.get('meetingAttendance').value) ||
                this.hasAttendance(form.get('replayAttendance').value);

            if (this.isAdd && (!hasContacts || !hasAttendance)) {
                return { requiredFields: true }
            }
            return null;
        }
    }

    private saveAttendanceWithContactInfo(updateActivityAccountToCurrentAccount: boolean,
                                          adds: EventMeetingAttendeeDto[],
                                          updates: EventMeetingAttendeeDto[],
                                          removes: number[],
                                          contacts: Contact[]) {
        let updateDto: UpdateEventAttendanceDto = {
            UpdateActivityAccountContactIds: updateActivityAccountToCurrentAccount ? [this.contactId] : [],
            Adds: adds,
            Updates: updates,
            Removes: removes
        };

        let save$ = (updateDto.Adds.length > 0 || updateDto.Updates.length > 0 || updateDto.Removes.length > 0 || updateActivityAccountToCurrentAccount) ?
            this.eventService.updateEventAttendees(this.eventId, updateDto) :
            of(null);

        let contactInfoToSave$ = this.updateContactInfo(contacts);

        combineLatest([save$, contactInfoToSave$]).subscribe(_ => {
            this.closeFormWithMessage("Attendance Updated");
        });
    }

    private getAdds(meetingAttendances: EventMeetingAttendeeDto[], replayAttendances: EventMeetingAttendeeDto[], contacts: Contact[]): EventMeetingAttendeeDto[] {
        let meetingAdds = meetingAttendances.filter(ma =>
            !ma.ActivityId &&
            ma.ActivityStatus !== "");

        let replayAdds = replayAttendances.filter(ma =>
            !ma.ActivityId &&
            this.hasReplayDate(ma.ActivityStatus, ma.CallDate));

        return _.flatMap(contacts, c => ([...meetingAdds, ...replayAdds].map(x => {
            return {
                ContactId: c.Id,
                EventMeetingId: x.EventMeetingId,
                ActivityStatus: x.ActivityStatus,
                CallDate: x.CallDate,
                SubCategory: x.SubCategory,
            };
        })));
    }

    private getRemoves(meetingAttendances: EventMeetingAttendeeDto[], replayAttendances: EventMeetingAttendeeDto[]): number[] {
        let meetingRemoves = this.activities.filter(a =>
            meetingAttendances
                .find(ma =>
                    ma.ActivityId === a.Id &&
                    ma.ActivityStatus === ""))
            .map(a => a.Id);

        let replayRemoves = this.activities.filter(a =>
            replayAttendances
                .find(ma =>
                    ma.ActivityId === a.Id &&
                    !this.hasReplayDate(ma.ActivityStatus, ma.CallDate)))
            .map(a => a.Id);

        return [...meetingRemoves, ...replayRemoves];
    }

    private getUpdates(meetingAttendances: EventMeetingAttendeeDto[], replayAttendances: EventMeetingAttendeeDto[]): EventMeetingAttendeeDto[] {
        let meetingUpdates = meetingAttendances.filter(ma =>
            this.activities
                .find(a =>
                    a.Id === ma.ActivityId &&
                    ma.ActivityStatus !== "" &&
                    (a.Status !== ma.ActivityStatus ||
                a.SubCategory !== ma.SubCategory) ));

        let replayUpdates = replayAttendances.filter(ma =>
            this.activities
                .find(a =>
                    a.Id === ma.ActivityId &&
                    this.hasReplayDate(ma.ActivityStatus, ma.CallDate) &&
                    moment(a.CallDate) !== moment(ma.CallDate)));
        return [...meetingUpdates, ...replayUpdates];
    }

    private updateContactInfo(contacts: Contact[]) {
        let contactInfoToSave = contacts.map(x => {
            return {
                EventId: this.eventId,
                ContactId: x.Id,
                Notes: this.attendeeForm.get('attendeeNotes').value,
                IsConfirmed: this.attendeeForm.get('confirmed').value === "Y",
            };
        });

        return this.eventService.updateEventContactInfos(this.eventId, contactInfoToSave);
    }

    private hasAttendance(form: any[]) {
        return form.length > 0 &&
            (form.some(m => m?.activityStatus && m.activityStatus !== "") ||
                form.some(m => m.replayDate && m.replayDate !== ""));
    }

    private hasReplayDate(status: string, replayDate: moment.Moment | Date | string): boolean {
        return status === this.replayStatus && replayDate != "";
    }
}
