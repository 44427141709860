import {Component, Input, OnInit} from "@angular/core";
import {ContactService} from "../../../Shared/Services/contact.service";
import {CalendarEventAttendee} from "../../../../Components/Events/CalendarEventAttendee";
import * as moment from "moment/moment";

@Component({
    selector: "app-request-meeting-form-replay-tab",
    template: `
        <div class="flex-vbox">
            <div class="flex-hbox form-group" style="font-weight: 600; margin-top: 10px;">
                <div class="flex-4">Contact</div>
                <div class="flex-3">Account</div>
                <div class="flex-1">Tier</div>
                <div class="flex-1">Replay Date</div>
            </div>

            <div class="form-group flex-hbox" *ngFor="let replay of replays">
                <div class="flex-4">
                    {{replay.Name}}
                </div>
                <div class="flex-3">
                    {{replay.Account}}
                </div>
                <div class="flex-1">
                    {{replay.Tier}}
                </div>
                <div class="flex-1">
                    {{replay.CallDate}}
                </div>
            </div>
        </div>
    `
})
export class RequestMeetingFormReplayTabComponent implements OnInit {

    @Input()
    activities: CalendarEventAttendee[];

    replays: AttendeeInfo[] = [];

    replayStatus = "Replay";

    constructor(
        private contactService: ContactService,
    ) {
    }

    ngOnInit(): void {
        let filteredActivities = this.activities.filter(a => a.Status === this.replayStatus);
        let contactIds = filteredActivities.map(a => a.ContactId).filter((value, index, array) => array.indexOf(value) === index);
        let contacts$ = this.contactService.getContactsByIds(contactIds).subscribe(contacts => {
            contacts.forEach((contact) => {

                let replayActivities = filteredActivities.find(a => a.ContactId === contact.Id);

                this.replays.push({
                    Name: `${contact?.LastName}, ${contact?.FirstName}`,
                    Account: contact?.Account?.Name,
                    Tier: contact?.Tier,
                    CallDate: moment(replayActivities?.CallDate).format('LL'),
                })
            });
            this.replays = this.replays.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
}

interface AttendeeInfo {
    Name: string,
    Account: string,
    Tier: string,
    CallDate: string
}
