import {Routes} from "@angular/router";
import {SalesDashboardComponent} from "./Sales/sales-dashboard.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {MsalGuard} from "@azure/msal-angular";
import {DashboardRoutePaths} from "./dashboard-route-paths";

export const dashboardRoutes: Routes = [
    {
        path: DashboardRoutePaths.SalesDashboard,
        component: SalesDashboardComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Sales Dashboard",
            requiredFeatures: [UserFeatureName.DashboardSales]
        },
    },
];
