import {Component, Input, OnInit} from "@angular/core";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {CalendarEvent} from "../../../Components/Events/CalendarEvent";
import {eventAttendanceCountCellRenderer, dateRenderer, } from "../../Shared/ag-grid-cell-renderers";
import {nullableValueComparator} from "../../Shared/ag-grid-cell-comparators";
import {getEventDisplayName} from "../../../Helpers/DisplayStringHelper";
import {UntypedFormBuilder} from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {EventService} from "../../Shared/Services/event.service";
import {getNoRowsOverlayTemplate} from "../../Shared/ag-grid-options";
import {map} from "rxjs/operators";
import * as moment from "moment";
import {combineLatest} from "rxjs";
import {Router} from "@angular/router";
import {EventRoutePaths} from "../../Event/event-route-paths";

@Component({
    selector: "app-research-dashboard-event-list",
    template: `
        <div class="flex flex-vbox">
            <div class="chart-header" style="margin-bottom: 10px">
                {{ title }}
            </div>
            <ag-grid-angular
                class="ag-theme-balham flex"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [rowClassRules]="rowClassRules"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
            >
            </ag-grid-angular>
        </div>
    `
})
export class ResearchDashboardEventListComponent extends BaseGridComponent<CalendarEvent> implements OnInit {

    @Input()
    teamId: number;

    startDate: moment.Moment;
    endDate: moment.Moment;
    title: string;

    columnDefs: ColDef[] = [
        {
            field: "BeginDate",
            headerName: "Start Date",
            maxWidth: 108,
            headerTooltip: "Start Date",
            sort: "asc",
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: "EndDate",
            headerName: "End Date",
            maxWidth: 85,
            headerTooltip: "End Date",
            flex: 1,
            cellRenderer: dateRenderer,
            tooltipValueGetter: dateRenderer,
            comparator: nullableValueComparator
        },
        {
            field: "Name",
            headerName: "Event Name",
            headerTooltip: "Event Name",
            flex: 1,
            valueGetter: (params) => getEventDisplayName(params.data),
            tooltipValueGetter: (params) => getEventDisplayName(params.data),
            comparator: nullableValueComparator
        },
        {
            field: "Type",
            headerName: "Category",
            maxWidth: 115,
            headerTooltip: "Category",
            flex: 1,
            tooltipValueGetter: (params) => params.data.Type,
            comparator: nullableValueComparator
        },
        {
            field: "Location",
            headerName: "Location/Time",
            maxWidth: 215,
            headerTooltip: "Location/Time",
            flex: 1,
            valueGetter: (params) => params.data.Location || "",
            tooltipValueGetter: (params) => params.data.Location,
            comparator: nullableValueComparator
        },
        {
            field: "NumberOfInterested",
            headerName: "Int",
            maxWidth: 50,
            headerTooltip: "Interested",
            flex: 1,
            type: "numericColumn",
            tooltipValueGetter: (params) => params.data.NumberOfInterested,
            comparator: nullableValueComparator
        },
        {
            field: "NumberOfAttendees",
            headerName: "Att",
            maxWidth: 50,
            headerTooltip: "Registered/Attended",
            flex: 1,
            type: "numericColumn",
            cellRenderer: (params) => eventAttendanceCountCellRenderer(params),
            tooltipValueGetter: (params) => params.data.NumberOfAttendees,
            comparator: nullableValueComparator
        },
    ];

    rowClassRules = {
        "non-public-event": (params) => !params.data.IsPublic
    };

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private eventService: EventService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.overlayNoRowsTemplate = getNoRowsOverlayTemplate("Events");

        this.startDate = moment().clone().startOf("day");
        this.endDate = this.startDate.clone().add(5, "years");

        this.title = "Equities Marketing Calendar";

        this.setRowData();
    }

    setRowData(): void {
        this.rowData$ =
            combineLatest([
                this.eventService.searchEvents(this.startDate, this.endDate),
                this.userService.getUsersOnTeam(this.teamId)
            ]).pipe(
                map(([events, usersOnTeam]) => {
                    return events.filter(
                        e => e.AnalystUserIds.find(
                            eventAnalystUserId => usersOnTeam.find(
                                userOnTeam => userOnTeam.Id === eventAnalystUserId
                            )
                        )
                    );
                })
            );
    }

    onRowClicked(event: RowClickedEvent<CalendarEvent>): void {
        if (event.data.Id) {
            this.router.navigate([EventRoutePaths.EventDetail, event.data.Id]);
        } else if (event.data.RequestId) {
            this.router.navigate([EventRoutePaths.RequestDetail], { queryParams: { requestId: event.data.RequestId } });
        }
    }
}
