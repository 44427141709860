import {Component} from '@angular/core';
import {ICellRendererParams, IHeaderGroup} from "ag-grid-community";

@Component({
    selector: 'app-account-summary-group',
    template: `
        <span class="ag-cell-wrapper ag-row-group" (dblclick)="!noData && onCollapseExpand()">
            <span [ngClass]="isExpanded ? 'ag-group-expanded' : 'ag-group-contracted'" [ngStyle]="{'min-width': level > 0 ? '16px' : null }">
                <span *ngIf="!noData" (click)="onCollapseExpand()" class="ag-icon" [ngClass]="isExpanded ? 'ag-icon-tree-open' : 'ag-icon-tree-closed'"></span>
            </span>
            <span class="ag-group-value">{{ params.value }} {{ level > 0 ? '(' + totalAttendees + ')' : null }} {{ meetingInfo ? '(' + meetingInfo + ')' : null }}</span>
        </span>
    `
})
export class AccountSummaryGroupRenderer implements IHeaderGroup {
    params: ICellRendererParams;
    isExpanded: boolean = false;
    meetingInfo: string;
    totalAttendees: number;
    noData: boolean = false;
    level: number;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.level = params.node.level;
        this.totalAttendees = this.level > 0 ? 0 : null;
        this.noData = this.params.node.allLeafChildren.some(c => c.data.type === "NO_DATA");
        if (!this.noData) {
            const types = {};
            this.params.node.allLeafChildren
                .filter(c => !c.data.noData)
                .forEach(c => {
                    this.totalAttendees += c.data.attendees;
                    if (c.data.type) {
                        types[c.data.type] = (types[c.data.type] ?? 0) + c.data.attendees;
                    }
                });
            this.meetingInfo = Object.entries(types)
                .sort(([typeA], [typeB]) => typeA.localeCompare(typeB))
                .map(([type, attendees]) => `${attendees} - ${type}`)
                .join(", ");  
        }
    }

    onCollapseExpand(): void {
        this.isExpanded = !this.isExpanded;
        this.params.node.setExpanded(this.isExpanded);
    }

    refresh(): boolean {
        return false;
    }
}