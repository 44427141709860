import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {AccountVoteService} from "../../Shared/Services/account-vote.service";
import {AccountVoteAttachment} from "./account-vote";

@Component({
    selector: 'app-account-vote-attachment',
    template: `
        <div *ngFor="let attachment of attachments" style="text-overflow: ellipsis; overflow: hidden;" (click)="click(attachment.Id)">
            <a [title]="attachment.Filename">{{ attachment.Filename }}</a>
        </div>
    `
})

export class AccountVoteAttachmentCellRenderer implements ICellRendererAngularComp {
    attachments: AccountVoteAttachment[];

    constructor(private accountVoteService: AccountVoteService) {}

    agInit(params: any): void {
        this.attachments = params.data.Attachments;
    }

    refresh(): boolean {
        return true;
    }

    click(id: number) {
        this.accountVoteService.getAttachment(id).subscribe();
    }
}
