import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {UserRenameService} from "./user-rename.service";
import {Router} from "@angular/router";
import {AdminRoutePaths} from "../admin-route-paths";

@Component({
    selector: "app-admin-user-rename",
    templateUrl: "./user-rename.component.html"
})
export class UserRenameComponent implements OnInit {
    message: string = "The user id and all associated references will be updated.  The user id must be 4 characters";
    errorMessage: string = "";
    wait: boolean = false;
    submitted: boolean = false;
    title: string;
    userId: string;
    form: UntypedFormGroup;

    @Output()
    dataUpdated: EventEmitter<string> = new EventEmitter<string>();

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private toastr: ToastrService,
                private userRenameService: UserRenameService,
                private router: Router,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            newUserId: this.fb.control('', [
                Validators.required, Validators.minLength(4), Validators.maxLength(4)
            ])
        });
    }

    close(): void {
        this.modalRef.hide();
        this.toastr.warning(`User ID rename canceled`);
    }

    changeUserId(): void {
        this.submitted = true;
        this.errorMessage = "";
        if(this.form.invalid){
            return;
        }
        this.wait = true;
        const newId = this.form.value.newUserId;
        const req = this.userRenameService.renameUser(this.userId, newId);
        const success = a => {
            this.toastr.success(`User ID renamed to ${newId}`);
            this.dataUpdated.emit(newId);
            this.modalRef.hide();
            this.router.navigate([AdminRoutePaths.UserList]);
        };
        const error = e => {
            this.wait = false;
            this.errorMessage = e;
        };
        req.subscribe(success, error);
    }

    get newUserId() { return this.form.get('newUserId'); }
    get hasErrors() { return (this.form.invalid || this.errorMessage) && this.submitted }
}
