import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular-legacy";

@Component({
    selector: 'app-textbox-renderer',
    template: `
    <input
      type="text"
      class="form-control input-sm"
      style="width: 100%; height: 100%; padding: 3px;"
      (blur)="blurHandler($event)"
      [value]="params.value"
    />
`,
})
export class TextBoxRenderer implements ICellRendererAngularComp {
    params: any;

    agInit(params: any): void {
        this.params = params;
    }

    blurHandler(event) {
        let value = event.target.value;
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, value);
    }

    refresh(params: any): boolean {
        this.params = params;
        return true;
    }
}
