import {Component} from '@angular/core';
import {IHeaderAngularComp} from "ag-grid-angular-legacy";
import {IHeaderParams} from "ag-grid-community";

@Component({
    selector: 'ag-collapsible-header',
    template: `
        <div class="flex flex-hbox">
            <div (click)="onSort()" class="ag-cell-label-container">
                <div class="ag-header-cell-label">
                    <span class="ag-header-cell-text">{{params.displayName}}</span>
                    <span class="ag-header-icon ag-header-label-icon">
                   <span class="ag-icon" [ngClass]="{'ag-icon-asc': sort === 'asc', 'ag-icon-desc': sort === 'desc'}"></span>
                </span>
                </div>
            </div>
        <span (click)="onCollapse()" class="collapse-icon">
                <i class="fa" [ngClass]="isCollapsed ? 'fa-plus' : 'fa-minus'"></i>
            </span>
        </div>
    `
})
export class CollapsibleHeaderRenderer implements IHeaderAngularComp {
    params: IHeaderParams & { isCollapsed: () => boolean; onCollapse: (params: IHeaderParams, isCollapsed: boolean) => void };
    isCollapsed: boolean = true;
    sort: string;

    agInit(params: IHeaderParams & { isCollapsed: () => boolean; onCollapse: (params: IHeaderParams, isCollapsed: boolean) => void }): void {
        this.params = params;
        if (params.isCollapsed) {
            this.isCollapsed = params.isCollapsed();
        }
        params.column.addEventListener("sortChanged", () => {
            this.sort = this.params.column.getSort();
        });
    }
    
    onSort(): void {
        if (this.params.column.getColDef().sortable) {
            this.params.progressSort();
        }
    }
    
    onCollapse(): void {
        this.isCollapsed = !this.isCollapsed;
        this.params.onCollapse && this.params.onCollapse(this.params, this.isCollapsed);
    }

    refresh(): boolean {
        return false;
    }
}