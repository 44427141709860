import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {User} from "../../../Models/User";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

@Injectable({
    providedIn: 'root',
})
export class UserAdminService {

    constructor(private baseHttp: BaseHttpService) {
    }

    createUser(user: User): Observable<User> {
        return this.baseHttp.postData(`/user/id/${user.Id}`, user);
    }

    updateUser(user: User): Observable<User> {
        return this.baseHttp.putData(`/user/id/${user.Id}`, user);
    }

    deleteUser(userId: string): Observable<any> {
        return this.baseHttp.deleteData(`/user/id/${userId}`);
    }
    
    updateMyTeam(userId: string, teamUserIds: string[]): Observable<any> {
        return this.baseHttp.postData(`/user/id/${userId}/membership`, teamUserIds);
    }

    updateSenderPermissions(userId: string, sendAsIds: string[]): Observable<any> {
        return this.baseHttp.postData(`/user/${userId}/campaign/from/permissions`, sendAsIds);
    }

    updateSendOnBehalfOfPermissions(userId: string, sendOnBehalfOfIds: string[]): Observable<any> {
        return this.baseHttp.postData(`/user/${userId}/campaign/onbehalfof/permissions`, sendOnBehalfOfIds);
    }
    
    updateUserFeatures(userId: string, features: string[]): Observable<any> {
        return this.baseHttp.postData(`/user/${userId}/features`, features);
    }
}
