<div class="flex flex-vbox form-underline">
    
    <div class="flex" style="padding: 10px; background-color: #fff; border: 1px solid #ccc; overflow-y: auto;">
        <div class="text-right" style="margin-bottom: 10px">
            <button type="button" class="btn btn-primary btn-xs" (click)="openEditModal()">Edit</button>
        </div>
        
        <div class="flex-hbox">
            <div class="flex form-group">
                <label>Title</label>
                <div>{{ contactProfile.Title }}</div>
            </div>
            <div class="flex form-group">
                <label>Role(s)</label>
                <div>{{ getContactRoleNames() }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex flex-hbox" style="margin-bottom: 0px">
                <div class="flex form-group">
                    <label>Mkt Cap Low (Bn)</label>
                    <div>{{ contactProfile.MarketCapLow | number : "1.0-4" }}</div>    
                </div>
                <div class="flex form-group">
                    <label>Mkt Cap High (Bn)</label>
                    <div>{{ contactProfile.MarketCapHigh | number : "1.0-4" }}</div>    
                </div>
            </div>
            <div class="flex form-group">
                <label>Investor Style(s)</label>
                <div>{{ contactProfile.InvestorStyles.join(', ') }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex flex-hbox" style="margin-bottom: 0px">
                <div class="flex form-group">
                    <label>Global Investor</label>
                    <div>{{ contactProfile.GlobalInvestor === null ? '' : contactProfile.GlobalInvestor ? 'Y' : 'N' }}</div>
                </div>
                <div class="flex form-group">
                    <label>Private Investment</label>
                    <div>{{ contactProfile.PrivateInvestment === null ? '' : contactProfile.PrivateInvestment ? 'Y' : 'N' }}</div>
                </div>
            </div>
            <div class="flex form-group">
                <label>Orientation</label>
                <div>{{ contactProfile.Orientations.join(', ') }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex form-group">
                <label>Deal Player</label>
                <div>{{ contactProfile.DealPlayer.join(', ') }}</div>
            </div>
            <div class="flex form-group">
                <label>Team(s)</label>
                <div>{{ contactProfile.Teams.join(', ') }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex">
                <label>Sector > Industry</label>
                <div *ngFor="let sector of contactSectors | async">{{sector}}</div>
            </div>
            <div class="flex">
                <label>Employment History</label>
                <div *ngFor="let employment of contactEmployment | async">{{employment}}</div>
            </div>
        </div>
    </div>
    
</div>




