<div class="modal-content">
    <div class="modal-header">
        <div class="flex-hbox">
            <div style="width: 300px">
                <div style="margin-bottom: 5px;"><h3 class="modal-title">View Meeting</h3></div>
                <div><span style="font-weight:700">Event Meeting ID:</span> {{requestMeeting?.Id}}</div>
            </div>
            <div style="width: 220px">
                <div><span style="font-weight:700">Date:</span> {{(requestMeeting?.MeetingDate)}}</div>
                <div><span style="font-weight:700">Time:</span> {{requestMeeting?.MeetingTime}} {{timeZone}} </div>
                <div><span style="font-weight:700">Duration:</span> {{requestMeeting?.Duration}}</div>
            </div>

            <div style="width: 220px">
                <div><span style="font-weight:700">Attended:</span> {{(requestMeeting?.NumberOfAttendees)}}</div>
                <div><span style="font-weight:700">Interested:</span> {{requestMeeting?.NumberOfInterested}}</div>
            </div>
        </div>
    </div>
    <form>
        <div *ngIf="requestMeeting" class="modal-body">
            <tabset>
                <tab heading="Attendance">
                    <app-request-meeting-form-attendee-tab
                            [activities]="requestMeeting.Activities"
                    >
                    </app-request-meeting-form-attendee-tab>
                </tab>
                <tab heading="Replays">
                    <app-request-meeting-form-replay-tab
                            [activities]="requestMeeting.Activities"
                    >
                    </app-request-meeting-form-replay-tab>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div>
                <button type="button" class="btn btn-default btn-sm" (click)="close()">Close</button>
            </div>
        </div>
    </form>
</div>

