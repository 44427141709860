import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {AgGridModule} from "ag-grid-angular-legacy";
import {NgSelectModule} from "@ng-select/ng-select";
import {CommissionAccountRenderer} from "../Widget/CellRenderers/commission-account-renderer";
import {PercentageRenderer} from "../Widget/CellRenderers/percentage-renderer.component";
import {CollapsibleHeaderRenderer} from "../Widget/CellRenderers/collapsible-header-renderer.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {entitlementsRoutes} from "./entitlements.routes";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {EntitlementListComponent} from "./FactSet/entitlement-list.component";
import {EntitlementFormComponent} from "./EntitlementForm/entitlement-form.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([CommissionAccountRenderer, PercentageRenderer, CollapsibleHeaderRenderer]),
        ButtonsModule.forRoot(),
        NgSelectModule,
        BsDropdownModule,
        TooltipModule,
        RouterModule.forChild(entitlementsRoutes),
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ],
    entryComponents:[
        EntitlementListComponent,
        EntitlementFormComponent,
    ],
    declarations: [
        EntitlementListComponent,
        EntitlementFormComponent,
    ],
})
export class EntitlementsModule {
    constructor(){
        console.log("registering the ReportModule");
    }
}
