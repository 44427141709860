import {User} from "../Models/User";
import {RoleName} from "../Models/Role";

export const UserPermissions  = {
    canManageBlueMatrixOnHoldings(user: User): boolean {
        if(!user || !user.Role){
            return false;
        }
        return user.Role.Name !== RoleName.EquityCapitalMarkets;
    },

    canEditAccountAndContactNotes(user: User | {Role: {Name: string}}): boolean {
        if(!user || !user.Role){
            return false;
        }
        return user.Role.Name !== RoleName.EquityCapitalMarkets;
    }
}