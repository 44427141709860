<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="emailSearchForm">
            <div class="input-group">
                <input type="search"
                       class="form-control input-sm pull-right"
                       style="width: 250px;"
                       formControlName="searchTerm"
                       placeholder="Search" />
                <span class="pull-right">
                    <app-date-range-picker 
                            [dates]="dateRange" 
                            [options]="dateRangeOptions"
                            (rangeSelected)="onDateChange($event)">
                    </app-date-range-picker>
                </span>
            </div>
        </form>
    </ng-template>
    
    <app-page-banner
        pagetitle="Shared Emails"
        faicon="fa fa-2x fa-envelope"
        faiconcolor="#ff8080"
        [bannerFormTemplate]="bannerFormTemplate"
        [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)"
                [frameworkComponents]="frameworkComponents"
                [context]="context"
        >
        </ag-grid-angular>
    </div>
</div>