<style>
    div.sidebar-list {
        position: relative;
    }
    
    div.sidebar-list > div {
        position: absolute;
        left: 100%;
        top: 0;
        display: none;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    div.sidebar-list.flyup > div {
        top: inherit;
        bottom: 0;
    }
    
    div.sidebar-list:hover > div {
        display: block;
    }

    div.sidebar-list > div > div {
        height: 30px;
        display: block;
        background-color: #30426a;
    }
    
    div.shell-nav-wrapper div.shell-nav div.sidebar-list > div > div > a {
        line-height: 30px;
    }
    
</style>


<div class="shell flex-hbox" *ngIf="!hideShell && !loadingCurrentUser">
    <div class="shell-nav-wrapper">
        <div class="shell-nav" [class.open]="open">
            <div style="background-color: #2d3e63; height: 50px">
                <a (click)="open = !open" style="color: white; font-size: 18px; line-height: 46px">
                    Instisel
                    <span class="menu-icon fa fa-exchange" style="color: white; font-size: 18px; line-height: 46px" ></span>    
                </a>
            </div>

            <div *ngFor="let link of navLinks" class="sidebar-list">
                <a [routerLink]="[link.Route]" [relativeTo]="null" *ngIf="link.Route">
                    <span *ngIf="link.SubNavs && link.SubNavs.length > 1" class="fa fa-angle-right" style="position: absolute; border-width: 0; top: 10px; right: 10px; color: #b2bfdc; font-size: 17px;"></span>
                    {{ link.Name }}
                    <span class="menu-icon fa" [ngClass]="link.Icon" [title]="link.Name" ></span>    
                </a>
                <a [href]="link.Url" target="_blank" *ngIf="link.Url">
                    <span *ngIf="link.SubNavs && link.SubNavs.length > 1" class="fa fa-angle-right" style="position: absolute; border-width: 0; top: 10px; right: 10px; color: #b2bfdc; font-size: 17px;"></span>
                    {{ link.Name }}
                    <span class="menu-icon fa" [ngClass]="link.Icon" [title]="link.Name" ></span>
                </a>
                <div *ngIf="link.SubNavs && link.SubNavs.length > 1">
                    <div *ngFor="let subNav of link.SubNavs">
                        <a [routerLink]="[subNav.Route]" [relativeTo]="null" [href]="subNav.Url" *ngIf="subNav.Route">{{ subNav.Name }}</a>
                        <a [href]="subNav.Url" target="_blank" *ngIf="subNav.Url">
                            {{subNav.Name}}
                            <span class="fa fa-external-link" style="text-indent: 5px; border-left-width: 0;"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="build-version">
                <span>{{buildVersion}}</span>
            </div>
        </div>
    </div>
    <div class="flex flex-vbox">
        <div class="shell-top flex-hbox">
            <span style="margin-left: 5px; width: 500px">
                <app-global-search></app-global-search>
            </span>
            <div class="links flex-hbox">
                <span>
                    <a class="btn btn-xs btn-primary" target="_blank" [href]="powerBIReportsUrl">Power BI Reports</a>
                </span>
                <span>
                    <app-shared-emails-notifications></app-shared-emails-notifications>
                </span>
                <span>
                    <app-notifications></app-notifications>
                </span>
                <span>
                    <span><app-user></app-user></span>
                </span>
                <span>
                    <span><app-network-swap></app-network-swap></span>
                </span>
                <span>
                    <app-help></app-help>
                </span>
            </div>
        </div>
        <div>
            <router-outlet class="flex"></router-outlet>
        </div>
    </div>
</div>


<div *ngIf="hideShell">
    <router-outlet class="flex"></router-outlet>
</div>

