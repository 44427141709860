import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import {ActivitySubCategory} from "../../../../Models/ActivitySubCategory";

@Component({
    selector: "app-activity-sub-category-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ActivitySubCategoryPickerComponent)
})
export class ActivitySubCategoryPickerComponent extends BasePickerComponent<ActivitySubCategory> {
    @Input()
    placeHolder: string = "";
    
    @Input()
    multiple: boolean = true;
    
    @Input()
    closeOnSelect: boolean = false;
    
    @Input()
    showSelectClearAll: boolean = true;

    @Input()
    appendTo: string = null;
    
    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);
        
        this.name = "Activity Sub Category(s)";
        this.bindValue = "Value";
        this.bindLabel = "DisplayName";
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.activityService.getActivitySubCategories().pipe(
            map(subCats => {
                return _.chain(subCats)
                    .uniqBy(sc => sc.Value)
                    .sortBy(sc => sc.DisplayName)
                    .value();
            })
        );
    }
}
