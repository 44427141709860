import {Routes} from "@angular/router";
import {QueryListComponent} from "./QueryList/query-list.component";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {MsalGuard} from "@azure/msal-angular";
import {QueryRoutePaths} from "./query-route-paths";
export const queryRoutes: Routes = [
    {
        path: QueryRoutePaths.QueryList,
        component: QueryListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Query List",
            requiredFeatures: [UserFeatureName.QueryBuilder]
        },
    },
];
