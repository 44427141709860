import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {InstiselSector, TickerService} from "../../../Shared/Services/ticker.service";
import {map} from "rxjs/operators";

@Component({
    selector: "app-instisel-sector-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(InstiselSectorPickerComponent)
})
export class InstiselSectorPickerComponent extends BasePickerComponent<any> {

    @Input()
    alignRight: boolean = false;
    
    @Input()
    placeHolder: string;
    
    @Input()
    multiple: boolean = true;
    
    @Input()
    closeOnSelect: boolean = false;
    
    @Input()
    includeEmptyOption: boolean = false;
    
    @Input() 
    showSelectClearAll: boolean = true;
    
    @Input()
    showNonParents: boolean = true;
    
    constructor(fb: UntypedFormBuilder, private tickerService: TickerService){
        super(fb);
        
        this.name = "Sector(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.customSearchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.tickerService.getInstiselSectors().pipe(
            map(results => {
                let sectors = (results as InstiselSector[])
                    .filter(s => this.showNonParents || !s.ParentInstiselSector)
                    .map(sector => {
                        return {
                            Id: sector.Id.toString(),
                            Name: sector.ParentInstiselSector ?`${sector.ParentInstiselSector.LongName} > ${sector.LongName}` : `${sector.LongName}`
                        };
                    })
                    .sort((a, b) => {
                        return a.Name > b.Name ? 1 : (a.Name < b.Name ? -1 : 0);
                    });
                
                if (!this.multiple) {
                    sectors.unshift({ Id: '', Name: ' ' });
                }
                
                return sectors;
            })
        );
    }
}
