import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ContactSubscriptionFormComponent} from "./contact-subscription-form.component";
import {SubscriptionService} from "../../Shared/Services/subscription.service";
import {map, startWith} from "rxjs/operators";
import {combineLatest, forkJoin, Observable} from "rxjs";
import {ContactSpecialtyMailingFormComponent} from "./contact-specialty-mailing-form.component";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {UserService} from "../../Shared/Services/user.service";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {InstiselSector, TickerService} from "../../Shared/Services/ticker.service";
import {EntitlementService} from "../../Shared/Services/entitlement.service";

@Component({
    selector: "app-contact-subscription-list",
    templateUrl: "./contact-subscription-list.component.html"
})
export class ContactSubscriptionListComponent extends BaseGridComponent<SubscriptionRowData> implements OnInit{

    @Input()
    contactId: number;

    @Input()
    interestDataChanged: EventEmitter<boolean>;

    @Input()
    contactDataChanged: EventEmitter<boolean>;

    columnDefs = [
        {field: 'Analyst', rowGroup: true, hide: true },
        {field: 'Type', width: 90, tooltipValueGetter: (p) => p.data ? p.data.TypeTooltip ?? p.data.Type : '' },
        {field: 'Code', width: 60 },
        {field: 'Comment',width: 90, tooltipValueGetter: (p) => p.data ? p.data.Comment : '' },
        {field: 'CapLow', headerName: 'CapLo', width: 70 },
        {field: 'CapHigh', headerName: 'CapHi', width: 70 },
        {field: 'Category', headerName: 'Cat', width: 50, cellClass: p => p.value === 'N' ? 'interest-category-no': 'interest-category-not-no' },
        {field: 'BlueMatrix', headerName: 'BM', width: 40, cellClass: p => p.value === 'Y' ? 'bluematrix-yes': 'bluematrix-no' },
        {field: 'MarketingEmails', headerName: 'MKT', headerTooltip: 'Marketing Emails', width: 40, cellClass: p => p.value === 'Y' ? 'marketing-emails-yes' : 'marketing-emails-no'},
        {field: 'ResearchEmails', headerName: 'RES EM', headerTooltip: 'Research Emails', width: 50, cellClass: p => p.value === 'Y' ? 'research-emails-yes' : 'research-emails-no'},
        {field: 'CreateDate', headerName: 'Created', width: 85, cellRenderer: dateRenderer }
    ];

    allInstiselSectors: InstiselSector[] = [];

    form: UntypedFormGroup = this.fb.group({
        search: this.fb.control(''),
        analysts: this.fb.control([]),
        types: this.fb.control([]),
    });

    constructor(private fb: UntypedFormBuilder,
                private subscriptionService: SubscriptionService,
                private entitlementService: EntitlementService,
                private userService: UserService,
                private modalService: BsModalService,
                private tickerService: TickerService) { super(); }

    ngOnInit(): void {
        this.gridOptions.pagination = true;
        this.gridOptions.groupDefaultExpanded = -1;
        this.gridOptions.autoGroupColumnDef = {
            headerName: 'Analyst',
            sort: 'asc',
            flex: 1,
            tooltipValueGetter: (p) => p.data ? p.data.Analyst : p.value,
            cellRendererParams: {
                suppressCount: true
            }
        }

        this.tickerService.getInstiselSectors()
            .subscribe((instiselSectors) => {
                this.allInstiselSectors = instiselSectors;

                this.setRowData();

                this.interestDataChanged.subscribe(() => {
                    this.setRowData();
                });

                this.contactDataChanged.subscribe(() => {
                    this.setRowData();
                });
            });
    }

    setRowData() {
        let contactSpecialtyMailings$: Observable<SubscriptionRowData[]> = forkJoin([
            this.subscriptionService.getSpecialtyMailings(true),
            this.subscriptionService.getContactSpecialtyMailings(this.contactId)
        ])
            .pipe(
                map(([
                    specialtyMailings,
                    contactSpecialtyMailings
                ]) => {
                    let values: SubscriptionRowData[] = [];

                    contactSpecialtyMailings.forEach(csm => {
                        let specialtyMailing = specialtyMailings.find(sm => sm.Code === csm.Code);
                        if (specialtyMailing) {
                            values.push({
                                Analyst: specialtyMailing.Analyst,
                                Type: 'Spec Mailing',
                                TypeTooltip: 'Spec Mailing',
                                Code: specialtyMailing.Symbol,
                                Comment: specialtyMailing.Description,
                                CreateDate: csm.CreatedDate,
                                BlueMatrix: csm.IsBlueMatrix ? 'Y' : 'N',
                                ResearchEmails: csm.IsResearchEmail ? 'Y' : 'N',
                                MarketingEmails: csm.IsMarketingEmail ? 'Y' : 'N',
                                CapLow: null,
                                CapHigh: null,
                                Category: null
                            });
                        }
                    });

                    return values;
                })
            );

        let vendorEntitlements$ : Observable<SubscriptionRowData[]> = this.entitlementService.getVendorEntitlementsForContact(this.contactId).pipe(
            map(entitlements =>
                entitlements.filter(e => e.EntitlementInfo?.some(a => a.IsSubscribed === true))
                    .map(e => {
                    const earliestDate = e.EntitlementInfo.map(ei => ei.CreateDate).sort()[0] ?? null;

                    return {
                        Analyst: 'Vendor Entitlements',
                        Type: 'Vendor',
                        Code: e.Code,
                        Comment: e.Description,
                        CreateDate: earliestDate
                    } as SubscriptionRowData;
                })
            )
        );

        let subscriptions$ : Observable<SubscriptionRowData[]> = this.subscriptionService.getSubscriptions(this.contactId)
            .pipe(
                map(subscriptions =>
                    subscriptions
                        .filter(s =>
                            s.InstiselSectorId ?
                                this.allInstiselSectors.filter(a => a.Id === s.InstiselSectorId).length > 0 :
                                !s.IndustryId && !s.SectorId
                        )
                        .map(s => {
                            let instiselSector = this.allInstiselSectors.find(a => a.Id === s.InstiselSectorId);

                            return {
                                Analyst: s.InstiselSectorId ? 'Sector' : s.Analyst,
                                Type: s.InstiselSectorId ? instiselSector.LongName : 'Subscription',
                                TypeTooltip:
                                    s.InstiselSectorId ?
                                        (instiselSector.ParentInstiselSector ? `${instiselSector.ParentInstiselSector.LongName} > ${instiselSector.LongName}` : instiselSector.LongName) :
                                        'Subscription',
                                Code: s.Code,
                                Comment: s.Comment,
                                CapLow: s.MarketCapLow,
                                CapHigh: s.MarketCapHigh,
                                Category: s.Category,
                                BlueMatrix: s.BlueMatrix,
                                ResearchEmails: s.ResearchEmails,
                                MarketingEmails: s.MarketingEmails,
                                CreateDate: s.CreateDate,
                                Id: s.Id,
                            }
                        }
                    )
                )
            );

        let flatData$ =
            combineLatest([contactSpecialtyMailings$, vendorEntitlements$, subscriptions$])
                .pipe(
                    map(
                        ([specMailings, vendorEntitlements, subscriptions]) =>
                            specMailings
                                .concat(vendorEntitlements)
                                .concat(subscriptions)
                    )
                );

        let form$ = this.form.valueChanges.pipe(
            startWith(this.form.value),
        );

        let analysts$ = this.userService.getAnalysts();

        this.rowData$ = combineLatest([form$, flatData$, analysts$]).pipe(
            map(([formValue, flatData, analysts]) => {

                if (formValue.analysts.length > 0) {
                    let analystLastNames = analysts
                        .filter(a => formValue.analysts.includes(a.Id))
                        .map(a => a.LastName);
                    flatData = flatData.filter(r => analystLastNames.includes(r.Analyst));
                }

                if (formValue.search) {
                    flatData = flatData.filter(r => objectSearchPredicate(r, formValue.search));
                }

                return flatData;
            })
        );
    }

    detailsCellRenderer(params: any): string {

        let details = params.data.Comment;

        if (params.data.MarketCapMin) {

        }

        return details;
    }

    onRowClicked($event: any) {
        this.openModalForm({ subscriptionId: $event.data.Id }, $event.data.Type, $event.data);
    }

    onClickAdd(modalType: string) {
        this.openModalForm({ contactId: this.contactId }, modalType, null);
    }

    openModalForm(initialState: any, modalType: string, data: any): void {
        let modalRef
        if (modalType === 'Subscription' || data?.Analyst === 'Sector')
        {
            modalRef = this.modalService.show(ContactSubscriptionFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static' });
        } else if (modalType === 'Spec Mailing') {
            modalRef = this.modalService.show(ContactSpecialtyMailingFormComponent, { initialState: { contactId: this.contactId}, animated: false, keyboard: false, backdrop: 'static' });
        }

        modalRef.content.dataUpdated.subscribe(() => {
            this.setRowData();
            this.interestDataChanged.emit(true);
        });
    }
}

interface SubscriptionRowData {
    Analyst: string;
    Code: string;
    Comment: string;
    Type: string;
    TypeTooltip: string;
    CapLow: number;
    CapHigh: number;
    Category: string;
    BlueMatrix: string;
    MarketingEmails: string;
    ResearchEmails: string;
    CreateDate: Date | string;
}
