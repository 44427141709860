<div class="modal-header">
    Edit Signature
</div>
<div class="modal-body">
    <div class="form-group">
        <app-html-editor [formControl]="signature" #editor></app-html-editor>    
    </div>
    <div class="form-group">
        <button type="button" class="btn btn-sm btn-default pull-right" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="save()">Save</button>
    </div>
</div>