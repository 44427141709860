import {Component, Input, OnInit } from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";
import {User, UserFeatureName} from "../../../Models/User";
import * as _ from "lodash";
import {RoleName} from "../../../Models/Role";

@Component({
    selector: "app-click-to-dial",
    templateUrl: "./click-to-dial.component.html"
})
export class ClickToDialComponent implements OnInit {

    @Input()
    phone: string;
    
    @Input()
    extension: string;
    
    user: User;
    
    constructor(private userService: UserService) { }
    
    ngOnInit(): void {
        this.userService.getCurrentUser()
            .subscribe(user => {
               this.user = user;
            });
    }

    getPhoneUri(): string {
        const phone = _.replace(this.phone, new RegExp(" ", "g"), "");
        return  phone + (_.isEmpty(this.extension) ? '' : ',' + this.extension);
    }

    showClickToDial(): boolean {
        return this.user && this.user.Role && (this.user.Role.Name == RoleName.Research  || _.includes(this.user.Features, UserFeatureName.ClickToDial));
    }

    hasPhoneNumber(): boolean {
        return !_.isNil(this.phone) && !_.isEmpty(this.phone);
    }
}
