import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular-legacy";
import * as moment from "moment";
import {CalendarEventAttendee} from "../../../Components/Events/CalendarEventAttendee";

@Component({
    selector: 'app-event-attendee-renderer',
    template: `
        <ng-template #popTemplate>
            <div style="text-align: left; padding: 4px; background-color: #eee;">{{ params.data.Status }} Details</div>
            <div *ngFor="let detail of params.data.Details" class="info">
                <h5>
                    <span class="pull-right">{{ getDate(detail) }}</span>
                    {{ getDetails(detail) }}
                </h5>
                <div class="comment" style="color: #999;">{{ detail.Comment }}</div>
            </div>
        </ng-template>
        <div style="position: relative; overflow: hidden;">
            <button type="button"
                    class="btn btn-link"
                    style="padding: 0;"
                    [tooltip]="popTemplate"
                    placement="right"
                    containerClass="attendee-popover"
                    container="body">{{params.data.Status}} ({{params.data.StatusCount}})</button>
        </div>
`,

})
export class EventAttendeeRenderer implements ICellRendererAngularComp {
    params: any = { data: {} };

    constructor() {

    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: any): boolean {
        return true;
    }

    getDate(detail: CalendarEventAttendee): string {
        return moment(detail.CallDate).format('LL');
    }

    getDetails(detail: CalendarEventAttendee): string {
        let val = '';
        if (detail.MeetingType) { val += `${detail.MeetingType}: `}
        if (detail.Tickers) { val += detail.Tickers }
        if (detail.Duration) { val += ` (${detail.Duration} mins)`}
        return val || '';
    }
}
