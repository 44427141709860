import {Component, EventEmitter, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {AccountService} from "../../Shared/Services/account.service";
import {Account} from "../../../Models/Account";
import {DateRange} from "../../Widget/DateRangePicker/date-range";
import * as moment from "moment";
import {ActivityService} from "../../Shared/Services/activity.service";
import {startWith, switchMap} from "rxjs/operators";
import {RoleName} from "../../../Models/Role";
import {TeamName} from "../../../Models/Team";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {TabsetComponent} from "ngx-bootstrap/tabs";
import {ContactResearchService} from "../../Shared/Services/contact-research.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountRoutePaths} from "../account-route-paths";

@Component({
    selector: "app-account-detail",
    templateUrl: "./account-detail.component.html",
    styleUrls: ['./account-detail.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountDetailComponent implements OnInit {

    defaultTab: AccountDetailsTab = "RvC";

    @ViewChild('accountDetailTabs', { static: false }) accountDetailTabs?: TabsetComponent;

    accountDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    commissionDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    accountId: number;
    account: Account;
    defaultDateRange: DateRange = { start: moment().add(-18, "month"), end: moment() };
    categories: string[];
    relatedAccount: UntypedFormControl = this.fb.control(null);
    isVote: boolean = false;

    isCommissionsVisible: boolean = false;
    isRvCVisible: boolean = false;
    isEESVisible: boolean = false;
    isRevenueTarget: boolean = false;
    isInvoiceBilling: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private accountService: AccountService,
                private contactResearchService: ContactResearchService,
                private activityService: ActivityService,
                private route: ActivatedRoute,
                private router: Router,
    ) { }

    ngOnInit(): void {
        this.accountId = +this.route.snapshot.paramMap.get('id');
        this.defaultTab = this.route.snapshot.queryParamMap.get('defaultTab') as AccountDetailsTab ?? this.defaultTab;

        this.accountDataChanged.pipe(
            startWith(true),
            switchMap(() => {
                return this.accountService.getAccountById(this.accountId);
            })
        ).subscribe((account) => {
            this.account = account;
            this.isVote = account.Tags.includes("Vote");
        });

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.isCommissionsVisible = this.userService.hasUserFeature(user, UserFeatureName.ForceAccountDetailToSalesView) ||
                    (user.Role.Name !== RoleName.Research && user.Team.Name !== TeamName.MarketMaker);

                this.isRvCVisible = this.userService.hasUserFeature(user, UserFeatureName.AccountYoYSummary);
                this.isEESVisible = this.userService.hasUserFeature(user, UserFeatureName.EES);
                this.isRevenueTarget = this.userService.hasUserFeature(user, UserFeatureName.RevenueTarget);
                this.isInvoiceBilling = this.userService.hasUserFeature(user, UserFeatureName.InvoiceBilling)
            });

        this.relatedAccount.valueChanges.subscribe(newAccountId => {
            this.router.navigate([AccountRoutePaths.AccountDetail, newAccountId]);
        });
    }

    focusContactResearch() {
        let contactsTab = this.accountDetailTabs.tabs.find(p => p.heading == "Contacts");
        contactsTab.active = true;
    }
}

export type AccountDetailsTab = "RvC" | "Summary" | "Contacts" | "Details" | "Profile" | "EES" | "Target" | "Billing";
