import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {BaseHttpService, FileAcceptType} from "../../Shared/Services/base-http.service";
import {requiredFileType} from "../../Widget/FileUpload/file-upload.component";
import {LoadingModalComponent} from "../Loading/loading-modal.component";

@Component({
    selector: "app-excel-upload",
    templateUrl: "./excel-upload.component.html"
})
export class ExcelUploadComponent implements OnInit {

    @Input()
    fileTitle: string;

    @Input()
    fileTemplatePath: string;

    @Input()
    fileUploadPath: string;

    errors: FileUploadError[] = [];

    dataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    form = this.fb.group({
       file: this.fb.control(null, [Validators.required, requiredFileType('xlsx')])
    });

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private baseHttpService: BaseHttpService,
                private toastr: ToastrService,
                private modalService: BsModalService) {}

    ngOnInit(): void {
    }

    save() {
        if (this.form.valid) {

            let formData = new FormData();
            formData.append('file', this.form.value.file, this.form.value.file.name);

            let loadingModal = this.modalService.show(LoadingModalComponent, {
                initialState: { message: `Saving ${this.fileTitle}` },
                animated: false,
                keyboard: false,
                backdrop: 'static'
            });

            this.baseHttpService.postData(this.fileUploadPath, formData)
                .subscribe(res => {
                    loadingModal.hide()
                    this.toastr.success("File Uploaded");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                },
                err => {
                    loadingModal.hide()
                    this.toastr.error("Error upload file");
                    this.form.reset();
                    this.errors = err;
                });
        }
    }

    downloadTemplate() {
        this.baseHttpService.getFileWithApiFileName(this.fileTemplatePath, FileAcceptType.Excel).subscribe();
    }

    close() {
        this.modalRef.hide();
    }
}

interface FileUploadError {
    Line: number;
    Messages: string[];
}
