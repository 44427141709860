<div class="page-content no-scroll flex-vbox">
    
    <app-page-banner
            pagetitle="Print Reports"
            faicon="fa-bar-chart"
            faiconcolor="#800080">
    </app-page-banner>

    <div class="page-body flex" style="padding-top: 20px;">
        <form [formGroup]="printReportForm">

            <div class="btn-group form-group"
                 btnRadioGroup
                 formControlName="reportName">
                <label class="btn btn-primary btn-toggle"
                       *ngFor="let report of reports"
                       btnRadio="{{report}}"
                       tabindex="0"
                       role="button">{{report}}</label>
            </div>
        
            <div class="form-group">
                <ng-template #contactsTitleTemplate>
                    <label >Contacts</label>
                </ng-template>
                
                <app-multi-contact-select formControlName="contactIds" [titleTemplate]="contactsTitleTemplate"></app-multi-contact-select>
            </div>
    
            <div class="form-group">
                <label>Accounts</label>
                <app-multi-account-select formControlName="accountIds"></app-multi-account-select>
            </div>
            
            <div class="form-group">
                <button class="btn btn-success" 
                        type="button"
                        (click)="generateReport()"
                        [disabled]="(printReportForm.value.contactIds.length === 0 && printReportForm.value.accountIds.length=== 0)"
                >Generate Report</button>
            </div>
        </form>
    </div>
</div>