import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ContactListsService} from "../../Contact/ContactLists/contact-lists.service";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {SavedContactList} from "../../Admin/ListGroups/saved-contact-list";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
    selector: "app-contact-list-open-modal",
    templateUrl: "./contact-list-open-modal.component.html"
})
export class ContactListOpenModalComponent extends BaseGridComponent<any> implements OnInit {
    @Output()
    contactListUpdated: EventEmitter<number> = new EventEmitter<number>();

    columnDefs = [
        {field: 'Name', headerName: 'Name', sort: 'asc', flex: 1 },
        //{field: 'Description', headerName: 'Description', flex: 1 },
        {field: 'Permission', headerName: 'Permission', width: 150, cellRenderer: this.renderPermission, tooltip: this.renderPermission },
        {field: 'PermissionGroup.Name', headerName: 'Group', width: 150 },
        {field: 'ModifiedBy', headerName: 'Mod By', width: 80 },
        {field: 'ModifiedDate', headerName: 'Mod Date', width: 100, cellRenderer: dateRenderer },
        {field: 'Size', headerName: 'Contacts', width: 100 },
    ];

    listSearchBox: UntypedFormControl = this.fb.control('');

    get rowSelected(): boolean {
        return this.gridApi?.getSelectedRows().length > 0;
    }

    constructor(public modalRef: BsModalRef,
                public modalService: BsModalService,
                private fb: UntypedFormBuilder,
                private contactListService: ContactListsService) {
        super();
        this.gridOptions.rowSelection = "single";
    }

    ngOnInit(): void {
        this.setRowData();
    }

    setRowData(): void {
        const allLists$ = this.contactListService.getAllLists();
        const formUpdate$ = this.listSearchBox.valueChanges.pipe(startWith(this.listSearchBox.value));

        this.rowData$ = combineLatest([allLists$, formUpdate$]).pipe(
            map(([lists, searchTerm]) => lists.filter(l => this.showList(l, searchTerm)))
        );
    }

    showList(list: SavedContactList, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();

        if(_.startsWith(list.Name.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (l: SavedContactList): boolean => {

            const fieldsContains = _.map([list.Name], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };

        return query(list);
    }

    renderPermission(params: any): string {
        return params.data.Permission === 'G' ? 'Group View'
            : params.data.Permission === 'E' ? 'Group View - Edit'
                : 'Private';
    }

    open(): void {
        const id = this.gridApi.getSelectedRows()[0]?.Id;
        this.contactListUpdated.emit(id);
        this.modalRef.hide();
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
