import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ListGroupsService} from "./list-groups.service";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {map, tap,} from "rxjs/operators";
import {SavedContactListGroup} from "./saved-contact-list-group";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../Shared/Models/user";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";

@Component({
    selector: "app-admin-list-groups",
    templateUrl: "./list-groups.component.html",
    encapsulation: ViewEncapsulation.None
})
export class ListGroupsComponent implements OnInit {

    listGroup: SavedContactListGroup;
    listGroups$: Observable<SavedContactListGroup[]>;
    users$: Observable<{Id: string, Label: string}[]>;
    listGroupsForm: UntypedFormGroup;
    listGroupForm: UntypedFormGroup;
    private modalRef: BsModalRef;
    formStatus: "new-item" | "submitting" | "clean";

    constructor(private listGroupsService: ListGroupsService,
                private userService: UserService,
                private fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private modalService: BsModalService) {
    }

    ngOnInit(): void {
        this.loadListGroups();
        this.listGroupsForm = this.fb.group({
            listGroupControl: this.fb.control(null)
        });

        this.listGroupForm = this.fb.group({
            groupName: this.fb.control('', [
                Validators.required,
                Validators.maxLength(30)]),
            users: this.fb.control('', []),
            clientListNames: this.fb.control({value: "", disabled: true}, []),
            queryNames: this.fb.control({value: "", disabled: true}, [])
        });

        this.listGroupsForm.get("listGroupControl").valueChanges
            .subscribe(x =>{
                this.listGroupForm.reset();
                this.setSelectedGroup(x);
            });

        this.users$ = this.userService.getUsers()
            .pipe(tap(results => results.sort(this.compareUser)))
            .pipe(map(results => results.filter(this.filterUser)))
            .pipe(map(results => results.map(r => this.userForSelection(r))));
    }

    private userForSelection(user: User): {Id: string, Label: string} {
        return {Id: user.Id, Label: `${user.LastName}, ${user.FirstName} (${user.Id})`}
    }

    private loadListGroups(): void {
        this.listGroups$ = this.listGroupsService.getAllGroups().pipe(
            tap(results => results.sort(this.compareListGroup))
        );
    }

    onAddListGroup(): void {
        this.listGroup = {Name: ""} as SavedContactListGroup;

        this.listGroupForm.reset();
        this.formStatus = "new-item";
    }

    setSelectedGroup(contactListGroup: SavedContactListGroup): void {
        if(!contactListGroup){
            return;
        }
        this.listGroup = contactListGroup;
        this.listGroupForm.setValue({
            groupName: contactListGroup.Name,
            users: contactListGroup.UserIds,
            clientListNames: contactListGroup.ClientListNames.join(", "),
            queryNames: contactListGroup.QueryNames.join(", ")});
        this.formStatus = "clean";
    }

    submit(): void {
        if(!this.listGroupForm.valid){
            this.validateAllFormFields(this.listGroupForm);
            return
        }

        this.listGroup.Name = this.listGroupForm.value.groupName;
        this.listGroup.UserIds = this.listGroupForm.value.users || [];
        this.listGroupsService.saveGroup(this.listGroup)
            .subscribe((updated: SavedContactListGroup) =>{
                this.loadListGroups();
                this.toastr.success(`Saved contact list group ${this.listGroup.Name}`);
            });
    }

    delete(): void {
        this.formStatus = "submitting";
        const initialState = {
            message: `Are you sure you want to delete the group "${this.listGroup.Name}"`,
        };
        if(this.canDelete) {
            this.modalRef = this.modalService.show(ConfirmModalComponent, {initialState});
        }
        this.modalRef.content.action
            .subscribe(value => {
                const success = () => {
                    this.toastr.success(`Deleted contact list group ${this.listGroup.Name}`);
                    this.listGroupForm.reset();
                    this.listGroup = undefined;
                    this.loadListGroups();
                };
                const error = (e) => {
                    this.toastr.error(`There was an error deleting the contact list group ${this.listGroup.Name}: ${e}`)
                };
                this.listGroupsService.deleteGroup(this.listGroup.Id)
                    .subscribe(success, error);
            });
    }

    compareListGroup = (lg1: SavedContactListGroup, lg2: SavedContactListGroup) => (lg1.Name || "").localeCompare(lg2.Name, undefined, {sensitivity: 'base'});
    compareUser = (u1: User, u2: User) => (u1.LastName || "").localeCompare(u2.LastName);
    filterUser = (u: User) => (u.FirstName && u.FirstName.length > 0) && (u.LastName && u.LastName.length > 0);

    get canDelete(): boolean { return this.listGroup && !(this.formStatus === "submitting" &&
        ((this.listGroup.ClientListNames && this.listGroup.ClientListNames.length > 0) ||
        (this.listGroup.QueryNames && this.listGroup.QueryNames.length > 0)))
    }
    validateAllFormFields(formGroup: UntypedFormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof UntypedFormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }
    isFieldValid(field: string) {
        return (!this.listGroupForm.get(field).valid && this.listGroupForm.get(field).touched);
    }
}
