import {Component, ElementRef, HostListener} from "@angular/core";
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: "app-file-upload",
    template: `
        <div>
            <span>Choose file </span>
            <span>{{file ? file.name : 'or drag and drop file here' }}</span>
            <input class="file-input" type="file">
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FileUploadComponent,
            multi: true
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {

    onChange: Function;
    file: File | null = null;

    @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
        const file = event && event.item(0);
        this.onChange(file);
        this.file = file;
    }

    constructor( private host: ElementRef<HTMLInputElement> ) {
    }

    writeValue( value: null ) {
        // clear file input
        this.host.nativeElement.value = '';
        this.file = null;
    }

    registerOnChange( fn: Function ) {
        this.onChange = fn;
    }

    registerOnTouched( fn: Function ) {
    }
}

export function requiredFileType( type: string ) {
    return function (control: UntypedFormControl) {
        const file = control.value;
        if ( file ) {
            const extension = file.name.split('.')[1].toLowerCase();
            if ( type.toLowerCase() !== extension.toLowerCase() ) {
                return {
                    requiredFileType: true
                };
            }

            return null;
        }

        return null;
    };
}
