import { CommonModule } from "@angular/common";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ModalModule} from "ngx-bootstrap/modal";
import {TabsModule} from "ngx-bootstrap/tabs";
import {CorpTravelAdminDashboardComponent} from "./Dashboard/corptravel-admin-dashboard.component";
import {NgModule} from "@angular/core";
import {WidgetModule} from "../Widget/widget.module";
import {ProviderListComponent} from "./Providers/provider-list.component";
import {AirportListComponent} from "./Airports/airport-list.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ProviderFormComponent} from "./Providers/provider-form.component";
import {AirportFormComponent} from "./Airports/airport-form.component"
import {TravelCityPickerComponent} from "./TravelCityPicker/travel-city-picker.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {TravelCityFormComponent} from "./Cities/travel-city-form.component";
import {TravelMarketPickerComponent} from "./Cities/travel-market-picker.component";
import {TravelCityListComponent} from "./Cities/travel-city-list.component";
import {TransportationProviderListComponent} from "./TransportationProviders/transportation-provider-list.component";
import {TransportationProviderFormComponent} from "./TransportationProviders/transportation-provider-form.component";
import {TransportationTypePickerComponent} from "./TransportationProviders/transportation-type-picker.component";
import {AirportTypePickerComponent} from "./Airports/airport-type-picker.component";
import {BairdRepAnalystListComponent} from "./BairdRepsAnalysts/baird-rep-analyst-list.component";
import {BairdRepAnalystFormComponent} from "./BairdRepsAnalysts/baird-rep-analyst-form.component";
import {BairdRepAnalystTypePickerComponent} from "./BairdRepsAnalysts/baird-rep-analyst-type-picker.component";
import {RouterModule} from "@angular/router"
import {corpTravelAdminRoutes} from "./corptravel-admin.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        WidgetModule,
        AgGridModule,
        ReactiveFormsModule,
        TabsModule,
        BsDropdownModule,
        NgSelectModule,
        FormsModule,
        NgOptionHighlightModule,
        RouterModule.forChild(corpTravelAdminRoutes),
    ],
    exports: [
        RouterModule,
    ],
    declarations: [
        CorpTravelAdminDashboardComponent,
        ProviderListComponent,
        AirportListComponent,
        ProviderFormComponent,
        TravelCityListComponent,
        TravelCityFormComponent,
        TransportationProviderListComponent,
        TransportationProviderFormComponent,
        AirportFormComponent,
        TravelCityPickerComponent,
        TravelMarketPickerComponent,
        TransportationTypePickerComponent,
        AirportTypePickerComponent,
        BairdRepAnalystListComponent,
        BairdRepAnalystFormComponent,
        BairdRepAnalystTypePickerComponent,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class CorpTravelAdminModule {
    constructor(){
        console.log("registering the CorpTravel Admin Module");
    }
}
