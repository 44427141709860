import {NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AgGridModule} from "ag-grid-angular-legacy";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {QueryListComponent} from "./QueryList/query-list.component";
import {QueryFormComponent} from "./QueryList/query-form.component";
import {RouterModule} from "@angular/router";
import {queryRoutes} from "./query.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        TabsModule,
        RouterModule.forChild(queryRoutes),
    ],
    declarations: [
        QueryListComponent,
        QueryFormComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class QueryModule {
    constructor(){
        console.log("registering the QueryModule");
    }
}
