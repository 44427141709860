import {Component, EventEmitter, OnInit, ViewChild} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {CommissionService} from "../../Shared/Services/commission.service";
import * as moment from "moment";
import * as _ from "lodash";
import {combineLatest, Observable} from "rxjs";
import {BsModalService} from "ngx-bootstrap/modal";
import {ReportService} from "../../Shared/Services/report.service";
import {UserService} from "../../Shared/Services/user.service";
import {ConfigService} from "../../Shared/Services/config.service";
import {RoleName} from "../../../Models/Role";
import {NonSplitTradeDetailRow} from "./non-split-trade-detail-grid.component";
import {SplitTradeDetailRow} from "./split-trade-detail-grid.component";
import { BackOfficeAcount } from "../../../Models/TradingAccountCommissionDetail";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: "app-reports-trade-detail",
    templateUrl: "./trade-detail.component.html"
})
export class TradeDetailComponent implements OnInit {

    tradeDetails$: Observable<TradeDetail[]>;

    nonSplitRowData: NonSplitTradeDetailRow[];
    splitRowData: SplitTradeDetailRow[];

    groupByParent: boolean = true;

    dataLoading: EventEmitter<any> = new EventEmitter<any>();

    filterForm = this.fb.group({
        myAccounts: this.fb.control(false),
        searchAccount: this.fb.control(''),
        searchTicker: this.fb.control([]),
        categories: this.fb.control([]),
        groupByParentToggle: this.fb.control(true)
    });

    dateRange: UntypedFormControl = this.fb.control({ start: moment(), end: moment() });
    groupByParentToggle: UntypedFormControl = this.fb.control(true);

    allCategories = [];
    dateRangeOptions: any;
    isCollapsed: boolean;

    pageTitle: string = 'Trade Detail';

    constructor(private fb: UntypedFormBuilder,
                private commissionService: CommissionService,
                private configService: ConfigService,
                private modalService: BsModalService,
                private userService: UserService,
                private reportService: ReportService,
                private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        const parentComdolId = this.route.snapshot.paramMap.get('parentComdolId');

        this.reportService.getLatestReleaseDate().subscribe(date => {
            this.dateRangeOptions = { maxDate: moment(date) };
            if (parentComdolId) {
                this.dateRange.setValue({ start: moment(date).startOf('year'), end: moment(date)});
                this.pageTitle = 'Parent ' + this.pageTitle;
            } else {
                this.dateRange.setValue({ start: moment(date), end: moment(date) });
            }
        });

        this.allCategories = this.commissionService.getCommissionCategories();

        this.userService.getCurrentUser().subscribe(user => {
            this.filterForm.patchValue({ myAccounts: (user.Role.Name === RoleName.Sales) || (user.Role.Name === RoleName.Traders) });

            if (this.configService.getBuyBackUsers().includes(user.Id)) {
                this.filterForm.patchValue({ categories: this.allCategories.map(c => c.code).filter(c => c !== 'S')});
            } else {
                this.filterForm.patchValue({ categories: this.allCategories.map(c => c.code)});
            }
        });

        this.tradeDetails$ = this.dateRange.valueChanges.pipe(
            startWith(this.dateRange.value),
            tap(() =>
                setTimeout(() => {
                    this.dataLoading.emit();
                }, 250)),
            switchMap(date => this.commissionService.getTradeDetailsByRange(date.start, date.end, parentComdolId ?? '')),
            map(commissions => {
                return _.chain(commissions)
                    .flatMap(a => _.map(a.Commissions, c => {
                        return {
                            AccountId: a.AccountId,
                            ComdolId: a.ComdolId,
                            ParentComdolId: a.ParentComdolId,
                            ParentAccountId: a.ParentAccountId,
                            Name: a.Name,
                            ParentName: a.ParentName ?? a.Name,
                            Location: _.join(_.filter([a.City, a.State, a.Country], (x => !_.isEmpty(x))), ', '),
                            PrimaryBroker: a.PrimaryBroker,
                            PrimaryTrader: a.PrimaryTrader,
                            My: a.Tags && a.Tags.includes("My"),
                            Ticker: c.Category !== 'E' ? c.Ticker : '',
                            TradeDate: c.TradeDate,
                            Side: c.Side,
                            Category: c.Category,
                            Price: c.Price,
                            Rate: c.Quantity > 0 ? c.Commission / c.Quantity : 0,
                            Quantity: c.Quantity,
                            Commission: c.Commission,
                            BackOfficeAccount: c.BackOfficeAccount
                        } as TradeDetail
                    }))
                    .value();
            })
        );

        let formChanges$ = this.filterForm.valueChanges.pipe(
            startWith(this.filterForm.getRawValue()),
            debounceTime(500)
        );

        combineLatest([formChanges$, this.tradeDetails$]).pipe(
            map(([formValues, tradeDetails]) => {
                tradeDetails = tradeDetails.filter(td => !formValues.myAccounts || td.My);
                tradeDetails =
                    tradeDetails.filter(td =>
                        !formValues.searchAccount ||
                        td.Name.toLowerCase().indexOf(formValues.searchAccount.toLowerCase()) > -1 ||
                        td.ParentName.toLowerCase().indexOf(formValues.searchAccount.toLowerCase()) > -1);
                tradeDetails = this.filterTickers(formValues.searchTicker, tradeDetails);
                tradeDetails = tradeDetails.filter(td => formValues.categories.indexOf(td.Category) > -1)
                return tradeDetails;
            })
        )
        .subscribe((trades: TradeDetail[]) => {

            this.splitRowData =_.chain(trades)
                .groupBy(t => `${t.AccountId} ${t.Ticker} ${t.TradeDate} ${t.Side} ${t.Category} ${t.Price}`)
                .map(t => {
                    let commission = _.sumBy(t, c => c.Commission);
                    let quantity = _.sumBy(t, c => c.Quantity);
                    return {
                        AccountId: _.head(t).AccountId,
                        ParentAccountId: _.head(t).ParentAccountId,
                        Name: `${_.head(t).Name} (${_.head(t).ComdolId})`,
                        ParentName: `${_.head(t).ParentName} (${_.head(t).ParentComdolId})`,
                        Location: _.head(t).Location,
                        PrimaryBroker: _.head(t).PrimaryBroker,
                        PrimaryTrader: _.head(t).PrimaryTrader,
                        Ticker: _.head(t).Ticker,
                        TradeDate: _.head(t).TradeDate,
                        Side: _.head(t).Side,
                        Category: _.head(t).Category,
                        My: _.head(t).My,
                        Commission: commission,
                        Price: _.meanBy(t, c => c.Price),
                        Quantity: quantity,
                        Rate: {Commission: commission, Quantity: quantity},
                        BackOfficeAccounts: this.backOfficeOverview(t, quantity)
                    } as SplitTradeDetailRow
                })
                .value();

            this.nonSplitRowData = _.chain(trades)
                .groupBy(t => `${t.ParentName} ${t.Ticker} ${t.TradeDate} ${t.Side} ${t.Category} ${t.Price}`)
                .map(t => {
                    let commission = _.sumBy(t, c => c.Commission);
                    let quantity = _.sumBy(t, c => c.Quantity);
                    return {
                        ParentAccountId: _.head(t).ParentAccountId,
                        ParentName: _.head(t).ParentName,
                        Ticker: _.head(t).Ticker,
                        TradeDate: _.head(t).TradeDate,
                        Side: _.head(t).Side,
                        Category: _.head(t).Category,
                        My: _.head(t).My,
                        Commission: commission,
                        Price: _.meanBy(t, c => c.Price),
                        Quantity: quantity,
                        Rate: { Commission: commission, Quantity: quantity },
                        BackOfficeAccounts: this.backOfficeOverview(t, quantity)
                    } as NonSplitTradeDetailRow
                })
                .value();

        });
    }

    private backOfficeOverview(trades: any[], totalQuantity: any): any[] {

        return _.chain(trades)
            .groupBy(trade => trade.BackOfficeAccount.Id)
            .map(groupTrades => {
                let quantity = _.sumBy(groupTrades, trade => trade.Quantity);
                return {
                    Id: _.head(groupTrades).BackOfficeAccount.Id,
                    Name: _.head(groupTrades).BackOfficeAccount.Name == '' ? _.head(groupTrades).BackOfficeAccount.Id : _.head(groupTrades).BackOfficeAccount.Name,
                    Quantity: quantity.toLocaleString(),
                    Percentage: (quantity / totalQuantity * 100).toFixed(2) + '%'
                };

            }).value();
    }

    filterTickers(value: string, data: any[]): any[] {
        const compare = _.chain(value).split(/(\,|\s+)/).map(m => m.toLowerCase()).reject(r => _.isEqual("", r)).value();

        return _.chain(data).filter((f: any) => {
            const right = f.Ticker.toLowerCase();

            return _.isNil(value) || _.isEmpty(value) ? true : _.includes(compare, right);
        }).value();
    }

    getSelectedCategories(): string {
        let selectedCategories = _.chain(this.allCategories)
            .filter(c => this.filterForm.get('categories').value.includes(c.code))
            .map(c => c.description)
            .value();
        return _.join(selectedCategories, ', ');
    }

    toggleGroupByParent(): void {
        this.groupByParent = !this.groupByParent;
    }

    collapseAll(): void {
        this.isCollapsed = !this.isCollapsed;
    }
}

interface TradeDetail {
    AccountId: number;
    ParentComdolId: string;
    ComdolId: string;
    Name: string;
    ParentAccountId: number | null;
    ParentName: string;
    Location: string;
    PrimaryBroker: string;
    PrimaryTrader: string;
    Status: string;
    Tier: string;
    Region: string;
    Market: string;
    My: boolean;
    Ticker: string,
    TradeDate: string,
    Side: string,
    Category: string,
    Price: number,
    Rate: number,
    Quantity: number,
    Commission: number,
    BackOfficeAccount: BackOfficeAcount
}

export interface BackOfficeAccount {
    Id: string;
    Name: string;
    Quantity: number;
    TotalQuantity: string;
}
