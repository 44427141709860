import {Component, OnInit} from "@angular/core";

@Component({
    selector: "app-report-selector",
    templateUrl: "./report-selector.component.html"
})
export class ReportSelectorComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }
}
