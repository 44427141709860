import { UserGroupsComponent  } from "./UserGroups/user-groups.component";
import { QueueMeetMaxEventsListComponent  } from "./QueueMeetMaxEvents/queue-meetmax-events-list.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {UserRenameComponent} from "./UserRename/user-rename.component";
import {UserRenameModalService} from "./UserRename/user-rename-modal.service";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {ModalModule} from "ngx-bootstrap/modal";
import {TabsModule} from "ngx-bootstrap/tabs";
import { ListGroupsComponent } from './ListGroups/list-groups.component';
import { MifidAccountsComponent } from './MifidAccounts/mifid-accounts.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {AgGridModule} from "ag-grid-angular-legacy";
import {MifidAccountFormComponent} from "./MifidAccounts/mifid-account-form.component";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {AdminDashboardComponent} from "./Dashboard/admin-dashboard.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {UserFeaturesComponent} from "./UserFeatures/user-features.component";
import {AdminUserListComponent} from "./Users/admin-user-list.component";
import {UserFormComponent} from "./Users/user-form.component";
import {UserTeamsListComponent} from "./UserTeams/user-team-list.component";
import {UserTeamFormComponent} from "./UserTeams/user-team-form.component";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {adminRoutes} from "./admin.routes";
import {CorpTravelUserRolePickerComponent} from "./Users/corp-travel-user-role-picker.component";
import {CorpTravelUserTypePickerComponent} from "./Users/corp-travel-user-type-picker.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        NgSelectModule,
        NgOptionHighlightModule,
        ModalModule.forRoot(),
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
        ButtonsModule,
        TabsModule,
        RouterModule.forChild(adminRoutes),
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        UserGroupsComponent,
        QueueMeetMaxEventsListComponent,
        UserRenameComponent,
        ListGroupsComponent,
        MifidAccountsComponent,
        MifidAccountFormComponent,
        AdminDashboardComponent,
        UserFeaturesComponent,
        AdminUserListComponent,
        UserFormComponent,
        UserTeamsListComponent,
        UserTeamFormComponent,
        CorpTravelUserRolePickerComponent,
        CorpTravelUserTypePickerComponent,
    ],
    providers: [
        UserRenameModalService,
        UserCanActivateRoute
    ]
})
export class AdminModule {
    constructor(){
        console.log("registering the AdminModule");
    }
}
