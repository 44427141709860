import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map, tap} from "rxjs/operators";
import {UserService} from "../../../Shared/Services/user.service";

@Component({
    selector: "app-broker-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(BrokerPickerComponent)
})
export class BrokerPickerComponent extends BasePickerComponent<any> {

    @Input()
    setMaxItems: number;
    
    @Input()
    multipleSelect: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    showTagCount: boolean = true;

    @Input()
    closeAfterSelection: boolean = false;

    @Input()
    placeHolderLabel: string = "";

    @Input()
    alignRight: boolean = false;
    
    @Input()
    includeInactive: boolean = true;

    @Input()
    defaultEmptyStringId: string = "NoBrokerSelected";

    constructor(fb: UntypedFormBuilder, private userService: UserService){
        super(fb);

        this.name = "Broker(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }
    
    ngOnInit() {
        this.multiple = this.multipleSelect;
        this.showSelectClearAll = this.selectClearAll;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = true;
        this.searchable = false;
        
        super.ngOnInit();
        this.customOnChange = (data: any) => {
            if(data == "NoBrokerSelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }

        this.items$ = this.userService.getBrokers().pipe(
            map(users => users.filter(u => u.IsActive || this.includeInactive)),
            map(users => _.sortBy(users, m => m.LastName)),
            map(users => _.map(users, user => { return { Id: user.Id , Name: `${user.LastName} (${user.Id})` } })),
            map(users => {
                users.unshift({Id: this.defaultEmptyStringId, Name: " "});
                return users;
            })
        );
    }
}
