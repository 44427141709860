<style>
    .ng-invalid > .btn{
        border-color: red;
    }
</style>

<div class="modal-content">
    <div
        class="modal-body"
        [ngClass]="contactMoved ? 'modal-contact-moved' : ''"
    >
        
        <div
            *ngIf="canCopyActivity"
            appRequiresFeature="activity-form-copy"
        >
            <div
                class="btn btn-xs btn-primary pull-right"
                (click)="copyActivity()"
            >
                Copy
            </div>
        </div>
        
        <div style="clear: both;"></div>

        <app-activity-form-fieldset
            [formControl]="activity"
            [accountId]="accountId"
        >
        </app-activity-form-fieldset>
        
        <div>
            <label>
                Created By
            </label>

            <input
                class="form-control input-sm"
                [formControl]="createdBy"
            />
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox">
            <button
                class="btn btn-sm btn-primary flex"
                (click)="save(false)"
                [disabled]="!formValid() || saving || loading"
            >
                Save
            </button>

            <button
                class="btn btn-sm btn-primary flex"
                (click)="save(true)"
                [disabled]="!formValid() || saving || loading"
            >
                Save and Send Email
            </button>

            <button
                class="btn btn-sm btn-danger flex"
                (click)="delete()"
                [disabled]="disabled || saving || loading"
                *ngIf="activityId"
            >
                Delete
            </button>

            <button
                class="btn btn-sm btn-default flex"
                (click)="close()"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
