import * as moment from "moment";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root',
})

export class ChecksReportService
{
    constructor(private baseHttp: BaseHttpService) {
    }

    getCommissionChecks(year: number): Observable<AccountChecks[]>{
        return this.baseHttp.getData(`/commission/checks/${year}`);
    }
}

export class AccountChecks {
    Name: string;
    City: string;
    Country: string;
    PrimaryBroker: string;
    PrimaryTrader: string;
    Id: number
    ComdolId: string;
    ParentComdolId: string;
    ParentName: string;
    Status: string;
    My: boolean;
    Checks: AccountCheck[];
}

export class AccountCheck {
    TradeDate: moment.Moment;
    TradeCommission: number;
    IsReceived: boolean;
    Description: string;
}