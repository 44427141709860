import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {ConfigService} from "../../Shared/Services/config.service";

@Directive({
    selector: '[appRequiresFeature]'
})
export class RequiresFeatureDirective implements OnInit {

    @Input() appRequiresFeature: string;
    
    constructor(private elementRef: ElementRef, private configService: ConfigService) { }

    ngOnInit(): void {
        if(!this.configService.getAppFeatures().includes(this.appRequiresFeature)) {
            this.elementRef.nativeElement.style.display = 'none';
        } else {
            //this.elementRef.nativeElement.style.display = '';
        }
    }
}

@Directive({
    selector: '[appRequiresNotFeature]'
})
export class RequiresNotFeatureDirective implements OnInit {

    @Input() appRequiresNotFeature: string;

    constructor(private elementRef: ElementRef, private configService: ConfigService) { }

    ngOnInit(): void {
        if(this.configService.getAppFeatures().includes(this.appRequiresNotFeature)) {
            this.elementRef.nativeElement.style.display = 'none';
        } else {
            //this.elementRef.nativeElement.style.display = '';
        }
    }
}