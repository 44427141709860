import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {combineLatest, forkJoin, Observable, of} from "rxjs";
import {AccountService} from "../../Shared/Services/account.service";
import {TickerService} from "../../Shared/Services/ticker.service";
import {UserService} from "../../Shared/Services/user.service";
import {QueryGroup} from "../../../Services/QueryService";
import {ListService} from "../../Shared/Services/list.service";

@Component({
    selector: "app-filter-selection-display",
    templateUrl: "./filter-selection-display.component.html",
    styleUrls: ["./filter-selection-display.component.scss"]
})
export class FilterSelectionDisplayComponent implements OnInit {
    @Input()
    formGroup: UntypedFormGroup;

    filterDisplay$: Observable<InterestListFilterDisplay>;
    regions: Map<number, string>;
    sectors: Map<number, string>;
    analysts: Map<string, string>;
    markets: Map<number, string>;

    requiredFiltersMessage: boolean = false;

    constructor(
        private accountService: AccountService,
        private tickerService: TickerService,
        private userService: UserService,
        private listService: ListService
    ) {}

    ngOnInit(): void {
        combineLatest([
            this.accountService.getAccountRegions(),
            this.tickerService.getInstiselSectors(),
            this.userService.getAnalysts(),
            this.accountService.getAccountMarkets()
        ])
            .pipe(
                tap(([regions, sectors, analysts, markets]) => {
                    this.regions = new Map(regions.map(r => [r.Id, r.Name]));

                    this.sectors = new Map(sectors.map(s => [
                        s.Id,
                        s.ParentInstiselSector ?
                            `${s.ParentInstiselSector.LongName} > ${s.LongName}` :
                            `${s.LongName}`
                    ]));

                    this.analysts = new Map(analysts.map(a => [a.Id, a.LastName]));
                    this.markets = new Map(markets.map(m => [m.Id, m.Name]));
                })
            ).subscribe(() => {
            this.mapFilters();
        });
    }

    private mapFilters(): void {
        this.filterDisplay$ = this.formGroup.valueChanges.pipe(
            startWith(this.formGroup.getRawValue()),
            switchMap(formValues => {
                const listId = formValues.savedContactListId;
                return forkJoin([
                    of(formValues),
                    listId ? this.listService.getListById(listId) : of(null)
                ])
            }),
            map(([formValues, list]) => {
                const getFilterValues = this.getFilterValuesFromQueryGroup(formValues.moreFilters);
                const selectedRegions = getFilterValues("Region");
                const selectedMarkets = getFilterValues("Market");
                const selectedPrBrs = getFilterValues("Primary Broker");
                const selectedPersBrs = getFilterValues("Personal Broker");
                const selectedExclude = getFilterValues("Contact Groups");
                const selectedMarketingEmails = getFilterValues("Marketing Email");
                const selectedResearchVms = getFilterValues("Research Voicemail");
                const selectedResearchEmails = getFilterValues("Research Email");
                const selectedBlueMatrix = getFilterValues("BlueMatrix");
                const selectedGlobalInvestor = getFilterValues("Global Investor");
                const selectedPrivateInvestment = getFilterValues("Private Investment");
                const selectedInvestorTypes = getFilterValues("Investor Type");
                const selectedInvestorStrategies = getFilterValues("Investor Strategy");
                const selectedInvestorStyles = getFilterValues("Investor Style");
                const selectedRoles = getFilterValues("Roles");
                const selectedDealPlayers = getFilterValues("Deal Player");
                const selectedOrientations = getFilterValues("Orientation");
                const selectedAum = getFilterValues("AUM");
                const selectedEquityAum = getFilterValues("AUM Equity");
                const selectedMarketCap = getFilterValues("Market Cap Low") ?? getFilterValues("Market Cap High");
                const selectedActivityWithinDays = formValues.searchBy !== 'Holding' ? getFilterValues("Call Within Days") : '';
                const selectedTickerCoverageTeams = getFilterValues("Ticker Coverage Team");
                const selectedParentComdolIdFilters = getFilterValues("Parent Comdol ID");
                const selectedAccountIds = getFilterValues("Instnum");
                return {
                    Grid: formValues.grid,
                    MyAccounts: formValues.myAccounts,
                    Favorites: formValues.favorites,
                    ContactSearch: formValues.contactSearch,
                    List: list?.Name,
                    Tiers: formValues.tiers.join(", "),
                    Analysts: this.getMappedDisplayString(formValues.analysts, this.analysts),
                    Tickers: formValues.tickers.join(", "),
                    Sectors: this.getMappedDisplayString(formValues.instiselSectors, this.sectors),
                    Categories: formValues.categories.join(", "),
                    Regions: this.getMappedDisplayString(selectedRegions, this.regions),
                    Markets: this.getMappedDisplayString(selectedMarkets, this.markets),
                    PrimaryBroker: selectedPrBrs?.join(", "),
                    PersonalBroker: selectedPersBrs?.join(", "),
                    Exclude: selectedExclude?.join(", "),
                    MarketingEmails: this.getYesNoFilterDisplayString(selectedMarketingEmails),
                    ResearchVms: this.getYesNoFilterDisplayString(selectedResearchVms),
                    ResearchEmails: this.getYesNoFilterDisplayString(selectedResearchEmails),
                    BlueMatrix: this.getYesNoFilterDisplayString(selectedBlueMatrix),
                    GlobalInvestor: this.getYesNoFilterDisplayString(selectedGlobalInvestor),
                    PrivateInvestment: this.getYesNoFilterDisplayString(selectedPrivateInvestment),
                    InvestorTypes: selectedInvestorTypes?.join(", "),
                    InvestorStrategies: selectedInvestorStrategies?.join(", "),
                    InvestorStyles: selectedInvestorStyles?.join(", "),
                    Roles: selectedRoles?.join(", "),
                    DealPlayers: selectedDealPlayers?.join(", "),
                    Orientations: selectedOrientations?.join(", "),
                    AUM: !!selectedAum,
                    EquityAUM: !!selectedEquityAum,
                    MarketCap: selectedMarketCap ? "Active" : null,
                    ActivityWithinDays: selectedActivityWithinDays,
                    MoreFilters: formValues.moreFilters,
                    TickerCoverageTeam: selectedTickerCoverageTeams,
                    HasOtherFilters: (selectedParentComdolIdFilters && selectedParentComdolIdFilters.length > 0)
                        || (selectedAccountIds && selectedAccountIds.length > 0)
                        || (selectedTickerCoverageTeams && selectedTickerCoverageTeams.length > 0)
                } as InterestListFilterDisplay;
            }),
            tap(filters => {
                this.requiredFiltersMessage = !filters.Favorites
                    && !filters.Grid
                    && !filters.MyAccounts
                    && !filters.ContactSearch
                    && !filters.List
                    && (!filters.Tickers || filters.Tickers.length === 0)
                    && (!filters.Analysts || filters.Analysts.length === 0)
                    && (!filters.Sectors || filters.Sectors.length === 0)
                    && (!filters.Categories || filters.Categories.length === 0)
                    && (!filters.BlueMatrix || filters.BlueMatrix.length === 0)
                    && (!filters.MarketingEmails || filters.MarketingEmails.length === 0)
                    && (!filters.ResearchVms || filters.ResearchVms.length === 0)
                    && (!filters.ResearchEmails || filters.ResearchEmails.length === 0)
                    && (!filters.Tiers || filters.Tiers.length === 0)
                    && (!filters.Regions || filters.Regions.length === 0)
                    && (!filters.Markets || filters.Markets.length === 0)
                    && (!filters.PrimaryBroker || filters.PrimaryBroker.length === 0)
                    && (!filters.PersonalBroker || filters.PersonalBroker.length === 0)
                    && (!filters.InvestorTypes || filters.InvestorTypes.length === 0)
                    && (!filters.InvestorStrategies || filters.InvestorStrategies.length === 0)
                    && (!filters.Roles || filters.Roles.length === 0)
                    && (!filters.InvestorStyles || filters.InvestorStyles.length === 0)
                    && (!filters.GlobalInvestor || filters.GlobalInvestor.length === 0)
                    && (!filters.Orientations || filters.Orientations.length === 0)
                    && (!filters.PrivateInvestment || filters.PrivateInvestment.length === 0)
                    && (!filters.DealPlayers || filters.DealPlayers.length === 0)
                    && !filters.AUM
                    && !filters.EquityAUM
                    && !filters.MarketCap
                    && (!filters.MoreFilters || filters.MoreFilters.length === 0);
            })
        );
    }

    private getMappedDisplayString<T>(values: T[], valueMap: Map<T, string>): string {
        return values?.length > 0 ? values.map(v => valueMap.get(v)).join(", ") : "";
    }

    private getFilterValuesFromQueryGroup(queryGroups: QueryGroup[]): (field: string) => any {
        return (field: string) => {
            const query = queryGroups.find(f => f.Queries.some(q => q.Field === field))?.Queries[0];
            return query ? query.Values ?? query.Value : null;
        };
    }

    private getYesNoFilterDisplayString(values: string | string[]): string {
        if (!values) return null;

        if (values instanceof Array) {
            const display = [];
            values.forEach(v => {
                if (v === "true" || v === 'Y') {
                    display.push("Yes")
                } else if (v === "false" || v === 'N') {
                    display.push("No")
                }
            });
            return display.join(", ");
        }

        return values === "true" ? "Yes" : "No";
    }
}

export interface InterestListFilterDisplay {
    Grid: boolean;
    MyAccounts: string;
    Favorites: string;
    ContactSearch: string;
    List: string;
    Tiers: string;
    Analysts: string;
    Tickers: string;
    Sectors: string;
    Categories: string;
    Regions: string;
    Markets: string;
    PrimaryBroker: string;
    PersonalBroker: string;
    Exclude: string;
    MarketingEmails: string;
    ResearchVms: string;
    ResearchEmails: string;
    BlueMatrix: string;
    GlobalInvestor: string;
    PrivateInvestment: string;
    InvestorTypes: string;
    InvestorStrategies: string;
    InvestorStyles: string;
    Roles: string;
    DealPlayers: string;
    Orientations: string;
    AUM: boolean;
    EquityAUM: boolean;
    MarketCap: string;
    ActivityWithinDays: string | number;
    MoreFilters: QueryGroup[];
    HasOtherFilters: boolean;
}
