import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import * as moment from "moment";

@Injectable({
    providedIn: 'root',
})
export class SharedEmailService {

    constructor(private baseHttp: BaseHttpService) {
    }
    
    searchSharedEmails(startDate: moment.Moment, endDate:moment.Moment): Observable<SharedEmail[]>{
        let config = {
            params: {
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD")
            }
        };

        return this.baseHttp.getDataWithConfig(`/sharedemail/search`, config);
    }

    removeSharedEmail(id: number): Observable<boolean> {
        return this.baseHttp.deleteData(`/sharedemail/id/${id}`);
    }

    updateSharedEmail(updatedSharedEmail: SharedEmail): Observable<SharedEmail>{
        return this.baseHttp.postData(`/sharedemail/id/${updatedSharedEmail.Id}`, updatedSharedEmail);
    }

    getMostRecentSharedEmail(): Observable<SharedEmail> {
        return this.baseHttp.getData<SharedEmail>(`/sharedemail/recent`);
    }

}

export interface SharedEmail {
    Id: number;
    Subject: string;
    Sender: string;
    SenderId: string;
    DateTimeSent: string | moment.Moment;
    Type: string;
    IsInternalUseOnly: boolean;
}