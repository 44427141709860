import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {UserService} from "../../../Shared/Services/user.service";
import {User} from '../../../../Models/User';
import {RoleName} from '../../../../Models/Role';

@Component({
    selector: "app-team-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(TeamPickerComponent)
})
export class TeamPickerComponent extends BasePickerComponent<any> {

    @Input()
    showResearchOnly: boolean = false;

    @Input()
    showSalesTeamsOnly: boolean = false;

    @Input()
    includeInActive: boolean = false;

    @Input()
    alignRight: boolean = false;

    @Input()
    selectAllByDefault: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    placeHolder = "0 Team(s)";

    constructor(fb: UntypedFormBuilder, private userService: UserService) {
        super(fb);

        this.name = "Team(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.customSearchable = true;
    }

    ngOnInit() {
        super.ngOnInit();

        if (!this.multiple) {
            this.showSelectClearAll = false;
            this.closeOnSelect = true;
        }

        let userTeams$ = this.userService.getUserTeams(this.showSalesTeamsOnly);

        let currentUser$ = this.userService.getCurrentUser();

        this.items$ = combineLatest([userTeams$, currentUser$]).pipe(
            map(([teams, user]) => _.chain(teams)
                .filter(t => {
                    return t.IsActive || this.includeInActive;
                })
                .filter(t => {
                    return !this.showResearchOnly || t.RoleName === RoleName.Research;
                })
                .map(t => {
                    let highlightDefault = this.shouldHighlightDefault(t, user);
                    let teamName = this.showResearchOnly
                        ? t.TeamName
                        : this.userService.getTeamName(t.RoleName, t.TeamName);
                    if (!t.IsActive) {
                        teamName += " (Inactive)";
                    }
                    return {
                        Id: t.TeamId,
                        Name: highlightDefault ? `** ${teamName} **` : teamName,
                        IsDefaultTeam: highlightDefault,
                    }
                })
                .orderBy(['IsDefaultTeam', 'Name'], ['desc', 'asc'])
                .value()
            )
        );

        if (this.selectAllByDefault) {
            this.onSelectAll();
        }
    }

    private shouldHighlightDefault(analyst: any, user: User): Boolean {
        const teamName = _.first(user.Team.Name.split(","));
        return analyst.TeamId === user.Team.Id && this.isUserOnRealTeam(teamName, user) && this.isUserPartOfResearch(user);
    }

    private isUserOnRealTeam(teamName: string, user: User) {
        return teamName !== user.Role.Name;
    }

    private isUserPartOfResearch(user: User) {
        return user.Role.Name === RoleName.Research;
    }
}
