import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";
import {from} from "rxjs";

@Component({
    selector: "app-baird-rep-analyst-type-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(BairdRepAnalystTypePickerComponent)
})
export class BairdRepAnalystTypePickerComponent extends BasePickerComponent<any> {

    @Input()
    setMaxItems: number;

    @Input()
    multipleSelect: boolean = false;

    @Input()
    selectClearAll: boolean = false;

    @Input()
    showTagCount: boolean = false;

    @Input()
    closeAfterSelection: boolean = false;

    @Input()
    includeNullOption: boolean = false;

    @Input()
    placeHolderLabel: string = "Type";

    constructor(
        fb: UntypedFormBuilder,
    ) {
        super(fb);

        this.name = "Type";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }

    ngOnInit() {
        this.multiple = this.multipleSelect;
        this.showSelectClearAll = this.selectClearAll;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = true;
        this.searchable = false;

        super.ngOnInit();
        this.customOnChange = (data: any) => {
            if(data == "NoTypeSelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }

        this.items$ = from([["", "Rep", "Analyst"]])
    }
}
