export class ActivityCategory {
    public Code: string;
    public Description: string;
    public UserHasAccess: boolean;

    constructor(code: string, description: string) {
        this.Code = code;
        this.Description = description;
    }
}


export enum ActivityCategoryCode {
    AnalystOutreach = "G",
    AnalystVisits = "A",
    BlastVM = "W",
    BusinessUpdate = "B",
    ClientEntertainment = "J",
    CompanyVisits = "V",
    ConferenceCalls = "N",
    Conferences = "C",
    CorpAccessException = "0",
    CorpAccessOutreach = "M",
    Deals = "R",
    Email = "E",
    EmailInboundExchange = "X",
    EventRegistration = "-",
    ExcelModel = "D",
    FieldTrips = "I",
    FollowUp = "F",
    Ideas = "Z",
    NDR = "K",
    PhoneInPerson = "P",
    PrincipalTraderOutreach = "Q",
    StockMaintGeneral = "H",
    VoiceMessage = "S",
}
