import {Component} from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {ICellRendererParams} from "ag-grid-community";

@Component({
    selector: 'ag-grid-commission-account',
    template: `
        <div class="text-right" container="body" placement="right">{{ value }} <i class="fa" [ngClass]="iconClass"></i></div>
     `
})
export class PercentageRenderer implements ICellRendererAngularComp {
    value: number;
    iconClass: string;

    agInit(params: ICellRendererParams): void {
        this.value = params.value;
        this.iconClass = params.value.iconClass;
    }

    refresh(): boolean {
        return true;
    }
}