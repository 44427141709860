import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { User } from "../../../Models/User";
import { ActivityEmailSpecificationDto, ActivityService } from "../../Shared/Services/activity.service";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-confirm-modal",
    templateUrl: "./activity-email-form.component.html"
})
export class ActivityEmailFormComponent implements OnInit {

    @Input()
    activityId: number;

    @Input()
    clientEntertainmentActivityComment?: string;

    @Input()
    excelModelTickers?: string[];

    @Input()
    contactIds: number[];

    @Output()
    action = new EventEmitter<boolean>();

    form: UntypedFormGroup = this.fb.group({
        toUserIds: this.fb.control([]),
        ccUserIds: this.fb.control([]),
    })

    previewBody: SafeHtml;
    users: User[]

    sendingEmail: boolean = false;
    formLoading: boolean = false;

    constructor(
        public modalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private sanitizer: DomSanitizer,
        private activityService: ActivityService,
        private toastrService: ToastrService,
    ) { }

    ngOnInit(): void {
        this.formLoading = true;

        const activityEmailSpecificationDto: ActivityEmailSpecificationDto = {
            ToUserIds: null,
            CcUserIds: null,
            ClientEntertainmentActivityComment: this.clientEntertainmentActivityComment,
            ExcelModelTickers: this.excelModelTickers ?? [],
            ContactIds: this.contactIds
        };

        this.activityService.getActivityEmailPreview(
            this.activityId,
            activityEmailSpecificationDto
        )
            .subscribe(preview => {

                this.previewBody = this.sanitizer.bypassSecurityTrustHtml(preview.Body);

                this.form.patchValue({
                    ccUserIds: preview.CcUserIds,
                    toUserIds: preview.ToUserIds,
                });

                this.formLoading = false;
            });
    }

    sendEmail() {
        this.sendingEmail = true;

        this.toastrService.success("Sending Email...");

        const activityEmailSpecificationDto: ActivityEmailSpecificationDto = {
            ToUserIds: this.form.value.toUserIds,
            CcUserIds: this.form.value.ccUserIds,
            ClientEntertainmentActivityComment: this.clientEntertainmentActivityComment,
            ExcelModelTickers: this.excelModelTickers ?? [],
            ContactIds: this.contactIds
        };

        this.activityService
            .sendActivityEmail(this.activityId, activityEmailSpecificationDto)
            .subscribe();

        this.modalRef.hide();
        this.action.emit(true);
        this.sendingEmail = false;
    }

    dontSendEmail() {
        this.modalRef.hide();
        this.action.emit(false);
    }
}
