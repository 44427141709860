import {Routes} from "@angular/router";
import {AccountActivityComponent} from "./AccountActivity/account-activity.component";
import {ChecksReportComponent} from "./ChecksReport/checks-report.component";
import {CommissionSplitsComponent} from "./CommissionSplits/commission-splits.component";
import {CommissionTotalsComponent} from "./CommissionTotals/commission-totals.component";
import {AccountSummaryByYearComponent} from "./AccountSummaryByYear/account-summary-by-year.component";
import {TradeDetailComponent} from "./TradeDetail/trade-detail.component";
import {YearOverYearCommissionComponent} from "./YearOverYearCommission/year-over-year-commission.component";
import {PrintReportsComponent} from "./PrintReports/print-reports.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {MsalGuard} from "@azure/msal-angular";
import {ReportRoutePaths} from "./report-route-paths";

export const reportRoutes: Routes = [
    {
        path: ReportRoutePaths.BasePath,
        canActivate: [UserCanActivateRoute, MsalGuard],
        canActivateChild: [MsalGuard],
        children: [
            {
                path: ReportRoutePaths.AccountActivityReportPathFragment,
                component: AccountActivityComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Account Activity Report",
                    requiredFeatures: [UserFeatureName.CommissionTradeDetail]
                },
            },
            {
                path: ReportRoutePaths.ChecksReportPathFragment,
                component: ChecksReportComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Check Report",
                    requiredFeatures: [UserFeatureName.CheckReport]
                },
            },
            {
                path: ReportRoutePaths.CommissionSplitsReportPathFragment,
                component: CommissionSplitsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Commission Splits Report",
                    requiredFeatures: [UserFeatureName.CommissionSplitReport]
                },
            },
            {
                path: ReportRoutePaths.CommissionTotalsReportPathFragment,
                component: CommissionTotalsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Commission Totals Report",
                    requiredFeatures: [UserFeatureName.CanSeeCommisionsReport]
                },
            },
            {
                path: ReportRoutePaths.AccountSummaryByYearReportPathFragment,
                component: AccountSummaryByYearComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Account Summary By Year Report",
                    requiredFeatures: [UserFeatureName.Commission5YrAccountSummaryReport]
                },
            },
            {
                path: ReportRoutePaths.PrintReportsPathFragment,
                component: PrintReportsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Print Report" },
            },
            {
                path: ReportRoutePaths.TradeDetailReportPathFragment,
                component: TradeDetailComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Trade Detail Report",
                    requiredFeatures: [UserFeatureName.CommissionTradeDetail]
                },
            },
            {
                path: `${ReportRoutePaths.ParentTradeDetailReportPathFragment}/:parentComdolId`,
                component: TradeDetailComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Parent Trade Detail Report",
                    requiredFeatures: [UserFeatureName.CommissionTradeDetail]
                },
            },
            {
                path: ReportRoutePaths.YearOverYearCommissionReportPathFragment,
                component: YearOverYearCommissionComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Year Over Year Report",
                    requiredFeatures: [UserFeatureName.CommissionYOYReport]
                },
            },
            {
                path: `${ReportRoutePaths.ParentYearOverYearCommissionReportPathFragment}/:id`,
                component: YearOverYearCommissionComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Parent Year Over Year Report",
                    requiredFeatures: [UserFeatureName.CommissionYOYReport]
                },
            },
        ],
    },
];
