import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {TravelCityService} from "../Services/travel-city.service";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";

@Component({
    selector: "app-travel-city-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(TravelCityPickerComponent)
})
export class TravelCityPickerComponent extends BasePickerComponent<any> {

    @Input()
    setMaxItems: number;

    @Input()
    multipleSelect: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    showTagCount: boolean = true;

    @Input()
    closeAfterSelection: boolean = false;
    
    @Input()
    includeNullOption: boolean = false;

    @Input()
    placeHolderLabel: string = "";

    constructor(
        fb: UntypedFormBuilder,
        private travelCityService: TravelCityService
    ) {
        super(fb);

        this.name = "City(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }

    ngOnInit() {
        this.multiple = this.multipleSelect;
        this.showSelectClearAll = this.selectClearAll;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = true;
        this.searchable = false;

        super.ngOnInit();
        this.customOnChange = (data: any) => {
            if(data == "NoTravelCitySelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }

        this.items$ = this.travelCityService.getTravelCities(true).pipe(
            map(travelCities => _.sortBy(travelCities, m => m.Name)),
            map(travelCities => {
                if (this.includeNullOption) {
                    travelCities.unshift({Id: null, Name: "", TerritoryId: null, MarketId: null, Deleted: ""});
                }
                travelCities.map(cities => {
                    if(cities.Deleted == "1") {
                        cities.Name = cities.Name + " (inactive)"
                    }
                })
                return travelCities;
            }),
        );
    }
}
