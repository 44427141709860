import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {AccountService} from "../../../Shared/Services/account.service";
import {map} from "rxjs/operators";

@Component({
    selector: "app-related-account-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(RelatedAccountPickerComponent)
})
export class RelatedAccountPickerComponent extends BasePickerComponent<any> implements OnInit {
    
    @Input()
    accountId: number
    
    constructor(fb: UntypedFormBuilder, private accountService: AccountService){
        super(fb);

        this.multiple = false;
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.showSelectClearAll = false;
        this.placeHolder = "Select Related Account";
        this.ngStyle = {width: '400px'};
    }

    ngOnInit() {
        super.ngOnInit();
        this.items$ = this.accountService.getAccountSubAccounts(this.accountId).pipe(
            map(accounts => accounts.filter(a => a.Id !== this.accountId)),
            map(accounts => accounts.map(a => {
                return {
                    Id: a.Id,
                    Name: `${a.Name} - ${a.City}, ${a.State} ${a.Country}`
                }
            }))
        );
    }
}
