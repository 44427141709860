import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {UserGroup} from "./user-group";
import {User} from "../../Shared/Models/user";

@Injectable({
    providedIn: 'root',
})
export class UserGroupService {

    constructor(private baseHttpService: BaseHttpService) { }

    getUserGroups(): Observable<UserGroup[]> {
        return this.baseHttpService.getData(`/usergroups`);
    }

    getUserGroup(userGroupId: number): Observable<UserGroup> {
        return this.baseHttpService.getData(`/usergroup/${userGroupId}`);
    }
    
    getAllUserGroupRepCodes(): Observable<string[]> {
        return this.baseHttpService.getData(`/usergrouprepcodes`);
    }
    
    getUserGroupRepCodes(userGroupId: number): Observable<any> {
        return this.baseHttpService.getData(`/usergroup/${userGroupId}/repcodes`);
    }
    
    getUserGroupUsers(userGroupId: number): Observable<User[]> {
        return this.baseHttpService.getData(`/usergroup/${userGroupId}/users`);
    }
    
    updateUserGroup(userGroup: UserGroup): Observable<UserGroup> {
        return this.baseHttpService.postData(`/usergroup`, userGroup);
    }
    
    deleteUserGroup(userGroupId: number): Observable<any> {
        return this.baseHttpService.deleteData(`/usergroup/${userGroupId}`);
    }
}
