<ng-select [items]="companies"
           [formControl]="companiesControl"
           bindLabel="SearchName"
           placeholder="Select a company"
           appendTo="body"
           [multiple]="true"
           [maxSelectedItems]="maxItems"
           [selectOnTab]="true"
           [hideSelected]="true">
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{item.Name}} ({{item.Ticker}})</span>
    </ng-template>
</ng-select>