<div class="interest-list-tab flex flex-vbox">
    <div class="header">
        <form [formGroup]="form">
            <div class="flex-hbox flex-gap" formGroupName="holdings">
                <button class="btn btn-sm btn-default" 
                        type="button"
                       [disabled]="!contactId && !accountId"
                       (click)="isMultiSelect ? !disableMultiEditing ? multiEditInterest() : null : allowEditing || enableAdd ? addInterest() : null"
                       *ngIf="allowEditing || enableAdd">
                    <span [ngClass]="isMultiSelect ? 'fa fa-pencil' : 'fa fa-plus'"></span>
                </button>
                <span class="btn btn-sm btn-toggle"
                      *ngIf="allowEditing && !disableMultiEditing"
                      (click)="toggleMultiSelect()"
                      [ngClass]="isMultiSelect ? 'active' : ''">
                    <span class="fa fa-check-square-o"></span>
                </span>
                <input formControlName="comment" type="search" placeholder="Search Comment" class="flex form-control input-sm"/>
                <app-string-array-input formControlName="ticker" class="flex" placeholder="Search Ticker"></app-string-array-input>
                <app-analyst-picker formControlName="analysts" [alignRight]="true" [defaultAnalyst]="true"></app-analyst-picker>
                <app-instisel-sector-picker formControlName="sectors" [alignRight]="true" placeHolder="Sector(s)"></app-instisel-sector-picker>
                <button class="pull-right btn btn-sm btn-default" 
                        type="button"
                        [appRequiresFeature]="'download-holdings'" 
                        [disabled]="!exportEnabled" 
                        (click)="exportHoldings()" 
                        tooltip="Export Interests"><span class="fa fa-download"></span></button>
            </div>
        </form>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowClassRules]="rowClassRules"
            (rowClicked)="onRowClicked($event)"
            (gridReady)="onGridReady($event)"
            [rowMultiSelectWithClick]="isMultiSelect"
            [suppressRowClickSelection]="!isMultiSelect"
    >
    </ag-grid-angular>

</div>