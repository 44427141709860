import {Routes} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {EntitlementListComponent} from "./FactSet/entitlement-list.component";
import {EntitlementRoutePaths} from "./entitlements-route-paths";

export const entitlementsRoutes: Routes = [
    {
        path: EntitlementRoutePaths.BasePath,
        canActivate: [UserCanActivateRoute],
        component: EntitlementListComponent,
    },
];
