<div class="modal-header">
    Select An Email
</div>
<div class="modal-body">
    <div class="flex flex-hbox">
        <div class="flex" style="min-height: 500px;" [hidden]="previewExpanded">
            <tabset class="flex-tabset" style="height: 100%;">
                <tab heading="Inbox">
                    <app-email-select-list 
                            mailboxName="Inbox" 
                            showToOrFrom="From" 
                            class = "flex flex-vbox" 
                            [previewEmail$]="previewEmail$">
                    </app-email-select-list>
                </tab>
                <tab heading="Sent Items">
                    <app-email-select-list 
                            mailboxName="Sent Items" 
                            showToOrFrom="To" 
                            class = "flex flex-vbox" 
                            [previewEmail$]="previewEmail$">
                    </app-email-select-list>
                </tab>
            </tabset>
        </div>
        <tabset class="flex-tabset" style="margin-left: 5px;">
            <tab heading="Preview">
                <div *ngIf="selectedEmail">
                    <button
                        class="btn btn-sm btn-default pull-right"
                        type="button"
                        (click)="togglePreviewExpansion()"
                        style="margin-bottom: 5px;"
                        tooltip="Toggle Preview Expansion"
                    >
                        <span
                            class="fa"
                            [ngClass]="previewExpanded ? 'fa-compress' : 'fa-expand'"
                            style="cursor: pointer;"
                        >
                        </span>
                    </button>
                </div>
                <iframe
                    class="preview-html flex"
                    style="min-height: 500px; width: 100%;"
                    [srcdoc]="previewHtml | trustHTML"
                >
                </iframe>
            </tab>
        </tabset>
    </div>
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap">
        <button type="button" class="btn btn-sm btn-primary flex" (click)="select()">Select</button>
        <button type="button" class="btn btn-sm btn-default flex" (click)="cancel()">Cancel</button>
    </div>
</div>
