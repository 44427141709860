export class Role {
    public Name: string;
    static Research: string;
}

export class RoleName {
    static get Research(): string { return "Research"; }
    static get Sales(): string { return "Sales"; }
    static get Traders(): string { return "Traders"; }
    static get ConferenceServices(): string { return "Conference Services"; }
    static get EquityCapitalMarkets(): string { return "ECM"; }
}