import {Component, Input, OnInit} from "@angular/core";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import * as moment from "moment";
import {combineLatest, Observable} from "rxjs";
import {EmailCampaign, EmailService} from "../../Shared/Services/email.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {DatePickerOptionsBuilder} from "../../../DatePickerOptionsBuilder";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {ReadershipModalService} from "../../Widget/Email/ReadershipModal/readership-modal-service";

@Component({
    selector: "app-contact-readership",
    templateUrl: "./contact-readership.component.html"
})
export class ContactReadershipComponent extends BaseGridComponent<EmailCampaign> implements OnInit{
    
    @Input()
    contactId: number;

    dateRangeOptions: any;

    columnDefs = [
        { field: 'CreatedAt', headerName: 'Date', sort: 'desc', width: 74, cellRenderer: dateRenderer },
        { field: 'Subject', headerName: 'Subject / Headline', flex: 3, tooltipValueGetter: (p) => p.data.Subject },
        { field: 'SentBys', headerName: 'Sender', flex: 1, valueGetter: this.renderSentBys, tooltip: this.renderSentBys },
        { field: 'Source', headerName: 'Source', flex: 1 },
        { field: 'Tickers', headerName: 'Ticker(s)', flex: 1, tooltipValueGetter: (p) => p.data.Tickers },
        { field: 'OpenCount', headerName: 'Open', width: 57, cellRenderer: (params) => this.displayRate(params, "open") },
        { field: 'ClickCount', headerName: 'Click', width: 53, cellRenderer: (params) => this.displayRate(params, "click") }
        ];

    rowData$: Observable<EmailCampaign[]>;

    emailSearchForm: UntypedFormGroup = this.fb.group({
        emailType: this.fb.control(['Email']),
        dateRange: this.fb.control({
            start: moment().add(-12, "month"),
            end: moment()
        }),
        searchTerm: this.fb.control(''),
        teamIds: this.fb.control([])
    });

    constructor(private fb: UntypedFormBuilder,
                private emailService: EmailService,
                private readershipModalService: ReadershipModalService) {
        super();
    }

    ngOnInit(): void {
        this.dateRangeOptions = new DatePickerOptionsBuilder()
            .setRangesToPredefinedList(moment())
            .buildOptions();

        this.setRowData();
    }

    setRowData(): void {
        const campaigns$ = this.emailSearchForm.controls['dateRange'].valueChanges.pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            startWith(this.emailSearchForm.controls['dateRange'].value),
            switchMap(dateRange => this.emailService.searchEmails(dateRange.start, dateRange.end, this.contactId))
        );
        const emailTypes$ = this.emailSearchForm.controls['emailType'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['emailType'].value)
        );
        const searchTerm$ = this.emailSearchForm.controls['searchTerm'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['searchTerm'].value)
        );
        const teamIds$ = this.emailSearchForm.controls['teamIds'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['teamIds'].value)
        )
        this.rowData$ = combineLatest([campaigns$, emailTypes$, searchTerm$, teamIds$]).pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            map(([campaigns, emailTypes, searchTerm, teamIds]) => {
                return campaigns.filter(c => objectSearchPredicate(c, searchTerm) &&
                    this.isEmailType(c, emailTypes) && this.isTeamIds(c, teamIds));
            })
        );
    }

    isEmailType(campaign: EmailCampaign, emailTypes: string[]): boolean {
        if (!emailTypes) return false;

        return (campaign.Source === 'Email' && emailTypes.indexOf('Email') > -1) ||
            (campaign.Source === 'BlueMatrix' && campaign.IsAllTiers && emailTypes.indexOf('BlueMatrix - All') > -1) ||
            (campaign.Source === 'BlueMatrix' && !campaign.IsAllTiers && emailTypes.indexOf('BlueMatrix - Limited') > -1) ||
            false;
    }
    
    isTeamIds(campaign: EmailCampaign, teamIds: number[]): boolean{
        const filteredArray = campaign.SentBys.map(p => p.TeamId).filter(value => teamIds.includes(value));
        return teamIds.length === 0 || filteredArray.length > 0;
    }

    renderSentBys(params: any): string {
        return params.data.SentBys ? params.data.SentBys.map(p => p.LastName).sort().join(', ') : '';
    }

    displayRate(params: any, type: string): string {
        if (params.data.Source === 'BlueMatrix') return '-';
        
        if (type === 'open') {
            return params.data.OpenCount > 0 ? 'Y' : 'N';
        } else if (type === 'click') {
            return params.data.ClickCount > 0 ? 'Y' : 'N';
        }
        return params.data.rate > 0 ? 'Y' : 'N';
    }

    onRowClicked($event: any) {
        this.readershipModalService.openReadershipModal($event.data.Id, $event.data.Source);
    }
}
