import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {Observable} from "rxjs";

@Component({
    selector: "app-string-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(StringPickerComponent)
})
export class StringPickerComponent extends BasePickerComponent<string> {
    
    @Input()
    showTagCountLabel: boolean;
    
    @Input()
    items$: Observable<string[]>;
    
    @Input()
    name: string;
    
    @Input()
    placeHolder: string;
    
    @Input()
    alignRight: boolean;
    
    @Input()
    multiple: boolean;

    @Input()
    showSelectClearAll: boolean;
    
    @Input()
    customSearchable: boolean;
    
    @Input()
    closeOnSelect: boolean;
    
    constructor(fb: UntypedFormBuilder){
        super(fb);
    }
    
    ngOnInit() {
        super.ngOnInit();
    }
}
