import {Component, EventEmitter, Input, OnChanges, ViewEncapsulation} from "@angular/core";
import { AccountService } from "../../Shared/Services/account.service";
import { Account } from "../../../Models/Account";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import { combineLatest, of } from "rxjs";
import { NoContactReasons } from "../../Contact/ContactForm/no-contact-reasons";
import {
    debounceTime,
    distinctUntilChanged, filter,
    map, startWith,
    switchMap,
    tap,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { UserService } from "../../Shared/Services/user.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";

@Component({
    selector: "app-tab-account-details",
    templateUrl: "./tab-account-details.component.html",
    styleUrls: ["./tab-account-details.scss"],
    encapsulation: ViewEncapsulation.None
})

export class TabAccountDetailsComponent implements OnChanges {
    @Input()
    accountId: number;
    @Input()
    accountDataChanged: EventEmitter<boolean>;

    attributionCodeEnabled: boolean = false;

    account: Account;
    noContactReasons$ = of(NoContactReasons);

    outsourcedBrokerAccounts: Account[] = [];

    constructor(
        private accountService: AccountService,
        private fb: UntypedFormBuilder,
        private toastrService: ToastrService,
        private userService: UserService,
        private modalService: BsModalService,
    ) {
    }

    noContactForm = this.fb.group({
        noContact: this.fb.control(false),
        noContactReason: this.fb.control(null, [Validators.required]),
        noContactNote: this.fb.control(null)
    });

    attributionForm = this.fb.group({
        attributionEditable: this.fb.control(false),
        attributionCode: this.fb.control(null)
    });

    accountBluematrixSecureLinks = this.fb.control(false);

    ngOnChanges(): void {
        this.accountDataChanged.pipe(
            startWith(true),
            switchMap(() => combineLatest([
                this.accountService.getAccountSubAccounts(this.accountId).pipe(
                    map(accounts => accounts.find(a => a.Id === this.accountId))
                ),
                this.userService.getCurrentUser(),
            ]))
        ).subscribe(([account, user]) => {
            if (!user?.Features.includes("UpdateNoContact")) {
                this.noContactForm.disable();
            }
            if (user?.Features.includes("AttributionCode")) {
                this.attributionCodeEnabled = true;
            }
            this.account = account;
            this.noContactForm.patchValue({
                noContact: !!this.account.ResearchStatus?.Reason,
                noContactReason: this.account.ResearchStatus?.Reason,
                noContactNote: this.account.ResearchStatus?.Note
            }, { emitEvent: false });
            this.attributionForm.get("attributionCode").patchValue(this.account.AttributionCode, { emitEvent: false });

            this.accountBluematrixSecureLinks.setValue(account.BluematrixSecureLinks, { emitEvent: false });

            if (!this.userService.hasUserFeature(user, UserFeatureName.BluematrixSecureLinksAccount)) {
                this.accountBluematrixSecureLinks.disable({ emitEvent: false });
            } else {
                this.accountBluematrixSecureLinks.enable({ emitEvent: false });
            }
        });

        this.noContactForm.valueChanges.pipe(
            filter(({ noContactReason }) => noContactReason && !this.noContactForm.disabled),
            debounceTime(1000),
            distinctUntilChanged(),
            tap(({ noContact, noContactReason, noContactNote }) => {
                if (noContact) {
                    this.account.ResearchStatus = {
                        Reason: noContactReason,
                        Note: noContactNote
                    };
                } else {
                    this.noContactForm.patchValue({
                        noContact: false,
                        noContactReason: null,
                        noContactNote: null
                    }, { emitEvent: false });
                    this.account.ResearchStatus = {
                        Reason: null,
                        Note: null
                    };
                }
            }),
            switchMap(() => this.accountService.updateAccount(this.account))
        ).subscribe(() => {
            this.toastrService.success("Account Updated", "Success");
            this.accountDataChanged.emit(true);
        });

        this.attributionForm.get("attributionCode").valueChanges.pipe(
            filter(() => !this.attributionForm.disabled),
            debounceTime(1000),
            distinctUntilChanged(),
            tap(attributionCode => {
                this.account.AttributionCode = attributionCode;
            }),
            switchMap(() => this.accountService.updateAccount(this.account))
        ).subscribe(() => {
            this.toastrService.success("Account Updated", "Success");
            this.accountDataChanged.emit(true);
        });

        this.accountBluematrixSecureLinks.valueChanges.pipe(
            distinctUntilChanged(),
            switchMap(bmSecureLinks => this.accountService.setAccountBluematrixSecureLinks(this.accountId, bmSecureLinks))
        ).subscribe(() => {
            this.toastrService.success("Bluematrix SecureLinks Updated", "Success");
        });
    }
}
