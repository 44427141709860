import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {AgGridModule} from "ag-grid-angular-legacy";
import {AccountActivityComponent} from "./AccountActivity/account-activity.component";
import {ChecksReportComponent} from "./ChecksReport/checks-report.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {ReportSelectorComponent} from "./ReportSelector/report-selector.component";
import {BackOfficeOverviewModalComponent} from "./TradeDetail/back-office-overview-modal.component";
import {CommissionAccountRenderer} from "../Widget/CellRenderers/commission-account-renderer";
import {PercentageRenderer} from "../Widget/CellRenderers/percentage-renderer.component";
import {CollapsibleHeaderRenderer} from "../Widget/CellRenderers/collapsible-header-renderer.component";
import {SelectionDisplayListComponent} from "./SelectionDisplayList/selection-display-list.component";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {reportRoutes} from "./report.routes";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {CommissionSplitsComponent} from "./CommissionSplits/commission-splits.component";
import { CommissionTotalsComponent } from "./CommissionTotals/commission-totals.component";
import { NonSplitTradeDetailGridComponent } from "./TradeDetail/non-split-trade-detail-grid.component";
import {AccountSummaryByYearComponent} from "./AccountSummaryByYear/account-summary-by-year.component";
import {PrintReportsComponent} from "./PrintReports/print-reports.component";
import {TradeDetailComponent} from "./TradeDetail/trade-detail.component";
import {SplitTradeDetailGridComponent} from "./TradeDetail/split-trade-detail-grid.component";
import {YearOverYearCommissionComponent} from "./YearOverYearCommission/year-over-year-commission.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([CommissionAccountRenderer, PercentageRenderer, CollapsibleHeaderRenderer]),
        ButtonsModule.forRoot(),
        NgSelectModule,
        BsDropdownModule,
        TooltipModule,
        RouterModule.forChild(reportRoutes),
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        UserCanActivateRoute,
    ],
    entryComponents:[
        BackOfficeOverviewModalComponent,
        AccountActivityComponent,
        ChecksReportComponent,
        CommissionTotalsComponent,
        CommissionSplitsComponent,
        AccountSummaryByYearComponent,
        PrintReportsComponent,
        TradeDetailComponent,
        NonSplitTradeDetailGridComponent,
        SplitTradeDetailGridComponent,
        YearOverYearCommissionComponent,
    ],
    declarations: [
        AccountActivityComponent,
        ChecksReportComponent,
        CommissionTotalsComponent,
        CommissionSplitsComponent,
        AccountSummaryByYearComponent,
        PrintReportsComponent,
        TradeDetailComponent,
        NonSplitTradeDetailGridComponent,
        SplitTradeDetailGridComponent,
        YearOverYearCommissionComponent,
        ReportSelectorComponent,
        BackOfficeOverviewModalComponent,
        SelectionDisplayListComponent,
    ]
})
export class ReportModule {
    constructor(){
        console.log("registering the ReportModule");
    }
}
