import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {Notification} from "../../../Models/Notification";
import {NotificationService} from "../../Shared/Services/notification.service";
import {Observable, Subject} from "rxjs";
import {tap, map, switchMap, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {BsModalService} from "ngx-bootstrap/modal";
import {ManageNotificationsDocumentsModalComponent} from "../Manage/manage-notifications-documents-modal.component";
import {PopoverDirective} from "ngx-bootstrap/popover";
import {DocumentsService} from "../../Shared/Services/documents.service";
import {UserService} from "../../Shared/Services/user.service";
import {UserFeatureName} from "../../../Models/User";
import * as moment from "moment";
import {CacheService} from "../../Shared/Services/cache.service";

@Component({
    selector: "app-notifications",
    templateUrl: "./notifications.component.html",
    styleUrls: ["./notifications.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent implements OnInit {

    notifications$: Observable<[string, Notification[]][]>;
    notificationsKey: string = "Notifications";
    notificationsUpdated: Subject<boolean> = new Subject<boolean>();
    unseenNotifications: boolean = false;

    isAdmin$: Observable<boolean>;

    @ViewChild(PopoverDirective)
    popover: PopoverDirective

    constructor(
        private notificationService: NotificationService,
        private documentService: DocumentsService,
        private modalService: BsModalService,
        private userService: UserService,
        private cacheService: CacheService,
    ) {
    }

    ngOnInit(): void {
        this.notifications$ = this.notificationsUpdated.pipe(
            startWith(true),
            switchMap(() => this.notificationService.getNotifications()),
            tap(notifications => {
                const seenNotificationIds: number[] = this.cacheService.getLocalStorage(this.notificationsKey);
                this.unseenNotifications = notifications.some(n => !seenNotificationIds?.includes(n.Id))
            }),
            map(notifications => {
              return _.chain(notifications)
                  .groupBy(n => n.NotificationDate)
                  .map((values, key) => [key, values] as [string, Notification[]])
                  .orderBy(n => moment(n[0]), "desc")
                  .value();
            })
        );

        this.isAdmin$ = this.userService.getCurrentUser().pipe(
            map(user => user.Features.includes(UserFeatureName.IsAdmin))
        );
    }

    onPopoverOpened(notifications: [string, Notification[]][]): void {
        const seenNotificationIds = _.chain(notifications).map(n => n[1]).flatMap().map(n => n.Id).value();
        this.cacheService.setLocalStorage(this.notificationsKey, seenNotificationIds)
        this.unseenNotifications = false;
    }

    manage(): void {
        this.popover?.hide();
        const manageModal = this.modalService.show(ManageNotificationsDocumentsModalComponent);
        manageModal.content.notificationsUpdated.subscribe(updated => {
            this.notificationsUpdated.next(updated);
        });
    }

    getWhatsNewPdf(): void {
        this.documentService.getDocumentInNewTab("whatsnew", "whatsNew.pdf").subscribe();
    }
}
