import {Component, EventEmitter, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {UserService} from "../../Shared/Services/user.service";
import {Contact} from "../../../Models/Contact";
import {DateRange} from "../../../Models/DateRange";
import * as moment from "moment";
import {RoleName} from "../../../Models/Role";
import {startWith, switchMap} from "rxjs/operators";
import {ContactFormComponent} from "../ContactForm/contact-form.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {ContactImageFormComponent} from "../ContactImageForm/contact-image-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactRoutePaths} from "../contact-route-paths";
import {AccountRoutePaths} from "../../Account/account-route-paths";

@Component({
    selector: "app-contact-detail",
    templateUrl: "./contact-detail.component.html",
    styleUrls: ['./contact-detail.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContactDetailComponent implements OnInit {

    defaultTab: ContactDetailsTab = "Activities";

    contactDateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    interestDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    activityDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    contactId: number;
    contact: Contact;
    assistantIds: number[];
    defaultDateRange: DateRange = {start: moment().add(-5, "years"), end: moment()};
    hasResearchTeam: boolean;
    teamName: string;
    isVote: boolean = false;
    isEESVisible: boolean = false;
    selectedContactId = this.fb.control(0);

    contactImageSrc: string = null;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private contactService: ContactService,
                private modalService: BsModalService,
                private route: ActivatedRoute,
                private router: Router,
    ) { }

    ngOnInit(): void {


        this.contactId = +this.route.snapshot.paramMap.get('contactId');
        this.defaultTab = this.route.snapshot.paramMap.get('defaultTab') as ContactDetailsTab ?? this.defaultTab;

        this.selectedContactId.patchValue(this.contactId);

        this.selectedContactId.valueChanges.subscribe(contactId => {
            this.router.navigate([ContactRoutePaths.ContactDetail, contactId]);
        });

        this.contactDateChanged.pipe(
            startWith(true),
            switchMap(() => this.contactService.getContactById(this.contactId))
        ).subscribe(contact => {
            this.contact = contact;
            this.assistantIds = this.getAssistantIds(contact);
            this.isVote = contact.Account.Tags.includes("Vote")
        });

        this.userService.getCurrentUser().subscribe(user => {
            this.hasResearchTeam = user.Role.Name === RoleName.Research && user.Team.Name !== RoleName.Research;
            this.teamName = user.Team.Name.split(",")[0];
            this.isEESVisible = this.userService.hasUserFeature(user, UserFeatureName.EES);
        });

        this.showContactImage();
    }

    goToAccount(): void {
        this.router.navigate([AccountRoutePaths.AccountDetail, this.contact.Account?.Id]);
    }

    addContact() {
        let initialState = {
            accountId: this.contact.Account?.Id,
            defaultTab: "Details",
        };

        this.modalService.show(ContactFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-lg'
        });
    }

    private getAssistantIds(contact: Contact): number[] {
        return this.contact.Assistants?.map(assistant => assistant.Id);
    }

    openContactImageModal() {

        let imageModalRef = this.modalService.show(ContactImageFormComponent, {
            initialState: {
                contactId: this.contactId,
            },
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-md'
        });

        imageModalRef.content.dataUpdated
            .subscribe(_ => {
                this.showContactImage();
            });
    }

    showContactImage(): void {
        this.contactImageSrc = null;
        this.contactService.getContactImage(this.contactId)
            .subscribe((image: Blob) => {
                if (image) {
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onloadend = () => {
                        this.contactImageSrc = reader.result as string;
                    }
                }
            });
    }
}

export type ContactDetailsTab = "Activities" | "Details" | "Profile" | "EES";
