import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityFeatureManager} from "../../../../ActivityFeatureManager";
import {of} from "rxjs";
import * as  _ from "lodash";
import {Feature} from "../../../../Models/Feature";
import {ActivityCategory} from "../../../../Models/ActivityCategory";
import {UserService} from "../../../Shared/Services/user.service";
import {ConfigService} from "../../../Shared/Services/config.service";
import {User} from "../../../../Models/User";
import {ActivityCategoryFilter} from "../../../../Filters/ActivityCategoryFilter";

@Component({
    selector: "app-ees-activity-status-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(EesActivityStatusPickerComponent)
})
export class EesActivityStatusPickerComponent extends BasePickerComponent<any> implements OnInit {

    @Input()
    placeHolder: string = "";

    @Input()
    alignRight: boolean = false;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    showSelectClearAll: boolean = true;

    constructor(fb: UntypedFormBuilder) {
        super(fb);
        this.name = "Status(es)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }

    ngOnInit() {
        super.ngOnInit();

        this.items$ = of([
            {Id: "O", Name: "Open"},
            {Id: "C", Name: "Complete"},

        ])
    }
}
