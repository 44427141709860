import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {TickerAccountSummary, TickerDetail} from "../../../Models/Ticker";
import * as moment from "moment";

@Injectable({
    providedIn: 'root',
})
export class TickerService {

    constructor(private baseHttp: BaseHttpService) { }

    getTickerDetails(ticker: string): Observable<TickerDetail> {
        return this.baseHttp.getData(`/ticker/${ticker}`);
    }

    getTickerAccountSummary(ticker: string, categories: string[], start: moment.Moment, end: moment.Moment): Observable<TickerAccountSummary[]> {
        let config = {
            params: {
                categories: categories,
                startDate: start.format("YYYY-MM-DD"),
                endDate: end.format("YYYY-MM-DD"),
            }
        };
        return this.baseHttp.getDataWithConfig(`/ticker/${ticker}/myaccounttradehistory`, config);
    }

    getTickerDailyVolume(ticker: string): Observable<TickerTradeSummary[]> {
        return this.baseHttp.getData(`/ticker/${ticker}/dailytradevolume`);
    }

    searchTickers(searchTerm: string): Observable<TickerSearchResult[]> {
        return this.baseHttp.getDataWithConfig(`/search/ticker`, { params: { query: searchTerm } });
    }

    getAllTickers(): Observable<TickerNameDto[]> {
        return this.baseHttp.getCacheOrData(`/ticker`, 5 * 60 * 1000);
    }

    getInstiselSectors(): Observable<InstiselSector[]> {
        return this.baseHttp.getData(`/instiselsectors`);
    }
}

export interface TickerTradeSummary {
    Region: string;
    Side: string;
    TradeDate: string;
    Volume: number;
}

export interface TickerSearchResult {
    Name: string,
    CompanyName: string,
}

export interface TickerNameDto {
    Name: string,
    CompanyName: string,
    Analyst: string,
    AnalystId: string,
    BlueMatrixSectorId: number;
}

export interface InstiselSectorBlueMatrixMapping {
    BlueMatrixSectorId: number,
    InstiselSectorId: number,
}

export interface InstiselSector {
    Id: number,
    ParentId: number,
    LongName: string,
    ShortName: string,
    ParentInstiselSector: InstiselSector,
    InstiselSectorBlueMatrixMappings: InstiselSectorBlueMatrixMapping[],
}