import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpHeaders} from '@angular/common/http';
import {tap} from "rxjs/operators";

declare var MiniProfiler;

@Injectable()
export class MiniProfilerHttpInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req)
            .pipe(
                tap((event: HttpResponse<any>) => {
                    if (typeof MiniProfiler !== 'undefined' && event && event.headers) {
                        this.makeMiniProfilerRequests(event.headers);
                    }
                })
            );
    }

    private makeMiniProfilerRequests(headers: HttpHeaders) {
        var miniProfilerHeaders = headers.getAll('x-miniprofiler-ids');
        if (!miniProfilerHeaders) {
            return;
        }
        miniProfilerHeaders.forEach(miniProfilerIdHeaderValue => {
            var ids = JSON.parse(miniProfilerIdHeaderValue) as string[];
            MiniProfiler.fetchResults(ids);
        });
    }
}