import {Injectable} from "@angular/core";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable} from "rxjs";
import {MifidAccount} from "./accounts";
import {FidessaAccount} from "../../../Services/MifidService";

@Injectable({
    providedIn: 'root',
})
export class MifidService {

    constructor(private baseHttpService: BaseHttpService) { }

    getMifidAccounts(): Observable<MifidAccount[]> {
        return this.baseHttpService.getData(`/mifid/account`);
    }

    getFidessaAccounts(): Observable<FidessaAccount[]> {
        return this.baseHttpService.getData(`/fidessa/account`);
    }

    createMifidAccount(account: MifidAccount): Observable<MifidAccount> {
        return this.baseHttpService.postData(`/mifid/account/id/${account.AccountId}`, account);
    }

    updateMifidAccount(account: MifidAccount): Observable<MifidAccount> {
        return this.baseHttpService.putData(`/mifid/account/id/${account.AccountId}`, account);
    }

    deleteMifidAccount(account: MifidAccount): Observable<void> {
        return this.baseHttpService.deleteData(`/mifid/account/id/${account.AccountId}`);
    }
}
