import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {map} from "rxjs/operators";
import {SavedContactList} from "../../Admin/ListGroups/saved-contact-list";
import {ContactListsService} from "../../Contact/ContactLists/contact-lists.service";
import {SavedContactListGroup} from "../../Admin/ListGroups/saved-contact-list-group";
import {Observable} from "rxjs";
import * as _ from "lodash";
import {ListGroupsService} from "../../Admin/ListGroups/list-groups.service";
import {ToastrService} from "ngx-toastr";
import {ConfirmModalComponent} from "../ConfirmModal/confirm-modal.component";
import {SelectionChangedEvent} from "ag-grid-community";

@Component({
    selector: "app-contact-list-save-modal",
    templateUrl: "./contact-list-save-modal.component.html"
})
export class ContactListSaveModalComponent extends BaseGridComponent<SavedContactList> implements OnInit {

    @Input()
    contactIds: number[];

    columnDefs = [
        {field: 'Name', headerName: 'Name', sort: 'asc', flex: 1 },
        {field: 'Description', headerName: 'Description', flex: 1 },
        {field: 'Permission', headerName: 'Permission', width: 150, cellRenderer: this.renderPermission, tooltip: this.renderPermission },
        {field: 'PermissionGroup.Name', headerName: 'Group', flex: 1 },
        // {field: 'CreatedBy', headerName: 'Created By', flex: 1 },
        {field: 'ModifiedBy', headerName: 'Modified', width: 100 },
        {field: 'ModifiedDate', headerName: 'Mod Date', width: 100, cellRenderer: dateRenderer },
        {field: 'Size', headerName: 'Contacts', width: 100 },
    ];

    form = this.fb.group({
        search: this.fb.control(''),
        lists: this.fb.control([]),
    });

    contactListForm = this.fb.group({
        name: this.fb.control('',[Validators.required]),
        description: this.fb.control(''),
        permission: this.fb.control("P"),
        group: this.fb.control(null),
    });

    permissions: SavedContactListGroup[] = [
        { Id: "G", Name: "Group View"},
        { Id: "E", Name: "Group View - Edit"},
        { Id: "P", Name: "Private"}
    ] as SavedContactListGroup[];

    groups$: Observable<SavedContactListGroup[]>;

    selectedIsEditable: boolean = false;

    constructor(public modalRef: BsModalRef,
                public modalService: BsModalService,
                private contactListsService: ContactListsService,
                private listGroupsService: ListGroupsService,
                private toastrService: ToastrService,
                private fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.rowSelection = "single";

        this.groups$ = this.listGroupsService.getGroups().pipe(
            map(name => _.sortBy(name, n => n.Name))
        );

        this.contactListsService.getAllLists()
            .subscribe(lists => {
                this.form.patchValue({
                    lists: lists
                });
            });

        this.rowData$ = this.form.valueChanges.pipe(
            map(formValues => formValues.lists.filter(q => !formValues.search || q.Name.toLowerCase().includes(formValues.search.toLowerCase()))),
            //map(lists => lists.filter(l => l.IsEditable))
        );
    }

    update(): void {
        let rows = this.gridApi.getSelectedRows();
        if (rows.length > 0) {

            let modalRef = this.modalService.show(ConfirmModalComponent, { initialState: { message: 'Are you sure you want to overwrite the contacts in this list?' } });

            modalRef.content.action.subscribe(_ => {
                let contactList: SavedContactList = rows[0];
                contactList.Contacts = this.contactIds.map(cid => { return { Id: cid }});

                this.contactListsService.updateContactList(contactList)
                    .subscribe(_ => {
                        this.toastrService.success("Contact List Updated");
                        this.modalRef.hide();
                    })
            });
        }
    }

    save(): void {

        if (this.contactListForm.invalid) {
            return;
        }

        let values = this.contactListForm.value;

        let contactList: SavedContactList = {
            Name: values.name,
            Description: values.description,
            Permission: values.permission,
            PermissionGroup: { Id: values.group ?? 0 },
            Contacts: this.contactIds.map(cid => { return { Id: cid }}),
        } as SavedContactList;

        this.contactListsService.createContactList(contactList)
            .subscribe(_ => {
                this.toastrService.success("Contact List Created");
                this.modalRef.hide();
            });
    }

    cancel(): void {
        this.modalRef.hide();
    }

    renderPermission(params: any): string {
        return params.data.Permission === 'G' ? 'Group View'
            : params.data.Permission === 'E' ? 'Group View - Edit'
                : 'Private';
    }

    onSelectionChanged($event: SelectionChangedEvent) {
        let rows = this.gridApi.getSelectedRows();
        this.selectedIsEditable = rows[0].IsEditable;
    }
}
