import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {getGridDisplayWords,} from "../../Shared/ag-grid-options";
import {ContactService} from "../../Shared/Services/contact.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {combineLatest, of} from "rxjs";
import {ColDef, RowClassParams, RowClickedEvent} from "ag-grid-community";
import {AttendeeFormComponent} from "./AttendeeForm/attendee-form.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {EventInvitee, EventService} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith, switchMap, tap, withLatestFrom} from "rxjs/operators";
import * as _ from "lodash";
import {Contact} from "../../../Models/Contact";
import { UserService } from "../../Shared/Services/user.service";
import { User } from "../../../Models/User";
import {RoleName} from "../../../Models/Role";
import * as moment from "moment";

@Component({
    selector: "app-tab-invite-list",
    template: `
        <div class="flex flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="contactSearchForm">
                    <div class="flex-hbox flex-gap">
                        <div
                            class="btn btn-primary btn-sm btn-toggle"
                            btnCheckbox
                            formControlName="myAccounts"
                            tooltip="My Accounts"
                        >
                            <span class="fa fa-sitemap"></span>
                        </div>

                        <div class="flex-3">
                            <input
                                formControlName="searchTerm"
                                type="search"
                                placeholder="Search"
                                class="flex form-control input-sm"
                            />
                        </div>
                    </div>
                </form>
            </div>

            <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </div>
    `,
    styleUrls: ["./tab-attendee-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabInviteListComponent extends BaseGridComponent<EventInvitee> implements OnInit {

    @Input()
    eventId: number;

    @Input()
    attendeeDataUpdated = new EventEmitter<boolean>();

    user: User;
    userIsEventAdmin: boolean;

    contactSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(""),
        myAccounts: this.fb.control(false),
    });

    columnDefs : ColDef[] = [
        {
            colId: "ContactName",
            valueGetter: p => p.data.ContactLastName ? `${p.data.ContactLastName}, ${p.data.ContactFirstName}`: "",
            tooltipValueGetter: p => p.data.ContactLastName ? `${p.data.ContactLastName}, ${p.data.ContactFirstName}`: "",
            headerName: "Contact Name",
            flex: 2,
            minWidth: 147,
            sort: "asc",
            sortIndex: 3
        },

        {
            field: "AccountName",
            tooltipValueGetter: (params) => params.data.AccountName,
            flex: 3,
            minWidth: 100,
            sort: "asc",
            sortIndex: 2
        },
        {
            field: "Tier",
            headerName: "Tier",
            headerTooltip: "Tier",
            width: 50,
        },
        {
            field: "InviteCount",
            headerName: "Count",
            headerTooltip: "Invite Count",
            width: 67
        },
        {
            field: "LastInvited",
            cellRenderer: (params) => moment(params.data.LastInvited).format("LL"),
            headerName: "Last Invited On",
            headerTooltip: "Last Invited On",
            width: 150
        },
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private eventService: EventService,
        private activityService: ActivityService,
        private contactService: ContactService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe((user) => {
            this.user = user;
            this.userIsEventAdmin = this.userService.hasUserFeature(user, 'EventAdmin');
            this.contactSearchForm.controls.myAccounts.setValue(this.determineMyAccountsDefault(user.Role.Name, this.userIsEventAdmin));
        });

        this.gridOptions.overlayNoRowsTemplate = `<span>No Attendees</span>`;
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.setRowData();
    }

    setRowData() {

        let invitees$ = this.attendeeDataUpdated.pipe(
            startWith(1),
            switchMap(() => this.eventService.getEventInvitees(this.eventId))
        );

        let formUpdates$ = this.contactSearchForm.valueChanges.pipe(
            startWith(this.contactSearchForm.getRawValue()),
            debounceTime(200),
        );

        this.rowData$ = combineLatest([
            invitees$,
            formUpdates$
        ]).pipe(
            map(([invitees, searchForm]) => {

                    return invitees.filter(row =>
                        this.filterAttendeeListRows(
                            row,
                            searchForm.searchTerm,
                            searchForm.myAccounts,
                        )
                    );
                }
            ));
    }

    filterAttendeeListRows(
        row: EventInvitee,
        searchTerm: string,
        myAccounts: boolean,
    ): boolean {

        if (myAccounts && !row.MyAccount) {
            return false;
        }
        const search = searchTerm.toLocaleLowerCase();
        if (_.startsWith(row.ContactFirstName.toLocaleLowerCase(), search) || _.startsWith(row.ContactLastName.toLocaleLowerCase(), search)) {
            return true;
        }
        const words = _.words(search, /[^,.\s;]+/g);
        const fieldsContains = _.map([row.ContactFirstName, row.ContactLastName, row.AccountName], f => (f || "").toLowerCase().trim());
        return _.every(words, (w) => {
            return (_.some(fieldsContains, prop => {
                return _.includes(prop, w);
            }))
        });
    }
    determineMyAccountsDefault(userRole: string, userIsEventAdmin: boolean):boolean {
        return !userIsEventAdmin && ((userRole === RoleName.Sales) || (userRole === RoleName.Traders));
    }
}

