import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ChartData} from "chart.js";
import {combineLatest, Observable, of} from "rxjs";
import { debounceTime, distinctUntilChanged, map, startWith, switchMap} from "rxjs/operators";
import * as _ from "lodash";
import {ChartType} from "ag-grid-community";
import * as moment from "moment";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityCategoryFilter} from "../../../Filters/ActivityCategoryFilter";
import {ActivityService} from "../../Shared/Services/activity.service";

@Component({
    selector: "app-ticker-trendline-chart",
    templateUrl: "./ticker-trendline-chart.component.html"
})
export class TickerTrendlineChartComponent extends BaseChartComponent implements OnChanges {
    @Input()
    selectedTicker: string;

    @Input()
    categories: string[];

    type: ChartType = "line";
    stacked: boolean = true;
    hasData: boolean = false;

    groupBy$ = of([
        "Category",
        "Region"
    ]);

    tickerTrendlineFilters: UntypedFormGroup = this.fb.group({
        categories: this.fb.control(["D", "P", "X"]),
        selectedTicker: this.fb.control(null),
        groupBy: this.fb.control("Category"),
        daysOfHistory: this.fb.control(90)
    });

    constructor(private activityService: ActivityService,
                private fb: UntypedFormBuilder) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTicker) {
            this.tickerTrendlineFilters.get('selectedTicker').patchValue(this.selectedTicker)
        }

        if (changes.categories) {
            this.tickerTrendlineFilters.get('categories').patchValue(this.categories)
        }
    }

    configureChart(): void {
        this.options = {
            scales: {
                y: {
                    type: 'linear',
                    position: 'left',
                    title: {
                        display: true,
                        text: 'Count'
                    },
                    beginAtZero: true,
                    suggestedMax: 4,
                },
                x:
                    {
                        stacked: true
                    }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                }
            },
        };

        this.configureData()
            .subscribe(chartData => {
                if (this.hasData) {
                    if (this.chart) {
                        this.updateChart(chartData);
                    } else {
                        this.data = chartData;
                        this.runChart();
                    }
                } else {
                    this.chart?.destroy();
                    this.chart = null;
                }
            });
    }

    configureData(): Observable<ChartData> {
        const summary$ = combineLatest([
            this.tickerTrendlineFilters.get('selectedTicker').valueChanges.pipe(startWith(this.tickerTrendlineFilters.get('selectedTicker').value)),
            this.tickerTrendlineFilters.get('categories').valueChanges.pipe(startWith(this.tickerTrendlineFilters.get('categories').value)),
            this.tickerTrendlineFilters.get('daysOfHistory').valueChanges.pipe(
                startWith(this.tickerTrendlineFilters.get('daysOfHistory').value),
                distinctUntilChanged()
            )
        ]).pipe(
            debounceTime(50),
            switchMap(([selectedTicker, categories, daysOfHistory]) => {
                    if (selectedTicker && categories && daysOfHistory) {
                        return this.activityService.getTickerSummary(selectedTicker.toUpperCase(), categories, daysOfHistory)
                    }
                    return of([]);
                }
            ));

        const groupBy$ = this.tickerTrendlineFilters.get('groupBy').valueChanges.pipe(
            startWith(this.tickerTrendlineFilters.get('groupBy').value)
        );

        return combineLatest([summary$, groupBy$]).pipe(
            map(([summaries, groupBy]) => {
                const regions = [...new Set(summaries.filter(s => s.Count > 0).map(s => s.Region))];
                const groupByValues = groupBy === "Category" ? this.categories : regions;
                const data = _.chain(summaries)
                    .groupBy("CallDate")
                    .map((groupedSummaries, callDate) => {
                        const categorySums = {};
                        groupByValues.forEach(gv => {
                            categorySums[gv] = _.chain(groupedSummaries)
                                .filter(gs => gs[groupBy] === gv)
                                .map(gs => gs.Count)
                                .sum()
                                .value();
                        });

                        return {CallDate: callDate, CategorySums: categorySums};
                    })
                    .orderBy(d => d.CallDate)
                    .value();

                this.hasData = data.length > 0;

                return {
                    labels: data.map(t => moment(t.CallDate).format("MM/DD/yy")),
                    datasets: groupByValues.sort().map(gv => {
                        return {
                            label: this.getLabelFromGroup(gv),
                            data: data.map(d => d.CategorySums[gv]),
                            fill: false,
                            borderWidth: 1.5,
                            yAxisID: "y",
                            pointRadius: 0,
                            lineTension: 0
                        }
                    })
                };
            })
        )
    }

    private getLabelFromGroup(value: string): string {
        return this.tickerTrendlineFilters.get('groupBy').value === "Category" ? `${value} - ${ActivityCategoryFilter.filter(value)}` : `${value}`;
    }
}
