import {Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";;
import {BaseGridComponent} from "../../../Shared/base-grid.component";
import {UntypedFormBuilder} from "@angular/forms";
import {HoldingService} from "../../../Shared/Services/holding.service";
import * as _ from "lodash";
import {QueryGroup} from "../../../../Services/QueryService";
import {QueryTranslator} from "../../../../Helpers/QueryTranslator";
import {combineLatest, of} from "rxjs";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {dateRenderer} from "../../../Shared/ag-grid-cell-renderers";
import {Holding} from "../../../../Models/Holding";
import {UserService} from "../../../Shared/Services/user.service";
import {Contact} from "../../../../Models/Contact";
import {RowClickedEvent} from "ag-grid-community";
import {RoleName} from "../../../../Models/Role";
import {User} from "../../../../Models/User";
import {BsModalService} from "ngx-bootstrap/modal";
import {InterestFormComponent} from "../../../Interest/InterestForm/interest-form.component";
import * as moment from "moment";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../../../Contact/contact-route-paths";

@Component({
    selector: "app-tab-interest-list",
    templateUrl: "./tab-interest-list.component.html",
    styleUrls: ["./tab-interest-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabInterestListComponent extends BaseGridComponent<Holding> implements OnInit, OnChanges {

    @Input()
    contactId: number;

    @Input()
    accountId: number;

    @Input()
    allowEditing: boolean = false;

    @Input()
    enableAdd: boolean = false;

    @Input()
    disableMultiEditing: boolean = false;

    @Input()
    appliedQueryGroups: QueryGroup[] = [];

    @Input()
    contactDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>(); // This default value can be removed when completely switched over to Angular2+

    @Input()
    interestDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>(); // This default value can be removed when completely switched over to Angular2+

    isMultiSelect: boolean = false;
    contact: Contact;
    holdings: Holding;
    user: User;
    exportEnabled: boolean = false;

    columnDefs = [
        {
            field: 'isEditable',
            headerName: '',
            width: 36,
            headerCheckboxSelection: () => this.isMultiSelect,
            checkboxSelection: () => this.isMultiSelect,
            hide: true
        },
        {
            field: 'Contact.ShortName',
            headerName: 'Name',
            headerTooltip: 'Name',
            flex: 1,
            tooltipValueGetter: p => `${p.data.Contact.LastName}, ${p.data.Contact.FirstName}`,
            hide: true
        },
        {field: 'Ticker', headerTooltip: 'Ticker', width: 82, sort: 'asc'},
        {field: 'Coverage.LastName', headerName: 'Analyst', flex: 1},
        {
            field: 'InstiselSector',
            headerName: 'Sector',
            headerTooltip: 'Sector',
            flex: 2,
            cellRenderer: p => p.data.InstiselSector?.ParentInstiselSector ? `${p.data.InstiselSector.ParentInstiselSector.LongName}` : p.data.InstiselSector?.LongName,
            tooltipValueGetter: (p) => p.data.InstiselSector?.ParentInstiselSector ? `${p.data.InstiselSector.ParentInstiselSector.LongName} > ${p.data.InstiselSector.LongName}` : p.data.InstiselSector?.LongName,
            valueGetter: p => p.data.InstiselSector?.ParentInstiselSector.LongName.toUpperCase() ?? p.data.InstiselSector?.LongName.toUpperCase() ?? "zzz",
        },
        {field: 'Comment', tooltipValueGetter: (p) => p.data.Comment, flex: 2, minWidth: 105, maxWidth: 225},
        {
            field: 'Category',
            headerName: 'C',
            headerTooltip: 'Category',
            width: 50,
            cellClass: p => p.value === 'N' ? 'interest-category-no' : 'interest-category-not-no'
        },
        {
            field: 'BlueMatrix',
            headerName: 'BM',
            headerTooltip: 'BlueMatrix',
            width: 50,
            cellClass: p => p.value === 'Y' ? 'bluematrix-yes' : 'bluematrix-no'
        },
        {
            field: 'MarketingEmails',
            headerName: 'MKT',
            headerTooltip: 'Marketing Emails',
            width: 60,
            cellClass: p => p.value === 'Y' ? 'marketing-emails-yes' : 'marketing-emails-no'
        },
        {
            field: 'ResearchEmails',
            headerName: 'RES EM',
            headerTooltip: 'Research Emails',
            width: 80,
            cellClass: p => p.data.ResearchEmails === 'Y' ? 'research-emails-yes' : 'research-emails-no'
        },
        {field: 'UpdatedDate', headerName: 'Updated', width: 90, cellRenderer: dateRenderer}
    ];

    rowClassRules = {
        'subscribed-contact': (params) => params.data.Tags && _.includes(params.data.Tags, 'Subscription'),
    };

    form = this.fb.group({
        holdings: this.fb.group({
            comment: this.fb.control(''),
            ticker: this.fb.control([]),
            analysts: this.fb.control([]),
            sectors: this.fb.control([]),
            contactId: this.fb.control(0),
            accountId: this.fb.control(0),
        }),
    });

    constructor(private fb: UntypedFormBuilder,
                private holdingService: HoldingService,
                private userService: UserService,
                private modalService: BsModalService,
                private router: Router) {
        super();
    }

    ngOnInit() {
        this.gridOptions.pagination = true;
        this.gridOptions.overlayNoRowsTemplate = `<span>No Interests Found</span>`;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
                if (this.user.Role?.Name === RoleName.Research && this.user.Team?.Name !== this.user.Role?.Name) {
                    this.userService.getAnalysts()
                        .subscribe(analysts => {
                            let analystLastName = this.user.Team?.Name?.split(',')[0];
                            let analyst = analysts.filter(a => a.LastName === analystLastName);
                            if (analyst.length > 0) {
                                this.form.get("holdings").patchValue({
                                    analysts: [analyst[0].Id]
                                })
                            }
                        });
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contactId) {
            this.form.get("holdings.contactId").patchValue(changes.contactId.currentValue);
        }
        if (changes.accountId) {
            this.form.get("holdings.accountId").patchValue(changes.accountId.currentValue);
        }
        if (changes.appliedQueryGroups) {
            this.interestDataChanged.emit(true);
        }
    }

    convertToQuery(form: any): QueryGroup[] {
        let queryGroups: QueryGroup[] = [];

        if (form.contactId) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Persnum", "contains", [form.contactId]);
        }
        if (form.accountId) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Instnum", "contains", [form.accountId]);
        }
        if (form.comment) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Holding", "Comment", "contains", form.comment);
        }
        if (form.ticker && _.filter(form.ticker, t => t != '').length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Ticker", "contains", form.ticker);
        }
        if (form.analysts?.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Analyst", "contains", form.analysts);
        }
        if (form.sectors?.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Sector", "contains", form.sectors);
        }
        queryGroups.push(...this.appliedQueryGroups);

        return queryGroups;
    }

    onGridReady(params) {
        super.onGridReady(params);

        if (this.accountId) {
            this.gridColumnApi.setColumnVisible('Contact.ShortName', true);
        }

        this.rowData$ = combineLatest([
            this.form.controls["holdings"].valueChanges.pipe(
                startWith(this.form.getRawValue().holdings)
            ),
            this.interestDataChanged.pipe(
                startWith(true)
            )
        ]).pipe(
            debounceTime(200),
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(([form]) =>
                form.contactId || form.accountId ? this.holdingService.searchHoldings(this.convertToQuery(form)) : of([])),
            tap(rowData => this.exportEnabled = rowData.length > 0)
        );
    }

    addInterest() {
        this.openInterestFormNew();
    }

    multiEditInterest() {
        const holdingIds: string[] = _.map(this.gridApi.getSelectedRows(), h => h.Id);
        if (holdingIds.length === 0) return;
        this.openInterestFormNew(holdingIds);
    }

    onRowClicked($event: RowClickedEvent): void {
        if (this.contactId) {
            if (!this.isMultiSelect) {
                this.openInterestFormNew([$event.data.Id]);
            }
        } else if (this.accountId) {
            const rowContactId = $event.data.Contact.Id;
            this.router.navigate([ContactRoutePaths.ContactDetail, rowContactId]);
        }
    }

    openInterestFormNew(holdingIds?: string[]): void {
        const initialState = {
            contactId: this.contactId,
            holdingIds: holdingIds
        } as any;
        let modalRef = this.modalService.show(InterestFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-md' });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.interestDataChanged.emit(true);
        });
    }

    toggleMultiSelect(): void {
        this.isMultiSelect = !this.isMultiSelect;
        this.gridOptions.rowSelection = this.isMultiSelect ? "multiple" : "single";
        this.gridApi.deselectAll();
        this.gridColumnApi.setColumnVisible('isEditable', this.isMultiSelect);
    }

    exportHoldings(): void {
        const fileDate = moment().toISOString();
        this.holdingService.downloadHoldingsWithQuery(this.convertToQuery(this.form.get("holdings").value), `Interests_${fileDate}.xlsx`).subscribe();
    }
}
