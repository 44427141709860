<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="checksSearchForm">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn">
                        <div class="btn btn-primary btn-sm btn-toggle"
                             btnCheckbox
                             formControlName="myAccounts">
                            <span class="fa fa-sitemap"></span>
                        </div>
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 250px;"
                           formControlName="searchTerm"
                           placeholder="Search" />
                </div>
            </div>
            <div class="form-group">
                <div
                        class="btn btn-primary btn-sm btn-toggle"
                        formControlName="groupByParent"
                        btnCheckbox
                >
                    Group By Parent
                </div>
            </div>
            <div class="form-group">
                <select class="form-control input-sm" [formControl]="yearControl">
                    <option *ngFor='let year of years' [value]="year">{{ year }}</option>
                </select>
            </div>
        </form>
    </ng-template>

    <app-page-banner
            pagetitle="Check Report"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                rowClass="non-clickable-row"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [pinnedTopRowData]="pinnedTopRowData"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>

    </div>
</div>
