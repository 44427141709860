<div class="flex flex-vbox">

    <div class="flex-hbox flex-gap" style="margin-bottom: 5px" appIfUserFeature="InstiselMeetingsVoteLink">
        <div><button type="button" class="btn btn-sm btn-default" (click)="create()"><span class="fa fa-plus"></span></button></div>
        <div class="flex">
            <textarea style="resize: vertical"
                      class="form-control input-sm"
                      rows="4"
                      [formControl]="accountComment"
                      (blur)="save()">
            </textarea>
        </div>
    </div>
    <div style="margin-bottom: 5px" appIfNotUserFeature="InstiselMeetingsVoteLink">
        <span>{{ accountComment.value }}</span>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
            (gridSizeChanged)="refreshGrid()"
    >
    </ag-grid-angular>

</div>