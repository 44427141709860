<div class="window-body flex flex-vbox form-underline">
    <div class="flex" style="padding: 5px; background-color: #fff; border: 1px solid #ccc; overflow-y: auto;">

        <div class="text-right">
            <button type="button" class="btn btn-primary btn-xs" (click)="openEditModal()">Edit</button>
        </div>

        <div class="row address-row">
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Billing Info</label>
                    <div *ngFor="let lines of billingLines">{{ lines }}</div>
                </div>
            </div>
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Invoice Contacts</label>
                    <div *ngFor="let contacts of invoiceContacts">{{ contacts }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Invoice Path</label>
                    <div>{{ invoicePath }}</div>
                </div>
            </div>
            <div class="col-xs-6">
                <div class="form-group">
                    <label> Hard Dollar </label> <span>{{ isHardDollar ? ' : Yes' : '' }}</span>
                    <div> {{ hardDollarNotes }}</div>
                </div>
            </div>
        </div>
    </div>
</div>