<div class="page-content no-scroll flex-vbox">
    
    <app-page-banner
            pagetitle="Account Activity"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="accountActivitySearchForm">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn">
                        <div class="btn btn-primary btn-sm btn-toggle"
                             btnCheckbox
                             formControlName="showAccountsWithGrid">
                            Grid
                        </div>    
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 145px;"
                           formControlName="searchTerm"
                           placeholder="Search"/>
                </div>
            </div>
            <div class="form-group">
                <app-date-range-picker formControlName="dateRange"></app-date-range-picker>
            </div>
            <div class="form-group" appIfUserFeature="ActivityReportAnalystFilter">
                <app-team-picker formControlName="callerTeams" [alignRight]="true" [showResearchOnly]="true" [selectAllByDefault]="!hasResearchRole"></app-team-picker>
            </div>
            <div class="form-group">
                <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
            </div>
            <div class="form-group">
                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" aria-controls="dropdown-basic">
                        <span class="fa fa-gear"></span> <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                        role="menu" aria-labelledby="button-basic">
                        <li role="menuitem">
                            <a class="dropdown-item" (click)="exportExcel()">Export to Excel</a>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    </ng-template>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="rowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>
