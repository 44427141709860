<div class="page-content no-scroll flex-vbox">
    <app-page-banner
            pagetitle="Compose Email"
            faicon="fa fa-2x fa-envelope"
            faiconcolor="#ff8080">
    </app-page-banner>

    <div class="page-body flex flex-hbox flex-gap">
        <div class="flex flex-vbox">
            <tabset class="flex-tabset">
                <tab heading="History">
                    <div style="margin-bottom: 5px;">
                        <form [formGroup]="emailSearchForm">
                            <div class="flex-hbox flex-gap">
                                <button type="button" class="btn btn-sm btn-default" (click)="new()"><span class="fa fa-plus"></span></button>
                                <input formControlName="searchTerm" type="search" placeholder="Search" class="flex form-control input-sm" />
                                <app-date-range-picker formControlName="dateRange"></app-date-range-picker>
                            </div>
                        </form>
                    </div>
                    <ag-grid-angular
                        style="width: 100%; height: 100%;"
                        class="ag-theme-balham"
                        [gridOptions]="gridOptions"
                        [pinnedTopRowData]="pinnedTopRowData$ | async"
                        [rowData]="rowData$ | async"
                        [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef"
                        (gridReady)="onGridReady($event)"
                        (rowClicked)="rowClicked($event)"
                    >
                    </ag-grid-angular>
                </tab>
            </tabset>
        </div>
        <div class="flex-2 flex-vbox" style="position: relative;">
            <tabset class="flex-tabset">
                <tab heading="Mail Merge">
                    <div class="flex" style="overflow-y: auto;" #mailMergeContainer>
                        <div>
                            <div class="form-group" *ngIf="campaignId">
                                <div class="btn btn-sm btn-primary" style="margin-right: 5px;" (click)="newFromExisting(campaignId)">Copy</div>
                                <div class="btn btn-sm btn-default" (click)="details(campaignId)">Details</div>
                            </div>
                            <form [formGroup]="emailForm">
                                <div class="form-group">
                                    <div class="flex-hbox flex-gap">
                                        <div class="flex">
                                            <label>From:</label>
                                            <select class="form-control input-sm" formControlName="from">
                                                <option [ngValue]="user.Id">{{ user.LastName }}, {{ user.FirstName }} &lt;{{ user.Email}}&gt;</option>
                                                <option *ngFor="let sender of senders" [ngValue]="sender.Id">{{ sender.LastName }}, {{ sender.FirstName }} &lt;{{ sender.Email }}&gt;</option>
                                            </select>
                                        </div>
                                        <div class="flex" *ngIf="onBehalfOfs.length > 0">
                                            <label>On Behalf Of:</label>
                                            <select class="form-control input-sm" formControlName="onBehalfOf">
                                                <option [ngValue]=""></option>
                                                <option *ngFor="let sender of onBehalfOfs" [ngValue]="sender.Id">{{ sender.LastName }}, {{ sender.FirstName }} &lt;{{ sender.Email }}&gt;</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <ng-template #toTitle>
                                        <label>To:</label><span tooltip="Send an individual email to each recipient." class="fa fa-info-circle info-icon"></span>
                                    </ng-template>
                                    <app-multi-contact-select 
                                            class="email-form-recipients" 
                                            formControlName="recipients"
                                            [requireEmail]="true"
                                            contactRequired="true"
                                            [titleTemplate]="toTitle"
                                            [includeDoNotContactContacts]="includeDoNotContactContacts">
                                    </app-multi-contact-select>
                                </div>
                                <div class="form-group">
                                    <label>CC:</label><span tooltip="Send a copy of every individual email delivered to each recipient." class="fa fa-info-circle info-icon"></span>
                                    <app-multi-user-select formControlName="ccs"></app-multi-user-select>
                                    <label style="font-weight: normal;">
                                        <input type="checkbox" formControlName="ccBrokers" /> CC Contact Brokers<span tooltip="Send a copy to brokers that cover the Account/Contact. Intended for sales use." class="fa fa-info-circle info-icon"></span>
                                    </label>
                                </div>
                                <div class="form-group">
                                    <label>Subject: </label>
                                    <div>
                                    <button
                                            type="button"
                                            class="btn btn-primary btn-xs"
                                            (click)="insertNamePlaceholderSubject()"
                                            style="margin-bottom: 2px;"
                                    >
                                        Insert Corresp Name Placeholder
                                    </button>
                                    </div>
                                    <input #subject type="text" class="form-control input-sm" formControlName="subject" />
                                </div>
                                <div class="form-group">
                                    <label>Body:</label>
                                    <tabset>
                                        <tab heading="Content">
                                            <div>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary btn-xs pull-left"
                                                    (click)="insertNamePlaceholder()"
                                                >
                                                    Insert Corresp Name Placeholder
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-primary btn-xs pull-right"
                                                    (click)="openImportUserEmailModal()"
                                                    style="margin-left: 5px;"
                                                >
                                                    <span class="fa fa-envelope-o"></span> Import Email From Outlook
                                                </button>
                                            </div>
                                            <div style="padding: 5px 0;">
                                                <app-html-editor #emailEditor formControlName="body"></app-html-editor>
                                            </div>
                                        </tab>
                                        <tab heading="Preview" (selectTab)="selectPreviewTab()">
                                            <div class="preview"  style="padding: 5px 0;">
                                                <div class="preview-toolbar">
                                                    <button type="button" class="btn btn-xs btn-default"
                                                            [disabled]="previewIndex === 0"
                                                            (click)="changePreview(-1)">
                                                        <span class="fa fa-arrow-left"></span>
                                                    </button>
                                                    <button type="button" class="btn btn-xs btn-default" 
                                                            [disabled]="previewIndex >= emailForm.get('recipients').value.length - 1" 
                                                            (click)="changePreview(1)">
                                                        <span class="fa fa-arrow-right"></span>
                                                    </button>
                                                    <span style="margin-left: 5px;" *ngIf="!previewContactName">No Recipients</span>
                                                    <span style="margin-left: 5px;" *ngIf="previewContactName">{{ previewContactName }}</span>
                                                </div>
                                                <iframe class="preview-html" [srcdoc]="previewHtml | trustHTML" #preview (load)="setIframeHeight()"></iframe>
                                            </div>
                                        </tab>
                                    </tabset>
                                </div>
                                <div class="form-group" *ngIf="!campaignId">
                                    <label style="font-weight: normal; display: block;">
                                        <input type="checkbox" formControlName="includeSignature" /> Include Signature
                                        <button type="button" class="btn btn-sm btn-link" (click)="openSignatureForm()">Edit Signature</button>
                                    </label>
                                    <label style="font-weight: normal; display: block;">
                                        <input type="checkbox" formControlName="bccSelf" /> Send a blind copy to {{ user?.Email }}
                                    </label>
                                    <label style="font-weight: normal; display: block;">
                                        <input type="checkbox" formControlName="logActivity" /> Log Activity with Email
                                    </label>
                                </div>
                                
                                <app-activity-form-fieldset [commentPlaceholder]="commentPlaceholder"
                                                                formControlName="activity"
                                                                *ngIf="!loading"
                                                                [hidden]="campaignId || !showActivityForm"
                                                                [showCallDate]="false"
                                ></app-activity-form-fieldset>
                                
                                <div class="flex-hbox flex-gap" *ngIf="!campaignId">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary"
                                        (click)="send()"
                                        [disabled]="emailForm.invalid || loading || sendingEmail"
                                    >
                                        Send
                                    </button>

                                    <button
                                        type="button"
                                        class="btn btn-sm btn-primary"
                                        tooltip="Send a test email to {{user.Email}}"
                                        (click)="sendTest()"
                                        [disabled]="loading || sendingTestEmail"
                                    >
                                        Send Test
                                    </button>

                                    <button type="button" class="btn btn-sm btn-default" (click)="new()">Clear</button>
                                    
                                    <button type="button" class="btn btn-sm btn-primary" style="margin-left: auto;" (click)="saveDraft()">Save Draft</button>
                                    <button type="button" class="btn btn-sm btn-warning" *ngIf="draftId" (click)="deleteDraft()">Delete Draft</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
    </div>
</div>
