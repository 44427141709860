<style>
    .ng-invalid > .btn{
        border-color: red;
    }
</style>

<div class="form-group" [formGroup]="EesActivityForm">
    <div class="form-group">
        <label>Client Participant(s)</label>
        <app-multi-contact-select formControlName="clientParticipants"
                                  [showEditButtons]="false"
                                  [accountId]="accountId"
                                  [contactRequired]="true"></app-multi-contact-select>
    </div>

    <div class="form-group">
        <label>Baird Participant(s)</label>
        <app-multi-contact-select formControlName="bairdParticipants"
                                  [showEditButtons]="false"
                                  [bairdContacts]="true"
                                  [contactRequired]="true"></app-multi-contact-select>
    </div>

    <div class="form-group flex-hbox flex-gap">
        <div class="flex-3">
            <label>Activity Date</label>
            <input class="form-control input-sm" formControlName="activityDate" type="date" [valueAsDate]="initialActivityDate" />
        </div>

        <div class="flex-3">
            <label>Activity Type</label>

            <app-ees-activity-category-picker formControlName="activityType"
                               [showTagCountLabel]="false"
                               [multiple]="false"
                               [showSelectClearAll]="false"
                               [closeOnSelect]="true">
            </app-ees-activity-category-picker>
        </div>

        <div class="flex-3" *ngIf="EesActivityForm.value.activityType === 'F'">
            <label>Status</label>
            <app-ees-activity-status-picker formControlName="status"
                                [showTagCountLabel]="false"
                                [multiple]="false"
                                [showSelectClearAll]="false"
                                [closeOnSelect]="true">
            </app-ees-activity-status-picker>
        </div>

        <div class="flex-3" *ngIf="EesActivityForm.value.activityType === 'F'">
            <label>Due Date</label>
            <input class="form-control input-sm" formControlName="dueDate" type="date" [valueAsDate]="initialDueDate" />
        </div>
    </div>

    <div class="form-group">
        <label>Comments</label>
        <textarea type="text" formControlName="comment" maxlength="4000" rows="9" class="form-control"></textarea>
    </div>

    <div class="form-group">
        <label>Baird</label>
        <app-multi-user-select formControlName="baird" [minUsers]="1" [maxUsers]="1"></app-multi-user-select>
    </div>

</div>