import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";

@Component({
    selector: "app-duplicate-contact-modal",
    templateUrl: "./duplicate-contact-modal.component.html"
})
export class DuplicateContactModalComponent extends BaseGridComponent<any> implements OnInit {

    @Output()
    confirmed = new EventEmitter<boolean>();

    contacts: any;

    constructor(private modalRef: BsModalRef) { super(); }

    columnDefs = [
        { field: 'AccountName', headerName: 'Institution', flex: 2 },
        { field: 'FirstName', flex: 1 },
        { field: 'LastName', flex: 1 },
        { field: 'Email', headerName: 'Primary Email', flex: 2 },
        { field: 'AccountId', headerName: 'InstNum', flex: 1 },
        { field: 'Id', headerName: 'PersNum', flex: 1 },
        { field: 'IsDeleted', headerName: 'Deleted', flex: 1, cellRenderer: yesNoCellRenderer },
    ];

    ngOnInit(): void {
        this.rowData = this.contacts;
    }

    close(): void {
        this.modalRef.hide();
    }

    confirm(): void {
        this.confirmed.emit(true);
        this.modalRef.hide();
    }
}
