import {Contact, SimpleContact} from "../Models/Contact";
import {FullNameFilter} from "./FullNameFilter";
import * as _ from "lodash";

export class ContactFullNameFilter {
    static ID: string = "ContactFullNameFilter";

    static filter(contact: Contact): string {
        if (_.isNil(contact)) return "";

        return FullNameFilter.filter(contact.FirstName, contact.LastName, contact.Alias);
    }
}

export class SimpleContactFullNameFilter {
    static ID: string = "SimpleContactFullNameFilter";

    static filter(contact: SimpleContact): string {
        if (_.isNil(contact)) return "";

        return FullNameFilter.filter(contact.FirstName, contact.LastName, contact.Alias);
    }
}