import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {CalendarEventPresenter} from "../../../Components/Events/CalendarEvent";

@Component({
    selector: 'app-company-presenter-list',
    styles: [`
        .presenter-container {
            border: 1px solid #ebebeb;
            padding: 2px 14px;
        }

        .presenter-name {
            font-size: 14px;
        }

        .presenter-details {
            font-size: 12px;
        }
    `],
    template: `
        <ng-container>
             <span
                    [popover]="presenterPopover"
                    [placement]="placement"
                    adaptivePosition="true"
                    [outsideClick]="true"
                    container="body"
                    [popoverTitle]="popoverTitle">
                <a class="pointer">{{ presenters.length }}</a>
            </span>

            <ng-template #presenterPopover>
                <div style="max-height: 70vh; overflow: auto;">
                    <ng-container *ngFor="let presenter of presenters">
                        <div *ngIf="presenter">
                            <div class="presenter-container">
                                <div class="presenter-name">
                                    {{ presenter.LastName }}, {{ presenter.FirstName }}
                                </div>
                                <div class="presenter-details">{{ presenter.Title }}</div>
                                <div *ngIf="showCompanyName" class="presenter-details">{{ presenter.CompanyName }}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>`
})
export class CompanyPresenterListRenderer implements ICellRendererAngularComp {
    params: any;
    presenters: CalendarEventPresenter[];
    popoverTitle: string;
    showCompanyName: boolean;
    placement: 'top' | 'bottom' | 'left' | 'right' | 'auto';

    constructor() {
    }

    agInit(params: any): void {
        this.params = params;
        this.presenters = params.data.EventPresenters
            .sort((a, b) => (a.CompanyName ?? "").localeCompare(b.CompanyName) || 
                            (a.LastName ?? "").localeCompare(b.LastName));
        this.popoverTitle = `${params.HeaderName} Presenters`;
        this.showCompanyName = params.ShowCompanyName;
        this.placement = params.Placement;
    }

    refresh(): boolean {
        return true;
    }
}