<div class="modal-header">
    <strong>Back Office Overview</strong>
</div>
<div *ngFor="let account of backOfficeAccounts" class="modal-body">
    <div class="flex-hbox">
        <div class="flex-4">{{ account.Name }} <small>({{ account.Id }})</small></div>
        <div class="flex">{{ account.Quantity }} <small>({{ account.Percentage }})</small></div>
    </div>
</div>
<div class="modal-footer">
    <div class="row">
        <button type="button" class="col-xs-12 btn btn-sm btn-default" (click)="dismiss()">Close</button>
    </div>
</div>