import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {of} from "rxjs";
import * as  _ from "lodash";

@Component({
    selector: "app-email-source-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(EmailSourcePickerComponent)
})
export class EmailSourcePickerComponent extends BasePickerComponent<any> implements OnInit {
    
    @Input()
    showPostOnly: boolean = true;

    @Input()
    alignRight: boolean = false;
    
    constructor(fb: UntypedFormBuilder){
        super(fb);
        
        this.name = "Source(s)";
        this.placeHolder = "Source(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();

        let emailTypes = ['Email', 'BlueMatrix - All', 'BlueMatrix - Limited', 'Post Only'];
        
        if(!this.showPostOnly) {
            emailTypes.pop();
        }

        this.items$ = of(_.map(emailTypes, et => {
            return { Id: et, Name: et }
        }));
    }
}
