import * as _ from "lodash";
export class FullNameFilter {
    static ID: string = "FullNameFilter";

    static filter(firstName: string, lastName: string, alias: string, emptyMessage: string = "Contact deleted"): string {
        if(!firstName && !lastName){
            return emptyMessage;
        }
        let fullName = `${lastName || ""}, ${firstName || ""}`;

        if (this.aliasDiffersFromFirstName(firstName, alias)) {
            fullName = fullName.concat(` (${alias || ""})`);
        }

        return fullName;
    }
    
    private static aliasDiffersFromFirstName(firstName: string, alias: string): boolean {
        return (!_.isEmpty(alias) && this.normalize(firstName) !== this.normalize(alias));
    }

    private static normalize(text: string): string {
        if (_.isNil(text)) return "";

        return text
            .trim()
            .toLocaleLowerCase();
    }
}