import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders,} from "../BasePicker/base-picker.component";
import {of} from "rxjs";

@Component({
    selector: "app-yes-no-any-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(YesNoAnyPickerComponent)
})
export class YesNoAnyPickerComponent extends BasePickerComponent<any> {
    
    @Input()
    showTagCountLabel: boolean;
    
    @Input()
    multiple: boolean;
    
    @Input()
    showSelectClearAll: boolean;
    
    @Input()
    abbreviateName: boolean
    
    @Input()
    ngStyle: any;
    
    @Input()
    closeOnSelect: boolean;

    @Input()
    useNameAsId: boolean = false;
    
    @Input()
    placeHolder: string;
    
    constructor(fb: UntypedFormBuilder){
        super(fb);
        this.name = "Selected";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.items$ = of([ 
            { Id: "Y", Name: this.abbreviateName ? 'Y' : "Yes"},
            { Id: "N", Name: this.abbreviateName ? 'N' : "No"}
            ]);
    }
}

