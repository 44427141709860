import {Injectable} from "@angular/core";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {Observable} from "rxjs";
import {Contact} from '../../../Models/Contact';

@Injectable({
    providedIn: 'root',
})
export class CallListService {

    constructor(private baseHttp: BaseHttpService) {
    }

    getMyCallList(): Observable<CallDto[]>{
        return this.baseHttp.getData(`/calllist`);
    }
    
    add(queryGroups: any[]): Observable<CallDto[]> {
        return this.baseHttp.putData(`/query/calllist`, { QueryGroups: queryGroups });
    }
    
    clear(): Observable<any> {
        return this.baseHttp.deleteData(`/calllist`);
    }
    
    delete(id: number): Observable<any> {
        return this.baseHttp.deleteData(`/calllist/id/${id}`);
    }

    downloadExcel(): Observable<any> {
        return this.baseHttp.getFileWithApiFileName('/calllist/download', FileAcceptType.Excel);
    }
}

export interface CallDto {
    Category: string;
    Comment: string;
    Contact: Contact;
    Id: number;
    Ticker: string;
    LastResearchActivity: any;
    LastTeamActivity: any;
}