import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {CalendarEvent} from "../../../Components/Events/CalendarEvent";
import {EventService} from "../../Shared/Services/event.service";
import {CalendarEventAttendee} from "../../../Components/Events/CalendarEventAttendee";
import * as _ from "lodash";
import {BsModalService} from "ngx-bootstrap/modal";
import {EventFormComponent} from "../EventForm/event-form.component";
import * as moment from "moment";
import {PhoneCellRenderer} from "../../Widget/CellRenderers/phone-renderer.component";
import {emailRenderer} from "../../Shared/ag-grid-cell-renderers";
import {EventAttendeeRenderer} from "./event-attendee-renderer.component";
import {combineLatest, Observable, of} from "rxjs";
import {debounceTime, map, startWith} from "rxjs/operators";
import {UserService} from "../../Shared/Services/user.service";
import {RoleName} from "../../../Models/Role";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";
import {EventRoutePaths} from "../event-route-paths";

@Component({
    selector: "app-event-detail",
    templateUrl: "./old-request-detail.component.html",
    styleUrls: ['./old-request-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OldRequestDetailComponent extends BaseGridComponent<EventAttendee> implements OnInit {

    eventId: number;
    requestId: number;

    event: CalendarEvent;

    columnDefs = [
        { field: 'Status', flex: 1, cellRenderer: 'eventAttendeeRenderer', comparator: this.statusComparator, sort: 'asc' },
        { field: 'Name', flex: 2 },
        { field: 'Account', flex: 2 },
        { field: 'PhoneNumber', headerName: 'Phone', flex: 1, cellRenderer: 'phoneRenderer' },
        { field: 'Email', flex: 1, cellRenderer: emailRenderer },
        { field: 'Broker', headerName: 'PersBk', flex: 1 },
    ];

    frameworkComponents = {
        phoneRenderer: PhoneCellRenderer,
        eventAttendeeRenderer: EventAttendeeRenderer,
    };

    attendeeSearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(true),
        search: this.fb.control(''),
        broker: this.fb.control([]),
        status: this.fb.control([]),
    });

    allAttendees$: Observable<CalendarEventAttendee[]>;

    brokers$: Observable<string[]>;
    statuses$: Observable<string[]>;

    presenterExpand: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private userService: UserService,
                private eventService: EventService,
                private route: ActivatedRoute,
                private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.eventId = +this.route.snapshot.queryParamMap.get('eventId') || +this.route.snapshot.queryParamMap.get('evId');
        this.requestId = +this.route.snapshot.queryParamMap.get('requestId') || +this.route.snapshot.queryParamMap.get('reqId');

        this.gridOptions.overlayNoRowsTemplate = `<span>No Attendees Found</span>`;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.attendeeSearchForm.patchValue({ myAccounts: _.includes([RoleName.Sales, RoleName.Traders], user.Role.Name) })

                if (this.eventId) {
                    this.eventService.getEvent(this.eventId)
                        .subscribe(e => this.loadEvent(e));
                } else if (this.requestId) {
                    this.eventService.getRequest(this.requestId)
                        .subscribe(e => this.loadEvent(e));
                }
            });
    }

    loadEvent(event: CalendarEvent): void {
        this.event = event;

        this.allAttendees$ = of(event.Attendees);

        this.loadEventAttendees();
    }

    loadEventAttendees(): void {
        let formData$ = this.attendeeSearchForm.valueChanges.pipe(
            startWith(this.attendeeSearchForm.getRawValue()),
            debounceTime(200)
        );

        this.rowData$ = combineLatest([this.allAttendees$, formData$]).pipe(
            map(([attendees, formData]) => {
                let groupedAttendees = _.chain(attendees)
                    .filter(a => {
                        if (formData.myAccounts && !a.Tags.includes("My")) { return false; }
                        return true;
                    })
                    .groupBy(a => a.ContactId + '-' + a.Status)
                    .map((values: CalendarEventAttendee[], key: string) => {
                        let a = _.first(values);

                        return {
                            ContactId: a.ContactId,
                            AccountId: a.AccountId,
                            Status: a.Status,
                            StatusCount: values.length,
                            Details: values,
                            Name: `${a.LastName}, ${a.FirstName}`,
                            Account: a.AccountName,
                            PhoneNumber: a.Phone,
                            Email: a.Email,
                            Broker: a.PersonalBroker,
                            Tags: a.Tags,
                        } as EventAttendee;
                    })
                    .value();

                this.brokers$ = of(_.chain(groupedAttendees)
                    .map(a => a.Broker)
                    .uniq()
                    .orderBy(a => a)
                    .map(name => name ?? '-- No Broker --')
                    .value());

                let orderedStatuses = _.chain(groupedAttendees)
                    .map(a => a.Status)
                    .uniq()
                    .value()
                    .sort(_.bind(this.statusComparator, this));

                this.statuses$ = of(_.chain(orderedStatuses)
                    .map(status => `${status} (${ groupedAttendees.filter(a => a.Status === status).length })`)
                    .value());

                return _.chain(groupedAttendees)
                    .filter(a => {
                        let searchTarget = `${a.Name.toLowerCase()} ${a.Account.toLowerCase()}`;
                        let search = formData.search.toLowerCase();
                        if (search && !_.every(search.split(' ').filter(t => t.length > 0), term => searchTarget.includes(term))) {
                            return false;
                        }

                        if (formData.broker.length > 0 && !formData.broker.includes(a.Broker ?? '-- No Broker --')) { return false; }
                        if (formData.status.length > 0 && !_.some(formData.status, s => _.startsWith(s, a.Status))) { return false; }
                        return true;
                    })
                    .orderBy(a => a.Name)
                    .value();
            })
        )
    }

    attendeeStatusComparator(attendee1: EventAttendee, attendee2: EventAttendee): number {
        return this.statusComparator(attendee1.Status, attendee2.Status);
    }

    statusComparator(status1: string, status2: string): number {
        const correctOrder = ['Invited','Attended','No Show','Canceled'];
        let status1index = correctOrder.indexOf(status1);
        let status2index = correctOrder.indexOf(status2);
        return status1index < status2index ? -1 :
               status2index < status1index ? 1 :
               0;
    }

    clearFilters() {
        this.attendeeSearchForm.patchValue({
            myAccounts: false,
            search: '',
            broker: [],
            status: []
        });
    }

    edit() {
        const initialState = {
            eventId: this.eventId
        };
        let modalRef = this.modalService.show(EventFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-xl' });

        modalRef.content.dataUpdated.subscribe((updatedEvent: CalendarEvent) => {
            if (!updatedEvent) {
                this.router.navigate([EventRoutePaths.EventCalendar]);
            } else {
                this.event = updatedEvent;
            }
        });
    }

    onCellClicked($event: any) {
        if ($event.column.colId === 'Name' || $event.column.colId === 'Account') {
            this.router.navigate([ContactRoutePaths.ContactDetail, $event.data.ContactId]);
        }
    }

    getEventDateRange(): string {
        return !this.event
            ? ''
            : `${moment(this.event.BeginDate).format('LL')} - ${moment(this.event.EndDate).format('LL')}`
    }

    showMorePresenters(): boolean {
        this.presenterExpand = !this.presenterExpand;
        return this.presenterExpand;
    }
}

interface EventAttendee {
    ContactId: number;
    AccountId: number;
    Status: string;
    StatusCount: number;
    Details: CalendarEventAttendee[];
    Name: string;
    Account: string;
    PhoneNumber: string;
    Email: string;
    Broker: string;
    Tags: string[];
}
