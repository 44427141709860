<div class="page-content no-scroll flex-vbox">

    <app-page-banner
            pagetitle="Commission Splits"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="commissionSplitSearchForm">
            <div class="form-group">
                <div class="input-group">
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 145px;"
                           formControlName="accountName"
                           placeholder="Account Name"/>
                </div>
            </div>
            <div class="form-group">
                <app-date-range-picker formControlName="dateRange" view="text" [options]="datePickerOptions"></app-date-range-picker>
            </div>
            <div class="form-group">
                <app-string-picker [items$]="startYears$" formControlName="startYear" [alignRight]="true" [showSelectClearAll]="false" [showTagCountLabel]="false" [multiple]="false" [closeOnSelect]="true"></app-string-picker>
            </div>
            <div *ngIf="showSalesTeamFilter" class="form-group">
                <app-string-picker [items$]="salesTeams$" placeHolder="Sales Team" name="Sales Team(s)" formControlName="salesTeams" [alignRight]="true" [customSearchable]="true"></app-string-picker>
            </div>
            <div *ngIf="showBrokerFilter" class="form-group">
                <app-string-picker [items$]="brokers$" placeHolder="Broker" name="Broker(s)" formControlName="brokers" [alignRight]="true" [customSearchable]="true"></app-string-picker>
            </div>
            <div *ngIf="showGroupByFilter" class="form-group">
                <app-string-picker [items$]="groupBy$" placeHolder="Group By" formControlName="groupBy" [alignRight]="true" [multiple]="false" [showSelectClearAll]="false" [showTagCountLabel]="false" [closeOnSelect]="true"></app-string-picker>
            </div>
            <div class="form-group">
                <app-exports-dropdown [features]="features" (exportToPDF)="exportToPdf()" (exportToCSV)="exportToCSV()">Export to PDF</app-exports-dropdown>
            </div>
        </form>
        <div class="row pull-right" style="font-size: 12px; margin-right: 0;">
            <div>Current year shown is not annualized</div>
        </div>
        <div class="row pull-right" style="clear: both; margin-left: 20px !important; margin-right: 0;">
            <app-selection-display-list style="margin-right: 10px;" name="Teams" [selectionKeyValues]="salesTeamKeyValues" [selectionControl]="commissionSplitSearchForm.controls['salesTeams']"></app-selection-display-list>
            <app-selection-display-list name="Brokers" [selectionKeyValues]="brokerKeyValues" [selectionControl]="commissionSplitSearchForm.controls['brokers']"></app-selection-display-list>
        </div>
    </ng-template>

    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [pinnedTopRowData]="!intradayError ? pinnedTopRowData : null"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReady($event)"
                (rowClicked)="rowClicked($event)"
                [overlayNoRowsTemplate]="intradayError"
        >
        </ag-grid-angular>
    </div>
</div>
