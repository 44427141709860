import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {SharedEmail} from "../../../Services/SharedEmailService";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {SharedEmailService} from "../../Shared/Services/shared-email.service";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";

@Component({
    selector: 'app-shared-email-form',
    templateUrl: "./shared-email-form.component.html"
})
export class SharedEmailFormComponent implements OnInit {
    public sharedEmail: SharedEmail;
    private isInternalUseOnlyPlaceHolder: boolean;

    @Output() emailUpdated = new EventEmitter<boolean>();

    constructor(private sharedEmailService: SharedEmailService,
                private modalService: BsModalService,
                private toastr: ToastrService,
                private bsModalRef: BsModalRef){
    }

    ngOnInit(): void {

        this.isInternalUseOnlyPlaceHolder = this.sharedEmail.IsInternalUseOnly;
    }

    dismiss(): void {
        this.bsModalRef.hide();
    }

    confirm(): void {
        if(this.isInternalUseOnlyPlaceHolder != this.sharedEmail.IsInternalUseOnly){
            this.sharedEmailService.updateSharedEmail(this.sharedEmail).subscribe(updatedSharedEmail => {
                this.toastr.success("Shared Email Updated");
                this.emailUpdated.emit(true);
            });
        }
        this.bsModalRef.hide();
    }

    delete(): void {
        const initialState = {
            message: `Are you sure you want to delete this shared email?`,
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(value => {
                console.log(value);
                if (value) {
                    this.sharedEmailService.removeSharedEmail(this.sharedEmail.Id).subscribe(deleted => {
                        this.toastr.success(`Shared email deleted`);
                        this.emailUpdated.emit(true);
                        this.bsModalRef.hide();
                    });
                }
            });
    }
}
