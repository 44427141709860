<form [formGroup]="interestForm">
    <div class="modal-header">
        <div class="btn-group pull-right" btnRadioGroup formControlName="action" *ngIf="holdingIds && holdingIds.length > 0">
            <label btnRadio="edit" class="btn btn-xs btn-toggle">Edit</label>
            <label btnRadio="reassign" class="btn btn-xs btn-toggle">Reassign</label>
            <label btnRadio="copy" class="btn btn-xs btn-toggle">Copy</label>
        </div>
        
        <strong>{{holdingIds && holdingIds.length > 1 ? "Edit Interests (" + holdingIds.length.toString() + ")" : "Interest"}}</strong>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <ng-template #contactTitle>
                <label>Contact</label>
            </ng-template>
            <app-multi-contact-select formControlName="contactIds" 
                                      [titleTemplate]="contactTitle"
                                      [maxItems]="maximumContacts"
                                      [showEditButtons]="false"
            ></app-multi-contact-select>
        </div>
        <div class="form-group flex-hbox flex-gap" *ngIf="!holdingIds || holdingIds.length <= 1">
            <div class="flex">
                <ng-template #tickerTitle>
                    <label>Ticker(s)</label>
                </ng-template>
                <app-multi-ticker-select formControlName="tickers" 
                                         [titleTemplate]="tickerTitle"
                                         [existingTickers]="existingTickers"
                                         [showHasUncovered]="showHasUncovered"
                                         [showHasExisting]="showHasExisting"
                                         [maximumTickers]="maximumTickers"
                                         [autoFocus]="true"
                ></app-multi-ticker-select>
            </div>
        </div>
        <div class="form-group flex-hbox flex-gap-lg">
            <div class="flex">
                <label>Category</label>
                <div class="btn-group-vertical" style="width: 100%;" btnRadioGroup formControlName="category">
                    <label btnRadio="" class="btn btn-sm btn-toggle" *ngIf="holdingIds && holdingIds.length > 1">No Change</label>
                    <label btnRadio="H" class="btn btn-sm btn-toggle">Holdings</label>
                    <label btnRadio="I" class="btn btn-sm btn-toggle">Interested</label>
                    <label btnRadio="N" class="btn btn-sm btn-toggle">NOT Interested</label>
                </div>
            </div>
            <div class="flex">
                <label>Distribution Channels</label>
                <div style="margin-bottom: 15px;">
                    <div class="btn-group pull-right" btnRadioGroup formControlName="blueMatrix">
                        <label btnRadio="" class="btn btn-sm btn-toggle" *ngIf="holdingIds && holdingIds.length > 1">No Change</label>
                        <label btnRadio="Y" class="btn btn-sm btn-toggle" [disabled]="blueMatrixDisabledForUser">Yes</label>
                        <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                    </div>
                    BlueMatrix
                </div>
                
                <div style="margin-bottom: 15px;">
                    <div class="btn-group pull-right" btnRadioGroup formControlName="marketingEmails">
                        <label btnRadio="" class="btn btn-sm btn-toggle" *ngIf="holdingIds && holdingIds.length > 1">No Change</label>
                        <label btnRadio="Y" class="btn btn-sm btn-toggle" >Yes</label>
                        <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                    </div>
                    Marketing Emails
                </div>
                
                <div style="margin-bottom: 15px;">
                    <div class="btn-group pull-right" btnRadioGroup formControlName="researchEmails">
                        <label btnRadio="" class="btn btn-sm btn-toggle" *ngIf="holdingIds && holdingIds.length > 1">No Change</label>
                        <label btnRadio="Y" class="btn btn-sm btn-toggle">Yes</label>
                        <label btnRadio="N" class="btn btn-sm btn-toggle">No</label>
                    </div>
                    Research Emails
                </div>
                
            </div>
        </div>
        <div class="form-group">
            <label>Comment</label>
            <input type="text" class="form-control input-sm" formControlName="comment" maxlength="40" />
        </div>
        <div class="form-group">
            <div>
                <label>13F Date</label>
                <input class="form-control" type="date" formControlName="fDate" min="1753-01-01" max="9999-12-31"/>
            </div>
        </div>
        <div class="form-group flex-hbox flex-gap-lg" *ngIf="!holdingIds || holdingIds.length <= 1">
            <div class="flex">
                <label>Created By</label>
                <input class="form-control input-sm" formControlName="createdBy"/>
            </div>
            <div class="flex">
                <label>Updated By</label>
                <input class="form-control input-sm" formControlName="updatedBy"/>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox flex-gap-lg">
            <button type="button" class="flex btn btn-sm btn-primary" (click)="save()" [disabled]="interestForm.invalid">Save</button>
            <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()" *ngIf="holdingIds && holdingIds.length > 0">Delete</button>
            <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
        </div>
    </div>
</form>