import {Injectable} from "@angular/core";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {Observable} from "rxjs";
import {Document} from "../../../Models/Document";

@Injectable({
    providedIn: "root"
})
export class DocumentsService {
    constructor(private baseHttp: BaseHttpService) {
    }
    
    getDocument(name: string, fileName: string): Observable<any> {
        return this.baseHttp.getFileFromGet(`/documentation/${name}/${fileName}`, fileName, FileAcceptType.Pdf);
    }

    getDocumentInNewTab(name: string, fileName: string): Observable<any> {
        return this.baseHttp.getFileInNewTab(`/documentation/${name}/${fileName}`, FileAcceptType.Pdf);
    }
    
    addUpdateDocument(name: string, file: FormData): Observable<Document> {
        return this.baseHttp.postData(`/documentation/${name}`, file);
    }
    
    deleteDocument(name: string): Observable<void> {
        return this.baseHttp.deleteData(`/documentation/${name}`);
    }
}