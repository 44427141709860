import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {EventService} from "../../../Shared/Services/event.service";

@Component({
    selector: "app-company-people-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(CompanyPeoplePickerComponent)
})
export class CompanyPeoplePickerComponent extends BasePickerComponent<any> {

    @Input()
    companyId: number;
    
    @Input()
    peopleIds: number[];
    
    constructor(fb: UntypedFormBuilder, private eventService: EventService){
        super(fb);

        this.name = "Presenter(s)";
        this.appendTo = "body";
        //this.appendTo = ".ag-body-container";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.closeOnSelect = false;
    }
    
    clickPicker() {
        super.clickPicker();

        if (!this.items$) {
            this.items$ = this.eventService.getCompanyPeople(this.companyId, this.peopleIds).pipe(
                map(users => _.sortBy(users, m => m.LastName)),
                map(users => _.map(users, user =>  {  return { Id: user.Id , Name: `${user.LastName}, ${user.FirstName}`}}))
            );    
        }
    }

    ngOnInit() {
        super.ngOnInit();
    }
}
