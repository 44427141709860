import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {EventService} from "../../../Shared/Services/event.service";
import {EventAccountStatus} from "../../../../Components/Events/CalendarEventAccount";

@Component({
    selector: "app-event-company-status-picker",
    templateUrl : "../BasePicker/base-picker.component.html",
    providers: pickerProviders(EventCompanyStatusPickerComponent)
})
export class EventCompanyStatusPickerComponent extends BasePickerComponent<EventAccountStatus> {

    constructor(fb: UntypedFormBuilder, private eventService: EventService){
        super(fb);
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.name = "Status(es)";
    }

    @Input()
    placeHolder: string = "";

    @Input()
    alignRight: boolean = false;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    showSelectClearAll: boolean = true;

    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.eventService.getEventStatuses();
    }
}
