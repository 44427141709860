import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import { SavedContactList } from '../../../Models/SavedContactList';

@Injectable({
    providedIn: "root"
})
export class ListService {
    
    constructor(private baseHttp: BaseHttpService) {}
    
    getListById(id: number): Observable<SavedContactList> {
        return this.baseHttp.getData(`/list/id/${id}`) 
    }
}