import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UserService} from "../../Shared/Services/user.service";
import {CommissionService} from "../../Shared/Services/commission.service";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {combineLatest} from "rxjs";
import {accountTagComparator} from "../../Shared/ag-grid-options";
import {AccountTagRenderer} from "../../Widget/CellRenderers/account-tag-renderer.component";
import {CommissionAccountRenderer} from "../../Widget/CellRenderers/commission-account-renderer";
import {ReportService} from "../../Shared/Services/report.service";
import * as daterangepicker from "daterangepicker";
import {Router} from "@angular/router";
import {AccountRoutePaths} from "../../Account/account-route-paths";

@Component({
    selector: "app-reports-commission-totals",
    templateUrl: "./commission-totals.component.html"
})
export class CommissionTotalsComponent extends BaseGridComponent<CommissionTotalRow> implements OnInit {

    columnDefs = [
        {
            field: 'Tags',
            headerName: 'Votes',
            headerClass: 'hide-header',
            headerTooltip: 'Votes',
            cellRenderer: 'accountTagRenderer',
            comparator: accountTagComparator,
            width: 35,
            cellClass: "no-color"
        },
        {
            field: 'AccountName',
            headerName: 'Account',
            showRowGroup: true,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
                innerRenderer: "accountInfoRenderer",
                suppressCount: false
            },
            flex: 2,
            minWidth: 250,
            maxWidth: 300,
            enableRowGroup: true,
            sort: 'asc',
            cellClass: "no-color"
        },
        {field: 'Location', flex: 1, minWidth: 125, enableRowGroup: true, cellClass: "no-color"},
        {
            field: 'ParentName',
            headerName: 'Parent Account',
            flex: 1,
            hide: true,
            enableRowGroup: true,
            cellClass: "no-color"
        },
        {field: 'Region', flex: 1, hide: true, enableRowGroup: true, cellClass: "no-color"},
        {field: 'Market', flex: 1, enableRowGroup: true, hide: true, cellClass: "no-color"},
        {field: 'PrimaryBroker', flex: 1, minWidth: 90, headerName: 'PrBk', enableRowGroup: true, cellClass: "no-color"},
        {
            field: 'PrimaryBrokerTeam',
            minWidth: 90,
            headerName: 'PrBkTeam',
            hide: true,
            enableRowGroup: true,
            cellClass: "no-color"
        },
        {field: 'PrimaryTrader', flex: 1, minWidth: 90, headerName: 'PrTr', enableRowGroup: true, cellClass: "no-color"},
        {field: 'Tier', maxWidth: 50, enableRowGroup: true, cellClass: "no-color"},
        {
            field: 'HighTouch',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "lighter ag-right-aligned-cell"
        },
        {
            field: 'ETS',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "lighter ag-right-aligned-cell"
        },
        {
            field: 'Options',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "lighter ag-right-aligned-cell"
        },
        {
            field: 'Checks',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "lighter ag-right-aligned-cell"
        },
        {
            field: 'Syndicate',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "lighter ag-right-aligned-cell"
        },
        {
            field: 'Total',
            flex: 1,
            minWidth: 75,
            headerClass: 'header-right',
            aggFunc: 'sum',
            cellRenderer: currencyRenderer,
            cellClass: "ag-right-aligned-cell"
        },
    ];

    frameworkComponents = {
        accountTagRenderer: AccountTagRenderer,
        accountInfoRenderer: CommissionAccountRenderer
    };

    accountCommissionSearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(false),
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control(null),
        categories: this.fb.control([])
    });

    autoGroupColumnDef = {
        flex: 2,
        cellStyle: this.colorAccountInfoCell,
    };

    pinnedTopRowData: CommissionTotalRow[];
    datePickerOptions: daterangepicker.Options = {};
    categoryKeyValues: { key: string, value: string }[];

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private commissionService: CommissionService,
                private reportService: ReportService,
                private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.defaultColDef.tooltip = (params) => {
            return params.name;
        };
        this.gridOptions.sideBar = this.sideBar;
        this.gridOptions.groupDisplayType = "custom";
        this.gridOptions.rowClass = "groupable-row";

        const commissionCategories = this.commissionService.getCommissionCategories();
        this.accountCommissionSearchForm.controls['categories'].patchValue(
            commissionCategories.map(cc => cc.code)
        );
        this.categoryKeyValues = commissionCategories.map(cc => {
            return { key: cc.code, value: cc.description }
        });

        this.reportService.getLatestReleaseDate()
            .pipe(
                tap(releaseDate => {
                    const dateRange = {start: moment().startOf('year'), end: moment(releaseDate).startOf('day')};
                    this.accountCommissionSearchForm.controls["dateRange"].patchValue(dateRange, {emitEvent: true});
                    this.datePickerOptions = {
                        ...this.datePickerOptions,
                        maxDate: dateRange.end
                    }
                }))
            .subscribe(() => this.setRowData());
    }

    setRowData(): void {
        let accounts$ = this.accountCommissionSearchForm.controls["dateRange"].valueChanges.pipe(
            startWith(this.accountCommissionSearchForm.controls["dateRange"].value),
            switchMap(dateRangeValue => this.commissionService.getAccountCommissionTotals(dateRangeValue.start, dateRangeValue.end)),
            map(accounts => {
                let getTotalByCategory = (account: any, cat: string) =>
                    account.Commissions
                        .filter(t => t.Category === cat)
                        .map(t => t.Commission)
                        .reduce((acc, cv) => acc + cv, 0);

                return accounts.map(a => {
                    return {
                        AccountId: a.AccountId,
                        AccountName: a.Name,
                        ComdolId: a.ComdolId,
                        ParentName: a.ParentName ? `${a.ParentName} (${a.ParentComdolId})` : `${a.Name} (${a.ParentComdolId})`,
                        ParentComdolId: a.ParentComdolId,
                        Location: `${a.City ? a.City + ',' : ''} ${a.State ? a.State + ',' : ''} ${a.Country}`,
                        Region: a.Region,
                        Market: a.Market,
                        Team: a.Team,
                        Tier: a.Tier,
                        PrimaryBroker: a.PrimaryBroker,
                        PrimaryBrokerTeam: a.PrimaryBrokerTeam,
                        PrimaryTrader: a.PrimaryTrader,
                        HighTouch: getTotalByCategory(a, 'P'),
                        ETS: getTotalByCategory(a, 'E'),
                        Options: getTotalByCategory(a, 'O'),
                        Syndicate: getTotalByCategory(a, 'S'),
                        Checks: getTotalByCategory(a, 'C'),
                        Tags: a.Tags,
                        My: a.Tags.includes("My"),
                        Status: a.Status
                    } as CommissionTotalRow;
                });
            })
        );

        let form$ = this.accountCommissionSearchForm.valueChanges.pipe(
            startWith(this.accountCommissionSearchForm.getRawValue()),
        );

        this.rowData$ = combineLatest([accounts$, form$]).pipe(
            map(([accounts, formValues]) => {
                return accounts
                    .filter(a => a.HighTouch + a.ETS + a.Options + a.Syndicate + a.Checks !== 0)
                    .filter(a => formValues.myAccounts ? a.My : true)
                    .filter(a => formValues.searchTerm ? formValues.searchTerm.split(' ').every(term => a.AccountName.toLowerCase().includes(term.toLowerCase())) : true)
                    .map(a => {

                        let na = Object.assign({}, a);

                        if (!formValues.categories.includes("P")) na.HighTouch = 0;
                        if (!formValues.categories.includes("E")) na.ETS = 0;
                        if (!formValues.categories.includes("O")) na.Options = 0;
                        if (!formValues.categories.includes("S")) na.Syndicate = 0;
                        if (!formValues.categories.includes("C")) na.Checks = 0;

                        na.Total = na.HighTouch + na.ETS + na.Options + na.Syndicate + na.Checks;

                        return na;
                    });
            }),
            tap(rowData => {
                const sumCol = (rowData: CommissionTotalRow[], col: string): number => rowData.map(d => d[col]).reduce((acc, cv) => acc + cv, 0);
                this.pinnedTopRowData = [];
                this.pinnedTopRowData.push({
                    AccountName: "Total",
                    HighTouch: sumCol(rowData, "HighTouch"),
                    ETS: sumCol(rowData, "ETS"),
                    Options: sumCol(rowData, "Options"),
                    Syndicate: sumCol(rowData, "Syndicate"),
                    Checks: sumCol(rowData, "Checks"),
                    Total: sumCol(rowData, "Total"),
                } as CommissionTotalRow);
            })
        );
    }

    colorAccountInfoCell(params: any) {
        if (params.node.level !== 0) return {backgroundColor: '#f5f5f5'};
    }

    rowClicked($event: any) {
        if ($event.data?.AccountId) {
            this.router.navigate([AccountRoutePaths.AccountDetail, $event.data.AccountId]);
        }
    }
}

export interface CommissionTotalRow {
    AccountId: number;
    AccountName: string;
    ComdolId: string;
    ParentName: string;
    ParentComdolId: string;
    Location: string;
    Region: string;
    Market: string;
    Team: string;
    PrimaryBroker: string;
    PrimaryBrokerTeam: string;
    PrimaryTrader: string;
    Tier: string;
    HighTouch: number;
    ETS: number;
    Options: number;
    Checks: number;
    Syndicate: number;
    Total: number;
    Tags: string[],
    My: boolean;
    Status: string;
}
