<style>
    .form-inline .form-group:not(:last-child) {
        margin-right: 5px;
    }
</style>

<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <div>
            <div>Excluding NDR and Analyst Visits</div>
            <span>{{ getPagingDisplayWords() }}</span>
        </div>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="eventSearchForm">
            <div class="form-group">
                <app-event-category-picker formControlName="categories"></app-event-category-picker>
            </div>
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn">
                        <div class="btn btn-primary btn-sm btn-toggle"
                             btnCheckbox
                             formControlName="myAccounts"
                             tooltip="My Accounts"
                             container="body">
                            <span class="fa fa-sitemap"></span>
                        </div>
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 200px;"
                           formControlName="searchTerm"
                           placeholder="Search Event & Meeting Name" />
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 145px;"
                           formControlName="accountSearchTerm"
                           placeholder="Search Account" />
                </div>
            </div>
            <div class="form-group">
                <app-broker-picker formControlName="brokers"
                                   placeHolderLabel="Broker"
                                   [alignRight]="true"
                                   [includeInactive]="false"
                                   defaultEmptyStringId="">
                </app-broker-picker>
            </div>
            <div class="form-group">
                <app-team-picker formControlName="salesTeams"
                                 placeHolder="Sales Team"
                                 [alignRight]="true"
                                 [showSalesTeamsOnly]="true">
                </app-team-picker>
            </div>
            <div class="form-group">
                <app-activity-status-picker formControlName="attendeeStatus"
                                 placeHolder="Status" 
                                 [alignRight]="true">
                </app-activity-status-picker>
            </div>
            <div class="form-group">
                <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
            </div>
        </form>
    </ng-template>
    
    <app-page-banner 
            pagetitle="Registrations" 
            faicon="fa-calendar-o" 
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [getContextMenuItems]="getContextMenuItems"
                (gridReady)="onGridReady($event)"
                rowSelection="single"
        >
        </ag-grid-angular>
        
    </div>
</div>
