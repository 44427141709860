<ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-balham"
    [gridOptions]="gridOptions"
    [rowData]="rowData$ | async"
    [columnDefs]="columnDefs"
    [pinnedTopRowData]="pinnedTopRowData"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
>
</ag-grid-angular>