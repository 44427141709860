import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Contact as GraphContact, ContactFolder} from "@microsoft/microsoft-graph-types";
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {BaseHttpService} from "./base-http.service";
import {Contact} from "../../../Models/Contact";
import {ContactNote} from "../../../Models/ContactNote";

@Injectable({
    providedIn: 'root',
})
export class ExchangeContactsService {
    constructor(private baseHttp: BaseHttpService) {
    }

    getContactsInFolder(folderId: string): Observable<GraphContact[]> {
        return this.baseHttp.getMSGraph<{ value: GraphContact[] }>(`/me/contactFolders/${folderId}/contacts?$top=1000`).pipe(
            map(res => res.value)
        );
    }

    getFullNoteForContact(folderId: string, contactId: string): Observable<string> {
        return this.baseHttp.getMSGraph<GraphContact>(`/me/contactFolders/${folderId}/contacts/${contactId}?$top=1000&$select=personalNotes`).pipe(
            map(c => c.personalNotes)
        );
    }

    addContact(folderId: string, contact: Contact, note: ContactNote): Observable<GraphContact> {
        const newContact: GraphContact = this.mapToGraphContact(contact, note);
        return this.baseHttp.postMSGraph(`/me/contactFolders/${folderId}/contacts`, newContact);
    }

    updateContact(folderId: string, contactId: string, contact: Contact, note: ContactNote): Observable<GraphContact> {
        const newContact: GraphContact = this.mapToGraphContact(contact, note);
        return this.baseHttp.patchMSGraph(`/me/contactFolders/${folderId}/contacts/${contactId}`, newContact);
    }

    getContactFolders(): Observable<(ContactFolder & { id: string })[]> {
        return this.baseHttp.getMSGraph<{ value: (ContactFolder & { id: string })[] }>("/me/contactFolders").pipe(
            map(res => res.value)
        );
    }

    addContactFolder(folderName: string): Observable<ContactFolder & { id: string }> {
        let folder: ContactFolder = {
            displayName: folderName
        }
        return this.baseHttp.postMSGraph("/me/contactFolders", folder);
    }

    private mapToGraphContact(contact: Contact, note: ContactNote): MicrosoftGraph.Contact {
        return {
            givenName: contact.FirstName,
            surname: contact.LastName,
            nickName: contact.Alias,
            fileAs: [contact.LastName, contact.FirstName].filter(n => !!n).join(", "),
            officeLocation: contact.Id?.toString(),
            businessPhones: [[contact.PhoneNumber, contact.PhoneNumberExtension].filter(p => !!p).join(" x ")],
            mobilePhone: contact.MobilePhoneNumber,
            personalNotes: note.Professional,
            department: contact.Account?.Name,
            companyName: contact.Account?.Name,
            jobTitle: contact.Title,
            businessAddress: {
                street: contact.Account?.Address?.Route,
                city: contact.Account?.Address?.Locality,
                state: contact.Account?.Address?.AdministrativeArea2,
                postalCode: contact.Account?.Address?.PostalCode,
                countryOrRegion: contact.Account?.Address?.Country
            },
            otherAddress: {
                street: contact.Account?.MailingAddress?.Route,
                city: contact.Account?.MailingAddress?.Locality,
                state: contact.Account?.MailingAddress?.AdministrativeArea2,
                postalCode: contact.Account?.MailingAddress?.PostalCode,
                countryOrRegion: contact.Account?.MailingAddress?.Country
            },
            emailAddresses: [
                {
                    address: contact.Email,
                    name: `${contact.FirstName} ${contact.LastName}`
                },
                ...contact.EmailAddresses.slice(0, 2).map(e => { // Outlook only allows a maximum of 3 email addresses
                    return {
                        address: e,
                        name: `${contact.FirstName} ${contact.LastName}`
                    }
                })
            ]
        };
    }
}

