import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'app-form-error-display',
    templateUrl : "./form-error-display.component.html",
    styleUrls: ["./form-error-display.component.css"],
    encapsulation: ViewEncapsulation.None
})
export class FormErrorDisplayComponent {
    @Input() errorMsg: string;
    @Input() displayError: boolean;
}
