import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";
import * as moment from "moment";
import {SimpleUser, User} from "../../../Models/User";
import {EmailCampaignDetail} from "../../../Services/EmailService";
import {Recipient} from "../../../Models/Recipient";

@Injectable({
    providedIn: 'root',
})
export class EmailService {

    constructor(private baseHttp: BaseHttpService) {
    }

    getMyEmailCampaigns(startDate: moment.Moment, endDate: moment.Moment, search: string): Observable<EmailCampaign[]> {
        let config = {
            params: {
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
                search: search,
            }
        };
        return this.baseHttp.getDataWithConfig(`/campaign`, config);
    }

    getMyDraftEmailCampaigns(): Observable<EmailCampaign[]> {
        return this.baseHttp.getData(`/campaign/draft`);
    }
    
    getSignature(userId: string): Observable<any> {
        return this.baseHttp.getData(`/user/${userId}/signature`);
    }
    
    saveSignature(userId: string, signature: string): Observable<any> {
        return this.baseHttp.postData(`/user/${userId}/signature`, { signatureHtml: signature });
    }
    
    saveDraft(draftCampaignId: number, message: any): Observable<any> {
        if (draftCampaignId) {
            return this.baseHttp.postData(`/campaign/draft/id/${draftCampaignId}`, message);
        } else {
            return this.baseHttp.postData(`/campaign/draft`, message); 
        }
    }
    
    deleteDraft(draftCampaignId: number): Observable<any> {
        return this.baseHttp.deleteData(`/campaign/draft/id/${draftCampaignId}`);
    }
    
    sendEmail(message: any, activity: any): Observable<any> {
        const body = {
            message: message,
            activity: activity
        };
        return this.baseHttp.postData(`/campaign/send`, body);
    }
    
    sendTestEmail(message: any): Observable<any> {
        const body = {
            message: message
        };
        return this.baseHttp.postData(`/campaign/sendTest`, body);
    }
    
    searchEmails(
        startDate: moment.Moment,
        endDate:moment.Moment,
        contactId: number,
        teamIds?: number[],
    ): Observable<EmailCampaign[]>{
        let config = {
            params: {
                startDate: startDate.format("YYYY-MM-DD"),
                endDate: endDate.format("YYYY-MM-DD"),
                contactId: contactId && contactId.toString() || null,
                teamIds: teamIds ? teamIds.map(id => id.toString()) : [],
            }
        };

        return this.baseHttp.getDataWithConfig(`/campaign/search`, config);
    }

    getEmailCampaignDetails(id: number): Observable<EmailCampaignDetail> {
        return this.baseHttp.getData(`/campaign/id/${id}`);
    }

    getEmailCampaignRecipients(id: number, includeBaird: boolean): Observable<Recipient[]> {
        let config = { params: { includeBaird: includeBaird.toString() } };
        
        return this.baseHttp.getDataWithConfig(`/campaign/id/${id}/recipients`, config);
    }

    getBMDocumentDetails(id: number): Observable<EmailCampaignDetail> {
        return this.baseHttp.getData(`/bmdocument/id/${id}`);
    }

    getBMDocumentRecipients(id: number): Observable<Recipient[]> {
        return this.baseHttp.getData(`/bmdocument/id/${id}/recipients`);
    }
}

export interface EmailCampaign {
    Id: number;
    Source: string;
    Subject: string;
    MyRecipientCount: number;
    MyOpenCount: number;
    MyClickCount: number;
    RecipientCount: number;
    DeliveredCount: number;
    OpenCount: number;
    ClickCount: number;
    Tickers: string[];
    Tiers: string[];
    IsAllTiers: boolean;
    IsPostOnly: boolean;
    VisibleInInstisel: boolean;
    SentBys: SimpleUser[];
    SendOnBehalfOfs: SimpleUser[];
    CreatedBy: SimpleUser;
    CreatedAt: string | moment.Moment;

    MyOpenRate: number;
    MyClickRate: number;
    OpenRate: number;
    ClickRate: number;

    DisplayTickers: string;
    DisplayTiers: string;
    DisplaySenders: string;
}

export interface TestEmailMessage {
    From: string;
    OnBehalfOf: string;
    To: string;
    Subject: string;
    Body: string;
}

export interface EmailMessage {
    From: string;
    OnBehalfOf: string;
    To: number[];
    Cc: string[];
    CcBrokers: boolean;
    Bcc: string[];
    Subject: string;
    Body: string;
    IsTracked: boolean;
    DraftId: number;
}

export interface EmailActivity {
    CallDate: moment.Moment,
    Category: string;
    SubCategory: string;
    Comment: string;
    EventId: number;
    Status: string;
    Type: string;
    Duration: number;
    Tickers: string;
    StockCallCategory: string;
    ContactedBy: string;
    ParticipantUserIds: string[];
    Side: string;
    Price: number;
}