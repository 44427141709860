export class ActivityCategoryFilter {
    public static ID: string = "ActivityCategoryFilter";

    public static filter(activityTypeLetter: string): string {

        const descriptions: { [type: string]: string } = {
            "F": "Follow Up",
            "G": "Analyst Outreach",
            "A": "Analyst Visits",
            "W": "Blast VM",
            "V": "Company Visits", 
            "N": "Conference Calls",
            "C": "Conferences",
            "M": "Corp Access Outreach",
            "R": "Deals",
            "E": "Email",
            "X": "Email Inbound / Exchange",
            "D": "Excel Model",
            "I": "Field Trips",
            "Z": "Ideas",
            "J": "In Person / Relationship Dev",
            "K": "NDR",
            "P": "Phone / In Person",
            "Q": "Principal Trader Outreach",
            "H": "Stock Maint / General",
            "S": "Voice Message",
            "M-S": "Account / Strategic",
            "M-T": "Trader",
            "0": "Corp Access Exception",
            "-": "Registration/Interest",
        };

        return descriptions[activityTypeLetter] || "unknown";
    }
}