import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import {Observable} from "rxjs";

export interface TimeZone {
    Id: number;
    Name: string;
    Abbreviation: string;
}

@Injectable({
    providedIn: 'root',
})
export class TimeZoneService {

    constructor(private baseHttp: BaseHttpService) { }

    getTimeZones(): Observable<TimeZone[]> {
        return this.baseHttp.getCacheOrData(`/timezone`, 5 * 60 * 1000);
    }
}