import {Component, forwardRef, OnInit } from "@angular/core";
import {ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as moment from "moment";

@Component({
    selector: "app-date-between-input",
    template: `
        <span [formGroup]="datesForm">
            <input type="date" class="form-control input-sm" formControlName="startDate" />
            <span style="margin-left: 5px; line-height: 30px;">to</span>
            <input type="date" class="form-control input-sm" formControlName="endDate" />        
        </span>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateBetweenInputComponent),
            multi: true
        }
    ]
})
export class DateBetweenInputComponent implements  OnInit, ControlValueAccessor {
    
    onChange = (updatedData: string[]) => {};
    onTouched = () => {};
    
    datesForm = this.fb.group({
        startDate: this.fb.control(''),
        endDate: this.fb.control('')
    })
    
    constructor(private fb: UntypedFormBuilder){}
    
    ngOnInit(): void {
        
        this.datesForm.valueChanges.subscribe(form => {
            let start = moment(form.startDate);
            let end = moment(form.endDate);
            if (start.isValid() && end.isValid()) {
                this.onChange([start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")])
            }
        });
    }

    registerOnChange(fn: (updatedData: string[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: string[]): void {
        
        if (obj && obj.length && obj.length === 2) {
            this.datesForm.patchValue({
                startDate: obj[0],
                endDate: obj[1]
            });
        }
        
        this.onChange(obj);
    }
}
