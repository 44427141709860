import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {of} from "rxjs";
import {
    debounceTime,
    startWith,
    switchMap, tap
} from "rxjs/operators";
import {
    accountTagComparator,
    getGridDisplayWords, isNoContactContact,
    isNoContactReadOnlyContact
} from "../../Shared/ag-grid-options";
import {Contact} from "../../../Models/Contact";
import {ContactService} from "../../Shared/Services/contact.service";
import {emailRenderer} from "../../Shared/ag-grid-cell-renderers";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {PhoneCellRenderer} from '../../Widget/CellRenderers/phone-renderer.component';
import {ColDef} from "ag-grid-community";
import {AccountTagRenderer} from "../../Widget/CellRenderers/account-tag-renderer.component";
import * as _ from "lodash";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../contact-route-paths";

@Component({
    selector: "app-contact-list",
    templateUrl: "./contact-list.component.html"
})
export class ContactListComponent extends BaseGridComponent<Contact> implements OnInit {

    private user: User;

    columnDefs: ColDef[] = [
        {field: 'Account.Tags',
            headerName: 'Votes',
            headerClass: 'hide-header',
            headerTooltip: 'Votes',
            width: 50,
            cellRenderer: 'accountTagRenderer',
            comparator: accountTagComparator
        },
        {field: 'LastName', headerName: 'Last', sort: 'asc', flex: 1 },
        {field: 'FirstName', headerName: 'First', flex: 1 },
        {field: 'Account.Name', headerName: 'Account', flex: 2 },
        {field: 'Account.City', headerName: 'City', flex: 1 },
        {field: 'Account.State', headerName: 'State', flex: 1 },
        {field: 'Account.Country', headerName: 'Country', flex: 1 },
        {field: 'PhoneNumber', headerName: 'Phone', flex: 1, cellRenderer: 'phoneRenderer' },
        {field: 'Email', flex: 1, cellRenderer: emailRenderer },
        {field: 'Title', flex: 1 },
        {field: 'Broker.LastName', headerName: 'PersBk', flex: 1 },
    ];

    frameworkComponents = {
        phoneRenderer: PhoneCellRenderer,
        accountTagRenderer: AccountTagRenderer
    };

    rowClassRules = {
        'do-not-contact': (params) => isNoContactContact(params.data),
        'do-not-contact-readonly': (params) => isNoContactReadOnlyContact(params.data),
    };

    contactSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control('')
    });

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private contactService: ContactService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.overlayNoRowsTemplate = `<span>Search contact list</span>`; //getNoRowsOverlayTemplate('Contacts');

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
            });
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.rowData$ = this.contactSearchForm.get('searchTerm').valueChanges.pipe(
            debounceTime(500),
            startWith(''),
            tap(x => this.gridApi.showLoadingOverlay()),
            switchMap((searchTerm) => searchTerm && searchTerm.length >= 2 ?
                this.contactService.searchContacts(searchTerm) :
                of([])
            ),
            tap(x => this.gridApi.hideOverlay()),
        );
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onCellClicked(event: any): void {
        if (event.column.colId === 'Phone' || event.column.colId === 'Email') return;

        let contact = event.data;
        this.router.navigate([ContactRoutePaths.ContactDetail, contact.Id ]);
    }
}
