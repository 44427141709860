<div class="page-content no-scroll flex-vbox sales-dashboard">
    <app-page-banner
            pagetitle="Sales Dashboard"
            faicon="fa-tachometer"
            faiconcolor="maroon">
    </app-page-banner>
    <div class="page-body flex-vbox" [formGroup]="salesDashboard">
        <div class="trending-tickers flex-2">
            <app-trending-ticker-chart
                    (categoriesSelected)="onCategorySelected($event)"
                    (tickerSelected)="onTickerSelected($event)">
            </app-trending-ticker-chart>
        </div>
        <div class="ticker-trendline flex">
            <app-ticker-trendline-chart
                    [selectedTicker]="salesDashboard['controls']['selectedTicker'].value"
                    [categories]="salesDashboard['controls']['categories'].value">
            </app-ticker-trendline-chart>
        </div>
    </div>
</div>
