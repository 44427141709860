import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {ContactRole, ContactService} from "../../../Shared/Services/contact.service";

@Component({
    selector: "app-role-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(RolePickerComponent)
})
export class RolePickerComponent extends BasePickerComponent<ContactRole> {

    @Input()
    placeHolder: string;
    
    @Input()
    alignRight: boolean = false;
    
    constructor(fb: UntypedFormBuilder, private contactService: ContactService){
        super(fb);

        this.placeHolder = "0 Role(s)";
        this.name = "Role(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.customSearchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.contactService.getContactRoles().pipe(
            map(roles => _.sortBy(roles, m => m.Name))
        );
    }
}
