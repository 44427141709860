import {Component, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {ColDef, GridReadyEvent, ITooltipParams, RowClickedEvent} from "ag-grid-community";
import {TravelCityFormComponent} from "./travel-city-form.component";
import {
    TravelCity,
    TravelCityForListComponent,
    TravelCityService
} from "../Services/travel-city.service";
import {TravelTerritory, TravelTerritoryService} from "../Services/travel-territory.service";
import {TravelMarket, TravelMarketService} from "../Services/travel-market.service";
import {yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";

@Component({
    selector: "app-admin-user-list",
    templateUrl: "./travel-city-list.component.html"
})
export class TravelCityListComponent extends BaseGridComponent<TravelCityForListComponent> implements OnChanges, OnInit {

    travelTerritoryIdIsNull: boolean;
    initialTerritoryId: number;


    columnDefs: ColDef[] = [
        { field: "City", sort: "asc", valueGetter: (params) => params.data.Name?.trim(), tooltipValueGetter: (p) => p.data.Name, flex: 1 },
        { field: "TerritoryNameForTerritoryId", headerName: "Territory", width: 400, tooltipValueGetter: (p) => p.data.TerritoryNameForTerritoryId },
        { field: "MarketNameForMarketId", headerName: "Market", width: 400, tooltipValueGetter: (p) => p.data.MarketNameForMarketId },
        { field: "Deleted", width: 75, tooltipValueGetter: (params: ITooltipParams<TravelCityForListComponent>) => params.data.Deleted, cellRenderer: yesNoCellRenderer },
    ];

    travelCitySearchForm: UntypedFormGroup = this.fb.group({
        territoryId: this.fb.control(null),
        marketId: this.fb.control(null),
        searchTerm: this.fb.control("")
    });

    constructor(
        private fb: UntypedFormBuilder,
        private travelCityService: TravelCityService,
        private travelTerritoryService: TravelTerritoryService,
        private travelMarketService: TravelMarketService,
        private modalService: BsModalService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.initialTerritoryId = this.travelCitySearchForm.get('territoryId').value;
        this.travelTerritoryIdIsNull = this.initialTerritoryId === null;

        this.travelCitySearchForm.get('territoryId').valueChanges.subscribe(newTerritoryId => {
            this.travelTerritoryIdIsNull = newTerritoryId === null;
            this.travelCitySearchForm.patchValue({marketId: null});
        });

        this.setRowData();
    }

    ngOnChanges(changes: SimpleChanges): void { }

    setRowData(): void {
        const travelCities$ = this.travelCityService.getTravelCities(true);
        const formUpdate$ = this.travelCitySearchForm.valueChanges.pipe(startWith(this.travelCitySearchForm.value));
        const travelTerritories$ = this.travelTerritoryService.getTravelTerritories();
        const travelMarkets$ = this.travelMarketService.getTravelMarkets();
        this.rowData$ = combineLatest([travelCities$, travelTerritories$, travelMarkets$, formUpdate$]).pipe(
            map(([travelCities, travelTerritories, travelMarkets, formUpdate]) =>
                travelCities
                    .map(x => {
                        return {
                            ...x,
                            TerritoryNameForTerritoryId: travelTerritories.find(tt => tt.Id === x.TerritoryId)?.Name,
                            MarketNameForMarketId: travelMarkets.find(tm => tm.Id === x.MarketId)?.Name
                        } as TravelCityForListComponent })
                    .filter(x => this.showTravelCity(x, formUpdate.territoryId, formUpdate.marketId, formUpdate.searchTerm))
            )
        );
    }

    showTravelCity(travelCity: TravelCity, territoryId: number, marketId: number, searchTerm: string): boolean {
        return (territoryId === null ? true : territoryId === travelCity.TerritoryId) &&
            (marketId === null ? true : marketId === travelCity.MarketId) &&
            objectSearchPredicate(travelCity, searchTerm);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked(rowClickedEvent: RowClickedEvent<TravelCityForListComponent>) {
        this.openModalForm(rowClickedEvent.data.Id);
    }

    addTravelCity() {
        this.openModalForm(0);
    }

    openModalForm(travelCityId: number): void {
        const initialState = {
            travelCityId: travelCityId,
        };
        let modalRef =
            this.modalService.show(TravelCityFormComponent, {
                initialState: initialState,
                animated: false,
                keyboard: false,
                backdrop: "static",
                class: "modal-lg"
            });
        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }
}
