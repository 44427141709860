<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="filtersForm">
        <div class="modal-body">

            <tabset>
                <tab heading="Accounts">
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Region</label>
                            <app-region-picker formControlName="Regions"></app-region-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Investor Type</label>
                            <app-string-picker formControlName="InvestorTypes" [name]="investorTypesHeader" [items$]="investorTypeValues$" ></app-string-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Market</label>
                            <app-market-picker formControlName="Markets"></app-market-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Investor Strategy</label>
                            <app-string-picker formControlName="InvestorStrategies" [name]="investorStrategyHeader" [items$]="investorStrategyValues$" ></app-string-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Primary Broker</label>
                            <app-broker-picker formControlName="PrimaryBrokers"></app-broker-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>AUM (Bn)</label>
                            <div class="input-group">
                                <app-number-range formControlName="AumRange" [lowMin]="0" [highMin]="0"></app-number-range>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Equity AUM (Bn)</label>
                            <div class="input-group">
                                <app-number-range formControlName="EquityAumRange" [lowMin]="0" [highMin]="0"></app-number-range>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Contacts">
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Investor Style</label>
                            <app-string-picker formControlName="InvestorStyles" [name]="investorStyleHeader" [items$]="investorStyleValues$" ></app-string-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Market Cap (Bn)</label>
                            <div class="input-group">
                                <app-number-range formControlName="MarketCapRange" [lowMin]="0" [highMin]="0"></app-number-range>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Research VMs</label>
                            <app-yes-no-any-picker [multiple]="false" formControlName="ResearchVms"></app-yes-no-any-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Orientation</label>
                            <app-string-picker formControlName="Orientations" [name]="orientationHeader" [items$]="orientationValues$"></app-string-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Personal Broker</label>
                            <app-broker-picker formControlName="PersonalBrokers"></app-broker-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Deal Player</label>
                            <app-string-picker formControlName="DealPlayers" [name]="dealPlayerHeader" [items$]="dealPlayerValues$"></app-string-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Role</label>
                            <app-role-picker formControlName="Roles"></app-role-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Global Investor</label>
                            <app-yes-no-any-picker [multiple]="false" formControlName="GlobalInvestors"></app-yes-no-any-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Baird Contact</label>
                            <app-baird-contact-picker formControlName="BairdContacts"></app-baird-contact-picker>
                        </div>
                        <div class="form-group col-xs-6">
                            <label>Private Investment</label>
                            <app-yes-no-any-picker [multiple]="false" formControlName="PrivateInvestments"></app-yes-no-any-picker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xs-6">
                            <label>Do Not Contact</label>
                            <app-yes-no-any-picker [multiple]="false" formControlName="DoNotContacts"></app-yes-no-any-picker>
                        </div>
                    </div>
                </tab>
                <tab heading="{{includeActivityHeader}}">
                    <div class="row">
                        <div class="col-xs-6"  *ngIf="showActivityFilters">
                                <div class="text-center">
                                    <label style="text-decoration: underline;">Activity</label>
                                </div>
                                <div class="form-group">
                                    <label>Activity Within</label>
                                    <div style="display: flex">
                                        <input formControlName="ActivityStartDays" class="form-control input-sm" style="width: 80px" type="number"/> 
                                        <span style="margin-left: 5px; margin-top: 2px">days</span>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Activity Category</label>
                                    <app-activity-category-picker formControlName="ActivityCategories"></app-activity-category-picker>
                                </div>
                        </div>
                        <div class="col-xs-6">
                            <div class="text-center">
                                <label style="text-decoration: underline;">Interest</label>
                            </div>
                            <div class="form-group">
                                <label>BlueMatrix</label>
                                <app-yes-no-any-picker formControlName="BlueMatrix"></app-yes-no-any-picker>
                            </div>
                            <div class="form-group">
                                <label>Marketing Emails</label>
                                <app-yes-no-any-picker formControlName="MarketingEmails" [multiple]="false"></app-yes-no-any-picker>
                            </div>
                            <div class="form-group">
                                <label>Research Emails</label>
                                <app-yes-no-any-picker formControlName="ResearchEmails" [multiple]="false"></app-yes-no-any-picker>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" (click)="submit()" [disabled]="filtersForm.invalid">Apply</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

