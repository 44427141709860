import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../Shared/Services/user.service";
import {ToastrService} from "ngx-toastr";
import {SubscriptionService} from "../../Shared/Services/subscription.service";
import {User} from "../../../Models/User";
import {combineLatest, concat, forkJoin, Observable} from "rxjs";
import {Subscription} from "../../Shared/Models/subscription";
import {ConfirmDeleteSubscriptionComponent} from "./confirm-delete-subscription.component";
import {finalize} from "rxjs/operators";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";

@Component({
    selector: "app-contact-subscription-form",
    templateUrl: "./contact-subscription-form.component.html"
})
export class ContactSubscriptionFormComponent implements OnInit {

    @Input()
    subscriptionId: number;

    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    formLoading: boolean = true;

    subscriptionForm: UntypedFormGroup = this.fb.group({
        type: this.fb.control('analyst'),
        analysts: this.fb.control([]),
        sectors: this.fb.control([]),
        marketCapRange: this.fb.control({ low: '', high: '' }),
        category: this.fb.control('I'),
        marketingEmails: this.fb.control('Y'),
        researchEmails: this.fb.control('Y'),
        blueMatrix: this.fb.control('Y'),
        comment: this.fb.control('', [Validators.maxLength(40)]),
    },
    {
        validators: [this.requiredAnalystOrIndustry()]
    });

    allUsers: User[] = [];
    teamUsers: User[] = [];
    checkedAnalysts: string[]=[];

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private userService: UserService,
                private subscriptionService: SubscriptionService,
                private toastr: ToastrService) {}

    ngOnInit(): void {
        this.subscriptionForm.controls["analysts"].valueChanges.subscribe(userIds => {
            this.checkedAnalysts = [];
            this.teamUsers = [];

            let userTeamIds = this.allUsers
                .filter(u => userIds.includes(u.Id) && u.Team)
                .map(u => u.Team.Id);

            this.teamUsers = this.allUsers
                .filter(u => !userIds.includes(u.Id) && u.Team && userTeamIds.includes(u.Team.Id));

            this.teamUsers.forEach(user => {
                this.checkedAnalysts.push(user.Id);
            });
        });

        let allUsers$ = this.userService.getUsers();
        let analysts$ = this.userService.getAnalysts();

        combineLatest([allUsers$, analysts$])
            .subscribe(([users, analysts]) => {
                this.allUsers = users
                    .filter(u => analysts.map(a => a.Id).includes(u.Id))
                    .filter(u => u.IsActive);

                if (this.subscriptionId) {
                    this.loadSubscription(this.subscriptionId);
                } else {
                    this.formLoading = false;
                }
            });
    }

    loadSubscription(subscriptionId: number): void {
        this.subscriptionService.getSubscription(subscriptionId)
            .subscribe(subscription => {

                this.contactId = subscription.ContactId;

                let analystIds = [];
                if (subscription.Analyst) {
                    analystIds = this.allUsers
                        .filter(u => u.LastName === subscription.Analyst)
                        .map(u => u.Id)
                        ;
                }

                let instiselSectorIds = [];
                if (subscription.InstiselSectorId) {
                    instiselSectorIds.push(subscription.InstiselSectorId);
                }

                this.subscriptionForm.patchValue({
                    type: subscription.Analyst ? 'analyst' : 'sector',
                    analysts: analystIds,
                    sectors: instiselSectorIds.map(x => x.toString()),
                    marketCapRange: { low: subscription.MarketCapLow, high: subscription.MarketCapHigh },
                    category: subscription.Category,
                    blueMatrix: subscription.BlueMatrix,
                    marketingEmails: subscription.MarketingEmails,
                    researchEmails: subscription.ResearchEmails,
                    comment: subscription.Comment,
                });
                this.formLoading = false;
            });
    }

    requiredAnalystOrIndustry(): ValidatorFn {
        return (form: UntypedFormGroup) => {
            if (form.get('type').value === 'analyst' && form.get('analysts').value.length === 0) {
                return { missingAnalyst: true };
            } else if (form.get('type').value === 'sector' && form.get('sectors').value.length === 0) {
                return { missingSector: true };
            }
            return null;
        }
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        const initialState = {
            message: 'Are you sure you want to delete this Subscription?',
        };

        let confirmModalRef = this.modalService.show(ConfirmDeleteSubscriptionComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action.subscribe((value) => {

            let loadingModal = this.modalService.show(LoadingModalComponent, {
                initialState: { message: 'Deleting Subscription' },
                animated: false,
                keyboard: false,
                backdrop: 'static'
            });

            if (value === 'SubscriptionAndInterestsAndContactSpecialtyMailings') {
                forkJoin([
                    this.subscriptionService.deleteSubscriptionInterests(this.subscriptionId),
                    this.subscriptionService.deleteSubscriptionContactSpecialtyMailings(this.subscriptionId)
                ])
                    .subscribe(() => {
                        this.subscriptionService.deleteSubscription(this.subscriptionId)
                            .subscribe(() => {
                                this.toastr.success("Subscription Deleted");
                                this.dataUpdated.emit(true);
                                loadingModal.hide();
                                this.modalRef.hide();
                            });
                    });
            }
            else if (value === 'Subscription') {
                this.subscriptionService.deleteSubscription(this.subscriptionId)
                    .subscribe(() => {
                        this.toastr.success("Subscription Deleted");
                        this.dataUpdated.emit(true);
                        loadingModal.hide();
                        this.modalRef.hide();
                    });
            }
        });
    }

    submit() {
        this.subscriptionForm.updateValueAndValidity();

        if (this.subscriptionForm.invalid) {
            return;
        }

        let subscription;
        let analyst;
        let subscriptions$: Observable<Subscription>[] = [];

        if ( this.subscriptionForm.value.type === "analyst") {
            let analystUserIds = this.subscriptionForm.value.analysts
            if (!this.subscriptionId) {
                this.checkedAnalysts.forEach(a => analystUserIds.push(a));
            }

            analystUserIds.forEach(analystUserId => {
                subscription = {
                    ContactId: this.contactId,
                    AnalystUserId: analystUserId,
                    BlueMatrix: this.subscriptionForm.value.blueMatrix,
                    ResearchEmails: this.subscriptionForm.value.researchEmails,
                    MarketingEmails: this.subscriptionForm.value.marketingEmails,
                    Category: this.subscriptionForm.value.category,
                    Comment: this.subscriptionForm.value.comment,
                    MarketCapHigh: this.subscriptionForm.value.marketCapRange.high,
                    MarketCapLow: this.subscriptionForm.value.marketCapRange.low,
                }

                if (this.subscriptionId && subscriptions$.length === 0) {
                    subscriptions$.push(this.subscriptionService.updateSubscription(this.subscriptionId, subscription));
                } else {
                    subscriptions$.push(this.subscriptionService.createSubscription(subscription));
                }
            });

        } else {
            let idToSubscribe;
            this.subscriptionForm.value.sectors
                .forEach(sectorId => {
                    subscription = {
                        ContactId: this.contactId,
                        BlueMatrix: this.subscriptionForm.value.blueMatrix,
                        ResearchEmails: this.subscriptionForm.value.researchEmails,
                        MarketingEmails: this.subscriptionForm.value.marketingEmails,
                        Category: this.subscriptionForm.value.category,
                        Comment: this.subscriptionForm.value.comment,
                        MarketCapHigh: this.subscriptionForm.value.marketCapRange.high,
                        MarketCapLow: this.subscriptionForm.value.marketCapRange.low,
                        InstiselSectorId: sectorId
                    }

                    if (this.subscriptionId && subscriptions$.length === 0) {
                        subscriptions$.push(this.subscriptionService.updateSubscription(this.subscriptionId, subscription));
                    } else {
                        subscriptions$.push(this.subscriptionService.createSubscription(subscription));
                    }
                });
        }

        let loadingModal = this.modalService.show(LoadingModalComponent, {
            initialState: { message: 'Saving Subscriptions' },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        concat(...subscriptions$).pipe(
            finalize( () => {
                loadingModal.hide();
                this.toastr.success("Subscription Saved");
                this.modalRef.hide();
                this.dataUpdated.emit(true);
            })
        ).subscribe(() => {
        });
    }

    changeStatus(event, lastName) {
        if (event.target.checked) {
            this.checkedAnalysts.push(lastName)
        } else {
            let removeIndex = this.checkedAnalysts.findIndex(name => name === lastName);
            if (removeIndex >= 0) {
                this.checkedAnalysts.splice(removeIndex, 1);
            }
        }
    }
}
