import {Observable} from "rxjs";
import {defaultColumnDef, defaultGridOptions, defaultSidebar, getGridDisplayWords} from "./ag-grid-options";
import {ColumnApi, GridApi, GridOptions} from "ag-grid-community";
import * as _ from "lodash";

export abstract class BaseGridComponent<T> {
    protected gridApi: GridApi;
    protected gridColumnApi: ColumnApi;
    
    rowData: T[];
    rowData$: Observable<T[]>;

    defaultColDef = _.cloneDeep(defaultColumnDef);
    gridOptions: GridOptions = _.cloneDeep(defaultGridOptions);
    sideBar = _.cloneDeep(defaultSidebar);

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    getPagingDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }
}