<div class="page-content no-scroll flex-vbox">
    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="travelCitySearchForm">
            <div class="form-group" style="min-width: 175px;">
                <app-travel-territory-picker
                        formControlName="territoryId"
                        [selectClearAll]="false"
                        [multipleSelect]="false"
                        [showTagCount]="false"
                        [closeAfterSelection]="true"
                        [setMaxItems]="1"
                        [includeNullOption]="true"
                        [placeHolderLabel]="'Select Territory'"
                >
                </app-travel-territory-picker>
            </div>
            <div class="form-group" style="min-width: 175px;">
                <app-travel-market-picker
                        formControlName="marketId"
                        [selectClearAll]="false"
                        [multipleSelect]="false"
                        [showTagCount]="false"
                        [closeAfterSelection]="true"
                        [setMaxItems]="1"
                        [includeNullOption]="true"
                        [placeHolderLabel]="'Select Market'"
                        [travelTerritoryIdIsNull]="travelTerritoryIdIsNull"
                        [currentTravelTerritoryId]="travelCitySearchForm.get('territoryId').value"
                >
                </app-travel-market-picker>
            </div>
            <div class="form-group">
                <input
                        type="search"
                        class="form-control input-sm"
                        style="width: 250px;"
                        formControlName="searchTerm"
                        placeholder="Search"
                />
            </div>
            <div class="form-group">
                <div class="btn btn-primary btn-sm" (click)="addTravelCity()"><span class="fa fa-plus"></span></div>
            </div>
        </form>
    </ng-template>
    <app-page-banner
            pagetitle="City Admin"
            faicon="fa-building-o"
            faiconcolor="#800000"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>