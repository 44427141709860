import {Component, forwardRef, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {BaseChartComponent} from "./BaseChart/base-chart.component";
import {TickerService, TickerTradeSummary} from "../../Shared/Services/ticker.service";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
    selector: "app-ticker-volume-chart",
    templateUrl: "./BaseChart/base-chart.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TickerVolumeChartComponent),
            multi: true
        }
    ]
})
export class TickerVolumeChartComponent extends BaseChartComponent {

    @Input()
    ticker: string;
    
    constructor(private tickerService: TickerService) {
        super();
    }
    
    configureChart(): void {
        this.type = 'bar';

        this.options = {
            scales: {
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Volume'
                    },
                    min: 0,
                    max: 1000,
                },
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            },
        }
        
        this.configureData();
    }
    
    configureData(): void {
        this.tickerService.getTickerDailyVolume(this.ticker).subscribe(dailySums => {
            let dataset = _.chain(dailySums)
                .groupBy('TradeDate')
                .map((trades: TickerTradeSummary[], tradeDate) => {
                    return {
                        TradeDate: tradeDate,
                        CategorySums: {
                            B: _.chain(trades).filter(t => t.Side === "B").map(t => t.Volume).sum().value() * 1,
                            S: _.chain(trades).filter(t => t.Side === "S").map(t => t.Volume).sum().value() * -1
                        }
                    }
                })
                .orderBy(sum => sum.TradeDate)
                .value();

            this.data = {
                labels: _.map(dataset, ds => moment(ds.TradeDate).format("L")),
                datasets: [{
                    label: 'B',
                    data: _.map(dataset, ds => ds.CategorySums.B),
                },
                {
                    label: 'S',
                    data: _.map(dataset, ds => ds.CategorySums.S),
                }]
            }
            this.runChart();
        });
    }
}
