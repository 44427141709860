import {NgModule, APP_INITIALIZER} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import 'core-js/es7/reflect';
import { AgGridModule } from 'ag-grid-angular-legacy';
import 'ag-grid-enterprise';

import {WidgetModule} from "./Modules/Widget/widget.module";
import {AdminModule} from "./Modules/Admin/admin.module";
import { UserModule } from "./Modules/User/user.module";

import { ModalModule} from "ngx-bootstrap/modal";
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { setTheme } from 'ngx-bootstrap/utils';
import { ToastrModule } from 'ngx-toastr';
import {ConfigService} from "./Modules/Shared/Services/config.service";
import {AccountModule} from "./Modules/Account/account.module";
import {ContactModule} from "./Modules/Contact/contact.module";
import {ActivityModule} from "./Modules/Activity/activity.module";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {ReportModule} from "./Modules/Reports/report.module";
import {EmailModule} from "./Modules/Email/email.module";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TimepickerConfig, TimepickerModule} from "ngx-bootstrap/timepicker";
import {ErrorModule} from "./Modules/Error/error.module";
import {EventModule} from "./Modules/Event/event.module";
import {QueryModule} from "./Modules/Query/query.module";
import {TickerModule} from "./Modules/Ticker/ticker.module";
import {UserService} from "./Modules/Shared/Services/user.service";
import {InstiselHttpHeaderInterceptor} from "./Modules/Shared/HttpInterceptors/InstiselHttpHeaderInterceptor";
import {MiniProfilerHttpInterceptor} from "./Modules/Shared/HttpInterceptors/mini-profiler-http-interceptor";
import {InterestModule} from "./Modules/Interest/interest.module";
import {ShellModule} from "./Modules/Shell/shell.module";
import {DashboardModule} from "./Modules/Dashboard/dashboard.module";
import {HomeModule} from "./Modules/Home/home.module";
import {TimepickerActions} from "ngx-bootstrap/timepicker";
import { CorpTravelAdminModule } from "./Modules/CorpTravelAdmin/corptravel-admin.module";
import { ShellComponent } from "./Modules/Shell/ShellComponent/shell.component";
import {EntitlementsModule} from "./Modules/Entitlements/entitlements.module";
import {InteractionType, IPublicClientApplication, PublicClientApplication} from "@azure/msal-browser";
import {AuthConfigService} from "./Modules/Shared/Services/auth-config.service";
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService
} from "@azure/msal-angular";
import {AppInitializerService} from "./Modules/Shared/Services/app-initializer.service";

const appInitializerFn = (appInitializerService: AppInitializerService) => {
    return () => {
        appInitializerService.initialize();
    };
};

export function MSALInstanceFactory(authConfigService: AuthConfigService): IPublicClientApplication {
    return new PublicClientApplication(authConfigService.getMsalConfig());
}

export function MSALInterceptorConfigFactory(authConfigService: AuthConfigService): MsalInterceptorConfiguration {
    const protectedResourceMap = authConfigService.getProtectedResources();
    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap
    };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ["user.read"]
        },
        loginFailedRoute: '/accessdenied'
    };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AgGridModule,
        WidgetModule,
        ShellModule,
        AdminModule,
        CorpTravelAdminModule,
        UserModule,
        AccountModule,
        EventModule,
        HomeModule,
        ContactModule,
        ActivityModule,
        InterestModule,
        ReportModule,
        EmailModule,
        ErrorModule,
        QueryModule,
        TickerModule,
        DashboardModule,
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        ToastrModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        HomeModule,
        MsalModule,
        EntitlementsModule,

        AppRoutingModule,
    ],
    providers: [
        ConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppInitializerService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InstiselHttpHeaderInterceptor,
            multi: true,
            deps: [UserService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MiniProfilerHttpInterceptor,
            multi: true,
            deps: []
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
            deps: [AuthConfigService, ConfigService]
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
            deps: [AuthConfigService, ConfigService]
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        TimepickerConfig,
        TimepickerActions
    ],
    bootstrap: [ShellComponent, MsalRedirectComponent]
})
export class AppModule {
    constructor() {
        console.log("Angular is running!");

        setTheme(`bs3`);
    }
}
