<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title text-center">A client already exists with the email or name.</h4>
        <h4 class="modal-title text-center">Do you still want to continue?</h4>
    </div>
    <div class="modal-body">
        <div style="height: 200px;">
            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox flex-gap">
            <button type="submit" class="btn btn-primary flex" (click)="confirm()">Yes</button>
            <button type="button" class="btn btn-default flex" (click)="close()">No</button>
        </div>
    </div>
</div>

