import {Injectable} from "@angular/core";
import {User} from "../../../Models/User";
import {UserService} from "./user.service";

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    trackingId: string;
    
    constructor(private userService: UserService) {
        this.trackingId = window["instiselconfig"].AnalyticsMeasurementId;
    }

    configureAnalytics(user: User): void {

        (<any>window).dataLayer = (<any>window).dataLayer || [];
        if (user.hasOwnProperty("Id")) {
            let gaScript = document.createElement('script');
            gaScript.setAttribute('async', 'true');
            gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${ this.trackingId }`);
            document.documentElement.firstChild.appendChild(gaScript);

            this.gtag('js', new Date());

            this.gtag('config', this.trackingId, {
                debug_mode: false,
                user_id: user?.Id,
                userid: user?.Id,
                user_team: user?.Team?.Name,
                user_role: user?.Role?.Name
            });
        }
    }
    
    gtag(... params: any[]) {
        (<any>window).dataLayer.push(arguments);
    }
    
    logEvent(eventName: string, eventParams: any) {
        this.gtag('event', eventName, eventParams);
    }
}