<div>
    <div class="title pull-left" *ngIf="titleTemplate">
        <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    </div>
    <div>
        <button type="button" style="margin-bottom: 3px;"
                *ngIf="tickersControl.enabled"
                class="btn btn-xs btn-primary pull-right"
                (click)="search()">
            <span class="fa fa-search"></span>
            Search
        </button>
        <button type="button" style="margin-bottom: 3px; margin-right: 5px;" 
                *ngIf="showHasUncovered && hasUncovered"
                class="btn btn-xs btn-danger pull-right" 
                (click)="clearUncovered()">
            <span class="fa fa-remove"></span>
            Uncovered
        </button>
        <button type="button" style="margin-bottom: 3px; margin-right: 5px;"
                *ngIf="hasExisting"
                class="btn btn-xs btn-warning pull-right" 
                (click)="clearExisting()">
            <span class="fa fa-remove"></span>
            Existing
        </button>
    </div>
    <div style="clear: both;">
        <ng-select #tickersNgSelect
                   class="custom"
                   [formControl]="tickersControl"
                   [maxSelectedItems]="maximumTickers"
                   [items]="tickers$ | async"
                   [typeahead]="userInput$"
                   [minTermLength]="1"
                   [addTag]="addTagFn"
                   [addTagText]="'Add ticker'"
                   [keyDownFn]="onKeyDown"
                   bindLabel="Name"
                   bindValue="Name"
                   (paste)="onPaste($event)"
                   placeholder="Enter a Ticker"
                   [hideSelected]="true"
                   [multiple]="true"
                   [selectOnTab]="true">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <div>
                    <div [ngOptionHighlight]="search">{{item.Name}} ({{item.CompanyName}})</div>
                </div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <span aria-hidden="true" class="ng-value-icon left" [ngClass]="{ uncovered: isUncovered(item.Name), existing: isExisting(item.Name)}" (click)="removeTicker(item.Name)">×</span>
                <span class="ng-value-label" [ngClass]="{ uncovered: isUncovered(item.Name), existing: isExisting(item.Name)}">{{item.Name}}</span>
            </ng-template>
        </ng-select>
    </div>
</div>
