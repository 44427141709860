import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService} from "../../Shared/Services/base-http.service";

export interface TransportationProvider {
    Id?: number;
    TypeId: number;
    Name: string;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    Zip: string;
    Country: string;
    Phone: string;
    Fax: string;
    Email: string;
    ContactName: string;
    AccountNumber: string;
    Notes: string;
    CityId: number;
    Deleted: string;
}

export interface TransportationType {
    Id: number;
    Name: string;
}

export interface TransportationProviderForListComponent extends TransportationProvider {
    CityNameForCityId: string;
    TypeNameForTypeId: string;
}

@Injectable({
    providedIn: 'root',
})
export class TransportationProviderService {

    constructor(private baseHttp: BaseHttpService) { }

    getProviders(): Observable<TransportationProvider[]> {
        return this.baseHttp.getData(`/transportationprovider`);
    }

    getProviderById(providerId: number): Observable<TransportationProvider> {
        return this.baseHttp.getData(`/transportationprovider/id/${providerId}`);
    }

    createOrUpdateProvider(provider: TransportationProvider): Observable<TransportationProvider> {
        return this.baseHttp.postData(`/transportationprovider`, provider);
    }
    
    getProviderType(): Observable<TransportationType[]>{
        return this.baseHttp.getData('/transportationtype');
    }
}
