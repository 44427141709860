import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import * as moment from "moment/moment";

@Injectable({
    providedIn: 'root',
})

export class RevenueTargetService {

    constructor(private baseHttp: BaseHttpService) { }

    getByAccount(id: number): Observable<RevenueTarget[]> {
        return this.baseHttp.getCacheOrData(`/account/${id}/revenuetarget`, 1000);
    }

    getRevenueTarget(id: number): Observable<RevenueTarget> {
        return this.baseHttp.getData(`/revenuetarget/${id}`);
    }

    updateRevenueTarget(id: number, revTarget: RevenueTarget): Observable<RevenueTarget> {
        return this.baseHttp.postData(`/revenuetarget/${id}`, revTarget);
    }

    deleteRevenueTarget(id: number): Observable<void> {
        return this.baseHttp.deleteData(`/revenuetarget/${id}`);
    }
}

export interface RevenueTarget {
    Id: number;
    AccountId: number;
    ComdolId: string;
    IsMine: boolean;
    Year: number;
    Target: number;
    Note: string;
    City: string;
    CreatedBy: string;
    CreatedOn: string | moment.Moment;
    UpdatedBy: string;
    UpdatedOn: string | moment.Moment;
}
