import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {HoldingService} from "../../../Shared/Services/holding.service";

@Component({
    selector: "app-holding-category-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(HoldingCategoryPickerComponent)
})
export class HoldingCategoryPickerComponent extends BasePickerComponent<any> {

    @Input()
    placeHolder: string = "";
    
    @Input()
    name: string = "Holding Category(s)";
    
    constructor(fb: UntypedFormBuilder, private holdingService: HoldingService){
        super(fb);
        
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.items$ = this.holdingService.getHoldingCategories();
    }
}
