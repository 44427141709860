import { NgModule } from "@angular/core";
import {InvalidUserComponent} from "./invalid-user.component";
import {PageNotFoundComponent} from "./page-not-found.component";


@NgModule({
    declarations: [
        InvalidUserComponent,
        PageNotFoundComponent
    ],
})
export class ErrorModule {
    constructor(){
        console.log("registering the ErrorModule");
    }
}