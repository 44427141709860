<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline">
            <input type="search" 
                   class="form-control"
                   style="width: 250px;"
                   [formControl]="userSearchBox" 
                   placeholder="Search" />
        </form>
    </ng-template>
    
    <app-page-banner 
            pagetitle="User List" 
            faicon="fa-users" 
            faiconcolor="#8ad419"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
        
    </div>
</div>
