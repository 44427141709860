import {NgModule} from "@angular/core";
import {PresentationNamePipe} from "./PresentationName/presentation-name.pipe";
import {TrustHtmlPipe} from "./trust-html.pipe";
import {AccountLocationPipe} from "./account-location.pipe";

@NgModule({
    imports: [],
    declarations: [
        PresentationNamePipe,
        TrustHtmlPipe,
        AccountLocationPipe
    ],
    exports: [
        PresentationNamePipe,
        TrustHtmlPipe,
        AccountLocationPipe
    ]
})
export class PipesModule {
    constructor(){}
}
