import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import {orderActivityStatusesByDefaultDisplayOrder} from "../../../../Helpers/ActivityStatusHelper";

@Component({
    selector: "app-activity-status-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ActivityStatusPickerComponent)
})
export class ActivityStatusPickerComponent extends BasePickerComponent<any> {

    @Input()
    placeHolder: string = "";

    @Input()
    multiple: boolean = true;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    useNameAsId: boolean = false;
    
    @Input()
    alignRight: boolean = false;

    @Input()
    includeEmptyOption: boolean = false;

    @Input()
    excludeReplay: boolean = false;
    
    @Input()
    appendTo: string = null;

    replayStatus = "Replay";
    
    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);
        
        this.name = "Activity Status(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }

    ngOnInit() {
        super.ngOnInit();
        this.showSelectClearAll = this.selectClearAll;

        this.items$ = this.activityService.getActivityStatuses();

        if (this.useNameAsId) {
            this.items$ = this.items$.pipe(
                map(status => status.map(s => { return { Id: s.Name, Name: s.Name }}))
            );
        }
        
        if(this.excludeReplay) {
            this.items$ = this.items$.pipe(
                map(statuses => statuses.filter(s => s.Name !== this.replayStatus))
            );
        }

        this.items$ = this.items$.pipe(
            map(statuses => orderActivityStatusesByDefaultDisplayOrder(statuses))
        );

        if (this.includeEmptyOption) {
            this.items$ = this.items$.pipe(
                map(statuses => {
                    if (!this.multiple) {
                        statuses.unshift({Id: '', Name: ' '});
                    }
                    return statuses;
                })
            );
        }
    }
}
