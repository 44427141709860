<div class="modal-content">
    <form [formGroup]="contactForm" id="contactForm">
        <div class="modal-header" *ngIf="contactDetails && contactDetails.Id">
            <div class="flex-hbox">
                <div class="flex-3">
                    <h3 class="modal-title">
                        {{ newAccount ? "Move" : "Edit" }}
                        {{ contactDetails && contactDetails.LastName }}, {{ contactDetails && contactDetails.FirstName}}
                    </h3>
                </div>
                <div class="flex" *ngIf="!newAccount">
                    <label>Primary Broker</label>
                    <div>{{ contactDetails && contactDetails.Account && contactDetails.Account.PrimaryBroker && contactDetails.Account.PrimaryBroker.LastName }}</div>
                </div>
                <div class="flex" *ngIf="!newAccount">
                    <label>Primary Trader</label>
                    <div>{{ contactDetails && contactDetails.Account && contactDetails.Account.PrimaryTrader && contactDetails.Account.PrimaryTrader.LastName }}</div>
                </div>
                <div class="flex" *ngIf="!newAccount">
                    <label>Persnum</label><br>
                    <div>{{ contactDetails && contactDetails.Id }}</div>
                </div>
                <div class="flex" *ngIf="!newAccount && !moveAccount" appRequiresFeature="contact-move-account">
                    <button class="btn btn-xs btn-primary" (click)="setupContactMove()">Move</button>
                </div>
            </div>
            <div class="flex-hbox">
                <div class="flex">
                    <div>{{ contactDetails && contactDetails.AccountName }}</div>
                    <div>{{ getAccountAddress() }}</div>
                </div>
                <div *ngIf="newAccount" style="margin-right: 30px;">
                    <span class="fa fa-arrow-right"></span>
                </div>
                <div class="flex" *ngIf="newAccount">
                    <div>{{ newAccount.Name }}</div>
                    {{ getNewAccountAddress() }}
                    <div *ngIf="newAccountDifferentParent" class="text-danger">Different Parent Comdol Id</div>
                </div>
            </div>
        </div>
        <div class="modal-header" *ngIf="accountDetails">
            <div class="flex-hbox">
                <div class="flex-3">
                    <h3 class="modal-title">Add Contact</h3>
                </div>
                <div class="flex">
                    <label>Primary Broker</label>
                    <div>{{ accountDetails && accountDetails.PrimaryBroker && accountDetails.PrimaryBroker.LastName }}</div>
                </div>
                <div class="flex">
                    <label>Primary Trader</label>
                    <div>{{ accountDetails && accountDetails.PrimaryTrader && accountDetails.PrimaryTrader.LastName }}</div>
                </div>
                <div class="flex">

                </div>
            </div>
            <div class="flex-hbox">
                <div class="flex">
                    <div>{{ accountDetails && accountDetails.Name }}</div>
                </div>
            </div>
            <div class="flex-hbox">
                <div class="flex">
                    <div>{{ getAccountAddress() }}</div>
                </div>
            </div>
        </div>
        <div class="modal-body form-gap">
            <tabset>
                <tab heading="Details" [active]="defaultTab === 'Details'">
                    <div class="flex-hbox">
                        <div style="width: 60px">
                            <label>Mr/Ms</label>
                            <input type="text" class="form-control input-sm" maxlength="10"
                                   formControlName="salutation"/>
                        </div>
                        <div class="flex-2 ">
                            <label>First Name/MI</label>
                            <input type="text" class="form-control input-sm" maxlength="25"
                                   formControlName="firstName"/>
                        </div>
                        <div class="flex-2 ">
                            <label>Corresp Name</label>
                            <input type="text" class="form-control input-sm" maxlength="30"
                                   formControlName="correspName"/>
                        </div>
                        <div class="flex-2 ">
                            <label>Last Name</label>
                            <input type="text" class="form-control input-sm" maxlength="50" formControlName="lastName"/>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="flex">
                            <ng-template #assistantsLabel>
                                <label [ngClass]="{'text-danger': newAccount}">Assistant(s)</label>
                            </ng-template>
                            <app-multi-contact-select
                                    [titleTemplate]="assistantsLabel"
                                    [showEditButtons]="false"
                                    [accountId]="accountId"
                                    [placeholder]="'Select an Assistant'"
                                    formControlName="assistants">
                            </app-multi-contact-select>
                        </div>
                        <div class="flex">
                            <label [ngClass]="{'text-danger': newAccount}">Personal Broker</label>
                            <app-broker-picker formControlName="personalBroker"
                                               [selectClearAll]="false"
                                               [multipleSelect]="false"
                                               [showTagCount]="false"
                                               [closeAfterSelection]="true"
                                               [setMaxItems]="1"
                                               [placeHolderLabel]="'Select Personal Broker'">
                            </app-broker-picker>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="flex">
                            <label [ngClass]="{'text-danger': newAccount}">Phone</label>
                            <input type="text" class="form-control input-sm" maxlength="20" formControlName="phone"/>
                        </div>
                        <div class="flex">
                            <label [ngClass]="{'text-danger': newAccount}">Email</label>
                            <input type="email" class="form-control input-sm" formControlName="email"/>
                            <div *ngIf="contactForm.controls['email'].hasError('email')">
                                <span class="text-danger">Invalid Email Address</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="flex">
                            <div>
                                <label>Mobile Phone</label>
                                <input type="text" class="form-control input-sm" maxlength="20"
                                       formControlName="mobilePhone"/>
                            </div>
                            <div class="flex-hbox" style="margin-top: 10px">
                                <div class="flex">
                                    <label>Tier</label>
                                    <input type="text" class="form-control input-sm" maxlength="2"
                                           formControlName="tier"/>
                                </div>
                                <div class="flex">
                                    <label>Buy Back Client</label>
                                    <select class="form-control input-sm" formControlName="buyBackClient">
                                        <option [ngValue]="true">Y</option>
                                        <option [ngValue]="false">N</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-vbox">
                            <label [ngClass]="{'text-danger': newAccount}">Alternate Email(s)</label>
                            <div class="form-group flex-hbox" style="gap: 5px" formArrayName="alternateEmails"
                                 *ngFor="let email of contactForm.get('alternateEmails')['controls']; let i = index;">
                                <input [formControl]="email" type="email" class="flex form-control input-sm"
                                       style="margin-right: 5px"/>
                                <button type="button" class="btn btn-sm btn-default" (click)="removeEmail(i)"><span
                                        class="fa fa-remove"></span></button>
                            </div>
                            <div>
                                <button type="button" class="btn btn-sm btn-default" (click)="addEmail()"><span
                                        class="fa fa-plus"></span></button>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Profile" [active]="defaultTab === 'Profile'">
                    <div class="text-right" appRequiresFeature="contact-form-copy-profile">
                        <button type="button" class="btn btn-xs btn-primary" (click)="copyProfile()">Copy Profile
                        </button>
                    </div>
                    <div class="flex-hbox flex-gap">
                        <div class="flex">
                            <label>Title</label>
                            <input class="form-control input-sm" formControlName="title" maxlength="200"/>
                        </div>
                        <div class="flex">
                            <label>Role(s)</label>
                            <app-role-picker formControlName="roleIds"
                                             [placeHolder]="'Select Role(s)'"></app-role-picker>
                        </div>
                    </div>

                    <div class="flex-hbox flex-gap">
                        <div class="flex flex-hbox">
                            <div class="flex">
                                <label>Mkt Cap Low (Bn)</label>
                                <input type="number" class="form-control input-sm" formControlName="marketCapLow"/>
                            </div>
                            <div class="flex">
                                <label>Mkt Cap High (Bn)</label>
                                <input type="number" class="form-control input-sm" formControlName="marketCapHigh"/>
                            </div>
                        </div>
                        <div class="flex flex-hbox">
                            <div class="flex">
                                <label>Investor Style(s)</label>
                                <app-string-picker formControlName="investorStyles" [items$]="investorStyles$"
                                                   [placeHolder]="'Select Style(s)'"
                                                   [name]="'Style(s)'"></app-string-picker>
                            </div>
                        </div>
                    </div>

                    <div class="flex-hbox flex-gap">
                        <div class="flex flex-hbox">
                            <div class="flex">
                                <label>Global Investor</label>
                                <select class="form-control input-sm" formControlName="globalInvestor">
                                    <option [ngValue]="null"></option>
                                    <option [ngValue]="true">Y</option>
                                    <option [ngValue]="false">N</option>
                                </select>
                            </div>
                            <div class="flex">
                                <label>Private Investment</label>
                                <select class="form-control input-sm" formControlName="privateInvestment">
                                    <option [ngValue]="null"></option>
                                    <option [ngValue]="true">Y</option>
                                    <option [ngValue]="false">N</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex">
                            <label>Orientation</label>
                            <app-string-picker formControlName="orientations" [items$]="allOrientations$"
                                               [placeHolder]="'Select Orientation(s)'"
                                               [name]="'Orientation(s)'"></app-string-picker>
                        </div>
                    </div>

                    <div class="flex-hbox flex-gap">
                        <div class="flex">
                            <label>Deal Player</label>
                            <app-string-picker formControlName="dealPlayer" [items$]="dealPlayerTypes$"
                                               [placeHolder]="'Select Type(s)'" [name]="'Type(s)'"></app-string-picker>
                        </div>
                        <div class="flex">
                            <label>Team(s)</label>
                            <ng-select [items]="allTeams$ | async"
                                       bindLabel="Label"
                                       bindValue="Label"
                                       placeholder="Select Team(s)"
                                       appendTo="body"
                                       [addTag]="true"
                                       [clearSearchOnAdd]="true"
                                       [multiple]="true"
                                       [selectOnTab]="true"
                                       [searchable]="true"
                                       [closeOnSelect]="false"
                                       formControlName="teams">
                                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-search="searchTerm">
                                    <input type="checkbox" [checked]="item$.selected"/> <span
                                        [ngOptionHighlight]="search">{{item.Label}}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </tab>
                <tab heading="Preferences" [active]="defaultTab === 'Preferences'">
                    <div class="flex-hbox flex-gap">
                        <div class="flex">
                            <label>BlueMatrix Report Type</label>
                            <app-string-picker formControlName="reportType" [items$]="reportTypes$" [name]="'Selected'"
                                               [placeHolder]="'Select Report(s)'"></app-string-picker>
                        </div>
                        <div class="flex">
                            <label>
                                BM Secure Links
                                <span class="fa fa-info-circle" tooltip="Controlled at the account level"
                                      *ngIf="accountSecureLinksEnabled"></span>
                            </label>
                            <select class="form-control input-sm" formControlName="secureLinks">
                                <option [ngValue]="true">Y</option>
                                <option [ngValue]="false">N</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>BM AutoSubscribe</label>
                            <select class="form-control input-sm" formControlName="autoSubscribe">
                                <option [ngValue]="true">Y</option>
                                <option [ngValue]="false">N</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex-hbox flex-gap">
                        <div class="flex">
                            <label>No Contact</label>
                            <select class="form-control input-sm" formControlName="noContact">
                                <option [ngValue]="true">Y</option>
                                <option [ngValue]="false">N</option>
                            </select>
                            <div style="margin-top: 10px" *ngIf="contactForm.get('noContact').value">
                                <app-string-picker formControlName="noContactReason"
                                                   [items$]="noContactReasons$"
                                                   [multiple]="false"
                                                   [showSelectClearAll]="false"
                                                   [name]="'Selected'"
                                                   [placeHolder]="'Select Reason'">
                                </app-string-picker>
                                <textarea class="form-control input-sm" formControlName="noContactNote"
                                          style="margin-top: 10px; resize: vertical"></textarea>
                            </div>
                        </div>
                        <div class="flex">
                            <label>Research VMs</label>
                            <select class="form-control input-sm" formControlName="blastVoiceMailAllowed">
                                <option [ngValue]="true">Y</option>
                                <option [ngValue]="false">N</option>
                            </select>
                        </div>
                        <div class="flex">
                            <label>Overrride Email From Analyst</label>
                            <select class="form-control input-sm" formControlName="overrideEmail">
                                <option [ngValue]="true">Y</option>
                                <option [ngValue]="false">N</option>
                            </select>
                        </div>
                    </div>
                    <div style="margin-bottom: 15px"><h3>Entitlements</h3></div>
                    <div class="form-group flex-hbox" formArrayName="entitlements">
                        <div *ngFor="let entitlementGroup of contactForm.get('entitlements')['controls']; let i = index"
                             [formGroupName]="i" style="flex: 0 1 calc(50% - 15px); margin-bottom: 10px">
                            <div class="">
                                <label>{{ entitlementGroup.get('description').value }}</label>
                                <button *ngIf="entitlementGroup.get('customModal').value && contactId"
                                        type="button"
                                        class="btn btn-primary btn-xs pull-right"
                                        [appIfUserFeature]="canEditResearchOnlineEntitlementFeature"
                                        (click)="openCustomEditModal(
                                        entitlementGroup.get('customModal').value,
                                        entitlementGroup.get('code').value, i)">
                                    Edit
                                </button>

                                <div class="form-group flex-vbox" formArrayName="vendorIds">
                                    <div *ngIf="entitlementGroup.get('code').value === 'RES'; else multipleInputs">
                                        <input [formControlName]="0" type="text" class="flex-1 form-control input-sm" disabled/>
                                    </div>
                                    <ng-template #multipleInputs>
                                        <div *ngFor="let vendorIdControl of entitlementGroup.get('vendorIds').controls; let j = index" class="flex-hbox form-group" style="gap: 8px; margin-right: 40px" >
                                            <input *ngIf="vendorIdControl.get" [formControlName]="j" type="text" class="flex-1 form-control input-sm " />
                                            <button type="button" class="btn btn-sm btn-default"  (click)="removeVendorId(i, j)"  >
                                                <span class="fa fa-remove"></span>
                                            </button>
                                        </div>
                                        <div>
                                            <button type="button" class=" btn btn-sm btn-default" (click)="addVendorId(i)">
                                                <span class="fa fa-plus"></span>
                                            </button>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab heading="Employment" [active]="defaultTab === 'Employment'" *ngIf="canViewEmployment">
                    <div class="flex flex-vbox">
                        <label>Employment History</label>
                        <div *ngIf="contactForm.get('employments')['controls'].length > 0">
                            <label>End Date</label>
                        </div>
                        <div class="form-group flex-hbox" style="gap: 5px" formArrayName="employments"
                             *ngFor="let employment of contactForm.get('employments')['controls']; let i = index;">
                            <input class="flex-1" [formControl]="employment.get('endDate')" type="date" class="flex form-control input-sm"
                                   style="margin-right: 5px"/>
                            <app-multi-account-select class="flex-2" [formControl]="employment.get('accountId')" [maxItems]="1"></app-multi-account-select>
                            <button type="button" class="btn btn-sm btn-default" (click)="removeEmployment(i)"><span
                                    class="fa fa-remove"></span></button>
                        </div>
                        <div>
                            <button type="button" class="btn btn-sm btn-default" (click)="addEmployment()"><span
                                    class="fa fa-plus"></span></button>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="btn btn-sm btn-primary flex" [disabled]="contactForm.invalid"
                        (click)="save()">Save
                </button>
                <button type="button" class="btn btn-sm btn-danger flex" appIfUserFeature="EditContact"
                        *ngIf="contactId && !newAccount" (click)="delete()">Delete
                </button>
                <button type="button" class="btn btn-sm btn-default flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
