import {Injectable} from "@angular/core";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable} from "rxjs";

export interface BairdAnalyst {
  Id?: number;
  FirstName: string;
  MiddleInitial: string;
  LastName: string;
  Title: string;
  Phone: string;
  Mobile: string;
  Email: string;
  Deleted: string;
}

@Injectable({
  providedIn: 'root',
})
export class BairdAnalystService {
  constructor(private baseHttp: BaseHttpService) { }

  getAnalysts(): Observable<BairdAnalyst[]> {
    return this.baseHttp.getData(`/bairdanalyst`);
  }

  getAnalystById(analystId: number): Observable<BairdAnalyst> {
    return this.baseHttp.getData(`/bairdanalyst/id/${analystId}`);
  }

  createOrUpdateAnalyst(analyst: BairdAnalyst): Observable<BairdAnalyst> {
    return this.baseHttp.postData(`/bairdanalyst`, analyst);
  }
}

