import {Component} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as _ from "lodash";

@Component({
    selector: "app-files-upload",
    template: `
        <div>
            <input class="file-input" type="file" multiple (change)="upload($event)"/>
        </div>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FilesUploadComponent,
            multi: true
        }
    ]
})

export class FilesUploadComponent implements ControlValueAccessor {

    onChange = (updatedData: File[]) => {};
    onTouched = () => {};
    
    files: File[] = [];
    
    upload($event: any): void {
        this.files = _.unionBy(this.files, $event.target.files, 'name');
        this.onChange(this.files);
        
    }

    registerOnChange(fn: (updatedData: File[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: File[]): void {

        if (obj) {
            this.files = _.unionBy(this.files, obj, 'name');
        }

        this.onChange(obj);
    }
}