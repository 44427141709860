import {Component, forwardRef, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {combineLatest, forkJoin, Observable, of, Subject} from "rxjs";
import * as _ from "lodash";
import {Contact} from "../../../Models/Contact";
import {ContactService} from "../../Shared/Services/contact.service";
import {catchError, debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
} from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../ConfirmModal/confirm-modal.component";
import {EditContactsModalComponent} from "./edit-contacts-modal.component";
import {AddContactsModalComponent} from "./add-contacts-modal.component";
import {isContactable} from "../../Shared/research-status";


@Component({
    selector: "app-multi-contact-select",
    templateUrl: "./multi-contact-select.component.html",
    styleUrls: ['./multi-contact-select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiContactSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiContactSelectComponent),
            multi: true
        }
    ]
})
export class MultiContactSelectComponent implements OnInit, ControlValueAccessor {

    @Input()
    maxItems: number = 50000;

    @Input()
    placeholder: string = "Add a contact";

    @Input()
    accountId: number;

    @Input()
    requireEmail: boolean = false;

    @Input()
    contactRequired: boolean = false;

    @Input()
    titleTemplate: TemplateRef<any>;

    @Input()
    showEditButtons: boolean = true;

    @Input()
    bairdContacts: boolean = false;

    @Input()
    includeDoNotContactContacts: boolean = true;

    @Input()
    readOnly: boolean = false;

    @Input()
    contactIdsToExclude: number[] = [];

    @Input()
    showContactId = false;

    contacts$: Observable<AutocompleteContactItem[]>;
    userInput$ = new Subject<string>();

    onChange = (cids: number[]) => {};
    onTouched = () => {};
    invalidPastedEmails: string[] = [];
    invalidPastedIds: string[] = [];
    invalidPastedTerms: string[] = [];
    deduplicatedEmails: string[] = [];
    deduplicatedIds: string[] = [];

    contacts: UntypedFormControl = this.fb.control([], [_.bind(this.isContactsValid, this)]);
    disabled: boolean = false;

    @ViewChild('contactsNgSelect', { static: true }) contactsNgSelect;

    constructor(private fb: UntypedFormBuilder,
                private contactService: ContactService,
                private modalService: BsModalService) {}

    ngOnInit(): void {
        this.contacts$ = this.userInput$.pipe(
            distinctUntilChanged(),
            debounceTime(250),
            switchMap(term => {
                if (this.bairdContacts) {
                    return this.contactService.getBairdContacts()
                        .pipe(
                            catchError(() => of([])), // empty list on error
                            map((contacts: Contact[]) => {
                                if (!term) return [];
                                return contacts
                                    .filter(c => c.FirstName.toLowerCase().includes(term.toLowerCase()) ||
                                                 c.LastName.toLowerCase().includes(term.toLowerCase())
                                    )
                            })
                        );
                } else {
                    return this.contactService.getContactsForAccount(term, this.accountId?.toString())
                        .pipe(
                            catchError(() => of([])), // empty list on error
                        );
                }
            }),
            map((contacts: Contact[]) => {
                let x = _.chain(contacts)
                    .filter(contact => !this.contacts.value.map(c => c.id).includes(contact.Id) && !this.contactIdsToExclude?.some(x => x === contact.Id))
                    .map(_.bind(this.getAutocompleteContact, this))
                    .take(10)
                    .value();
                return x;
            })
        );

        this.contacts.valueChanges.subscribe(values => {
            this.onChange(values.map(c => c.id));
        })
    }

    isContactsValid(control: AbstractControl): ValidationErrors | null {
        if (this.contactRequired && control.value.length === 0) return { required: true };
        return null;
    }

    validate({ value }: UntypedFormControl) {
        if (this.contacts.invalid) {
            return {invalid: true};
        }
        return null;
    }

    private isContactDisabled(contact: Contact): boolean {
        const existingContactIds = this.contacts.value.map(c => c.id) ?? [];
        const existingContactEmails = this.contacts.value.map(c => c.email.toLowerCase()) ?? [];
        const canContact = this.includeDoNotContactContacts || isContactable(contact);

        const requiredEmailAlreadyExists = (this.requireEmail ?
            !contact.Email || existingContactEmails.includes(contact.Email.toLowerCase())
            : false);

        const contactAlreadyExists = existingContactIds.includes(contact.Id);

        return !canContact || requiredEmailAlreadyExists || contactAlreadyExists;
    }

    private getAutocompleteContact(contact: Contact): AutocompleteContactItem {
        return {
            id: contact.Id,
            text: this.getContactText(contact),
            email: contact.Email?.toLowerCase(),
            disabled: this.isContactDisabled(contact)
        };
    }

    private getContactText(contact: Contact): string {
        let contactInformation = `${contact.LastName}, ${contact.FirstName}`;
        if (contact.AccountName) {
            contactInformation += ` (${contact.AccountName})`;
        }

        if (this.showContactId)
            contactInformation += ` (${contact.Id})`;

        return contactInformation;
    }

    registerOnChange(fn: (cids: number[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(contactIds: number[]): void {
        this.invalidPastedEmails = [];
        this.invalidPastedIds = [];
        this.invalidPastedTerms = [];
        this.deduplicatedEmails = [];
        if (!contactIds || contactIds.length === 0) {
            this.contacts.patchValue([]);
        } else {
            this.setValue(contactIds);
        }
    }

    setValue(contactIds: number[]): void {
        this.contacts.patchValue([]); // We need to empty out the contacts list so prevent duplicate checks since we are reading all contacts
        this.contactService.getContactsByIds(contactIds)
            .subscribe(contacts => {
                this.contacts.patchValue(
                    contacts
                        .sort((a, b) => a.LastName.localeCompare(b.LastName))
                        .map(c => this.getAutocompleteContact(c))
                );
            });
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.contacts.disable();
        } else {
            this.contacts.enable();
        }
    }

    clear() {
        const initialState = {
            message: 'Are you sure you want to clear contacts?',
        };

        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action.subscribe(isConfirmed => {
            if (isConfirmed) {
                this.contacts.patchValue([]);
                this.invalidPastedEmails = [];
                this.invalidPastedIds = [];
                this.invalidPastedTerms = [];
                this.deduplicatedEmails = [];
            }
        });
    }

    edit() {
        let confirmModalRef = this.modalService.show(EditContactsModalComponent, {
            initialState: { contactIds: this.contacts.value.map(c => c.id) },
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-lg'
        });

        confirmModalRef.content.action.subscribe(updateContactIds => {
            this.setValue(updateContactIds);
        });
    }

    add() {
        let confirmModalRef = this.modalService.show(AddContactsModalComponent, {
            initialState: { includeDoNotContactContacts: this.includeDoNotContactContacts },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action.subscribe(newContactIds => {
            if (newContactIds.length > 0) {
                const allContacts: AutocompleteContactItem[] = this.contacts.value;
                if (this.requireEmail) {
                    this.deduplicatedEmails = allContacts.filter(c => newContactIds.includes(c.id)).map(c => c.email?.toLowerCase());
                }

                this.setValue(_.chain(allContacts).map(c => c.id).concat(newContactIds).uniq().value());
            }
        });
    }

    onPaste($event: ClipboardEvent) {
        $event.stopPropagation();
        let pastedText = $event.clipboardData.getData("text");

        this.invalidPastedTerms = []; // term is not a number or email
        this.invalidPastedEmails = []; // email does not exist
        this.invalidPastedIds = []; // id does not exist
        this.deduplicatedEmails = []; // pasted email already exists in selected list
        this.deduplicatedIds = []; // pasted persnum already exists or person's email already exists

        const EMAIL_REGEXP = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const CONTACTID_REGEXP = /^\d+$/;
        const splitTerms = _.map(_.uniq(_.words(pastedText, /[^,\s;|]+/g)), word => word.trim());
        let [validEmails, invalidEmails] = _.partition(splitTerms, e => EMAIL_REGEXP.test(e.toLowerCase()));
        let [validCIds, invalidTerms] = _.partition(invalidEmails, e => CONTACTID_REGEXP.test(e));
        let validContactIds = _.map(validCIds, cid => parseInt(cid));

        this.invalidPastedTerms = _.chain(this.invalidPastedTerms).concat(invalidTerms).uniqBy(e => e.trim().toLowerCase()).value();

        const groupsOfEmails = _.chunk(validEmails, 50);

        if (groupsOfEmails.length > 0 || validContactIds.length > 0) {

            let contactsChunks$: Observable<Contact[]>[] = [];
            contactsChunks$.push(...groupsOfEmails.map(emails => this.contactService.getContactsByEmails(emails)));

            let contactsByEmail$ = contactsChunks$.length > 0 ? forkJoin(contactsChunks$).pipe(
                map((contactChunks): Contact[] => _.flatMap(contactChunks))
            ) : of([]);

            let contactsById$ = this.contactService.getContactsByIds(validContactIds);

            combineLatest([contactsById$, contactsByEmail$])
                .subscribe(([contactsById, contactsByEmail]) => {

                    this.invalidPastedIds = validContactIds.filter(vcid => !contactsById.map(c => c.Id).includes(vcid)).map(cid => cid.toString());
                    this.invalidPastedEmails = validEmails.filter(vce => !contactsByEmail.map(c => c.Email.toLowerCase()).includes(vce.toLowerCase()));

                    let existingContactIds = this.contacts.value.map(c => c.id);
                    let existingContactEmails = this.contacts.value.map(c => c.email.toLowerCase())

                    let newContacts: Contact[] = [];

                    contactsById.forEach(contact => {
                        if (existingContactIds.includes(contact.Id) || this.contactIdsToExclude.includes(contact.Id)) {
                            this.deduplicatedIds.push(contact.Id.toString());
                        } else if (this.requireEmail && existingContactEmails.includes(contact.Email.toLowerCase())) {
                            this.deduplicatedIds.push(contact.Id.toString());
                        } else {
                            newContacts.push(contact);
                            existingContactIds.push(contact.Id);
                            if (this.requireEmail && contact.Email) {
                                existingContactEmails.push(contact.Email.toLowerCase());
                            }
                        }
                    });

                    contactsByEmail.forEach(contact => {
                        if (existingContactIds.includes(contact.Id) || this.contactIdsToExclude.includes(contact.Id)) {
                            this.deduplicatedEmails.push(contact.Email);
                        } else if (this.requireEmail && existingContactEmails.includes(contact.Email.toLowerCase())) {
                            if (!newContacts.map(c => c.Id).includes(contact.Id)) {
                                this.deduplicatedEmails.push(contact.Email);
                            }
                        } else {
                            newContacts.push(contact);
                            existingContactIds.push(contact.Id);
                            if (this.requireEmail && contact.Email) {
                                existingContactEmails.push(contact.Email.toLowerCase());
                            }
                        }
                    });

                newContacts = this.contacts.value
                    .concat(
                        newContacts.map(c => this.getAutocompleteContact(c))
                    );

                this.contacts.patchValue(newContacts);
            });
        }
        this.contactsNgSelect.blur();
    }
}

export class AutocompleteContactItem {
    id: number;
    text: string;
    email: string;
    disabled: boolean;
}

