import {Injectable} from "@angular/core";
import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {Observable, of} from "rxjs";

export enum UserFeatureName {
    AccountGeneralNotes = "AccountGeneralNotes",
    AccountYoYSummary = "AccountYoYSummary",
    ActivityReportAnalystFilter = "ActivityReportAnalystFilter",
    AdminMifidAccounts = "AdminMifidAccounts",
    AllowAddResourceRank = "AllowAddResourceRank",
    AllowEditClientIdMapping = "AllowEditClientIdMapping",
    AllowEditInstitutionPriority = "AllowEditInstitutionPriority",
    AllowEditResourceRank = "AllowEditResourceRank",
    AttributionCode = "AttributionCode",
    BluematrixSecureLinks = "BluematrixSecureLinks",
    BluematrixSecureLinksAccount = "BluematrixSecureLinksAccount",
    BluematrixUnsubscribe = "BluematrixUnsubscribe",
    CanAssignFeatures = "CanAssignFeatures",
    CanDeleteAccounts = "CanDeleteAccounts",
    CanEditCallHistory = "CanEditCallHistory",
    CanEditComdol = "CanEditComdol",
    CanEditCorpAccessCallType = "CanEditCorpAccessCallType",
    CanEditResearchCallType = "CanEditResearchCallType",
    CanEditEmployment = "CanEditEmployment",
    CanEditInstTrader = "CanEditInstTrader",
    CanEditInventoryAccounts = "CanEditInventoryAccounts",
    CanEditParentCodes = "CanEditParentCodes",
    CanEditResearchOnlineEntitlement = "CanEditResearchOnlineEntitlement",
    CanEditTraderBackup = "CanEditTraderBackup",
    CanExportQuery = "CanExportQuery",
    CanGridMaintenance = "CanGridMaintenance",
    CanSeeCommisionsReport = "CanSeeCommisionsReport",
    CanSeeCommissionSplitTab = "CanSeeCommissionSplitTab",
    CanSeeCorpEventsTab = "CanSeeCorpEventsTab",
    CanSeeInstitutionAdminTab = "CanSeeInstitutionAdminTab",
    CanUseKailashEmail = "CanUseKailashEmail",
    CheckReport = "CheckReport",
    ClickToDial = "ClickToDial",
    Commission5YrAccountSummaryReport = "Commission5YrAccountSummaryReport",
    CommissionAccountNet = "CommissionAccountNet",
    CommissionExport = "CommissionExport",
    CommissionExportAdmin = "CommissionExportAdmin",
    CommissionSplitReport = "CommissionSplitReport",
    CommissionTradeDetail = "CommissionTradeDetail",
    CommissionYOYReport = "CommissionYOYReport",
    DashboardChangeTeam = "DashboardChangeTeam",
    DashboardSales = "DashboardSales",
    EditContact = "EditContact",
    EES = "EES",
    EventAdmin = "EventAdmin",
    EventCompanyUpload = "EventCompanyUpload",
    EventExceptions = "EventExceptions",
    EventUpcomingEmail = "EventUpcomingEmail",
    ForceAccountDetailToSalesView = "ForceAccountDetailToSalesView",
    InstiselMeetingsVoteLink = "InstiselMeetingsVoteLink",
    InstiselUpdate = "InstiselUpdate",
    IsAdmin = "IsAdmin",
    CorpTravelAdmin = "CorpTravelAdmin",
    CorpTravelCityAdmin = "CorpTravelCityAdmin",
    MailMerge = "MailMerge",
    McLaganCommissionRank = "McLaganCommissionRank",
    MyAccountsTeam = "MyAccountsTeam",
    QueryBuilder = "QueryBuilder",
    RevenueTarget = "RevenueTarget",
    SharedEmails = "SharedEmails",
    ShowAnnualization = "ShowAnnualization",
    SpecialtyMailingsAdmin = "SpecialtyMailingsAdmin",
    TickerChange = "TickerChange",
    TickerPage = "TickerPage",
    UndeleteClients = "UndeleteClients",
    UpdateNoContact = "UpdateNoContact",
    ViewAllCampaigns = "ViewAllCampaigns",
    ViewComdolRepInMain = "ViewComdolRepInMain",
    ViewInstiselWebReportAccountActivity = "ViewInstiselWebReportAccountActivity",
    InvoiceBilling = "InvoiceBilling",
}

export class UserFeature {
    UserId: string;
    UserFeatureName: string;
    LastName: string;
    FirstName: string;
}

@Injectable({
    providedIn: 'root',
})
export class UserFeaturesService {
    constructor(private baseHttpService: BaseHttpService) { }
    
    getUserFeatures(): Observable<string[]> {
        return of(Object.keys(UserFeatureName));
    }
    
    getUsersByUserFeature(featureName: string): Observable<UserFeature[]> {
        return this.baseHttpService.getData(`/userfeatures/${featureName}`);
    }
    
    addUserFeature(userId: string, featureName: string): Observable<any> {
        return this.baseHttpService.postData(`/userfeature`, { UserId: userId, UserFeatureName: featureName });
    }
    
    deleteUserFeature(userfeature: UserFeature): Observable<any> {
        return this.baseHttpService.deleteData(`/userfeature/${userfeature.UserId}/${userfeature.UserFeatureName}`);
    }
}