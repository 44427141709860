import * as _ from "lodash";
import {TDocumentDefinitions} from "pdfmake/build/pdfmake";
import * as moment from "moment";
import {Holding} from "../../Models/Holding";
import {Contact} from "../../Models/Contact";
import {Call} from "../../Components/CallList/Call";
import {ContactFullNameFilter} from "../../Filters/ContactFullNameFilter";

export class HoldingListDocumentDefinitions {

    static getByCalls(calls: Call[]): TDocumentDefinitions {
        return this.getSimpleTable([], [], "");
    }

    static getByHoldings(holdings: Holding[], contacts: Contact[]): TDocumentDefinitions {

        let pdfHeaders: {property: string, headerName: string}[] = [
            {property:"Tier", headerName:"Tier"},
            {property:"AccountName", headerName:"Account"},
            {property:"ContactName", headerName:"Client"},
            {property:"Ticker", headerName:"Tick"},
            {property:"Comment", headerName:"Comment"},
            {property:"Category", headerName:"Cat"},
            {property:"PersonalBroker", headerName:"PersBk"}
        ];

        let flatData = _.map(holdings, holding => { 

            const contact = _.find(contacts, (c: Contact) => c.Id === holding.Contact.Id);

            return {
                Tier: contact.Account && contact.Account.Tier || "",
                AccountName: contact.Account && contact.Account.Name || "",
                ContactName: ContactFullNameFilter.filter(contact),
                Ticker: holding.Ticker,
                Comment: holding.Comment,
                Category: holding.Category,
                PersonalBroker: contact.Broker && contact.Broker.LastName || ""
            }
        });

        return this.getSimpleTable(flatData, pdfHeaders, "Holding List");
    }

    static getSimpleTable(exportData: any[], headerData: {property:string,  headerName:string}[],  title: string): TDocumentDefinitions {
        let body = [];

        const headers = _.map(headerData,  h=> {return {text: h.headerName, style: 'tableHeader', alignment: 'left'}});
        body.push(headers);

        _.each(exportData, record=> {
            let row = [];
            _.each(headerData, header => {
                row.push({text: record[header.property], style: 'pdfData', maxHeight: 12})
            });
            body.push(row);
        });

        return {
            pageSize: 'LETTER',
            header: this.createHeader(title), 
            content: this.createBodyContent(body),
            styles: this.styles,
            pageMargins: [20, 25, 20, 10]
        } as TDocumentDefinitions;
    }

    private static styles = {
        header: {
            fontSize: 16,
            bold: true
        },
        pdfData: {
            fontSize: 10
        }
    };

    private static createHeader(pdfTitle: string)  {
        return (currentPage: number, pageCount: number) => {
            return {
                columns:[
                    { text: moment().format('LL'), alignment: 'left', margin: [15,5,0,0]},
                    { text: pdfTitle, alignment: 'center', margin: [0,5,0,0]},
                    { text: 'Page: ' + currentPage + ' of ' + pageCount, alignment: 'right', margin: [0,5,15,0]}
                ]
            };
        };
    };

    private static createBodyContent(body: any[]) {
        return [
            {
                table: {
                    headerRows: 1,
                    body: body
                },
                layout: 'headerLineOnly'
            }
        ];
    }
}