import {Component, EventEmitter, Input, OnChanges} from "@angular/core";
import {ContactProfile, ContactRole, ContactService} from "../../Shared/Services/contact.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import {ContactFormComponent} from "../ContactForm/contact-form.component";

@Component({
    selector: "app-contact-profile",
    templateUrl: "./contact-profile.component.html"
})
export class ContactProfileComponent implements OnChanges {

    @Input()
    contactId: number;

    @Input()
    contactDataChanged: EventEmitter<boolean>;

    contactProfile: ContactProfile = { InvestorStyles: [], DealPlayer: [], Teams: [], Orientations: [], RoleIds: [] } as ContactProfile;

    contactSectors: Observable<string[]>;
    contactEmployment: Observable<string[]>;
    contactRoles: ContactRole[];

    constructor(private bsModalService: BsModalService,
                private contactService: ContactService) { }

    ngOnChanges() {
        if (!this.contactId) return;
        this.getProfile();
        this.contactDataChanged.subscribe(() => this.getProfile());
    }

    loadContactProfile(profile: ContactProfile): void {
        profile.Orientations = _.sortBy(profile.Orientations);
        profile.Teams = _.sortBy(profile.Teams);
        profile.DealPlayer = _.sortBy(profile.DealPlayer);
        profile.InvestorStyles = _.sortBy(profile.InvestorStyles);

        this.contactProfile = profile;
    }

    getProfile() {
        this.contactService.getContactProfile(this.contactId)
            .subscribe(profile => {
                this.loadContactProfile(profile);
            });

        this.contactSectors = this.contactService.getContactSectors(this.contactId);

        this.contactEmployment = this.contactService.getContactEmployment(this.contactId).pipe(
            map(employments => _.orderBy(employments, ['EndDate'], ['desc'])),
            map(employments => _.map(employments, e => `${e.Year} - ${e.AccountName}`))
        );

        this.contactService.getContactRoles().subscribe(roles => this.contactRoles = roles);
    }

    getContactRoleNames(): string {
        if(!this.contactRoles || !this.contactProfile){
            return "";
        }

        return _.chain(this.contactRoles)
            .filter(cr => _.includes(this.contactProfile.RoleIds, cr.Id))
            .map(cr => cr.Name)
            .sortBy()
            .value()
            .join(', ');
    }

    openEditModal() {
        let modalRef = this.bsModalService.show(ContactFormComponent,
            {
                initialState: { contactId: this.contactId, defaultTab: "Profile" },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-lg'
            });

        modalRef.content.dataUpdated.subscribe(() => {
            this.contactDataChanged.emit(true);
        });
    }
}
