import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {SubscriptionService} from "../../../Shared/Services/subscription.service";

@Component({
    selector: "app-specialty-mailing-code-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(SpecialtyMailingCodePickerComponent)
})
export class SpecialtyMailingCodePickerComponent extends BasePickerComponent<any> {

    constructor(fb: UntypedFormBuilder, private subscriptionService: SubscriptionService){
        super(fb);

        this.name = "Specialty Mailing Code(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.subscriptionService.getSpecialtyMailings().pipe(
            map(specialtyMailings => _.chain(specialtyMailings)
                .map(sm => {
                    return {
                        Id: sm.Code,
                        Name: `${sm.Analyst} - ${sm.Description}`
                    }
                })
                .sortBy(sm => sm.Name)
                .value()
        ));
    }
}
