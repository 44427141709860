<div class="page-content no-scroll flex-vbox">

    <app-page-banner
            [pagetitle]="pageTitle"
            faicon="fa-bar-chart"
            faiconcolor="#800080"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="yoySearchForm">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-btn" tooltip="My Accounts" container="body">
                        <div class="btn btn-primary btn-sm btn-toggle"
                             btnCheckbox
                             formControlName="myAccounts">
                            <span class="fa fa-sitemap"></span>
                        </div>
                    </div>
                    <input type="search"
                           class="form-control input-sm"
                           style="width: 145px;"
                           formControlName="accountName"
                           placeholder="Account Name" />
                </div>
            </div>
            <div
                class="form-group"
                *ngIf="yoySearchForm.controls['dateRange'].value"
                [ngStyle]="{'cursor': disableDateControls ? 'not-allowed' : 'default'}"
            >
                <app-date-range-picker
                    formControlName="dateRange"
                    view="text"
                    [options]="datePickerOptions"
                    [ngStyle]="{'pointer-events': disableDateControls ? 'none' : '', 'opacity': disableDateControls ? '0.65' : '1'}"
                ></app-date-range-picker>
            </div>
            <div
                class="form-group"
                [ngStyle]="{'cursor': disableDateControls ? 'not-allowed' : 'default'}"
            >
                <app-string-picker
                    [items$]="startYears$"
                    formControlName="startYear"
                    [alignRight]="true"
                    [showSelectClearAll]="false"
                    [showTagCountLabel]="false"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    [ngStyle]="{'pointer-events': disableDateControls ? 'none' : '', 'opacity': disableDateControls ? '0.65' : '1'}"
                ></app-string-picker>
            </div>
            <div class="form-group">
                <app-commission-category-picker formControlName="categories" [alignRight]="true"></app-commission-category-picker>
            </div>
            <div appIfUserFeature="ShowAnnualization" class="form-group">
                <div btnCheckbox class="btn btn-primary btn-sm btn-toggle" formControlName="annualize">
                    Show Annualized
                </div>
            </div>
        </form>
        <app-selection-display-list name="Categories" [selectionKeyValues]="categoryKeyValues" [selectionControl]="yoySearchForm.controls['categories']"></app-selection-display-list>
    </ng-template>

    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [pinnedTopRowData]="pinnedTopRowData"
                [frameworkComponents]="frameworkComponents"
                [getContextMenuItems]="getContextMenuItems"
                (gridReady)="onGridReady($event)"
                (rowClicked)="rowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>