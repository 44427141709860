import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import * as _ from "lodash";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {MonthlyCommission, MonthlyExpense} from "../../../Models/AccountMonthlyCommission";
import {currencyFormatter} from "../../Shared/ag-grid-cell-renderers";
import {UntypedFormBuilder} from "@angular/forms";
import {UserFeatureName} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {withLatestFrom, startWith} from "rxjs/operators";
import * as moment from "moment";

@Component({
    selector: "app-net-commission-chart",
    templateUrl: "./net-commission-chart.component.html"
})
export class NetCommissionChartComponent extends BaseChartComponent implements OnChanges {

    @Input()
    type: string = "bar";

    @Input()
    stacked: boolean = false;

    @Input()
    years: number[];

    @Input()
    commissions: MonthlyCommission[];

    @Input()
    pnl: MonthlyCommission[];

    @Input()
    expenses: MonthlyExpense[];

    controlGroup = this.fb.group({
        P: this.fb.control(true),
        E: this.fb.control(true),
        O: this.fb.control(true),
        C: this.fb.control(true),
        S: this.fb.control(false),
        PNL: this.fb.control(true)
    });

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["commissions"].isFirstChange() && !changes["pnl"].isFirstChange() && !changes["expenses"].isFirstChange()) {
            this.configureChart();
        }
    }
    configureChart(): void {
        this.options = {
            plugins: {
                tooltip: {
                    position: "nearest",
                    axis: "xy",
                    mode: "nearest",
                    callbacks: {
                        label: (item) => `${item.dataset.label}: ${currencyFormatter(item.raw, 0)}`,
                    }
                },
            },
            scales: {
                y:
                    {
                        stacked: this.stacked,
                        ticks: {
                            callback: val => currencyFormatter(val, 0)
                        },
                    },
                x:
                    {
                        stacked: this.stacked,
                    },

            }
        };

        this.controlGroup.valueChanges.pipe(
            startWith(this.controlGroup.getRawValue()),
            withLatestFrom(this.userService.getCurrentUser())
        )
            .subscribe(([controls, user]) => {
                const comTotals = this.years.map(y => _.sumBy(this.commissions, c => c.Year === y && controls[c.Category] ? c.Commission : 0));
                const bidsExpTotals = this.years.map(y => _.sumBy(this.expenses.filter(e => e.Type === "BIDS"), c => c.Year === y && controls[c.Category] ? c.Amount : 0));
                const pnLTotals = this.years.map(y => _.sumBy(this.pnl, pnl => pnl.Year === y ? pnl.Commission : 0))
                const netComTotals = _.zipWith(
                    comTotals, pnLTotals, bidsExpTotals,
                    (com, pnl, exp) => com + exp + (controls.PNL ? pnl : 0)
                );

                const chartData = {
                    "Gross Commission": comTotals,
                    "Net Commission": netComTotals
                };

                if (user.Features.includes(UserFeatureName.CommissionAccountNet)) {
                    const expTotals = this.years.map(y => _.sumBy(this.expenses, c => c.Year === y && controls[c.Category] ? c.Amount : 0));
                    chartData["Net All"] = _.zipWith(
                        comTotals, pnLTotals, expTotals,
                        (com, pnl, exp) => com + exp + (controls.PNL ? pnl : 0)
                    );
                }

                const data = {
                    labels: this.years.map(year => year === moment().year() ? `${year} YTD` : year),
                    datasets: Object.entries(chartData).map(([key, data]) => {
                        return {
                            label: key,
                            data: data,
                            borderWidth: 1,
                        }
                    })
                };

                if (this.chart) {
                    this.updateChart(data);
                } else if (!this.chart) {
                    this.data = data;
                    this.runChart();
                }
            });
    }
}
