import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR, ValidationErrors,
    Validators
} from "@angular/forms";
import {ActivityService} from "../../Shared/Services/activity.service";
import {Activity} from "../../../Models/Activity";
import {ActivityFeatureManager} from "../../../ActivityFeatureManager";
import {combineLatest, Observable, of} from "rxjs";
import {ActivitySubCategory} from "../../../Models/ActivitySubCategory";
import {map, startWith} from "rxjs/operators";
import {EventService} from "../../Shared/Services/event.service";
import * as moment from "moment";
import * as _ from "lodash";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {TeamName} from "../../../Models/Team";
import {AccountService} from "../../Shared/Services/account.service";
import {ConfigService} from "../../Shared/Services/config.service";
import { ActivityCategoryCode } from "../../../Models/ActivityCategory";

@Component({
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActivityFormFieldsetComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ActivityFormFieldsetComponent),
            multi: true
        }
    ],
    selector: "app-activity-form-fieldset",
    templateUrl: "./activity-form-fieldset.component.html"
})
export class ActivityFormFieldsetComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input()
    commentPlaceholder: string;

    @Input()
    showCallDate: boolean = true;

    @Input()
    accountId: number;

    activityForm: UntypedFormGroup = this.fb.group({
        id: this.fb.control(null),
        contacts: this.fb.control([]),
        callDate: this.fb.control(""),
        category: this.fb.control("", [Validators.required]),
        subCategory: this.fb.control("", [_.bind(this.isSubCategoryValid, this)]),
        meetingType: this.fb.control(""),
        status: this.fb.control("",[_.bind(this.isStatusValid, this)]),
        eventId: this.fb.control(null),
        duration: this.fb.control(null, [_.bind(this.isDurationValid, this)]),
        side: this.fb.control(""),
        price: this.fb.control(null),
        comment: this.fb.control("", [_.bind(this.isCommentValid, this)] ),
        tickers: this.fb.control([]),
        participantUserIds: this.fb.control([]),
        includeExtraClientEntertainmentActivity: this.fb.control(false),
        extraClientEntertainmentActivityComment: this.fb.control("", [_.bind(this.isExtraClientEntertainmentActivityCommentValid, this)] ),
        includeExcelModel: this.fb.control(false),
        excelModelTickers: this.fb.control([]),
    });

    subCategories: ActivitySubCategory[] = [];

    sideOptions$: Observable<string[]> = of(["", "Buy", "Sell"]);

    events$: Observable<any[]>;

    availableCategories: string[] = [];

    user: User;

    initialCallDate: Date;

    minTickers: number = 0;
    minExcelModelTickers: number = 0;

    disabled: boolean = false;
    uneditableCategory: string = "";

    excludeCategories: string[] = [];

    categoriesThatCannotBeChangedForExistingActivites: string[] = ["B", "J"];

    contactMoved: boolean = false;
    activityOccurredAt: string = "";

    activityFeatureManager: ActivityFeatureManager;

    onChange: (activity: Activity) => void = () => {};

    constructor(
        private fb: UntypedFormBuilder,
        private configService: ConfigService,
        private activityService: ActivityService,
        private eventService: EventService,
        private userService: UserService,
        private accountService: AccountService
    ) { }

    ngOnInit(): void {

        let user$ = this.userService.getCurrentUser();
        let categories$ = this.activityService.getActivityCategories();

        combineLatest([user$, categories$])
            .subscribe(([user, categories]) => {
                this.user = user;

                this.availableCategories = categories
                    .filter(c => c.UserHasAccess)
                    .map(c => c.Code);

                this.activityFeatureManager = new ActivityFeatureManager(null, this.user?.Role?.Name, null);

                this.activityForm.get("category").valueChanges.subscribe(categoryCode => {
                    this.activityForm.get("duration").updateValueAndValidity();
                    this.activityForm.get("tickers").updateValueAndValidity();
                    this.activityForm.get("status").updateValueAndValidity();
                    this.activityForm.get("subCategory").updateValueAndValidity();
                    this.activityForm.patchValue({
                        includeExcelModel: false,
                        excelModelTickers: [],
                        includeExtraClientEntertainmentActivity: false,
                        extraClientEntertainmentActivityComment: null,
                    });

                    this.minTickers = ["V","K"].includes(categoryCode) ? 1 : 0;

                    const cat = categories.find(c => c.Code === categoryCode);

                    const userHasAccessToCategory = categoryCode && this.availableCategories.includes(categoryCode);
                    const activityExistsAlready = !!this.activityForm.get("id").value;
                    const thisCategoryCannotBeChangedForExistingActivities = !!this.categoriesThatCannotBeChangedForExistingActivites.find(c => c === categoryCode);

                    if (categoryCode && (!userHasAccessToCategory || (activityExistsAlready && thisCategoryCannotBeChangedForExistingActivities))) {
                        this.uneditableCategory = `${cat.Code} - ${cat.Description}`;
                    } else if ((activityExistsAlready && categoryCode !== "B") || this.user?.Role?.Name === 'Research') {
                        this.excludeCategories = ["B"];
                    }
                });

                this.activityForm.get("includeExtraClientEntertainmentActivity")
                    .valueChanges.subscribe(includeExtraClientEntertainmentActivity => {
                    this.activityForm.get("extraClientEntertainmentActivityComment")?.updateValueAndValidity();
                });
            });

        combineLatest([
            this.activityService.getActivitySubCategories(),
            this.activityForm.get("category").valueChanges
        ]).pipe(
            startWith([
                [],
                ""
            ])
        ).subscribe(([
            allSubCategories,
            category
        ]) => {
            this.subCategories = (allSubCategories as ActivitySubCategory[]).filter(sc => sc.CategoryCode == category);
        });

        this.events$ = this.eventService.searchEvents(moment().add(-3, "months"), moment()).pipe(
            map(events => events.map(e => { return { id: e.Id, name: e.Name }}))
        );

        this.activityForm.valueChanges.subscribe(formData => {
            this.onChange({
                Id: this.activityForm.get("id").value,
                CallDate: moment(this.activityForm.get("callDate").value),
                ContactIds: this.activityForm.get("contacts").value,
                Category: this.activityForm.get("category").value,
                SubCategory: this.activityForm.get("subCategory").value,
                Comment: this.activityForm.get("comment").value,
                Status: this.activityForm.get("status").value,
                Side: this.activityForm.get("side").value,
                BuySell: this.activityForm.get("side").value,
                StockPrice: this.activityForm.get("price").value,
                Duration: this.activityForm.get("duration").value,
                Tickers: this.activityForm.get("tickers").value?.join(","),
                ParticipantUserIds: this.activityForm.get("participantUserIds").value,
                EventId: this.activityForm.get("eventId").value,
                MeetingType: this.activityForm.get("meetingType").value,
                ExcelModelTickers: this.activityForm.get("includeExcelModel").value ? this.activityForm.get("excelModelTickers").value : [],
                ClientEntertainmentComment: this.activityForm.get("extraClientEntertainmentActivityComment").value
            } as Activity);
        });

        this.activityForm.get("includeExcelModel").valueChanges
            .subscribe((includeExcelModel) => {
                this.minExcelModelTickers = includeExcelModel ? 1 : 0;

                if (includeExcelModel) {
                    this.activityForm.get("excelModelTickers").patchValue(this.activityForm.get("tickers").value)
                }
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.activityForm.get("comment").updateValueAndValidity();
    }

    isSubCategoryValid(control: AbstractControl): ValidationErrors | null {
        if (_.includes(["A","K","C","V","I","N","P"], this.activityForm?.get("category")?.value)  && !control.value) {
            return { subCatRequired: true };
        }
        return null;
    }

    isCommentValid(control: AbstractControl): Validators | null {
        if (!this.commentPlaceholder && !control.value) {
            return { commentRequired: true };
        }

        return null;
    }

    isExtraClientEntertainmentActivityCommentValid(control: AbstractControl): Validators | null {
        let includeExtraClientEntertainmentActivity = this.activityForm?.get("includeExtraClientEntertainmentActivity")?.value;

        if (includeExtraClientEntertainmentActivity && !control.value) {
            return { extraClientEntertainmentActivityCommentRequired: true };
        }

        return null;
    }

    isDurationValid(control: AbstractControl): ValidationErrors | null {
        let category = this.activityForm?.get("category")?.value;
        if (["P","X"].includes(category) && !control.value && this.user?.Team?.Name !== TeamName.MarketMaker) {
            return { required: true };
        }
        return null;
    }

    isStatusValid(control: AbstractControl): ValidationErrors | null {
        if ( _.includes(["A","K","C","V","I","N"], this.activityForm?.get("category")?.value)  && !control.value) return { required: true };
        return null;
    }

    registerOnChange(fn: (activity: Activity) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.disabled ? this.activityForm.disable() : this.activityForm.enable();
    }

    writeValue(activity: Activity): void {
        this.activityForm.reset();
        if (activity) {
            this.initialCallDate = moment(activity.CallDate).toDate();

            if (this.configService.getAppFeatures().includes("activity-form-copy")) {
                if (
                    activity.Id &&
                    activity.ContactIds &&
                    activity.ContactIds.length > 0 &&
                    activity.Category !== ActivityCategoryCode.BusinessUpdate &&
                    activity.Category !== ActivityCategoryCode.ClientEntertainment
                ) {
                    this.activityForm.get("contacts").disable();
                } else {
                    this.activityForm.get("contacts").enable();
                }
            }

            this.contactMoved = activity.AccountId && activity.ActivityAccountId && activity.AccountId !== activity.ActivityAccountId;
            if (this.contactMoved) {
                this.activityOccurredAt = "";
                this.accountService.getAccountById(activity.ActivityAccountId)
                    .subscribe(account => {
                        this.activityOccurredAt = account.Name;
                    });
            } else {
                this.activityOccurredAt = "";
            }

            this.activityForm.patchValue({
                id: activity.Id,
                callDate: activity.CallDate,
                contacts: activity.ContactIds,
                category: activity.Category,
                subCategory: activity.SubCategory,
                comment: activity.Comment,
                side: activity.Side ?? activity.BuySell,
                price: activity.StockPrice,
                status: activity.Status,
                duration: activity.Duration,
                participantUserIds: activity.ParticipantUserIds,
                tickers: activity.Tickers.split(",").filter(t => t),
                meetingType: activity.MeetingType,
                eventId: activity.EventId,
                excelModelTickers: activity.ExcelModelTickers,
            });
        }
    }

    validate({ value }: UntypedFormControl) {
        if (this.activityForm.invalid) {
            return {invalid: true};
        }
        return null;
    }

    isCorpAccess(): boolean {
        return new ActivityFeatureManager(null, this.user?.Role?.Name, null).isCorpAccess(this.activityForm.get("category").value);
    }

    allowExcelModelTickers(): boolean {
        const selectedCategory = this.activityForm.get("category").value;

        return (
            !this.activityForm.get("id").value &&
            selectedCategory !== "D" &&
            this.availableCategories.includes("D") &&
            new ActivityFeatureManager(null, this.user?.Role?.Name, null).getResearchCategories().map(c => c.Code).includes(selectedCategory)
        );
    }

    allowExtraClientEntertainmentActivity(): boolean {
        return (
            !this.activityForm.get("id").value &&
            this.activityIsBusinessUpdate() &&
            this.availableCategories.includes("J")
        );
    }

    activityIsBusinessUpdate(): boolean {
        return this.activityForm.get("category").value == "B";
    }

    activityIsClientEntertainment(): boolean {
        return this.activityForm.get("category").value == "J";
    }

    showExcelModelTickers(): boolean {
        return this.allowExcelModelTickers() && this.activityForm.get("includeExcelModel").value;
    }
}
