import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {map} from "rxjs/operators";
import {TimeZone, TimeZoneService} from "../../../Shared/Services/time-zone.service";

@Component({
    selector: "app-time-zone-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(TimeZonePickerComponent)
})
export class TimeZonePickerComponent extends BasePickerComponent<any> {

    @Input()
    multiple: boolean = true;
    
    @Input()
    closeOnSelect: boolean = false;
    
    @Input() 
    showTagCountLabel: boolean = true;

    @Input()
    showSelectClearAll: boolean = true;

    @Input()
    alignRight: boolean = false;
    
    constructor(fb: UntypedFormBuilder, private timeZoneService: TimeZoneService){
        super(fb);
        
        this.placeHolder = "Select Time Zone";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.customWriteValue = (obj: number) => {
            if(obj == null){
                this.pickerControl.patchValue(-1, { emitEvent: false });
            }
            else{
                this.pickerControl.patchValue(obj, { emitEvent: false });
            }
        }
        this.customOnChange = (data: any) => {
            if(data == -1){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }
        this.setupEvents();
    }
    
    setupEvents(): void {
        
        this.items$ = this.timeZoneService.getTimeZones().pipe(
            map((timeZones: TimeZone[]) => {
                let pickerItems = timeZones.map(t => {
                    return {
                        Id: t.Id,
                        Name: `${t.Name} (${t.Abbreviation})`,
                    };
                });
                if(!this.multiple){
                    pickerItems.push({
                        Id: -1,
                        Name: ""
                    })
                }
                return pickerItems.sort((a,b) => a.Name < b.Name ? -1 : 1)
            }),
        );
    }
}
