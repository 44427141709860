import {Component, EventEmitter, OnInit, Output, ViewChild} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {EmailService} from "../../Shared/Services/email.service";
import {UntypedFormBuilder, UntypedFormControl, FormGroup} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../Shared/Services/user.service";
import {HtmlEditorComponent} from "../../Widget/HtmlEditor/html-editor.component";

@Component({
    selector: "app-email-signature-form",
    templateUrl: "./email-signature-form.component.html"
})
export class EmailSignatureFormComponent implements OnInit {

    message: string;

    @Output()
    action = new EventEmitter<boolean>();

    userId: string;

    signature: UntypedFormControl = this.fb.control('');

    @ViewChild("editor", { static: true })
    editor: HtmlEditorComponent;

    constructor(public bsConfirmModalRef: BsModalRef,
                private userService: UserService,
                private emailService: EmailService,
                private fb: UntypedFormBuilder,
                private toastrService: ToastrService) { }

    ngOnInit(): void {
        this.userService.getCurrentUser()
            .subscribe(user => {
                this.userId = user.Id;
                this.loadSignature(this.userId);
            });
    }

    loadSignature(userId: string): void {
        this.emailService.getSignature(userId)
            .subscribe(result => {
                this.signature.patchValue(result.SignatureHtml);
            });
    }

    save(): void {
        this.editor.forceUpdate().subscribe(() => {
            this.emailService.saveSignature(this.userId, this.signature.value)
                .subscribe(_ => {
                    this.toastrService.success(`Email Signature Saved`);
                    this.bsConfirmModalRef.hide();
                    this.action.emit(true);
                });
        });
    }

    cancel(): void {
        this.bsConfirmModalRef.hide();
    }
}
