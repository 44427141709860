import {Routes} from "@angular/router";
import {CorpTravelAdminDashboardComponent} from "./Dashboard/corptravel-admin-dashboard.component";
import {ProviderListComponent} from "./Providers/provider-list.component";
import {TransportationProviderListComponent} from "./TransportationProviders/transportation-provider-list.component";
import {AirportListComponent} from "./Airports/airport-list.component";
import {TravelCityListComponent} from "./Cities/travel-city-list.component";
import {BairdRepAnalystListComponent} from "./BairdRepsAnalysts/baird-rep-analyst-list.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {ProviderType} from "./Services/provider.service";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {MsalGuard} from "@azure/msal-angular";
import {CorpTravelAdminRoutePaths} from "./corp-travel-admin-route-paths";

export const corpTravelAdminRoutes: Routes = [
    {
        path: CorpTravelAdminRoutePaths.BasePath,
        canActivate: [UserCanActivateRoute, MsalGuard],
        canActivateChild: [MsalGuard],
        data: {
            requiredFeatures: [UserFeatureName.CorpTravelAdmin],
        },
        children: [
            {
                path: CorpTravelAdminRoutePaths.HotelsProviderListPathFragment,
                component: ProviderListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Hotels",
                    providerType: ProviderType.Hotel
                },
            },
            {
                path: CorpTravelAdminRoutePaths.RestaurantsProviderListPathFragment,
                component: ProviderListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Restaurants",
                    providerType: ProviderType.Restaurant
                },
            },
            {
                path: CorpTravelAdminRoutePaths.TransportationProviderListPathFragment,
                component: TransportationProviderListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Transportation",
                    providerType: "transportation"
                },
            },
            {
                path: CorpTravelAdminRoutePaths.AirportListPathFragment,
                component: AirportListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Airports"
                },
            },
            {
                path: CorpTravelAdminRoutePaths.TravelCityListPathFragment,
                component: TravelCityListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Cities",
                    requiredFeatures: [UserFeatureName.CorpTravelCityAdmin]
                },
            },
            {
                path: CorpTravelAdminRoutePaths.BairdRepAnalystListPathFragment,
                component: BairdRepAnalystListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Baird Rep"
                },
            },
            {
                path: "",
                component: CorpTravelAdminDashboardComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Corp Travel Admin - Dashboard",
                },
            },
        ],
    },
];
