<ng-select [items]="users$ | async"
           bindLabel="text"
           placeholder="Add user"
           [multiple]="true"
           [selectOnTab]="true"
           [hideSelected]="true"
           [minTermLength]="2"
           [typeahead]="userInput$"
           [formControl]="usersControl"
    >
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{item.text}}</span>
    </ng-template>
</ng-select>