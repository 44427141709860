import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {durationHeaderTemplate, getGridDisplayWords,} from "../../Shared/ag-grid-options";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {CellClickedEvent, ColDef, ICellRendererParams, ValueFormatterParams} from "ag-grid-community";
import {MeetingFormComponent} from "./MeetingForm/meeting-form.component";
import * as moment from "moment";
import {EventService} from "../../Shared/Services/event.service";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {CompanyPresenterListRenderer} from "../../Widget/CellRenderers/company-presenter-list-renderer.component";
import {EventMeeting} from "../../../Components/Events/CalendarEvent";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {combineLatest} from "rxjs";
import {ActivityService} from "../../Shared/Services/activity.service";
import {TimeZone, TimeZoneService} from "../../Shared/Services/time-zone.service";
import {MeetingAttendeeFormComponent} from "./MeetingAttendeeForm/meeting-attendee-form.component";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import * as _ from "lodash";
import {ExcelUploadComponent} from "../../Widget/DataUpload/excel-upload.component";
import {timeComparator} from "../../Shared/ag-grid-cell-comparators";

@Component({
    selector: "app-tab-meeting-list",
    template: `
        <div class="flex-1 flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="meetingSearchForm">
                    <div class="flex-hbox flex-gap">
                        <div appIfUserFeature="EventAdmin">
                            <button
                                appRequiresFeature="contact-add-new"
                                type="button"
                                class="btn btn-sm btn-default"
                                (click)="addMeeting()"
                            >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>
                        <div appIfUserFeature="EventAdmin">
                            <button type="button"
                                    class="btn btn-sm btn-default"
                                    appIfUserFeature="EventCompanyUpload"
                                    (click)="openUploadModal()">
                                <span class="fa fa-upload"></span>
                            </button>
                        </div>
                        <input
                            formControlName="searchTerm"
                            type="search"
                            placeholder="Search Name"
                            class="flex form-control input-sm"
                        />
                    </div>
                </form>
            </div>

            <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClick($event)"
            >
            </ag-grid-angular>

        </div>`
})
export class TabMeetingListComponent extends BaseGridComponent<EventMeeting> implements OnInit {

    @Input()
    eventId: number;

    @Input()
    attendeeDataUpdated = new EventEmitter<boolean>();

    @Input()
    webcastAttendeeDataUpdated = new EventEmitter<boolean>();

    @Input()
    meetingDataUpdated = new EventEmitter<boolean>();

    @Input()
    companyDataUpdated = new EventEmitter<boolean>();

    columnDefs = [
        {field: 'Date', maxWidth:79, cellRenderer: dateRenderer},
        {
            field: 'StartTime',
            headerName: 'Time',
            width: 110,
            valueFormatter: params => this.getDisplayTime(params),
            comparator: timeComparator,
            sort: "asc"
        },
        {
            field: 'Duration',
            headerName: '',
            headerTooltip: 'Duration',
            width: 40,
            headerComponentParams: {template: durationHeaderTemplate}
        },
        {field: 'DisplayName', headerName: 'Name', flex: 2, tooltipField: 'DisplayName'},
        {field: 'MeetingType', headerName: 'Type', headerTooltip: 'Meeting Type', tooltipField: 'MeetingType', maxWidth: 60, flex: 1},
        {
            field: 'EventPresenters',
            headerName: 'Pres',
            headerTooltip: 'Presenters',
            maxWidth: 50,
            flex: 1,
            cellRenderer: CompanyPresenterListRenderer,
            cellRendererParams: (params: ICellRendererParams<EventMeeting>) => {
                return {
                    HeaderName: params.data.DisplayName,
                    ShowCompanyName: true,
                    Placement: 'left',
                }
            },
            type: 'numericColumn',
        },
        {
            field: 'NumberOfInterested',
            headerName: 'Int',
            flex: 1,
            maxWidth: 40,
            type: 'numericColumn',
            cellRenderer: _.bind(this.interestedCountRenderer, this),
            headerTooltip: 'Interested'
        },
        {
            field: 'NumberOfAttendees',
            headerName: 'Att',
            maxWidth: 45,
            flex: 1,
            type: 'numericColumn',
            cellRenderer: _.bind(this.attendeeCountRenderer, this),
            headerTooltip: 'Registered & Attended'
        },
    ] as ColDef[];

    meetingSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(''),
    });

    user: User;

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private activityService: ActivityService,
                private timeZoneService: TimeZoneService,
                private eventService: EventService,
                private userService: UserService) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.overlayNoRowsTemplate = `<span>No Meetings</span>`;
    }

    onGridReady(params) {
        super.onGridReady(params);

        this.userService.getCurrentUser().subscribe(user => this.user = user);

        let event$ = this.eventService.getEvent(this.eventId);
        let timezones$ = this.timeZoneService.getTimeZones();

        combineLatest([event$, timezones$])
            .subscribe(([event, timezones]) => {
                let timeCol = this.columnDefs.find(c => c.field === 'StartTime');
                let timeZone = timezones.find(tz => tz.Id === event.TimeZoneId);
                if (timeCol && timeZone) {
                    timeCol.headerName = `Time (${ timeZone.Abbreviation })`;
                }
                this.gridOptions.api.setColumnDefs(this.columnDefs);
                this.loadMeetings(timeZone, timezones);
            });
    }

    userIsEventAdmin(): boolean {
        return this.user && this.userService.hasUserFeature(this.user, 'EventAdmin');
    }

    getDisplayWords(): string {
        return getGridDisplayWords(this.gridApi);
    }

    addMeeting() {
        const initialState = {
            eventId: this.eventId
        };
        let modalFormRef = this.modalService.show(MeetingFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static',
            class: 'modal-xl'
        });

        modalFormRef.content.dataUpdated
            .subscribe(_ => {
                this.meetingDataUpdated.emit(true);
            });
    }

    onCellClick($event: CellClickedEvent<EventMeeting>) {
        let eventColumnId = $event.column.getColId();
        if (eventColumnId !== 'EventPresenters' && !((eventColumnId == 'NumberOfAttendees' || eventColumnId == 'NumberOfInterested') && this.userIsEventAdmin())) {
            const initialState = {
                meetingId: $event.data.MeetingId,
                eventId: this.eventId
            };
            let modalFormRef = this.modalService.show(MeetingFormComponent, {
                initialState: initialState,
                animated: false,
                keyboard: false,
                backdrop: 'static',
                class: 'modal-xl'
            });

            modalFormRef.content.dataUpdated
                .subscribe(_ => {
                    this.meetingDataUpdated.emit(true);
                    this.webcastAttendeeDataUpdated.emit(true);
                    this.attendeeDataUpdated.emit(true);
                });
        }

        if ((eventColumnId === 'NumberOfAttendees' || eventColumnId === 'NumberOfInterested') && this.userIsEventAdmin()) {
            const initialState = !!$event.data.WebcastId && $event.data.WebcastId !== "" ? {
                meetingId: $event.data.MeetingId,
                meetingTabWebcastViewOnlyToggle: true
            } : {
                eventMeetingId: $event.data.MeetingId
            } as any;

            let config: ModalOptions<any> = {
              initialState: initialState,
              animated: false,
              keyboard: false,
              backdrop: 'static',
              class: 'modal-xl'
            };

            let modalFormRef: BsModalRef;

            if (!!$event.data.WebcastId && $event.data.WebcastId !== "") {
              modalFormRef = this.modalService.show(MeetingFormComponent, config);
            }
            else {
              modalFormRef = this.modalService.show(MeetingAttendeeFormComponent, config);
            }

            modalFormRef.content.dataUpdated
                .subscribe(_ => {
                    this.meetingDataUpdated.emit(true);
                    this.attendeeDataUpdated.emit(true);
                    this.webcastAttendeeDataUpdated.emit(true);
                });
        }
    }

    openUploadModal() {
        this.openExcelModal({
            fileTitle: "Event Meeting List",
            fileTemplatePath: `/eventmeetingupload/template`,
            fileUploadPath: `/event/id/${this.eventId}/meetingupload`,
        })
    }

    openExcelModal(initialState: any): void {
        let modalRef = this.modalService.show(ExcelUploadComponent,
            {initialState: initialState, ignoreBackdropClick: true, keyboard: false, animated: false});

        modalRef.content.dataUpdated
            .subscribe(_ => {
                this.meetingDataUpdated.emit(true);
            });
    }

    getDisplayTime(params: ValueFormatterParams<EventMeeting>): string {
        return this.formatTimeRange(params.data.StartTime, params.data.DisplayTimeZone);
    }

    formatTimeRange(startTime: string | moment.Moment,
                    timeZone: string ): string {
        let startTimeFormatted = startTime ?
            moment(startTime).format('h:mm A',) : '';

        if (startTimeFormatted && timeZone){
            startTimeFormatted += ` (${timeZone})`
        }
        return `${startTimeFormatted}`;
    }

    attendeeCountRenderer(params: any): string {
        let attendeeCount = params && params.data.NumberOfAttendees;
        let displayString = `${attendeeCount}`;

        if (this.userIsEventAdmin()) {
            return `<a class="pointer">${displayString}</a>`;
        } else {
            return `<span>${displayString}</span>`;
        }
    }

    interestedCountRenderer(params: any): string {
        let interestedCount = params && params.data.NumberOfInterested;
        let displayString = `${interestedCount}`;

        if (this.userIsEventAdmin()) {
            return `<a class="pointer">${displayString}</a>`;
        } else {
            return `<span>${displayString}</span>`;
        }
    }

    private loadMeetings(eventTimeZone: TimeZone, timezones: TimeZone[]): void {

        let searchForm$ = this.meetingSearchForm.valueChanges.pipe(
            startWith(this.meetingSearchForm.getRawValue()),
            debounceTime(300));

        let meetingOrAttendeesOrEventUpdated$ = combineLatest(
            this.meetingDataUpdated.pipe(startWith(true)),
            this.attendeeDataUpdated.pipe(startWith(true)),
            this.companyDataUpdated.pipe(startWith(true)),
        );

        let meetings$ = meetingOrAttendeesOrEventUpdated$.pipe(
            switchMap(() => this.eventService.getEventMeetings(this.eventId))
        );

        this.rowData$ = combineLatest([searchForm$, meetings$]).pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            map(([searchForm, meetings]) => {

                let searchTerms = searchForm.searchTerm.split(/[\s,]+/);

                return meetings
                    .filter(a => searchTerms.some(term =>
                        a.DisplayName.toLowerCase().indexOf(term.toLowerCase()) > -1
                    ))
                    .map(meeting => {
                        let tz = timezones.find(z => z.Id === meeting.TimeZoneId)
                        if(eventTimeZone?.Id !== tz?.Id){
                            meeting.DisplayTimeZone = tz?.Abbreviation ?? ""
                        }
                        return meeting
                    })
                    .sort((a,b) =>
                    {
                    if(a.Date < b.Date){
                        return -1;
                    }
                    else if(a.Date > b.Date){
                        return 1;
                    }
                    else if (a.StartTime < b.StartTime){
                        return -1;
                    }
                    else{
                        return 1;
                    }

                    });
            }));
    }
}
