import {Component, OnInit} from "@angular/core";
import {
    getGridDisplayWords
} from "../../Shared/ag-grid-options";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {SharedEmailService} from "../../Shared/Services/shared-email.service";
import {SharedEmail} from "../../../Services/SharedEmailService";
import * as _ from "lodash";
import {ColDef} from "ag-grid-community";
import {combineLatest} from "rxjs";
import {map, startWith} from "rxjs/operators";
import * as moment from "moment";
import {DateRange} from "../../Widget/DateRangePicker/date-range";
import {DatePickerOptionsBuilder} from "../../../DatePickerOptionsBuilder";
import {SharedEmailFormComponent} from "./shared-email-form.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfigService} from "../../Shared/Services/config.service";
import {UserFeatureName} from "../../../Models/User";
import {User} from "../../Shared/Models/user";
import {UserService} from "../../Shared/Services/user.service";
import {EditButtonRenderer} from "./edit-button-renderer.component";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import { Router } from "@angular/router";

@Component({
    selector: "app-shared-emails",
    templateUrl: "./shared-emails.component.html"
})

export class SharedEmailsComponent extends BaseGridComponent<SharedEmail> implements  OnInit {

    private baseApiUrl: string;
    dateRangeOptions: any;
    user:User;
    private dateRange: DateRange = { start: moment().add(-12, "months"),
        end: moment().endOf('day') } as DateRange;

    frameworkComponents = {
        editButtonRenderer: EditButtonRenderer
    };

    columnDefs: ColDef[] = [
        {
            field: 'DateTimeSent',
            headerName: 'Date',
            sort: 'desc',
            cellRenderer: function(params){
                return moment(params.data.DateTimeSent).format('MM/DD/YYYY')
            },
            flex: 3
        },
        {
            field: 'Subject',
            headerName: 'Headline',
            cellRenderer: function(params) {
                return params.data.IsInternalUseOnly ?
                    '<span class="text-danger">(Internal Use Only) </span>' + params.data.Subject :
                    params.data.Subject;},
            flex: 10
        },
        { field: 'Sender', flex: 3 },
        { field: 'Type', flex: 3 },
        {
            field: 'data',
            headerName: 'Action',
            headerClass: 'hide-header',
            suppressCellFocus: true,
            cellRenderer: 'editButtonRenderer',
            sortable: false,
            flex: 1
        } as ColDef
    ];

    context: any;

    emailSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control('')
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private sharedEmailService: SharedEmailService,
        private configService: ConfigService,
        private userService: UserService,
        private router: Router,
    ) {
        super();
        this.context = { componentParent: this };
    }

    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';

        this.baseApiUrl = this.configService.getActiveApiUrl();
        this.userService.getCurrentUser().subscribe( user => {
            this.user = user;
        });
        this.dateRangeOptions = new DatePickerOptionsBuilder()
            .setRangesToPredefinedList(moment())
            .buildOptions();

        this.pageCreator();
    }

    pageCreator(): void {

        const allSharedEmails$ = this.sharedEmailService.searchSharedEmails(this.dateRange.start, this.dateRange.end);
        const formUpdate$ = this.emailSearchForm.valueChanges.pipe(startWith(this.emailSearchForm.getRawValue()));

        this.rowData$ = combineLatest([allSharedEmails$, formUpdate$]).pipe(
            map(([sharedEmails, searchForm]) => sharedEmails.filter(a => this.SharedEmailFilter(a, searchForm.searchTerm)))
        );
    }

    private SharedEmailFilter(sharedEmail: SharedEmail, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        if(_.startsWith(sharedEmail.Subject.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (sa: SharedEmail): boolean => {

            const fieldsContains = _.map([sharedEmail.Subject, sharedEmail.Sender], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                        return _.includes(prop, w);
                    }))
            });
        };

        return query(sharedEmail);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onDateChange(event) {
        this.dateRange = event;
        this.pageCreator();
    }

    onCellClicked($event: any) {
        if ($event.column.colId === 'data') {
            return;
        }

        if(this.canAdmin($event.data)) {
            this.openModalForm($event.data);
        }
    }

    openModalForm(sharedEmail: SharedEmail): void {
        const initialState = {
            sharedEmail: sharedEmail
        };
        let modalRef = this.modalService.show(SharedEmailFormComponent, { initialState: initialState, class: 'modal-sm', animated: false, keyboard: false, backdrop: 'static' });

        modalRef.content.emailUpdated.subscribe(updated => {
            if(updated) {
                this.pageCreator();
            }
        });
    }

    canAdmin(sharedEmail: SharedEmail): boolean {
        return this.user && (_.includes(this.user.Features, UserFeatureName.IsAdmin) || this.user.Id === sharedEmail.SenderId);
    }
}
