import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ChartData, ChartEvent, ActiveElement, Chart} from "chart.js";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {ChartType} from "ag-grid-community";
import * as moment from "moment";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {ActivityWeekSum} from "../../../Models/ActivityWeekSum";
import {Router} from "@angular/router";
import {ActivityRoutePaths} from "../../Activity/activity-route-paths";
import {CacheService} from "../../Shared/Services/cache.service";
import {ActivityListComponent} from "../../Activity/ActivityList/activity-list.component";

@Component({
    selector: "app-bespoke-research-interaction-chart",
    templateUrl: "./research-dashboard-base-chart.component.html"
})
export class BespokeResearchInteractionChartComponent extends BaseChartComponent implements OnChanges {
    @Input()
    chartData: ActivityWeekSum[];

    @Input()
    team: number;

    categories: string[] = ['X'];
    title: string = "Bespoke Research Interaction";
    info: string = "Include activities: Email Exchange (X)";
    type: ChartType = 'bar';
    hasData: boolean = false;

    chartDataControl: UntypedFormControl = this.fb.control([]);

    constructor(
                private cacheService: CacheService,
                private fb: UntypedFormBuilder,
                private router: Router,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartData) {
            this.chartDataControl.patchValue(this.chartData);
        }
    }

    configureChart(): void {
        this.options = {
            scales: {
                y: {
                    type: 'linear',
                    position: 'left',
                    title: {
                        display: true,
                        text: 'Minutes'
                    },
                    min: 0,
                },
                y1: {
                    type: 'linear',
                    position: 'right',
                    title: {
                        display: true,
                        text: 'Count'
                    },
                    min: 0,
                }
            },
            onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart): void => {
                if (elements.length === 0) {
                    return;
                }
                const dateLabel = chart.data.labels[elements[0].index] as string;

                this.cacheService.setValue(ActivityListComponent.NavigatedFiltersCacheKey, {
                    startDate: moment(dateLabel),
                    endDate: moment(dateLabel).add(6, "days"),
                    categories: this.categories,
                    callerTeams: this.team ? [this.team] : null
                });

                this.router.navigate([ActivityRoutePaths.Activities]);
            }
        };

        this.configureData()
            .subscribe(chartData => {
                if (this.hasData) {
                    if (this.chart) {
                        this.updateChart(chartData);
                    } else {
                        this.data = chartData;
                        this.runChart();
                    }
                } else {
                    this.chart?.destroy();
                    this.chart = null;
                }
            });
    }

    configureData(): Observable<ChartData> {
        return this.chartDataControl.valueChanges.pipe(
            startWith(this.chartDataControl.value),
            map((chartData: ActivityWeekSum[]) => {
                this.hasData = chartData.length > 0;

                const bespokeCountsByWeek = _.chain(chartData)
                    .groupBy(cd => cd.WeekOf)
                    .value();

                const bespokeCounts = _.chain(bespokeCountsByWeek)
                    .reduce((acc, curr) => {
                        const filteredByCategory = curr.filter(cd => this.categories.includes(cd.Category));
                        acc.Minutes.push(_.chain(filteredByCategory).map(c => c.TotalDuration).sum().value());
                        acc.Count.push(_.chain(filteredByCategory).map(c => c.TotalCount).sum().value());
                        return acc;
                    }, {Minutes: [], Count: []})
                    .value();

                return {
                    labels: _.chain(bespokeCountsByWeek)
                        .map((ws, key) => moment(key, "YYYY-MM-DDT00:00:00").format("L"))
                        .value(),
                    datasets: Object.entries(bespokeCounts).map(([key, val]) => {
                        const isMinutes = key === "Minutes";
                        return {
                            label: key,
                            data: val,
                            yAxisID: isMinutes ? 'y' : 'y1',
                            type: isMinutes ? 'bar' : 'line',
                            borderWidth: isMinutes ? 1 : 3,
                            fill: false
                        }
                    })
                };
            })
        );
    }
}
