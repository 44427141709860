<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerTitleTemplate>
        <span *ngIf="event.Name" class="event-detail-title">
            <h4 title="{{ event.Name }}"></h4>
        </span>
    </ng-template>

    <ng-template #bannerSubTitleTemplate>
        <div class="flex-hbox flex-gap" style="max-width: 300px">
            <div style="max-width: 300px">
                <div><span style="font-weight: bold">Location: </span>{{ event && event.Location }}</div>
                <div><span style="font-weight: bold">Dates:</span> {{ getEventDateRange() }}</div>
            </div>
        </div>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <div class="flex-hbox" style="text-align: left; margin-left: 30px;" *ngIf="event">
            <div class="flex" style="margin-top: 26px;">
                <div><span style="font-weight: bold;">Status: </span>{{event.Status}}</div>
                <span style="font-weight: bold;">Meeting Coordinator:</span>
                <ng-container *ngFor="let coordinator of event.MeetingCoordinator; let last = last">
                    <span style="text-align: left;">
                     {{coordinator?.FirstName}} {{coordinator?.LastName}}
                        <ng-container *ngIf="!last">,</ng-container>
                    </span>
                </ng-container>
            </div>

            <div *ngIf="isCorpTravelUser">
                <a class="btn btn-sm btn-primary" href="{{ requestUrl }}" target="_blank">
                    <span class="fa fa-plane"></span>
                </a>
            </div>
        </div>
    </ng-template>

    <app-page-banner
            [pagetitle]="pageTitle"
            faicon="fa-calendar-o"
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex flex-hbox flex-gap">
        <div class="flex-2 flex-vbox flex-gap">
            <div class="flex">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Presenters">
                        <app-tab-presenter-list
                                class="flex flex-vbox"
                                [requestId]="requestId"
                        >
                        </app-tab-presenter-list>
                    </tab>
                </tabset>
            </div>
            <div class="flex">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Meetings">
                        <app-tab-request-meeting-list
                                class="flex flex-vbox"
                                [requestId]="requestId"
                        >
                        </app-tab-request-meeting-list>
                    </tab>
                </tabset>
            </div>
        </div>
        <div class="flex-3 flex-vbox">
            <form class="flex">
                <tabset class="flex-tabset" style="height: 100%;">
                    <tab heading="Attendees">
                        <app-tab-request-attendee-list
                                class="flex flex-vbox"
                                [requestId]="requestId"
                        >
                        </app-tab-request-attendee-list>
                    </tab>
                </tabset>
            </form>
        </div>
    </div>
</div>
