import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-confirm-modal",
    templateUrl: "./confirm-modal.component.html"
})
export class ConfirmModalComponent implements OnInit {

    message: string;
    messages: string[];

    @Output()
    action = new EventEmitter<boolean>();

    @Output()
    declineAction = new EventEmitter<boolean>();

    constructor(public bsConfirmModalRef: BsModalRef) { }

    ngOnInit(): void { }

    confirm(): void {
        this.bsConfirmModalRef.hide();
        this.action.emit(true);
    }

    decline(): void {
        this.bsConfirmModalRef.hide();
        this.declineAction.emit(true);
    }
}
