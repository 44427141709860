<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="emailSearchForm">
            <div class="form-group" style="margin-right: 5px;">
                <app-team-picker formControlName="teamIds"></app-team-picker>
            </div>
            <div class="form-group" style="margin-right: 5px;">
                <app-email-source-picker formControlName="emailType"></app-email-source-picker>
            </div>
            <div class="form-group" style="margin-right: 5px;">
                <app-date-range-picker
                        formControlName="dateRange"
                        [options]="dateRangeOptions">
                </app-date-range-picker>
            </div>
            <div class="form-group">
                <input type="search"
                       class="form-control input-sm pull-right"
                       style="width: 250px;"
                       formControlName="searchTerm"
                       placeholder="Search" />
            </div>
        </form>
    </ng-template>
    
    <app-page-banner
        pagetitle="Readership"
        faicon="fa fa-2x fa-envelope"
        faiconcolor="#ff8080"
        [bannerFormTemplate]="bannerFormTemplate"
        [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>