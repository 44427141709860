export class MifidAccount {
    AccountId: number;
    AccountName: string;
    Monikers: string[];
    EmailRecipients: string[];
    DeliveryPreference: string;
}

export interface FidessaAccount {
    AccountName: string;
    AccountId: number;
    Moniker: string;
}

export interface AccountMonikers {
    AccountName: string;
    AccountId: number;
    Monikers: string[]
}