import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {SelectedEmail} from "./email-select-list-component";

@Component({
    selector: "app-email-select-modal",
    templateUrl: "./email-select-modal.component.html",
    styleUrls: ["./email-select-modal.scss"],
    encapsulation: ViewEncapsulation.None
})
export class EmailSelectModalComponent implements OnInit {

    @Output()
    selectedEmail$ = new EventEmitter<SelectedEmail>();

    previewEmail$ = new EventEmitter<SelectedEmail>();

    selectedEmail: SelectedEmail;

    previewHtml: string = "";

    previewExpanded: boolean = false;

    constructor(
        public bsConfirmModalRef: BsModalRef
    ) {
    }

    ngOnInit(): void {
        this.previewEmail$.subscribe(email => {
            this.selectedEmail = email;
            this.previewEmail(email);

        })
    }

    togglePreviewExpansion() {
        this.previewExpanded = !this.previewExpanded;
    }

    previewEmail(email: SelectedEmail) {
        this.previewHtml = email.Body;
    }

    select(): void {
        this.bsConfirmModalRef.hide();
        this.selectedEmail$.emit(this.selectedEmail);
    }

    cancel(): void {
        this.bsConfirmModalRef.hide();
    }
}

