
export class CorpTravelAdminRoutePaths {
  static readonly BasePath: string = "corptraveladmin";

  static readonly HotelsProviderListPathFragment: string = "providers/hotels";
  static readonly RestaurantsProviderListPathFragment: string = "providers/restaurants";
  static readonly TransportationProviderListPathFragment: string = "providers/transportation";
  static readonly AirportListPathFragment: string = "airports";
  static readonly TravelCityListPathFragment: string = "cities";
  static readonly BairdRepAnalystListPathFragment: string = "bairdrep";

  static readonly CorpTravelAdminDashboard: string = CorpTravelAdminRoutePaths.BasePath;
  static readonly HotelsProviderList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.HotelsProviderListPathFragment}`;
  static readonly RestaurantsProviderList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.RestaurantsProviderListPathFragment}`;
  static readonly TransportationProviderList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.TransportationProviderListPathFragment}`;
  static readonly AirportList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.AirportListPathFragment}`;
  static readonly TravelCityList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.TravelCityListPathFragment}`;
  static readonly BairdRepAnalystList: string = `${CorpTravelAdminRoutePaths.BasePath}/${CorpTravelAdminRoutePaths.BairdRepAnalystListPathFragment}`;
}
