import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {ActivityStockCallCategory} from "../../../../Models/ActivityStockCallCategory";

@Component({
    selector: "app-stock-call-category-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(StockCallCategoryPickerComponent)
})
export class StockCallCategoryPickerComponent extends BasePickerComponent<ActivityStockCallCategory> {

    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);

        this.showSelectClearAll = true;
        this.name = "Stock Call Category(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.activityService.getActivityStockCallCategories().pipe(
            map(sccs => _.filter(sccs, scc => scc.Name && scc.Name.length > 0)),
            map(sccs => _.sortBy(sccs, scc => scc.Name))
        );
    }
}
