import {Component, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {CommissionService} from "../../Shared/Services/commission.service";
import {UserService} from "../../Shared/Services/user.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {combineLatest, of} from "rxjs";
import * as daterangepicker from "bootstrap-daterangepicker";
import {PercentageRenderer} from "../../Widget/CellRenderers/percentage-renderer.component";
import {CommissionAccountRenderer} from "../../Widget/CellRenderers/commission-account-renderer";
import {AccountTagRenderer} from "../../Widget/CellRenderers/account-tag-renderer.component";
import {ColDef, IHeaderParams, RowClickedEvent} from "ag-grid-community";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {accountTagComparator} from "../../Shared/ag-grid-options";
import {AccountMonthlyCommission, MonthlyCommission} from "../../../Models/AccountMonthlyCommission";
import {currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {CollapsibleHeaderRenderer} from "../../Widget/CellRenderers/collapsible-header-renderer.component";
import {PercentChange} from "../../../Models/SplitCommissions";
import * as _ from "lodash";
import {ReportService} from "../../Shared/Services/report.service";
import {RoleName} from "../../../Models/Role";
import {ActivatedRoute, Router} from "@angular/router";
import { AccountRoutePaths } from "../../Account/account-route-paths";
import { annualizeAccountMonthlyCommissions } from "../../../Helpers/AnnualizationHelper";
import { UserFeatureName } from "../../Admin/UserFeatures/user-features.service";

@Component({
    selector: "app-reports-year-over-year-commission",
    templateUrl: "./year-over-year-commission.component.html"
})

export class YearOverYearCommissionComponent extends BaseGridComponent<YoyRow> implements OnInit {

    columnDefs: ColDef[];
    pinnedTopRowData: YoyRow[];
    datePickerOptions: daterangepicker.Options = {};
    categoryKeyValues: { key: string, value: string }[];

    pageTitle: string = 'Year Over Year';

    startYears$ = of([
        moment().year().toString(),
        moment().subtract(1, "year").year().toString()
    ]);

  startOfYear: moment.Moment = moment().startOf('year');
  endOfYear: moment.Moment = moment().endOf('year');
  disableDateControls: boolean = false;

    yoySearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(false),
        accountName: this.fb.control(""),
        dateRange: this.fb.control(null),
        startYear: this.fb.control(moment().year().toString()),
        categories: this.fb.control([]),
        annualize: this.fb.control(false),
    });

    frameworkComponents = {
        percentageRenderer: PercentageRenderer,
        accountRenderer: CommissionAccountRenderer,
        accountTagRenderer: AccountTagRenderer,
        collapsibleHeaderRenderer: CollapsibleHeaderRenderer
    };

    get reportYear(): number {
        return moment(this.yoySearchForm.controls["startYear"].value).year();
    }

    get previousReportYear(): number {
        return moment().year(this.reportYear).subtract(1, "year").year();
    }

    constructor(
        private fb: UntypedFormBuilder,
        private commissionService: CommissionService,
        private userService: UserService,
        private reportService: ReportService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe(user => {
            const userIsCommissionExportAdmin = this.userService.hasUserFeature(user, UserFeatureName.CommissionExportAdmin);

            this.gridOptions.suppressContextMenu = !userIsCommissionExportAdmin;
        });

        this.gridOptions.sideBar = this.sideBar;
        this.gridOptions.groupDisplayType = "custom";
        this.gridOptions.suppressHorizontalScroll = false;
        this.gridOptions.suppressMakeColumnVisibleAfterUnGroup = true;
        this.gridOptions.rowClass = "groupable-row";
        this.gridOptions.paginationPageSize = 200;

        const commissionCategories = this.commissionService.getCommissionCategories()
        this.yoySearchForm.controls['categories'].patchValue(
            commissionCategories
                .filter(cc => cc.description !== "Syndicate")
                .map(cc => cc.code)
        );
        this.categoryKeyValues = commissionCategories.map(cc => {
            return { key: cc.code, value: cc.description };
        });

        if (this.route.snapshot.paramMap.get('id')) {
            this.pageTitle = 'Parent ' + this.pageTitle;
        }

        combineLatest([
            this.userService.getCurrentUser(),
            this.reportService.getLatestReleaseDate()
        ]).pipe(
            tap(([user, releaseDate]) => {
                this.yoySearchForm.get("myAccounts").patchValue(user.Role.Name === RoleName.Sales || user.Role.Name === RoleName.Traders, { emitEvent: false });
                const dateRange = { start: this.startOfYear, end: moment(releaseDate).startOf('day') };
                this.yoySearchForm.get("dateRange").patchValue(dateRange, { emitEvent: false });
                this.datePickerOptions = {
                    ...this.datePickerOptions,
                    maxDate: dateRange.end
                }
            }))
            .subscribe(([user, releaseDate]) => this.setRowData(releaseDate));

        this.yoySearchForm.controls["annualize"].valueChanges.pipe(
            startWith(this.yoySearchForm.controls["annualize"].value),
            debounceTime(10), // this debounce makes the annualize button select/unselect smoothly
        )
            .subscribe((annualize) => {
                const currentDateRange = this.yoySearchForm.controls["dateRange"].value;
                const startYear = this.yoySearchForm.controls["startYear"].value;
                const currentYear = moment().year().toString();

                this.disableDateControls = annualize;

                if (annualize) {
                    if (
                        currentDateRange.start != this.startOfYear ||
                        currentDateRange.end != this.endOfYear ||
                        startYear != currentYear
                    ) {
                        this.yoySearchForm.patchValue({
                            dateRange: {
                                start: this.startOfYear,
                                end: this.endOfYear,
                            },
                            startYear: currentYear
                        });
                    }
                }
            });
    }

    setRowData(releaseDate: string): void {
        const filters$ = combineLatest([
            this.yoySearchForm.controls["myAccounts"].valueChanges.pipe(
                startWith(this.yoySearchForm.controls["myAccounts"].value)
            ),
            this.yoySearchForm.controls["accountName"].valueChanges.pipe(
                startWith(this.yoySearchForm.controls["accountName"].value)
            ),
            this.yoySearchForm.controls["categories"].valueChanges.pipe(
                startWith(this.yoySearchForm.controls["categories"].value)
            ),
            this.yoySearchForm.controls["annualize"].valueChanges.pipe(
                startWith(this.yoySearchForm.controls["annualize"].value)
            ),
        ]);

        const startYear$ = this.yoySearchForm.controls["startYear"].valueChanges.pipe(
            startWith(this.yoySearchForm.controls["startYear"].value),
            tap(startYear => {
                const reportYear = moment(startYear).year();
                const previousReportYear = moment().year(startYear).subtract(1, "year").year();
                const start = moment().year(startYear).startOf("year");
                const end = startYear === moment().year().toString() ? moment(releaseDate).startOf('day') : moment().year(startYear).endOf("year");
                this.datePickerOptions = {
                    ...this.datePickerOptions,
                    minDate: start,
                    maxDate: moment().year(startYear).endOf("year")
                }

                if (!this.yoySearchForm.controls["annualize"].value) {
                    this.yoySearchForm.controls["dateRange"].patchValue({ start, end }, { emitEvent: false });
                }

                this.columnDefs = [
                    {
                        field: "Tags",
                        headerName: "Votes",
                        headerClass: "hide-header",
                        headerTooltip: "Votes",
                        cellRenderer: "accountTagRenderer",
                        comparator: accountTagComparator,
                        width: 35,
                        cellClass: "no-color",
                    },
                    {
                        field: "AccountName",
                        headerName: "Account",
                        flex: 2,
                        minWidth: 250,
                        cellRenderer: "agGroupCellRenderer",
                        cellRendererParams: {
                            innerRenderer: "accountRenderer",
                            reportYear: reportYear,
                            suppressCount: false
                        },
                        showRowGroup: true,
                        enableRowGroup: true,
                        cellClass: "no-color",
                        sort: "asc",
                    },
                    {
                        field: 'ParentName',
                        headerName: 'Parent Account',
                        flex: 1,
                        enableRowGroup: true,
                        cellClass: "no-color",
                        hide: true,
                    },
                    {
                        field: "Location",
                        headerName: "Location",
                        valueGetter: params => [params.data?.City, params.data?.State, params.data?.Country].filter(x => !!x).join(", "),
                        enableRowGroup: true,
                        flex: 1,
                        minWidth: 125,
                        maxWidth: 325,
                        cellClass: "no-color"
                    },
                    {
                        field: "Region",
                        headerName: "Region",
                        enableRowGroup: true,
                        flex: 1,
                        minWidth: 125,
                        cellClass: "no-color",
                        hide: true
                    },
                    {
                        field: "PrimaryBroker",
                        headerName: "PrBk",
                        enableRowGroup: true,
                        flex: 1,
                        minWidth: 145,
                        maxWidth: 165,
                        cellClass: "no-color"
                    },
                    {
                        field: "PrimaryTrader",
                        headerName: "PrTr",
                        enableRowGroup: true,
                        flex: 1,
                        minWidth: 145,
                        maxWidth: 165,
                        cellClass: "no-color"
                    },
                    { field: "Tier", enableRowGroup: true, maxWidth: 70, cellClass: "no-color" },
                    {
                        field: "ReportYearTotal",
                        headerName: reportYear.toString(),
                        headerComponent: "collapsibleHeaderRenderer",
                        headerComponentParams: {
                            isCollapsed: () => {
                                return !(this.gridColumnApi?.getColumn("ReportYearHighTouch").isVisible()
                                    && this.gridColumnApi?.getColumn("ReportYearETS").isVisible()
                                    && this.gridColumnApi?.getColumn("ReportYearOptions").isVisible()
                                    && this.gridColumnApi?.getColumn("ReportYearChecks").isVisible()
                                    && this.gridColumnApi?.getColumn("ReportYearSyndicate").isVisible())
                            },
                            onCollapse: (params: IHeaderParams, isCollapsed: boolean): void => {
                                this.gridColumnApi.setColumnsVisible([
                                    "ReportYearHighTouch",
                                    "ReportYearETS",
                                    "ReportYearOptions",
                                    "ReportYearChecks",
                                    "ReportYearSyndicate"
                                ], !isCollapsed);
                            }
                        },
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 105,
                        maxWidth: 155,
                        cellClass: "ag-right-aligned-cell",
                        headerClass: 'header-right'
                    },
                    {
                        field: "ReportYearHighTouch",
                        headerName: "High Touch",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 100,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "ReportYearETS",
                        headerName: "ETS",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "ReportYearOptions",
                        headerName: "Options",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "ReportYearChecks",
                        headerName: "Checks",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "ReportYearSyndicate",
                        headerName: "Syndicate",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "PreviousReportYearTotal",
                        headerName: previousReportYear.toString(),
                        headerComponent: "collapsibleHeaderRenderer",
                        headerComponentParams: {
                            isCollapsed: () => {
                                return !(this.gridColumnApi?.getColumn("PreviousReportYearHighTouch").isVisible()
                                    && this.gridColumnApi?.getColumn("PreviousReportYearETS").isVisible()
                                    && this.gridColumnApi?.getColumn("PreviousReportYearOptions").isVisible()
                                    && this.gridColumnApi?.getColumn("PreviousReportYearChecks").isVisible()
                                    && this.gridColumnApi?.getColumn("PreviousReportYearSyndicate").isVisible())
                            },
                            onCollapse: (params: IHeaderParams, isCollapsed: boolean): void => {
                                this.gridColumnApi.setColumnsVisible([
                                    "PreviousReportYearHighTouch",
                                    "PreviousReportYearETS",
                                    "PreviousReportYearOptions",
                                    "PreviousReportYearChecks",
                                    "PreviousReportYearSyndicate"
                                ], !isCollapsed);
                            }
                        },
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 105,
                        maxWidth: 155,
                        cellClass: "ag-right-aligned-cell",
                        headerClass: 'header-right'
                    },
                    {
                        field: "PreviousReportYearHighTouch",
                        headerName: "High Touch",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 100,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "PreviousReportYearETS",
                        headerName: "ETS",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "PreviousReportYearOptions",
                        headerName: "Options",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "PreviousReportYearChecks",
                        headerName: "Checks",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "PreviousReportYearSyndicate",
                        headerName: "Syndicate",
                        cellRenderer: currencyRenderer,
                        aggFunc: "sum",
                        flex: 1,
                        minWidth: 90,
                        maxWidth: 120,
                        cellClass: "lighter ag-right-aligned-cell",
                        headerClass: 'header-right',
                        initialHide: true
                    },
                    {
                        field: "AccountTotalChange",
                        headerName: "% Change",
                        maxWidth: 95,
                        comparator: (v1, v2) => v1.value < v2.value ? -1 : 1,
                        valueGetter: params => {
                            return {
                                reportYearTotal: params.data.ReportYearTotal,
                                previousReportYearTotal: params.data.PreviousReportYearTotal,
                                iconClass: params.data.PercentChange.IconClass,
                                value: params.data.PercentChange.Value,
                                toString: () => params.data.PercentChange.Display
                            };
                        },
                        aggFunc: params => {
                            const reportYearTotal = _.sum(params.values.map(v => v.reportYearTotal));
                            const previousReportYearTotal = _.sum(params.values.map(v => v.previousReportYearTotal));
                            const percentChange = this.getCommissionPercentChange(reportYearTotal, previousReportYearTotal);
                            return {
                                reportYearTotal,
                                previousReportYearTotal,
                                iconClass: percentChange.IconClass,
                                toString: () => percentChange.Display
                            }
                        },
                        cellRenderer: "percentageRenderer",
                        cellClass: "info ag-right-aligned-cell",
                        headerClass: "header-right",
                    }
                ];
            })
        );

        const parentComdolId = this.route.snapshot.paramMap.get('id');

        const data$ = combineLatest([
            this.yoySearchForm.controls["dateRange"].valueChanges.pipe(
                startWith(this.yoySearchForm.controls["dateRange"].value),
            ),
            startYear$
        ]).pipe(
            debounceTime(100),
            tap(() => {
                this.gridApi?.showLoadingOverlay();
            }),
            switchMap(([dateRange]) =>
                this.commissionService.getCommissionsYearOverYear(dateRange.start, dateRange.end).pipe(
                    map(yoyCommissions => {
                        if (parentComdolId) {
                            return yoyCommissions.filter(c => c.ParentComdolId === parentComdolId);
                        } else {
                            return yoyCommissions.filter(c => c.Status !== "Inactive" || c.MonthlyCommissions?.length > 0);
                        }
                    })
                )
            )
        );

        const rows$ = combineLatest([
            data$,
            filters$
        ]).pipe(
            debounceTime(10),
            map(([yoyCommissions, [myAccounts, accountName, categories, annualize]]) => {

                let commissionsToDisplay = annualize
                    ? annualizeAccountMonthlyCommissions(yoyCommissions, moment().year(), moment())
                    : yoyCommissions;

                return this.filterAndMapMonthlyCommissionsToYoyRows(
                    commissionsToDisplay,
                    myAccounts,
                    accountName,
                    categories
                );
            }),
        );

        this.rowData$ = rows$.pipe(
            tap(rowData => {
                const reportYearTotal = _.sum(rowData.map(r => r.ReportYearTotal));
                const previousReportYearTotal = _.sum(rowData.map(r => r.PreviousReportYearTotal));

                this.pinnedTopRowData = [{
                    AccountName: "Total",
                    ReportYearTotal: reportYearTotal,
                    ReportYearHighTouch: _.sum(rowData.map(r => r.ReportYearHighTouch)),
                    ReportYearETS: _.sum(rowData.map(r => r.ReportYearETS)),
                    ReportYearOptions: _.sum(rowData.map(r => r.ReportYearOptions)),
                    ReportYearChecks: _.sum(rowData.map(r => r.ReportYearChecks)),
                    ReportYearSyndicate: _.sum(rowData.map(r => r.ReportYearSyndicate)),
                    PreviousReportYearTotal: previousReportYearTotal,
                    PreviousReportYearHighTouch: _.sum(rowData.map(r => r.PreviousReportYearHighTouch)),
                    PreviousReportYearETS: _.sum(rowData.map(r => r.PreviousReportYearETS)),
                    PreviousReportYearOptions: _.sum(rowData.map(r => r.PreviousReportYearOptions)),
                    PreviousReportYearChecks: _.sum(rowData.map(r => r.PreviousReportYearChecks)),
                    PreviousReportYearSyndicate: _.sum(rowData.map(r => r.PreviousReportYearSyndicate)),
                    PercentChange: this.getCommissionPercentChange(reportYearTotal, previousReportYearTotal),
                } as YoyRow];

                setTimeout(() => {
                    if (rowData.length === 0) {
                        this.gridApi?.showNoRowsOverlay();
                    }
                }, 0);
            }),
        );
    }

    private filterAndMapMonthlyCommissionsToYoyRows(
        accountMonthlyCommissions: AccountMonthlyCommission[],
        myAccounts: boolean,
        accountName: string,
        categories: string[],
    ) {
        const sumCommissions = (commissions: MonthlyCommission[]) =>
            commissions
                .map(c => c.Commission)
                .reduce((acc, cv) => acc + cv, 0);

        const getValidCategorySum = (commissions: Map<string, MonthlyCommission[]>, category: string) =>
            (commissions?.has(category) &&
                categories.includes(category))
                ? sumCommissions(commissions.get(category))
                : 0;

        return accountMonthlyCommissions
            .filter(commission =>
                (accountName ? commission.Name.toLowerCase().includes(accountName) : true) &&
                (myAccounts ? commission.Tags.includes("My") : true)
            )
            .map(commission => {
                const groupedCommissions = this.groupCommissions(commission.MonthlyCommissions);

                const reportYearCommissions = groupedCommissions.get(this.reportYear);

                const previousReportYearCommissions = groupedCommissions.get(this.previousReportYear);

                const reportYearTotal = sumCommissions(
                    commission.MonthlyCommissions.filter(c =>
                        c.Year === this.reportYear &&
                        categories.includes(c.Category)
                    )
                );

                const previousReportYearTotal = sumCommissions(
                    commission.MonthlyCommissions.filter(c =>
                        c.Year === this.previousReportYear &&
                        categories.includes(c.Category)
                    )
                );

                const yoyRow: YoyRow = {
                    AccountId: commission.AccountId,
                    AccountName: commission.Name,
                    ParentName: commission.ParentName ? `${commission.ParentName} (${commission.ParentComdolId})` : `${commission.Name} (${commission.ParentComdolId})`,
                    ParentComdolId: commission.ParentComdolId,
                    ComdolId: commission.ComdolId,
                    Status: commission.Status,
                    Tags: commission.Tags,
                    Region: commission.Region,
                    City: commission.City,
                    State: commission.State,
                    Country: commission.Country,
                    PrimaryBroker: commission.PrimaryBroker ?? "No Broker",
                    PrimaryTrader: commission.PrimaryTrader ?? "No Trader",
                    Tier: commission.Tier,
                    ReportYearTotal: reportYearTotal,
                    ReportYearHighTouch: getValidCategorySum(reportYearCommissions, "P"),
                    ReportYearETS: getValidCategorySum(reportYearCommissions, "E"),
                    ReportYearOptions: getValidCategorySum(reportYearCommissions, "O"),
                    ReportYearChecks: getValidCategorySum(reportYearCommissions, "C"),
                    ReportYearSyndicate: getValidCategorySum(reportYearCommissions, "S"),
                    PreviousReportYearTotal: previousReportYearTotal,
                    PreviousReportYearHighTouch: getValidCategorySum(previousReportYearCommissions, "P"),
                    PreviousReportYearETS: getValidCategorySum(previousReportYearCommissions, "E"),
                    PreviousReportYearOptions: getValidCategorySum(previousReportYearCommissions, "O"),
                    PreviousReportYearChecks: getValidCategorySum(previousReportYearCommissions, "C"),
                    PreviousReportYearSyndicate: getValidCategorySum(previousReportYearCommissions, "S"),
                    PercentChange: this.getCommissionPercentChange(reportYearTotal, previousReportYearTotal)
                };

                return yoyRow;
            });
    }

    private getCommissionPercentChange(year: number, previousYear: number): PercentChange {
        if (previousYear === 0) {
            return { Value: 0, Display: "N/A", IconClass: "fa fa-empire neutral" };
        } else {
            const change = _.round((((year - previousYear) / Math.abs(previousYear)) * 100), 2);
            const iconClass = change > 0 ? "fa fa-arrow-up in-the-black" : "fa fa-arrow-down in-the-red";
            if (change > 0) {
                const value = _.min([change, 500]);
                const display = change >= 500 ? "N/A" : `${change.toFixed(2)}%`;
                return { Value: value, Display: display, IconClass: iconClass };

            } else {
                const value = _.max([change, -100]);
                const display = change <= -100 ? "N/A" : `${change.toFixed(2)}%`;
                return { Value: value, Display: display, IconClass: iconClass };
            }
        }
    }

    private groupCommissions(commissions: MonthlyCommission[]): Map<number, Map<string, MonthlyCommission[]>> {
        const commissionGroups = new Map<number, Map<string, MonthlyCommission[]>>();
        commissions.forEach(c => {
            if (commissionGroups.has(c.Year)) {
                if (commissionGroups.get(c.Year).has(c.Category)) {
                    commissionGroups.get(c.Year).get(c.Category).push(c);
                } else {
                    commissionGroups.get(c.Year).set(c.Category, [c]);
                }
            } else {
                commissionGroups.set(c.Year, new Map<string, MonthlyCommission[]>());
                if (commissionGroups.get(c.Year).has(c.Category)) {
                    commissionGroups.get(c.Year).get(c.Category).push(c);
                } else {
                    commissionGroups.get(c.Year).set(c.Category, [c]);
                }
            }
        });
        return commissionGroups;
    }

    rowClicked($event: RowClickedEvent): void {
        if ($event.data?.AccountId && !$event.data?.Tags.includes("NoAccess")) {
            this.router.navigate([AccountRoutePaths.AccountDetail, $event.data.AccountId]);
        }
    }

    getContextMenuItems(params) {
        return [
            {
                name: 'Export All',
                subMenu: [
                    {
                        name: 'CSV Export',
                        action: () => {
                            params.api.exportDataAsCsv();
                        }
                    },
                    {
                        name: 'Excel Export (.xlsx)',
                        action: () => {
                            params.api.exportDataAsExcel();
                        }
                    },

                ]
            }
        ];
    }
}

interface YoyRow {
    AccountId: number;
    AccountName: string;
    ParentName: string;
    ParentComdolId: string;
    ComdolId: string;
    Status: string;
    Tags: string[];
    Region: string;
    City: string;
    State: string;
    Country: string;
    PrimaryBroker: string;
    PrimaryTrader: string;
    Tier: string;
    ReportYearTotal: number;
    ReportYearHighTouch: number;
    ReportYearETS: number;
    ReportYearOptions: number;
    ReportYearChecks: number;
    ReportYearSyndicate: number;
    PreviousReportYearTotal: number;
    PreviousReportYearHighTouch: number;
    PreviousReportYearETS: number;
    PreviousReportYearOptions: number;
    PreviousReportYearChecks: number;
    PreviousReportYearSyndicate: number;
    PercentChange: PercentChange;
}
