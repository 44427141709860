import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AccountService} from "../../Shared/Services/account.service";
import {RevenueTarget, RevenueTargetService} from "../../Shared/Services/revenue-target.service";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import * as moment from "moment";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../../Models/User";

@Component({
    selector: 'app-account-vote-form',
    templateUrl: "./revenue-target-form.component.html"
})

export class RevenueTargetFormComponent implements OnInit {

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    @Input()
    revenueTargetId: number;

    @Input()
    accountId: number;

    invalidYears: number[] = [];

    formSaving: boolean = false;

    user: User;

    revenueTargetForm: UntypedFormGroup = this.fb.group({
        year: this.fb.control('', [
            Validators.required,
            Validators.max(9999),
            Validators.min(2000),
            this.forbiddenYearValidator(),
        ]),
        comdolId: this.fb.control({ value: '', disabled: true}),
        city: this.fb.control({ value: '', disabled: true}),
        target: this.fb.control('', [Validators.required, Validators.min(0)]),
        note: this.fb.control('', [Validators.maxLength(2000)]),
        createdBy: this.fb.control({value: '', disabled: true}),
        updatedBy: this.fb.control({value: '', disabled: true}),
    });

    constructor(private fb: UntypedFormBuilder,
                private revenueTargetService: RevenueTargetService,
                private accountService: AccountService,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private userService: UserService,
                private toastr: ToastrService) { }

    ngOnInit(): void {
        if (this.revenueTargetId) {
            this.revenueTargetForm.get('year').disable();
            this.revenueTargetService.getRevenueTarget(this.revenueTargetId)
                .subscribe(rt => {
                    this.revenueTargetForm.patchValue({
                        year: rt.Year,
                        target: rt.Target,
                        note: rt.Note,
                        comdolId: rt.ComdolId,
                        city: rt.City,
                        createdBy: rt.CreatedBy ? `${rt.CreatedBy} ${moment(rt.CreatedOn).format("L")}` : '',
                        updatedBy: rt.UpdatedBy ? `${rt.UpdatedBy} ${moment(rt.UpdatedOn).format("L")}` : '',
                    });
                })
        } else {
            this.accountService.getAccountById(this.accountId)
                .subscribe(account => {
                    this.revenueTargetForm.patchValue({
                        comdolId: account.ComdolId,
                        city: account.City,
                    });
                });

            this.revenueTargetService.getByAccount(this.accountId)
                .subscribe(revTargets => {
                    this.invalidYears = revTargets
                        .filter(rt => rt.IsMine)
                        .map(rt => rt.Year);
                    this.revenueTargetForm
                        .get('year')
                        .updateValueAndValidity();
                });

            this.userService.getCurrentUser().subscribe(user => {
                this.user = user;
            });

            this.revenueTargetForm.patchValue({
                year: new Date().getFullYear(),
                createdBy: `${this.user.Id} ${moment().format("L")}`,
            });
        }
    }

    forbiddenYearValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const forbidden = this.invalidYears.includes(control.value);
            return forbidden ? {forbiddenName: {value: control.value}} : null;
        };
    }

    save(): void {
        if (this.revenueTargetForm.valid) {
            this.formSaving = true;

            let revTarget = {
                Id: this.revenueTargetId,
                AccountId: this.accountId,
                Year: this.revenueTargetForm.value.year,
                Target: this.revenueTargetForm.value.target,
                Note: this.revenueTargetForm.value.note,
            } as RevenueTarget;

            this.revenueTargetService.updateRevenueTarget(this.revenueTargetId, revTarget)
                .subscribe(() => {
                    this.toastr.success("Revenue Target Saved");
                    this.dataUpdated.emit(true);
                    this.formSaving = false;
                    this.modalRef.hide();
                });
        }
    }

    delete(): void {

        let confirmRef = this.modalService.show(ConfirmModalComponent, {
            ignoreBackdropClick: true,
            backdrop: true,
            initialState: {message: `Are you sure you want to delete this Revenue Target`}
        });

        confirmRef.content.action
            .subscribe(() => {
                this.revenueTargetService.deleteRevenueTarget(this.revenueTargetId)
                    .subscribe(() => {
                        this.toastr.success("Revenue Target Deleted");
                        this.dataUpdated.emit(true);
                        confirmRef.hide();
                        this.modalRef.hide();
                    });
            });
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
