<div class="modal-body">
    <strong>Editing "{{sharedEmail.Subject}}"</strong>
    <div class="checkbox checkbox-sm">
        <label>
            <input type="checkbox" [(ngModel)]="sharedEmail.IsInternalUseOnly"/>Internal Use Only
        </label>
    </div>
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap">
        <button type="button" class="flex btn btn-sm btn-primary" (click)="confirm()">Save</button>
        <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()">Delete</button>
        <button type="button" class="flex btn btn-sm btn-default" style="" (click)="dismiss()">Cancel</button>
    </div>
</div>
