import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Observable, of} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import * as _ from "lodash";
import {AccountProfile, AccountService} from "../../Shared/Services/account.service";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment";
import {InvestorTypes} from "./investor-types";
import {InvestorStrategies} from "./investor-strategies";
import {UserPermissions} from "../../../Services/UserPermissions";
import {User} from "../../../Models/User";
import {Account} from "../../../Models/Account";


@Component({
    selector: "app-account-profile-form",
    templateUrl: "./account-profile-form.component.html"
})
export class AccountProfileFormComponent implements OnInit {

    @Input()
    accountId: number;

    @Output()
    profileEmitter = new EventEmitter<AccountProfile>();

    profileForm = this.fb.group({
        description: this.fb.control(null),
        investorTypes: this.fb.control([]),
        investorStrategy: this.fb.control(null),
        rateCard: this.fb.control(null),
        notes: this.fb.control(null),
        aumTotal: this.fb.control(null, [Validators.min(0), Validators.max(2147483647)]),
        aumEquityTotal: this.fb.control(null, [Validators.min(0), Validators.max(2147483647)]),
        aumDate: this.fb.control(null),
    });

    profile: AccountProfile = {} as AccountProfile;

    investorTypes$: Observable<any[]>;
    investorStrategy$: Observable<string[]>;

    canEditRateCard: boolean = false;
    canEditNotes: boolean = false;
    private user: User;
    private account: Account;

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private toastrService: ToastrService,
                private userService: UserService,
                private accountService: AccountService) { }

    ngOnInit(): void {
        this.investorTypes$ = of(_.map(InvestorTypes, t => { return { Label: t } }));
        this.investorStrategy$ = of([ null].concat(InvestorStrategies));

        combineLatest([this.userService.getCurrentUser(), this.accountService.getAccountById(this.accountId)])
            .subscribe(([user, account]) => {
                this.user = user;
                this.account = account;

                this.loadProfileForm(this.accountId);
            });
    }

    loadProfileForm(accountId: number): void {
        this.accountService.getAccountProfile(accountId)
            .subscribe(profile => {
                this.canEditRateCard = profile.CanSeeRateCard &&
                    this.userService.isUserSalesOrManagement(this.user) &&
                    this.userService.isMyAccount(this.user, this.account.Tags);

                this.canEditNotes = profile.CanSeeNotes &&
                    this.userService.isUserSalesOrManagement(this.user) &&
                    this.userService.isMyAccount(this.user, this.account.Tags);

                this.profile = profile;
                this.profile.AumDate = profile.AumDate ? moment(profile.AumDate).toDate() : null;

                this.profileForm.patchValue({
                    description: profile.Description,
                    investorTypes: _.sortBy(profile.InvestorTypes),
                    investorStrategy: profile.InvestorStrategy,
                    rateCard: profile.RateCard,
                    notes: profile.Notes,
                    aumTotal: profile.AumTotal,
                    aumEquityTotal: profile.AumEquityTotal,
                    aumDate: profile.AumDate,
                });
            });
    }

    save() {
        if (this.profileForm.valid) {

            let profile = {
                Description: this.profileForm.value.description,
                InvestorTypes: this.profileForm.value.investorTypes,
                InvestorStrategy: this.profileForm.value.investorStrategy,
                RateCard: this.profileForm.value.rateCard,
                Notes: this.profileForm.value.notes,
                AumTotal: this.profileForm.value.aumTotal,
                AumEquityTotal: this.profileForm.value.aumEquityTotal,
                AumDate: this.profileForm.value.aumDate,
                CanSeeRateCard: this.canEditRateCard,
                CanSeeNotes: this.canEditNotes,
            } as AccountProfile;

            const updateSuccess = (profile) => {
                this.toastrService.success("Account Profile Updated");
                this.profileEmitter.emit(profile);
                this.modalRef.hide();
            };

            const updateError = () => {
                this.toastrService.error("Error Saving Account Profile");
            };

            this.accountService.updateAccountProfile(this.accountId, profile)
                .subscribe(updateSuccess, updateError);
        }
    }

    close() {
        this.modalRef.hide();
    }

    get notesReadOnlyForUser(): boolean {
        return !UserPermissions.canEditAccountAndContactNotes(this.user);
    }
}
