import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {AccountService} from "../../Shared/Services/account.service";
import {UserService} from "../../Shared/Services/user.service";
import {combineLatest, of} from "rxjs";
import {CommissionService} from "../../Shared/Services/commission.service";
import {MonthlyCommission} from "../../../Models/AccountMonthlyCommission";
import {
    currencyFormatter,
    currencyRenderer,
    numberFormatter,
    numberRenderer
} from "../../Shared/ag-grid-cell-renderers";

export interface AccountCommissionRank {

    AccountId : number;
    Year: number
    Quarter: number;
    Tier: string;
    BrokerCount: number;
    BairdMarketShare: number;
    BairdRank: number;
    BairdCommission: number;
    BairdCommissionYearOverYear: number;
    BairdMarketShareBasisPoints: number;
    StreetCommission: number;
    StreetCommissionYearOverYear: number;
    RestrictedOrSuppressed: McLaganStatusUnavailable;
    AnnualizedFactor: number;
}

export type McLaganStatusUnavailable = "Rest" | "Supp" | "";

@Component({
    selector: "app-commission-rank",
    templateUrl: "./commission-rank.component.html",
    styleUrls: ["./commission-rank.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CommissionRankComponent extends BaseGridComponent<AccountCommissionRank> implements OnInit {

    @Input()
    accountId: number;

    columnDefs = [
        {
            headerName: '',
            children: [
                {field: 'Year', sort: 'desc', width: 70},
                {field: 'Tier', width: 90},
                {headerName: 'Brokers', width: 80, cellRenderer: (p) => p.data.BrokerCount || p.data.RestrictedOrSuppressed},
            ]
        },
        {
            headerName: 'Commission Year over Year (000\'s)',
            cellStyle: { 'text-align': 'left' },
            children: [
                {headerName: 'Street', flex: 1, cellRenderer: this.streetCommissionCellRenderer },
                {headerName: 'Baird', flex: 1, cellRenderer: this.bairdCommissionCellRenderer }
            ]
        },
        {
            headerName: 'Baird Rank',
            children: [
                {headerName: 'Mkt Sh %', width: 90, cellStyle: {backgroundColor: '#d9edf7'}, cellRenderer: (p) => `${ numberFormatter(p.data.BairdMarketShare, 2) }%`},
                {headerName: 'Rank', width: 60, cellStyle: {backgroundColor: '#d9edf7'}, cellRenderer: (p) => p.data.BairdRank || p.data.RestrictedOrSuppressed},
                {field: 'BairdMarketShareBasisPoints', headerName: 'Mkt Sh (bp)', width: 100, cellStyle: {backgroundColor: '#d9edf7'}, cellRenderer: (p) => p.value && numberRenderer(p) || p.data.RestrictedOrSuppressed  }
            ]
        }
    ];

    constructor(private accountService: AccountService,
                private userService: UserService,
                private commissionService: CommissionService) { super(); }

    ngOnInit(): void {

        this.defaultColDef.sortable = false;

        let user$ = this.userService.getCurrentUser();
        let account$ = this.accountService.getAccountById(this.accountId);

        combineLatest([user$, account$]).subscribe(([user, account]) => {
            if (this.userService.isMyAccount(user, account.Tags)) {
                this.loadCommissionRanks(account.Id);
            } else {
                this.rowData$ = of([]);
            }
        });
    }

    streetCommissionCellRenderer(p) {
        let val = `${ currencyFormatter(p.data.StreetCommission) }`;

        if (p.data.StreetCommissionYearOverYear) {
            val += ` (${ numberFormatter(p.data.StreetCommissionYearOverYear * 100, 2)}%)`;
        } else if (p.data.RestrictedOrSuppressed) {
            val += ` (${p.data.RestrictedOrSuppressed})`;
        }

        return val;
    }

    bairdCommissionCellRenderer(p) {
        let val = `${ currencyFormatter(p.data.BairdCommission) }`;
        if (p.data.BairdCommissionYearOverYear === Infinity || p.data.BairdCommissionYearOverYear === -Infinity) {
            val += ` (N/A)`;
        } else if (p.data.BairdCommissionYearOverYear) {
            val += ` (${ numberFormatter(p.data.BairdCommissionYearOverYear, 2) }%)`;
        }
        return val;
    }

    loadCommissionRanks(accountId: number): void {
        this.accountService.getAccountCommissionRank(accountId).subscribe(comRanks => {

            if (comRanks.length == 0) {
                this.rowData$ = of([]);
                return;
            }

            let years = comRanks.map(r => r.Year).concat(comRanks.map(r => r.Year - 1));

            this.commissionService.getAccountMonthlyCommissions(years, accountId, true).subscribe(commissions => {
                const thisYearAndLastYear = (year:number) => {
                    return (acc:{thisYear:number, lastYear: number}, commission: MonthlyCommission) =>{
                        if(year == commission.Year){
                            acc.thisYear = acc.thisYear + (commission.Commission);
                        } else {
                            acc.lastYear = acc.lastYear + (commission.Commission);
                        }
                        return acc;
                    }
                };
                const sum = (acc:number, curr:MonthlyCommission) => {
                    acc += curr.Commission;
                    return acc;
                };
                let commission = (commissions.map(d => d.MonthlyCommissions || [])
                    .reduce((acc, val) => acc.concat(val), []))
                    .filter(c => !["S", "O"].includes(c.Category));

                comRanks.forEach(r => {
                    let yoyComm = commission
                        .filter(c => (c.Year === r.Year && c.Quarter <= r.Quarter) || c.Year === r.Year - 1)
                        .reduce(thisYearAndLastYear(r.Year), {thisYear:0, lastYear:0});
                    let bairdYoy = ((yoyComm.thisYear * r.AnnualizedFactor) - yoyComm.lastYear) / yoyComm.lastYear;
                    r.BairdCommission = commission
                        .filter(c => c.Year === r.Year)
                        .filter(c => c.Quarter <= r.Quarter)
                        .reduce(sum, 0);
                    r.StreetCommission = Math.round(r.StreetCommission / 1000);

                    r.BairdCommission = Math.round(r.BairdCommission / 1000);
                    r.BairdCommissionYearOverYear = yoyComm.thisYear === 0 ? Infinity : Math.round(bairdYoy * 10000) / 100;

                    r.BairdMarketShare = Math.round(r.BairdCommission / r.StreetCommission * 10000) / 100;
                    if (!r.BairdMarketShare || r.BairdMarketShare === Infinity || r.BairdMarketShare === -Infinity) r.BairdMarketShare = 0;

                    r.BairdMarketShareBasisPoints = (r.BairdCommission === 0 || !(r.StreetCommissionYearOverYear && r.BairdCommissionYearOverYear)) ? undefined : r.BairdMarketShare *(1-(1+r.StreetCommissionYearOverYear)/(1+bairdYoy)) * 100;
                    if (r.BairdMarketShareBasisPoints) r.BairdMarketShareBasisPoints = Math.round(r.BairdMarketShareBasisPoints);
                });

                this.rowData$ = of(comRanks);
            });
        });
    }
}
