import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../Shared/Services/user.service";
import {AccountProfile, AccountService} from "../../Shared/Services/account.service";
import {AccountBillingInfo, InvoiceContact} from "../../../Models/AccountBillingInfo";

@Component({
    selector: "app-billing-info-form",
    templateUrl: "./billing-info-form.component.html"
})
export class BillingInfoFormComponent implements OnInit{

    @Input()
    accountId: number;

    @Output()
    billingInfoEmitter = new EventEmitter<AccountBillingInfo>();

    billingForm = this.fb.group({
        billingLines: this.fb.control(''),
        invoiceContacts: this.fb.control([]),
        invoicePath: this.fb.control('', [Validators.maxLength(255)]),
        isHardDollar: this.fb.control(''),
        hardDollarNotes: this.fb.control('', [Validators.maxLength(255)])
    });

    loading: boolean = false;
    saving: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private toastrService: ToastrService,
                private userService: UserService,
                private accountService: AccountService) { }

    ngOnInit(): void {
        this.loadBillingForm(this.accountId);
    }

    loadBillingForm(accountId: number): void {
        this.loading = true;
        this.accountService.getAccountBillingInfo(accountId)
            .subscribe(info => {
                this.loading = false;
                this.billingForm.patchValue({
                    billingLines: info.BillingLines,
                    invoicePath: info.InvoicePath,
                    invoiceContacts: info.InvoiceContacts.map(c => c.ContactId),
                    isHardDollar: info.IsHardDollar,
                    hardDollarNotes: info.HardDollarNotes

                })
            })
    }

    save() {
        if (!this.billingForm.valid)
            return;

        let info = {
            BillingLines: this.billingForm.value.billingLines,
            InvoicePath: this.billingForm.value.invoicePath,
            InvoiceContacts: this.billingForm.value.invoiceContacts.map(c => {
                return {
                    AccountId: this.accountId,
                    ContactId: c,
                } as InvoiceContact;
            }),
            IsHardDollar: this.billingForm.value.isHardDollar,
            HardDollarNotes: this.billingForm.value.hardDollarNotes
        } as AccountBillingInfo

        const updateSuccess = () => {
            this.saving = false;
            this.toastrService.success("Billing Info Updated");
            this.billingInfoEmitter.emit(info);
            this.modalRef.hide();
        };

        const updateError = () => {
            this.saving = false;
            this.toastrService.error("Error Saving Billing Info");
        };

        this.saving = true;
        this.accountService.updateAccountBillingInfo(this.accountId, info)
            .subscribe(updateSuccess, updateError);
    }

    close() {
        this.modalRef.hide();
    }
}
