<form class="form-inline summary-filters" [formGroup]="accountSummarySearchForm">
    <div class="form-group">
        <app-activity-category-picker formControlName="categories" [groupByFn]="groupActivityCategories" [categories]="categories" name="Categories" placeHolder="Categories" [searchable]=false></app-activity-category-picker>
    </div>
    <div class="form-group" *ngIf="accountSummarySearchForm.controls['dateRange'].value">
        <app-date-range-picker formControlName="dateRange" [enableRanges]="true" [options]="datePickerOptions"></app-date-range-picker>
    </div>
    <div *ngIf="activityReportAnalystFilter" class="form-group">
        <app-team-picker
            formControlName="analysts"
            [showResearchOnly]="true"
        >
        </app-team-picker>
    </div>
    <button type="button" [disabled]="exportDisabled" (click)="export()" class="btn btn-default btn-sm" tooltip="Export Data">
        <span class="fa fa-download fa-lg"></span>
    </button>
</form>
<div class="flex summary-grid">
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            rowClass="non-clickable-row"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [frameworkComponents]="frameworkComponents"
            [tooltipShowDelay]="0"
            (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
</div>
