import {Component, OnInit, TemplateRef, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {UserGroup} from "./user-group";
import {UserGroupService} from "./user-group.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {User} from "../../Shared/Models/user";
import * as _ from "lodash";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: "app-admin-user-groups",
    templateUrl: "./user-groups.component.html",
    styleUrls: ["./user-groups.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class UserGroupsComponent implements OnInit {

    selectUserGroupForm = this.fb.group({
        userGroup: this.fb.control(null)
    });

    userGroupForm = this.fb.group({
        name: this.fb.control('', [
            Validators.required,
            Validators.maxLength(20)]),
        description: this.fb.control(''),
        repcodes: this.fb.control([])
    });

    selectedUserGroup: UserGroup;
    userGroups: UserGroup[] = [];
    repCodes: string[];
    userGroupUsers: User[] = [];

    constructor(private userGroupService: UserGroupService,
                private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private toastr: ToastrService) { }

    ngOnInit(): void {

        this.userGroupService.getUserGroups()
            .subscribe(userGroups => {
                this.userGroups = _.orderBy(userGroups, ug => ug.GroupName.toLowerCase());
            });

        this.userGroupService.getAllUserGroupRepCodes()
            .subscribe(allRepCodes => {
                this.repCodes = _.orderBy(allRepCodes, r => r);
            });
        this.selectUserGroupForm.valueChanges
            .subscribe(x => {
                this.setSelectedGroup(x.userGroup);
            });
    }

    setSelectedGroup(group: UserGroup): void {
        this.userGroupUsers = [];

        if (!group) {
            this.selectedUserGroup = null;
            return;
        }

        this.selectedUserGroup = group;

        this.userGroupForm.setValue({
            name: ``,
            description: ``,
            repcodes: []
        });

        if (group.GroupId > 0) {

            this.userGroupService.getUserGroup(group.GroupId)
                .subscribe(userGroup => {
                    this.userGroupForm.patchValue({
                        name: userGroup.GroupName,
                        description: userGroup.Description,
                    });
                });

            this.userGroupService.getUserGroupRepCodes(group.GroupId)
                .subscribe(repCodes => {
                    // this.userGroupRepCodes = repCodes;
                    this.userGroupForm.patchValue({
                        repcodes: _.map(repCodes, r => r.RepCode),
                    });
                });

            this.userGroupService.getUserGroupUsers(group.GroupId)
                .subscribe(users => {
                    this.userGroupUsers = users;
                });
        }
    }

    onSubmit(): void {

        if (this.userGroupForm.valid) {
            let userGroupData = {
                GroupId: this.selectedUserGroup.GroupId,
                GroupName: this.userGroupForm.value.name,
                Description: this.userGroupForm.value.description,
                RepCodes: this.userGroupForm.value.repcodes
            } as UserGroup;

            this.userGroupService.updateUserGroup(userGroupData)
                .subscribe(updatedUserGroup => {
                    let ug = _.find(this.userGroups, ug => ug.GroupId === updatedUserGroup.GroupId);

                    if (!ug) {
                        this.userGroups.unshift(updatedUserGroup);
                    } else {
                        ug.GroupName = updatedUserGroup.GroupName;
                        ug.Description = updatedUserGroup.Description;
                    }

                    this.selectUserGroupForm.patchValue({
                        userGroup: updatedUserGroup
                    });

                    this.toastr.success(`User Group "${updatedUserGroup.GroupName}" Saved.`);
                });
        }
    }

    onAddUserGroup(): void {
        this.selectUserGroupForm.patchValue({
            userGroup: null
        });
        this.setSelectedGroup({ GroupName: ``, Description: `` } as UserGroup);
    }

    onDeleteUserGroup(selectedUserGroup: UserGroup): void {

        const initialState = {
            message: `Are you sure you want to delete the group "${selectedUserGroup.GroupName}"`,
        };
        this.bsModalRef = this.modalService.show(ConfirmModalComponent, {initialState});

        this.bsModalRef.content.action
            .subscribe(value => {
                console.log(value);
                if (value) {
                    this.userGroupService.deleteUserGroup(selectedUserGroup.GroupId).subscribe(x => {
                        this.toastr.success(`User Group "${selectedUserGroup.GroupName}" Deleted.`);
                        this.userGroups = _.filter(this.userGroups, ug => ug.GroupId !== selectedUserGroup.GroupId);
                        this.selectUserGroupForm.patchValue({
                            userGroup: null
                        });
                    });
                }
            });
    }

    bsModalRef: BsModalRef;

    addNewRepCode(repCode: string): string | false {
        if (repCode.length > 5) {
            return false;
        }

        return repCode.toUpperCase();
    }

    get selectedRepCodes(): string[] { return this.userGroupForm.get("repcodes").value}
}
