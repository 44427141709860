<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="providerSearchForm">
            <div class="form-group" style="min-width: 175px;">
                <app-travel-city-picker
                    formControlName="cityId"
                    [selectClearAll]="false"
                    [multipleSelect]="false"
                    [showTagCount]="false"
                    [closeAfterSelection]="true"
                    [setMaxItems]="1"
                    [includeNullOption]="true"
                    [placeHolderLabel]="'Select City'"
                >
                </app-travel-city-picker>
            </div>
            <div class="form-group">
                <input
                    type="search"
                    class="form-control input-sm"
                    style="width: 250px;"
                    formControlName="searchTerm"
                    placeholder="Search"
                />
            </div>
            <div class="form-group">
                <div class="btn btn-primary btn-sm" (click)="addProvider()"><span class="fa fa-plus"></span></div>
            </div>
        </form>
    </ng-template>

    <app-page-banner
        pagetitle="{{ getProviderTypeDisplayName(providerType) }} Admin"
        faicon="{{ getProviderTypeDisplayIcon(providerType) }}"
        faiconcolor="#800000"
        [bannerFormTemplate]="bannerFormTemplate"
        [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>

    <div class="page-body flex">
        <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>
