import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../Shared/Services/user.service";
import {
    SubscriptionService
} from "../../Shared/Services/subscription.service";
import {ToastrService} from "ngx-toastr";
import {forkJoin} from "rxjs";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";

@Component({
    selector: "app-contact-specialty-mailing-form",
    templateUrl: "./contact-specialty-mailing-form.component.html"
})
export class ContactSpecialtyMailingFormComponent implements OnInit {

    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    formLoading: boolean = true;

    specialtyMailingsMap: Map<string, Array<{ description: string, code: string, isBlueMatrix: boolean | null, symbol: string }>>
        = new Map<
            string,
            { description: string, code: string, isBlueMatrix: boolean | null, symbol: string }[]
        >();

    initialContactSpecialtyMailings: { code: string, isBlueMatrix: boolean }[] = [];

    specialtyMailingForm: UntypedFormGroup = this.fb.group({
        groupedMailings: this.fb.array([]),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private userService: UserService,
        private subscriptionService: SubscriptionService,
        private toastr: ToastrService,
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.subscriptionService.getSpecialtyMailings(true),
            this.subscriptionService.getContactSpecialtyMailings(this.contactId)
        ])
            .subscribe(([
                specialtyMailings,
                contactSpecialtyMailings
            ]) => {

                let analysts =
                    specialtyMailings
                        .map(m => m.Analyst)
                        .filter((analyst, index, list) => {
                            return list.indexOf(analyst) === index
                        });

                const emptyIndex = analysts.indexOf('');

                if (emptyIndex >= 0) {
                    analysts.splice(emptyIndex, 1, 'No Analyst');
                }

                analysts.sort(function (a, b) {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });

                analysts.forEach(analyst => {
                    this.specialtyMailingsMap.set(analyst, []);
                });

                this.initialContactSpecialtyMailings =
                    contactSpecialtyMailings
                        .map(sm => ({
                            code: sm.Code,
                            isBlueMatrix: sm.IsBlueMatrix
                        }));

                specialtyMailings
                    .forEach(specialtyMailing => {

                        if (!specialtyMailing.Analyst) {
                            specialtyMailing.Analyst = 'No Analyst'
                        }

                        let contactSpecialtyMailing = contactSpecialtyMailings.find(csm => csm.Code === specialtyMailing.Code);

                        if (this.specialtyMailingsMap.has(specialtyMailing.Analyst)) {

                            let values = this.specialtyMailingsMap.get(specialtyMailing.Analyst);

                            values.push({
                                description: specialtyMailing.Description,
                                code: specialtyMailing.Code,
                                isBlueMatrix: contactSpecialtyMailing?.IsBlueMatrix ?? null,
                                symbol: specialtyMailing.Symbol
                            });

                            this.specialtyMailingsMap.set(specialtyMailing.Analyst, values);

                        } else {

                            let values = [{
                                description: specialtyMailing.Description,
                                code: specialtyMailing.Code,
                                isBlueMatrix: contactSpecialtyMailing?.isBlueMatrix ?? null,
                                symbol: specialtyMailing.Symbol
                            }];

                            this.specialtyMailingsMap.set(specialtyMailing.Analyst, values);

                        }
                    });

                let groupedMailings = this.specialtyMailingForm.get('groupedMailings') as UntypedFormArray;

                this.specialtyMailingsMap.forEach((values, key) => {
                    let group = this.fb.group({
                        analyst: this.fb.control(key),
                        specialtyMailings: this.fb.array([]),
                    });

                    values.sort(function (a, b) {
                        return a.description.toLowerCase().localeCompare(b.description.toLowerCase());
                    })

                    values.forEach(value => {
                        (group.controls["specialtyMailings"] as UntypedFormArray)
                            .push(this.fb.group({
                                description: this.fb.control(value.description),
                                code: this.fb.control(value.code),
                                isBlueMatrix: this.fb.control(
                                    value.isBlueMatrix === true
                                        ? 'Yes'
                                        : value.isBlueMatrix === false
                                            ? 'No'
                                            : ''
                                ),
                                symbol: this.fb.control(value.symbol),
                            }));
                    });
                    groupedMailings.push(group);
                });

                this.formLoading = false;
            });
    }

    close() {
        this.modalRef.hide();
    }

    submit() {
        let selectedContactSpecialtyMailings: { code: string, isBlueMatrix: boolean }[] = [];

        let groupedMailings = this.specialtyMailingForm.get('groupedMailings') as UntypedFormArray;

        groupedMailings.controls.forEach(control => {
            let specialtyMailings = control.get('specialtyMailings') as UntypedFormArray;
            specialtyMailings.controls.forEach(m => {
                if (m.value.isBlueMatrix === 'Yes' || m.value.isBlueMatrix === 'No') {
                    selectedContactSpecialtyMailings.push({ code: m.value.code, isBlueMatrix: m.value.isBlueMatrix === 'Yes' });
                }
            });
        });

        let adds: { code: string, isBlueMatrix: boolean }[] = [];
        let updates: { code: string, isBlueMatrix: boolean }[] = [];
        let removes: string[] = [];

        selectedContactSpecialtyMailings.forEach(selectedKey => {
            if (!this.initialContactSpecialtyMailings.find(initialKey => initialKey.code === selectedKey.code)) {
                adds.push(selectedKey);
            }
        });

        selectedContactSpecialtyMailings.forEach(selectedKey => {
            if (this.initialContactSpecialtyMailings.find(initialKey => initialKey.code === selectedKey.code && initialKey.isBlueMatrix !== selectedKey.isBlueMatrix)) {
                updates.push(selectedKey);
            }
        });

        this.initialContactSpecialtyMailings.forEach(initialKey => {
            if (!selectedContactSpecialtyMailings.find(selectedKey => selectedKey.code === initialKey.code)) {
                removes.push(initialKey.code);
            }
        });

        let addAndUpdatesAndRemoves$ = [];

        adds.forEach(add => addAndUpdatesAndRemoves$.push(this.subscriptionService.createContactSpecialtyMailing(this.contactId, add.code, add.isBlueMatrix)));
        updates.forEach(update => addAndUpdatesAndRemoves$.push(this.subscriptionService.updateContactSpecialtyMailing(this.contactId, update.code, update.isBlueMatrix)));
        removes.forEach(remove => addAndUpdatesAndRemoves$.push(this.subscriptionService.deleteContactSpecialtyMailing(this.contactId, remove)));

        if (addAndUpdatesAndRemoves$.length > 0) {
            let loadingModal = this.modalService.show(LoadingModalComponent, {
                initialState: { message: 'Saving Specialty Mailings' },
                animated: false,
                keyboard: false,
                backdrop: 'static'
            });

            forkJoin(addAndUpdatesAndRemoves$)
                .subscribe((updatedMailings) => {
                    this.toastr.success("Specialty Mailings Saved");
                    loadingModal.hide();
                    this.modalRef.hide();
                    if (updatedMailings.length > 0) {
                        this.dataUpdated.emit(true);
                    }
                });
        } else {
            this.toastr.success("Specialty Mailings Saved");
            this.modalRef.hide();
        }
    }
}
