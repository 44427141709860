import {Injectable} from "@angular/core";
import {Notification} from "../../../Models/Notification";
import {Observable, of} from "rxjs";
import {BaseHttpService} from "./base-http.service";

@Injectable({
    providedIn: "root"
})
export class NotificationService {
    constructor(private baseHttp: BaseHttpService) {
    }
    
    getNotifications(): Observable<Notification[]> {
        return this.baseHttp.getData("/notification");
    }
    
    addNotification(notification: Notification): Observable<Notification> {
        return this.baseHttp.postData("/notification", notification);
    }
    
    updateNotification(notification: Notification): Observable<void> {
        return this.baseHttp.putData(`/notification/${notification.Id}`, notification);
    }
    
    deleteNotification(id: number): Observable<void> {
        return this.baseHttp.deleteData(`/notification/${id}`);
    }
}