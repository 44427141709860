<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">Upload {{ fileTitle }}</h3>
    </div>
    <form [formGroup]="form">
        <div class="modal-body">
            <div class="row">
                <div class="col-xs-12 form-group">
                    <strong>Upload Excel File</strong>
                    <a (click)="downloadTemplate()" style="cursor: pointer;">(Download Template)</a>
                </div>
            </div>
            <div class="row">
                <div class="col-xs-12">
                    <app-file-upload formControlName="file"></app-file-upload>
                </div>
            </div>
            <div class="row text-danger" *ngIf="errors.length > 0">
                <div class="col-xs-12">No records saved (errors found):</div>
                <div class="col-xs-12">
                    <div *ngFor="let error of errors">
                        Row {{error.Line}}: {{ error.Messages.join(', ') }}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="col-xs-6">
                <button type="button" class="col-xs-12 btn btn-primary" [disabled]="form.invalid" (click)="save()">Save</button>
            </div>
            <div class="col-xs-6">
                <button type="button" class="col-xs-12 btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
