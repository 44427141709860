import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {CellClickedEvent, ColDef, ValueFormatterParams} from "ag-grid-community";
import {EventService} from "../../Shared/Services/event.service";
import {map} from "rxjs/operators";
import {RequestEventMeeting} from "../../../Components/Events/CalendarEvent";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {durationHeaderTemplate} from "../../Shared/ag-grid-options";
import * as moment from "moment";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import * as _ from "lodash";
import {RequestMeetingFormComponent} from "./RequestMeetingForm/request-meeting-form.component";
import {ActivatedRoute} from "@angular/router";
import {combineLatest} from "rxjs";
import {TimeZoneService} from "../../Shared/Services/time-zone.service";
import {timeComparator} from "../../Shared/ag-grid-cell-comparators";
@Component({
    selector: "app-tab-request-meeting-list",
    template: `
        <div class="flex-1 flex-vbox">
            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [groupDefaultExpanded]="groupDefaultExpanded"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
                    (cellClicked)="onCellClick($event)"
            >
            </ag-grid-angular>
        </div>`
})
export class TabRequestMeetingListComponent extends BaseGridComponent<MeetingRow> implements OnInit {

    @Input()
    requestId: number;

    user: User;

    timeZone: string;

    columnDefs = [
        {field: 'MeetingDate', headerName: 'Date', cellRenderer: dateRenderer, flex: 1},
        {
            field: 'MeetingTime',
            headerName: 'Time',
            flex: 1,
            valueFormatter: params => this.getDisplayTime(params),
            comparator: timeComparator,
            sort: "asc"
        },
        {
            field: 'Duration',
            headerName: '',
            headerTooltip: 'Duration',
            flex: 1,
            headerComponentParams: {template: durationHeaderTemplate}
        },
        {field: 'MeetingType', headerName: 'Type', headerTooltip: 'Meeting Type', tooltipField: 'MeetingType', flex: 1},
        {
            field: 'NumberOfInterested',
            headerName: 'Int',
            flex: 1,
            type: 'numericColumn',
            cellRenderer: _.bind(this.interestedCountRenderer, this),
            headerTooltip: 'Interested'
        },
        {
            field: 'NumberOfAttendees',
            headerName: 'Att',
            flex: 1,
            type: 'numericColumn',
            cellRenderer: _.bind(this.attendeeCountRenderer, this),
            headerTooltip: 'Registered & Attended'
        },
    ] as ColDef[];

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private eventService: EventService,
                private userService: UserService,
                private timeZoneService: TimeZoneService,
                private route: ActivatedRoute,
    ) {
        super();
    }

    public groupDefaultExpanded = 1;

    ngOnInit(): void {
        this.requestId = +this.route.snapshot.queryParamMap.get('requestId') || +this.route.snapshot.queryParamMap.get('reqId');

        let request$ = this.eventService.getRequest(this.requestId)
        let timezones$ = this.timeZoneService.getTimeZones();
        let requestMeetings$ = this.eventService.getRequestMeetings(this.requestId)

        combineLatest([request$, timezones$])
            .subscribe(([request, timezones]) => {
                let timeCol = this.columnDefs.find(c => c.field === 'MeetingTime');
                let timeZone = timezones.find(tz => tz.Id === request.TimeZoneId);

                if (timeZone){
                    this.timeZone = `(${timeZone.Abbreviation})`
                }
                timeCol.headerName = `Time ${ this.timeZone }`;
                this.gridOptions.api.setColumnDefs(this.columnDefs);
                this.rowData$ = requestMeetings$.pipe(
                    map(meetings => {
                        return meetings.map(meeting => {
                            return {
                                Id: meeting.Id,
                                MeetingDate: `${moment(meeting.MeetingDate) ? moment(meeting.MeetingDate).format('L'): meeting.MeetingDate}`,
                                MeetingTime: `${meeting.MeetingDate}`,
                                Duration: meeting.Duration,
                                MeetingType: `${meeting.MeetingType}`,
                                NumberOfInterested: meeting.NumberOfInterested,
                                NumberOfAttendees: meeting.NumberOfAttendees,
                            } as MeetingRow
                        })
                    })
                );
            });
    }

    getDisplayTime(params: ValueFormatterParams<RequestEventMeeting>): string {
        return this.formatTimeRange(params.data.MeetingTime);
    }

    formatTimeRange(meetingTime: string | moment.Moment,): string {
        let startTimeFormatted = meetingTime ?
            moment(meetingTime).format('h:mm A',) : '';
        return `${startTimeFormatted}`;
    }

    attendeeCountRenderer(params: any): string {
        let attendeeCount = params && params.data.NumberOfAttendees;
        let displayString = `${attendeeCount}`;
        return `<a class="pointer">${displayString}</a>`;
    }

    interestedCountRenderer(params: any): string {
        let interestedCount = params && params.data.NumberOfInterested;
        let displayString = `${interestedCount}`;
        return `<a class="pointer">${displayString}</a>`;
    }

    onCellClick($event: CellClickedEvent<RequestEventMeeting>) {
        const initialState = {
            meetingId: $event.data.Id,
            timeZone: this.timeZone,
        };
            this.modalService.show(RequestMeetingFormComponent, {
                initialState: initialState,
                animated: false,
                keyboard: false,
                backdrop: 'static',
                class: 'modal-xl'
            });
    }
}

interface MeetingRow {
    Id: number;
    MeetingDate: string;
    MeetingTime: string;
    Duration: number;
    MeetingType: string;
    NumberOfInterested: number,
    NumberOfAttendees: number,
}
