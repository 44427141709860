import {Component, forwardRef, Input, OnChanges, SimpleChanges} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact} from "../../../Models/Contact";

@Component({
    selector: "app-contact-name",
    template: `
        <span *ngIf="contact">
            {{ contact.FirstName }} {{ contact.LastName }} ({{contact.AccountName}})
        </span>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ContactNameComponent),
            multi: true
        }
    ]
})
export class ContactNameComponent implements OnChanges {

    @Input()
    contactId: number;
    
    contact: Contact;
    
    constructor(private contactService: ContactService){ }
    
    ngOnChanges(changes: SimpleChanges): void {
        this.contactService.getContactById(this.contactId).subscribe(c => this.contact = c);
    }
}