import {Component, OnInit} from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {map, startWith, tap, withLatestFrom} from "rxjs/operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {QueryFormComponent} from "./query-form.component";
import {dateRenderer, yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {QueryService} from "../../Shared/Services/query.service";
import {QueryDto} from "../../../Services/QueryService";
import {ListGroupsService} from "../../Admin/ListGroups/list-groups.service";
import * as _ from "lodash";
import {SavedContactListGroup} from "../../Admin/ListGroups/saved-contact-list-group";
import {ColDef} from "ag-grid-community";
import {UserService} from "../../Shared/Services/user.service";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";

@Component({
    selector: "app-query-list",
    templateUrl: "./query-list.component.html"
})
export class QueryListComponent extends BaseGridComponent<any> implements OnInit{

    columnDefs: ColDef[] = [
        {field: 'Name', headerName: 'Name', sort: 'asc', flex: 2 },
        {field: 'Description', headerName: 'Description', flex: 2 },
        {field: 'ListGroupPermission', headerName: 'Permission', flex: 1, valueGetter: this.renderPermission },
        {field: 'ListGroupId', headerName: 'Group', flex: 1, valueGetter: _.bind(this.renderGroup, this) },
        {field: 'Query.ExportObject', headerName: 'Select', flex: 1 },
        {field: 'CreatedBy', headerName: 'Created By', flex: 1 },
        {field: 'UpdatedBy', headerName: 'Modified By', flex: 1, valueGetter: (p) => p.data.UpdatedBy || '' },
        {field: 'UpdatedAt', headerName: 'Modified Date', flex: 1, valueGetter: (p) => p.data.UpdatedAt || p.data.CreatedAt , cellRenderer: dateRenderer },
        {field: 'IsEmailable', headerName: 'Is Emailable', flex: 1, hide: true, cellRenderer: yesNoCellRenderer}
    ];

    querySearchBox: UntypedFormControl = this.fb.control('');

    listGroups: _.Dictionary<SavedContactListGroup>;

    constructor(private fb: UntypedFormBuilder,
                private queryService: QueryService,
                private listGroupsService: ListGroupsService,
                private modalService: BsModalService,
                private userService: UserService) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.pagination = true;

        this.listGroupsService.getAllGroups()
            .subscribe(groups => {
                this.listGroups = _.keyBy(groups, "Id");
                this.setRowData();
            });
    }

    setRowData(): void {
        const allQueries$ = this.queryService.getAllQueries();
        const formUpdate$ = this.querySearchBox.valueChanges.pipe(startWith(this.querySearchBox.value));

        this.rowData$ = combineLatest([allQueries$, formUpdate$]).pipe(
            withLatestFrom(this.userService.getCurrentUser()),
            tap(([_, user]) => {
                if (user.Features.includes(UserFeatureName.IsAdmin)) {
                    this.gridColumnApi?.setColumnVisible("IsEmailable", true);
                }
            }),
            map(([[lists, searchTerm]]) => lists.filter(l => this.showQuery(l, searchTerm)))
        );
    }

    showQuery(list: QueryDto, searchTerm: string): boolean {
        return objectSearchPredicate(list, searchTerm);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked($event: any) {
        this.openModalForm($event.data.Id);
    }

    addQuery() {
        this.openModalForm(0);
    }

    openModalForm(id: number): void {
        const initialState = {
            queryId: id
        };
        let modalRef = this.modalService.show(QueryFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-xl' });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }

    renderPermission(params: any): string {
        return params.data.ListGroupPermission === 'View' ? 'Group View'
            : params.data.ListGroupPermission === 'Edit' ? 'Group View - Edit'
                : 'Private';
    }

    renderGroup(params: any): string {
        const groupId = params.data.ListGroupId;
        return this.listGroups && this.listGroups[groupId] && this.listGroups[groupId].Name || '';
    }
}
