import * as moment from "moment";
import { AccountMonthlyCommission, MonthlyCommission } from "../Models/AccountMonthlyCommission";

export function annualizeAccountMonthlyCommissions(
    accountMonthlyCommissions: AccountMonthlyCommission[],
    currentYear: number = moment().year(),
    now: moment.Moment = moment(),
): AccountMonthlyCommission[] {
    let results: AccountMonthlyCommission[] = [];

    let daysInThisYear = now.isLeapYear() ? 366 : 365;
    let progressThroughCurrentYearRatio = now.dayOfYear() / daysInThisYear;

    let currentMonth = now.month() + 1;
    let currentQuarter = Math.ceil(4 * (currentMonth / 12));
    let wholeMonthsRemainingInCurrentYear = 12 - currentMonth;
    let progressThroughCurrentMonth = now.date() / now.daysInMonth();
    let remainingProgressInCurrentMonth = (1 - progressThroughCurrentMonth);

    accountMonthlyCommissions.forEach(ac => {
        let commissionCopy = {
            Name: ac.Name,
            AccountId: ac.AccountId,
            ParentName: ac.ParentName,
            ParentComdolId: ac.ParentComdolId,
            City: ac.City,
            ComdolId: ac.ComdolId,
            Country: ac.Country,
            PrimaryBroker: ac.PrimaryBroker,
            PrimaryTrader: ac.PrimaryTrader,
            MonthlyCommissions: ac.MonthlyCommissions.map(mc => {
                return {
                    Year: mc.Year,
                    Month: mc.Month,
                    Category: mc.Category,
                    Commission: mc.Commission,
                    Quarter: mc.Quarter,
                    ComdolId: mc.ComdolId,
                    Location: mc.Location,
                    DateType: mc.DateType
                } as MonthlyCommission;
            }),
            Tags: ac.Tags,
            State: ac.State,
            Status: ac.Status,
            Tier: ac.Tier,
            Region: ac.Region
        } as AccountMonthlyCommission;

        let currentYearCommissions = commissionCopy.MonthlyCommissions.filter(mc => mc.Year === currentYear);
        let existingCommissionForThisMonth = currentYearCommissions.find(c => c.Month === currentMonth);
        let currentYearCommissionAmounts = currentYearCommissions.map(c => c.Commission)
        let totalSoFar = currentYearCommissionAmounts.length > 0
            ? currentYearCommissionAmounts.reduce((a, b) => a + b)
            : 0;
        let projectedTotalForThisYear = totalSoFar / progressThroughCurrentYearRatio;
        let remainingCommissionAmount = projectedTotalForThisYear * (1 - progressThroughCurrentYearRatio);
        let projectedCommissionAmountPerMonth = Math.round(remainingCommissionAmount / (remainingProgressInCurrentMonth + wholeMonthsRemainingInCurrentYear));
        let projectedCommissionAmountRemainingThisMonth = Math.round(projectedCommissionAmountPerMonth * remainingProgressInCurrentMonth);

        if (currentYearCommissions.length > 0) {

            if (existingCommissionForThisMonth) {
                existingCommissionForThisMonth.Commission = projectedCommissionAmountRemainingThisMonth + existingCommissionForThisMonth.Commission;
            } else {
                commissionCopy.MonthlyCommissions.push({
                    Year: currentYear,
                    Month: currentMonth,
                    Category: currentYearCommissions[0].Category,
                    Commission: projectedCommissionAmountRemainingThisMonth,
                    Quarter: currentQuarter,
                    ComdolId: currentYearCommissions[0].ComdolId,
                    Location: currentYearCommissions[0].Location,
                    DateType: currentYearCommissions[0].DateType
                } as MonthlyCommission);
            }

            for (let i = currentMonth + 1; i <= 12; i++) {
                commissionCopy.MonthlyCommissions.push({
                    Year: currentYear,
                    Month: i,
                    Category: currentYearCommissions[0].Category,
                    Commission: projectedCommissionAmountPerMonth,
                    Quarter: Math.ceil(4 * (i / 12)),
                    ComdolId: currentYearCommissions[0].ComdolId,
                    Location: currentYearCommissions[0].Location,
                    DateType: currentYearCommissions[0].DateType
                } as MonthlyCommission);
            }
        }

        results.push(commissionCopy);
    });

    return results;
}