import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {map} from "rxjs/operators";
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import {BaseHttpService} from "./base-http.service";

@Injectable({
    providedIn: 'root',
})
export class ExchangeEmailService {

    constructor(private baseHttp: BaseHttpService) {
    }

    getMailFolders(): Observable<(MicrosoftGraph.MailFolder & { id: string })[]> {
        return this.baseHttp.getMSGraph<{ value: (MicrosoftGraph.MailFolder & { id: string })[] }>(
            "/me/mailFolders?$top=1000")
            .pipe(
                map(res => res.value)
            );
    }

    getMessagesWithoutBody(parentFolderId: string): Observable<any[]> {
        return this.baseHttp.getMSGraph<{ value: (any & { id: string })[] }>(
            `/me/mailFolders/${parentFolderId}/messages?$select=id,createdDateTime,subject,toRecipients,from&$top=200`
        )
            .pipe(
                map(res => res.value)
            );
    }

    getMessage(messageId: string): Observable<any> {
        return this.baseHttp.getMSGraph<any>(
            `/me/messages/${messageId}`
        );
    }

    getMessageAttachments(messageId: string): Observable<any[]> {
        return this.baseHttp.getMSGraph<{ value: (any & { id: string })[] }>(
            `/me/messages/${messageId}/attachments?$top=1000`
        )
            .pipe(
                map(res => res.value)
            );
    }
}

