import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {UntypedFormBuilder} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {Contact} from "../../../Models/Contact";
import {ContactService} from "../../Shared/Services/contact.service";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: "app-edit-contacts-modal",
    templateUrl: "./edit-contacts-modal.component.html"
})
export class EditContactsModalComponent extends BaseGridComponent<Contact> implements OnInit {

    contacts: Contact[];
    contactIds: number[];

    @Output()
    action = new EventEmitter<number[]>();

    columnDefs = [
        { field: 'LastName', headerName: 'Last', flex: 1, checkboxSelection: true },
        { field: 'FirstName', headerName: 'First', flex: 1 },
        { field: 'Email', headerName: 'Email', flex: 1 },
        { field: 'AccountName', headerName: 'Account', flex: 1 },
    ];

    form = this.fb.group({
        search: this.fb.control(''),
        contacts: this.fb.control([]),
    });

    constructor(public bsConfirmModalRef: BsModalRef,
                private contactService: ContactService,
                private toastrService: ToastrService,
                private fb: UntypedFormBuilder) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.rowSelection = 'multiple';
        this.gridOptions.rowMultiSelectWithClick = true;

        this.contactService.getContactsByIds(this.contactIds).subscribe(
            contacts => {
                this.form.get("contacts").patchValue(contacts)
            }
        );

        this.rowData$ = this.form.valueChanges.pipe(
            startWith(this.form.getRawValue()),
            map((formValues) => {
                return formValues.search ? formValues.contacts.filter(c => c.LastName.toLowerCase().includes(formValues.search.toLowerCase())
                    || c.FirstName.toLowerCase().includes(formValues.search.toLowerCase())
                    || c.Email.toLowerCase().includes(formValues.search.toLowerCase())
                    || c.AccountName.toLowerCase().includes(formValues.search.toLowerCase())) : formValues.contacts
            })
        )

        // this.rowData$ = this.contactService.getContactsByIds(this.contactIds).pipe(
        //     switchMap(contacts => combineLatest([
        //         this.contactSearchControl.valueChanges.pipe(
        //             startWith("")
        //         ),
        //         of(contacts)
        //     ])),
        //     map(([searchTerm,contacts]) => {
        //         return searchTerm ? contacts.filter(c => c.LastName.toLowerCase().includes(searchTerm.toLowerCase())
        //             || c.FirstName.toLowerCase().includes(searchTerm.toLowerCase())
        //             || c.Email.toLowerCase().includes(searchTerm.toLowerCase())
        //             || c.AccountName.toLowerCase().includes(searchTerm.toLowerCase())) : contacts
        //     })
        // );
    }

    save(): void {
        this.bsConfirmModalRef.hide();
        this.action.emit(this.form.get("contacts").value.map(
            c => c.Id
        ));
    }

    cancel(): void {
        this.bsConfirmModalRef.hide();
    }

    removeSelected(): void {
        const removedIds = this.gridApi.getSelectedRows().map(r => r.Id);
        let contacts = this.form.get("contacts").value.filter(
            c => !removedIds.includes(c.Id)
        )
        this.form.get("contacts").patchValue(contacts);
    }
}
