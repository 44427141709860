import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidatorFn,} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventMeetingAttendeeDto, EventService, UpdateEventAttendanceDto} from "../../../Shared/Services/event.service";
import {combineLatest, of} from "rxjs";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {EventMeeting} from "../../../../Components/Events/CalendarEvent";
import * as moment from "moment";
import {Activity} from "../../../../Models/Activity";
import {ContactService} from "../../../Shared/Services/contact.service";
import {ConfirmModalComponent} from "../../../Widget/ConfirmModal/confirm-modal.component";
import {ActivityStatus} from "../../../../Models/ActivityStatus";

@Component({
    selector: "app-meeting-attendee-form",
    templateUrl: "./meeting-attendee-form.component.html",
    styleUrls: ["./meeting-attendee-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class MeetingAttendeeFormComponent implements OnInit {

    @Input()
    eventMeetingId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    attendanceForm: UntypedFormGroup = this.fb.group({
        contactIdsForNewAttendees: this.fb.control([]),
        contactIdsForNewReplays: this.fb.control([]),
        activityStatusForNewAttendee: this.fb.control(''),
        replayDateForNewAttendee: this.fb.control(''),
        meetingAttendance: this.fb.array([]),
        replayAttendance: this.fb.array([]),
    }, {
        validators: [
            this.requiredNewAttendeeFieldsFormValidatorFn(),
            this.requiredNewReplayFieldsFormValidatorFn(),
        ]
    });

    formLoading: boolean = false;
    formSaving: boolean = false;
    saveMessage: string = '';
    saveMessageReplay: string = '';
    subCategory: string = '';
    activities: Activity[];
    eventMeeting: EventMeeting;
    title: string;

    private replayStatus = "Replay";

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private eventService: EventService,
        private contactService: ContactService,
        private activityService: ActivityService,
        private toastrService: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.title = "Edit Meeting Attendance";

        this.formLoading = true;
        let eventMeeting$ = this.eventService.getEventMeetingById(this.eventMeetingId);
        let activities$ = this.activityService.getActivitiesByEventMeetingId(this.eventMeetingId);



        combineLatest([eventMeeting$, activities$])
            .subscribe(([eventMeeting, activities]) => {



                this.eventMeeting = eventMeeting;
                this.activities = activities.sort((a, b) => a.ContactLastName.localeCompare(b.ContactLastName));

                this.eventService.getEvent(eventMeeting.EventId).subscribe(event => {
                    this.subCategory = event.SubCategory

                    let meetingAttendance = this.attendanceForm.get('meetingAttendance') as UntypedFormArray;
                    let replayAttendance = this.attendanceForm.get('replayAttendance') as UntypedFormArray;

                    for (let i = 0; i < this.activities.length; i++) {
                        let activity = this.activities[i];

                        let group = this.fb.group({
                            activityId: this.fb.control(activity?.Id),
                            contactId: this.fb.control(activity.ContactId),
                            contactName: this.fb.control(`${activity.ContactLastName}, ${activity.ContactFirstName}`),
                            accountName: this.fb.control(activity.AccountName),
                            subCategory: this.fb.control((activity && activity.SubCategory) ?? event.SubCategory),
                            initialActivityStatus: this.fb.control((activity && activity.Status) ?? ""),
                            activityStatus: this.fb.control((activity && activity.Status) ?? ""),
                            initialReplayDate: this.fb.control(moment(activity.CallDate).format("yyyy-MM-DD")),
                            replayDate: this.fb.control(moment(activity.CallDate).format("yyyy-MM-DD")),
                        });

                        if (activity?.Status == "Replay") {
                            replayAttendance.push(group);
                        }
                        else {
                            meetingAttendance.push(group);
                        }
                    }

                    this.formLoading = false;
                });


            });

    }

    clearAttendance(attendanceGroup: AbstractControl) {
        let meetingAttendance = attendanceGroup.parent as UntypedFormArray;
        let contactId = attendanceGroup.get("contactId").value as number;

        meetingAttendance.controls = meetingAttendance.controls.filter(x => x.get("contactId").value !== contactId);
    }

    clearNewAttendeeFormControls() {
        this.attendanceForm.patchValue({
            contactIdsForNewAttendees: [],
            contactIdsForNewReplays: [],
            activityStatusForNewAttendee: "",
            replayDateForNewAttendee: "",
        });
    }

    clearNewReplayFormControls() {
        this.attendanceForm.patchValue({
            contactIdsForNewReplays: [],
            replayDateForNewAttendee: "",
        });
    }

    addNewAttendeeRows() {
        this.saveMessage = '';

        let meetingAttendance = this.attendanceForm.get('meetingAttendance') as UntypedFormArray;

        let activityStatusForNewAttendee = this.attendanceForm.get('activityStatusForNewAttendee').value as string;
        let contactIdsForNewAttendees = this.attendanceForm.get('contactIdsForNewAttendees').value as number[];

        let contacts$ = this.contactService.getContactsByIds(contactIdsForNewAttendees);

        let [ addedCount, updatedCount ] = [0,0];
        contacts$.subscribe((contacts) => {

            for (let i = 0; i < contactIdsForNewAttendees.length; i++) {
                let contactId = contactIdsForNewAttendees[i];
                let contact = contacts.find(c => c.Id === contactId);

                let group = meetingAttendance.controls
                    .find(c => c.value.contactId === contactId &&
                               c.value.activityStatus !== "Replay");

                if (!group) {
                    group = this.fb.group({
                        activityId: null,
                        contactId: this.fb.control(contactId),
                        contactName: this.fb.control(`${contact.LastName}, ${contact.FirstName}`),
                        accountName: this.fb.control(contact.Account.Name),
                        subCategory: this.fb.control(this.subCategory),
                        initialActivityStatus: this.fb.control(""),
                        activityStatus: this.fb.control(activityStatusForNewAttendee),
                    });

                    meetingAttendance.push(group);
                    addedCount++;
                } else {
                    group.patchValue({
                        activityStatus: activityStatusForNewAttendee
                    });
                    updatedCount++;
                }
            }

            if (addedCount > 0) {
                this.saveMessage += `${addedCount} Attendance Added. `
            }
            if (updatedCount > 0) {
                this.saveMessage += `${updatedCount} Attendance Updated. `
            }

            this.clearNewAttendeeFormControls();
        });
    }

    addNewReplayAttendeeRows() {
        this.saveMessageReplay = '';

        let replayAttendance = this.attendanceForm.get('replayAttendance') as UntypedFormArray;

        let replayDate = this.attendanceForm.get('replayDateForNewAttendee').value;
        let contactIdsForNewReplays = this.attendanceForm.get('contactIdsForNewReplays').value as number[];

        let contacts$ = this.contactService.getContactsByIds(contactIdsForNewReplays);

        let [ addedCount, updatedCount ] = [0,0];
        contacts$.subscribe((contacts) => {

            for (let i = 0; i < contactIdsForNewReplays.length; i++) {
                let contactId = contactIdsForNewReplays[i];
                let contact = contacts.find(c => c.Id === contactId);

                let group = replayAttendance.controls
                    .find(c => c.value.contactId === contactId &&
                        c.value.activityStatus === this.replayStatus);

                if (!group) {
                    group = this.fb.group({
                        activityId: null,
                        contactId: this.fb.control(contactId),
                        contactName: this.fb.control(`${contact.LastName}, ${contact.FirstName}`),
                        accountName: this.fb.control(contact.Account.Name),
                        activityStatus: this.fb.control(this.replayStatus),
                        initialReplayDate: this.fb.control(""),
                        replayDate: this.fb.control(replayDate),
                    });

                    replayAttendance.push(group);
                    addedCount++;
                } else {
                    group.patchValue({
                        replayDate: replayDate
                    });
                    updatedCount++;
                }
            }

            if (addedCount > 0) {
                this.saveMessageReplay += `${addedCount} Replay(s) Added. `
            }
            if (updatedCount > 0) {
                this.saveMessageReplay += `${updatedCount} Replay(s) Updated. `
            }

            this.clearNewReplayFormControls();
        });
    }

    hasUnaddedContacts(): boolean {
        let contactIdsForNewAttendees = this.attendanceForm.get('contactIdsForNewAttendees').value as number[];
        let contactIdsForNewReplays = this.attendanceForm.get('contactIdsForNewReplays').value as number[];

        return contactIdsForNewAttendees.length > 0 || contactIdsForNewReplays.length > 0;
    }

    save() {
        this.formSaving = true;

        let meetingAttendances: EventMeetingAttendeeDto[] = (this.attendanceForm.get('meetingAttendance') as UntypedFormArray).controls.map(control => {
            return {
                ActivityId: control.value.activityId,
                EventMeetingId: this.eventMeetingId,
                ContactId: control.value.contactId,
                ActivityStatus: control.value.activityStatus,
                SubCategory: control.value.subCategory,
                CallDate: null,
            };
        });

        let replayAttendances: EventMeetingAttendeeDto[] = (this.attendanceForm.get('replayAttendance') as UntypedFormArray).controls.map(control => {
            return {
                ActivityId: control.value.activityId,
                EventMeetingId: this.eventMeetingId,
                ContactId: control.value.contactId,
                ActivityStatus: control.value.activityStatus,
                SubCategory: '',
                CallDate: control.value.replayDate,
            };
        });

        let adds = this.getAdds(meetingAttendances, replayAttendances);

        let removes = this.getRemoves([...meetingAttendances, ...replayAttendances]);

        let updates = this.getUpdates(meetingAttendances, replayAttendances);

        let warningMessages = [];

        if (this.hasUnaddedContacts()) {
            warningMessages.push(`You haven't yet clicked the add button for one or more contacts in the contact search.`);
        }

        if (removes.length > 0 && !this.hasAttendance(meetingAttendances) && !this.hasAttendance(replayAttendances)) {
            warningMessages.push('You are about to delete all attendance from this event meeting.');
        }

        if (warningMessages.length > 0) {
            const confirmModalInitialState = {
                messages: [...warningMessages, `Do you still want to save?`]
            };

            let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
                initialState: confirmModalInitialState,
                animated: false,
                keyboard: false,
                backdrop: 'static'
            });

            confirmModalRef.content.action.subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.saveAttendance(adds, updates, removes);
                }
            });

            confirmModalRef.content.declineAction.subscribe(isDeclined => {
                this.formSaving = false;
            });
        } else {
            this.saveAttendance(adds, updates, removes);
        }
    }

    closeFormWithMessage(message: string): void {
        this.dataUpdated.emit(true);
        this.toastrService.success(message);
        this.modalRef.hide();
    }

    close() {
        this.modalRef.hide();
    }

    requiredNewAttendeeFieldsFormValidatorFn(): ValidatorFn {

        return (form: UntypedFormGroup) => {
            let activityStatusForNewAttendee = form.get('activityStatusForNewAttendee').value as string;
            let contactIdsForNewAttendees = form.get('contactIdsForNewAttendees').value as number[];

            if (contactIdsForNewAttendees.length === 0 || !activityStatusForNewAttendee) {
                return { requiredNewAttendeeFields: true }
            }

            return null;
        }
    }

    requiredNewReplayFieldsFormValidatorFn(): ValidatorFn {

        return (form: UntypedFormGroup) => {
            let contactIdsForNewReplays = form.get('contactIdsForNewReplays').value as number[];
            let replayDateForNewAttendee = form.get('replayDateForNewAttendee').value as string;

            if (contactIdsForNewReplays.length === 0 || replayDateForNewAttendee === "") {
                return { requiredNewReplayFields: true }
            }

            return null;
        }
    }

    hasAttendance(attendance: EventMeetingAttendeeDto[]) {
        return attendance.length > 0 &&
            (attendance.some(m => m?.ActivityStatus && m.ActivityStatus !== "") ||
             attendance.some(r => r?.CallDate && r.CallDate !== ""));
    }

    onAddAllClick(statusName) {
        let allContactIds = (this.attendanceForm.get('meetingAttendance') as UntypedFormArray).controls
            .filter(control => control.value.activityStatus !== 'Replay')
            .filter(control => control.value.activityStatus === statusName || statusName === '')
            .map(control => control.value.contactId)
            .filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        let existingContactIds = this.attendanceForm.get('contactIdsForNewAttendees').value;
        let updatedContactIds = existingContactIds.concat(allContactIds)
            .filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        this.attendanceForm.patchValue({
            contactIdsForNewAttendees: updatedContactIds
        });
    }

    private saveAttendance(adds: EventMeetingAttendeeDto[], updates: EventMeetingAttendeeDto[], removes: number[]) {
        let updateDto: UpdateEventAttendanceDto = {
            UpdateActivityAccountContactIds: [],
            Adds: adds,
            Updates: updates,
            Removes: removes
        };

        let save$ = (updateDto.Adds.length > 0 || updateDto.Updates.length > 0 || updateDto.Removes.length > 0) ?
            this.eventService.updateEventAttendees(this.eventMeeting.EventId, updateDto) :
            of(null);

        save$.subscribe(_ => {
            this.formSaving = false;
            this.closeFormWithMessage("Attendance Updated");
        });
    }

    private getAdds(meetingAttendances: EventMeetingAttendeeDto[], replayAttendances: EventMeetingAttendeeDto[]) {
        let meetingAttendancesAdds = meetingAttendances.filter(ma => !ma.ActivityId && ma.ActivityStatus !== "");
        let replayAdds = replayAttendances.filter(ra => !ra.ActivityId && (ra.CallDate ?? "") !== "");

        return [...meetingAttendancesAdds, ...replayAdds];
    }

    private getRemoves(attendances: EventMeetingAttendeeDto[]): number[] {
        return this.activities
            .filter(a => !attendances.find(ma => ma.ActivityId === a.Id))
            .map(a => a.Id);
    }

    private getUpdates(meetingAttendances: EventMeetingAttendeeDto[], replayAttendances: EventMeetingAttendeeDto[]): EventMeetingAttendeeDto[] {
        let meetingUpdates = meetingAttendances
            .filter(ma => this.activities.find(a => a.Id === ma.ActivityId && (a.Status !== ma.ActivityStatus || a.SubCategory !== ma.SubCategory)));

        let replayUpdates = replayAttendances
            .filter(ra => this.activities.find(a => a.Id == ra.ActivityId
                && moment(a.CallDate).format('LL') !== moment(ra.CallDate).format('LL')))

        return [...meetingUpdates, ...replayUpdates];
    }
}
