import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";

@Directive({
    selector: '[appIfUserFeature]'
})
export class IfUserFeatureDirective implements OnInit {

    @Input() appIfUserFeature: string;
    
    constructor(private elementRef: ElementRef, private userService: UserService) { }

    ngOnInit(): void {
        this.userService.getCurrentUser()
            .subscribe(user => {
                let userHasFeature = this.userService.hasUserFeature(user, this.appIfUserFeature);

                if (!userHasFeature) {
                    this.elementRef.nativeElement.style.display = 'none';
                } else {
                    this.elementRef.nativeElement.style.display = '';
                }
            });
    }
}

@Directive({
    selector: '[appIfNotUserFeature]'
})
export class IfNotUserFeatureDirective implements OnInit {

    @Input() appIfNotUserFeature: string;

    constructor(private elementRef: ElementRef, private userService: UserService) { }

    ngOnInit(): void {
        this.userService.getCurrentUser()
            .subscribe(user => {
                let userHasFeature = this.userService.hasUserFeature(user, this.appIfNotUserFeature);

                if (userHasFeature) {
                    this.elementRef.nativeElement.style.display = 'none';
                } else {
                    this.elementRef.nativeElement.style.display = '';
                }
            });
    }
}