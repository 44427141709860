import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {AbstractControl, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../Shared/Services/user.service";
import {combineLatest, Observable, of} from "rxjs";
import {UserFeaturesService} from "../UserFeatures/user-features.service";
import * as _ from "lodash";
import {User} from "../../../Models/User";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {UserRenameComponent} from "../UserRename/user-rename.component";
import {catchError, map, startWith} from "rxjs/operators";
import {UserAdminService} from "./user-admin.service";
import {UserBacksup} from "../../Shared/Models/user-backsup";
import {UserTeam} from "../../../Models/UserTeam";
import {CorpTravelUser, CorpTravelUserService} from "./corp-travel-user.service";

@Component({
    selector: "app-user-form",
    templateUrl: "./user-form.component.html",
    styleUrls: ["./user-form.scss"],
    encapsulation: ViewEncapsulation.None
})
export class UserFormComponent implements OnInit{

    userId: string;

    isMyUserId: Boolean = true;
    title: string;

    userForm: UntypedFormGroup = this.fb.group({
        Id: this.fb.control('', [
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.required
        ]),
        FirstName: this.fb.control('', [Validators.maxLength(50)]),
        LastName: this.fb.control('', [Validators.maxLength(50)]),
        Email: this.fb.control('', [Validators.maxLength(100)]),
        Domain: this.fb.control(''),
        NetworkId: this.fb.control('', [Validators.maxLength(35)]),
        Title: this.fb.control('', [Validators.maxLength(100)]),
        Phone: this.fb.control('', [Validators.maxLength(20)]),
        PhoneExt: this.fb.control('', [Validators.maxLength(4)]),
        MobilePhone: this.fb.control('', [Validators.maxLength(20)]),
        Notes: this.fb.control('', [Validators.maxLength(2000)]),
        BetaRepCode: this.fb.control(null, [Validators.maxLength(8)]),

        IsActive: this.fb.control(''),
        IsTrader: this.fb.control(''),
        IsBroker: this.fb.control(''),
        IsDefaultAnalyst: this.fb.control(''),
        IsEmailFromAnalyst: this.fb.control(''),

        TeamId: this.fb.control(null),
        UserGroupId: this.fb.control(null),
        ComdolRole: this.fb.control(''),
        ComdolGroupId: this.fb.control(null),
        Coverage: this.fb.control(''),

        TeamUserIds: this.fb.control([]),
        SendAsIds: this.fb.control([]),
        SendOnBehalfOfIds: this.fb.control([]),
        UserBackups: this.fb.control([]),

        UserFeatures: this.fb.control([]),

        FidessaIds: this.fb.control([]),

        IsCorpTravelUser: this.fb.control(false),
        CorpTravelForm: this.fb.group({
            CorpRoleIds: this.fb.control([]),
            CorpTypeId: this.fb.control('', [Validators.maxLength(2), Validators.required]),
            CorpTerritoryIds: this.fb.control([]),
            CorpMeetingCoordinator: this.fb.control(false),

            CorpEmailSignature: this.fb.control('', [Validators.maxLength(500)]),
        }),
    });

    get corpTravelForm(): any {
        return this.userForm.get('CorpTravelForm');
    }

    teams$: Observable<any[]>;
    groups$: Observable<any[]>;
    comdolRoles$: Observable<string[]>;
    userFeatures$: Observable<string[]>;

    @Output()
    dataUpdated = new EventEmitter<boolean>();


    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private userService: UserService,
                private userAdminService: UserAdminService,
                private userFeatureService: UserFeaturesService,
                private corpTravelUserService: CorpTravelUserService,
                private toastr: ToastrService
                ) {}

    ngOnInit(): void {
        this.teams$ = this.userService.getUserTeams().pipe(
            map(teams => _.sortBy(teams, ["RoleName", "TeamName"])),
            map(teams => {
                teams.unshift({RoleName: "", TeamName: "", TeamId: null, IsActive: true})
                return teams;
            })
        );

        this.userForm.get('IsCorpTravelUser').valueChanges
            .pipe(startWith(this.userForm.get('IsCorpTravelUser').value))
            .subscribe(isCorpTravelUser => {
                if (isCorpTravelUser) {
                    this.corpTravelForm.enable();
                }
                else {
                    this.corpTravelForm.disable();
                }
            });

        this.groups$ = this.userService.getUserGroups().pipe(
            map(groups => _.sortBy(groups, g => g.GroupName)),
            map(groups => {
                groups.unshift({GroupName: "", GroupId: null})
                return groups;
            })
        );

        this.comdolRoles$ = of(["", "Admin", "Coordinator", "Management", "Regional Director"]);

        this.userFeatures$ = this.userFeatureService.getUserFeatures();

        if (this.userId) {
            this.loadUser(this.userId);
        } else {
            this.userForm.patchValue({
                Domain: 'US',
                UserGroupId: null,
                ComdolGroupId: null,
                IsActive: true,
                IsBroker: false,
                IsTrader: false,
                IsDefaultAnalyst: false,
                IsEmailFromAnalyst: false,
                CorpTravelForm: {},
            });

            this.title = 'New User';

            this.userForm.get('Id').setAsyncValidators(this.existingUserIdValidatorFn(this.userService, this.corpTravelUserService));
        }
    }

    loadUser(userId: string) {
        const currentUser$ = this.userService.getCurrentUser();
        const editUser$ = this.userService.getUserById(userId, true);
        const corpTravelUser$ = this.corpTravelUserService.getCorpTravelUser(this.userId)
            .pipe(catchError(() => of(null)));

        combineLatest([currentUser$, editUser$])
            .subscribe(([currentUser, user]) => {
                this.userForm.patchValue({
                    Id: user.Id,
                    FirstName: user.FirstName,
                    LastName: user.LastName,
                    Email: user.Email,
                    Domain: user.Domain,
                    NetworkId: user.WindowsId,
                    Title: user.Title,
                    Phone: user.Phone,
                    PhoneExt: user.PhoneExtension,
                    MobilePhone: user.MobilePhone,
                    BetaRepCode: user.BetaRepCode,

                    IsActive: user.IsActive,
                    IsBroker: user.Broker,
                    IsTrader: user.Trader,
                    IsDefaultAnalyst: user.DefaultAnalyst,
                    IsEmailFromAnalyst: user.EmailFromAnalyst,

                    TeamId: user.Team ? user.Team.Id : null,
                    UserGroupId: user.UserGroupId,
                    ComdolRole: user.ComdolRole,
                    ComdolGroupId: user.ComdolGroupId,
                    Coverage: user.Coverage,

                    Notes: user.Notes,

                    UserFeatures: user.Features,

                    FidessaIds: user.FidessaIds,
                });

                corpTravelUser$
                    .subscribe(corpTravelUser => {
                        if (corpTravelUser) {
                            if (corpTravelUser.NetworkId !== "INACTIVE")
                                this.userForm.get('IsCorpTravelUser').patchValue(true);
                            this.corpTravelForm.patchValue({
                                CorpRoleIds: corpTravelUser?.RoleIds,
                                CorpTypeId: corpTravelUser?.TypeId,
                                CorpTerritoryIds: corpTravelUser?.TerritoryIds,
                                CorpMeetingCoordinator: corpTravelUser?.MeetingCoordinator,

                                CorpEmailSignature: corpTravelUser?.EmailSignature,
                            });
                        }
                    });

                this.userForm.get('Id').disable();

                this.isMyUserId = currentUser.Id === user.Id;

                if (this.isMyUserId) {
                    this.userForm.get('NetworkId').disable();
                    this.userForm.get('IsActive').disable();
                }

                this.title = `Edit User: ${user.FirstName} ${user.LastName} (${user.Id})`;
            });

        this.userService.getMyTeam(userId)
            .subscribe(teamUsers => {
                this.userForm.patchValue({
                    TeamUserIds: _.map(teamUsers, t => t.Id)
                });
            });

        this.userService.getSenderPermissions(userId)
            .subscribe((sendAsUsers: User[]) => {
                this.userForm.patchValue({
                    SendAsIds: _.map(sendAsUsers, u => u.Id)
                });
            });

        this.userService.getSendOnBehalfOfPermissions(userId)
            .subscribe((sendOnBehalfOfUsers: User[]) => {
                this.userForm.patchValue({
                    SendOnBehalfOfIds: sendOnBehalfOfUsers.map(u => u.Id)
                });
            });

        this.userService.getUserBackups(userId)
            .subscribe((userBackups: UserBacksup[]) => {
                this.userForm.patchValue({
                    UserBackups: _.map(userBackups, u => u.BacksupUserId)
                });
            });
    }

    formatTeamName(team: UserTeam): string {
        let teamName = this.userService.formatTeamName(team.RoleName, team.TeamName);
        if (!team.IsActive) {
            teamName += ` (Inactive)`;
        }
        return teamName;
    }

    submit() {
        if (this.userForm.valid) {
            let user: User = {
                Id: this.userForm.get('Id').value,

                FirstName: this.userForm.get('FirstName').value,
                LastName: this.userForm.get('LastName').value,
                Email: this.userForm.get('Email').value,

                Domain: this.userForm.get('Domain').value,
                WindowsId: this.userForm.get('NetworkId').value,
                Title: this.userForm.get('Title').value,

                Phone: this.userForm.get('Phone').value,
                PhoneExtension: this.userForm.get('PhoneExt').value,
                MobilePhone: this.userForm.get('MobilePhone').value,

                BetaRepCode: this.userForm.get('BetaRepCode').value || null,

                Team: {
                    Id: this.userForm.get('TeamId').value,
                    Name: '',
                },
                UserGroupId: this.userForm.get('UserGroupId').value,
                ComdolRole: this.userForm.get('ComdolRole').value,
                ComdolGroupId: this.userForm.get('ComdolGroupId').value,

                Coverage: this.userForm.get('Coverage').value,
                Trader: this.userForm.get('IsTrader').value,
                Broker: this.userForm.get('IsBroker').value,
                DefaultAnalyst: this.userForm.get('IsDefaultAnalyst').value,
                EmailFromAnalyst: this.userForm.get('IsEmailFromAnalyst').value,

                IsActive: this.userForm.get('IsActive').value,

                Notes: this.userForm.get('Notes').value,

                FidessaIds: this.userForm.get('FidessaIds').value,

                Features: [],
                Role: null,
            } as User;

            let userUpdate$ = this.userId ?
                this.userAdminService.updateUser(user) :
                this.userAdminService.createUser(user);

            userUpdate$.subscribe(updatedUser => {

                let updateMyTeam$ = this.userAdminService.updateMyTeam(updatedUser.Id, this.userForm.get('TeamUserIds').value);
                let updateSenderPermission$ = this.userAdminService.updateSenderPermissions(updatedUser.Id, this.userForm.get('SendAsIds').value);
                let updateSendOnBehalfOfPermission$ = this.userAdminService.updateSendOnBehalfOfPermissions(updatedUser.Id, this.userForm.get('SendOnBehalfOfIds').value);
                let updateUserFeatures$ = this.userAdminService.updateUserFeatures(updatedUser.Id, this.userForm.get('UserFeatures').value);
                let updateBackups$ = this.userService.updateUserBackups(updatedUser.Id, this.userForm.get('UserBackups').value);

                let corpTravelUserUpdate$: Observable<any>;
                if (this.userForm.get('IsCorpTravelUser').value) {
                    var instiselUser = this.userForm.getRawValue();
                    let corpTravelUser: CorpTravelUser = {
                        Id: instiselUser.Id,
                        FirstName: instiselUser.FirstName,
                        LastName: instiselUser.LastName,
                        Email: instiselUser.Email,

                        Title: instiselUser.Title,
                        NetworkId: instiselUser.NetworkId,
                        Mobile: instiselUser.MobilePhone,
                        WorkPhone: instiselUser.Phone,

                        RoleIds: this.corpTravelForm.get('CorpRoleIds').value,
                        TypeId: this.corpTravelForm.get('CorpTypeId').value,
                        TerritoryIds: this.corpTravelForm.get('CorpTerritoryIds').value,
                        MeetingCoordinator: this.corpTravelForm.get('CorpMeetingCoordinator').value,

                        EmailSignature: this.corpTravelForm.get('CorpEmailSignature').value,
                    };
                    corpTravelUserUpdate$ = this.corpTravelUserService.saveCorpTravelUser(corpTravelUser);
                }
                else {
                    corpTravelUserUpdate$ = this.corpTravelUserService
                        .deactivateCorpTravelUser(this.userForm.get('Id').value)
                }

                combineLatest([updateMyTeam$, updateSenderPermission$, updateSendOnBehalfOfPermission$, updateUserFeatures$, updateBackups$, corpTravelUserUpdate$])
                    .subscribe(([res1, res2, res3, res4, res5, corpTravelUpdate]) => {
                        this.toastr.success(`Saved User`);
                        if (this.isMyUserId) {
                            this.userService.updateCurrentUser();
                        }
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            });
        }
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        const initialState = {
            message: `Are you sure you want to delete the User "${this.userId}"`,
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.userAdminService.deleteUser(this.userId)
                        .subscribe(() => {
                            this.corpTravelUserService.deleteCorpTravelUser(this.userForm.get('Id').value)
                                .subscribe(() => {
                                    this.toastr.success("User Deleted");
                                    this.dataUpdated.emit(true);
                                    this.modalRef.hide();
                                });
                        });
                }
            });
    }

    existingUserIdValidatorFn(userService: UserService, corpTravelUserService: CorpTravelUserService): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            let user$ = userService.getUserById(control.value, true);
            let corpTravelUser$ = corpTravelUserService.getCorpTravelUser(control.value);
            return combineLatest([user$, corpTravelUser$]).pipe(
                catchError(() => of([null, null])),
                map(([user, corpTravelUser]) => (user || corpTravelUser) ? {"userIdExists": true} : null)
            );
        }
    }

    userFeatureClick(userFeature: string) {
        let userFeatures = this.userForm.get('UserFeatures').value;
        if (_.some(userFeatures, uf => uf === userFeature)) {
            userFeatures = _.filter(userFeatures, uf => uf !== userFeature)
        } else {
            userFeatures.push(userFeature);
        }

        this.userForm.patchValue({
            UserFeatures: userFeatures
        });
    }

    renameUserId() {
        const initialState = {
            title: `Rename User ID: ${this.userId}`,
            userId: this.userId
        };
        let renameModalRef = this.modalService.show(UserRenameComponent, { initialState: initialState, ignoreBackdropClick: true, keyboard: false });

        renameModalRef.content.dataUpdated.subscribe(updated => {
            this.dataUpdated.emit(true);
            this.modalRef.hide();
        });
    }

    addTag(tag: string) {
        return tag;
    }
}
