<div class="modal-content attendee-form">
    <div class="modal-header">
        <h3 class="modal-title">
            {{userIsEventAdmin() ? (isAdd ? "Add Attendance to this Event" : "Edit Attendance for " + getContactText(contact)) : "View Attendance for " + getContactText(contact)}}
        </h3>
    </div>
    <form [formGroup]="attendeeForm">
        <div appIfUserFeature="EventAdmin" class="modal-header">
            <div *ngIf="isAdd" class="form-group">
                <app-multi-contact-select
                        formControlName="contactIds"
                        [showEditButtons]="false"
                        [contactIdsToExclude]="alreadyAttendedContactIds"
                >
                </app-multi-contact-select>
            </div>

            <div class="form-group flex-hbox flex-gap">
                <div class="flex-4">
                    <label>Notes</label>

                    <textarea
                            class="form-control input-sm"
                            formControlName="attendeeNotes"
                    >
                    </textarea>
                </div>
                <div class="flex">
                    <label>Confirmed</label>
                    <app-yes-no-any-picker
                            formControlName="confirmed"
                            [multiple]="false"
                            [showSelectClearAll]="false"
                            [showTagCountLabel]="false"
                            [closeOnSelect]="true"
                    >
                    </app-yes-no-any-picker>
                </div>
            </div>

            <div *ngIf="contactHasActivitiesForOldAccount" class="form-group flex-gap">
                <div class="flex flex-hbox" style="align-items: center; margin-top: 5px">
                    <label style="margin: 0 5px 0 0">
                        Set to Current Account
                    </label>

                    <input
                            type="checkbox"
                            class="form-control"
                            formControlName="updateActivityAccountToCurrentAccount"
                            style="width: 20px; height: 20px; margin: 0"
                    >
                </div>
            </div>
        </div>
        <div class="modal-body">
            <tabset>
                <tab heading="Attendance">
                    <div class="flex-hbox attendance-header">
                        <div class="flex">
                            Date
                        </div>
                        <div class="flex-2">
                            Time {{timeZoneForEvent}}
                        </div>
                        <div class="flex-2">
                            Meeting
                        </div>
                        <div class="flex-1">
                            Status
                        </div>
                        <div class="flex-1">
                            Sub Category
                        </div>
                        <div
                                class="flex"
                                appIfUserFeature="EventAdmin"
                        >
                        </div>
                    </div>

                    <div class="form-group flex-hbox attendance-row"
                         *ngFor="let meetingAttendanceGroup of attendeeForm.get('meetingAttendance')['controls']"
                         [formGroup]="meetingAttendanceGroup">
                        <div class="flex">
                            {{meetingAttendanceGroup.get("date").value}}
                        </div>
                        <div class="flex-2">
                            {{meetingAttendanceGroup.get("timeRange").value}}
                        </div>
                        <div class="flex-2">
                            {{meetingAttendanceGroup.get("name").value}}
                        </div>
                        <div class="flex-1">
                            <app-activity-status-picker
                                *ngIf="userIsEventAdmin() && !meetingAttendanceGroup.get('hasWebcastId').value"
                                formControlName="activityStatus"
                                [selectClearAll]="false"
                                [multiple]="false"
                                [showTagCountLabel]="false"
                                [closeOnSelect]="true"
                                [useNameAsId]="true"
                                [excludeReplay]="true"
                            >
                            </app-activity-status-picker>

                            <div
                                *ngIf="!userIsEventAdmin() || meetingAttendanceGroup.get('hasWebcastId').value"
                            >
                                {{meetingAttendanceGroup.get("activityStatus").value}}
                            </div>
                        </div>

                        <div class="flex-1">
                            <app-activity-sub-category-picker
                                    *ngIf="userIsEventAdmin() && !meetingAttendanceGroup.get('hasWebcastId').value"
                                    formControlName = "subCategory"
                                    [multiple]="false"
                                    [showSelectClearAll]="false"
                                    [closeOnSelect]="true"
                                    [appendTo]="'body'"
                            >
                            </app-activity-sub-category-picker>

                            <div
                                    *ngIf="!userIsEventAdmin() || meetingAttendanceGroup.get('hasWebcastId').value"
                            >
                                {{meetingAttendanceGroup.get("subCategory").value}}
                            </div>
                        </div>

                        <div class="flex" appIfUserFeature="EventAdmin">
                            <button
                                type="button"
                                class="btn btn-danger btn-sm flex"
                                [disabled]="meetingAttendanceGroup.get('hasWebcastId').value"
                                (click)="clearAttendance(meetingAttendanceGroup)"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </tab>
                <tab heading="Replays">
                    <div class="flex-hbox attendance-header">
                        <div class="flex">
                            Date
                        </div>
                        <div class="flex-2">
                            Time {{timeZoneForEvent}}
                        </div>
                        <div class="flex-3">
                            Meeting
                        </div>
                        <div class="flex-2">
                            Replay Date
                        </div>
                        <div
                                class="flex"
                                appIfUserFeature="EventAdmin"
                        >
                        </div>
                    </div>

                    <div class="form-group flex-hbox attendance-row"
                         *ngFor="let replayAttendanceGroup of attendeeForm.get('replayAttendance')['controls']"
                         [formGroup]="replayAttendanceGroup">
                        <div class="flex">
                            {{replayAttendanceGroup.get("date").value}}
                        </div>
                        <div class="flex-2">
                            {{replayAttendanceGroup.get("timeRange").value}}
                        </div>
                        <div class="flex-3">
                            {{replayAttendanceGroup.get("name").value}}
                        </div>
                        <div class="flex-2">
                            <input
                                    type="date"
                                    class="form-control input-sm"
                                    formControlName="replayDate"
                                    *ngIf="userIsEventAdmin() && !replayAttendanceGroup.get('hasWebcastId').value"
                            />

                            <div
                                    *ngIf="!userIsEventAdmin() || replayAttendanceGroup.get('hasWebcastId').value"
                            >
                                {{replayAttendanceGroup.get("replayDate").value}}
                            </div>
                        </div>

                        <div
                            class="flex"
                            appIfUserFeature="EventAdmin"
                        >
                            <button
                                type="button"
                                class="btn btn-danger btn-sm flex"
                                [disabled]="replayAttendanceGroup.get('hasWebcastId').value"
                                (click)="clearReplay(replayAttendanceGroup)"
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button
                        appIfUserFeature="EventAdmin"
                        type="button"
                        class="btn btn-primary btn-sm flex"
                        [disabled]="attendeeForm.invalid || formSaving || formLoading"
                        (click)="save()"
                >
                    Save
                </button>
                <button type="button" class="btn btn-default btn-sm flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
