<style>
    .ng-invalid > .btn{
        border-color: red;
    }
</style>

<div [formGroup]="activityForm">
    
    <div *ngIf="showCallDate" class="form-group">
        <label>
            Call Date
        </label>

        <input
            class="form-control input-sm"
            formControlName="callDate"
            type="date"
            [valueAsDate]="initialCallDate"
        />
    </div>

    <div
        *ngIf="showCallDate"
        class="form-group"
    >
        <label>
            Client Participant(s)
        </label>

        <app-multi-contact-select
            formControlName="contacts"
            [showEditButtons]="false"
            [contactRequired]="true"
            [accountId]="accountId"
        >
        </app-multi-contact-select>

        <div
            style="font-size: 12px; font-style: italic;"
            *ngIf="contactMoved"
            appRequiresFeature="activity-form-show-original-account"
        >
            Activity Occured at: {{ activityOccurredAt }}
        </div>
    </div>

    <div class="form-group"
         *ngIf="this.activityIsBusinessUpdate()"
    >
        <label>
            Baird Participant(s)
        </label>

        <app-multi-user-select
                formControlName="participantUserIds"
                [minUsers]="1"
        >
        </app-multi-user-select>
    </div>
    
    <div class="form-group">
        <label>
            Category
        </label>

        <app-activity-category-picker
            formControlName="category"
            *ngIf="!uneditableCategory"
            [selectClearAll]="false"
            [closeOnSelect]="true"
            [multiple]="false"
            [showTagCountLabel]="false"
            [excludeCategories]="excludeCategories"
            [categoryByRole]="true"
        >
        </app-activity-category-picker>

        <input
            type="text"
            [disabled]="true"
            [value]="uneditableCategory"
            *ngIf="uneditableCategory"
            class="form-control input-sm"
        />
    </div>

    <div
        class="form-group"
        *ngIf="subCategories?.length > 0"
    >
        <label>
            Sub Category
        </label>

        <div>
            <div class="btn-group" btnRadioGroup formControlName="subCategory">
                <div
                    *ngFor="let subCat of subCategories"
                    class="btn btn-primary btn-sm btn-toggle"
                    [btnRadio]="subCat.Value"
                    [disabled]="disabled"
                >
                    {{subCat.Value}}
                 </div>
            </div>
        </div>
    </div>

    <div
        class="form-group"s
        *ngIf="isCorpAccess()"
    >
        <label>
            Event
        </label>

        <app-event-picker
            formControlName="eventId"
            [showSelectClearAll]="false"
            [multiple]="false"
            [showTagCountLabel]="false"
            [closeOnSelect]="true"
        >
        </app-event-picker>
    </div>

    <div
        class="form-group flex-hbox flex-gap"
        *ngIf="isCorpAccess()"
    >
        <div class="flex">
            <label>
                Status
            </label>

            <app-activity-status-picker
                formControlName="status"
                [selectClearAll]="false"
                [multiple]="false"
                [showTagCountLabel]="false"
                [closeOnSelect]="true"
                [useNameAsId]="true"
                [includeEmptyOption]="true"
            >
            </app-activity-status-picker>
        </div>

        <div class="flex">
            <label>
                Meeting Type
            </label>

            <app-activity-meeting-type-picker
                formControlName="meetingType"
                [selectClearAll]="false"
                [multiple]="false"
                [showTagCountLabel]="false"
                [closeOnSelect]="true"
                [forActivityForm]="true"
            >
            </app-activity-meeting-type-picker>
        </div>
    </div>

    <div class="form-group">
        <label>
            Comment
        </label>

        <textarea
            type="text"
            formControlName="comment"
            [placeholder]="commentPlaceholder || ''"
            [maxlength]="this.activityIsBusinessUpdate() ? 4000 : 80"
            [rows]="this.activityIsBusinessUpdate() ? 9 : 2"
            class="form-control"
        >
        </textarea>
    </div>

    <div
        class="form-group flex-hbox flex-gap"
        *ngIf="!this.activityIsBusinessUpdate() && !this.activityIsClientEntertainment()"
    >
        <div class="flex-3">
            <ng-template #tickerTitle>
                <label>
                    Ticker
                </label>
            </ng-template>

            <app-multi-ticker-select 
                formControlName="tickers"
                [minimumTickers]="minTickers"
                [titleTemplate]="tickerTitle"
            >
            </app-multi-ticker-select>

            <span *ngIf="this.activityForm.get('category').value === 'D'">
                Each ticker will be a logged as an activity
            </span>
        </div>
        <div
            class="flex"
            *ngIf="this.user?.Team?.Name !== 'Market Maker' && this.activityForm.get('category').value !== 'D'"
        >
            <label>
                Duration
            </label>

            <input
                type="number"
                formControlName="duration"
                class="form-control input-sm"
                min="0"
                max="999"
            />
        </div>
    </div>

    <div
        class="form-group flex-hbox flex-gap"
        *ngIf="this.activityForm.get('category').value === 'Z'"
    >
        <div class="flex">
            <label>
                Buy / Sell
            </label>

            <app-string-picker
                formControlName="side"
                [multiple]="false"
                [showTagCountLabel]="false"
                [closeOnSelect]="true"
                [showSelectClearAll]="false"
                [items$]="sideOptions$"
            >
            </app-string-picker>
        </div>
        <div class="flex">
            <label>
                Stock Price
            </label>

            <input
                type="number"
                formControlName="price"
                class="form-control input-sm"
            />
        </div>
    </div>

    <div class="form-group"
         *ngIf="!this.activityIsBusinessUpdate()"
    >
        <label>
            Baird
        </label>

        <app-multi-user-select
            formControlName="participantUserIds"
            [minUsers]="1"
        >
        </app-multi-user-select>
    </div>

    <div
        class="form-group"
        *ngIf="this.allowExtraClientEntertainmentActivity()"
    >
        <label style="font-weight: normal;">
            <input
                type="checkbox"
                formControlName="includeExtraClientEntertainmentActivity"
            /> Client Entertainment
        </label>

        <div *ngIf="this.activityForm.get('includeExtraClientEntertainmentActivity').value === true">
            <div
                class="flex"
                style="padding: 5px 10px 2px 10px; border: 1px solid #ddd; background-color: #F9F9F9"
            >
                <div class="form-group">
                    <label>
                        Comment
                    </label>

                    <textarea
                        type="text"
                        formControlName="extraClientEntertainmentActivityComment"
                        [placeholder]="commentPlaceholder || ''"
                        maxlength="80"
                        rows="2"
                        class="form-control"
                    >
                    </textarea>
                </div>
            </div>
        </div>
    </div>

    <div
        class="form-group"
        [hidden]="!allowExcelModelTickers()"
    >
        <label style="font-weight: normal;">
            <input
                type="checkbox"
                formControlName="includeExcelModel"
            /> Include excel model activities
        </label>
    </div>

    <div [hidden]="!showExcelModelTickers()">
        <ng-template #excelTickerTitle>
            <label>
                Excel Model Ticker(s)
            </label>
        </ng-template>

        <app-multi-ticker-select
            formControlName="excelModelTickers"
            [minimumTickers]="minExcelModelTickers"
            [titleTemplate]="excelTickerTitle"
        >
        </app-multi-ticker-select>

        <div class="help-block">
            Each ticker will be a logged as an activity
        </div>
    </div>
</div>
