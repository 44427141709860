<div class="modal-header">
    <h5 class="modal-title">Details</h5>
</div>
<form [formGroup]="readershipForm" >
    <div class="modal-body">
        <div class="form-group-sm">
            <label>Subject / Headline</label>
            <div>{{campaignDetails.campaign && campaignDetails.campaign.Subject}}</div>
        </div>

        <div class="form-group-sm flex-hbox">
            <div class="flex">
                <label>Sent</label>
                <div>{{displaySentDate(campaignDetails)}}</div>
            </div>
            <div class="flex">
                <label>Sent By</label>
                <div>
                    {{displaySenders(campaignDetails.campaign)}}
                </div>
            </div>
        </div>

        <div class="form-group-sm">
            <label>Content</label>

            <iframe
                *ngIf="!campaignDetails.htmlUrl"
                style="height: 200px; overflow: auto; border: 1px solid #ddd; margin-bottom: 15px; width: 100%;"
                [srcdoc]="(campaignDetails.campaign?.Html || '<div></div>') | trustHTML"
            >
            </iframe>

            <div *ngIf="campaignDetails.htmlUrl">
                <iframe
                    style="height: 200px; overflow: auto; border: 1px solid #ddd; margin-bottom: 15px; width: 100%;"
                    [src]="campaignDetails.htmlUrl"
                >
                </iframe>
            </div>
        </div>

        <div>
            <label class="pull-left">Contacts ({{(rowData$ | async)?.length}})</label>
            <button class="pull-right btn btn-sm btn-default" (click)="downloadReadership()" tooltip="Download Readership"><span class="fa fa-download"></span></button>
            <span class="btn-group pull-right" style="margin-bottom: 5px; margin-right: 5px;">
                <label class="btn btn-sm btn-toggle" formControlName="my" btnCheckbox tabindex="0" role="button">My</label>
                <label class="btn btn-sm btn-toggle" formControlName="open" btnCheckbox tabindex="0" role="button">Only Opens</label>
                <label class="btn btn-sm btn-toggle" formControlName="click" btnCheckbox tabindex="0" role="button">Only Clicks</label>
            </span>
        </div>
        <ag-grid-angular
                [masterDetail]="masterDetail"
                style="width: 100%; height: 300px; clear: both"
                class="ag-theme-balham"
                [gridOptions]="readershipGridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="false"
                (gridReady)="onGridReady($event)"
                [detailCellRendererParams]="detailCellRendererParams"
        ></ag-grid-angular>
    </div>
</form>
