import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ConfigService} from "../../Shared/Services/config.service";
import { UserService } from "../../Shared/Services/user.service";

@Component({
    selector: "app-network-swap-form",
    templateUrl: "./network-swap-form.component.html"
})
export class NetworkSwapFormComponent implements OnInit {

    isExternalApi : UntypedFormControl = this.fb.control("true");
    saving: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private toastr: ToastrService,
                private configService: ConfigService,
                private userService: UserService) { }

    ngOnInit(): void {
        const value = this.configService.getApiIsExternal() ? "true" : "false";
        this.isExternalApi.setValue(value);
    }

    close(): void {
        this.modalRef.hide();
    }

    submit() {
        this.saving = true;

        const newIsExternalValue = this.isExternalApi.value === "true";

        this.configService.setApiIsExternal(newIsExternalValue);

        const successMessage = `Successfully updated connection type to ${newIsExternalValue ? "external" : "internal"}.`

        if (!newIsExternalValue) {
            this.userService.getUser().subscribe(
                user => {
                    this.toastr.success(successMessage);
                    this.saving = false;
                    this.close();
                },
                error => {
                    this.configService.setApiIsExternal(!newIsExternalValue);
                    this.saving = false;
                    this.toastr.error("Failed to reach internal connection. Check your VPN connection or consider connecting to the network via a Baird device.");
                }
            );
        } else {
            this.saving = false;

            this.toastr.success(successMessage);
            this.close();
        }
    }
}
