export class ContactRoutePaths {
  static readonly BasePath: string = "contacts";

  static readonly InterestListPathFragment: string = "interestlist";
  static readonly ContactListsPathFragment: string = "lists";

  static readonly Contacts: string = ContactRoutePaths.BasePath;
  static readonly InterestList: string = `${ContactRoutePaths.BasePath}/${ContactRoutePaths.InterestListPathFragment}`;
  static readonly ContactLists: string = `${ContactRoutePaths.BasePath}/${ContactRoutePaths.ContactListsPathFragment}`;
  static readonly ContactDetail: string = "contact";
}
