import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {InstiselSector, TickerService} from "../../Shared/Services/ticker.service";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: "app-add-tickers-modal",
    templateUrl: "./add-tickers-modal.component.html"
})
export class AddTickersModalComponent implements OnInit {

    @Output()
    action = new EventEmitter<string[]>();

    selectedTickers: any = {};

    allInstiselSectors: InstiselSector[] = [];
    allTickers: TickerDetail[] = [];
    tickers: TickerDetail[] = [];

    searchForm: UntypedFormGroup = this.fb.group({
        analysts: this.fb.control([]),
        sectors: this.fb.control([]),
        covered: this.fb.control(false),
    });

    constructor(public bsConfirmModalRef: BsModalRef,
                private fb: UntypedFormBuilder,
                private tickerService: TickerService
                ) {
    }

    ngOnInit(): void {
        let instiselSectors$ = this.tickerService.getInstiselSectors();
        let coveredTickers$ = this.tickerService.getAllTickers();

        forkJoin([instiselSectors$, coveredTickers$])
            .subscribe(([instiselSectors, coveredTickers]) => {

                this.allInstiselSectors = instiselSectors;

                this.allTickers = coveredTickers.map(ct => {

                    const instiselSector = instiselSectors.find(s => s.InstiselSectorBlueMatrixMappings.some(mapping => mapping.BlueMatrixSectorId === ct.BlueMatrixSectorId));

                    return {
                        Name: ct.Name,
                        AnalystId: ct.AnalystId,
                        InstiselSectorId: instiselSector.Id
                    }
                });

                this.searchForm.valueChanges
                    .subscribe(formValue => {
                        if (formValue.analysts.length === 0 &&
                            formValue.sectors.length === 0) {
                            this.tickers = [];
                        } else {
                            const childSectorIdsOfSelectedSectors =
                                this.allInstiselSectors
                                    .filter(sector => formValue.sectors.some(formValueSector => formValueSector == sector.ParentId))
                                    .map(sector => sector.Id);

                            const sectorIdsToFilterTickersBy = [...childSectorIdsOfSelectedSectors, ...formValue.sectors];

                            this.tickers = this.allTickers
                                .filter(ticker => formValue.analysts.length > 0 ? formValue.analysts.includes(ticker.AnalystId) : true)
                                .filter(ticker => sectorIdsToFilterTickersBy.length > 0 ? sectorIdsToFilterTickersBy.some(sector => ticker.InstiselSectorId === sector) : true);
                        }
                    });
            })
    }

    save(): void {

        const newTickers = _.chain(this.selectedTickers)
            .keys()
            .filter((it:string) => this.selectedTickers[it])
            .value();
        this.action.emit(newTickers);

        this.bsConfirmModalRef.hide();
    }

    cancel(): void {
        this.bsConfirmModalRef.hide();
    }
}

interface TickerDetail {
    Name: string;
    AnalystId: string;
    InstiselSectorId: number;
}
