import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AccountListComponent} from "./AccountList/account-list.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {AccountProfileComponent} from "./AccountProfile/account-profile.component";
import {AccountProfileFormComponent} from "./AccountProfile/account-profile-form.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {AccountDetailComponent} from "./AccountDetail/account-detail.component";
import {AccountVotesComponent} from "./AccountVotes/account-votes.component";
import {AccountVoteInputComponent} from "./AccountVotes/account-vote-input.component";
import {AccountVoteFormComponent} from "./AccountVotes/account-vote-form.component";
import {AccountVoteAttachmentCellRenderer} from "./AccountVotes/account-vote-attachment-render.component";
import {CommissionRankComponent} from "./CommissionRank/commission-rank.component";
import {AccountContactListComponent} from "./AccountContactList/account-contact-list.component";
import {TabAccountDetailsComponent} from "./TabAccountDetails/tab-account-details.component";
import {AccountCommissionsComponent} from "./AccountCommissions/account-commissions.component";
import {TabAccountSummaryComponent} from "./TabAccountSummary/tab-account-summary.component";
import {AccountSummaryGroupRenderer} from "./TabAccountSummary/account-summary-group-renderer.component";
import {IconHeaderRendererComponent} from "../Widget/CellRenderers/icon-header-renderer.component";
import {ContactListRenderer} from "../Widget/CellRenderers/contact-list-renderer.component";
import {CommissionChartComponent} from "./CommissionChart/commission-chart.component";
import {AccountCommissionTotalsComponent} from "./AccountCommissionTotals/account-commission-totals.component";
import {NetCommissionChartComponent} from "./NetCommissionChart/net-commission-chart.component";
import {AccountNotesComponent} from "./AccountNotes/account-notes.component";
import {TabRevenueTargetListComponent} from "./AccountRevenueTarget/tab-revenue-target-list.component";
import {RevenueTargetFormComponent} from "./AccountRevenueTarget/revenue-target-form.component";
import {RouterModule} from "@angular/router";
import {accountRoutes} from "./account.routes";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {BillingInfoComponent} from "./BillingInfo/billing-info.component";
import {BillingInfoFormComponent} from "./BillingInfo/billing-info-form.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([
            AccountVoteAttachmentCellRenderer,
            AccountSummaryGroupRenderer,
            IconHeaderRendererComponent,
            ContactListRenderer
        ]),
        ButtonsModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        TabsModule,
        TooltipModule,
        RouterModule.forChild(accountRoutes),
    ],
    exports: [
        RouterModule
    ],
    declarations: [
        AccountListComponent,
        AccountProfileComponent,
        AccountProfileFormComponent,
        AccountDetailComponent,
        AccountVotesComponent,
        AccountVoteFormComponent,
        AccountVoteInputComponent,
        AccountVoteAttachmentCellRenderer,
        AccountSummaryGroupRenderer,
        CommissionRankComponent,
        AccountContactListComponent,
        TabAccountDetailsComponent,
        AccountCommissionsComponent,
        TabAccountSummaryComponent,
        CommissionChartComponent,
        AccountCommissionTotalsComponent,
        NetCommissionChartComponent,
        AccountNotesComponent,
        TabRevenueTargetListComponent,
        RevenueTargetFormComponent,
        BillingInfoComponent,
        BillingInfoFormComponent,
    ],
    providers: [
        UserCanActivateRoute,
    ]
})
export class AccountModule {
    constructor(){
        console.log("registering the AccountModule");
    }
}
