import { CalendarEvent } from "../Components/Events/CalendarEvent";


export function getEventDisplayName(event: CalendarEvent): string {
    return getEventDisplayNameByValues(event.IsActive, event.Name);
}

export function getEventDisplayNameByValues(isActive: boolean, name: string): string {
    return isActive ? name : `(Canceled) ${name}`;
}


export function getNameForDisplay(firstName: string, lastName: string): string {
    if (firstName && lastName) {
        return `${lastName}, ${firstName}`;
    }

    if (firstName) {
        return firstName;
    }

    if (lastName) {
        return lastName;
    }

    return "";
}