import * as moment from "moment";
import * as _ from "lodash";
import {RoleName} from "../../Models/Role";
import {ICellRendererParams, ValueGetterParams} from "ag-grid-community";
import {Activity, ActivityParticipant} from "../../Models/Activity";
import {UserTeam} from "../../Models/UserTeam";
import {CalendarEvent} from "../../Components/Events/CalendarEvent";
import {User} from "../../Models/User";

export const accountTagsVoteRenderer = (params: any): string => {
    let tags = params && params.value || [];
    if (tags.indexOf("Vote") >= 0) return `<span class="label-vote fa fa-check-circle" title="Vote"></span>`;
    if (tags.indexOf("Wildcard") >= 0) return `<span class="label-icon label-wildcard fa fa-asterisk" title="Other Priorities"></span>`;
    return ``;
};

export const contactHasResearchRenderer = (params: any): string => {
    if (params.value)
        return '<span class="label-vote fa fa-newspaper-o" tooltip="Has Research"></span>';
    return ``;
};

export const emailRenderer = (params: any): string => {
    let email = params && params.value || ``;

    if (!email || email.indexOf('@') === -1) return ``;

    return `<a href="mailto:${email}" onclick="event.stopPropagation()">${email}</a>`;
};

export const dateRenderer = (params: any): string => {
    return params.value && moment(params.value) ? moment(params.value).format('L') : '';
};

export const dateTimeRenderer = (params: any): string => {
    return params.value && moment(params.value) ? moment(params.value).format('YYYY-MM-DD HH:mm:ss') : '';
};

export const dateRendererNoYear = (params: any): string => {
    return params.value && moment(params.value) ? moment(params.value).format('MM/DD') : '';
};


export const currencyRenderer = (params: ICellRendererParams, precision: number = 0): string => {
    return currencyFormatter(params.valueFormatted ?? params.value, precision);
};


export const currencyFormatter = (value: any, precision: number = 0): string => {
    if (isNaN(value)) return '';

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });

    return formatter.format(value);
}

export const numberRenderer = (params: any, precision: number = 0): string => {
    return numberFormatter(params.value, precision);
};

export const numberFormatter = (value: any, precision: number = 0): string => {
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
    });

    return formatter.format(value);
};

export const activityParticipantsRenderer = (activity: Activity, userTeams: UserTeam[], users: User[]) => {
    let participantsToDisplay = [];

    if (activity.Category === "B") {
        let singleParticipant: ActivityParticipant = {
            UserId: activity.ContactedBy,
            TeamId: users?.find(u => u.Id === activity.ContactedBy)?.Team?.Id
        };

        participantsToDisplay = [ singleParticipant ];
    } else {
        participantsToDisplay = activity.Participants;
    }

    return participantsRenderer(participantsToDisplay, userTeams);
}

export const participantsRenderer = (participants: ActivityParticipant[], userTeams: UserTeam[], useRole: boolean = true): string => {
    if (participants) {
        let teamDictionary = {};
        userTeams.forEach(team => teamDictionary[team.TeamId] = team);

        let participantTeams = participants.map(p => teamDictionary[p.TeamId]);

        const defaultClass = "label-activity-author-default";
        let authorClass = defaultClass;
        let firstParticipantRole = teamDictionary[participants[0]?.TeamId]?.RoleName;

        if (useRole && firstParticipantRole) {
            const labels: Record<string, string> = {
                "conference services": "label-meeting-author-conf",
                "research": "label-meeting-author-research",
                "traders": "label-meeting-author-traders",
                "desk analyst": "label-meeting-author-desk-analyst"
            };
            authorClass = labels[firstParticipantRole.toLowerCase().trim()] || defaultClass;
        }

        if (participants?.length > 1) {
            let hasBairdResearch = participantTeams.some(r => r?.RoleName == RoleName.Research)
            if (hasBairdResearch && useRole) {
                return `<span class="label label-meeting-author-desktop label-meeting-author-research">****</span>`;
            }
            return `<span style="margin-left: 15px">****</span>`
        }
        return `<span class="label label-meeting-author-desktop ${authorClass}">${participants[0].UserId}</span>`;
    }
};

export const favoriteCellRenderer = (params: any): string => {
    return params.value ? `<span class="fa favorite-contact fa-star" title="Favorite"></span>` : '';
}

export const yesNoCellRenderer = (params: any): string => {
    return params.value ? `Y` : 'N';
}

export const yesBlankCellRenderer = (params: any): string => {
    return params.value ? `Y` : '';
}

export const contactNameValueGetter = (params: any): string => {
    if (!params.data.FirstName && !params.data.LastName) {
        return "";
    }
    return `${params.data.LastName || ""}, ${contactFirstNameAndAliasValueGetter(params)}`;
}

export const contactFirstNameAndAliasValueGetter = (params: any): string => {
    if (!params.data.FirstName) {
        return "";
    }
    let fullName = `${params.data.FirstName || ""}`;

    if (params.data.Alias && params.data.Alias !== params.data.FirstName) {
        fullName = fullName.concat(` (${params.data.Alias || ""})`);
    }

    return fullName;
}



export const eventAttendanceCountCellRenderer = (params: ICellRendererParams<CalendarEvent>): string | number =>{
    if (params.data.NumberOfAttendees > parseInt(params.data.MaxAttendance))
    {
        return `<div style="background-color: red; color: white; padding: 2px;">${params.data.NumberOfAttendees}</div>`;
    }

    return params.data.NumberOfAttendees;
}