import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {combineLatest, Observable, of} from "rxjs";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {EventService} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../../Models/User";
import {RoleName} from "../../../Models/Role";
import {ActivatedRoute} from "@angular/router";
import {CalendarEventAttendee} from "../../../Components/Events/CalendarEventAttendee";
import {CalendarEvent} from "../../../Components/Events/CalendarEvent";
import {RequestAttendeeFormComponent} from "./RequestAttendeeForm/request-attendee-form.component";

@Component({
    selector: "app-tab-request-attendee-list",
    template: `
        <div class="flex flex-vbox">
            <div class="flex" style="margin-bottom: 5px;">
                <form [formGroup]="attendeeSearchForm">
                    <div class="flex-hbox flex-gap">
                        <div
                                class="btn btn-primary btn-sm btn-toggle"
                                btnCheckbox
                                formControlName="myAccounts"
                                tooltip="My Accounts"
                        >
                            <span class="fa fa-sitemap"></span>
                        </div>
                        <div class="flex-3">
                            <input type="search"
                                   class="flex form-control input-sm"
                                   formControlName="search"
                                   placeholder="Search Name, Account"/>
                        </div>
                    </div>
                </form>
            </div>

            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
                    (rowClicked)="onRowClick($event)"
            >
            </ag-grid-angular>
        </div>
    `,
    styleUrls: ["./tab-request-attendee-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabRequestAttendeeListComponent extends BaseGridComponent<EventAttendee> implements OnInit {

    @Input()
    requestId: number;

    event: CalendarEvent;

    allAttendees$: Observable<CalendarEventAttendee[]>;

    user: User;

    userIsEventAdmin: boolean;

    statuses$: Observable<string[]>;

    attendeeSearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(true),
        search: this.fb.control(''),
    });

    columnDefs: ColDef[] = [
        {
            field: 'Name',
            headerName: 'Contact Name',
            flex: 2,
            sort: "asc",
            sortIndex: 2
        },
        {
            field: 'Account', headerName: 'Account Name',
            flex: 2,
            sort: "asc",
            sortIndex: 1
        },
        {field: 'Broker', flex: 1},
        {
            field: "Tier",
            headerTooltip: "Tier",
            width: 50,
        },
        {
            field: "Registered",
            headerName: "Reg",
            headerTooltip: "Registered",
            width: 50,
            cellClass: "highlight"
        },
        {
            field: "Interested",
            headerName: "Int",
            headerTooltip: "Interested",
            width: 40
        },

        {
            field: "Attended",
            headerName: "Att",
            headerTooltip: "Attended",
            width: 45,
            cellClass: "highlight"
        },
        {
            field: "Canceled",
            headerName: "Cxl",
            headerTooltip: "Canceled",
            width: 45
        },
        {
            field: "NoShow",
            headerName: "NoSho",
            headerTooltip: "No Show",
            width: 67
        },
        {
            field: "Replay",
            headerName: "Rpl",
            headerTooltip: "Replay",
            width: 45
        },
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private eventService: EventService,
        private userService: UserService,
        private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.overlayNoRowsTemplate = `<span>No Attendees Found</span>`;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.attendeeSearchForm.patchValue({myAccounts: _.includes([RoleName.Sales, RoleName.Traders], user.Role.Name)})

                this.eventService.getRequest(this.requestId)
                    .subscribe(e => this.loadEvent(e));
            });
    }

    loadEvent(event: CalendarEvent): void {
        this.event = event;

        this.allAttendees$ = of(event.Attendees);

        this.loadEventAttendees();
    }

    loadEventAttendees(): void {
        let formData$ = this.attendeeSearchForm.valueChanges.pipe(
            startWith(this.attendeeSearchForm.getRawValue()),
            debounceTime(200)
        );

        this.rowData$ = combineLatest([this.allAttendees$, formData$]).pipe(
            map(([attendees, formData]) => {
                let groupedAttendees = _.chain(attendees)
                    .filter(a => {
                        if (formData.myAccounts && !a.Tags.includes("My")) {
                            return false;
                        }
                        return true;
                    })
                    .groupBy(a => a.ContactId)
                    .map((values: CalendarEventAttendee[], key: string) => {
                        let a = _.first(values);

                        return {
                            Status: a.Status,
                            StatusCount: values.length,
                            Name: `${a.LastName}, ${a.FirstName}`,
                            Account: a.AccountName,
                            Broker: a.PersonalBroker,
                            Tier: a.Tier,
                            Registered: values.filter(a => a.Status === "Registered").length,
                            Canceled: values.filter(a => a.Status === "Canceled").length,
                            NoShow: values.filter(a => a.Status === "No Show").length,
                            Attended: values.filter(a => a.Status === "Attended").length,
                            Interested: values.filter(a => a.Status === "Interested").length,
                            Replay: values.filter(a => a.Status === "Replay").length,
                            ContactId: a.ContactId,
                        } as EventAttendee;
                    })
                    .value();

                let orderedStatuses = _.chain(groupedAttendees)
                    .map(a => a.Status)
                    .uniq()
                    .value()
                    .sort(_.bind(this.statusComparator, this));

                this.statuses$ = of(_.chain(orderedStatuses)
                    .map(status => `${status} (${groupedAttendees.filter(a => a.Status === status).length})`)
                    .value());

                return _.chain(groupedAttendees)
                    .filter(a => {
                        let searchTarget = `${a.Name.toLowerCase()} ${a.Account.toLowerCase()}`;
                        let search = formData.search.toLowerCase();
                        if (search && !_.every(search.split(' ').filter(t => t.length > 0), term => searchTarget.includes(term))) {
                            return false;
                        }

                        return true;
                    })
                    .orderBy(a => a.Name)
                    .value();
            })
        )
    }

    onGridReady(params) {
        super.onGridReady(params);
    }

    statusComparator(status1: string, status2: string): number {
        const correctOrder = ['Invited', 'Attended', 'No Show', 'Canceled'];
        let status1index = correctOrder.indexOf(status1);
        let status2index = correctOrder.indexOf(status2);
        return status1index < status2index ? -1 :
            status2index < status1index ? 1 :
                0;
    }

    onRowClick($event: RowClickedEvent<any>) {
        this.openAttendeeForm(this.requestId, $event.data.ContactId);
    }

    openAttendeeForm(requestId: number, contactId: number): void {
        const initialState = {
            contactId: contactId,
            requestId: requestId,
        };
        this.modalService.show(RequestAttendeeFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });
    }
}

interface EventAttendee {
    Status: string;
    StatusCount: number;
    Name: string;
    Account: string;
    Broker: string;
    Tier: string;
    Registered: number,
    Canceled: number,
    NoShow: number,
    Attended: number,
    Interested: number,
    ContactId: number,
}
