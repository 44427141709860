import * as _ from "lodash";
import { ActivityQuery } from "../Services/ActivityService";
import {QueryGroup, QueryPredicate, QueryOperation} from "../Services/QueryService";

export class QueryTranslator {
    static translateActivity(query: ActivityQuery): QueryGroup[] {
        const translatedQueries: QueryGroup[] = [];

        if (query.grid) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "Grid",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.favorites) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Favorite",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.myAccounts) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "My Account",
                    Operation: "is",
                    Value: "true"
                },{
                    Object: "Contact",
                    Field: "My",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.searchTerms) {
            _.split(query.searchTerms, /\s+/).forEach(search => {
                const queryObjects: QueryPredicate[] = [];

                queryObjects.push({
                    Object: "Account",
                    Field: "Name",
                    Operation: "contains",
                    Value: search
                });

                queryObjects.push({
                    Object: "Activity",
                    Field: "Comment",
                    Operation: "contains",
                    Value: search
                });

                queryObjects.push({
                    Object: "Contact",
                    Field: "First Name",
                    Operation: "contains",
                    Value: search
                });

                queryObjects.push({
                    Object: "Contact",
                    Field: "Last Name",
                    Operation: "contains",
                    Value: search
                });

                queryObjects.push({
                    Object: "Contact",
                    Field: "Corresp Name",
                    Operation: "contains",
                    Value: search
                });

                translatedQueries.push({ Queries: queryObjects });
            });
        }

        if (query.tickers) {
            let cleanTickers = query.tickers.trim().replace(/[,\s]+/g, " ").replace(/[—–]/g, "-");
            let tickers = cleanTickers.split(" ").filter((t) => {
                return t !== undefined && t !== ""
            });

            if (_.some(tickers)) {
                translatedQueries.push({
                    Queries: [{
                        Object: "Activity",
                        Field: "Tickers",
                        Operation: "contains",
                        Values: tickers
                    }]
                });
            }
        }

        if (query.startDate && query.endDate) {
            translatedQueries.push({
                Queries: [{
                    Object: "Activity",
                    Field: "Date",
                    Operation: "between",
                    Values: [query.startDate, query.endDate]
                }]
            });
        }

        if (query.categories && _.some(query.categories)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Activity",
                    Field: "Category",
                    Operation: "contains",
                    Values: query.categories
                }]
            });
        }

        if (query.tiers && _.some(query.tiers)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "Tier",
                    Operation: "contains",
                    Values: query.tiers
                }]
            });
        }

        if (query.callers && _.some(query.callers)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Activity",
                    Field: "Baird Caller",
                    Operation: "contains",
                    Values: query.callers
                }]
            });
        }

        if (!_.isNil(query.activityGroupFilter) && !_.isEmpty(query.activityGroupFilter)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Activity",
                    Field: "Group",
                    Operation: "is",
                    Value: query.activityGroupFilter
                }]
            });
        }

        return translatedQueries;
    }

    static translateCallList(query: any): any[] {
        const translatedQueries : QueryGroup[] = [];

        if (query.Analysts && _.some(query.Analysts)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Holding",
                    Field: "Analyst",
                    Operation: "contains",
                    Values: query.Analysts
                }]
            });
        }
        
        if (query.Tickers && _.some(query.Tickers)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Holding",
                    Field: "Ticker",
                    Operation: "contains",
                    Values: query.Tickers
                }]
            });
        }
        
        if (query.Query) {
            translatedQueries.push({
                Queries: [{
                    Object: "Holding",
                    Field: "Comment",
                    Operation: "contains",
                    Value: query.Query
                }]
            });
        }
        
        if (query.Tiers && _.some(query.Tiers)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Contact Tier",
                    Operation: "contains",
                    Values: query.Tiers
                }]
            });
        }

        if (query.Brokers && _.some(query.Brokers)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Personal Broker",
                    Operation: "contains",
                    Values: query.Brokers
                }]
            });
        }

        if (query.Categories && _.some(query.Categories)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Holding",
                    Field: "Category",
                    Operation: "contains",
                    Values: query.Categories
                }]
            });
        }

        if (query.Favorites) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Favorite",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.Grid) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "Grid",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.My) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "My Account",
                    Operation: "is",
                    Value: "true"
                }]
            });
        }

        if (query.BairdFilter && _.some(query.BairdFilter)) {
            translatedQueries.push({
                Queries: [{
                    Object: "Account",
                    Field: "Contact Groups",
                    Operation: "not contains",
                    Values: _.map(query.BairdFilter, (it: string) => QueryTranslator.translateContactGroup(it))
                }]
            });
        }

        if (query.MarketingEmails) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Marketing Email",
                    Operation: "is",
                    Value: QueryTranslator.translateBooleanValue(query.MarketingEmails)
                }]
            });
        }

        if (query.ResearchVoiceMails) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Research Voicemail",
                    Operation: "is",
                    Value: QueryTranslator.translateBooleanValue(query.ResearchVoiceMails)
                }]
            });
        }

        if (query.ResearchEmails) {
            translatedQueries.push({
                Queries: [{
                    Object: "Contact",
                    Field: "Research Email",
                    Operation: "is",
                    Value: QueryTranslator.translateBooleanValue(query.ResearchEmails)
                }]
            });
        }

        if (query.BlueMatrix) {
            translatedQueries.push({
                Queries: [{
                    Object: "Holding",
                    Field: "Blue Matrix",
                    Operation: "contains",
                    Values: [query.BlueMatrix]
                }]
            });
        }
        
        if (_.some(query.AdditionalQueryGroups)) {
            translatedQueries.push(... query.AdditionalQueryGroups);
        }
        
        return translatedQueries;
    }
    
    public static AddQueryGroupValue(queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation, value: string | number): void {
        queryGroups.push({
            Queries: [{
                Object: object,
                Field: field,
                Operation: operation,
                Value: value
            }]
        });
    }

    public static AddQueryGroupValues(queryGroups: QueryGroup[], object: string, field: string, operation: QueryOperation, values: string[] | number[]): QueryGroup {
        const queryGroup = {
            Queries: [{
                Object: object,
                Field: field,
                Operation: operation,
                Values: values
            }]
        };
        queryGroups.push(queryGroup);
        return queryGroup;
    }

    public static AddQueryCriteriaValue(queryGroup: QueryGroup, object: string, field: string, operation: QueryOperation, values: string[] | number[]): void {
        queryGroup.Queries.push({
            Object: object,
            Field: field,
            Operation: operation,
            Values: values
        });
    }
    
    private static translateBooleanValue(source: string): string {
        if (_.isEqual(source, "Y")) {
            return "true";
        }

        return "false";
    }

    private static translateContactGroup(source: string): string {
        if (_.isEqual(source, "PCG")) {
            return "PWM";
        }

        if (_.isEqual(source, "RWB")) {
            return "IER";
        }

        return source;
    }
}