<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerFormTemplate>
        
    </ng-template>
    
    <app-page-banner
            pagetitle="User Features"
            faicon="fa-gear"
            faiconcolor="#708090"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <div class="page-body flex flex-hbox">
        <div class="flex flex-vbox" style="padding: 10px; border: 1px solid #ccc;">
            
            <div style="margin-bottom: 15px;">
                <form class="form-inline pull-right" [formGroup]="featureSearchForm">
                    <div class="input-group">
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 250px;"
                               formControlName="searchTerm"
                               placeholder="Search" />
                    </div>
                </form>
                <h4>Features</h4>
            </div>
            
            <div class="flex" style="overflow-y: auto;">
                <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-4" style="margin-bottom: 5px;"
                         *ngFor="let userFeatureName of userFeatureNames$ | async">
                        <div class="btn btn-default btn-sm"
                             [(ngModel)]="selectedUserFeature"
                             btnRadio="{{userFeatureName}}"
                             (click)="selectUserFeature(userFeatureName)">
                            {{ userFeatureName }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="flex flex-vbox" style="margin-left: 10px; padding: 10px; border: 1px solid #ccc;">
            <h4 *ngIf="selectedUserFeature !== ''">Users with {{ selectedUserFeature }}</h4>
            <form>
                <div *ngIf="selectedUserFeature !== ''" class="form-group">
                    <div class="input-group input-group-sm">
                        <input name="newUserId" 
                               type="text" 
                               placeholder="Enter User Id"
                               maxlength="4"
                               class="form-control" 
                               [(ngModel)]="newUserId" />
                        <div class="input-group-btn">
                            <button type="button" class="btn btn-primary" (click)="addUserFeature()"><span class="fa fa-plus"></span></button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="flex list-group" style="overflow-y: auto;">
                <div class="list-group-item" *ngFor="let userFeature of userFeatures">
                    <span class="btn btn-danger btn-xs pull-right" (click)="deleteUserFeature(userFeature)">&times;</span>
                    {{userFeature.LastName}}, {{userFeature.FirstName}} ({{ userFeature.UserId }})
                </div>
            </div>
        </div>
    </div>
</div>
