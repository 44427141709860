<div class="modal-content">
    <app-event-meeting-header
            [eventMeeting]="eventMeeting"
            [title]="title"
    >
    </app-event-meeting-header>

    <form [formGroup]="meetingForm">
        <div class="modal-body">
            <tabset>
                <tab *ngIf="userIsEventAdmin() && !meetingTabWebcastViewOnlyToggle" heading="Details">

                    <h4>Presenters</h4>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Companies</label>
                            <app-multi-event-company-select
                                    [eventId]="eventId"
                                    formControlName="CompanyIds"
                                    (addedTickers)="addTickers($event)"
                            ></app-multi-event-company-select>
                        </div>
                        <div class="flex">
                            <label>Activity Comment Preview</label>
                            <div>{{ activityCommentPreview }}</div>
                        </div>
                    </div>

                    <h4>Details</h4>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Date</label>
                            <input type="date" formControlName="Date" class="form-control input-sm" />
                        </div>
                        <div class="flex">
                            <label>Duration</label>
                            <input type="number" formControlName="Duration" class="form-control input-sm" />
                        </div>
                        <div class="flex-2">
                            <label>Start Time</label>
                            <div>
                                <div [ngClass]="timeCssClass" style="display: inline-block;">
                                    <timepicker formControlName="StartTime" [showSpinners]="false"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="flex-2">
                            <label>End Time</label>
                            <div>
                                <div [ngClass]="timeCssClass" style="display: inline-block;">
                                    <timepicker formControlName="EndTime" [showSpinners]="false"></timepicker>
                                </div>
                            </div>
                        </div>
                        <div class="flex-2">
                            <label>Time Zone</label>
                            <app-time-zone-picker
                                    formControlName="TimeZoneId"
                                    [multiple] = "false"
                                    [closeOnSelect] = "true"
                                    [showSelectClearAll] = "false">
                            </app-time-zone-picker>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <label>Display Name</label>
                            <input class="form-control input-sm" formControlName="DisplayName" />
                        </div>
                        <div class="flex">
                            <label>Activity Category</label>
                            <app-activity-category-picker
                                    formControlName="ActivityCategory"
                                    [selectClearAll]="false"
                                    [closeOnSelect]="true"
                                    [multiple]="false"
                                    [showTagCountLabel]="false"
                                    [categoryByRole]="true"
                                    [showCorpTravelActivityTypes]="true"
                            ></app-activity-category-picker>
                        </div>
                        <div class="flex">
                            <label>Meeting Type</label>
                            <app-activity-meeting-type-picker formControlName="MeetingType"
                                                              [selectClearAll]="false"
                                                              [multiple]="false"
                                                              [showTagCountLabel]="false"
                                                              [closeOnSelect]="true"
                                                              [forActivityForm]="true"
                            ></app-activity-meeting-type-picker>
                        </div>
                        <div class="flex">
                            <label>Canceled/No Show Override</label>
                            <app-activity-meeting-type-picker formControlName="CancelledNoShowOverride"
                                                              [selectClearAll]="false"
                                                              [multiple]="false"
                                                              [showTagCountLabel]="false"
                                                              [closeOnSelect]="true"
                                                              [forActivityForm]="true"
                            ></app-activity-meeting-type-picker>
                        </div>
                    </div>
                    <div class="form-group flex-hbox flex-gap">
                        <div class="flex">
                            <ng-template #tickerTitle>
                                <label>Tickers</label>
                            </ng-template>
                            <app-multi-ticker-select
                                    formControlName="Tickers"
                                    [minimumTickers]="0"
                                    [titleTemplate]="tickerTitle">
                            </app-multi-ticker-select>
                        </div>
                        <div class="flex-2">
                            <label>Baird Callers</label>
                            <app-multi-user-select formControlName="AnalystIds" [activeUsersOnly]="true"></app-multi-user-select>
                        </div>
                        <div class="flex-1">
                            <label>Webcast ID</label>
                            <label *ngIf="isWebcastIdAlreadyInUse()" style="color: red; margin-left: 5px">* Already in use</label>
                            <input class="form-control input-sm" formControlName="WebcastId" />
                        </div>
                    </div>
                    <div class="form-group flex-1 flex-hbox flex-gap">
                        <div class="flex">
                            <label>Location</label>
                            <input class="form-control input-sm" formControlName="Location" />
                        </div>
                    </div>
                </tab>
                <tab *ngIf="true" heading="Attendees">
                    <app-meeting-form-attendee-tab [meetingId]="meetingId">
                    </app-meeting-form-attendee-tab>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button
                        appIfUserFeature="EventAdmin"
                        *ngIf="!meetingTabWebcastViewOnlyToggle"
                        type="button"
                        class="btn btn-primary btn-sm flex"
                        (click)="save()"
                        [disabled]="meetingForm.invalid || formLoading || formSaving"
                >
                    Save
                </button>
                <button
                        appIfUserFeature="EventAdmin"
                        *ngIf="meetingId && !meetingTabWebcastViewOnlyToggle"
                        type="button"
                        class="btn btn-danger btn-sm flex"
                        (click)="delete()"
                        [disabled]="formLoading || formSaving"
                >
                    Delete
                </button>
                <button type="button" class="btn btn-default btn-sm flex" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

