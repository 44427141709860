import {Routes} from "@angular/router";
import {ActivityListComponent} from "./ActivityList/activity-list.component";
import {EesActivityListComponent} from "./EESActivityList/ees-activity-list.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {UserFeatureName} from "../Admin/UserFeatures/user-features.service";
import {ActivityRoutePaths} from "./activity-route-paths";
import {MsalGuard} from "@azure/msal-angular";


export const activityRoutes: Routes = [
    {
        path: ActivityRoutePaths.Activities,
        component: ActivityListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Activities",
        },
    },
    {
        path: ActivityRoutePaths.EesActivityList,
        component: EesActivityListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            requiredFeatures: [UserFeatureName.EES],
            title: "EES Activities"
        },
    },
];
