import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import {SavedContactListGroup} from "./saved-contact-list-group";
import {Injectable} from "@angular/core";
import {SavedContactList} from "./saved-contact-list";
import {User} from "../../../Models/User";
import {Contact, SimpleContact} from "../../../Models/Contact";

@Injectable({
    providedIn: 'root',
})
export class ListGroupsService {
    
    constructor(private baseHttpService: BaseHttpService) {
    }

    getGroups(): Observable<SavedContactListGroup[]> {
        return this.baseHttpService.getData(`/list/group`);
    }
    
    getAllGroups(): Observable<SavedContactListGroup[]> {
        return this.baseHttpService.getData(`/list/groups`);
    }

    saveGroup(group: SavedContactListGroup): Observable<SavedContactListGroup> {
        return this.baseHttpService.postData(`/list/group`, group);
    }

    deleteGroup(groupId: string): Observable<void> {
        return this.baseHttpService.deleteData(`/list/group/${groupId}`);
    }
}