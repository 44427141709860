<span class="fa fa-question-circle help-icon"
      [popover]="helpPopover"
      [outsideClick]="true"
      containerClass="help-popover"
      placement="bottom"
></span>

<ng-template #helpPopover>
    <div class="popover-title popover-header">
        <span>Instisel Help</span>
    </div>
    <div class="links">
        <a (click)="getUserGuideInNewTab()">User Guide<span class="fa fa-external-link"></span></a>
        <a (click)="getOutlookGuide()">Outlook Add-in Guide<span class="fa fa-external-link"></span></a>
        <a (click)="goToUserList()">User List</a>
    </div>
</ng-template>