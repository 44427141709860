<ng-select [items]="accounts$ | async"
           [formControl]="accountIdsControl"
           bindLabel="text"
           placeholder="Select an account"
           appendTo="body"
           [multiple]="true"
           [maxSelectedItems]="maxItems"
           [selectOnTab]="true"
           [hideSelected]="true"
           [minTermLength]="2"
           [typeahead]="userInput$">
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <span [ngOptionHighlight]="search">{{item.text}}</span>
    </ng-template>
</ng-select>