import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {TickerService} from "../../Shared/Services/ticker.service";
import {TickerDetail} from "../../../Models/Ticker";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ActivityFeatureManager} from "../../../ActivityFeatureManager";
import * as _ from "lodash";
import * as moment from "moment";
import {DateRange} from "../../Widget/DateRangePicker/date-range";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: "app-ticker-detail",
    templateUrl: "./ticker-detail.component.html",
    styleUrls: ["./ticker-detail.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TickerDetailComponent implements OnInit {

    ticker: string;

    tickerDetails: TickerDetail = {} as TickerDetail;

    backups: UntypedFormControl = this.fb.control([]);
    backupIds: string[] = [];

    categories: string[] = [];

    defaultDateRange: DateRange = { start: moment().add(-18, "month"), end: moment() };

    constructor(
        private fb: UntypedFormBuilder,
        private tickerService: TickerService,
        private route: ActivatedRoute,
    ) {
        this.ticker = this.route.snapshot.paramMap.get('ticker');
    }

    ngOnInit(): void {

        let activityFeatureManager = new ActivityFeatureManager(null, null, null);
        let corpCategories = _.map(activityFeatureManager.getTickerCorpCategories(), c => c.Code);
        let researchCategories = _.map(activityFeatureManager.getTickerResearchCategories(), c => c.Code);
        this.categories = corpCategories.concat(researchCategories);

        this.backups.valueChanges.subscribe(values => {
                this.backupIds = values;
            });

        this.tickerService.getTickerDetails(this.ticker)
            .subscribe(details => {
                this.tickerDetails = details;
            });
    }

    openTickerPopout(): void {
        window.open(`/tickerpopout/${this.ticker}`, "tickerpopout", "width=800,height=400,top=100,left=100");
    }
}
