import {Injectable} from "@angular/core";
import {BaseHttpService} from "./base-http.service";
import * as _ from "lodash";
import {User} from "../Models/user";
import {Observable} from "rxjs";
import {RoleName} from "../../../Models/Role";

// Preventing a circular dependency in report.module.ts by declaring these const paths here, rather than using the ones
// from the report routes file. I'm not immediately sure how to resolve this using the other consts due to this.
// -Phil
class ReportRoutePaths {
    static readonly AccountActivityReport: string = "reports/accountactivity";
    static readonly ChecksReport: string = "reports/checks";
    static readonly CommissionSplitsReport: string = "reports/splitcommission";
    static readonly CommissionTotalsReport: string = "reports/commissiontotal";
    static readonly AccountSummaryByYearReport: string = "reports/accountsummarybyyear";
    static readonly PrintReports: string = "reports/printreports";
    static readonly TradeDetailReport: string = "reports/tradedetail";
    static readonly YearOverYearCommissionReport: string = "reports/yoy";
}

@Injectable({
    providedIn: 'root',
})
export class ReportService {

    constructor(private baseHttp: BaseHttpService) {
    }

    public getLatestReleaseDate(): Observable<string> {
        return this.baseHttp.getData(`/report/reportDate`);
    }

    public getDefaultReportForUser(user: User): Report {
        const reports = this.getReportList(user);
        const defaultReport = this.defaultReportFilter(user)(reports);

        return defaultReport;
    }
    
    public getReportList(user: User): Report[] {
        return _.filter(this.getAllReports(), report => ReportService.userHasAccessToReport(user, report));
        
    }

    private defaultReportFilter(user: User): (reports: Report[]) => Report {
        switch (user.Role.Name) {
            case RoleName.Research:
                return (reports) => this.getDefaultReportByName(reports, ReportName.AccountActivity);
            case RoleName.Sales:
                return (reports) => this.getDefaultReportByName(reports, ReportName.YearOverYear);
            default:
                return (reports) => this.getDefaultReportByName(reports, ReportName.YearOverYear);
        }
    }

    private getDefaultReportByName(reports: Report[], reportName: string): Report {
        const researchDefaultReport = _.find(reports, (report) => report.Name === reportName);
        if (researchDefaultReport) {
            return researchDefaultReport;
        }

        return this.getDefaultReport(reports);
    }

    private getDefaultReport(reports: Report[]): Report {
        return _.first(reports);
    }

    private getAllReports(): Report[] {
        let reports: Report[] = [
            { Name: ReportName.AccountActivity, Link: `${ReportRoutePaths.AccountActivityReport}`, UserFeature: "ViewInstiselWebReportAccountActivity" },
            { Name: ReportName.YearOverYear, Link: `${ReportRoutePaths.YearOverYearCommissionReport}`, UserFeature: "CommissionYOYReport" },
            { Name: ReportName.AccountSummaryByYear, Link: `${ReportRoutePaths.AccountSummaryByYearReport}`, UserFeature: "Commission5YrAccountSummaryReport" },
            { Name: ReportName.SplitCommission, Link: `${ReportRoutePaths.CommissionSplitsReport}`, UserFeature: "CommissionSplitReport" },
            { Name: ReportName.TradeDetail, Link: `${ReportRoutePaths.TradeDetailReport}`, UserFeature: "CommissionTradeDetail" },
            { Name: ReportName.CommissionTotals, Link: `${ReportRoutePaths.CommissionTotalsReport}`, UserFeature: "CanSeeCommisionsReport" },
            { Name: ReportName.Checks, Link: `${ReportRoutePaths.ChecksReport}`, UserFeature: "CheckReport" },
            { Name: ReportName.PrintReports, Link: `${ReportRoutePaths.PrintReports}`, UserFeature: "" }
        ];

        return _.orderBy(reports, report => report.Name);
    }

    private static userHasAccessToReport(user: User, report: Report): boolean {
        return _.isEmpty(report.UserFeature) || _.includes(user.Features, report.UserFeature);
    }
}

export class ReportName {
    static get AccountActivity(): string { return "Account Activity"; }
    static get YearOverYear(): string { return "Year Over Year"; }
    static get AccountSummaryByYear(): string { return "Account Summary By Year"; }
    static get SplitCommission(): string { return "Commission Splits"; }
    static get TradeDetail(): string { return "Trade Detail"; }
    static get CommissionTotals(): string { return "Commission Totals"; }
    static get PrintReports(): string { return "Print Reports"; }
    static get Checks(): string { return "Checks"; }
}

export class Report {
    public Name: string;
    public Link: string;
    public UserFeature: string;
}
