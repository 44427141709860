<div class="page-content no-scroll flex-vbox">

    <app-page-banner 
        pagetitle="Queue MeetMax Events"
        faicon="fa-gear"
        faiconcolor="#708090"
        [subTitleTemplate]="bannerSubTitleTemplate"
        [bannerFormTemplate]="bannerFormTemplate"
    >
    </app-page-banner>

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getPagingDisplayWords() }}</span>
    </ng-template>

    <ng-template #bannerFormTemplate>
        <div class="flex-hbox" style="text-align: left; margin-left: 30px;">
            <div>
                <form class="form-inline" [formGroup]="meetMaxQueueForm">
                    <div class="form-group">
                        <input
                            type="text"
                            class="form-control input-sm"
                            style="width: 200px;"
                            formControlName="meetMaxId"
                            maxlength="100"
                            placeholder="MeetMax ID"
                            (keydown.enter)="!shouldPreventQueueing() && queueMeetMaxId()"
                        />
                    </div>
                    <div class="form-group" appIfUserFeature="EventAdmin">
                        <button
                            type="button"
                            (click)="queueMeetMaxId()"
                            class="btn btn-primary btn-sm"
                            [title]="meetMaxIdTitle()"
                            [disabled]="shouldPreventQueueing()"
                        >
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>

    <div class="page-body flex">
        <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
        >
        </ag-grid-angular>
        
    </div>
</div>
