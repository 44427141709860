<div class="modal-content">
    <div class="modal-body">
        <app-ees-activity-form-base [formControl]="eesActivity" [accountId]="accountId">
        </app-ees-activity-form-base>
        
        <div>
            <label>Created By</label>
            <input class="form-control input-sm" [formControl]="createdBy"/>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox">
            <button class="btn btn-sm btn-primary flex" (click)="save()" [disabled]="!formValid()">Save</button>
            <button class="btn btn-sm btn-danger flex" (click)="delete()" [disabled]="disabled" *ngIf="activityId">Delete</button>
            <button class="btn btn-sm btn-default flex" (click)="close()">Cancel</button>
        </div>
    </div>
</div>