import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {AccountService} from "../../../Shared/Services/account.service";
import {AccountMarket} from "../../../../Models/Market";
import * as _ from "lodash";
import {map} from "rxjs/operators";

@Component({
    selector: "app-market-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(MarketPickerComponent)
})
export class MarketPickerComponent extends BasePickerComponent<AccountMarket> {

    constructor(fb: UntypedFormBuilder, private accountService: AccountService){
        super(fb);

        this.name = "Market(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.accountService.getAccountMarkets().pipe(
            map(markets => _.sortBy(markets, m => m.Name))
        );
    }
}
