import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {AccountVoteService} from "../../Shared/Services/account-vote.service";
import {ToastrService} from "ngx-toastr";
import {AccountService} from "../../Shared/Services/account.service";
import * as moment from "moment";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {AccountVote} from "./account-vote";
import {CheckboxItem} from "../../Widget/CheckboxList/checkbox-item";
import * as _ from "lodash";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-account-vote-form',
    templateUrl: "./account-vote-form.component.html"
})

export class AccountVoteFormComponent implements OnInit {

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    accountVoteId: number;
    accountId: number;
    relatedAccounts$: Observable<CheckboxItem[]>;
    otherAccounts: CheckboxItem[];

    voteForm: UntypedFormGroup = this.fb.group({
        StartDate: this.fb.control('', [Validators.required]),
        EndDate: this.fb.control('', [Validators.required]),
        US: this.fb.control({vote: null, type: ''}),
        Global: this.fb.control({vote: null, type: ''}),
        Sales: this.fb.control({vote: null, type: ''}),
        Research: this.fb.control({vote: null, type: ''}),
        Corp: this.fb.control({vote: null, type: ''}),
        Trader: this.fb.control({vote: null, type: ''}),
        Comment: this.fb.control(''),
        RelatedAccounts: this.fb.control([]),
        OtherAccounts: this.fb.control([]),
        ExistingAttachments: this.fb.control([]),
        NewAttachments: this.fb.control([])
    });

    constructor(private fb: UntypedFormBuilder,
                private accountService: AccountService,
                private accountVoteService: AccountVoteService,
                private modalService: BsModalService,
                private modalRef: BsModalRef,
                private toastr: ToastrService) { }

    ngOnInit(): void {
        this.voteForm.patchValue({ RelatedAccounts: [this.accountId.toString()] });

        this.relatedAccounts$ = this.accountService.getAccountSubAccounts(this.accountId).pipe(
            map(subAccounts => _.map(subAccounts, sa => {
                return {
                    value: sa.Id.toString(),
                    label: sa.Name + ', ' + sa.City,
                    disabled: sa.Id === this.accountId
                } as CheckboxItem; })
            )
        );

        if (this.accountVoteId) {
            let accountVote$ = this.accountVoteService.getById(this.accountVoteId);

            combineLatest([this.relatedAccounts$, accountVote$]).subscribe(([relatedAccounts, accountVote]) => {
                let relatedAccountIds = _.map(relatedAccounts, a => +a.value);
                let otherAccounts$ = _.chain(accountVote.AccountIds)
                    .filter(id => !_.includes(relatedAccountIds, id))
                    .map(id => this.accountService.getAccountById(id))
                    .value();

                combineLatest(otherAccounts$).subscribe(otherAccounts => {
                    this.otherAccounts = otherAccounts.map(oa => {
                        return {
                            value: oa.Id.toString(),
                            label: oa.Name + ', ' + oa.City,
                            disabled: oa.Id === this.accountId
                        } as CheckboxItem
                    });

                    this.voteForm.patchValue({
                        OtherAccounts: _.map(this.otherAccounts, oa => oa.value),
                    });
                });

                this.voteForm.patchValue({
                    StartDate: moment(accountVote.StartDate).format("YYYY-MM-DD"),
                    EndDate: moment(accountVote.EndDate).format("YYYY-MM-DD"),
                    US: {vote: accountVote.USVote, type: accountVote.USVoteType},
                    Global: {vote: accountVote.GlobalVote, type: accountVote.GlobalVoteType},
                    Sales: {vote: accountVote.SalesVote, type: accountVote.SalesVoteType},
                    Research: {vote: accountVote.ResearchVote, type: accountVote.ResearchVoteType},
                    Corp: {vote: accountVote.CorpAccessVote, type: accountVote.CorpAccessVoteType},
                    Trader: {vote: accountVote.TraderVote, type: accountVote.TraderVoteType},
                    Comment: accountVote.VoteComment,
                    RelatedAccounts: _.chain(relatedAccountIds).filter(ra => accountVote.AccountIds.includes(ra)).map(ra => ra.toString()).value(),
                    ExistingAttachments: accountVote.Attachments,
                });
            })
        }
    }

    save(): void {
        if (this.voteForm.valid) {
            let updatedVoteForm = this.voteForm.getRawValue();
            let accountVote = {
                Id: this.accountVoteId,
                StartDate: updatedVoteForm.StartDate,
                EndDate: updatedVoteForm.EndDate,
                USVote: updatedVoteForm.US.vote,
                USVoteType: updatedVoteForm.US.type,
                GlobalVote: updatedVoteForm.Global.vote,
                GlobalVoteType: updatedVoteForm.Global.type,
                SalesVote: updatedVoteForm.Sales.vote,
                SalesVoteType: updatedVoteForm.Sales.type,
                ResearchVote: updatedVoteForm.Research.vote,
                ResearchVoteType: updatedVoteForm.Research.type,
                CorpAccessVote: updatedVoteForm.Corp.vote,
                CorpAccessVoteType: updatedVoteForm.Corp.type,
                TraderVote: updatedVoteForm.Trader.vote,
                TraderVoteType: updatedVoteForm.Trader.type,
                VoteComment: updatedVoteForm.Comment,
                AccountIds: updatedVoteForm.RelatedAccounts.concat(updatedVoteForm.OtherAccounts).map(account => +account),
                Attachments: updatedVoteForm.NewAttachments
                    .map(na => { return { Id: this.accountId, AccountVoteId: this.accountVoteId, Filename: na.name, IsDeleted: false } })
                    .concat(updatedVoteForm.ExistingAttachments)
            } as AccountVote;

            if(this.accountVoteId) {
                this.accountVoteService.updateAccountVote(accountVote).subscribe(() => {
                    combineLatest(this.uploadAttachments(this.accountVoteId)).subscribe().add(() => {
                        this.toastr.success("Account Vote Saved");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
                });
            } else {
                this.accountVoteService.createAccountVote(accountVote).subscribe(updatedAccountVote => {
                    combineLatest(this.uploadAttachments(updatedAccountVote.Id)).subscribe().add(() => {
                        this.toastr.success("Account Vote Saved");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
                });
            }
        }
    }

    uploadAttachments(accountVoteId: number): Observable<any>[] {
        return _.map(this.voteForm.controls['NewAttachments'].value, na => {
            let formData = new FormData();
            formData.append('file', na, na.name);
            return this.accountVoteService.uploadAttachment(accountVoteId, formData);
        });
    }

    removeNew(filename: string): void {
        this.voteForm.controls['NewAttachments'].patchValue(_.filter(this.voteForm.controls['NewAttachments'].value, na => na.name !== filename));
    }

    removeExisting(id: number): void {
        let attachment = _.find(this.voteForm.controls['ExistingAttachments'].value, ea => ea.Id === id);
        attachment.IsDeleted = true;
    }

    delete(): void {
        const initialState = {
            message: 'Are you sure you want to delete this Vote?',
        };

        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action.subscribe(isConfirmed => {
            if (isConfirmed) {
                this.accountVoteService.deleteAccountVote(this.accountVoteId)
                    .subscribe(() => {
                        this.toastr.success("Vote Deleted");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            }
        });
    }

    cancel(): void {
        this.modalRef.hide();
    }

    onToggle($event: any, accountType: string): void {
        if(accountType === 'related') {
            this.voteForm.patchValue({ RelatedAccounts: $event });
        } else {
            this.voteForm.patchValue({ OtherAccounts: $event });
        }
    }
}
