import {Injectable} from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class CSVService {
    private columnDelimiter = ",";
    private lineDelimiter = "\r\n";

    exportToCSV<T>(fileName: string, columns: CSVColumn<T>[], data: T[]): void {
        let csvData = this.convertDataToCSV(columns, data);
        
        if (!csvData) return;
        csvData = "data:text/csv;charset=utf-8," + csvData;

        const encodedExportData = encodeURI(csvData);
        const link = document.createElement("a");
        link.setAttribute("display", "none");
        link.setAttribute("href", encodedExportData);
        link.setAttribute("download", fileName);
        link.click();
        link.remove();
    }

    private convertDataToCSV<T>(columns: CSVColumn<T>[], data: T[]): string {
        if (columns?.length === 0) return;
        
        let headers = columns.map(c => c.headerName).join(this.columnDelimiter);
        let rows = "";
        for (let datum of data) {
            let counter = 0;
            for (let {field} of columns) {
                if (counter > 0) {
                    rows += this.columnDelimiter;
                }
                rows += datum[field] ? `"${datum[field]}"` : "";
                counter++;
            }
            rows += this.lineDelimiter;
        }
        return headers + this.lineDelimiter + rows;
    }
}

export interface CSVColumn<T> {
    headerName: string;
    field: keyof T;
}