import { NgModule } from "@angular/core";
import {ShellComponent} from "./ShellComponent/shell.component";
import {CommonModule} from "@angular/common";
import {GlobalSearchComponent} from "./GlobalSearch/global-search.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {WidgetModule} from "../Widget/widget.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NotificationsComponent} from "./Notifications/notifications.component";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TabsModule} from "ngx-bootstrap/tabs";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {ManageNotificationsDocumentsModalComponent} from "./Manage/manage-notifications-documents-modal.component";
import {HelpComponent} from "./Help/help.component";
import {UserComponent} from "./User/user.component";
import {SharedEmailsNotificationsComponent} from "./SharedEmailsNotifications/shared-emails-notifications.component";
import {RouterModule} from "@angular/router";
import {NetworkSwapComponent} from "./NetworkSwap/network-swap.component";
import {NetworkSwapFormComponent} from "./NetworkSwap/network-swap-form.component";

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        TooltipModule,
        WidgetModule,
        ReactiveFormsModule,
        PopoverModule.forRoot(),
        TabsModule,
        RouterModule,
        ButtonsModule
    ],
    declarations: [
        ShellComponent,
        GlobalSearchComponent,
        NotificationsComponent,
        ManageNotificationsDocumentsModalComponent,
        HelpComponent,
        UserComponent,
        SharedEmailsNotificationsComponent,
        NetworkSwapComponent,
        NetworkSwapFormComponent
    ],
    providers: [

    ],
    exports: [
        GlobalSearchComponent,
    ]
})
export class ShellModule {
    constructor(){
        console.log("registering the ShellModule");
    }
}
