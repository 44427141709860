import {Component} from '@angular/core';
import {IHeaderAngularComp} from "ag-grid-angular-legacy";
import {IHeaderParams} from "ag-grid-community";

@Component({
    selector: 'app-icon-header',
    template: `
        <div (click)="onSort()" class="ag-cell-label-container" style="width: 100%">
            <span class="ag-header-icon ag-header-label-icon">
               <span class="ag-icon" [ngClass]="{'ag-icon-asc': sort === 'asc', 'ag-icon-desc': sort === 'desc'}"></span>
            </span>
            <span [ngClass]="params.iconClass" style="font-size: 16px; font-weight: bold; color: #333; margin-left: 3.5px"></span>
        </div>
    `
})
export class IconHeaderRendererComponent implements IHeaderAngularComp {
    params: IHeaderParams & {iconClass: string};
    sort: string;

    agInit(params: IHeaderParams & {iconClass: string}): void {
        this.params = params;
        params.column.addEventListener("sortChanged", () => {
            this.sort = this.params.column.getSort();
        });
    }

    onSort(): void {
        if (this.params.column.getColDef().sortable) {
            this.params.progressSort();
        }
    }

    refresh(): boolean {
        return false;
    }
}