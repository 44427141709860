import {Component, OnInit} from "@angular/core";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {CorpTravelAdminRoutePaths} from "../corp-travel-admin-route-paths";

@Component({
    selector: "app-page-dashboard",
    templateUrl: "./corptravel-admin-dashboard.component.html"
})
export class CorpTravelAdminDashboardComponent implements OnInit{

    corpTravelCityAdminUserFeature = UserFeatureName.CorpTravelCityAdmin;

    hotelsProviderListPath = CorpTravelAdminRoutePaths.HotelsProviderList;
    restaurantsProviderListPath = CorpTravelAdminRoutePaths.RestaurantsProviderList;
    transportationProviderListPath = CorpTravelAdminRoutePaths.TransportationProviderList;
    airportListPath = CorpTravelAdminRoutePaths.AirportList;
    travelCityListPath = CorpTravelAdminRoutePaths.TravelCityList;
    bairdRepAnalystListPath = CorpTravelAdminRoutePaths.BairdRepAnalystList;

    constructor() { }

    ngOnInit(): void { }
}
