import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {of} from "rxjs";
import {BairdContact} from "../../../Contact/InterestList/baird-contact";

@Component({
    selector: "app-baird-contact-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(BairdContactPickerComponent)
})
export class BairdContactPickerComponent extends BasePickerComponent<any> {

    constructor(fb: UntypedFormBuilder){
        super(fb);

        this.name = "Baird Contact(s) Excluded";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = false;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = of(BairdContact)
    }
}
