import {Routes} from "@angular/router";
import {CallListComponent} from "./CallList/call-list.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {MsalGuard} from "@azure/msal-angular";
import {InterestRoutePaths} from "./interest-route-paths";

export const interestRoutes: Routes = [
    {
        path: InterestRoutePaths.CallList,
        component: CallListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "Call List"
        },
    },
];
