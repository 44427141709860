import {Role} from "./Role";
import {Team} from "./Team";
import * as _ from "lodash";

export class SimpleUser{
    public Id: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public TeamId: number;
}

export class User {
    public Id: string;
    public WindowsId: string;
    public FirstName: string;
    public LastName: string;
    public Email: string;
    public Role: Role;
    public Team: Team;
    public Coverage: string;
    public Features: string[];
    public UserGroupId: number;
    public ComdolGroupId: number;
    public Domain: string;
    public ComdolRole: string;
    public Trader: boolean;
    public Broker: boolean;
    public DefaultAnalyst: boolean;
    public EmailFromAnalyst: boolean;
    public IsActive: boolean;
    public Phone: string;
    public PhoneExtension: string;
    public Notes: string;
    public FidessaIds: string[];
    public BetaRepCode: string;
    public IsCorpTravelUser: boolean;
    public Title: string;
    public MobilePhone: string;
    
    public static isMyAccount(user: User, accountTags: string[]): boolean {
        return !(_.isEqual(user.Coverage, "My Accounts") && !_.includes(accountTags, "My"));
    }
}

export class UserFeatureName {
    static get CanViewEventList(): string { return "CanViewEventList"; }
    static get MailMerge(): string { return "MailMerge"; }
    static get SharedEmails(): string { return "SharedEmails"; }
    static get QueryBuilder(): string { return "QueryBuilder"; }
    static get IsAdmin(): string { return "IsAdmin"; }
    static get InstiselUpdate(): string { return "InstiselUpdate"; }
    static get CommissionAccountNet(): string { return "CommissionAccountNet"; }
    static get ClickToDial(): string { return "ClickToDial"; }
    static get ForceAccountDetailToSalesView(): string { return "ForceAccountDetailToSalesView"; }
}