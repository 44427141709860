import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import {AccountMonthlyCommission} from "../../../Models/AccountMonthlyCommission";
import {CommissionCategory} from "../../../Services/CommissionCategoryService";
import * as moment from "moment";
import {BrokerSplitChange, SplitCommissions} from "../../../Models/SplitCommissions";
import {TradingAccountCommissionDetail} from "../../../Models/TradingAccountCommissionDetail";

@Injectable({
    providedIn: 'root',
})
export class CommissionService {

    constructor(private baseHttp: BaseHttpService) { }

    getCommissionCategories(): CommissionCategory[] {
        return [
            { code: "P", description: "High Touch" },
            { code: "E", description: "ETS" },
            { code: "O", description: "Options" },
            { code: "C", description: "Checks" },
            { code: "S", description: "Syndicate" }
        ] as CommissionCategory[];
    }
    
    getAccountMonthlyCommissions(years: number[], accountId: number, includeParent: boolean): Observable<AccountMonthlyCommission[]> {
        const params = {
            years: years.map(String),
            includeparent: includeParent.toString()
        };

        return this.baseHttp.getDataWithConfig(`/account/id/${accountId}/commissions`, {params: params});
        
        
    }
    
    getAccountCommissionTotals(startDate: moment.Moment, endDate: moment.Moment): Observable<any[]> {
        const params = {startDate: startDate.format("YYYY-MM-DD"), endDate: endDate.format("YYYY-MM-DD")};
        return this.baseHttp.getDataWithConfig(`/account/commissiontotals`, {params: params});
    }
    
    getMonthlyCommissions(years: number[]): Observable<AccountMonthlyCommission[]> {
        return this.baseHttp.getDataWithConfig(`/account/commissions/yearly`, { params: { years: years.map(String) }});
    }

    getTradeDetailsByRange(startDate: moment.Moment, endDate: moment.Moment, parentComdolId: string): Observable<TradingAccountCommissionDetail[]> {
        const params = {
            startDate: startDate.format("YYYY-MM-DD"), 
            endDate: endDate.format("YYYY-MM-DD"),
            parentComdolId: parentComdolId,
        };
        return this.baseHttp.getDataWithConfig(`/account/commissions/detail`, {params: params});
    }
    
    getBrokerSplits(year: string): Observable<BrokerSplitChange[]> {
        return this.baseHttp.getData(`/commission/splits/sales/${year}`);
    }
    
    getCommissionSplits(startDate: moment.Moment, endDate: moment.Moment): Observable<SplitCommissions[]> {
        return this.baseHttp.getData(`/commission/sales/${startDate.format("YYYY-MM-DD")}/${endDate.format("YYYY-MM-DD")}`);
    }
    
    getAllCommissionsYearOverYear(startDate: moment.Moment, endDate: moment.Moment): Observable<AccountMonthlyCommission[]> {
        return this.baseHttp.getData(`/account/yearoveryearcommissions/all/${startDate.format("YYYY-MM-DD")}/${endDate.format("YYYY-MM-DD")}`);
    }

    getCommissionsYearOverYear(startDate: moment.Moment, endDate: moment.Moment): Observable<AccountMonthlyCommission[]> {
        return this.baseHttp.getData(`/account/yearoveryearcommissions/${startDate.format("YYYY-MM-DD")}/${endDate.format("YYYY-MM-DD")}`);
    }

    getAccountPnL(years: number[], accountId: number, includeParent: boolean): Observable<AccountMonthlyCommission[]> {
        const params = {
            years: years.map(y => y.toString()),
            includeparent: includeParent.toString()
        };

        return this.baseHttp.getDataWithConfig(`/account/id/${accountId}/pnl`, {params: params});
    }

}