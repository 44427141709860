import {Injectable} from "@angular/core";
import {ConfigService} from "./config.service";
import {UserService} from "./user.service";
import {AnalyticsService} from "./analytics.service";
import {UserLocaleService} from "./user-locale.service";

@Injectable({
    providedIn: 'root',
})
export class AppInitializerService {
    constructor(private configService: ConfigService,
                private userService: UserService,
                private userLocaleService: UserLocaleService,
                private analyticsService: AnalyticsService,) {
    }

    initialize() {
        this.configService.loadAppConfig();

        this.configService.setApiIsExternal(false);

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.userLocaleService.initializeLocale(user);
                this.analyticsService.configureAnalytics(user);
            });
    }
}