<div
    class="modal-body"
    [formGroup]="form"
>
    <div class="form-group flex-hbox flex-gap">
        <button
            class="btn btn-sm btn-primary flex"
            (click)="sendEmail()"
            [disabled]="form.invalid || sendingEmail || formLoading"
        >
            Send Email
        </button>

        <button
            class="btn btn-sm btn-default flex"
            (click)="dontSendEmail()"
        >
            Don't Send Email
        </button>
    </div>
    
    <div class="form-group">
        <label>
            To
        </label>

        <app-multi-user-select
            formControlName="toUserIds"
            activeUsersOnly="true"
            emailUsersOnly="true"
            [minUsers]="1"
        >
        </app-multi-user-select>
    </div>
    
    <div class="form-group">
        <label>
            Cc
        </label>

        <app-multi-user-select 
            formControlName="ccUserIds"
            activeUsersOnly="true"
            emailUsersOnly="true"
        >
        </app-multi-user-select>
    </div>
    
    <div
        class="form-group"
        [innerHTML]="previewBody"
    >
    </div>
</div>