import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {EventService} from "../../../Shared/Services/event.service";
import * as moment from "moment";
import {ActivatedRoute} from "@angular/router";
import {combineLatest} from "rxjs";
import {TimeZoneService} from "../../../Shared/Services/time-zone.service";

@Component({
    selector: "app-request-attendee-form",
    templateUrl: "./request-attendee-form.component.html",
    styleUrls: ["./request-attendee-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class RequestAttendeeFormComponent implements OnInit {

    @Input()
    meetingId: number;

    @Input()
    requestId: number;

    @Input()
    contactId: number;

    requestEventMeetingsNoReplay: MeetingRowAttendance[];
    requestEventMeetingsReplay: MeetingRowReplay[];
    requestContact: ContactInfo;

    title: string = "";

    timeZone: string = ""

    formLoading: boolean = false;

    constructor(private modalRef: BsModalRef,
                private eventService: EventService,
                private route: ActivatedRoute,
                private timeZoneService: TimeZoneService,
    ) {
    }

    ngOnInit(): void {
        this.formLoading = true;

        // this.requestId = +this.route.snapshot.queryParamMap.get('requestId') || +this.route.snapshot.queryParamMap.get('reqId');


        let request$ = this.eventService.getRequest(this.requestId)
        let meetings$ = this.eventService.getRequestMeetings(this.requestId);
        let timezones$ = this.timeZoneService.getTimeZones();
        combineLatest([meetings$, request$, timezones$ ])
            .subscribe(([meetings, request, timezones])  => {
                let tz = timezones.find(tz => tz.Id === request.TimeZoneId);
                if (tz){ this.timeZone = `(${tz.Abbreviation})` }

                let contactActivity = meetings.reduce((acc, curr) => {acc.push(...curr.Activities); return acc}, [])
                    .find(a => a.ContactId === this.contactId)

                this.requestContact = {
                            FirstName: contactActivity?.ContactFirstName,
                            LastName: contactActivity?.ContactLastName,
                            Account: contactActivity?.AccountName,
                        } as ContactInfo

                this.requestEventMeetingsNoReplay = meetings
                    .filter(meeting => meeting.Activities.filter(a => a.Status !== "Replay")
                        .map(a => a.ContactId)
                        .includes(this.contactId))
                    .map(meeting => {
                        meeting.MeetingTime = meeting.MeetingDate;
                        let activity = meeting.Activities.filter(a => a.Status !== "Replay").find(x => x.ContactId === this.contactId);
                        return {
                            Date: moment(meeting.MeetingDate).format('MM/DD/YY'),
                            Time: moment(meeting.MeetingTime).format('h:mm A',),
                            Status: activity.Status,
                            Subcategory: activity.SubCategory,
                            MeetingType: activity.MeetingType,
                        } as MeetingRowAttendance
                })

                this.requestEventMeetingsReplay = meetings
                    .filter(meeting => meeting.Activities.filter(a => a.Status === "Replay")
                        .map(a => a.ContactId)
                        .includes(this.contactId))
                    .map(meeting => {
                        meeting.MeetingTime = meeting.MeetingDate;
                        let activity = meeting.Activities.find(x => x.ContactId === this.contactId);
                        return {
                            Date: moment(meeting.MeetingDate).format('MM/DD/YY'),
                            Time: moment(meeting.MeetingTime).format('h:mm A',),
                            ReplayDate: moment(activity.CallDate).format('MM/DD/YY'),
                            Subcategory: activity.SubCategory,
                            MeetingType: activity.MeetingType,
                        } as MeetingRowReplay
                    })
        });
    }

    close() {
        this.modalRef.hide();
    }
}

interface MeetingRowAttendance {
    Date: string;
    Time: string;
    Status: string;
    Subcategory: string;
    MeetingType: string;
}

interface MeetingRowReplay {
    Date: string;
    Time: string;
    ReplayDate: string;
    Subcategory: string;
    MeetingType: string;
}

interface ContactInfo {
    FirstName: string;
    LastName: string;
    Account: string;
}
