<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
        <app-export-contacts
                [appRequiresFeature]="'ExportContactsExchange'"
                class="pull-right"
                [contacts]="rowData"
                [includeDoNotContactContacts]="includeDoNotContactContacts">
        </app-export-contacts>
    </div>
    <form [formGroup]="listForm">
        <div class="modal-body">
            <tabset>
                <tab heading="Basic Info" (selectTab)="selectTab($event)">
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Name</label>
                            <input class="form-control input-sm" type="text" formControlName="Name"/>
                        </div>
                    </div>
                    <div class="flex-hbox">
                        <div class="form-group flex">
                            <label>Description</label>
                            <input class="form-control input-sm" type="text" formControlName="Description"/>
                        </div>
                    </div>
                    <div class="flex-hbox flex-gap-lg">
                        <div class="form-group flex">
                            <label>Permission</label>
                            <select class="form-control input-sm" formControlName="Permission">
                                <option *ngFor='let permission of permissions' [value]="permission.Id">{{ permission.Name }}</option>
                            </select>
                        </div>
                        <div class="form-group flex" *ngIf="!isPrivatePermission">
                            <label>Group</label>
                            <select class="form-control input-sm" formControlName="Group">
                                <option *ngFor='let group of groups$ | async' [value]="group.Id">{{ group.Name }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="flex-hbox flex-gap-lg">
                        <div class="form-group flex">
                            <label>Created By</label>
                            <input class="form-control input-sm" type="text" formControlName="CreatedBy"/>
                        </div>
                        <div class="form-group flex">
                            <label>Modified By</label>
                            <input class="form-control input-sm" type="text" formControlName="ModifiedBy"/>
                        </div>
                    </div>
                </tab >
                <tab heading="Contacts" (selectTab)="selectTab($event)" *ngIf="listId">
                    <div class="form-group">
                        <button type="button" class="btn btn-danger btn-xs" [disabled]="!isContactListEditable" (click)="removeSelectedContacts()">Remove Selected</button>
                    </div>
                    <div class="form-group" style="height: 600px;">
                        <ag-grid-angular
                                style="width: 100%; height: 100%;"
                                class="ag-theme-balham"
                                [gridOptions]="gridOptions"
                                [rowData]="rowData"
                                [rowClassRules]="rowClassRules"
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef"
                                (gridReady)="onGridReady($event)"
                        >
                        </ag-grid-angular>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="submit" class="flex btn btn-primary" [disabled]="listForm.invalid || !isContactListEditable" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-danger" *ngIf="canDelete" [disabled]="!isContactListEditable" (click)="deleteList()">Delete</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>
