import {Component, EventEmitter, Output} from "@angular/core";
import {ActiveElement, ChartData, ChartEvent, Chart} from "chart.js";
import {combineLatest, interval, Observable, of} from "rxjs";
import {debounce, distinctUntilChanged, map, startWith, switchMap, tap} from "rxjs/operators";
import * as _ from "lodash";
import {ChartType} from "ag-grid-community";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {TrendingTicker} from "../../../Models/TrendingTicker";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityService} from "../../Shared/Services/activity.service";

@Component({
    selector: "app-trending-ticker-chart",
    templateUrl: "./trending-ticker-chart.component.html"
})
export class TrendingTickerChartComponent extends BaseChartComponent {
    @Output()
    tickerSelected: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    categoriesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

    type: ChartType = "bar";
    stacked: boolean = true;
    trendingTickers: TrendingTicker[];

    hasData: boolean = false;
    isLoading: boolean = true;

    periodHelpInfo = "Current window that is compared to yearly average over that same window."

    period$ = of([
        "2",
        "4"
    ]);

    sortBy$ = of([
        "Ascending",
        "Descending"
    ]);

    categories = ["D", "P", "X"];

    trendingTickerFilters: UntypedFormGroup = this.fb.group({
        period: this.fb.control("2"),
        sortBy: this.fb.control("Descending"),
        tickerCount: this.fb.control(30),
        categories: this.fb.control(this.categories),
        netChange: this.fb.control(3),
    });

    constructor(private activityService: ActivityService,
                private fb: UntypedFormBuilder) {
        super();
    }

    configureChart(): void {
        this.options = {
            scales: {
                percentChangeY: {
                    type: 'linear',
                    position: 'left',
                    title: {
                        display: true,
                        text: 'Percent Change'
                    },
                    suggestedMin: 0,
                    suggestedMax: 2
                },
                countY: {
                    type: 'linear',
                    position: 'right',
                    title: {
                        display: true,
                        text: 'Count'
                    },
                    suggestedMin: 0,
                    suggestedMax: 2
                },
                x: {
                    stacked: true
                }
            },
            plugins: {
                tooltip: {
                    mode: "index",
                    intersect: false
                }
            },
            onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart): void => {
                if (elements.length === 0) {
                    return;
                }
                const label = chart.data.labels[elements[0].index] as string;
                if (label) {
                    this.tickerSelected.emit(label);
                }
            }
        };

        this.configureData()
            .subscribe(chartData => {
                if (this.hasData) {
                    if (this.chart) {
                        this.updateChart(chartData);
                    } else {
                        this.data = chartData;
                        this.runChart();
                    }
                } else {
                    this.chart?.destroy();
                    this.chart = null;
                    this.tickerSelected.emit(null);
                }
                this.isLoading = false;
            });
    }

    configureData(): Observable<ChartData> {
        const categories$ = this.trendingTickerFilters.get('categories').valueChanges.pipe(
            startWith(this.trendingTickerFilters.get('categories').value),
            tap(categories => {
                this.categoriesSelected.emit(categories);
            })
        );

        const trendingTickers$ = combineLatest([
            this.trendingTickerFilters.get('period').valueChanges.pipe(
                startWith(this.trendingTickerFilters.get('period').value)
            ),
            categories$
        ]).pipe(
            tap(() => {
                this.tickerSelected.emit(null);
                this.chart?.destroy();
                this.chart = null;
                this.isLoading = true;
            }),
            debounce(() => interval(500)),
            switchMap(([period, categories]) =>
                this.activityService.getTrendingTickers(categories, period * 7))
        )

        const filters$ = combineLatest([
            this.trendingTickerFilters.get('sortBy').valueChanges.pipe(startWith(this.trendingTickerFilters.get('sortBy').value)),
            this.trendingTickerFilters.get('tickerCount').valueChanges.pipe(
                startWith(this.trendingTickerFilters.get('tickerCount').value),
                distinctUntilChanged()
            ),
            this.trendingTickerFilters.get('netChange').valueChanges.pipe(
                startWith(this.trendingTickerFilters.get('netChange').value),
                distinctUntilChanged()
            )
        ]).pipe(
            debounce(() => interval(500))
        );

        return combineLatest([trendingTickers$, filters$]).pipe(
            map(([tickers, [sortBy, tickerCount, netChange]]) => _.chain(tickers)
                .filter(t => Math.abs(t.NetChange) >= netChange)
                .orderBy("PercentDiff", sortBy === "Ascending" ? ["asc"] : ["desc"])
                .take(tickerCount)
                .value()
            ),
            tap(tickers => {
                this.hasData = tickers.length > 0;
                this.tickerSelected.emit(tickers[0]?.Ticker);
            }),
            map(tickers => {
                const data = tickers.reduce((acc, curr) => {
                    acc.PercentDiff.push(curr.PercentDiff);
                    acc.NetChange.push(curr.NetChange);
                    acc.PeriodAverage.push(curr.PeriodAverage);
                    acc.LastPeriod.push(curr.LastPeriod);
                    return acc;
                }, {PercentDiff: [], NetChange: [], PeriodAverage: [], LastPeriod: []});
                return {
                    labels: tickers.map(t => t.Ticker),
                    datasets: Object.entries(data).map(([key, val]) => {
                        const isPercentDiff = key === "PercentDiff";
                        return {
                            label: key,
                            data: val,
                            fill: false,
                            borderWidth: isPercentDiff ? 1 : 3,
                            yAxisID: isPercentDiff ? "percentChangeY" : "countY",
                            type: isPercentDiff ? "line" : "bar",
                            pointRadius: isPercentDiff ? 0 : undefined,
                            lineTension: isPercentDiff ? 0 : undefined
                        }
                    })
                };
            })
        );
    }
}
