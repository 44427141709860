<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <div class="flex-hbox flex-gap" style="max-width: 300px">
            <div *ngIf="eventId" appIfUserFeature="EventAdmin">
                <button type="button" (click)="edit()">Edit</button>
            </div>
            <div>
                <div>Dates: {{ getEventDateRange() }}</div>
                <div>Location: {{ event && event.Location }}</div>
                <div>Notes: {{ event && event.Notes }}</div>        
            </div>
        </div>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <div class="flex-hbox">
            <div *ngIf="event && event.Presenters" [class.expanded]="!presenterExpand" class="text-left" style="padding-left: 50px;">
                <strong *ngIf="event.Presenters.length > 0">
                    Company Management
                    <button class="btn btn-xs btn-default" *ngIf="event.Presenters && event.Presenters.length > 3" (click)="showMorePresenters()">
                        {{presenterExpand ? 'Show Less' : 'Show More'}}
                    </button>
                </strong>
                <div *ngFor="let presenter of event.Presenters">
                    {{presenter?.FirstName}}
                    {{presenter?.LastName}},
                    <span style="font-size: 11px; font-style: italic;">{{presenter?.Title}}</span>
                </div>
            </div>
            <div class="flex">
                <form class="form-inline" [formGroup]="attendeeSearchForm">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="input-group-btn">
                                <div class="btn btn-primary btn-sm btn-toggle"
                                     btnCheckbox
                                     formControlName="myAccounts">
                                    <span class="fa fa-sitemap"></span>
                                </div>
                            </div>
                            <input type="search"
                                   class="form-control input-sm"
                                   style="width: 250px;"
                                   formControlName="search"
                                   placeholder="Search Name, Account" />
                        </div>
                    </div>
                    <div class="form-group">
                        <app-string-picker formControlName="broker" name="Broker" [items$]="brokers$" placeHolder="Broker" [alignRight]="true"></app-string-picker>
                    </div>
                    <div class="form-group">
                        <app-string-picker formControlName="status" name="Status" [items$]="statuses$" placeHolder="Status" [alignRight]="true"></app-string-picker>
                    </div>
                    <div class="form-group">
                        <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>
    
    <app-page-banner 
            [pagetitle]="(event && event.Name || '')"
            faicon="fa-calendar-o" 
            faiconcolor="#ff8080"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [frameworkComponents]="frameworkComponents"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>
