<ng-template #inactiveContactTemplate>
    <div class="modal-body">
        Contact has been deleted or moved to an account you do not have access to view.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" style="width: 100%;" (click)="modalRef.hide()">Ok</button>
    </div>
</ng-template>


<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
        <div *ngIf="notShowingAllRecords" class="small">
            <em style="font-weight: bold; color: red">(Not showing all records. Add filters.)</em>
        </div>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <div style="display: inline-block;">
            <form class="form-inline" [formGroup]="activitySearchForm">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="showAccountsWithGrid">
                                Grid
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="showFavorites">
                                <span class="fa fa-star"></span>
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="showMyAccounts">
                                <span class="fa fa-sitemap"></span>
                            </div>
                        </div>
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 145px;"
                               formControlName="accountSearchTerm"
                               placeholder="Search Account" />
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 145px;"
                               formControlName="commentSearchTerm"
                               placeholder="Search Comment" />
                    </div>
                </div>
                <div class="form-group">
                    <app-string-array-input formControlName="searchTickers" [placeholder]="'Tickers'" style="width: 145px;"></app-string-array-input>
                </div>
                <div class="form-group">
                    <app-date-range-picker formControlName="dateRange" [options]="datePickerOptions"></app-date-range-picker>
                </div>
                <div class="form-group">
                    <app-activity-category-picker formControlName="categories" [searchable]="false"></app-activity-category-picker>
                </div>
                <div class="form-group">
                    <app-tier-picker formControlName="tiers"></app-tier-picker>
                </div>
                <div class="form-group">
                    <app-caller-picker formControlName="callers"></app-caller-picker>
                </div>
                <div class="form-group">
                    <app-team-picker formControlName="callerTeams" [showResearchOnly]="true"></app-team-picker>
                </div>
                <div class="form-group">
                    <div class="btn-group">
                        <div class="btn btn-primary btn-sm btn-toggle" formControlName="myActivities" btnCheckbox>My</div>
                    </div>
                </div>
                <div class="form-group">
                    <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
                </div>
                <div class="form-group">
                    <div class="btn-group" dropdown>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" aria-controls="dropdown-basic">
                            <span class="fa fa-gear"></span> <span class="caret" style="margin-left: 2px"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" [class.disabled]="!canExport()">
                                <a class="dropdown-item" [class.disabled]="!canExport()" (click)="exportExcel()">Export to Excel</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
            <span class="pull-left small" *ngIf="!hasEnoughFilters(activitySearchForm.getRawValue())">
                Please use filters to narrow results
            </span>
            <div *ngIf="additionalFiltersNotShown && additionalFiltersNotShown.length > 0">
                <span class="info">Additional Filters:</span>
                <app-query-groups-popover [queryGroups]="additionalFiltersNotShown"></app-query-groups-popover>
            </div>
        </div>
    </ng-template>
    
    <app-page-banner
            pagetitle="Activities" 
            faicon="fa-comments" 
            faiconcolor="brown"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [rowClassRules]="rowClassRules"
                (gridReady)="onGridReady($event)"
                (rowClicked)="rowClicked($event)"
        >
        </ag-grid-angular>
        
    </div>
</div>
