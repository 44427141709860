import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import {QueryGroup} from "../../../Services/QueryService";

@Injectable({
    providedIn: 'root',
})
export class ContactResearchService {

    constructor(private baseHttp: BaseHttpService) { }
    
    getContactResearchByQuery(query: QueryGroup[]): Observable<ContactResearchDto[]> {
        const params = {
            QueryGroups: query
        };
        return this.baseHttp.postData(`/contactresearch/query`, params);
    }
}

export interface ContactResearchDto {
    ContactId: number;
    IsBlueMatrix: boolean;
    IsResearchOnline: boolean;
    IsMarketingEmail: boolean;
    IsResearchEmail: boolean;
    IsResearchVoicemail: boolean;
    VendorEntitlements: string[];
}
