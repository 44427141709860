import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {map} from "rxjs/operators";
import {RevenueTarget, RevenueTargetService} from "../../Shared/Services/revenue-target.service";
import {currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {RevenueTargetFormComponent} from "./revenue-target-form.component";
import {AccountService} from "../../Shared/Services/account.service";

@Component({
    selector: "app-tab-revenue-target-list",
    template: `
        <div class="flex flex-vbox">
            <div style="margin-bottom: 5px;" class="flex-hbox flex-gap" *ngIf="isEditable">
                <button type="button" class="btn btn-sm btn-default" (click)="create()"><span class="fa fa-plus"></span></button>
                <div class="flex text-right">
                    * current account
                </div>
            </div>

            <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham revenue-target-list"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClick($event)"
            >
            </ag-grid-angular>
        </div>
    `,
    styleUrls: ["./tab-revenue-target-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabRevenueTargetListComponent extends BaseGridComponent<RevenueTargetRow> implements OnInit {

    @Input()
    accountId: number;

    @Input()
    commissionDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    isEditable: boolean = false;

    columnDefs : ColDef[] = [
        {
            field: "Year",
            width: 100,
            sort: "desc",
            rowGroup: true,
            hide: true,
        },
        {
            field: "ComdolId",
            width: 120,
            cellRenderer: params => params.data ? params.data.ComdolId + ( params.data.IsMine ? ' *' : '' ) : '',
        },
        {
            field: "City",
            width: 120,
        },
        {
            field: "RevenueTarget",
            width: 150,
            cellRenderer: currencyRenderer,
            headerClass: "header-right",
            type: "rightAligned",
            aggFunc: "sum",
        },
        {
            field: "Note",
            flex: 1,
        },
    ];

    constructor(
        private modalService: BsModalService,
        private accountService: AccountService,
        private revenueTargetService: RevenueTargetService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.overlayNoRowsTemplate = `<span>No Revenue Targets</span>`;
        this.gridOptions.groupDefaultExpanded = 1;
        this.gridOptions.autoGroupColumnDef = {
            headerName: 'Year',
            width: 100,
            cellRendererParams: {
                suppressCount: true,
            },
        };

        this.accountService.getAccountById(this.accountId)
            .subscribe(account => {
                this.isEditable = !!account.ComdolId?.trim();
            });
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.setRowData();
    }

    setRowData() {
        this.rowData$ = this.revenueTargetService.getByAccount(this.accountId).pipe(
            map(targets => {

                return targets.map(target => {
                    return {
                        Id: target.Id,
                        Year: target.Year,
                        ComdolId: target.ComdolId,
                        IsMine: target.IsMine,
                        RevenueTarget: target.Target,
                        Note: target.Note,
                        City: target.City,
                    } as RevenueTargetRow
                });
            })
        )
    }

    onRowClick($event: RowClickedEvent<RevenueTargetRow>) {
        if ($event.data?.Id) {
            this.showRevenueTargetForm($event);
        }
    }

    create() {
        let modalRef = this.modalService.show(RevenueTargetFormComponent, {
            initialState: {
                revenueTargetId: 0,
                accountId: this.accountId,
            },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        modalRef.content.dataUpdated.subscribe(isUpdated => {
            if (isUpdated) {
                this.setRowData();
                this.commissionDataChanged.emit(true);
            }
        });
    }

    private showRevenueTargetForm($event: RowClickedEvent<RevenueTargetRow>) {
        let modalRef = this.modalService.show(RevenueTargetFormComponent, {
            initialState: {
                revenueTargetId: $event.data.Id,
                accountId: this.accountId,
            },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        modalRef.content.dataUpdated.subscribe(isUpdated => {
            if (isUpdated) {
                this.setRowData();
                this.commissionDataChanged.emit(true);
            }
        });
    }
}

interface RevenueTargetRow {
    Id: number;
    Year: number;
    ComdolId: string;
    IsMine: boolean;
    RevenueTarget: number;
    Note: string;
    City: string;
}
