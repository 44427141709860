<form class="form-inline summary-filters" [formGroup]="regionSummaryOptions">
    <div class="form-group">
        <app-string-picker formControlName="metricType" 
                           [items$]="items"
                           name="Metric Type"
                           [alignRight]="true"
                           [closeOnSelect]="true"
                           [multiple]="false"
                           [showSelectClearAll]="false"
        ></app-string-picker>
    </div>
</form>

<div class="flex summary-grid">
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            (cellClicked)="onCellClicked($event)"
    >
    </ag-grid-angular>
</div>
