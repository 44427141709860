<div class="modal-header">
    Add Tickers
</div>
<div class="modal-body" >
    <div class="form-group flex-hbox flex-gap" [formGroup]="searchForm">
        <app-analyst-picker class="flex" formControlName="analysts" [alignRight]="true"></app-analyst-picker>
        <app-instisel-sector-picker class="flex" formControlName="sectors" [alignRight]="true" [placeHolder]="'Sector(s)'"></app-instisel-sector-picker>
    </div>
    <div style="height: 300px; overflow-y: scroll; border: 1px solid #ccc; border-radius: 4px; padding: 5px;">
        <div>
            <label *ngFor="let ticker of tickers" style="width: 85px; font-weight: normal; font-size: 12px; cursor: pointer;">
                <input type="checkbox" [(ngModel)]="selectedTickers[ticker.Name]"/>
                {{ticker.Name}}
            </label>    
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap">
        <button type="button" class="btn btn-sm btn-primary flex" (click)="save()">Add</button>
        <button type="button" class="btn btn-sm btn-default flex" (click)="cancel()">Cancel</button>
    </div>
</div>