import { ElementRef, forwardRef, OnInit, TemplateRef, ViewChild, Directive } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from "@angular/forms";
import {Observable} from "rxjs";
import * as _ from "lodash";
import {AnalyticsService} from "../../../Shared/Services/analytics.service";

@Directive()
export abstract class BasePickerComponent<T> implements  OnInit, ControlValueAccessor, Validator {
    
    items$: Observable<T[]>;
    
    appendTo: string = null;
    name: string = "item(s)";
    placeHolder: string = "";
    closeOnSelect: boolean = false;
    bindLabel: string;
    bindValue: string;
    searchable: boolean = false;
    customSearchable: boolean = false;
    multiple: boolean = true;
    ngStyle: any;
    maxItems: number = 99999999;
    
    showPermanentPlaceHolder: boolean = false;
    showSelectClearAll: boolean = true;
    showTagCountLabel: boolean = true;

    groupByFn: (item: any) => string;

    headerTemplate: TemplateRef<any>;
    
    onChange = (updatedData: T[]) => {};
    onTouched = () => {};
    
    pickerControl = this.fb.control([]);
    customOnChange: Function;
    customWriteValue: Function;

    searchField = this.fb.control('');
    
    alignRight: boolean = false;
    
    @ViewChild('myPicker', { static: true }) myPicker;
    
    protected constructor(private fb: UntypedFormBuilder){}
    
    ngOnInit(): void {
        this.pickerControl.valueChanges.subscribe(data => {
            if(this.customOnChange){
                this.customOnChange(data);
            } else {
              this.onChange(data)  
            }
        });
        
        this.searchField.valueChanges
            .subscribe(value => {
                this.myPicker.filter(value);
            });
    }

    registerOnChange(fn: (updatedData: T[]) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: T[]): void {
        if(this.customWriteValue){
            this.customWriteValue(obj);
        } else {
            this.pickerControl.patchValue(obj, { emitEvent: false });
        }
    }

    setDisabledState(isDisabled: boolean): void {
        isDisabled ? this.pickerControl.disable() : this.pickerControl.enable();
    }

    validate(c: AbstractControl): ValidationErrors | null { 
        return null;
    }
    
    onSelectAll(): void {
        this.items$.subscribe(allData => {
            if (!_.isEmpty(this.bindValue)) {
                this.pickerControl.patchValue(_.map(allData, d => d[this.bindValue]))
            } else {
                this.pickerControl.patchValue(allData);
            }
        });
    }
    
    onClearAll(): void {
        this.pickerControl.patchValue([]);
    }

    closePicker() {
        //this.myPicker._clearSearch();
    }

    openPicker() {
        this.searchField.patchValue(null);
        setTimeout(() => {
            const scrollContainer = this.myPicker.element.querySelector(".ng-dropdown-panel-items");
            const firstSelected = this.myPicker.element.querySelector(".ng-dropdown-panel-items .ng-option input:checked")?.parentElement;
            if (firstSelected) {
                scrollContainer.scrollTo({
                    top: firstSelected.offsetTop
                });
            }
        }, 0);
    }
    
    clickPicker() {

    }
    
    getItemLabel(item: T): string {
        return (!_.isEmpty(this.bindLabel) && !_.isNil(item[this.bindLabel])) ? item[this.bindLabel] : item;
    }
    
    isIndeterminate(items: any[]) {
        return items.some(i => i.selected) && items.some(i => !i.selected);
    }
}

export function pickerProviders(type: any) {
    return [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => type),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => type),
            multi: true
        }
    ];
}