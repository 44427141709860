import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {
    CellClassRules, CellClickedEvent,
    ColDef,
    GroupCellRendererParams,
    IAggFuncParams,
    ITooltipParams,
    ValueGetterParams
} from "ag-grid-community";
import {DateRange} from "../../../Models/DateRange";
import {ActivityService} from "../../Shared/Services/activity.service";
import {debounceTime, startWith, switchMap, tap} from "rxjs/operators";
import {of} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Query, QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {RegionAccountCategorySummary} from "../../../Models/RegionAccountCategorySummary";
import {CacheService} from "../../Shared/Services/cache.service";
import {Router} from "@angular/router";
import {ActivityRoutePaths} from "../../Activity/activity-route-paths";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";
import {InterestListComponent} from "../../Contact/InterestList/interest-list.component";
import {ActivityListComponent} from "../../Activity/ActivityList/activity-list.component";

@Component({
    selector: "app-region-activity-summary",
    templateUrl: "./region-activity-summary.component.html",
    styleUrls: ["./region-activity-summary.component.scss"]
})
export class RegionActivitySummaryComponent extends BaseGridComponent<RegionAccountActivitiesSummary> implements OnChanges, OnInit {

    @Input()
    teamId: number;

    @Input()
    dateRange: DateRange;

    items = of(Object.values(MetricType));

    regionSummaryOptions: UntypedFormGroup = this.fb.group({
        teamId: this.fb.control(null),
        dateRange: this.fb.control(null),
        metricType: this.fb.control(MetricType.Interactions)
    });

    private lastFormValues: any;

    private queryResults: RegionAccountCategorySummary[] = [];

    private readonly linkCursorClass: string = "link-cursor";

    private readonly cellClassRules: CellClassRules = {
        // Zero Interaction Rules
        'zero-interactions': params => this.regionSummaryOptions.get("metricType").value !== MetricType.DaysSince &&
            params.value === 0,
        // Days Since Rules
        'days-since-red': params => this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince &&
            (!params.value || params.value > 360),
        'days-since-orange': params => this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince &&
            params.value > 180 && params.value <= 360,
        'days-since-yellow': params => this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince &&
            params.value >= 90 && params.value <= 180,
        // Default
        'default-cell': params =>
            (this.regionSummaryOptions.get("metricType").value !== MetricType.DaysSince && params.value !== 0) ||
            (this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince && params.value && params.value <= 90),
    };

    columnDefs: ColDef[] = [
        {
            field: "RegionName",
            rowGroup: true,
            hide: true
        },
        {
            colId: ColumnId.AccountColumn,
            field: "AccountName",
            headerName: "Account",
            tooltipValueGetter: (params: ITooltipParams) => RegionActivitySummaryComponent.getAccountTooltip(params),
            flex: 3,
            cellRenderer: "agGroupCellRenderer",
            cellRendererParams: {
                suppressCount: true
            } as GroupCellRendererParams,
            showRowGroup: true,
            cellClass: "default-cell",
            cellStyle: (params) => {
                if (params.value === params.node.key && params.node.level === 1) {
                    return { paddingLeft: "0px" };
                }
            }
        },
        {
            colId: ColumnId.AnalystVisitColumn,
            headerName: "Analyst Visit",
            headerTooltip: "Analyst Visit",
            aggFunc: (params: IAggFuncParams) => this.applyAggregateFunction(params.values),
            type: "numericColumn",
            flex: 1,
            minWidth: 70,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: () => this.addCursorCellClass([]),
            cellClassRules: this.cellClassRules,
            valueGetter: (params): ValueGetterParams => this.getMetricTypeData(params).AnalystVisits
        },
        {
            colId: ColumnId.PhoneInPersonColumn,
            headerName: 'Phone',
            headerTooltip: "Phone",
            aggFunc: (params: IAggFuncParams) => this.applyAggregateFunction(params.values),
            type: "numericColumn",
            flex: 1,
            minWidth: 82,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: () => this.addCursorCellClass([]),
            cellClassRules: this.cellClassRules,
            valueGetter: params => this.getMetricTypeData(params).PhoneInPersons
        },
        {
            colId: ColumnId.EmailColumn,
            headerName: "EmailX",
            headerTooltip: "Email Exchange",
            aggFunc: (params: IAggFuncParams) => this.applyAggregateFunction(params.values),
            type: "numericColumn",
            flex: 1,
            minWidth: 66,
            cellClass: () => this.addCursorCellClass([]),
            cellClassRules: this.cellClassRules,
            valueGetter: params => this.getMetricTypeData(params).EmailExchanges
        },
        {
            colId: ColumnId.TotalColumn,
            headerName: "Total",
            aggFunc: (params: IAggFuncParams) => this.applyAggregateFunction(params.values),
            type: "numericColumn",
            cellClass: () => this.addCursorCellClass(["darken", "numeric-aligned"]),
            cellClassRules: this.cellClassRules,
            flex: 1,
            minWidth: 54,
            valueGetter: params => this.getMetricTypeData(params).Total
        },
        {
            colId: ColumnId.ContactsCountColumn,
            field: "ContactsCount",
            headerName: "H&I",
            headerTooltip: '# of Contacts with at least one H&I for a covered stock',
            aggFunc: "sum",
            type: "numericColumn",
            flex: 1,
            minWidth: 53,
            cellClass: ["default-cell", "numeric-aligned", this.linkCursorClass]
        },
        {
            colId: ColumnId.ContactsWithBlueMatrixColumn,
            field: "ContactsWithBlueMatrix",
            headerName: "Subs w/BM",
            headerTooltip: '#Contacts with Bluematrix subscription to at least one covered stock',
            aggFunc: "sum",
            type: "numericColumn",
            flex: 1,
            minWidth: 65,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: ["default-cell", "numeric-aligned", this.linkCursorClass]
        }
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private activityService: ActivityService,
        private cacheService: CacheService,
        private router: Router,
    ) {
        super();

        this.gridOptions.groupDisplayType = "custom";
        this.gridOptions.suppressMakeColumnVisibleAfterUnGroup = true;

        this.gridOptions.isGroupOpenByDefault =
            params => {
                return params.key == 'Main';
            };

        this.gridOptions.rowClass = "groupable-row-darken";
    }

    ngOnInit(): void {
        const cacheKey = "RegionActivitySummary"
        const cachedFilters = this.cacheService.getValue(cacheKey);
        if (cachedFilters) {
            this.regionSummaryOptions.patchValue(cachedFilters);
        }

        this.rowData$ = this.regionSummaryOptions.valueChanges.pipe(
            startWith(this.regionSummaryOptions.value),
            debounceTime(250),
            tap((formValues) => this.cacheService.setValue(cacheKey, formValues)),
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(formValue => {
                if (this.isTeamIdOrDateRangeChanged(formValue)) {
                    this.lastFormValues = formValue;
                    const query = RegionActivitySummaryComponent.buildQuery(formValue.teamId, formValue.dateRange);
                    return this.activityService.getRegionActivitySummaries(query, formValue.teamId).pipe(
                        tap(data => this.queryResults = data)
                    );
                } else {
                    return of(this.queryResults);
                }
            }),
            switchMap((data) => {
                const regionAccountActivitiesSummary = data.map(summary => {
                    const analystVisit = summary.ActivityCategories
                        .find(it => it.Category == ActivityType.AnalystVisit);
                    const phoneInPerson = summary.ActivityCategories
                        .find(it => it.Category == ActivityType.PhoneInPerson);
                    const emailExchange = summary.ActivityCategories
                        .find(it => it.Category == ActivityType.EmailExchange);
                    return {
                        RegionName: summary.Region,
                        RegionSortOrder: summary.RegionSortOrder,
                        AccountName: summary.AccountName,
                        ParentComdolId: summary.ParentComdolId,
                        AccountIds: summary.AccountIds,
                        City: summary.City,
                        AnalystVisitsCount: analystVisit?.ActivityCount ?? 0,
                        PhoneInPersonsCount: phoneInPerson?.ActivityCount ?? 0,
                        EmailExchangesCount: emailExchange?.ActivityCount ?? 0,
                        AnalystVisitsDuration: analystVisit?.Duration ?? 0,
                        PhoneInPersonsDuration: phoneInPerson?.Duration ?? 0,
                        EmailExchangesDuration: emailExchange?.Duration ?? 0,
                        AnalystVisitsDaysSince: analystVisit?.DaysSince,
                        PhoneInPersonsDaysSince: phoneInPerson?.DaysSince,
                        EmailExchangesDaysSince: emailExchange?.DaysSince,
                        ContactsWithBlueMatrix: summary.ContactsWithBlueMatrix,
                        ContactsCount: summary.ContactsCount
                    } as RegionAccountActivitiesSummary
                });
                return of(regionAccountActivitiesSummary);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["teamId"]) {
            this.regionSummaryOptions.controls["teamId"].patchValue(changes["teamId"].currentValue);
        }

        if (changes["dateRange"]) {
            this.regionSummaryOptions.controls["dateRange"].patchValue(changes["dateRange"].currentValue);
        }
    }

    onCellClicked(event: CellClickedEvent<RegionAccountActivitiesSummary, number>): void {
        const eventColumn = event.column.getColId();
        switch (eventColumn) {
            case ColumnId.AccountColumn:
                break;
                case ColumnId.AnalystVisitColumn:
                case ColumnId.PhoneInPersonColumn:
                case ColumnId.EmailColumn:
                case ColumnId.TotalColumn:
                    this.goToActivitiesList(eventColumn, event);
                    break;
                case ColumnId.ContactsWithBlueMatrixColumn:
                case ColumnId.ContactsCountColumn:
                    this.goToInterestList(eventColumn, event);
                    break;
            }
    }

    private goToActivitiesList(eventColumn: string, event: CellClickedEvent<RegionAccountActivitiesSummary, number>): void {
        if (this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince) {
            return;
        }

        let dateRange: DateRange = this.regionSummaryOptions.get("dateRange").value;

        let categories = [];

        if (eventColumn === ColumnId.AnalystVisitColumn || eventColumn === ColumnId.TotalColumn) {
            categories.push(ActivityType.AnalystVisit);
        }
        if (eventColumn === ColumnId.PhoneInPersonColumn || eventColumn === ColumnId.TotalColumn) {
            categories.push(ActivityType.PhoneInPerson);
        }
        if (eventColumn === ColumnId.EmailColumn || eventColumn === ColumnId.TotalColumn) {
            categories.push(ActivityType.EmailExchange);
        }

        const teamId: number = this.regionSummaryOptions.get("teamId").value;

        this.cacheService.setValue(ActivityListComponent.NavigatedFiltersCacheKey, {
            startDate: dateRange.start,
            endDate: dateRange.end,
            categories: categories,
            callerTeams: [teamId],
            filters: RegionActivitySummaryComponent.getAccountFilters(event),
        });

        this.router.navigate([ActivityRoutePaths.Activities]);
    }

    private goToInterestList(eventColumn: string, event: CellClickedEvent<RegionAccountActivitiesSummary, number>): void {
        const filters = RegionActivitySummaryComponent.getAccountFilters(event);

        const teamId: number = this.regionSummaryOptions.get("teamId").value;

        QueryTranslator.AddQueryGroupValues(filters, "Holding", "Ticker Coverage Team", "contains", [teamId]);

        if (eventColumn === ColumnId.ContactsWithBlueMatrixColumn) {
            QueryTranslator.AddQueryGroupValues(filters, "Holding", "Blue Matrix", "contains", ['Y']);
        }

        this.cacheService.setValue(InterestListComponent.NavigatedFiltersCacheKey, filters);
        this.router.navigate([ContactRoutePaths.InterestList]);
    }

    private static getAccountFilters(event: CellClickedEvent<RegionAccountActivitiesSummary, number>): QueryGroup[] {
        let parentComdolIds = event.data
            ? event.data.ParentComdolId ? [event.data.ParentComdolId] : []
            : event.node.allLeafChildren.map(leaf => leaf.data.ParentComdolId).filter(id => !!id);

        let accountIds = event.data
            ? event.data.AccountIds
                ? event.data.AccountIds
                : []
            : event.node.allLeafChildren.map(leaf => leaf.data.AccountIds).reduce((a, b) => a.concat(b)).filter(id => !!id);

        let additionalFilters: QueryGroup[] = [];
        let group: QueryGroup;
        if (parentComdolIds.length > 0) {
            group = QueryTranslator.AddQueryGroupValues(additionalFilters, "Account", "Parent Comdol ID", "contains", parentComdolIds);
        }

        if (accountIds.length > 0) {
            group
                ? QueryTranslator.AddQueryCriteriaValue(group, "Account", "Instnum", "contains", accountIds)
                : QueryTranslator.AddQueryGroupValues(additionalFilters, "Account", "Instnum", "contains", accountIds);
        }

        return additionalFilters;
    }

    private static getAccountTooltip(params: ITooltipParams): string {
        let tooltip = params.value;

        if (params.data && params.data.RegionName !== 'Main') {
            tooltip += `, ${params.data.City}`;
        }

        if (params.data){
            const accountIdentifier = params.data.ParentComdolId
                ? params.data.ParentComdolId
                : params.data.AccountIds.join(", ");

            tooltip += ` (${accountIdentifier})`;
        }

        return tooltip;
    }

    private isTeamIdOrDateRangeChanged(formValue: any) {
        return !this.lastFormValues
            || formValue.teamId !== this.lastFormValues.teamId
            || formValue.dateRange !== this.lastFormValues.dateRange;
    }

    private getMetricTypeData(params: any) {
        switch (this.regionSummaryOptions.get("metricType").value) {
            case MetricType.Interactions: {
                return {
                    AnalystVisits: params.data.AnalystVisitsCount,
                    PhoneInPersons: params.data.PhoneInPersonsCount,
                    EmailExchanges: params.data.EmailExchangesCount,
                    Total: params.data.AnalystVisitsCount +
                        params.data.PhoneInPersonsCount +
                        params.data.EmailExchangesCount
                };
            }
            case MetricType.Duration: {
                return {
                    AnalystVisits: params.data.AnalystVisitsDuration,
                    PhoneInPersons: params.data.PhoneInPersonsDuration,
                    EmailExchanges: params.data.EmailExchangesDuration,
                    Total: params.data.AnalystVisitsDuration +
                        params.data.PhoneInPersonsDuration +
                        params.data.EmailExchangesDuration
                };
            }
            case MetricType.DaysSince: {
                return {
                    AnalystVisits: params.data.AnalystVisitsDaysSince,
                    PhoneInPersons: params.data.PhoneInPersonsDaysSince,
                    EmailExchanges: params.data.EmailExchangesDaysSince,
                    Total: this.findMinWithoutUndefined([params.data.AnalystVisitsDaysSince,
                        params.data.PhoneInPersonsDaysSince,
                        params.data.EmailExchangesDaysSince])
                };
            }
        }
        return undefined;
    }

    private static buildQuery(teamId: string, dateRange: DateRange): Query {
        const queryGroups: QueryGroup[] = [];

        QueryTranslator.AddQueryGroupValues(
            queryGroups,
            "Activity",
            "Baird Caller Team",
            "contains",
            [teamId ?? ""]
        );

        const activityTypes: string[] = Object.values(ActivityType);

        QueryTranslator.AddQueryGroupValues(
            queryGroups,
            "Activity",
            "Category",
            "contains",
            activityTypes
        );

        QueryTranslator.AddQueryGroupValues(
            queryGroups,
            "Activity",
            "Call Date",
            "between",
            [dateRange.start.format("L"), dateRange.end.format("L")]
        );
        return {
            QueryGroups: queryGroups
        } as Query;
    }

    private applyAggregateFunction(array: number[]): number {
        if (this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince) {
            return this.findMinWithoutUndefined(array);
        }
        return RegionActivitySummaryComponent.sum(array);
    }

    private addCursorCellClass(cellClasses: string[]): string[] {
        if (this.regionSummaryOptions.get("metricType").value === MetricType.DaysSince){
            return cellClasses;
        }
        return [...cellClasses, this.linkCursorClass];
    }

    private findMinWithoutUndefined(array: number[]): number {
        const filteredArray = array
            .filter(it => it !== undefined);

        const minimumDaysSince = Math.min(...filteredArray);
        return minimumDaysSince === Infinity ? null : minimumDaysSince;
    }

    private static sum(array: number[]): number {
        return array.reduce((runningSum, value) => runningSum + value, 0);
    }
}

class ColumnId {
    static AccountColumn: string = "Account";
    static AnalystVisitColumn: string = "AnalystVisit";
    static PhoneInPersonColumn: string = "PhoneInPerson";
    static EmailColumn: string = "EmailExchange";
    static TotalColumn: string = "Total";
    static ContactsCountColumn: string = "ContactsCount";
    static ContactsWithBlueMatrixColumn: string = "ContactsWithBlueMatrix";
}

export enum MetricType {
    Interactions = "Interactions",
    Duration = "Duration",
    DaysSince = "Days Since"
}

export interface RegionAccountActivitiesSummary {
    RegionName: string,
    RegionSortOrder: number,
    ParentComdolId: string,
    AccountIds: number[],
    AccountName: string,
    AnalystVisitsCount: number,
    PhoneInPersonsCount: number,
    EmailExchangesCount: number,
    AnalystVisitsDuration: number,
    PhoneInPersonsDuration: number,
    EmailExchangesDuration: number,
    AnalystVisitsDaysSince: number,
    PhoneInPersonsDaysSince: number,
    EmailExchangesDaysSince: number,
    ContactsWithBlueMatrix: number,
    ContactsCount: number
}

export enum ActivityType {
    AnalystVisit = "A",
    PhoneInPerson = "P",
    EmailExchange = "X"
}
