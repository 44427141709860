import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {User} from "../../Shared/Models/user";
import {SavedContactListGroup} from "../../Admin/ListGroups/saved-contact-list-group";
import {Observable} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ListGroupsService} from "../../Admin/ListGroups/list-groups.service";
import {UserService} from "../../Shared/Services/user.service";
import {ToastrService} from "ngx-toastr";
import {debounceTime, map} from "rxjs/operators";
import * as _ from "lodash";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {FieldSchema, QuerySchema, QueryService} from "../../Shared/Services/query.service";
import {Query, QueryDto, QueryGroup} from "../../../Services/QueryService";
import {QueryExportField} from "../../../Components/Query/QueryExportField";
import {ConfigService} from "../../Shared/Services/config.service";

@Component({
    selector: "app-query-form",
    templateUrl: "./query-form.component.html"
})
export class QueryFormComponent implements OnInit {

    previewMatch: number = 0;
    previewType: string = "";

    schema: QuerySchema;

    fromObjectNames: string[] = [];

    availableObjects: { objectName: string, fieldNames: string[] }[];
    exportFields: QueryExportField[] = [];

    currentUser: User;
    queryId: number;

    exporting: boolean = false;

    canEdit: boolean = true;
    canExport: boolean = true;
    isCIAExport: boolean = false;

    title: string;
    permissions: SavedContactListGroup[] = [
        { Id: "View", Name: "Group View"},
        { Id: "Edit", Name: "Group View - Edit"},
        { Id: "Private", Name: "Private"}
    ] as SavedContactListGroup[];

    groups$: Observable<SavedContactListGroup[]>;

    queryForm: UntypedFormGroup = this.fb.group({
        SaveAsNewQuery: this.fb.control(false),
        IsEmailable: this.fb.control(false),
        Name: this.fb.control('', [Validators.required, Validators.maxLength(50)]),
        Description: this.fb.control('', [Validators.maxLength(50)]),
        Permission: this.fb.control(''),
        Group: this.fb.control(''),
        CreatedBy: this.fb.control(''),
        UpdatedBy: this.fb.control(''),

        selectObject: this.fb.control(''),
        queryGroups: this.fb.control([]),
    });

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private queryService: QueryService,
                private listGroupsService: ListGroupsService,
                private userService: UserService,
                private toastr: ToastrService) {}

    ngOnInit(): void {
        this.queryService.getQuerySchema()
            .subscribe(schema => {
                this.schema = schema;
                this.fromObjectNames = this.getObjectNames();
                this.queryForm.get("selectObject").patchValue('Contact');

                if (this.queryId) {
                    this.title = 'Edit Query';
                    this.loadQuery(this.queryId);
                } else {
                    this.queryForm.patchValue({
                        Permission: "Private",
                    });
                    this.initializeForm();
                    this.title = 'New Query';
                }
            });

        this.queryForm.get('Permission').valueChanges
            .subscribe(value => {
                this.queryForm.get('Group').clearValidators();
                if (value === "Private") {
                    this.queryForm.patchValue({
                        Group: null
                    });
                    this.queryForm.get('Group').disable();
                } else {
                    this.queryForm.get('Group').setValidators(Validators.required);
                    this.queryForm.get('Group').enable();
                }
            });

        this.userService.getCurrentUser().subscribe(
            user => {
                this.currentUser = user;
            });

        this.groups$ = this.listGroupsService.getGroups().pipe(
            map(name => _.sortBy(name, n => n.Name))
        );

        this.queryForm.get('selectObject').valueChanges.subscribe(value => {
            if(value !== "CIAExport") {
                this.isCIAExport = false;
                this.exportFields = this.schema[value].DefaultExportFields;
                this.availableObjects = _.map(this.schema[value].ExportObjects, eo => {
                    return {
                        objectName: eo,
                        fieldNames: _.chain(this.schema[eo].Fields as FieldSchema[])
                            .filter(f => f.CanExport)
                            .map(f => f.Name)
                            .value()
                    }
                });
                this.updatePreview();
            }
            else {
                this.isCIAExport = true;
            }
        });

        this.queryForm.get('queryGroups').valueChanges.pipe(
            debounceTime(250)
        ).subscribe(formValue => {
            this.updatePreview();
        });
    }

    updatePreview(): void {
        const query = this.getQuery();
        this.queryService.getQueryPreview(query)
            .subscribe(preview => {
                this.previewMatch = preview.Count;
                this.previewType = query.ExportObject;
                this.canExport = this.previewMatch > 0;
            });
    }

    initializeForm(): void {
        this.queryForm.patchValue({
            queryGroups: [
                { Queries: [{ Object: 'Account', Field: 'Contact Groups', Operation: 'not contains', Value: '', Values: ['IER','PWM'] }] } as QueryGroup,
                { Queries: [{ Object: '', Field: '', Operation: '', Value: '', Values: [] }] } as QueryGroup,
            ]
        });
    }

    loadQuery(queryId: number) {
        this.queryService.getQueryById(queryId)
            .subscribe(query => {
                this.queryForm.patchValue({
                    Name: query.Name,
                    Description: query.Description,
                    Permission: query.ListGroupPermission || "Private",
                    Group: query.ListGroupId,
                    CreatedBy: query.CreatedBy,
                    UpdatedBy: query.UpdatedBy,
                    IsEmailable: query.IsEmailable,
                    selectObject: query.Query.ExportObject || "Contact",
                    queryGroups: query.Query.QueryGroups,
                });

                this.exportFields = query.Query.ExportFields || this.schema.Contact.DefaultExportFields;

                this.canEdit = query.IsEditable;
            });
    }

    getObjectNames(): string[] {
        return _.keys(this.schema).concat("CIAExport");
    }

    getQuery(): Query {
        return {
            QueryGroups: this.queryForm.get("queryGroups").value,
            ExportObject: this.queryForm.get("selectObject").value,
            ExportFields: this.exportFields
        } as Query;
    }

    export() {
        let exporting = true;

        if(!this.isCIAExport) {
            this.queryService.exportQuery(`QueryExport`, this.getQuery())
                .subscribe(x => {
                    exporting = false;
                });
        } else {
            this.queryService.getCIAExport('CIAExport', this.getQuery())
                .subscribe(x => {
                    exporting = false;
                });
        }
    }

    submit() {
        if (this.queryForm.valid) {

            let queryDto = {
                Id: this.queryForm.get("SaveAsNewQuery").value ? null : this.queryId,
                Name: this.queryForm.get("Name").value,
                Description: this.queryForm.get("Description").value,
                ListGroupPermission: this.queryForm.get("Permission").value,
                ListGroupId: this.queryForm.get("Group").value,
                Query: this.getQuery(),
                IsEmailable: this.queryForm.get("IsEmailable").value
            } as QueryDto;

            this.queryService.createOrUpdateQuery(queryDto)
                .subscribe(query => {
                    this.toastr.success("Query Saved");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
        }
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        const initialState = {
            message: 'Are you sure you want to delete this Query?',
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(isConfirmed => {
                if (isConfirmed) {
                    this.queryService.deleteQuery(this.queryId)
                        .subscribe(() => {
                            this.toastr.success("Query Deleted");
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        });
                }
            });
    }

    moveExportUp(exportField: QueryExportField): void {
        const itemIndex = _.findIndex(this.exportFields, sf => sf.Object === exportField.Object && sf.Field === exportField.Field);
        if (itemIndex > 0) {
            this.swapSelectedItems(itemIndex, itemIndex - 1);
        }
    }

    moveExportDown(exportField: QueryExportField): void {
        const itemIndex = _.findIndex(this.exportFields, sf => sf.Object === exportField.Object && sf.Field === exportField.Field);
        if (itemIndex < this.exportFields.length - 1) {
            this.swapSelectedItems(itemIndex, itemIndex + 1);
        }
    }

    private swapSelectedItems(indexA: number, indexB: number) {
        const tempItem = this.exportFields[indexB];
        this.exportFields[indexB] = this.exportFields[indexA];
        this.exportFields[indexA] = tempItem;
    }

    removeExport(exportField: QueryExportField): void {
        this.exportFields = this.exportFields.filter(f => f.Object !== exportField.Object || f.Field !== exportField.Field);
    }

    removeAllExport(): void {
        this.exportFields = [];
    }

    addExportField(objectName: string, fieldName: string): void {
        if (!this.isExporting(objectName, fieldName)) {
            this.exportFields.push({ Object: objectName, Field: fieldName });
        }
    }

    addAllExportFields(objectName: string): void {
        _.chain(this.schema[objectName].Fields as FieldSchema[])
            .filter(f => f.CanExport)
            .map(f => f.Name)
            .value()
            .forEach(fn => {
                this.addExportField(objectName, fn)
            });
    }

    isExporting(objectName: string, fieldName: string): boolean {
        return this.exportFields.filter(f => f.Object === objectName && f.Field === fieldName).length > 0;
    }
}
