<div class="modal-header">
    <strong>Account Revenue Target</strong>
</div>
<form [formGroup]="revenueTargetForm">
    <div class="modal-body">
        <div class="form-group">
            <label>Year</label>
            <input type="number" 
                   formControlName="year" 
                   class="form-control" />
        </div>
        <div class="form-group">
            <label>Comdol Id</label>
            <input type="string"
                   formControlName="comdolId"
                   class="form-control" />
        </div>
        <div class="form-group">
            <label>City</label>
            <input type="string"
                   formControlName="city"
                   class="form-control" />
        </div>
        <div class="form-group">
            <label>Revenue Target</label>
            <input type="number" 
                   formControlName="target" 
                   class="form-control" />
        </div>
        <div class="form-group">
            <label>Notes</label>
            <textarea style="resize: vertical"
                      class="form-control input-sm"
                      rows="3"
                      formControlName="note">
            </textarea>
        </div>
        <div class="form-group flex-hbox flex-gap-lg">
            <div class="flex">
                <label>Created By</label>
                <input class="form-control input-sm" formControlName="createdBy"/>
            </div>
            <div class="flex">
                <label>Updated By</label>
                <input class="form-control input-sm" formControlName="updatedBy"/>
            </div>
        </div>
    </div>
</form>
<div class="modal-footer">
    <div class="flex-hbox">
        <button type="button" class="flex btn btn-sm btn-primary" (click)="save()" [disabled]="revenueTargetForm.invalid || formSaving">Save</button>
        <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()" [disabled]="!revenueTargetId">Delete</button>
        <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
    </div>
</div>