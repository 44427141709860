import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {AccountService} from "../../../Shared/Services/account.service";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {AccountRegion} from "../../../../Models/AccountRegion";

@Component({
    selector: "app-region-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(RegionPickerComponent)
})
export class RegionPickerComponent extends BasePickerComponent<AccountRegion> {

    constructor(fb: UntypedFormBuilder, private accountService: AccountService){
        super(fb);

        this.name = "Region(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.accountService.getAccountRegions().pipe(
            map(regions => _.sortBy(regions, m => m.Name))
        );
    }
}
