<div class="flex flex-vbox">
    <div style="font-size: 0.8em;">
        Current Year Commission % change based on annualized commission values
    </div>
    <div class="flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                rowClass="non-clickable-row"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
</div>
