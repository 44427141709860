<div *ngIf="requiredFiltersMessage" class="required-filters-message">Please use filters to narrow results</div>
<ng-container *ngIf="filterDisplay$ | async as filters">
    <div class="filter-display-item" *ngIf="filters.List">
        <span class="bold">List: </span> {{ filters.List }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Tiers">
        <span class="bold">Tiers: </span>{{ filters.Tiers }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Analysts">
        <span class="bold">Analysts: </span>{{ filters.Analysts }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Sectors">
        <span class="bold">Sectors: </span>{{ filters.Sectors }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Categories">
        <span class="bold">Categories: </span>{{ filters.Categories }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Regions">
        <span class="bold">Regions: </span>{{ filters.Regions }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Markets">
        <span class="bold">Markets: </span>{{ filters.Markets }}
    </div>
    <div class="filter-display-item" *ngIf="filters.PrimaryBroker">
        <span class="bold">Primary Brokers: </span>{{ filters.PrimaryBroker }}
    </div>
    <div class="filter-display-item" *ngIf="filters.PersonalBroker">
        <span class="bold">Personal Brokers: </span>{{ filters.PersonalBroker }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Exclude">
        <span class="bold">Exclude: </span>{{ filters.Exclude }}
    </div>
    <div class="filter-display-item" *ngIf="filters.MarketingEmails">
        <span class="bold">Marketing Emails: </span>{{ filters.MarketingEmails }}
    </div>
    <div class="filter-display-item" *ngIf="filters.ResearchVms">
        <span class="bold">Research Vms: </span>{{ filters.ResearchVms }}
    </div>
    <div class="filter-display-item" *ngIf="filters.ResearchEmails">
        <span class="bold">Research Emails: </span>{{ filters.ResearchEmails }}
    </div>
    <div class="filter-display-item" *ngIf="filters.BlueMatrix">
        <span class="bold">BlueMatrix: </span>{{ filters.BlueMatrix }}
    </div>
    <div class="filter-display-item" *ngIf="filters.GlobalInvestor">
        <span class="bold">Global Investor: </span>{{ filters.GlobalInvestor }}
    </div>
    <div class="filter-display-item" *ngIf="filters.PrivateInvestment">
        <span class="bold">Private Investment: </span>{{ filters.PrivateInvestment }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Roles">
        <span class="bold">Roles: </span>{{ filters.Roles }}
    </div>
    <div class="filter-display-item" *ngIf="filters.InvestorTypes">
        <span class="bold">Investor Types: </span>{{ filters.InvestorTypes }}
    </div>
    <div class="filter-display-item" *ngIf="filters.InvestorStrategies">
        <span class="bold">Investor Strategies: </span>{{ filters.InvestorStrategies }}
    </div>
    <div class="filter-display-item" *ngIf="filters.InvestorStyles">
        <span class="bold">Investor Styles: </span>{{ filters.InvestorStyles }}
    </div>
    <div class="filter-display-item" *ngIf="filters.DealPlayers">
        <span class="bold">Deal Players: </span>{{ filters.DealPlayers }}
    </div>
    <div class="filter-display-item" *ngIf="filters.Orientations">
        <span class="bold">Orientations: </span>{{ filters.Orientations }}
    </div>
    <div class="filter-display-item" *ngIf="filters.AUM">
        <span class="bold">AUM</span>
    </div>
    <div class="filter-display-item" *ngIf="filters.EquityAUM">
        <span class="bold">Equity AUM</span>
    </div>
    <div class="filter-display-item" *ngIf="filters.MarketCap">
        <span class="bold">Market Cap: </span>{{ filters.MarketCap }}
    </div>
    <div class="filter-display-item" *ngIf="filters.ActivityWithinDays && filters.ActivityWithinDays !== '365' && filters.ActivityWithinDays !== 365">
        <span class="bold">Activity Within Days: </span>{{ filters.ActivityWithinDays }}
    </div>
    <div class="filter-display-item" *ngIf="filters.HasOtherFilters">
        <span class="bold">Other Filters Applied </span>
    </div>
</ng-container>