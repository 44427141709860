import {Component, Input} from "@angular/core";
import {BasePickerComponent, pickerProviders} from "../../Widget/Pickers/BasePicker/base-picker.component";
import {UntypedFormBuilder} from "@angular/forms";
import {CorpTravelUserService, CorpTravelUserType} from "./corp-travel-user.service";

@Component({
    selector: "app-corp-travel-user-type-picker",
    templateUrl: "../../Widget/Pickers/BasePicker/base-picker.component.html",
    providers: pickerProviders(CorpTravelUserTypePickerComponent)
})
export class CorpTravelUserTypePickerComponent extends BasePickerComponent<CorpTravelUserType> {

    constructor(fb: UntypedFormBuilder, private corpTravelUserService: CorpTravelUserService){
        super(fb);
        this.name = "Selected";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.multiple = false;
        this.closeOnSelect = true;
        this.showSelectClearAll = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.items$ = this.corpTravelUserService.getCorpTravelUserTypes();
    }
}
