
<div class="page-content flex-vbox">

    <app-page-banner
            pagetitle="Admin Dashboard"
            faicon="fa-gear"
            faiconcolor="#708090">
    </app-page-banner>

    <div class="page-body flex" style="margin: 0 10px;">

        <div class="row">
            <div class="col-xs-12">
                <h4>Users</h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminUserListPath]" [relativeTo]="null">Users</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminUserFeaturesPath]" [relativeTo]="null">User Features</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminUserGroupsPath]" [relativeTo]="null">User Groups</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminListGroupsPath]" [relativeTo]="null">List Groups</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminUserTeamsListPath]" [relativeTo]="null">User Teams</a>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <h4>Data Updates</h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openHoldingsModal()">Holdings</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openMcLaganModal()">McLagan</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openRelativePayModal()">Relative Pay</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openSubscriptionModal()">Subscriptions</a>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <h4></h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openAccountBrokerTrader()">Account Broker Trader</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openContactBroker()">Contacts</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openAccountHistory()">Account History</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block; font-size: medium" (click)="openSpecMailing()">Spec Mailing Subscriptions</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openContactHistory()">Contact History</a>
            </div>
        </div>

        <div class="row" data-if-user-feature="AdminMifidAccounts">
            <div class="col-xs-12">
                <h4>Mifid</h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" [routerLink]="[adminMifidAccountsPath]" [relativeTo]="null">Mifid Accounts</a>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <h4>Data Downloads</h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openModalTemplate(resourcesTemplate)">Activity Resources</a>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <h4>BlueMatrix/Research</h4>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openBlueMatrixContactsModalTemplate(bluematrixContactsTemplate)">Send Contacts</a>
            </div>
            <div class="col-sm-2">
                <a class="btn btn-default btn-lg" style="display: block" (click)="openResearchFiscalYearModalTemplate(researchFiscalYearTemplate)">Fiscal Year</a>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <h4>MeetMax</h4>
            </div>
            <div class="col-sm-2">
                <a
                    class="btn btn-default btn-lg"
                    style="display: block"
                    [routerLink]="[adminQueueMeetMaxEventsPath]"
                    [relativeTo]="null"
                >
                    Queue MeetMax Events
                </a>
            </div>
        </div>

    </div>
</div>

<ng-template #resourcesTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Activity Resource Download</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="templateModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="year">Year</label>
            <select id="year" class="form-control" name="year" [(ngModel)]="selectedYear">
                <option *ngFor="let year of resourceYears" value="{{year}}">{{year}}</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-xs-4">
            <button type="button" class="col-xs-12 btn btn-primary" (click)="downloadActivityResources()">Download</button>
        </div>
        <div class="col-xs-4">
            <button type="button" class="col-xs-12 btn btn-primary" (click)="downloadActivityResourcesNew()">Download New</button>
        </div>
        <div class="col-xs-4">
            <button type="button" class="col-xs-12 btn btn-default" (click)="templateModalRef.hide()">Cancel</button>
        </div>
    </div>
</ng-template>

<ng-template #bluematrixContactsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Send Contacts</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="templateModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-xs-12">
                <label class="control-label">Contacts to include in next file to BlueMatrix</label>
                <textarea class="form-control" [(ngModel)]="bluematrixNewContactIds" placeholder="Contact Id(s)" rows="5" style="resize: none;"></textarea>
            </div>
            <div class="col-xs-12">
                <span class="pull-right"><button type="button" class="btn btn-sm btn-link" (click)="clearBlueMatrixContactids()">Clear All</button></span>
                <label class="control-label">
                    {{bluematrixContactIds.length}} Contacts Included in next file to BlueMatrix
                </label>
                <select class="form-control" size="5">
                    <option *ngFor="let contactId of bluematrixContactIds">{{contactId}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox flex-gap">
            <button type="button" class="flex btn btn-primary" (click)="saveBlueMatrixContactIds()">Save</button>
            <button type="button" class="flex btn btn-default" (click)="templateModalRef.hide()">Close</button>
        </div>
    </div>
</ng-template>

<ng-template #researchFiscalYearTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Fiscal Year</h4>
    </div>
    <div class="modal-body">
        <label>Current Fiscal Year</label>
        <input class="form-control" type="number" [formControl]="researchFiscalYear"/>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox flex-gap">
            <button type="button" class="flex btn btn-sm btn-primary" (click)="saveResearchFiscalYear()">Save</button>
            <button type="button" class="flex btn btn-sm btn-default" (click)="templateModalRef.hide()">Close</button>
        </div>
    </div>
</ng-template>