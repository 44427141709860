import {NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {ButtonsModule} from "ngx-bootstrap/buttons";
import {PopoverModule} from "ngx-bootstrap/popover";
import {TabsModule} from "ngx-bootstrap/tabs";
import {TimepickerConfig,TimepickerModule} from "ngx-bootstrap/timepicker";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {AgGridModule} from "ag-grid-angular-legacy";
import {NgSelectModule} from "@ng-select/ng-select";
import { FullCalendarModule } from '@fullcalendar/angular';
import {EventCalendarComponent} from "./EventCalendar/event-calendar.component";
import {EventFormComponent} from "./EventForm/event-form.component";
import {NgOptionHighlightModule} from "../../NgOptionHighlightDirective";
import {CheckBoxRenderer} from "../Widget/CellRenderers/checkbox-renderer.component";
import {TextBoxRenderer} from "../Widget/CellRenderers/textbox-renderer.component";
import {EventAccountPresentersRenderer} from "./EventForm/event-account-presenters-renderer.component";
import {EventAccountStatusRenderer} from "./EventForm/event-account-status-renderer.component";
import {OldRequestDetailComponent} from "./RequestDetail/old-request-detail.component";
import {EventAttendeeRenderer} from "./RequestDetail/event-attendee-renderer.component";
import {EventDetailComponent} from "./EventDetail/event-detail.component";
import {ContactModule} from "../Contact/contact.module";
import {AccountModule} from "../Account/account.module";
import {TabAttendeeListComponent} from "./EventDetail/tab-attendee-list.component";
import {TabMeetingListComponent} from "./EventDetail/tab-meeting-list.component";
import {TabWebcastAttendeeListComponent} from "./EventDetail/tab-webcast-attendee-list.component";
import { MeetingFormComponent } from "./EventDetail/MeetingForm/meeting-form.component";
import {AttendeeFormComponent} from "./EventDetail/AttendeeForm/attendee-form.component";
import {MeetingAttendeeFormComponent} from "./EventDetail/MeetingAttendeeForm/meeting-attendee-form.component";
import {TabCompanyListComponent} from "./EventDetail/tab-company-list.component";
import {CompanyFormComponent} from "./EventDetail/company-form.component";
import {CompanyPresenterListRenderer} from "../Widget/CellRenderers/company-presenter-list-renderer.component";
import {MeetingFormAttendeeTabComponent} from "./EventDetail/MeetingForm/meeting-form-attendee-tab";
import {RegistrationListComponent} from "./RegistrationList/registration-list.component";
import { EventListComponent } from "./EventList/event-list.component";
import {EventMeetingHeaderComponent} from "./EventDetail/EventMeetingHeader/event-meeting-header.component";
import {WebcastAttendeeFormComponent} from "./EventDetail/webcast-attendee-form.component";
import {PipesModule} from "../Shared/Pipes/pipes.module";
import {TabInviteListComponent} from "./EventDetail/tab-invite-list.component";
import {RequestDetailComponent} from "./NewRequestDetail/request-detail.component";
import {TabPresenterListComponent} from "./NewRequestDetail/tab-presenter-list.component";
import {TabRequestMeetingListComponent} from "./NewRequestDetail/tab-request-meeting-list.component";
import {TabRequestAttendeeListComponent} from "./NewRequestDetail/tab-request-attendee-list.component";
import {RouterModule} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {eventRoutes} from "./event.routes";
import {RequestMeetingFormComponent} from "./NewRequestDetail/RequestMeetingForm/request-meeting-form.component";
import {
    RequestMeetingFormAttendeeTabComponent
} from "./NewRequestDetail/RequestMeetingForm/request-meeting-form-attendee-tab";
import {
    RequestMeetingFormReplayTabComponent
} from "./NewRequestDetail/RequestMeetingForm/request-meeting-form-replay-tab";
import {RequestAttendeeFormComponent} from "./NewRequestDetail/RequestAttendeeForm/request-attendee-form.component";
import {UpcomingEventsModal} from "./UpcomingEventsModal/upcoming-events-modal.component";
import {CompanyTickerListRenderer} from "../Widget/CellRenderers/company-ticker-list-renderer.component";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([
            CheckBoxRenderer,
            CompanyPresenterListRenderer,
            CompanyTickerListRenderer,
            TextBoxRenderer,
            EventAccountStatusRenderer,
            EventAccountPresentersRenderer,
            EventAttendeeRenderer]),
        ButtonsModule.forRoot(),
        TooltipModule.forRoot(),
        NgSelectModule,
        NgOptionHighlightModule,
        FullCalendarModule,
        TabsModule,
        PopoverModule.forRoot(),
        ContactModule,
        AccountModule,
        TimepickerModule.forRoot(),
        PipesModule,
        BsDropdownModule,
        RouterModule.forChild(eventRoutes),
    ],
    exports: [
        RouterModule,
    ],
    declarations: [
        EventListComponent,
        EventCalendarComponent,
        OldRequestDetailComponent,
        RequestDetailComponent,
        EventFormComponent,
        UpcomingEventsModal,
        EventAccountPresentersRenderer,
        EventAccountStatusRenderer,
        EventAccountStatusRenderer,
        EventAttendeeRenderer,
        EventDetailComponent,
        TabAttendeeListComponent,
        TabWebcastAttendeeListComponent,
        TabInviteListComponent,
        EventMeetingHeaderComponent,
        TabMeetingListComponent,
        MeetingFormAttendeeTabComponent,
        MeetingFormComponent,
        RequestMeetingFormComponent,
        RequestMeetingFormAttendeeTabComponent,
        RequestMeetingFormReplayTabComponent,
        AttendeeFormComponent,
        WebcastAttendeeFormComponent,
        MeetingAttendeeFormComponent,
        CompanyFormComponent,
        TabCompanyListComponent,
        RegistrationListComponent,
        RequestDetailComponent,
        TabPresenterListComponent,
        TabRequestMeetingListComponent,
        TabRequestAttendeeListComponent,
        RequestAttendeeFormComponent,
    ],
    providers: [
        TimepickerConfig,
        UserCanActivateRoute,
    ],
})
export class EventModule {
    constructor(){
        console.log("registering the EventModule");
    }
}
