import {BaseHttpService} from "../../Shared/Services/base-http.service";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import {Injectable} from "@angular/core";
import {SavedContactList} from "../../Admin/ListGroups/saved-contact-list";
import {Contact} from "../../../Models/Contact";

@Injectable({
    providedIn: 'root',
})
export class ContactListsService {
    currentList: BehaviorSubject<SavedContactList> = new BehaviorSubject<SavedContactList>(null);
    
    constructor(private baseHttpService: BaseHttpService) {
    }
    
    getAllLists(): Observable<SavedContactList[]> {
        return this.baseHttpService.getData(`/list`);
    }

    getCurrentList(): Subject<SavedContactList> {
        if(this.currentList.getValue()){
            return this.currentList;
        } else {
            this.currentList = new BehaviorSubject<SavedContactList>({} as SavedContactList);
            this.updateCurrentList();
        }

        return this.currentList;
    }

    getListById(listId: number): Observable<SavedContactList> {
        return this.baseHttpService.getData(`/list/id/${listId}`);
    }

    getListContacts(listId: number): Observable<Contact[]> {
        return this.baseHttpService.getData(`/list/id/${listId}/contacts`);
    }
    
    deleteList(listId: number): Observable<void> {
        return this.baseHttpService.deleteData(`/list/id/${listId}`);
    }
    
    updateContactList(contactList: SavedContactList): Observable<SavedContactList> {
        return this.baseHttpService.postData(`/list/id/${contactList.Id}`, contactList);
    }
    
    createContactList(contactList: SavedContactList): Observable<SavedContactList> {
        return this.baseHttpService.postData(`/list`, contactList);
    }

    updateCurrentList(): Subscription {
        return this.baseHttpService.getData<SavedContactList>(`/list`)
            .subscribe(list => {
                this.currentList.next(list);
            });
    }

    addContactToList(listId: number, contactId: number): Observable<boolean> {
        return this.baseHttpService.postData(`/list/id/${listId}/contact/${contactId}`, null);
    }

    removeContactFromList(listId: number, contactId: number): Observable<boolean> {
        return this.baseHttpService.deleteData(`/list/id/${listId}/contact/${contactId}`);
    }
}