import {Component, OnInit, EventEmitter, Input, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {UserService} from "../../Shared/Services/user.service";
import * as daterangepicker from "daterangepicker";
import {combineLatest, Observable, of} from "rxjs";
import {AccountDetailActivityQuery, ActivityService} from "../../Shared/Services/activity.service";
import {map, startWith, switchMap, tap, withLatestFrom} from "rxjs/operators";
import * as moment from "moment";
import {dateRenderer, participantsRenderer} from "../../Shared/ag-grid-cell-renderers";
import {RoleName} from "../../../Models/Role";
import {Moment} from "moment";
import {AccountSummaryGroupRenderer} from "./account-summary-group-renderer.component";
import {IconHeaderRendererComponent} from "../../Widget/CellRenderers/icon-header-renderer.component";
import {User} from "../../Shared/Models/user";
import * as _ from "lodash";
import {ContactListRenderer} from "../../Widget/CellRenderers/contact-list-renderer.component";
import {UserTeam} from "../../../Models/UserTeam";
import {ActivityParticipant} from "../../../Models/Activity";
import {ColDef} from "ag-grid-community";

@Component({
    selector: "app-tab-account-summary",
    templateUrl: "./tab-account-summary.component.html",
})
export class TabAccountSummaryComponent extends BaseGridComponent<AccountSummary> implements OnInit {

    @Input()
    accountId: number;
    @Input()
    accountDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

    corpAccessCategories: string[] = ["V", "N", "C", "I", "K"];
    analystTimeCategories: string[] = ["A", "P", "X", "D", "S", "E"];
    categories = [...this.corpAccessCategories, ...this.analystTimeCategories];

    users: User[];
    userTeams: UserTeam[];
    activityQuery: AccountDetailActivityQuery;
    exportDisabled: boolean = true;
    activityReportAnalystFilter: boolean = false;

    rowData$: Observable<AccountSummary[]>;
    columnDefs: ColDef[] = [
        {
            field: "parentCategory",
            rowGroup: true,
            hide: true
        },
        {
            field: "category",
            rowGroup: true,
            hide: true,
        },
        {
            field: "date",
            headerName: "Date",
            flex: 1,
            cellRenderer: dateRenderer,
            sort: "desc",
        },
        {
            field: "eventComment",
            headerName: "Event/Comment",
            flex: 3
        },
        {
            field: "ticker",
            headerName: "Ticker",
            flex: 1,
        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },
        {
            field: "participants",
            headerName: "Analyst",
            flex: 1,
            cellRenderer: (params) => participantsRenderer(params.data.participants, this.userTeams),
            tooltipValueGetter: (params) => this.participantsToolTip(params.data.participants)
        },
        {
            field: "duration",
            headerTooltip: "Duration",
            type: "numericColumn",
            width: 60,
            headerClass: "header-right",
            headerComponent: "iconHeaderRenderer",
            headerComponentParams: {
                iconClass: "fa fa-clock-o"
            }
        },
        {
            field: "attendees",
            headerTooltip: "Attendees",
            type: "numericColumn",
            width: 50,
            headerClass: "header-right",
            headerComponent: "iconHeaderRenderer",
            headerComponentParams: {
                iconClass: "fa fa-user"
            },
            cellRenderer: "contactListRenderer"
        }
    ];

    frameworkComponents = {
        accountSummaryGroupRenderer: AccountSummaryGroupRenderer,
        iconHeaderRenderer: IconHeaderRendererComponent,
        contactListRenderer: ContactListRenderer
    };

    accountSummarySearchForm: UntypedFormGroup = this.fb.group({
        categories: this.fb.control(this.categories),
        dateRange: this.fb.control({start: moment().startOf("year"), end: moment()}),
        analysts: this.fb.control([])
    });

    datePickerOptions: daterangepicker.Options = {};

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService,
                private activityService: ActivityService) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.groupDisplayType = "groupRows";
        this.gridOptions.groupRowRenderer = "accountSummaryGroupRenderer";
        this.gridOptions.groupDefaultExpanded = 1;
        this.gridOptions.suppressPaginationPanel = true;
        this.gridOptions.suppressRowClickSelection = true;
        this.defaultColDef.cellClassRules = {
            "leaf-node": params => !params.node.group
        }
        combineLatest([
            this.userService.getCurrentUser(),
            this.userService.getUsers(),
            this.userService.getUserTeams(),
            this.userService.getAllTeams()
        ]).pipe(
            tap(([user, users, userTeams, teams]) => {
                this.users = users;
                this.userTeams = userTeams;
                this.activityReportAnalystFilter = user.Features.includes("ActivityReportAnalystFilter");
                const selected = user.Role.Name === RoleName.Research ? [teams.find(t => t.Name === user.Team.Name)?.Id] : teams.map(t => t.Id);
                this.accountSummarySearchForm.get("analysts").patchValue(selected, {emitEvent: false});
            })
        ).subscribe(() => {
            this.setRowData();
        });
    }

    setRowData(): void {
        this.rowData$ = this.accountSummarySearchForm.valueChanges.pipe(
            startWith(this.accountSummarySearchForm.getRawValue()),
            withLatestFrom(this.userService.getCurrentUser()),
            switchMap(([filters, user]) => {
                this.activityQuery = this.createActivityQuery(user.Role.Name, filters);
                return this.activityService.getAccountDetailActivitySummaries(this.accountId, this.activityQuery).pipe(
                    map(summaries => {
                        const accountSummaries: AccountSummary[] = [];
                        summaries.forEach(s => {
                            if (s.Activities.length === 0) {
                                accountSummaries.push({
                                    category: s.CategoryDescription,
                                    parentCategory: this.corpAccessCategories.includes(s.Category) ? "Corp Access" : "Analyst Time",
                                    type: "NO_DATA",
                                    attendees: 0
                                } as AccountSummary);
                                return;
                            }
                            s.Activities
                                .forEach(a => {
                                    accountSummaries.push({
                                        accountId: this.accountId,
                                        date: a.CallDate,
                                        eventComment: a.Comment,
                                        ticker: a.Tickers,
                                        type: a.EventType,
                                        analyst: a.Analyst,
                                        participants: a.Participants,
                                        duration: a.Duration,
                                        attendees: a.AttendeeCount,
                                        category: s.CategoryDescription,
                                        categoryCode: s.Category,
                                        parentCategory: this.corpAccessCategories.includes(s.Category) ? "Corp Access" : "Analyst Time",
                                    } as AccountSummary);
                                });
                        });
                        return accountSummaries;
                    })
                );
            }),
            tap(accountSummaries => {
                this.exportDisabled = accountSummaries.length === 0;
            })
        );
    }

    export(): void {
        this.activityService.getAccountDetailSummaryActivitiesExport(this.accountId, this.activityQuery).subscribe();
    }

    groupActivityCategories = (item: { Id: string; Name: string; }): any => {
        return this.corpAccessCategories.includes(item.Id) ? "Corp Access" : "Analyst Time";
    }

    private createActivityQuery(roleName: string, filters: { categories: string[], analysts: string[], dateRange: { start: Moment, end: Moment }, quarters: number[]}): AccountDetailActivityQuery {
        const {categories, analysts, dateRange, quarters} = filters;
        return {
            analystTimeActivityGroupFilter: analysts.length > 0 ? "All" : "Team",
            analystTimeTeamIds: analysts.length === 0 && roleName !== RoleName.Research ? "9999999" : analysts.join(" "),
            corpAccessActivityGroupFilter: "",
            corpAccessTeamIds: "",
            categories: categories,
            startDate: dateRange.start.format("YYYY-MM-DD"),
            endDate: dateRange.end.format("YYYY-MM-DD"),
            quarters: quarters
        } as unknown as AccountDetailActivityQuery;
    }

    private participantsToolTip(participants: ActivityParticipant[]) {
        return this.users.filter(u => _.includes(participants.map(p => p.UserId), u.Id)).map(bc => bc.LastName).sort().join(",");
    }
}

interface AccountSummary {
    accountId: number,
    date: string;
    eventComment: string;
    ticker: string;
    type: string;
    analyst: string;
    participants: ActivityParticipant[];
    duration: number;
    attendees: number;
    category: string;
    categoryCode: string;
    parentCategory: string;
}
