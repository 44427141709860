import {NgModule} from "@angular/core";
import {MarketPickerComponent} from "./MarketPicker/market-picker.component";
import {RegionPickerComponent} from "./RegionPicker/region-picker.component";
import {BrokerPickerComponent} from "./BrokerPicker/broker-picker.component";
import {YesNoAnyPickerComponent} from "./YesNoAnyPicker/yes-no-any-picker.component";
import {RolePickerComponent} from "./RolePicker/role-picker.component";
import {StringPickerComponent} from "./StringPicker/string-picker.component";
import {NumberPickerComponent} from "./NumberPicker/number-picker.component";
import {ActivityCategoryPickerComponent} from "./ActivityCategoryPicker/activity-category-picker.component";
import {BairdContactPickerComponent} from "./BairdContactPicker/baird-contact-picker.component";
import {EventCategoryPickerComponent} from "./EventCategoryPicker/event-category-picker.component";
import {AnalystPickerComponent} from "./AnalystPicker/analyst-picker.component";
import {CommissionCategoryPickerComponent} from "./CommissionCategoryPicker/commission-category-picker.component";
import {TierPickerComponent} from "./TierPicker/tier-picker.component";
import {ActivitySubCategoryPickerComponent} from "./ActivitySubCategoryPicker/activity-sub-category-picker.component";
import {ActivityStatusPickerComponent} from "./ActivityStatusPicker/activity-status-picker.component";
import {HoldingCategoryPickerComponent} from "./HoldingCategoryPicker/holding-category-picker.component";
import {TeamPickerComponent} from "./TeamPicker/team-picker.component";
import {QuarterPickerComponent} from "./QuarterPicker/quarter-picker.component";
import {StockCallCategoryPickerComponent} from "./StockCallCategoryPicker/stock-call-category-picker.component";
import {SpecialtyMailingCodePickerComponent} from "./SpecialtyMailingCodePicker/specialty-mailing-code-picker.component";
import {ActivityMeetingTypePickerComponent} from "./ActivityMeetingTypePicker/activity-meeting-type-picker.component";
import {CallerPickerComponent} from "./CallerPicker/caller-picker.component";
import {MeetingMeetingTypePickerComponent} from "./MeetingMeetingTypePicker/meeting-meeting-type-picker.component";
import {MeetingContextPickerComponent} from "./MeetingContextPicker/meeting-context-picker.component";
import {BackupsPickerComponent} from "./BackupsPicker/backups-picker.component";
import {CommonModule} from "@angular/common";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "../../../NgOptionHighlightDirective";
import {ReactiveFormsModule} from "@angular/forms";
import {CompanyPeoplePickerComponent} from "./CompanyPeoplePicker/company-people-picker.component";
import {EmailSourcePickerComponent} from "./EmailSourcePicker/email-source-picker.component";
import {ContactTeamsPickerComponent} from "./ContactTeamsPicker/contact-teams-picker.component";
import {ContactPickerComponent} from "./ContactPicker/contact-picker";
import {RelatedAccountPickerComponent} from "./RelatedAccountPicker/related-account-picker.component";
import {EventPickerComponent} from "./EventPicker/event-picker.component";
import {SavedContactListsPickerComponent} from "./SavedContactListsPicker/saved-contact-lists-picker.component";
import {ContactListPickerComponent} from "./ContactListPicker/contact-list-picker.component";
import {QueryListPickerComponent} from "./QueryListPicker/query-list-picker.component";
import {EesActivityCategoryPickerComponent} from "./EESPicker/ees-activity-category-picker.component";
import {EesActivityStatusPickerComponent} from "./EESPicker/ees-activity-status-picker.component";
import { InstiselSectorPickerComponent } from "./InstiselSectorPicker/instisel-sector-picker.component";
import {EventCompanyStatusPickerComponent} from "./EventCompanyStatusPicker/event-company-status-picker.component";
import {TimeZonePickerComponent} from "./TimeZonePicker/time-zone-picker.component";
import {TravelTerritoryPickerComponent} from "./TravelTerritoryPicker/travel-territory-picker.component";
import {BusinessUnitPickerComponent} from "./BusinessUnitPicker/business-unit-picker.component";
import {MonthPickerComponent} from "./MonthPicker/month-picker.component";

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        ReactiveFormsModule,
    ],
    declarations: [
        MarketPickerComponent,
        RegionPickerComponent,
        BrokerPickerComponent,
        YesNoAnyPickerComponent,
        RolePickerComponent,
        StringPickerComponent,
        NumberPickerComponent,
        ActivityCategoryPickerComponent,
        BairdContactPickerComponent,
        EventCategoryPickerComponent,
        EventCompanyStatusPickerComponent,
        AnalystPickerComponent,
        BusinessUnitPickerComponent,
        CommissionCategoryPickerComponent,
        TierPickerComponent,
        ActivitySubCategoryPickerComponent,
        ActivityStatusPickerComponent,
        HoldingCategoryPickerComponent,
        StockCallCategoryPickerComponent,
        TeamPickerComponent,
        QuarterPickerComponent,
        MonthPickerComponent,
        SpecialtyMailingCodePickerComponent,
        ActivityMeetingTypePickerComponent,
        CallerPickerComponent,
        MeetingMeetingTypePickerComponent,
        MeetingContextPickerComponent,
        BackupsPickerComponent,
        CompanyPeoplePickerComponent,
        EmailSourcePickerComponent,
        ContactTeamsPickerComponent,
        RelatedAccountPickerComponent,
        ContactPickerComponent,
        EventPickerComponent,
        SavedContactListsPickerComponent,
        ContactListPickerComponent,
        QueryListPickerComponent,
        EesActivityCategoryPickerComponent,
        EesActivityStatusPickerComponent,
        InstiselSectorPickerComponent,
        TimeZonePickerComponent,
        TravelTerritoryPickerComponent,
        QuarterPickerComponent,
    ],
    exports: [
        MarketPickerComponent,
        RegionPickerComponent,
        BrokerPickerComponent,
        YesNoAnyPickerComponent,
        RolePickerComponent,
        MonthPickerComponent,
        StringPickerComponent,
        NumberPickerComponent,
        ActivityCategoryPickerComponent,
        BairdContactPickerComponent,
        EventCategoryPickerComponent,
        EventCompanyStatusPickerComponent,
        AnalystPickerComponent,
        CommissionCategoryPickerComponent,
        TierPickerComponent,
        ActivitySubCategoryPickerComponent,
        ActivityStatusPickerComponent,
        HoldingCategoryPickerComponent,
        StockCallCategoryPickerComponent,
        TeamPickerComponent,
        QuarterPickerComponent,
        SpecialtyMailingCodePickerComponent,
        ActivityMeetingTypePickerComponent,
        CallerPickerComponent,
        MeetingMeetingTypePickerComponent,
        MeetingContextPickerComponent,
        BackupsPickerComponent,
        CompanyPeoplePickerComponent,
        EmailSourcePickerComponent,
        ContactTeamsPickerComponent,
        ContactPickerComponent,
        RelatedAccountPickerComponent,
        EventPickerComponent,
        SavedContactListsPickerComponent,
        ContactListPickerComponent,
        QueryListPickerComponent,
        EesActivityCategoryPickerComponent,
        EesActivityStatusPickerComponent,
        InstiselSectorPickerComponent,
        TimeZonePickerComponent,
        TravelTerritoryPickerComponent,
        BusinessUnitPickerComponent,
        QuarterPickerComponent,
    ]
})
export class PickersModule {
    constructor(){}
}
