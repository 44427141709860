import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {UserService} from "../../../Shared/Services/user.service";

@Component({
    selector: "app-caller-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(CallerPickerComponent)
})
export class CallerPickerComponent extends BasePickerComponent<any> {

    constructor(fb: UntypedFormBuilder, private userService: UserService){
        super(fb);

        this.placeHolder = "0 Caller(s)";
        this.name = "Caller(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.customSearchable = true;
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = this.userService.getCallers().pipe(
            map(users => _.sortBy(users, m => m.LastName)),
            map(users => _.map(users, user =>  {  return { Id: user.Id , Name: `${user.LastName}, ${user.FirstName}`}}))
        );
    }
}
