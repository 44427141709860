import { AfterViewInit, ElementRef, ViewChild, Directive } from "@angular/core";
import { Chart, ChartData, ChartDataset, ChartOptions, ChartType } from "chart.js";
import * as _ from "lodash";
import { registerables } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);
Chart.register(...registerables)

@Directive()
export abstract class BaseChartComponent implements AfterViewInit {

    chart: Chart;
    type: ChartType | string;
    data: ChartData;
    options: ChartOptions;

    @ViewChild("myChart", { static: true })
    myChart: ElementRef<HTMLCanvasElement>;

    ngAfterViewInit(): void {
        this.configureChart();
    }

    abstract configureChart(): void;

    runChart(): void {
        this.applyDatasetColors(this.data.datasets);
        this.chart = new Chart(this.myChart.nativeElement.getContext('2d'), {
            type: this.type as ChartType,
            data: this.data,
            options: _.merge(this.options, BaseChartOptions.options()),
        });
    }

    updateChart(data: ChartData): void {
        if (this.chart) {
            this.chart.data = data;
            this.applyDatasetColors(this.chart.data.datasets);
            this.chart.update();
        }
    }

    private applyDatasetColors(datasets: ChartDataset[]): void {
        const colors = BaseChartOptions.colors();
        datasets.forEach((d, i) => {
            if (!d.backgroundColor) {
                d.backgroundColor = colors[i % colors.length].backgroundColor;
            }
            if (!d.borderColor) {
                d.borderColor = colors[i % colors.length].borderColor;
            }
        });
    }
}

export class BaseChartOptions {
    static options(): ChartOptions {
        Chart.defaults.scales.linear.beginAtZero = true;
        return {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: true
                },
                datalabels: {
                    display: false
                }
            },
        }
    }

    static colors(): any {
        return [
            {
                backgroundColor: "rgba(107, 192, 75, 0.6)",
                borderColor: "rgba(107, 192, 75, 1)"
            },
            {
                backgroundColor: "rgba(243, 112, 33, 0.6)",
                borderColor: "rgba(243, 112, 33, 1)"
            },
            {
                backgroundColor: "rgba(57, 194, 215, 0.6)",
                borderColor: "rgba(57, 194, 215, 1)"
            },
            {
                backgroundColor: "rgba(112, 164, 137, 0.6)",
                borderColor: "rgba(112, 164, 137, 1)"
            },
            {
                backgroundColor: "rgba(234, 171, 0, 0.6)",
                borderColor: "rgba(234, 171, 0, 1)"
            },
            {
                backgroundColor: "rgba(83, 46, 96, 0.6)",
                borderColor: "rgba(83, 46, 96, 1)"
            },
            {
                backgroundColor: "rgba(178, 34, 34, 0.6)",
                borderColor: "rgba(178, 34, 34, 1)"
            },
            {
                backgroundColor: "rgba(35, 74, 232, 0.6)",
                borderColor: "rgba(35, 74, 232, 1)"
            },
            {
                backgroundColor: "rgba(236, 177, 234, 0.6)",
                borderColor: "rgba(236, 177, 234, 1)"
            },
        ]
    }

    static tierColors(): any {
        return ["rgba(0, 128, 0, 1)", "rgba(50, 205, 50, 1)", "rgba(255, 127, 80, 1)", "rgba(192, 0, 0, 1)"];
    }

    static tierHoverColors(): any {
        return ["rgba(0, 128, 0, 0.2)", "rgba(50, 205, 50, 0.2)", "rgba(255, 127, 80, 0.2)", "rgba(192, 0, 0, 0.2)"];
    }

    static clientEventsColors(): any {
        return [{
            backgroundColor: "rgba(243, 112, 33, 0.6)",
            borderColor: "rgba(243, 112, 33, 1)"
        },
            {
                backgroundColor: "rgba(57, 194, 215, 0.6)",
                borderColor: "rgba(57, 194, 215, 1)"
            }];
    }
}
