import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {Contact} from "../../../Models/Contact";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";

@Component({
    selector: 'app-grid-contact-name',
    template: `
        <a
            *ngIf="contact.Id"
            [routerLink]="[contactDetailPath, contact.Id]"
            [relativeTo]="null"
        >
            {{ contact.LastName }}, {{ contact.FirstName }}
        </a>
    `
})
export class ContactNameCellRenderer implements ICellRendererAngularComp {

    public contact: Contact;

    contactDetailPath = ContactRoutePaths.ContactDetail;

    constructor(
        private router: Router,
    ) { }

    agInit(params: any): void {
        this.contact = params.data.Contact || params.data;
    }

    refresh(): boolean {
        return true;
    }
}
