import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {ActiveElement, ChartData, ChartEvent, Chart} from "chart.js";
import {map, startWith} from "rxjs/operators";
import * as _ from "lodash";
import {ChartType} from "ag-grid-community";
import * as moment from "moment";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {BaseChartComponent} from "../../Widget/Charts/BaseChart/base-chart.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {ActivityWeekSum} from "../../../Models/ActivityWeekSum";
import {Router} from "@angular/router";
import {ActivityRoutePaths} from "../../Activity/activity-route-paths";
import {ActivityListComponent} from "../../Activity/ActivityList/activity-list.component";
import { CacheService } from "../../Shared/Services/cache.service";
import {Observable} from "rxjs";

@Component({
    selector: "app-call-activity-chart",
    templateUrl: "./research-dashboard-base-chart.component.html"
})
export class CallActivityChartComponent extends BaseChartComponent implements OnChanges {
    @Input()
    chartData: ActivityWeekSum[];

    @Input()
    team: number;

    categories: string[] = ['P', 'S', 'N'];
    title: string = "Call Activity";
    info: string = "Include activities: Phone (P), Voice Mail (S), and Conf Call (N)";
    type: ChartType = 'line';
    stacked: boolean = true;
    hasData: boolean = false;

    chartDataControl: UntypedFormControl = this.fb.control([]);

    constructor(private activityService: ActivityService,
                private cacheService: CacheService,
                private fb: UntypedFormBuilder,
                private router: Router,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartData) {
            this.chartDataControl.patchValue(this.chartData);
        }
    }

    configureChart(): void {
        this.options = {
            scales: {
                y: {
                    title: {
                        display: true,
                        text: 'Count'
                    },
                    beginAtZero: true,
                    suggestedMax: 25,
                }
            },
            onClick: (event: ChartEvent, elements: ActiveElement[], chart: Chart): void => {
                if (elements?.length === 0) {
                    return;
                }
                const dateLabel = this.chart.data.labels[elements[0].index] as string;

                this.cacheService.setValue(ActivityListComponent.NavigatedFiltersCacheKey, {
                    startDate: moment(dateLabel),
                    endDate: moment(dateLabel).add(6, "days"),
                    categories: this.categories,
                    callerTeams: this.team ? [this.team] : null
                });

                this.router.navigate([ActivityRoutePaths.Activities]);
            }
        };

        this.configureData()
            .subscribe(chartData => {
                if (this.hasData) {
                    if (this.chart) {
                        this.updateChart(chartData);
                    } else {
                        this.data = chartData;
                        this.runChart();
                    }
                } else {
                    this.chart?.destroy();
                    this.chart = null;
                }
            });
    }

    configureData(): Observable<ChartData> {
        return this.chartDataControl.valueChanges.pipe(
            startWith(this.chartDataControl.value),
            map((chartData: ActivityWeekSum[]) => {
                this.hasData = chartData.length > 0;

                const activitiesGroupedByWeek = _.chain(chartData)
                    .groupBy(cd => cd.WeekOf)
                    .value();

                const activityCounts = _.chain(activitiesGroupedByWeek)
                    .reduce((acc, curr) => {
                        const filteredByCategory = curr.filter(cd => this.categories.includes(cd.Category));
                        acc.Target.push(20);
                        acc.Grid.push(_.chain(filteredByCategory).map(c => c.GridCount).sum().value());
                        acc.Total.push(_.chain(filteredByCategory).map(c => c.TotalCount).sum().value());
                        acc.Average.push(_.chain(filteredByCategory).map(c => c.RollingCountAverage).sum().round(2).value());
                        return acc;
                    }, {Grid: [], Total: [], Average: [], Target: []})
                    .value();

                return {
                    labels: _.chain(activitiesGroupedByWeek)
                        .map((ws, key) => moment(key, "YYYY-MM-DDT00:00:00").format("L"))
                        .value(),
                    datasets: Object.entries(activityCounts).map(([key, val]) => {
                        return {
                            label: key,
                            data: val,
                            fill: false,
                            pointRadius: key === "Target" ? 0 : 3
                        }
                    })
                };
            })
        )
    }
}
