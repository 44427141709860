import {Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from "@angular/core";
import {combineLatest} from "rxjs";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef} from "ag-grid-community";
import {of} from "rxjs";
import {yesBlankCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {ContactResearchDto, ContactResearchService} from "../../Shared/Services/contact-research.service";
import {EntitlementService, VendorEntitlementDtoShow} from "../../Shared/Services/entitlement.service";
import {map} from "rxjs/operators";

export interface ContactWithVendorEntitlement {
    Code: string;
    IsSubscribed: boolean;
    Description: string;
    MaxColumnWidth?: number;
}

@Component({
    selector: "app-research-access",
    templateUrl: "./research-access.component.html",
    styleUrls: ["./research-access.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ResearchAccessComponent extends BaseGridComponent<any> implements OnChanges {
    @Input()
    contactId: number;

    @Input()
    accountId: number;

    @Input()
    showPopover: boolean = false;

    columnDefs: ColDef[];

    hasResearch: boolean = false;

    constructor(
        private contactResearchService: ContactResearchService,
        private entitlementService: EntitlementService,
    ) {
        super();
    }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        let queryGroups: QueryGroup[] = [];
        if (simpleChanges['contactId'])
        {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Persnum", "contains", [this.contactId]);
        }
        else if (simpleChanges['accountId'])
        {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Account", "Instnum", "contains", [this.accountId]);
        }
        else
        {
            return;
        }
        let contactsResearch$ = this.contactResearchService.getContactResearchByQuery(queryGroups);
        let vendorEntitlements$ = this.entitlementService.getVendorEntitlementsList();

        let entitlements$ = combineLatest([contactsResearch$, vendorEntitlements$]).pipe(
            map(([contactsResearch, vendorEntitlements]) =>
                this.mapContactsResearchAndVendorEntitlements(contactsResearch, vendorEntitlements)),
        );

        entitlements$.subscribe(contactWithVendorEntitlements => {
            this.columnDefs = [];

            contactWithVendorEntitlements.forEach(e => {
                let colDef: ColDef = {
                    field: e.Code,
                    headerName: e.Code,
                    headerTooltip: e.Description,
                    maxWidth: e.MaxColumnWidth ?? 52,
                    cellRenderer: yesBlankCellRenderer,
                    cellClassRules: this.cellClassRules
                };

                this.columnDefs.push(colDef);
            });

            this.hasResearch = contactWithVendorEntitlements.some(x => x.IsSubscribed);

            this.rowData$ = of([contactWithVendorEntitlements.reduce((obj, item) => (obj[item.Code] = item.IsSubscribed, obj) ,{})]);
        });
    }
    mapContactsResearchAndVendorEntitlements(
        contactResearches: ContactResearchDto[],
        vendorEntitlements: VendorEntitlementDtoShow[]
    ): ContactWithVendorEntitlement[] {

        const singleResearch = contactResearches[0];
        if (!singleResearch) {
            return [];
        }

        let researchColumns = [];

        researchColumns.push(...[
            { Code: 'BM', IsSubscribed: singleResearch.IsBlueMatrix, Description: 'Interests/Specialty Mailings', },
            { Code: 'BOL', IsSubscribed: singleResearch.IsResearchOnline, Description: 'Research Online', }
        ]);

        vendorEntitlements.forEach(e => {
            let matchingVendorEntitlement = singleResearch.VendorEntitlements.find(x => x === e.Code);

            researchColumns.push({
                Code: e.Code,
                IsSubscribed: !!matchingVendorEntitlement,
                Description: e.Description
            });
        });

        researchColumns.push(...[
            { Code: 'MKT', IsSubscribed: singleResearch.IsMarketingEmail, Description: 'Marketing Email', },
            { Code: 'RES EM', IsSubscribed: singleResearch.IsResearchEmail, Description: 'Research Email', MaxColumnWidth: 71 },
            { Code: 'VM', IsSubscribed: singleResearch.IsResearchVoicemail, Description: 'Research Voicemail', }
        ]);

        return researchColumns;
    }

    onGridReady(params) {
        super.onGridReady(params);
    }

    cellClassRules = {
        'hasResearch-yes': (params) => params.value
    }
}
