import {ActivityStatus} from "../Models/ActivityStatus";
import * as _ from "lodash";

export const defaultStatusDisplayOrder = [
    'Invited',
    'Registered',
    'Interested',
    'Denied',
    'Declined',
    'Cancel Prior',
    'Attended',
    'Canceled',
    'No Show'
];

export function orderActivityStatusesByDefaultDisplayOrder(activityStatuses: ActivityStatus[]): ActivityStatus[] {

    let orderedStatuses = [];
    let leftOverStatuses = [...activityStatuses];

    for (let i = 0; i < defaultStatusDisplayOrder.length; i++) {
        let orderReference = defaultStatusDisplayOrder[i];
        let matchingActualStatus = activityStatuses.find(x => x.Name === orderReference);

        if (matchingActualStatus) {
            orderedStatuses.push(matchingActualStatus);
            leftOverStatuses.splice(leftOverStatuses.findIndex(x => x.Name === orderReference), 1);
        }
    }

    return orderedStatuses.concat(_.orderBy(leftOverStatuses, 'Name', 'asc'));

}