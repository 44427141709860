

<div  class="flex flex-vbox">
    <form [formGroup]="emailSearchFormInbox">
        <div class="flex flex-hbox flex-gap" style="margin-bottom: 5px">
            <input formControlName="searchTerm" type="search" placeholder="Search Subject" class="flex form-control input-sm" />
        </div>
    </form>
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [rowData]="rowData$ | async"
            [columnDefs]="inboxColumnDefs"
            [gridOptions]="gridOptions"
            (gridReady)="onGridReady($event)"
            [defaultColDef]="defaultColDef"
            [paginationPageSize]="20"
            rowSelection="single"
            (rowClicked)="rowClicked($event)"
    >
    </ag-grid-angular>

</div>