import {Component, EventEmitter, Input, OnInit} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {ContactService} from "../../Shared/Services/contact.service";
import {ContactFormComponent} from "../ContactForm/contact-form.component";
import {Contact} from "../../../Models/Contact";

@Component({
    selector: "app-contact-details",
    templateUrl: "./contact-details.component.html"
})

export class ContactDetailsComponent implements OnInit {
    @Input()
    contactId: number;

    @Input()
    contactDataChanged: EventEmitter<boolean>;

    @Input()
    activityDataChanged: EventEmitter<boolean>;

    contactDetails: Contact = {} as Contact;
    canEditContacts: boolean = false;

    constructor(private bsModalService: BsModalService,
                private contactService: ContactService) { }

    ngOnInit() {
        if(!this.contactId) return;
        this.getDetails();
        this.contactDataChanged.subscribe(() => this.getDetails());
    }

    getDetails() {
        this.contactService.getContactById(this.contactId).subscribe(contactDetails => {
            this.contactDetails = contactDetails;
        });
    }

    getContactAssistants(): string[] {
        if(!this.contactDetails.Assistants || this.contactDetails.Assistants.length === 0) {
            return [''];
        }
        return this.contactDetails.Assistants.map(assistant =>
            `${assistant.LastName}, ${assistant.FirstName} - ${assistant.AccountName}`)
            .sort();
    }

    openEditModal() {
        let modalRef = this.bsModalService.show(ContactFormComponent,
            {
                initialState: { contactId: this.contactId, defaultTab: "Details" },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-lg'
            });

        modalRef.content.dataUpdated.subscribe(() => {
            this.contactDataChanged.emit(true);
        });
    }

}
