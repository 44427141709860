import {Injectable} from "@angular/core";
import {defer, from, Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class ClipboardService {
    writeText(text: string): Observable<void> {
        return defer(() => from(navigator.clipboard.writeText(text)));
    }
}