<div class="modal-header">
    Add Contacts
</div>
<div class="modal-body" >
    <ng-container *ngIf="queryList$ | async as queryList">
        <div class="form-group">
            <div>Select how you want to add contacts.</div>
            <div class="btn-group"  btnRadioGroup [formControl]="addType">
                <div class="btn btn-primary btn-sm btn-toggle" btnRadio="call">Call List</div>
                <div class="btn btn-primary btn-sm btn-toggle" btnRadio="contact">Contact List</div>
                <div *ngIf="queryList.length > 0" class="btn btn-primary btn-sm btn-toggle" btnRadio="query">Saved Query</div>
            </div>    
        </div>
    
        <div class="form-group" *ngIf="addType.value === 'contact'">
            <app-contact-list-picker [formControl]="selectedContactLists" class="full-width-dropdown"></app-contact-list-picker>
        </div>
    
        <div class="form-group" *ngIf="addType.value === 'query' && queryList.length > 0">
            <app-query-list-picker [formControl]="selectedQuery" [emailableOnly]="true" class="full-width-dropdown"></app-query-list-picker>
        </div>
        
        <div class="form-group">
            <button type="button" class="btn btn-sm btn-default pull-right" (click)="cancel()">Cancel</button>
            <button type="button" class="btn btn-sm btn-primary" (click)="save()" [disabled]="!addType.value">Add</button>
        </div>
    </ng-container>
</div>