import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import * as _ from "lodash";
import {map, tap} from "rxjs/operators";
import {UserService} from "../../../Shared/Services/user.service";

@Component({
  selector: "app-backups-picker",
  templateUrl: "../BasePicker/base-picker.component.html",
  providers: pickerProviders(BackupsPickerComponent)
})
export class BackupsPickerComponent extends BasePickerComponent<any> {

  previousData: string[] = [];

  constructor(fb: UntypedFormBuilder, private userService: UserService){
    super(fb);

    this.placeHolder = "Backups";
    this.name = "Backup(s)";
    this.bindValue = "Id";
    this.bindLabel = "Name";
    this.searchable = false;
    this.alignRight = true;
  }

  ngOnInit() {
    super.ngOnInit();

    this.userService.getCurrentUser().subscribe(user => {
      this.customOnChange = (data: string[]) => {
        _.forEach(_.difference(this.previousData, data), backup => {
          this.userService.setBackupStatus(user.Id, backup, false).subscribe(() => {
            this.onChange(data);
          });
        });
        _.forEach(_.difference(data, this.previousData), backup => {
          this.userService.setBackupStatus(user.Id, backup, true).subscribe(() => {
            this.onChange(data);
          });
        });
        this.previousData = data;
      }

      this.items$ = this.userService.getUserBackups(user.Id).pipe(
        tap(users => {
          this.previousData = _.chain(users).filter(u => u.IsActive).map(u => u.BacksupUserId).value();
          this.writeValue(this.previousData);
        }),
        map(users => _.chain(users).sortBy(u => u.LastName).map(u => { return { Id: u.BacksupUserId, Name: `${u.LastName}, ${u.FirstName}` } }).value())
      )
    });
  }
}
