<div class="modal-content user-form">
    <form [formGroup]="emailForm">
        <div class="modal-header">
            <h3 class="modal-title pull-left">Compose Email</h3>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <label>From:</label>
                <select class="form-control">
                    <option>Wolff, Adam (awolff@rwbaird.com)</option>
                </select>
            </div>
            <div class="form-group">
                <label>To:</label>
                <app-multi-contact-select></app-multi-contact-select>
            </div>
            <div class="form-group">
                <label>CC:</label>
                <app-multi-user-select></app-multi-user-select>
                <label>
                    <input type="checkbox" /> CC Contact Brokers
                </label>
            </div>
            <div class="form-group">
                <label>Subject:</label>
                <input type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label>Body:</label>
                <tabset>
                    <tab heading="Content">
                        <div style="padding: 5px;">
                            <textarea class="form-control" style="height: 250px;"></textarea>    
                        </div>
                    </tab>
                    <tab heading="Preview">
                        <div style="padding: 5px;">
                            <textarea class="form-control" style="height: 250px;"></textarea>
                        </div>
                    </tab>
                </tabset>
            </div>
            <div class="form-group">
                <label style="font-weight: normal; display: block;">
                    <input type="checkbox" /> Include Signature
                </label>
                <label style="font-weight: normal; display: block;">
                    <input type="checkbox" /> Include Email Tracking
                </label>
                <label style="font-weight: normal; display: block;">
                    <input type="checkbox" /> Send a blind copy to awolff@rwbaird.com
                </label>
                <label style="font-weight: normal; display: block;">
                    <input type="checkbox" /> Log Activity with Email
                </label>
            </div>
            <div class="form-group">
                <label>Category:</label>
                <input type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label>Comment:</label>
                <input type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label>Tickers:</label>
                <input type="text" class="form-control" />
            </div>
            <div class="form-group">
                <label>Duration:</label>
                <input type="number" class="form-control" />
            </div>
            <div class="form-group">
                <label>Baird:</label>
                <app-multi-user-select></app-multi-user-select>
            </div>
        </div>
        <div class="modal-footer flex-hbox flex-gap">
            <button type="button" class="flex btn btn-primary" (click)="send()">Send</button>
            <button type="button" class="flex btn btn-primary" (click)="sendTest()">Send Test</button>
            <button type="button" class="flex btn btn-primary" (click)="saveDraft()">Save Draft</button>
            <button type="button" class="flex btn btn-default" (click)="close()">Discard</button>
        </div>
    </form>
</div>

