<div class="flex flex-vbox">

    <ag-grid-angular
        class="ag-theme-balham"
        [domLayout]="'autoHeight'"
        rowClass="non-clickable-row"
        [gridOptions]="gridOptions"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [tooltipShowDelay]="0"
        [pivotGroupHeaderHeight]="0"
        [pivotMode]="true"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>

</div>
