import {Component, EventEmitter, forwardRef, Output} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: "app-clear-filters-button",
    template: `
        <button type="button" (click)="clearFilters()" class="btn btn-default btn-sm" style="padding: 3px 5px 5px 5px; height: 30px;">
            <span class="fa-stack fa-1x">
                <i class="fa fa-filter fa-stack-1x" style="font-size: 15px"></i>
                <i class="fa fa-ban text-danger fa-stack-1x" style="font-size: 23px"></i>
            </span>
        </button>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ClearFiltersButtonComponent),
            multi: true
        }
    ]
})
export class ClearFiltersButtonComponent  {

    @Output('click') clickEvent = new EventEmitter();
    
    constructor(){ }

    clearFilters() {
        this.clickEvent.emit();
    }
}