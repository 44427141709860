<div class="page-content no-scroll flex-vbox">

    <app-page-banner 
            pagetitle="User Teams" 
            faicon="fa-gear"
            faiconcolor="#708090"
            [subTitleTemplate]="bannerSubTitleTemplate"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getPagingDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="userTeamSearchForm">
            <div class="input-group">
                <div class="input-group-btn">
                    <div class="btn btn-primary btn-sm" (click)="addUserTeam()"><span class="fa fa-plus"></span></div>
                </div>
                <input type="search"
                       class="form-control input-sm"
                       style="width: 250px;"
                       formControlName="searchTerm"
                       placeholder="Search" />
            </div>
        </form>
    </ng-template>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClicked($event)"
        >
        </ag-grid-angular>
        
    </div>
</div>
