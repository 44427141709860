<style>
    .error-msg {
        color: #a94442;
    }
    .fix-error-icon {
        top: 27px;
    }
</style>
<div *ngIf="displayError" >
    <span class="glyphicon glyphicon-remove form-control-feedback fix-error-icon"></span>
    <span class="sr-only">(error)</span>
    <div class="error-msg">
        {{errorMsg}}
    </div>
</div>