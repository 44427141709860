import {Component} from "@angular/core";

@Component({
    selector: "app-invalid-user",
    template: `
        <div>
            <h3 style="margin-left: 20px">
                Access Denied
            </h3>
        </div>
    `
})
export class InvalidUserComponent {
    constructor() { }
}
