import * as moment from "moment";
import {Injectable} from "@angular/core";
import {User} from "../../../Models/User";

const UsUserLocale = () => {
    console.log("Initialize moment locale to en");
    moment.locale("en", {
        longDateFormat: {
            LTS: "h:mm:ss A",
            LT: "h:mm A",
            L: "MM/DD/YY",
            LL: "MM/DD/YYYY",
            LLL: "MMMM D, YYYY h:mm A",
            LLLL: "dddd, MMMM D, YYYY h:mm A"
        }
    });
}
const UkUserLocale = () => {
    console.log("Initialize moment locale to en-gb");
    moment.locale("en-gb", {
        longDateFormat: {
            LT: "HH:mm",
            LTS: "HH:mm:ss",
            L: "DD/MM/YY",
            LL: "DD/MM/YYYY",
            LLL: "D MMMM YYYY HH:mm",
            LLLL: "dddd, D MMMM YYYY HH:mm"
        }});
}

@Injectable({
    providedIn: 'root',
})
export class UserLocaleService {
    constructor() { }
    
    initializeLocale(user: User): void {
        if (user.Domain !== "US") {
            UkUserLocale();
        } else {
            UsUserLocale();
        }
    }
}
