<div class="checkbox-list" *ngFor="let item of options">
    <label [class.checked]="item.checked">
        <i class="fa checkbox-icon" [ngClass]="item.checked ? 'fa-check-square' : 'fa-square-o'"></i>
        <input type="checkbox" [(ngModel)]="item.checked" [disabled]="item.disabled" (change)="onToggle(item)"> {{item.label}}
    </label>
</div>







