<style>
    /*ng-value {*/
    /*    background-color: #428bca;*/
    /*    border: 1px solid #357ebd;*/
    /*    border-radius: 4px;*/
    /*    color: #fff;*/
    /*    font-size: 1em;*/
    /*}*/
</style>
<div class="page-content no-scroll flex-vbox">
    <ng-template #bannerFormTemplate>
        <span></span>
    </ng-template>
    <app-page-banner 
            pagetitle="List Groups" 
            faicon="fa-gear" 
            faiconcolor="#708090"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>
    <div class="page-body" style="margin: 5px 10px 10px;display: grid; grid-gap: 15px; grid-template-columns: 1fr 3fr;">
        <div class="user-groups" style="grid-column: 1;">
            <form [formGroup]="listGroupsForm">
                <label class="control-label" style="display: block;">
                    <span style="margin-bottom: 5px;" class="btn btn-sm btn-primary pull-right" (click)="onAddListGroup()">New Group</span>
                    Groups List
                </label>
                <select class="form-control"
                        size="30"
                        style="font-family: Arial,Helvetica,sans-serif;"
                        formControlName="listGroupControl">
                    <option *ngFor="let listGroup of listGroups$ | async" [ngValue]="listGroup">
                        {{ listGroup.Name}}
                    </option>
                </select>
            </form>
        </div>
        <div class="" style="grid-column: 2;">
            <div [hidden]="!listGroup">
               <form [formGroup]="listGroupForm">
                   <div class="form-group">
                       <label for="groupName">Group Name</label>
                       <input id="groupName" type="text" formControlName="groupName" class="form-control" />
                       <app-form-error-display
                               [displayError]="isFieldValid('groupName')"
                               errorMsg="Please enter the group name">
                       </app-form-error-display>
                   </div>
                   <div class="form-group">
                       <label for="groupName">Users</label>
                       <ng-select [items]="users$ | async"
                                  bindLabel="Label"
                                  bindValue="Id"
                                  placeholder="Select users"
                                  appendTo="body"
                                  [multiple]="true"
                                  [selectOnTab]="true"
                                  [searchable]="true"
                                  formControlName="users">
                           <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                               <span [ngOptionHighlight]="search">{{item.Label}}</span>
                           </ng-template>
                       </ng-select>
                   </div>
                   <div class="form-group">
                       <label for="clientListNames">Client List Names</label>
                       <input id="clientListNames" type="text" formControlName="clientListNames" class="form-control" />
                   </div>
                   <div class="form-group">
                       <label for="queryNames">Query Names</label>
                       <input id="queryNames" type="text" formControlName="queryNames" class="form-control" />
                   </div>
                   <div class="form-group">
                       <button class="btn btn-success" type="button" style="margin-right: 10px" (click)="submit()">Save</button>
                       <button class="btn btn-danger" type="button" [disabled]="formStatus === 'new-item'" (click)="delete()" style="margin-right: 10px">Delete</button>
                       <span [hidden]="canDelete" class="text-danger">Can't delete, group has a client list or a query</span>
                   </div>
               </form>
            </div>
        </div>
    </div>
</div>
