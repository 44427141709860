import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {ContactService} from "../../../Shared/Services/contact.service";
import {combineLatest} from "rxjs";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {UserService} from "../../../Shared/Services/user.service";
import {startWith} from "rxjs/operators";
import {RoleName} from "../../../../Models/Role";

@Component({
    selector: "app-meeting-form-attendee-tab",
    template: `
        <div class="flex-vbox">
            <form [formGroup]="filterForm">
                <div class="form-group" style="margin-top: 10px;">
                    <div
                        class="btn btn-primary btn-sm btn-toggle"
                        btnCheckbox
                        appIfNotUserFeature="EventAdmin"
                        formControlName="myAccounts"
                    >
                        <span class="fa fa-sitemap"></span>
                    </div>
                </div>
            </form>

            <div class="flex-hbox form-group" style="font-weight: 600; margin-top: 10px;">
                <div class="flex">Name</div>
                <div class="flex-2">Account</div>
                <div class="flex">Tier</div>
                <div class="flex">Status</div>
                <div class="flex">Confirmed</div>
            </div>

            <div class="form-group flex-hbox" *ngFor="let attendee of attendees">
                <div class="flex">
                    {{attendee.Name}}
                </div>
                <div class="flex-2">
                    {{attendee.Account}}
                </div>
                <div class="flex">
                    {{attendee.Tier}}
                </div>
                <div class="flex">
                    {{attendee.ActivityStatus}}
                </div>
                <div class="flex">
                    {{attendee.Confirmed}}
                </div>
            </div>
        </div>
    `
})
export class MeetingFormAttendeeTabComponent implements OnInit {

    @Input()
    meetingId: number;

    attendees: AttendeeInfo[] = [];

    filterForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(false),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private activityService: ActivityService,
        private contactService: ContactService,
        private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe((user) => {
            let userIsEventAdmin = this.userService.hasUserFeature(user, 'EventAdmin');
            this.filterForm.controls.myAccounts.setValue(this.determineMyAccountsDefault(user.Role.Name, userIsEventAdmin));
        });

        let activities$ = this.activityService.getActivitiesByEventMeetingId(this.meetingId);

        let formUpdates$ = this.filterForm.valueChanges.pipe(
            startWith(this.filterForm.getRawValue())
        );

        activities$.subscribe((activities) => {
            let contactIds = activities.map(a => a.ContactId).filter((value, index, array) => array.indexOf(value) === index);
            let contacts$ = this.contactService.getContactsByIds(contactIds);

            combineLatest([
                contacts$,
                formUpdates$,
            ]).subscribe(([
                contacts,
                filterForm
            ]) => {
                let filteredContacts = contacts.filter(c => !filterForm.myAccounts || !!c.Account?.Tags?.find(t => t === "My"));
                this.attendees = [];

                filteredContacts.forEach((contact) => {
                    let activity = activities?.find(a => a.ContactId === contact.Id);

                    this.attendees.push({
                        Name: `${contact?.LastName}, ${contact?.FirstName}`,
                        Account: activity?.AccountName,
                        Tier: activity?.TierAtTimeOfActivity,
                        ActivityStatus: activity?.Status,
                        Confirmed: activity?.Confirmed !== null ? (activity?.Confirmed ? "Yes" : "No") : ""
                    });
                });
            });
        });
    }
    determineMyAccountsDefault(userRole: string, userIsEventAdmin: boolean):boolean {
        return !userIsEventAdmin && ((userRole === RoleName.Sales) || (userRole === RoleName.Traders));
    }
}

interface AttendeeInfo {
    Name: string,
    Account: string,
    Tier: string,
    ActivityStatus: string,
    Confirmed: string
}
