<div class="form-group flex-hbox flex-gap" [formGroup]="trendingTickerFilters">
    <input class="form-control input-sm ticker-count-filter" type="number" formControlName="tickerCount"/>
    <span class="filter-description">Tickers sorted by</span>
    <app-string-picker class="period-filter"
                       [items$]="period$"
                       formControlName="period"
                       [showTagCountLabel]="false"
                       [showSelectClearAll]="false"
                       [multiple]="false"
                       [closeOnSelect]="true">
    </app-string-picker>
    <span class="filter-description">week</span>
    <span class="fa fa-info-circle" [tooltip]="periodHelpInfo"></span>
    <span class="filter-description">percent change</span>
    <app-string-picker class="sort-filter"
                       [items$]="sortBy$"
                       formControlName="sortBy"
                       [showTagCountLabel]="false"
                       [showSelectClearAll]="false"
                       [multiple]="false"
                       [closeOnSelect]="true">
    </app-string-picker>
    <span class="filter-description">with minimum net change threshold of</span>
    <input class="form-control input-sm net-change-filter" type="number" formControlName="netChange"/>
    <span class="filter-description">and</span>
    <app-activity-category-picker formControlName="categories"
                                  [categories]="categories"
                                  name="Categories"
                                  placeHolder="0 Categories"
                                  [searchable]=false>
    </app-activity-category-picker>
</div>
<div class="trending-ticker-chart">
    <div *ngIf="!hasData && !isLoading" class="flex flex-vbox no-data-chart">No Chart Data</div>
    <app-loading *ngIf="isLoading"></app-loading>
    <div style="position:relative; height: 100%;"><canvas class="chart-canvas" #myChart></canvas></div>
</div>