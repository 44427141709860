<div class="modal-content airport-form">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="airportForm">
        <div class="modal-body">
            <div class="flex-hbox">
                <div class="form-group flex">
                    <label>City</label>
                    <app-travel-city-picker
                            formControlName="CityId"
                            [selectClearAll]="false"
                            [multipleSelect]="false"
                            [showTagCount]="false"
                            [closeAfterSelection]="true"
                            [setMaxItems]="1"
                            [placeHolderLabel]="'Select City'"
                    >
                    </app-travel-city-picker>
                </div>
                <div class="form-group flex">
                    <label>Type</label>
                    <app-airport-type-picker
                            formControlName="TypeId"
                            [showTagCount]="false"
                            [closeAfterSelection]="true"
                            [setMaxItems]="1"
                            [placeHolderLabel]="'Select Type'"
                    >
                    </app-airport-type-picker>
                </div>
                <div class="form-group flex-3"></div>
            </div>
            <div class="flex-hbox">
                <div class="form-group flex-3">
                    <label>Name</label>
                    <input class="form-control input-sm" type="text" formControlName="Name" />
                </div>

                <div class="form-group flex">
                    <label>Deleted</label>
                    <select class="form-control input-sm" formControlName="Deleted">
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex-2">
                    <label>Address Line 1</label>
                    <input class="form-control input-sm" type="text" formControlName="Address1" />
                </div>
                <div class="form-group flex-2">
                    <label>Address Line 2</label>
                    <input class="form-control input-sm" type="text" formControlName="Address2" />
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex">
                    <label>Address City</label>
                    <input class="form-control input-sm" type="text" formControlName="AddressCity" />
                </div>
                <div class="form-group flex">
                    <label>State</label>
                    <input class="form-control input-sm" type="text" formControlName="State" />
                </div>
                <div class="form-group flex">
                    <label>Zip</label>
                    <input class="form-control input-sm" type="text" formControlName="Zip" />
                </div>
                <div class="form-group flex">
                    <label>Country</label>
                    <input class="form-control input-sm" type="text" formControlName="Country" />
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex">
                    <label>Phone</label>
                    <input class="form-control input-sm" type="text" formControlName="Phone" />
                </div>
                <div class="form-group flex-3">
                    <label>Notes</label>
                    <textarea class="form-control input-sm" formControlName="Notes" rows="1"></textarea>
                </div>
            </div>
            
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" [disabled]="airportForm.invalid" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

