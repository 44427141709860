import {Routes} from "@angular/router";
import {UserCanActivateRoute} from "../../app-routing.guards";
import { UserListComponent } from "./UserList/user-list.component";
import {MsalGuard} from "@azure/msal-angular";
import { UserRoutePaths } from "./user-route-paths";

export const userRoutes: Routes = [
    {
        path: UserRoutePaths.UserList,
        component: UserListComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        data: {
            title: "User List"
        },
    },
];
