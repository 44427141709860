import {Route, RouterModule, Routes, UrlSegment, UrlSegmentGroup} from '@angular/router';
import { NgModule } from '@angular/core';
import {UserCanActivateRoute} from "./app-routing.guards";
import {HomeComponent} from "./Modules/Home/Home/home.component";
import {InvalidUserComponent} from "./Modules/Error/invalid-user.component";
import {PageNotFoundComponent} from "./Modules/Error/page-not-found.component";
import {MsalGuard} from "@azure/msal-angular";

export class BaseRoutePaths {
    static readonly Home: string = "home";
}

const routes: Routes =
[
    // Base Route
    {
        path: "",
        canActivate: [UserCanActivateRoute, MsalGuard],
        data : {
            title: "Instisel"
        },
        children: [

            // Access Denied
            {
                path: "accessdenied",
                component: InvalidUserComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Access Denied"
                },
            },

            // Home
            {
                matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
                    return (
                        segments.length === 0 ||
                        (segments.length === 1 && segments[0].path === BaseRoutePaths.Home)
                    )
                        ? { consumed: segments }
                        : null;
                },
                component: HomeComponent,
                canActivate: [UserCanActivateRoute, MsalGuard],
                data: {
                    title: "Home"
                },
            },

            // Unregistered Route
            {
                path: "**",
                component: PageNotFoundComponent,
                canActivate: [UserCanActivateRoute, MsalGuard],
                data: {
                    title: "Page Not Found"
                },
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [UserCanActivateRoute]
})
export class AppRoutingModule { }
