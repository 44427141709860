import {Contact} from "../../Models/Contact";
import {Account} from "../../Models/Account";
import {GridOptions} from "ag-grid-community";
import * as _ from "lodash";
import {getContactResearchStatus, getAccountResearchStatus, isContactable} from "./research-status";
import { Activity } from "../../Models/Activity";
import { User } from "../../Models/User";

export const defaultGridOptions: GridOptions = {
    suppressHorizontalScroll: true,
    suppressCellFocus: true,
    enableBrowserTooltips: true,
    suppressContextMenu: true,
    suppressAggFuncInHeader: true,
    pagination: true,
};

export const defaultColumnDef: any = {
    comparator: function(a,b) {
        if (typeof a === 'string') {
            return a.localeCompare(b);
        } else {
            return (a > b ? 1 : (a < b ? -1 : 0));
        }
    },
    sortable: true,
    sortingOrder: ["asc", "desc"],
    suppressMenu: true,
    suppressMovable: true,
    resizable: true,
    icons: {
        checkboxChecked: '<input type="checkbox" checked onclick="return false;">',
        checkboxUnchecked: '<input type="checkbox" onclick="return false;">',
    },
};

export const activityParticipantsValueGetter = (activity: Activity) => {
    let participants = [];

    if (activity.Category === "B") {
        participants = [ activity?.ContactedBy ];
    } else {
        participants = activity?.Participants?.map(p => p.UserId);
    }

    return participants?.sort().join() ?? "";
}

export const participantsToolTip = (activity: Activity, users: User[]) => {
    let participants = [];

    if (activity.Category === "B") {
        participants = [ activity?.ContactedBy ];
    } else {
        participants = activity?.Participants?.map(p => p.UserId);
    }

    return _.filter(users, u => _.includes(participants, u.Id)).map(bc => bc.LastName).sort().join(",");
}

export const getNoRowsOverlayTemplate = (type: string): string => {
    return `<span>No ${type} Found</span>`;
};

export const getGridDisplayWords = (gridApi: any): string => {
    if (!gridApi || !gridApi.paginationProxy) return '';

    if (gridApi.paginationProxy.getTotalPages() === 1) return `Displaying ${ gridApi.getDisplayedRowCount() }`;

    const page = gridApi.paginationGetCurrentPage();
    const pageSize = gridApi.paginationGetPageSize();
    const totalSize = gridApi.paginationGetRowCount();
    
    if (!totalSize) return `Displaying 0 - 0 of 0`;
    
    const first = (page * pageSize) + 1;
    const last = ((page + 1) * pageSize) > totalSize ? totalSize : (page + 1) * pageSize; 
    
    return `Displaying ${ first } - ${ last } of ${ totalSize }`;
};

export const isNoContactAccount = (account: Account): boolean => {
    return getAccountResearchStatus(account);
};

export const isNoContactReadOnlyAccount = (account: Account): boolean => {
    let researchStatus = getAccountResearchStatus(account);
    return researchStatus && researchStatus.Reason === 'Read Only Access';
};

export const isNoContactContact = (contact: Contact): boolean => {
    return !isContactable(contact);
};

export const isNoContactReadOnlyContact = (contact: Contact): boolean => {
    let researchStatus = getContactResearchStatus(contact);
    return researchStatus && researchStatus.Reason === 'Read Only Access';
};

export const accountTagComparator = (d1: string[], d2: string[]): number => {
    if (_.includes(d1, 'Vote') && !_.includes(d2, 'Vote')) return -1;
    if (!_.includes(d1, 'Vote') && _.includes(d2, 'Vote')) return 1;
    if (_.includes(d1, 'Wildcard') && !_.includes(d2, 'Wildcard')) return -1;
    if (!_.includes(d1, 'Wildcard') && _.includes(d2, 'Wildcard')) return 1;
    return 0;
};

export const durationHeaderTemplate = 
    '<div class="ag-cell-label-container" role="presentation">' +
    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    '  <div ref="eLabel" class="ag-header-cell-label fa fa-clock-o boldHeader" role="presentation">' +
    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
    '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    '  </div>' +
    '</div>';

export const defaultSidebar = {
    toolPanels: [
        {
            id: 'customize',
            labelDefault: 'Customize',
            labelKey: 'customize',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressValues: true,
                suppressPivotMode: true
            }
        }],
}
