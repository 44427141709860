import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-confirm-modal",
    templateUrl: "./confirm-delete-subscription.component.html"
})
export class ConfirmDeleteSubscriptionComponent implements OnInit {

    message: string;

    @Output()
    action = new EventEmitter<string>();

    constructor(public bsConfirmModalRef: BsModalRef) { }

    ngOnInit(): void { }

    confirmDelete(): void {
        this.bsConfirmModalRef.hide();
        this.action.emit('Subscription');
    }

    confirmDeleteInterestsAndContactSpecialtyMailings(): void {
        this.bsConfirmModalRef.hide();
        this.action.emit('SubscriptionAndInterestsAndContactSpecialtyMailings');
    }

    decline(): void {
        this.bsConfirmModalRef.hide();
    }
}
