<i
        class="fa fa-newspaper-o icon vote pointer"
        [popover]="researchAccessPopover"
        containerClass="research-access"
        [outsideClick]="true"
        tooltip="Research Access"
        placement="bottom"
        *ngIf="hasResearch && showPopover"
>
</i>

<i
        class="fa fa-newspaper-o icon vote pointer"
        tooltip="Research Access"
        container="body"
        *ngIf="hasResearch && !showPopover"
>
</i>

<ng-template #researchAccessPopover>
    <div
            class="research-access-popover"
            *ngIf="showPopover"
    >
        <div class="popover-title flex-hbox">
            <div class="flex-2">
                Research Access
            </div>
        </div>
        <div class="grid-container">
            <ag-grid-angular
                    style="width: 500px; height: 72px;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
                    [pagination]="false"
                    rowSelection="single">
            </ag-grid-angular>
        </div>
    </div>

</ng-template>
