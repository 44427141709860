<div class="flex flex-vbox">

    <div class="" style="margin-bottom: 5px;">
        <form [formGroup]="searchForm">
            <div class="flex-hbox flex-gap">
                <input type="search" placeholder="Search" class="flex form-control input-sm" formControlName="searchTerm" />
                <app-date-range-picker formControlName="dateRange"></app-date-range-picker>
            </div>
        </form>
    </div>
    
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>
    
</div>
