import {Component, OnInit} from "@angular/core";
import {getGridDisplayWords} from "../../Shared/ag-grid-options";
import {combineLatest} from "rxjs";
import {User} from "../../Shared/Models/user";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ColDef} from "ag-grid-community";
import {UserService} from "../../Shared/Services/user.service";
import {map, startWith} from "rxjs/operators";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserFormComponent} from "./user-form.component";
import * as _ from "lodash";
import { BaseGridComponent } from "../../Shared/base-grid.component";

@Component({
    selector: "app-admin-user-list",
    templateUrl: "./admin-user-list.component.html"
})
export class AdminUserListComponent extends BaseGridComponent<User> implements OnInit {

    columnDefs: ColDef[] = [
        { field: 'Id', sort: 'asc', flex: 1 },
        { field: 'FirstName', flex: 1 },
        { field: 'LastName', flex: 1 },
        { field: 'Email', flex: 1 },
        { colId: 'Team', headerName: 'Team', flex: 1, valueGetter: _.bind(this.teamValueGetter, this) },
        { headerName: 'Status', field: 'IsActive', flex: 1, valueFormatter: this.userActiveFormatter },
    ];

    userSearchBox: UntypedFormControl = this.fb.control('');

    constructor(
        private fb: UntypedFormBuilder,
        private userService: UserService,
        private modalService: BsModalService
    ) {
        super();
    }

    ngOnInit(): void {
        this.setRowData();
    }

    setRowData(): void {
        const allUsers$ = this.userService.getUsers(true);
        const formUpdate$ = this.userSearchBox.valueChanges.pipe(startWith(this.userSearchBox.value));

        this.rowData$ = combineLatest([allUsers$, formUpdate$]).pipe(
            map(([users, searchTerm]) => users.filter(u => this.showUser(u, searchTerm)))
        );
    }

    showUser(user: User, searchTerm: string): boolean {
        return objectSearchPredicate(user, searchTerm);
    }

    teamValueGetter(params): string {
        let role = params.data.Role && params.data.Role.Name || '';
        let team = params.data.Team && params.data.Team.Name || '';
        return this.userService.formatTeamName(role, team);
    }

    userActiveFormatter(params) {
        return params.value ? `Active` : `Inactive`;
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked($event: any) {
        this.openModalForm($event.data.Id);
    }

    addUser() {
        this.openModalForm("");
    }

    openModalForm(id: any): void {
        const initialState: Partial<UserFormComponent> = {
            userId: id.toString()
        };
        let modalRef = this.modalService.show(UserFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-lg' });

        modalRef.content.dataUpdated.subscribe(updated => {
            this.setRowData();
        });
    }
}
