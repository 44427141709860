import {Component, OnInit} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";
import { of } from "rxjs";

@Component({
    selector: "app-quarter-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(QuarterPickerComponent)
})
export class QuarterPickerComponent extends BasePickerComponent<any> implements OnInit {

    constructor(fb: UntypedFormBuilder) {
        super(fb);
        
        this.bindValue = "Id";
        this.bindLabel = "Name";

        this.placeHolder = "Quarter(s)";
        this.name = "Quarter(s)";
        this.multiple = true;
        this.showSelectClearAll = true;
        this.showTagCountLabel = true;

    }

    ngOnInit() {
        super.ngOnInit();
        this.items$ = of([
            { Id: 1, Name: "Q1" },
            { Id: 2, Name: "Q2" },
            { Id: 3, Name: "Q3" },
            { Id: 4, Name: "Q4" },
        ]);
    }
}
