import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription, throwError} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import {User, UserFeatureName} from "../../../Models/User";
import {RoleName} from "../../../Models/Role";
import * as _ from "lodash";
import {Team, TeamName} from "../../../Models/Team";
import {UserBacksup} from "../Models/user-backsup";
import {Person} from "../../../Models/Person";
import {IFilterStateOptions} from "../../../Models/FilterStateOptions";
import {InterestListFormValues} from "../../Contact/InterestList/interest-list-form-values";
import {UserTeam} from "../../../Models/UserTeam";
import { catchError } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    teamIdOverride: number = null;

    allUsers$: Observable<User[]>;

    constructor(
        private baseHttp: BaseHttpService,
        private configService: ConfigService
    ) { }

    getUser(retryCount: number = this.baseHttp.defaultRetryCount): Observable<User> {
        return this.baseHttp.getData<User>(`/user`, retryCount, false);
    }

    getUserForExternalSite(): Observable<User> {
        const retryCount: number = 0;

        return this.getUser(retryCount).pipe(
            catchError((error) => {
                if (!this.configService.getApiIsExternal()) {
                    this.configService.setApiIsExternal(true);
                    return this.getUser(this.baseHttp.defaultRetryCount);
                } else {
                    return throwError(error);
                }
            })
        );
    }

    getCurrentUser(): Subject<User> {
        if (this.currentUser.getValue()) {
            return this.currentUser;
        } else {
            this.currentUser = new BehaviorSubject<User>({} as User);
            this.updateCurrentUser();
        }

        return this.currentUser;
    }

    updateCurrentUser(retryCount: number = this.baseHttp.defaultRetryCount): Subscription {
        let user$ =
            this.configService.getSiteIsExternal()
                ? this.getUserForExternalSite()
                : this.getUser(retryCount);

        return user$
            .subscribe(
                user => {
                    this.currentUser.next(user);
                },
                error => {
                    this.currentUser.next({Id: 'INVALID', Features: []} as User);
                }
            );
    }

    setTeamIdOverride(teamId: number): void {
        this.teamIdOverride = teamId;
        this.updateCurrentUser();
    }

    getUserById(id: string, includeInactive?: boolean): Observable<User> {
        let params = {includeInactive: (includeInactive || false).toString()};
        return this.baseHttp.getDataWithConfig(`/user/id/${id}`, {params: params});
    }

    getUsers(forceRefresh: boolean = false): Observable<User[]> {
        return this.baseHttp.getCacheOrData(`/users`, 5 * 60 * 1000, forceRefresh);
    }

    getUserTeams(onlySalesTeams = false): Observable<UserTeam[]> {
        let params = {} as any;
        if (onlySalesTeams) {
            params.onlySalesTeams = onlySalesTeams;
        }
        return this.baseHttp.getDataWithConfig(`/userteams`, {params: params});
    }

    getAllTeams(): Observable<Team[]> {
        return this.baseHttp.getData(`/user/allTeams`);
    }

    getTeamName(roleName: string, teamName: string): string {
        if (roleName === teamName) {
            return _.includes(['Research', 'Sales', 'Traders'], roleName) ?
                `${roleName} - Other` :
                `Other - ${roleName}`;
        }

        return `${roleName} - ${teamName}`;
    }

    getUserGroups(): Observable<any[]> {
        return this.baseHttp.getData(`/usergroups`);
    }

    getBrokers(): Observable<User[]> {
        return this.baseHttp.getData(`/user/team/brokers`);
    }

    getAnalysts(): Observable<{ Id: string, LastName: string }[]> {
        return this.baseHttp.getData(`/ticker/coverage`);
    }

    getMyTeam(userId: string): Observable<any[]> {
        return this.baseHttp.getData(`/user/id/${userId}/membership`);
    }

    getSenderPermissions(userId: string): Observable<User[]> {
        return this.baseHttp.getData(`/user/${userId}/campaign/from/permissions`);
    }

    getSendOnBehalfOfPermissions(userId: string): Observable<User[]> {
        return this.baseHttp.getData(`/user/${userId}/campaign/onbehalfof/permissions`);
    }

    getUsersOnTeam(teamId: number): Observable<User[]> {
        return this.baseHttp.getData(`/user/teamid/${teamId}`);
    }
    
    getUserBackups(userId: string): Observable<UserBacksup[]> {
        return this.baseHttp.getData(`/user/${userId}/backups`);
    }

    updateUserBackups(userId: string, backupIds: string[]): Observable<any> {
        return this.baseHttp.postData(`/user/${userId}/backups`, backupIds);
    }

    setBackupStatus(userId: string, backupId: string, status: boolean): Observable<void> {
        return this.baseHttp.postDataWithConfig(`/user/${userId}/backup/${backupId}/status`, status, {headers: {'Content-Type': 'application/json'}});
    }

    formatTeamName(roleName: string, teamName: string): string {
        return teamName == roleName ? roleName : `${roleName} - ${teamName}`
    }

    isUserSalesOrManagement(user: User): boolean {
        return user &&
            user.Role.Name !== RoleName.Research &&
            (!(user.Role.Name === RoleName.Traders && user.Team.Name === TeamName.MarketMaker)) ||
            this.hasUserFeature(user, UserFeatureName.ForceAccountDetailToSalesView);
    }

    isMyAccount(user: User, accountTags: string[]): boolean {
        return !(_.isEqual(user.Coverage, "My Accounts") && !_.includes(accountTags, "My"));
    }
    
    canViewContactEmailWithDoNotContact(user: User): boolean {
        return user.Role.Name !== RoleName.Research &&
            user.Role.Name !== RoleName.ConferenceServices;
    }

    hasUserFeature(user: User, featureName: string): boolean {
        return user && _.includes(user.Features, featureName);
    }

    getCallers(): Observable<Person[]> {
        return this.baseHttp.getData(`/callerpeople`);
    }

    getFilters(userId: string): Observable<UserFilters> {
        const path = `/user/id/${userId}/filters`;
        return this.baseHttp.getData(path);
    }

    saveFilters(userId: string, filtersJSON: string): Observable<UserFilters> {
        const path = `/user/id/${userId}/filters`;
        return this.baseHttp.postDataWithConfig(path, filtersJSON, {headers: {"content-type": "application/json"}});
    }

    
}

interface UserFilters {
    InterestList: IFilterStateOptions,
    NewInterestList: InterestListFormValues
}
