import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {ToastrService} from "ngx-toastr";
import {debounceTime} from "rxjs/operators";
import {RoleName} from "../../../Models/Role";
import {UserService} from "../../Shared/Services/user.service";

@Component({
    selector: "app-contact-note",
    templateUrl: "./contact-note.component.html"
})

export class ContactNoteComponent implements OnInit {

    @Input()
    contactId: number;

    @Input()
    noteType: ContactNoteType;

    note = this.fb.control('');

    constructor(private fb: UntypedFormBuilder,
                private contactService: ContactService,
                private userService: UserService,
                private toastr: ToastrService) {}

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe(user => {
            if(user.Role.Name === RoleName.EquityCapitalMarkets) this.note.disable();

            if(this.noteType === 'Exceptions' && !this.userService.hasUserFeature(user, 'EventExceptions')) this.note.disable();
        });

        this.contactService.getContactNote(this.contactId).subscribe(notes => {
            this.note.patchValue(notes[this.noteType]);

            this.note.valueChanges.pipe(
                debounceTime(1000)
            ).subscribe(() => {
                let note = { [this.noteType]: this.note.value }
                this.contactService.updateContactNote(this.contactId, note).subscribe(() => {
                    this.toastr.success(`Saved ${this.noteType} Note`, "Success");
                });
            });
        });
    }
}

export type ContactNoteType =  "Professional" | "Personal" | "Exceptions" | "EES" | "Team";
