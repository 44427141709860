<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title pull-left">Edit Billing Info</h3>
    </div>
    <form [formGroup]="billingForm">
        <div class="modal-body">
            <div class="row">

                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Billing Info</label>
                        <textarea
                            [readOnly]="saving || loading"
                            class="form-control input-sm"
                            formControlName="billingLines"
                            style="height: 150px"
                        >
                        </textarea>
                    </div>
                </div>

                <div class="col-xs-6">
                    <div class="form-group">
                        <label>Invoice Contact(s)</label>
                        <div>
                            <app-multi-contact-select
                                [readOnly]="saving || loading"
                                [titleTemplate]=""
                                [showEditButtons]="false"
                                [accountId]="accountId"
                                [placeholder]="'Select an Invoice Contact'"
                                formControlName="invoiceContacts">
                            </app-multi-contact-select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Invoice Path</label>
                        <div>
                            <textarea
                                [readOnly]="saving || loading"
                                class="form-control input-sm"
                                formControlName="invoicePath"
                                style="height: 87px"
                            >
                            </textarea>
                        </div>
                    </div>
                    
                </div>
                <div class="col-xs-12">
                    <div class="form-group">
                        <label><input type="checkbox" formControlName="isHardDollar"> Hard Dollar</label>
                        <textarea
                                [readOnly]="saving || loading"
                                class="form-control input-sm"
                                formControlName="hardDollarNotes"
                                placeholder="Hard Dollar Notes"
                                style="height: 50px"
                        >
                        </textarea>
                    </div>
                    
                </div>

            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox">
                <button
                    [disabled]="!billingForm.valid || saving || loading"
                    type="button"
                    class="btn btn-primary flex"
                    style="margin: 15px;"
                    (click)="save()"
                >
                    Save
                </button>
                <button type="button" class="btn btn-default flex" style="margin: 15px;" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>