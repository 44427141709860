import {Component, Input, OnChanges, ViewEncapsulation} from "@angular/core";
import {AbstractControl} from "@angular/forms";
import {startWith, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Component({
    selector: "app-selection-display-list",
    templateUrl: "./selection-display-list.component.html",
    styleUrls: ['./selection-display-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SelectionDisplayListComponent implements OnChanges {
    @Input()
    name: string;

    @Input()
    selectionControl: AbstractControl;

    @Input()
    selectionKeyValues: { key: string, value: string }[];

    selectionList$: Observable<string>;

    constructor() { }

    ngOnChanges(): void {
        if (this.selectionKeyValues?.length > 0) {
            this.selectionList$ = this.selectionControl.valueChanges.pipe(
                startWith(this.selectionControl.value),
                map(selections => this.selectionKeyValues
                    .filter(s => selections.includes(s.key))
                    .map(s => s.value)
                    .sort((a, b) => a.localeCompare(b))
                    .join(', '))
            )
        } else {
            this.selectionList$ = this.selectionControl.valueChanges.pipe(
                startWith(this.selectionControl.value),
                map(selections => selections
                    .sort((a, b) => a.localeCompare(b))
                    .join(', '))
            )
        }
    }
}
