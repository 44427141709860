import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {WidgetModule} from "../Widget/widget.module";
import {UserListComponent} from "./UserList/user-list.component";
import {AgGridModule} from "ag-grid-angular-legacy";
import {RouterModule} from "@angular/router";
import {userRoutes} from "./user.routes";

@NgModule({
    imports: [
        CommonModule,
        WidgetModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule.withComponents([]),
        RouterModule.forChild(userRoutes)
    ],
    declarations: [ UserListComponent ]
})
export class UserModule {
    constructor(){
        console.log("registering the UserModule");
    }
}
