<div class="flex flex-vbox">

    <div style="margin-bottom: 5px;">
        <form [formGroup]="emailSearchForm">
            <div class="flex-hbox flex-gap">
                <input formControlName="searchTerm" type="search" placeholder="Search" class="flex form-control input-sm" />
                <app-date-range-picker formControlName="dateRange" [options]="dateRangeOptions"></app-date-range-picker>
                <app-team-picker formControlName="teamIds" [alignRight]="true" appRequiresFeature="ReadershipTeamPicker"></app-team-picker>
                <app-email-source-picker formControlName="emailType" [alignRight]="true" [showPostOnly]="false"></app-email-source-picker>
            </div>
        </form>
    </div>
    
    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            pagination="true"
            (gridReady)="onGridReady($event)"
            (rowClicked)="onRowClicked($event)"
    >
    </ag-grid-angular>
</div>