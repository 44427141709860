import {Injectable} from "@angular/core";
import * as _ from "lodash";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact, SimpleContact} from "../../../Models/Contact";
import {forkJoin, Observable, Subscription, zip} from "rxjs";
import {flatMap, map, mergeMap, switchMap, tap} from "rxjs/operators";
import {ContactNote} from "../../../Models/ContactNote";
import {TearSheetDocumentDefinitions} from "../../../Helpers/Pdf/TearSheetDocumentDefinitions";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as moment from "moment";
import {HoldingService} from "../../Shared/Services/holding.service";
import {Holding} from "../../../Models/Holding";
import {HoldingListDocumentDefinitions} from "../../../Helpers/Pdf/HoldingListDocumentDefinition";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

@Injectable({
    providedIn: 'root',
})
export class PrintReportsService {
    contacts: Contact[];
    filteredContactIds: number[];
    
    constructor(private contactService: ContactService,
    private holdingService: HoldingService) {

        (pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;
    }
    
    generateClientTearSheetReport(contactIds: number[], accountIds: number[]): Observable<any> {
        
        let allContactIds$ = this.getContacts(contactIds, accountIds);

        return allContactIds$.pipe(
            map(cts => {
                this.filteredContactIds = _.map(cts, c => c.Id);
                this.contacts = _.orderBy(cts, [c => c.Account.Name, c => c.LastName]);
                return this.filteredContactIds;
            }),
            switchMap(contactIds => forkJoin(... _.map(contactIds, contactId => this.contactService.getContactNote(contactId)))),
            map(contactNotes => {
                let filterContactNotes = _.filter(contactNotes, contactNote => !_.isNil(contactNote));

                const docDef = TearSheetDocumentDefinitions.getByContacts(this.contacts, filterContactNotes);

                pdfMake.createPdf(docDef).download(`TearSheet_${moment().format("LL")}_${moment().format("LT")}.pdf`);
                
                return true;
            })
        );
    }

    generateHoldingListReport(contactIds: number[], accountIds: number[]): Observable<any> {
        
        let allContactIds$ = this.getContacts(contactIds, accountIds);
        
        return allContactIds$.pipe(
            map(cts => {
                this.filteredContactIds = _.map(cts, c => c.Id);
                this.contacts = _.orderBy(cts, [c => c.Account.Name, c => c.LastName]);
                return this.filteredContactIds;
            }),
            switchMap(contactIds => forkJoin(... _.map(contactIds, contactId => this.holdingService.getContactHoldings(contactId)))),
            map(contactHoldings => {
                const holdings = _.flatMap(contactHoldings) as Holding[];

                const docDef = HoldingListDocumentDefinitions.getByHoldings(holdings, this.contacts);

                pdfMake.createPdf(docDef).download(`HoldingList_${moment().format("LL")}_${moment().format("LT")}.pdf`);
                
                return true;
            })
        );
    }
    
    getContacts(contactIds: number[], accountIds: number[]): Observable<Contact[]> {
        if(accountIds.length === 0){
            return this.contactService.getContactsByIds(contactIds);
        }
        
        let contactsObservables = _.map(accountIds, accountId =>  this.contactService.getAccountSimpleContacts(accountId));
        
        return forkJoin(...contactsObservables).pipe(
            map((contactsResults: SimpleContact[][]) => {
                    return _.chain(contactsResults)
                        .flatMap()
                        .map((c: SimpleContact) => c.Id)
                        .concat(contactIds)
                        .uniq()
                        .chunk(100)
                        .value();
                }),
            switchMap((cidChunks: number[][]) => {
                return forkJoin(cidChunks.map(cidChunk => this.contactService.getContactsByIds(cidChunk)))
            }),
            map((contactChunks: Contact[][]) => _.flatMap(contactChunks))
        );
    }
}