import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import * as _ from "lodash";

@Component({
    selector: 'app-grid-account-tag',
    template: `
        <span *ngIf="isVote" class="label-vote fa fa-check-circle" tooltip="Vote" container="body" [adaptivePosition]="false"></span>
        <span *ngIf="isWildcard" class="label-icon label-wildcard fa fa-asterisk" tooltip="Other Priorities" container="body" [adaptivePosition]="false"></span>
    `
})
export class AccountTagRenderer implements ICellRendererAngularComp {
    public params: any;

    isVote: boolean = false;
    isWildcard: boolean = false;
    
    agInit(params: any): void {
        this.params = params;
        this.isVote = (_.includes(params.value, 'Vote'));
        this.isWildcard = (_.includes(params.value, 'Wildcard'));
    }

    refresh(): boolean {
        return true;
    }
}