<ag-grid-angular
    style="height: 100%; min-height: 100%;"
    class="ag-theme-balham"
    rowClass="non-clickable-row"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [pivotGroupHeaderHeight]="0"
    [pivotMode]="true"
    (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
