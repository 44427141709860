import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, interval} from "rxjs";
import {ConfigService} from "./config.service";


@Injectable({
    providedIn: 'root',
})
export class PageService {
    private previous : number = 0;
    current : number = 0;
    
    constructor(private httpClient: HttpClient, configService: ConfigService) {
        interval(300000)
            .subscribe(this.next.bind(this));
        
        if(configService.getAppFeatures().indexOf("test-page-reload") > -1) {
            interval(30000)
                .subscribe(this.forceNewVersion.bind(this));
        }
    }
    
    private forceNewVersion(): void {
        console.log("new app version available");
        this.current = this.current - 1;
    }
    
    private next(): void {
        this.getIndex()
            .subscribe(r => {
                if(this.previous === 0){
                    this.previous = this.hash(r);
                } else {
                    this.current = this.hash(r)
                }
                if(this.newVersionAvailable()) {
                    console.log(`New app version detected.  This version ${this.previous}.  Next version ${this.current}`)
                }
            });
    }   
    
    getIndex() : Observable<string> {
        const headers = new HttpHeaders({
            Accept:"text/html"
        });
        return this.httpClient.get("/version.json", {withCredentials: true, headers: headers, responseType: "text"});
    }
    
    newVersionAvailable(): boolean {
        return this.previous !== this.current && this.current !== 0;
    }
    
    private hash(str) : number {
        const len = str.length;
        let hash = 0;
        if (len === 0) return hash;
        let i;
        for (i = 0; i < len; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
}