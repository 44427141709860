import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../Shared/Services/user.service";
import {EntitlementService} from "../../Shared/Services/entitlement.service";

@Component({
    selector: "app-unsubscribe-research-modal",
    template: `
        <div class="modal-header">
            <h5>Are you sure you want to unsubscribe research for this contact? The changes below will be applied to the contact.</h5>
        </div>
        <div class="modal-body">
            <p>1.	Update Marketing Email, Research VMs and Research Emails to "No"</p>
            <p>2.	Update Holdings Records (BM = No, Category = Not Interested)</p>
            <p>3.	Update Subscriptions (BM = No, Category = Not Interested)</p>
            <p>4.	Remove Specialty Mailings</p>
            <p>5.	Send email to Entitlements Team</p>
            <p>6.	Unsubscribe from Research Online</p>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox">
                <button type="button" class="flex btn btn-sm btn-primary" (click)="unsubscribe()">Unsubscribe</button>
                <button type="button" class="flex btn btn-sm btn-toggle" (click)="cancel()" >Cancel</button>
            </div>
        </div>
    `
})

export class UnsubscribeResearchModalComponent  {

    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();
    constructor(private userService: UserService,
                private entitlementService: EntitlementService,
                private contactService: ContactService,
                private modalRef: BsModalRef,
                private toastr: ToastrService) {}

    unsubscribe(): void {
        this.contactService.unsubscribeContactFromResearch(this.contactId)
            .subscribe(() => {
                this.userService.getCurrentUser()
                    .subscribe((user) => {
                        let today = new Date();
                        let todayFormatted = `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`;
                        this.entitlementService.getResearchOnlineEntitlement(this.contactId)
                            .subscribe((researchOnlineEntitlement) => {
                                if (!researchOnlineEntitlement || researchOnlineEntitlement.IsArchived) {
                                    this.closeModalAndShowSuccessToast();
                                } else {
                                    researchOnlineEntitlement.Notes += `\n\nUnsubscribed from Research Online on ${todayFormatted} by ${user.Id}`;
                                    this.entitlementService
                                        .deleteResearchOnlineEntitlement(researchOnlineEntitlement)
                                        .subscribe(() => this.closeModalAndShowSuccessToast());
                                }
                            });
                    });
            });
    }

    closeModalAndShowSuccessToast(): void {
        this.toastr.success('Contact has been unsubscribed from research.', 'Success');
        this.dataUpdated.emit(true);
        this.modalRef.hide();
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
