import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, Validators} from "@angular/forms";
import * as moment from "moment";
import {combineLatest} from "rxjs";
import {UserService} from "../../Shared/Services/user.service";
import {ContactService} from "../../Shared/Services/contact.service";
import {
    ResearchOnlineEntitlementNotes,
    ResearchOnlineEntitlementResponse,
    ResearchOnlineEntitlementToDelete,
    ResearchOnlineEntitlementToUpsert,
    EntitlementService
} from "../../Shared/Services/entitlement.service";
import {ToastrService} from "ngx-toastr";
import {User} from "../../Shared/Models/user";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";

export enum UpdateType {
    Create = "Create",
    Edit = "Edit",
    Reactivate = "Reactivate"
}

@Component({
    selector: "app-unsubscribe-research-modal",
    templateUrl: './research-online-form.component.html'
})
export class ResearchOnlineFormComponent implements OnInit {
    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    user: User;

    isArchived: boolean = false;

    placeholderUsername: string = "";

    errorMessage: string;

    isLoading: boolean = true;

    isUsernameAndPasswordActive: boolean = false;

    UpdateType = UpdateType;

    updateType: UpdateType = UpdateType.Create;

    researchOnlineFormGroup = this.fb.group({
        username: this.fb.control({value: '', disabled: true}),
        password: this.fb.control({value: '', disabled: true}, [Validators.required]),
        notes: this.fb.control(''),
        created: this.fb.control({value: "", disabled: true}),
        lastUpdated: this.fb.control({value: "", disabled: true}),
        isArchived: this.fb.control(false),
        createdBy: this.fb.control({value: "", hidden: true}),
        lastUpdatedBy: this.fb.control({value: "", hidden: true}),
    });

    constructor(
        private entitlementService: EntitlementService,
        private userService: UserService,
        private contactService: ContactService,
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private toastr: ToastrService,
        private modalService: BsModalService) {
    }

    ngOnInit() {
        this.isLoading = true;
        let user$ = this.userService.getCurrentUser();
        let contact$ = this.contactService.getContactById(this.contactId);
        let researchOnlineEntitlement$ = this.entitlementService.getResearchOnlineEntitlement(this.contactId);

        combineLatest([user$, researchOnlineEntitlement$, contact$])
            .subscribe(([user, researchOnlineEntitlement, contact]) => {
                if (!this.userService.hasUserFeature(user, UserFeatureName.CanEditResearchOnlineEntitlement)) {
                    this.modalRef.hide();
                }

                this.user = user;

                if (researchOnlineEntitlement) {
                    this.placeholderUsername = researchOnlineEntitlement.Username

                    this.updateType = UpdateType.Edit;
                    this.isUsernameAndPasswordActive = false;

                    let formObject = {
                        username: researchOnlineEntitlement.Username,
                        created: `${researchOnlineEntitlement.InstiselEnteredBy} ` +
                            `${moment(researchOnlineEntitlement.EnteredDate).format("LL LT")}`,
                        notes: researchOnlineEntitlement.Notes,
                        lastUpdated: `${researchOnlineEntitlement.InstiselLastUpdatedBy} `
                            +`${moment(researchOnlineEntitlement.LastUpdatedDate).format("LL LT")}`,
                        isArchived: researchOnlineEntitlement.IsArchived,
                        createdBy: researchOnlineEntitlement.InstiselEnteredBy,
                        lastUpdatedBy: researchOnlineEntitlement.InstiselLastUpdatedBy,
                    };

                    if (researchOnlineEntitlement.IsArchived) {
                        this.isArchived = true;
                        this.updateType = UpdateType.Reactivate;
                        formObject.username = "";
                    }

                    this.researchOnlineFormGroup.patchValue(formObject);
                } else {
                    this.activateUsernameAndPassword();

                    this.placeholderUsername = contact.Email;
                    this.researchOnlineFormGroup.get("created")
                        .patchValue(`${user.Id} ${moment().format("L LT")}`);
                    this.researchOnlineFormGroup.get("lastUpdated")
                        .patchValue(`${user.Id} ${moment().format("L LT")}`);
                    this.researchOnlineFormGroup.get("createdBy").patchValue(user.Id);
                    this.researchOnlineFormGroup.get("lastUpdatedBy").patchValue(user.Id);
                    this.researchOnlineFormGroup.markAsPristine();
                }
                this.isLoading = false;
            });
    }

    save() {
        this.isLoading = true;
        this.errorMessage = undefined;

        if (this.isUsernameAndPasswordActive) {
            this.updateResearchOnlineEntitlement();
        }
        else {
            this.saveNotes();
        }
    }

    delete() {
        let formValues = this.researchOnlineFormGroup.value;

        let researchOnlineEntitlementToDelete: ResearchOnlineEntitlementToDelete = {
            ContactId: this.contactId,
            Notes: formValues.notes,
            InstiselEnteredBy: formValues.createdBy,
            InstiselLastUpdatedBy: this.user.Id
        };

        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                message: "Are you sure you want to remove access to Research Online?"
            },
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-md'
        });

        confirmModalRef.content.action.subscribe((isConfirmed: boolean) => {
            this.isLoading = true;
            if (isConfirmed) {
                this.entitlementService.deleteResearchOnlineEntitlement(researchOnlineEntitlementToDelete)
                    .subscribe(() => {
                        this.confirmComplete("Research Online Removed");
                        this.isLoading = false;
                    });
            }
        });
    }

    cancel() {
        this.modalRef.hide();
    }

    activateUsernameAndPassword() {
        this.isUsernameAndPasswordActive = true;
        this.researchOnlineFormGroup.get('username').enable();
        this.researchOnlineFormGroup.get('password').enable();
    }

    private confirmComplete(message: string): void {
        this.toastr.success(message, 'Success');
        this.dataUpdated.emit(true);
        this.modalRef.hide();
    }

    private saveNotes() {
        let formValues = this.researchOnlineFormGroup.getRawValue();
        let researchOnlineEntitlementNotes: ResearchOnlineEntitlementNotes = {
            ContactId: this.contactId,
            Notes: formValues.notes,
            InstiselEnteredBy: formValues.createdBy,
            InstiselLastUpdatedBy: this.user.Id,
        };

        this.entitlementService.saveResearchOnlineEntitlementNotes(researchOnlineEntitlementNotes)
            .subscribe(() => {
                this.confirmComplete("Research Online Entitlements Saved")
                this.isLoading = false;
            });
    }

    private updateResearchOnlineEntitlement() {
        let formValues = this.researchOnlineFormGroup.getRawValue();
        let username = formValues.username
        if (username === '') {
            username = this.placeholderUsername;
        }

        let researchOnlineEntitlementToUpsert: ResearchOnlineEntitlementToUpsert = {
            ContactId: this.contactId,
            Username: username,
            Notes: formValues.notes,
            InstiselEnteredBy: formValues.createdBy,
            InstiselLastUpdatedBy: this.user.Id,
            Password: formValues.password
        };
        
        this.entitlementService.saveResearchOnlineEntitlement(researchOnlineEntitlementToUpsert)
            .subscribe((response: ResearchOnlineEntitlementResponse) => {
                if (response.HasError) {
                    this.errorMessage = response.ErrorMessage
                } else {
                    this.confirmComplete("Research Online Entitlements Saved");
                }
                this.isLoading = false;
            });
    }
}
