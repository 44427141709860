import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment";
import {Activity} from "../../../Models/Activity";
import {ActivityService} from "../../Shared/Services/activity.service";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Observable, of} from "rxjs";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {EmailDraftService} from "../../Shared/Services/email-draft.service";
import {map, switchMap, tap} from "rxjs/operators";
import {RoleName} from "../../../Models/Role";
import {TeamName} from "../../../Models/Team";
import {ActivityFeatureManager} from "../../../ActivityFeatureManager";
import {AnalyticsService} from "../../Shared/Services/analytics.service";
import { ActivityEmailFormComponent } from "./activity-email-form.component";
import { ActivityCategoryCode } from "../../../Models/ActivityCategory";

@Component({
    selector: "app-activity-form",
    templateUrl: "./activity-form.component.html",
    styleUrls: ["./activity-form.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ActivityFormComponent implements OnInit {

    @Input()
    contactIds: number[];

    @Input()
    accountId: number;

    @Input()
    activityId: number;

    @Output()
    dataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    createdBy: UntypedFormControl = this.fb.control({
        value: "",
        disabled: true
    });

    activity: UntypedFormControl = this.fb.control({
        Tickers: ""
    } as Activity);

    contactMoved: boolean = false;

    canCopyActivity: boolean = false;

    disabled: boolean = false;

    loading: boolean = false;
    saving: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private activityService: ActivityService,
        private toastrService: ToastrService,
        private modalRef: BsModalRef,
        private userService: UserService,
        private contactService: ContactService,
        private modalService: BsModalService,
        private analyticsService: AnalyticsService,
    ) {}

    ngOnInit() {
        this.loading = true;

        if (this.activityId) {
            let user$ = this.userService.getCurrentUser();
            let activity$ = this.activityService.getActivityById(this.activityId);

            combineLatest([activity$, user$])
                .subscribe(([activity, user]) => {
                    if (activity.MeetingId || activity.EventMeetingId) {
                        this.disabled = true;
                        this.activity.disable();
                    } else {
                        let availableCategories =
                            new ActivityFeatureManager(
                                user.Features.map(f => { return { Name: f } }),
                                user.Role.Name,
                                user.Team.Name
                            ).getCategories().map(c => c.Code);

                        this.canCopyActivity = availableCategories.includes(activity.Category);
                    }

                    this.contactMoved = activity.AccountId !== activity.ActivityAccountId;

                    activity.ContactIds = activity.ContactIds ?? [activity.ContactId];
                    activity.CallDate = moment(activity.CallDate).toDate();
                    activity.ParticipantUserIds = activity.Participants.map(p => p.UserId);

                    this.activity.patchValue(activity);
                    this.createdBy.patchValue(`${activity.CreatedBy} ${moment(activity.CreatedDate).format("LL")}`);

                    this.loading = false;
                });

        } else if (this.contactIds?.length > 0) {

            let user$ = this.userService.getCurrentUser();
            let bairdContacts$ = this.contactService.getBairdContacts();
            let contacts$ = this.contactService.getContactsByIds(this.contactIds);

            combineLatest([user$, bairdContacts$, contacts$])
                .subscribe(([user, bairdContacts, contacts]) => {
                    this.accountId = contacts[0].Account.Id;
                    this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);

                    this.initEmptyActivity(
                        this.contactIds,
                        user.Id,
                        user.Role.Name,
                        user.Team.Name
                    );

                    this.loading = false;
                });

        } else if (this.accountId) {

            let user$ = this.userService.getCurrentUser();
            let bairdContacts$ = this.contactService.getBairdContacts();

            combineLatest([user$, bairdContacts$])
                .subscribe(([user, bairdContacts]) => {
                    this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);

                    this.initEmptyActivity(
                        this.contactIds,
                        user.Id,
                        user.Role.Name,
                        user.Team.Name
                    );

                    this.loading = false;
                });
        }
    }

    initEmptyActivity(
        contactIds: number[],
        userId: string,
        userRoleName: string,
        userTeamName: string
    ) : void {
        this.activity.patchValue({
            Id: 0,
            CallDate: moment().toDate(),
            ContactIds: contactIds,
            Category: this.getDefaultCategory(userRoleName, userTeamName),
            SubCategory: null,
            MeetingType: null,
            Status: "",
            EventId: null,
            Duration: null,
            Side: "",
            Price: null,
            Comment: "",
            Tickers: "",
            ParticipantUserIds: [userId],
            BairdCaller: userId,
        } as Partial<Activity>);
    }

    close() {
        this.modalRef.hide();
    }

    save(sendEmail: boolean) {
        if (this.saving || this.loading || this.disabled) {
            return;
        }

        this.saving = true;

        let createUpdate$: Observable<any>;
        let saveMessage: string = "";

        this.activity.value.Participants = this.activity.value.ParticipantUserIds.map(u => ({ UserId: u, TeamId: null }));

        if (this.activityId) {
            createUpdate$ = this.activityService.updateActivity(this.activity.value);
            saveMessage = "Activity Updated";
        } else {
            createUpdate$ = this.activityService.createActivity(this.activity.value);
            saveMessage = "Activity Created";
        }

        createUpdate$
            .subscribe(activity => {
                if (sendEmail) {
                    this.openActivityEmailModal(activity)
                        .subscribe(() => {
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        });
                    this.closeForm(saveMessage);
                } else {
                    this.closeForm(saveMessage);
                }
            });
    }

    closeForm(message: string): void {
        this.displayAndLogSaveSuccess(message);
        this.modalRef.hide();
    }

    displayAndLogSaveSuccess(message: string): void {
        this.toastrService.success(message);
        this.dataUpdated.emit(true);
        this.analyticsService.logEvent("activity_save", { message: message });
    }

    openActivityEmailModal(activity: Activity): Observable<any> {
        let activityEmailModalRef = this.modalService.show(
            ActivityEmailFormComponent, {
                ignoreBackdropClick: true,
                backdrop: true,
                initialState: {
                    activityId: activity.Id,
                    contactIds: activity.ContactIds,
                    clientEntertainmentActivityComment: this.activity.value.ClientEntertainmentComment,
                    excelModelTickers: this.activity.value.ExcelModelTickers
                }
            }
        );

        return activityEmailModalRef.content.action;
    }

    delete() {
        if (this.activityId) {
            this.saving = true

            let delete$ = this.activityService.deleteActivity(this.activityId);

            let confirmRef = this.modalService.show(ConfirmModalComponent, {
                ignoreBackdropClick: true,
                backdrop: true,
                initialState: {message: `Are you sure you want to delete this Activity?`}
            });

            confirmRef.content.declineAction.subscribe(() => {
                this.saving = false;
            });

            confirmRef.content.action
                .subscribe(() => {
                    delete$
                        .subscribe(() => {
                            this.saving = false;
                            this.toastrService.success(`Activity Deleted`);
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        })
                });
        }
    }

    formValid(): boolean {
        return !this.disabled && this.activity.valid;
    }

    copyActivity(): void {

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.activityId = null;
                this.canCopyActivity = false;
                this.contactMoved = false;

                let activity = this.activity.value;

                activity.Id = 0;
                activity.ActivityAccountId = null;
                activity.ContactIds = [];

                this.activity.patchValue(activity);

                this.createdBy.patchValue(`${user.Id} ${moment().format("LL")}`);
            });
    }

    private getDefaultCategory(userRoleName: string, userTeamName: string): string {
        if (userRoleName === RoleName.Traders && userTeamName === TeamName.MarketMaker) {
            return ActivityCategoryCode.EmailInboundExchange;
        }

        return "";
    }
}
