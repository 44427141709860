<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerTitleTemplate>
        <span class="entitlement-list-title">
            <h4>Entitlements</h4>
        </span>
    </ng-template>
    
    
    <app-page-banner
            pagetitle="Entitlements"
            faicon="fa-newspaper-o"
            faiconcolor="#800080"
            bannerClass="flex-6"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
            
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <div class="flex-hbox" style="text-align: left; margin-left: 30px;">
            <div class="flex-2 flex-hbox">
                <span style="font-weight: bold;">FactSet Updated:&nbsp; </span> {{ mostRecentUpdateDate }}
            </div>
        </div>    
            
            <form class="form-inline" [formGroup]="entitlementSearchForm">
                <div class="form-group">

                    <app-string-picker
                            formControlName="statuses"
                            [items$]="statusOptions$"
                            [multiple]="true"
                            [showSelectClearAll]="true"
                            [closeOnSelect]="true"
                            [showTagCountLabel]="true"
                            [placeHolder]="'Entitlement Status'"
                            [name]="'Entitlement Status(es)'"
                    >
                    </app-string-picker>
                </div>

                <div class="form-group">
                    <app-business-unit-picker
                            formControlName="businessUnits"
                            [multiple]="true"
                            [showSelectClearAll]="true"
                            [closeOnSelect]="false"
                            [showTagCountLabel]="true"
                            [placeHolder]="'Business Unit'"
                    >
                    </app-business-unit-picker>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 145px;"
                               formControlName="searchTerm"
                               placeholder="Search"/>
                    </div>
                </div>

                <button type="button"
                        class="btn btn-sm btn-default"
                        appIfUserFeature="EventAdmin"
                        (click)="openUploadModal()">
                    <span class="fa fa-upload"></span>
                </button>
            </form>
        
    </ng-template>

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getPagingDisplayWords() }}</span>
    </ng-template>
    
    <div class="page-body flex">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (cellClicked)="onCellClicked($event)"
                [rowClassRules]="rowClassRules"
        >
        </ag-grid-angular>
    </div>
</div>
