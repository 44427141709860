import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {ActivityService} from "../../../Shared/Services/activity.service";
import {MeetingType} from "../../../../Models/MeetingType";
import {map} from "rxjs/operators";
import * as _ from "lodash";

@Component({
    selector: "app-activity-meeting-type-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(ActivityMeetingTypePickerComponent)
})
export class ActivityMeetingTypePickerComponent extends BasePickerComponent<any> {

    @Input()
    searchable: boolean = true;
    
    @Input()
    placeHolder: string = "";

    @Input()
    multiple: boolean = true;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    selectClearAll: boolean = true;
    
    @Input()
    forActivityForm: boolean = false;
    
    constructor(fb: UntypedFormBuilder, private activityService: ActivityService){
        super(fb);
        
        this.name = "Meeting Type(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.showSelectClearAll = this.selectClearAll;
        
        this.items$ = this.activityService.getActivityMeetingTypes().pipe(
            map(types => types.filter(t => t.Name !== "")),
            map(types => types.map(t => { return { Id: this.forActivityForm ? t.Name : t.Id, Name: t.Name }})),
            map(types => {
                if (!this.multiple) {
                    types.unshift({ Id: '', Name: ' ' });
                }
                return types;
            })
        )
        
        // if(this.forActivityForm) {
        //     this.items$ = this.activityService.getActivityMeetingTypes().pipe(
        //         map(meetings => _.filter(meetings.map(m => { return { Id: m.Name, Name: m.Name }}), m => m.Name !== ""))
        //     );
        // } else {
        //     this.items$ = this.activityService.getActivityMeetingTypes().pipe(
        //         map(meetings => _.filter(meetings, m => m.Name !== ""))
        //     );
        // }
    }
}
