import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import * as _ from "lodash";
import {map} from "rxjs/operators";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {TravelTerritoryService} from "../../../CorpTravelAdmin/Services/travel-territory.service";

@Component({
    selector: "app-travel-territory-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(TravelTerritoryPickerComponent)
})
export class TravelTerritoryPickerComponent extends BasePickerComponent<any> {

    @Input()
    setMaxItems: number;

    @Input()
    multipleSelect: boolean = true;

    @Input()
    selectClearAll: boolean = true;

    @Input()
    showTagCount: boolean = true;

    @Input()
    closeAfterSelection: boolean = false;

    @Input()
    includeNullOption: boolean = false;

    @Input()
    placeHolderLabel: string = "";

    constructor(
        fb: UntypedFormBuilder,
        private travelTerritoryService: TravelTerritoryService
    ) {
        super(fb);

        this.name = "Territory(s)";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = true;
    }

    ngOnInit() {
        this.multiple = this.multipleSelect;
        this.showSelectClearAll = this.selectClearAll;
        this.showTagCountLabel = this.showTagCount;
        this.closeOnSelect = this.closeAfterSelection;
        this.placeHolder = this.placeHolderLabel;
        this.maxItems = this.setMaxItems;
        this.customSearchable = true;
        this.searchable = false;

        super.ngOnInit();
        this.customOnChange = (data: any) => {
            if(data == "NoTravelTerritorySelected"){
                this.onChange(null);
            } else {
                this.onChange(data);
            }
        }

        this.items$ = this.travelTerritoryService.getTravelTerritories().pipe(
            map(travelTerritories => _.sortBy(travelTerritories, m => m.Name)),
            map(travelTerritories => {
                if (this.includeNullOption) {
                    travelTerritories.unshift({Id: null, Name: "",});
                }
                return travelTerritories;
            })
        );
    }
}
