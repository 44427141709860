import {Component, forwardRef, OnInit, ViewChild} from "@angular/core";
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ConfigService} from "../../Shared/Services/config.service";
import {CKEditor4, CKEditorComponent} from "ckeditor4-angular";
import {Observable, of} from "rxjs";

declare var CKEDITOR: any;

@Component({
    selector: "app-html-editor",
    templateUrl: "./html-editor.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => HtmlEditorComponent),
            multi: true
        }
    ]
})
export class HtmlEditorComponent implements OnInit, ControlValueAccessor {

    onChange: (data: string) => void;

    html: UntypedFormControl = this.fb.control('');

    @ViewChild('editor', { static: true})
    editor: CKEditorComponent;
    
    constructor(private fb: UntypedFormBuilder, private configService: ConfigService) { }

    ngOnInit(): void {
        
        if (typeof (CKEDITOR) === 'object') {
            this.setConfig();
        }
        
        this.html.valueChanges
            .subscribe(data => {
                if (this.onChange) {
                    this.onChange(data);
                }
            });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    writeValue(obj: string): void {
        this.html.patchValue(obj);
    }
    
    setDisabledState(isDisabled: boolean) {
        this.editor?.instance?.setReadOnly(isDisabled);
    }

    public insertText(str: string): void {
        this.editor?.instance?.insertText(str);   
    }
    
    public forceUpdate(): Observable<boolean> {
        this.html.patchValue(this.editor.instance.getData());
        return of(true);
    }

    onNamespaceLoaded() {
        this.setConfig();
    }
    
    setConfig(): void {
        this.editor.config = {
            enterMode: CKEDITOR.ENTER_BR,
            extraPlugins: "autogrow,basicstyles,font,uploadimage,colorbutton,tableresize",
            autoGrow_minHeight: 400,
            autoGrow_bottomSpace: 50,
            fontSize_sizes: "8/8pt;9/9pt;10/10pt;12/12pt;14/14pt;16/16pt;18/18pt;20/20pt;22/22pt;24/24pt;26/26pt;28/28pt;36/36pt;48/48pt;72/72pt",
            font_defaultLabel: "Arial",
            fontSize_defaultLabel: "10",
            resize_enabled: false,
            colorButton_colors: "FFFFFF,F2F2F2,D8D8D8,BFBFBF,A5A5A5,7F7F7F,595959,3F3F3F,262626,000000,CC0000,FF0000,FFCC00,FFFF00,99CC99,009966,0099FF,0066CC,003366,663399",
            colorButton_colorsPerRow: 10,
            disallowedContent: 'img{width,height}; script; meta; iframe; *[on*]',
            allowedContent: {
                $1: {
                    elements: CKEDITOR.dtd,
                    attributes: true,
                    styles: true,
                    classes: true
                }
            },
            uploadUrl: `${this.configService.getActiveApiUrl()}/upload`,
            imageUploadUrl: `${this.configService.getActiveApiUrl()}/upload`,
            toolbar: [
                {
                    name: "history",
                    items: ["Undo", "Redo"]
                },
                {
                    name: "basics",
                    items: ["Bold", "Italic", "Underline"]
                },
                {
                    name: "lists",
                    items: ["BulletedList", "NumberedList"]
                },
                {
                    name: "font",
                    items: ["Font", "FontSize"]
                },
                {
                    name: "colors",
                    items: ["TextColor", "BGColor"]
                },
                {
                    name: "links",
                    items: ["Link", "Unlink"]
                },
                {
                    name: "insert",
                    items: ["Image", "Table"]
                },
                {
                    name: "source",
                    items: ["Source"]
                },
                {
                    name: "help",
                    items: []
                }
            ],
            removeButtons: "",
            removePlugins: "elementspath,editorresize,showborders",
        };
    }
}
