<div class="form-group flex-hbox flex-gap" [formGroup]="tickerTrendlineFilters">
    <span class="filter-description">Calls for</span>
    <input class="form-control input-sm selected-ticker" style="text-transform: uppercase" type="text" formControlName="selectedTicker" />
    <span class="filter-description">by</span>
    <app-string-picker class="group-filter"
                       [items$]="groupBy$"
                       formControlName="groupBy"
                       [showTagCountLabel]="false"
                       [showSelectClearAll]="false"
                       [multiple]="false"
                       [closeOnSelect]="true">
    </app-string-picker>
    <span class="filter-description">over the last</span>
    <input class="form-control input-sm days-of-history" type="number" formControlName="daysOfHistory" />
    <span class="filter-description">days</span>
</div>
<div class="ticker-trendline-chart">
    <div *ngIf="!hasData" class="flex flex-vbox no-data-chart">No Chart Data</div>
    <div style="position:relative; height: 100%;"><canvas class="chart-canvas" #myChart></canvas></div>
</div>