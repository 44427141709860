import { Component, OnInit } from "@angular/core";
import {UserFeature, UserFeaturesService} from "./user-features.service";
import {ToastrService} from "ngx-toastr";
import * as _ from "lodash";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: "app-admin-user-features",
    templateUrl: "./user-features.component.html"
})
export class UserFeaturesComponent implements OnInit {

    featureSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control('')
    });

    userFeatureNames$: Observable<string[]>;
    selectedUserFeature: string = '';
    userFeatures: UserFeature[];

    newUserId: string;

    constructor(private fb: UntypedFormBuilder,
                private userFeatureService: UserFeaturesService,
                private modalService: BsModalService,
                private toastrService: ToastrService) { }

    ngOnInit(): void {

        const allUserFeatureNames$ = this.userFeatureService.getUserFeatures();
        const formUpdate$ = this.featureSearchForm.valueChanges.pipe(startWith(this.featureSearchForm.getRawValue()));

        this.userFeatureNames$ = combineLatest([allUserFeatureNames$, formUpdate$]).pipe(
            map(([features, searchForm]) => features.filter(f => f.toLocaleLowerCase().indexOf(searchForm.searchTerm.toLocaleLowerCase()) > -1))
        );
    }

    selectUserFeature(userFeatureName: string): void {
        this.selectedUserFeature = userFeatureName;
        this.userFeatures = [];
        this.userFeatureService.getUsersByUserFeature(userFeatureName)
            .subscribe(userFeatures => {
                this.userFeatures = _.sortBy(userFeatures, uf => uf.LastName);;
            });
    }

    addUserFeature(): void {
        if (this.newUserId && this.selectedUserFeature) {
            this.userFeatureService.addUserFeature(this.newUserId, this.selectedUserFeature)
                .subscribe(newUserFeature => {
                    this.userFeatures.push(newUserFeature);
                    this.userFeatures = _.sortBy(this.userFeatures, uf => uf.LastName);
                    this.newUserId = '';
                    this.toastrService.success("User Feature Added");
                },
                (err) => {
                    this.toastrService.error(`Error: ${err}`);
                });
        }
    }

    deleteUserFeature(userFeature: UserFeature): void {

        let modalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                message: `Are you sure you want to remove ${userFeature.UserId} from user feature ${userFeature.UserFeatureName}`
            },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        modalRef.content.action.subscribe(isConfirmed => {
            this.userFeatureService.deleteUserFeature(userFeature)
                .subscribe(x => {
                    this.userFeatures = _.filter(this.userFeatures, uf => uf.UserId !== userFeature.UserId);
                    this.toastrService.success("User Feature Deleted");
                });
        });
    }
}
