<div class="page-content no-scroll flex-vbox interest-list">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <div style="display: inline-block; margin-left: 10px;">
            <form class="form-inline" [formGroup]="holdingSearchForm">
                <div class="form-group">
                    <div class="input-group">
                        <div class="input-group-btn">
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="grid">
                                Grid
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="favorites"
                                 tooltip="Favorites"
                                 container="body">
                                <span class="fa fa-star"></span>
                            </div>
                            <div class="btn btn-primary btn-sm btn-toggle"
                                 btnCheckbox
                                 formControlName="myAccounts"
                                 tooltip="My Accounts"
                                 container="body">
                                <span class="fa fa-sitemap"></span>
                            </div>
                        </div>
                        <input type="search"
                               class="form-control input-sm"
                               style="width: 123px;"
                               formControlName="contactSearch"
                               placeholder="Contact contains text" />
                    </div>
                </div>
                
                <div class="form-group">
                    <app-tier-picker formControlName="tiers"></app-tier-picker>
                </div>

                <div class="form-group">
                    <app-string-array-input formControlName="tickers" [placeholder]="'Tickers'" ></app-string-array-input>
                </div>

                <div class="form-group">
                    <app-instisel-sector-picker formControlName="instiselSectors" placeHolder="Sector(s)"></app-instisel-sector-picker>
                </div>
                
                <div class="form-group">
                    <app-analyst-picker formControlName="analysts" [defaultAnalyst]="true"></app-analyst-picker>
                </div>
                
                <div class="form-group">
                    <app-holding-category-picker formControlName="categories" placeHolder="Cat(s)" name="Cat(s)"></app-holding-category-picker>
                </div>
                
                <div class="form-group">
                    <select formControlName="searchBy" class="form-control input-sm">
                        <option value="Holding">Search by H&I</option>
                        <option value="Activity">Search by Activity</option>
                        <option value="Both">Search by Both</option>
                    </select>
                </div>
                
                <div class="form-group">
                    <button type="button" class="btn btn-sm btn-default" (click)="moreFilters()" tooltip="More filters" container="body">
                        <span class="fa fa-filter" style="font-size: 14px;"></span>
                        ({{ holdingSearchForm.get('moreFilters').value.length }})
                    </button>
                </div>
                
                <div class="form-group">
                    <app-clear-filters-button (click)="resetFilters()" tooltip="Reset Filters" container="body"></app-clear-filters-button>
                </div>
                
                <div class="form-group">
                    <div class="btn-group" dropdown>
                        <button class="btn btn-sm btn-default" type="button" (click)="saveFilters()" tooltip="Save filters" container="body"><span class="fa fa-save"></span></button>
                        <button id="button-basic" dropdownToggle type="button" class="btn btn-sm btn-success dropdown-toggle" aria-controls="dropdown-basic">
                            <span class="fa fa-gear"></span> <span class="caret" style="margin-left: 2px"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                            role="menu" aria-labelledby="button-basic">
                            <li role="menuitem" [ngClass]="{'disabled': !hasContacts}">
                                <a class="dropdown-item" (click)="hasContacts && copyEmailToClipboard()">Copy Emails to Clipboard</a>
                            </li>
                            <li role="menuitem" [ngClass]="{'disabled': !hasContacts}">
                                <a class="dropdown-item" (click)="hasContacts && createActivities()">Create Activities from List</a>
                            </li>
                            <li role="menuitem" [ngClass]="{'disabled': !hasContacts}">
                                <a class="dropdown-item" (click)="hasContacts && exportToExcel()" [appEventTracker]="{ name:'export' }">Export to Excel</a>
                            </li>
                            <li role="menuitem" [ngClass]="{'disabled': !hasContacts}">
                                <a class="dropdown-item" (click)="hasContacts && saveContactList()">Create/Update Contact List</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="openContactList()">Open Contact List</a>
                            </li>
                            <li role="menuitem">
                                <a class="dropdown-item" (click)="openQuery()">Open Query</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
            <span class="pull-left small" *ngIf="holdingSearchForm.invalid">
                Enter tickers, analysts, or sectors
            </span>
            <div class="info">
                <app-filter-selection-display [formGroup]="holdingSearchForm"></app-filter-selection-display>
                <app-query-groups-popover [queryGroups]="holdingQueryGroups"></app-query-groups-popover>
            </div>
        </div>
    </ng-template>
    
    <app-page-banner
            pagetitle="Interest list" 
            faicon="fa-line-chart" 
            faiconcolor="orange"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex flex-hbox flex-gap">
        <div class="flex">
            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
                    [rowClassRules]="rowClassRules"
                    (rowDataUpdated)="onRowDataChanged()"
                    (selectionChanged)="onSelectionChanged()"
            >
            </ag-grid-angular>
        </div>
        <div class="flex flex-vbox flex-gap">
            <div class="flex-1 justify-center" style="border: 1px solid #d5d5d5; padding: 5px;" *ngIf="!contact">
                No Contact Selected
            </div>
            <div class="flex-1 il-contact-details" style="border: 1px solid #d5d5d5; min-height: 0;" *ngIf="contact">
                <div class="contact-account">
                    <span style="margin-right: 30px;">
                       <label style="margin-bottom: 0;">Contact:</label> 
                        <a style="margin-right: 5px;" [routerLink]="[contactDetailBaseBath, contact.Id]" [relativeTo]="null">{{ contact | presentationName }}</a>
                        <app-contact-favorite [contactId]="selectedContactId" (change)="onFavoriteChanged($event)" ></app-contact-favorite>
                    </span>
                    <span>
                        <label>Account:</label><a *ngIf="contact.Account" [routerLink]="[accountDetailBaseBath, contact.Account?.Id]" [relativeTo]="null">{{ contact.AccountName }}</a> ({{ contact.Account | accountLocation }})
                    </span>
                </div>
                <app-do-not-contact-banner [reason]="contact.Account?.ResearchStatus?.Reason || contact.ResearchStatus?.Reason"></app-do-not-contact-banner>
                <div class="flex flex-hbox more-details" [ngClass]="{ 'has-banner': contact.Account?.ResearchStatus?.Reason || contact.ResearchStatus?.Reason }">
                    <div class="flex-2">
                        <div>
                            <label>Email:</label><a ng-if="contact.Email" href="mailto:{{contact.Email}}">{{contact.Email}}</a>
                        </div>
                        <div>
                            <label>P:</label><app-click-to-dial [phone]="contact.PhoneNumber" [extension]="contact.PhoneNumberExtension"></app-click-to-dial>
                        </div>
                        <div>
                            <label>M:</label><app-click-to-dial [phone]="contact.MobilePhoneNumber"></app-click-to-dial>
                        </div>
                    </div>
                    <div class="flex">
                        <div>
                            <label>Title:</label>{{ contact.Title }}
                        </div>
                        <div>
                            <label>Asst:</label>
                            <app-contact-popover
                                    [contactIds]="assistantIds"
                                    valueForMultiple="Multiple"
                                    title="Assistant Overview"
                                    placement="left"
                                    [adaptivePosition]="false">
                            </app-contact-popover>
                        </div>
                        <div>
                            <label>Tier:</label>{{ contact.Tier }}
                        </div>
                    </div>
                    <div class="flex">
                        <div>
                            <label>PersBk:</label>{{ contact.Broker?.LastName }}
                        </div>
                        <div>
                            <label>PrBk:</label>{{ contact.Account?.PrimaryBroker?.LastName }}
                        </div>
                        <div>
                            <label>PrTr:</label>{{ contact.Account?.PrimaryTrader?.LastName }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-2 flex-vbox" style="border: 1px solid #d5d5d5; padding: 5px;">
                <div style="margin-bottom: 5px;">
                    <button type="button" class="pull-right btn btn-toggle btn-xs"
                            [formControl]="applyFiltersInterests"
                            btnCheckbox>Apply Filters</button>
                    Interests
                </div>
                <app-tab-interest-list class="flex flex-vbox" 
                                       [contactId]="selectedContactId"
                                       [enableAdd]="true"
                                       [disableMultiEditing]="true"
                                       [appliedQueryGroups]="applyFiltersInterests.value ? holdingQueryGroups : []"
                ></app-tab-interest-list>
            </div>
            <div class="flex-2 flex-vbox" style="border: 1px solid #d5d5d5; padding: 5px;">
                <div style="margin-bottom: 5px;">
                    <button type="button" class="pull-right btn btn-toggle btn-xs"
                            [formControl]="applyFiltersActivities"
                            btnCheckbox>Apply Filters</button>
                    Activities
                </div>
                <app-tab-activity-list class="flex flex-vbox"
                                       [dateRange]="defaultDateRange"
                                       [showAccountName]="false"
                                       [showContactShortName]="false"
                                       [contactId]="selectedContactId"
                                       [dateRangeTopAlign]="true"
                                       [activityDataChanged]="activityDataChanged"
                                       [appliedQueryGroups]="applyFiltersActivities.value ? activityQueryGroups : []"
                ></app-tab-activity-list>
            </div>
        </div>
    </div>
</div>
