import {Component, Input, OnInit} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";
import {iif} from "rxjs";
import {QueryService} from "../../../Shared/Services/query.service";
import { map } from 'rxjs/operators';

@Component({
    selector: "app-query-list-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(QueryListPickerComponent)
})
export class QueryListPickerComponent extends BasePickerComponent<any> implements OnInit {

    @Input()
    alignRight: boolean = false;

    @Input()
    emailableOnly: boolean = false;
    
    constructor(fb: UntypedFormBuilder,
                private queryService: QueryService,
    ) {
        super(fb);

        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.placeHolder = "Select Saved Query";
        this.customSearchable = true;
        this.showSelectClearAll = false;
        this.showTagCountLabel = false;
        this.closeOnSelect = true;
        this.multiple = false;
    }

    ngOnInit() {
        super.ngOnInit();
        this.items$ = iif(
            () => this.emailableOnly,
            this.queryService.getAllEmailableQueries(),
            this.queryService.getAllQueries()
        ).pipe(
            map(queries => queries.sort((a, b) => a.Name.localeCompare(b.Name)))
        );
    }
}
