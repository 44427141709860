import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";
import { of } from "rxjs";

@Component({
    selector: "app-month-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(MonthPickerComponent)
})
export class MonthPickerComponent extends BasePickerComponent<any> implements OnInit {
    @Output() monthNamesChange = new EventEmitter<{ Id: number, Name: string }[]>();
    constructor(fb: UntypedFormBuilder) {
        super(fb);

        this.bindValue = "Id";
        this.bindLabel = "Name";

        this.placeHolder = "Month(s)";
        this.name = "Month(s)";
        this.multiple = true;
        this.showSelectClearAll = true;
        this.showTagCountLabel = true;

    }

    ngOnInit() {
        super.ngOnInit();
        this.items$ = of([
            { Id: 1, Name: "Jan" },
            { Id: 2, Name: "Feb" },
            { Id: 3, Name: "Mar" },
            { Id: 4, Name: "Apr" },
            { Id: 5, Name: "May" },
            { Id: 6, Name: "June" },
            { Id: 7, Name: "July" },
            { Id: 8, Name: "Aug" },
            { Id: 9, Name: "Sep" },
            { Id: 10, Name: "Oct" },
            { Id: 11, Name: "Nov" },
            { Id: 12, Name: "Dec" }
        ]);
    }
}
