import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
    AbstractControl,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {combineLatest, Observable, of} from "rxjs";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import * as moment from "moment";
import {Contact} from "../../../Models/Contact";
import {UserService} from "../../Shared/Services/user.service";
import {ConfigService} from "../../Shared/Services/config.service";
import {DuplicateContactModalComponent} from "./duplicate-contact-modal.component";
import {BluematrixReportTypes} from "./bm-report-type";
import {NoContactReasons} from "./no-contact-reasons";
import {InvestorStyles} from "../../Shared/Models/investor-styles";
import {DealPlayer} from "../../Shared/Models/deal-player";
import {Orientation} from "../../Shared/Models/orientation";
import {ContactSelectModalComponent} from "../../Widget/ContactSelectModal/contact-select-modal.component";
import {AccountService} from "../../Shared/Services/account.service";
import {Account} from "../../../Models/Account";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {RejectModalComponent} from "../../Widget/RejectModal/reject-modal.component";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";
import {CustomEditEntitlements} from "./custom-edit-entitlements";
import {UserFeatureName} from "../../Admin/UserFeatures/user-features.service";
import {AccountSelectModalComponent} from "../../Widget/AccountSelectModal/account-select-modal.component";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {ContactResearchService} from "../../Shared/Services/contact-research.service";
import {ActivityService} from "../../Shared/Services/activity.service";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../contact-route-paths";
import {AccountRoutePaths} from "../../Account/account-route-paths";
import {Employment} from "../../../Models/Employment";
import {EntitlementService, VendorEntitlementDtoShow} from "../../Shared/Services/entitlement.service";

@Component({
    selector: "app-contact-form",
    templateUrl: "./contact-form.component.html"
})
export class ContactFormComponent implements OnInit {

    @Input()
    defaultTab: string;

    @Input()
    contactId: number;

    @Input()
    accountId: number;

    @Input()
    moveAccount: boolean = false;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    contactForm = this.fb.group({
        salutation: this.fb.control(''),
        firstName: this.fb.control(''),
        correspName: this.fb.control(''),
        lastName: this.fb.control(''),
        assistants: this.fb.control([]),
        email: this.fb.control('',  [Validators.email]),
        alternateEmails: this.fb.array([]),
        phone: this.fb.control(''),
        mobilePhone: this.fb.control(''),
        tier: this.fb.control(''),
        buyBackClient: this.fb.control(false),
        personalBroker: this.fb.control([]),
        title: this.fb.control('', [Validators.max(200)]),
        marketCapLow: this.fb.control(null, [Validators.min(0), Validators.max(2147483647)]),
        marketCapHigh: this.fb.control(null, [Validators.min(0), Validators.max(2147483647)]),
        investorStyles: this.fb.control([]),
        orientations: this.fb.control(null),
        globalInvestor: this.fb.control(null),
        privateInvestment: this.fb.control(null),
        dealPlayer: this.fb.control([]),
        teams: this.fb.control([]),
        roleIds: this.fb.control([]),
        emailAllowed: this.fb.control(false),
        blastEmailAllowed: this.fb.control(false),
        blastVoiceMailAllowed: this.fb.control(false),
        noContact: this.fb.control(false),
        noContactReason: this.fb.control(''),
        noContactNote: this.fb.control(''),
        secureLinks: this.fb.control(false),
        reportType: this.fb.control([]),
        autoSubscribe: this.fb.control(false),
        overrideEmail: this.fb.control(false),
        entitlements: this.fb.array([]),
        employments: this.fb.array([]),
    }, {validator: this.firstLastNameValidator('firstName', 'lastName')});

    investorStyles$: Observable<string[]>;
    dealPlayerTypes$: Observable<string[]>;
    allOrientations$: Observable<string[]>;
    allTeams$: Observable<any[]>;
    reportTypes$: Observable<string[]>;
    noContactReasons$: Observable<string[]>;
    reportTypes = BluematrixReportTypes;
    contactDetails: Contact;
    accountDetails: Account;
    newAccount: Account;
    newAccountDifferentParent: boolean = false;
    entitlements: VendorEntitlementDtoShow[];
    IsResearchOnline: boolean = false;
    canEditResearchOnlineEntitlementFeature = UserFeatureName.CanEditResearchOnlineEntitlement;
    accountSecureLinksEnabled: boolean = false;
    canViewEmployment: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private toastr: ToastrService,
                private contactService: ContactService,
                private accountService: AccountService,
                private userService: UserService,
                private configService: ConfigService,
                private activityService: ActivityService,
                private entitlementService: EntitlementService,
                private contactResearchService: ContactResearchService,
                private router: Router,
    ) { }

    ngOnInit(): void {
        this.contactForm.get('noContact').valueChanges.subscribe(value => {
           value == true ? this.contactForm.get('noContactReason').setValidators([Validators.required]) : this.contactForm.get('noContactReason').clearValidators();
           this.contactForm.get('noContactReason').updateValueAndValidity();
        });

        this.investorStyles$ = of(InvestorStyles);
        this.dealPlayerTypes$ = of(DealPlayer);
        this.allOrientations$ = of(Orientation);
        this.reportTypes$ = of(BluematrixReportTypes);
        this.noContactReasons$ = of(NoContactReasons);

        if (this.contactId > 0) {
            this.allTeams$ = this.contactService.getAvailableTeams(this.contactId).pipe(
                map(teams => _.sortBy(teams)),
                map(teams => _.map(teams, t => { return {Label: t} }))
            );
            this.loadContactForm(this.contactId);
        } else if (this.accountId > 0) {
            this.allTeams$ = this.accountService.GetAccountContactAvailableTeams(this.accountId).pipe(
                map(teams => _.sortBy(teams)),
                map(teams => _.map(teams, t => { return {Label: t} }))
            );
            this.loadContactFormByAccountId(this.accountId);
        }

        this.enableIfHasUserFeature();

        if (this.moveAccount) {
            this.setupContactMove();
        }
    }

    loadContactForm(contactId: number): void {
        const contactDetails$ = this.contactService.getContactById(contactId)
        const contactRoles$ = this.contactService.getContactRoles();
        const entitlements$ = this.entitlementService.getVendorEntitlementsForContact(this.contactId);
        const employments$ = this.contactService.getContactEmployment(contactId);

        combineLatest([contactDetails$, contactRoles$, entitlements$, employments$])
            .subscribe(([contactDetails, contactRoles, entitlements, employments])=> {
                this.contactDetails = contactDetails;
                this.entitlements = entitlements;
                this.accountId = contactDetails.Account.Id;
                this.accountSecureLinksEnabled = contactDetails.Account.BluematrixSecureLinks;

                const sortedRoleIds = _.chain(contactRoles)
                    .filter(cr => _.includes(contactDetails.RoleIds, cr.Id))
                    .sortBy(cr => cr.Name)
                    .map(cr => cr.Id)
                    .value();

                this.contactForm.patchValue({
                    salutation: contactDetails.Salutation,
                    firstName: contactDetails.FirstName,
                    correspName: contactDetails.Alias,
                    lastName: contactDetails.LastName,
                    assistants: contactDetails.Assistants ? contactDetails.Assistants.map(assistant => assistant.Id) : [0],
                    email: contactDetails.Email,
                    phone: contactDetails.PhoneNumber,
                    mobilePhone: contactDetails.MobilePhoneNumber,
                    tier: contactDetails.Tier ? contactDetails.Tier : contactDetails.Account.Tier,
                    buyBackClient: contactDetails.BuyBackClient,
                    personalBroker: contactDetails.Broker && contactDetails.Broker.Id ? contactDetails.Broker.Id : "NoBrokerSelected",
                    title: contactDetails.Title,
                    marketCapLow: contactDetails.MarketCapLow,
                    marketCapHigh: contactDetails.MarketCapHigh,
                    globalInvestor: contactDetails.GlobalInvestor,
                    privateInvestment: contactDetails.PrivateInvestment,
                    orientations: _.sortBy(contactDetails.Orientations),
                    dealPlayer: _.sortBy(contactDetails.DealPlayer),
                    investorStyles: _.sortBy(contactDetails.InvestorStyles),
                    teams: _.sortBy(contactDetails.Teams),
                    roleIds: sortedRoleIds,
                    emailAllowed: contactDetails.EmailAllowed === 'Y',
                    blastEmailAllowed: contactDetails.BlastEmailAllowed === 'Y',
                    blastVoiceMailAllowed: contactDetails.BlastVoiceMailAllowed === 'Y',
                    noContact: contactDetails.ResearchStatus && contactDetails.ResearchStatus.Reason ? true : false,
                    noContactReason: contactDetails.ResearchStatus && contactDetails.ResearchStatus.Reason,
                    noContactNote: contactDetails.ResearchStatus && contactDetails.ResearchStatus.Note,
                    secureLinks: contactDetails.BlueMatrixSecureLinks,
                    reportType: _.chain(contactDetails.BlueMatrixReportPreferences).pickBy(v => v === 'Y').keys().value(),
                    autoSubscribe: contactDetails.BlueMatrixReportPreferences ? contactDetails.BlueMatrixReportPreferences.AllTickers !== ' ' : false,
                    overrideEmail: contactDetails.OverrideEmailFromAnalyst,
                });

                let alternateEmails = this.contactForm.get('alternateEmails') as UntypedFormArray;
                _.forEach(contactDetails.EmailAddresses, e => { alternateEmails.push(this.fb.control(e, [Validators.email])) });

                let entitlementGroups = this.contactForm.get('entitlements') as UntypedFormArray;
                _.forEach(entitlements, e => {
                    let group = this.createVendorEntitlementGroup(e);
                    entitlementGroups.push(group);
                });
                let employmentGroups = this.contactForm.get('employments') as UntypedFormArray;
                _.forEach(_.orderBy(employments, ['EndDate'], ['desc']), e => {
                   let group = this.createEmploymentGroup(e);
                   employmentGroups.push(group)
                });
            });
    }

    loadContactFormByAccountId(accountId: number): void {

        const entitlements$ = this.entitlementService.getVendorEntitlementsList();
        const account$ = this.accountService.getAccountById(this.accountId)

        combineLatest([account$, entitlements$])
            .subscribe(([account, entitlements]) => {
                this.accountDetails = account;
                this.contactDetails = {} as Contact;

                this.contactForm.patchValue({
                    tier: account.Tier,
                    personalBroker: account.PrimaryBroker?.Id ?? "NoBrokerSelected",
                    emailAllowed: true,
                    blastEmailAllowed: true,
                    blastVoiceMailAllowed: true,
                    reportType: this.reportTypes,
                });

                let entitlementGroups = this.contactForm.get('entitlements') as UntypedFormArray;
                _.forEach(entitlements, e => {
                    let group = this.createVendorEntitlementGroup(e);
                    entitlementGroups.push(group);
                });
            });

    }

    enableIfHasUserFeature(): void {
        this.userService.getCurrentUser().subscribe(user => {
            if (user && !_.includes(user.Features, "AllowEditInstitutionPriority")) {
                this.contactForm.get('tier').disable();
            }
            if (user && !_.includes(user.Features, "BluematrixSecureLinks")) {
                this.contactForm.get('secureLinks').disable();
            }
            if (user && !_.includes(user.Features, "IsAdmin")) {
                this.contactForm.get('autoSubscribe').disable();
            }
            if (user && !_.includes(user.Features, "EditContact")) {
                this.contactForm.get('buyBackClient').disable();
                _.forEach((this.contactForm.controls.entitlements as UntypedFormArray).controls, c => c.disable());
            } else {
                this.canViewEmployment = true;
            }
            if (user && !_.includes(user.Features, "UpdateNoContact")) {
                this.contactForm.get('noContact').disable();
                this.contactForm.get('noContactReason').disable();
                this.contactForm.get('noContactNote').disable();
            }
        });
    }

    setupContactMove(): void {
        let modalRef = this.modalService.show(AccountSelectModalComponent, {
            initialState: {
                message: "Choose a new Account for this Contact.",
                excludeAccountId: this.accountId,
            },
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-md'
        });

        modalRef.content.accountId$.subscribe(newAccountId => {
            this.accountService.getAccountById(newAccountId)
                .subscribe(newAccount => {

                    this.newAccount = newAccount;
                    this.contactForm.patchValue({
                        tier: newAccount.Tier,
                        teams: [],
                    });

                    let employmentGroups = this.contactForm.get('employments') as UntypedFormArray;

                    let group = this.createEmploymentGroup({
                        AccountId: this.accountId,
                        EndDate: new Date(),
                    });

                    employmentGroups.push(group)

                    this.accountId = newAccount.Id;

                    this.newAccountDifferentParent = this.contactDetails.Account.ParentComdolId !== newAccount.ParentComdolId;

                    if (this.newAccount.Name.startsWith("Departed Clients")) {
                        this.contactForm.patchValue({
                            personalBroker: "NoBrokerSelected",
                            assistants: [],
                            email: '',
                            phone: '',
                        });
                        let alternateEmails = this.contactForm.get('alternateEmails') as UntypedFormArray;
                        alternateEmails.clear();
                    } else if (this.newAccountDifferentParent) {
                        let newPersBroker = this.newAccount.PrimaryBroker?.Id ?? "NoBrokerSelected";
                        this.contactForm.patchValue({
                            personalBroker: newPersBroker,
                            assistants: [],
                            email: '',
                            phone: '',
                        });
                        let alternateEmails = this.contactForm.get('alternateEmails') as UntypedFormArray;
                        alternateEmails.clear();
                    }

                    this.allTeams$ = this.accountService.GetAccountContactAvailableTeams(this.accountId).pipe(
                        map(teams => _.sortBy(teams)),
                        map(teams => _.map(teams, t => { return {Label: t} }))
                    );
                });
        });
    }

    openDuplicateContactModal(contacts: any): void {
        let modalRef = this.modalService.show(DuplicateContactModalComponent, {initialState: {contacts: contacts}, animated: false, keyboard: false, backdrop: 'static', class: 'modal-lg'});
        modalRef.content.confirmed.subscribe(isConfirmed => {
            if(isConfirmed) this.confirm();
        });
    }

    save(): void {
        if (this.contactForm.valid) {
            let contact = {
                Id: this.contactDetails.Id,
                FirstName: this.contactForm.value.firstName,
                LastName: this.contactForm.value.lastName,
                EmailAddresses: this.contactForm.value.alternateEmails.filter(i => i).concat(this.contactForm.value.email),
            }
            if(this.firstNameOrLastNameOrEmailChanged()){
                this.contactService.getDuplicateContacts(contact).subscribe(contacts => {
                    if(contacts.length) {
                        this.openDuplicateContactModal(contacts);
                    } else {
                        this.confirm();
                    }
                });
            } else {
                this.confirm();
            }
        }
    }

    firstNameOrLastNameOrEmailChanged(): boolean {
        return this.contactDetails.FirstName != this.contactForm.value.firstName ||
            this.contactDetails.LastName != this.contactForm.value.lastName ||
            this.contactDetails.Email != this.contactForm.value.email ||
            !_.isEqual(this.contactDetails.EmailAddresses, this.contactForm.value.alternateEmails);
    }

    confirm(): void {

        let formValue = this.contactForm.getRawValue()

        let updatedContact = {
            Id: this.contactDetails.Id,
            NewAccountId: this.newAccount ? this.newAccount.Id : 0,
            Account: { Id: this.accountId } as Account,
            Salutation: formValue.salutation ? formValue.salutation.trim() : formValue.salutation,
            FirstName: formValue.firstName ? formValue.firstName.trim() : formValue.firstName,
            Alias: formValue.correspName ? formValue.correspName.trim() : formValue.correspName,
            Assistants: formValue.assistants.length ? formValue.assistants.map(assistantId => { return {Id: assistantId} }) : null,
            LastName: formValue.lastName ? formValue.lastName.trim() : formValue.lastName,
            Email: formValue.email ? formValue.email.trim() : formValue.email,
            EmailAddresses: formValue.alternateEmails.filter(e => e).map(e => e.trim()),
            PhoneNumber: formValue.phone ? formValue.phone.trim() : formValue.phone,
            MobilePhoneNumber: formValue.mobilePhone ? formValue.mobilePhone.trim() : formValue.mobilePhone,
            Tier: formValue.tier ? formValue.tier.trim() : this.contactDetails.Tier,
            BuyBackClient: formValue.buyBackClient,
            Broker: formValue.personalBroker !== "NoBrokerSelected" ? {Id: formValue.personalBroker} : null,
            Title: formValue.title ? formValue.title.trim() : formValue.title,
            MarketCapLow: formValue.marketCapLow,
            MarketCapHigh: formValue.marketCapHigh,
            GlobalInvestor: formValue.globalInvestor,
            PrivateInvestment: formValue.privateInvestment,
            Orientations: formValue.orientations,
            DealPlayer: formValue.dealPlayer,
            InvestorStyles: formValue.investorStyles,
            Teams: formValue.teams,
            RoleIds: formValue.roleIds,
            EmailAllowed: formValue.emailAllowed ? 'Y' : 'N',
            BlastEmailAllowed: formValue.blastEmailAllowed ? 'Y' : 'N',
            BlastVoiceMailAllowed: formValue.blastVoiceMailAllowed ? 'Y' : 'N',
            ResearchStatus: {
                Reason: this.contactForm.get('noContact').enabled ?
                    formValue.noContact ? formValue.noContactReason : null :
                    this.contactDetails.ResearchStatus ? this.contactDetails.ResearchStatus.Reason : null,
                Note: this.contactForm.get('noContact').enabled ?
                    formValue.noContact ? formValue.noContactNote : null :
                    this.contactDetails.ResearchStatus ? this.contactDetails.ResearchStatus.Note : null,
            },
            BlueMatrixReportPreferences: this.reportTypes.reduce((acc, key) => ({...acc, [key]: formValue.reportType.includes(key) ? 'Y' : 'N'}), {}),
            BlueMatrixSecureLinks: this.contactForm.get('secureLinks').enabled ? formValue.secureLinks : this.contactDetails.BlueMatrixSecureLinks,
            OverrideEmailFromAnalyst: formValue.overrideEmail,
            Employments: formValue.employments.filter(e => e).map(e => { return {Id: e.id, AccountId: e.accountId[0], EndDate: e.endDate} as Employment}),
        } as Contact;

        updatedContact['BlueMatrixReportPreferences']['AllTickers'] = formValue.autoSubscribe === true ? 'B' : ' ';

        let loadingModal = this.modalService.show(LoadingModalComponent, {
            initialState: { message: 'Saving' },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        if (this.contactId > 0) {
            this.contactService.updateContact(updatedContact).subscribe((contact) => {
                this.completeUpdate(contact, loadingModal, formValue, () => {
                    loadingModal.hide();
                    this.confirmComplete("Contact Updated");
                });
            });
        }
        else {
            this.contactService.createContact(updatedContact).subscribe((contact) => {
                this.completeUpdate(contact, loadingModal, formValue, () => {
                    loadingModal.hide();
                    this.confirmComplete("Contact Updated");
                    this.router.navigate([ContactRoutePaths.ContactDetail, contact.Id]);
                });
            });
        }
    }

    confirmComplete(message: string): void {
        this.toastr.success(message, 'Success');
        this.dataUpdated.emit(true);
        this.modalRef.hide();
    }

    close() {
        this.modalRef.hide();
    }

    getAccountAddress(): string {

        if (this.contactDetails && this.contactDetails.Account) {
            return `${this.contactDetails.Account.Address.Route != null ? this.contactDetails.Account.Address.Route : ''} (${this.contactDetails.Account.City}, ${this.contactDetails.Account.State} ${this.contactDetails.Account.Country})`;
        } else if (this.accountDetails) {
            return `${this.accountDetails.Address.Route != null ? this.accountDetails.Address.Route : ''} (${this.accountDetails.City}, ${this.accountDetails.State} ${this.accountDetails.Country})`;
        } else {
            return '';
        }

    }

    addEmail(): void {
        let alternateEmails = this.contactForm.get('alternateEmails') as UntypedFormArray;
        alternateEmails.push(this.fb.control(''));
    }

    addVendorId(entitlementIndex: number): void {
        const entitlementsFormArray = this.contactForm.get('entitlements') as UntypedFormArray;
        const vendorIdsFormArray = entitlementsFormArray.at(entitlementIndex).get('vendorIds') as UntypedFormArray;
        vendorIdsFormArray.push(this.fb.control(''));
    }

    removeEmail(index: number): void {
        let alternateEmails = this.contactForm.get('alternateEmails') as UntypedFormArray;
        alternateEmails.removeAt(index);
    }

    removeVendorId(entitlementIndex: number, vendorIdIndex: number): void {
        const entitlementsFormArray = this.contactForm.get('entitlements') as UntypedFormArray;
        const vendorIdsFormArray = entitlementsFormArray.at(entitlementIndex).get('vendorIds') as UntypedFormArray;
        vendorIdsFormArray.removeAt(vendorIdIndex);
    }

    addEmployment(): void {
        let employments = this.contactForm.get('employments') as UntypedFormArray;
        let group = this.createEmploymentGroup({Id: null, AccountId: null, StartDate: null})
        employments.push(group)
    }

    removeEmployment(index: number): void {
        let employments = this.contactForm.get('employments') as UntypedFormArray;
        employments.removeAt(index);
    }

    firstLastNameValidator(firstControl, secondControl): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const first = control.get(firstControl).value;
            const last = control.get(secondControl).value;
            if (first || last) return null;
            else return {'Empty': true}
        }
    }

    copyProfile() {
        let modalRef = this.modalService.show(ContactSelectModalComponent, {
            initialState: { accountId: this.accountId, message: "Choose a Contact to copy profile from." },
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-md'
        });

        modalRef.content.contactId$.subscribe(copyProfileFromContactId => {

            this.contactService.getContactProfile(copyProfileFromContactId)
                .subscribe(profile => {
                    this.contactForm.patchValue({
                        marketCapLow: profile.MarketCapLow,
                        marketCapHigh: profile.MarketCapHigh,
                        globalInvestor: profile.GlobalInvestor,
                        privateInvestment: profile.PrivateInvestment,
                        orientations: _.sortBy(profile.Orientations),
                        dealPlayer: _.sortBy(profile.DealPlayer),
                        investorStyles: _.sortBy(profile.InvestorStyles),
                        teams: _.sortBy(profile.Teams),
                        roleIds: profile.RoleIds,
                    });
                });
        });
    }

    delete() {
        let queryGroups: QueryGroup[] = [];
        QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Persnum", "contains", [this.contactId]);
        let researchOnline$ = this.contactResearchService.getContactResearchByQuery(queryGroups);
        let activities$ = this.activityService.searchActivities(queryGroups);
        combineLatest([researchOnline$, activities$])
            .subscribe(([contactsWithResearch, activities]) => {
                this.IsResearchOnline = contactsWithResearch[0]?.IsResearchOnline ?? false;
                if (this.IsResearchOnline==true) {
                    this.modalService.show(RejectModalComponent, {
                        initialState: {message: "Contact has BOL access and cannot be deleted. Remove access before deleting."},
                        backdrop: 'static',
                        keyboard: false,
                        animated: false,
                        class: 'modal-md'
                    });
                }
                else if (activities.length > 0) {
                    this.modalService.show(RejectModalComponent, {
                        initialState: {message: `Contact has ${activities.length} existing activities and cannot be deleted. Reassign activities before deleting.`},
                        backdrop: 'static',
                        keyboard: false,
                        animated: false,
                        class: 'modal-md'
                    });
                }
                else {
                    let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
                        initialState: { message: "Are you sure you want to delete this contact?" },
                        backdrop: 'static',
                        keyboard: false,
                        animated: false,
                        class: 'modal-md'
                    });

                    confirmModalRef.content.action.subscribe(() => {
                        this.contactService.deleteContact(this.contactId)
                            .subscribe(() => {
                                this.toastr.success("Contact Deleted");
                                this.modalRef.hide();
                                this.router.navigate([AccountRoutePaths.AccountDetail, this.accountId]);
                            });
                    });
                }
            });
    }

    openCustomEditModal(component: any, code: string, index: number): void {
        let componentModalRef = this.modalService.show(component, {
            initialState: { contactId: this.contactId },
            backdrop: 'static',
            keyboard: false,
            animated: false,
            class: 'modal-md'
        });

        // @ts-ignore
        componentModalRef.content.dataUpdated
            .subscribe((isDataUpdated) => {
                if (isDataUpdated) {
                    this.entitlementService.getVendorEntitlementsForContact(this.contactId)
                        .subscribe(entitlements => this.setVendorEntitlements(entitlements, code, index));
                    this.dataUpdated.emit(true);
                }
            });
    }

    private completeUpdate (contact: Contact, loadingModal: BsModalRef, formValue: any, completeFunction: () => void): void {
        let entitlementFormArray = (this.contactForm.controls.entitlements as UntypedFormArray);
        if (entitlementFormArray.length > 0 && entitlementFormArray.controls[0].enabled) {
            var updateEntitlements = formValue.entitlements.map(e => {

                let vendorIds = [];

                if (Array.isArray(e.vendorIds) && e.vendorIds.every(v => typeof v === 'string')) {
                    vendorIds = e.vendorIds;
                } else if (typeof e.vendorIds[0] === 'string' || e.vendorIds[0] instanceof String) {
                    vendorIds = [e.vendorIds[0]];
                } else {
                    vendorIds = e.vendorIds[0].map(v => v);
                }

                vendorIds = vendorIds.map(v => v.trim()).filter(v => v);

                return {
                    Code: e.code,
                    Description: e.description,
                    VendorIds: vendorIds,
                }
            });

            let entitlements$ = this.entitlementService.updateVendorEntitlements(contact.Id, updateEntitlements);
            entitlements$.subscribe(() => {
                completeFunction();
            })
        } else {
            completeFunction();
        }
    }

    private setVendorEntitlements(entitlements: VendorEntitlementDtoShow[], code: string, index: number) {
        let entitlementGroups = this.contactForm.get('entitlements') as UntypedFormArray;
        _.forEach(entitlements, entitlement => {
            if (entitlement.Code === code) {
                let group = this.createVendorEntitlementGroup(entitlement);

                entitlementGroups.removeAt(index);
                entitlementGroups.insert(index, group);
            }
        });
    }

    private createVendorEntitlementGroup(entitlement: VendorEntitlementDtoShow): UntypedFormGroup {
        let customModal = CustomEditEntitlements
            .find(c => c.Code == entitlement.Code)?.ModalComponent;

        let hasCustomModal = !!customModal;
        let vendorIds;
        let isSubbed = entitlement.EntitlementInfo && entitlement.EntitlementInfo.some(info => info.IsSubscribed);

        if (isSubbed) {
            vendorIds = entitlement.EntitlementInfo.map(a => a.VendorId);
        } else {
            vendorIds = entitlement.Code === 'RES' ? [''] : [''];
        }

        return this.fb.group({
            code: this.fb.control({ value: entitlement.Code, disabled: hasCustomModal }),
            description: this.fb.control({ value: entitlement.Description, disabled: hasCustomModal }),
            vendorIds: this.fb.array(vendorIds.map(vendorId => this.fb.control(vendorId))),
            customModal: this.fb.control(customModal),
        });
    }

    private createEmploymentGroup(employment): UntypedFormGroup {
        return this.fb.group({
            id: this.fb.control(employment.Id),
            endDate: this.fb.control(moment(employment.EndDate).format("YYYY-MM-DD"), Validators.required),
            accountId: this.fb.control([employment.AccountId], Validators.required),
        });
    }

    getNewAccountAddress(): string {
        if (this.newAccount) {
            return `${this.newAccount.Address.Route != null ? this.newAccount.Address.Route : ''} (${this.newAccount.City}, ${this.newAccount.State} ${this.newAccount.Country})`;
        } else {
            return '';
        }
    }
}
