import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";

import {BairdRep, BairdRepService} from "../Services/baird-rep.service";
import {BairdAnalyst, BairdAnalystService} from "../Services/baird-analyst.service";

@Component({
    selector: "app-baird-rep-form",
    templateUrl: "./baird-rep-analyst-form.component.html",
    styleUrls: ["../Providers/provider-form.component.scss"]
})
export class BairdRepAnalystFormComponent implements OnInit{

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    RepAnalystId: number = null;
    RepAnalystLabel: string;
    type: string;
    title: string;
    newForm: boolean = true;

    RepAnalystForm: UntypedFormGroup = this.fb.group({
        Type: this.fb.control({value: '', disabled: !this.newForm}, [Validators.required]),
        FirstName: this.fb.control('', [Validators.maxLength(25), this.emptyOrWhiteSpaceValidatorForNonNullField()]),
        LastName: this.fb.control('', [Validators.maxLength(25), this.emptyOrWhiteSpaceValidatorForNonNullField()]),
        MiddleInitial: this.fb.control('', [Validators.maxLength(50)]),
        Title: this.fb.control('', [Validators.maxLength(75)]),
        Phone: this.fb.control('', [Validators.maxLength(20)]),
        Mobile: this.fb.control('', [Validators.maxLength(20)]),
        Email: this.fb.control('', [Validators.maxLength(60)]),
        Deleted: this.fb.control(false),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private modalRef: BsModalRef,
        private modalService: BsModalService,
        private bairdRepService: BairdRepService,
        private analystService: BairdAnalystService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.RepAnalystLabel = "Baird Rep or Analyst"
        if (this.RepAnalystId) {
            this.loadProvider(this.RepAnalystId);
        } else {
            this.title = `New ${this.RepAnalystLabel}`;
        }
        if (!this.newForm) {
            this.title = `Edit ${this.type}`
            this.RepAnalystForm.get('Type').disable()
        }
    }

    loadProvider(RepAnalystId: number) {
        let repAnalyst$ = null;
        let type = 0
        if (this.type === "Rep") {
            repAnalyst$ = this.bairdRepService.getProviderById(RepAnalystId);
        }
        if (this.type === "Analyst") {
            repAnalyst$ = this.analystService.getAnalystById(RepAnalystId);
            type = 1
        }
        if(repAnalyst$) {
            repAnalyst$
                .subscribe((repAnalyst) => {
                    this.RepAnalystForm.patchValue({
                        Type: type,
                        FirstName: repAnalyst.FirstName,
                        LastName: repAnalyst.LastName,
                        MiddleInitial: repAnalyst.MiddleInitial,
                        Title: repAnalyst.Title,
                        Phone: repAnalyst.Phone,
                        Mobile: repAnalyst.Mobile,
                        Email: repAnalyst.Email,
                        Deleted: repAnalyst.Deleted,
                    });
                });
        }
    }

    submit() {
        if (this.RepAnalystForm.valid) {
            let updatedRepAnalyst$ = null
            if (this.RepAnalystForm.get("Type").value === 0) {
                let rep: BairdRep = {
                    Id: this.RepAnalystId,
                    FirstName: this.RepAnalystForm.get('FirstName').value.trim(),
                    LastName: this.RepAnalystForm.get('LastName').value.trim(),
                    MiddleInitial: this.RepAnalystForm.get('MiddleInitial').value.trim(),
                    Title: this.RepAnalystForm.get('Title').value.trim(),
                    Phone: this.RepAnalystForm.get('Phone').value.trim(),
                    Mobile: this.RepAnalystForm.get('Mobile').value.trim(),
                    Email: this.RepAnalystForm.get('Email').value.trim(),
                    Deleted: this.RepAnalystForm.get('Deleted').value,
                };
                updatedRepAnalyst$ = this.bairdRepService.createOrUpdateRep(rep);
            }
            if (this.RepAnalystForm.get("Type").value === 1) {
                let analyst: BairdAnalyst = {
                    Id: this.RepAnalystId,
                    FirstName: this.RepAnalystForm.get('FirstName').value.trim(),
                    LastName: this.RepAnalystForm.get('LastName').value.trim(),
                    MiddleInitial: this.RepAnalystForm.get('MiddleInitial').value.trim(),
                    Title: this.RepAnalystForm.get('Title').value.trim(),
                    Phone: this.RepAnalystForm.get('Phone').value.trim(),
                    Mobile: this.RepAnalystForm.get('Mobile').value.trim(),
                    Email: this.RepAnalystForm.get('Email').value.trim(),
                    Deleted: this.RepAnalystForm.get('Deleted').value,
                }
                updatedRepAnalyst$ = this.analystService.createOrUpdateAnalyst(analyst)
            }
            if (updatedRepAnalyst$) {
                updatedRepAnalyst$.subscribe(updatedProvider => {
                    this.toastr.success(`Saved ${this.RepAnalystLabel}`);
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
            }
        }
    }

    close() {
        this.modalRef.hide();
    }

    emptyOrWhiteSpaceValidatorForNonNullField(): ValidatorFn {
        return (control: AbstractControl) : ValidationErrors | null => {
            return (control.value.trim().length === 0) ?
                { emptyOrWhiteSpace: true } :
                null;
        }
    }
}


