import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {EventService} from "../../../Shared/Services/event.service";
import * as moment from "moment";
import {UserService} from "../../../Shared/Services/user.service";
import {User} from "../../../../Models/User";
import {RequestEventMeeting} from "../../../../Components/Events/CalendarEvent";


@Component({
    selector: "app-request-meeting-form",
    templateUrl: "./request-meeting-form.component.html"
})
export class RequestMeetingFormComponent implements OnInit {

    @Input()
    meetingId: number;

    @Input()
    timeZone: string;

    requestMeeting: RequestEventMeeting;

    user: User;
    title: string = "";

    formLoading: boolean = false;

    constructor(private modalRef: BsModalRef,
                private eventService: EventService,
                private userService: UserService,
    ) {
    }

    ngOnInit(): void {
        this.formLoading = true;

        this.userService.getCurrentUser().subscribe(user => {
            this.user = user;
            this.title = "View Meeting";
            this.eventService.getRequestMeetingWithAttendees(this.meetingId)
                .subscribe((meetingWithAtt) => {
                    this.requestMeeting = meetingWithAtt;
                    meetingWithAtt.MeetingTime = meetingWithAtt.MeetingDate
                    this.requestMeeting.MeetingDate = moment(meetingWithAtt.MeetingDate).format('MM/DD/YYYY');
                    this.requestMeeting.MeetingTime = moment(meetingWithAtt.MeetingTime).format('h:mm A',);
                    this.requestMeeting.Id = meetingWithAtt.Id;
                    this.requestMeeting.NumberOfAttendees = meetingWithAtt.NumberOfAttendees;
                    this.requestMeeting.NumberOfInterested = meetingWithAtt.NumberOfInterested;
                    this.requestMeeting.Duration = meetingWithAtt.Duration;
                });
        });
    }

    close() {
        this.modalRef.hide();
    }

}
