import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validators
} from "@angular/forms";
import {combineLatest, Observable, Subject} from "rxjs";
import {map} from "rxjs/operators";
import * as _ from "lodash";
import {User} from "../../../Models/User";
import {UserService} from "../../Shared/Services/user.service";
import {objectSearchPredicate} from "../../Shared/query-operators";

@Component({
    selector: "app-multi-user-select",
    templateUrl: "./multi-user-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiUserSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiUserSelectComponent),
            multi: true
        }
    ]
})
export class MultiUserSelectComponent implements  OnInit, ControlValueAccessor {

    @Input()
    public minUsers: number;

    @Input()
    public maxUsers: number;

    @Input()
    public activeUsersOnly: boolean = false;
    
    @Input()
    public emailUsersOnly: boolean = false;
    
    users$: Observable<AutocompleteUserItem[]>;
    userInput$ = new Subject<string>();

    onChange = (uids: string[]) => {};
    onTouched = () => {};

    usersControl: UntypedFormControl = this.fb.control([]);

    disabled: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private userService: UserService){}

    ngOnInit(): void {

        let validators = [];
        if (this.minUsers) {
            validators.push(this.minLengthArray(this.minUsers))
        }
        if (this.maxUsers) {
            validators.push(Validators.maxLength(this.maxUsers))
        }
        this.usersControl.setValidators(validators);

        const allUsers$ = this.userService.getUsers();

        this.users$ = combineLatest([this.userInput$, allUsers$]).pipe(
            map(([userInput, allUsers]) => {
                return _.chain(allUsers)
                    .filter(u => objectSearchPredicate(u, userInput))
                    .filter(u => this.activeUsersOnly ? u.IsActive : true)
                    .filter(u => this.emailUsersOnly ? u.Email?.includes('@') : true)
                    .map(this.getAutocompleteUser)
                    .value();
            })
        );

        this.usersControl.valueChanges
            .subscribe(users => {
                if (this.onChange) {
                    this.onChange(users.map(u => u.id));
                }
            });
    }

    getAutocompleteUser(user: User): AutocompleteUserItem {
        return {
            id: user.Id,
            text: `${user.LastName}, ${user.FirstName} (${user.Id})`
        };
    }

    minLengthArray(min: number) {
        return (c: AbstractControl): {[key: string]: any} => {
            if (c.value.length >= min)
                return null;

            return { 'minLengthArray': {valid: false }};
        }
    }

    validate({ value }: UntypedFormControl) {
        if (this.usersControl.invalid) {
            return {invalidMultiUsers: true};
        }
        return null;
    }

    registerOnChange(fn: (uids: string[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(obj: string[]): void {
        this.users$ =
            this.userService.getUsers().pipe(
                map((users) => {
                    return _.chain(users)
                        .filter(u => (this.activeUsersOnly ? u.IsActive : true) || _.includes(obj, u.Id))
                        .filter(u => this.emailUsersOnly ? u.Email?.includes('@') : true)
                        .map(this.getAutocompleteUser)
                        .value();
                }));

        this.users$.subscribe(users => {
            let controlUsers = _.chain(users)
                .filter(u => _.includes(obj, u.id))
                .value();
            this.usersControl.patchValue(controlUsers);
        });
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.disabled) {
            this.usersControl.disable();
        } else {
            this.usersControl.enable();
        }
    }
}

export class AutocompleteUserItem {
    id: string;
    text: string;
}
