import {Component, OnInit} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ActivityService} from "../../Shared/Services/activity.service";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import * as moment from "moment";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import * as _ from "lodash";
import {accountTagsVoteRenderer} from "../../Shared/ag-grid-cell-renderers";
import {accountTagComparator} from "../../Shared/ag-grid-options";
import {UserService} from "../../Shared/Services/user.service";
import {Router} from "@angular/router";
import {AccountRoutePaths} from "../../Account/account-route-paths";

@Component({
    selector: "app-reports-account-activity",
    templateUrl: "./account-activity.component.html"
})

export class AccountActivityComponent extends BaseGridComponent<AccountActivitiesRow> implements OnInit {

    columnDefs = [
        { field: 'ParentAccount', headerName: 'Parent Account', flex: 2, enableRowGroup: true, rowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'ParentComdolId', headerName: 'Parent Comdol Id', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'ComdolId', headerName: 'Comdol Id', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'AccountId', headerName: 'Instnum', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Status', headerName: 'Trading Status', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Grid', headerClass: 'hide-header', width: 40, headerTooltip: 'Votes', cellStyle: this.colorAccountInfoCell, cellRenderer: accountTagsVoteRenderer, comparator: accountTagComparator },
        { field: 'Name', headerName: 'Account', flex: 2, enableRowGroup: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Region', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Market', flex: 1, enableRowGroup: true, hide: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Location', flex: 1, enableRowGroup: true, cellStyle: this.colorAccountInfoCell },
        { field: 'PrimaryBroker', headerName: 'PrBk', flex: 1, enableRowGroup: true, cellStyle: this.colorAccountInfoCell },
        { field: 'PrimaryTrader', headerName: 'PrTr', flex: 1, enableRowGroup: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Tier', flex: 1, enableRowGroup: true, cellStyle: this.colorAccountInfoCell },
        { field: 'Activity', headerName: 'Last', flex: 1, enableRowGroup: true, headerTooltip: 'Last Activity', cellStyle: this.colorAccountInfoCell },
        { field: 'PN', headerName: 'P/N', width: 50, headerTooltip: 'Phone In-Person/Conference Calls', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
        { field: 'X', headerName: 'X', width: 50, headerTooltip: 'Email Inbound/Exchange', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
        { field: 'Duration', width: 75, headerName: 'Dur', headerTooltip: 'Duration', aggFunc: 'sum', cellStyle: { backgroundColor: '#faf2cc' } },
        { field: 'D', headerName: 'D', width: 50, headerTooltip: 'Excel Model', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
        { field: 'S', headerName: 'S', width: 50, headerTooltip: 'Voice Message', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
        { field: 'E', headerName: 'E', width: 70, headerTooltip: 'Email', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
        { field: 'A', headerName: 'A', width: 50, headerTooltip: 'Analyst Visits', aggFunc: 'sum', cellStyle: this.colorActivityRollupCell },
    ];

    accountActivitySearchForm: UntypedFormGroup = this.fb.group({
        showAccountsWithGrid: this.fb.control(true),
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control({ start: moment().startOf('year'), end: moment().startOf('day') }),
        callerTeams: this.fb.control([])
    });

    autoGroupColumnDef = {
        sort: 'asc',
        flex: 2,
        cellStyle: this.colorAccountInfoCell
    };

    hasResearchRole: boolean = false;

    constructor(private activityService: ActivityService,
                private userService: UserService,
                private fb: UntypedFormBuilder,
                private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.sideBar = this.sideBar;

        this.userService.getCurrentUser().subscribe(user => {
            if(user.Role.Name === 'Research') {
                this.hasResearchRole = true;
                this.accountActivitySearchForm.patchValue({ callerTeams: [user.Team.Id] });
            }
        });

        this.rowData$ = this.accountActivitySearchForm.valueChanges.pipe(
            startWith(this.accountActivitySearchForm.getRawValue()),
            debounceTime(200),
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(formValues => this.activityService.getAccountActivities(formValues.showAccountsWithGrid, formValues.searchTerm, formValues.dateRange.start, formValues.dateRange.end, _.join(formValues.callerTeams, ' '))),
            map(accountActivities => _.map(accountActivities, aa => {
                    return {
                        ParentAccount: aa.ParentName ? aa.ParentName : '',
                        ParentComdolId: aa.ParentComdolId,
                        ComdolId: aa.ComdolId,
                        AccountId: aa.Id,
                        Status: aa.Status,
                        Name: aa.Name,
                        Region: aa.Region,
                        Market: aa.Market,
                        Location: _.join(_.filter([aa.City, aa.State, aa.Country], (x => !_.isEmpty(x))), ', '),
                        PrimaryBroker: aa.PrimaryBroker,
                        PrimaryTrader: aa.PrimaryTrader,
                        Tier: aa.Tier,
                        Activity: aa.LastTeamActivity ? `${moment(aa.LastTeamActivity.ActivityDate).format('LL')} (${aa.LastTeamActivity.Category})` : '',
                        PN: aa.ActivityRollups.CountPhoneInPerson + aa.ActivityRollups.CountConferenceCalls,
                        X: aa.ActivityRollups.CountEmailExchange,
                        Duration: aa.ActivityRollups.Duration,
                        D: aa.ActivityRollups.CountExcelModel,
                        S: aa.ActivityRollups.CountVM,
                        E: aa.ActivityRollups.CountEmail,
                        A: aa.ActivityRollups.CountAnalystVisit,
                        Grid: aa.Tags
                    } as AccountActivitiesRow
                })
            ),
            tap(() => this.gridApi.hideOverlay())
        );
    }

    clearFilters(): void {
        this.accountActivitySearchForm.patchValue({
            showAccountsWithGrid: true,
            searchTerm: '',
            dateRange: { start: moment().startOf('year'), end: moment().startOf('day') },
            callerTeams: []
        });
    }

    exportExcel(): void {
        let formValues = this.accountActivitySearchForm.getRawValue();
        this.activityService.getAccountActivityExport(formValues.showAccountsWithGrid, formValues.searchTerm,
            formValues.dateRange.start, formValues.dateRange.end, _.join(formValues.callerTeams, ' '))
            .subscribe();
    }

    colorActivityRollupCell(params: any) {
        if(params.node.level !== 0) return { backgroundColor: '#dff0d8' };
        return { backgroundColor: '#d9edf7' };
    }

    colorAccountInfoCell(params: any) {
        if(params.node.level !== 0) return { backgroundColor: '#f5f5f5' };
    }

    rowClicked($event: any) {
        if(!$event.node.group) {
            this.router.navigate([AccountRoutePaths.AccountDetail, $event.data.AccountId]);
        }
    }
}

export interface AccountActivitiesRow {
    ParentAccount: string;
    ParentComdolId: string;
    ComdolId: string;
    AccountId: number;
    Status: string;
    Name: string;
    Region: string;
    Market: string;
    Location: string;
    PrimaryBroker: string;
    PrimaryTrader: string;
    Tier: string;
    Activity: string;
    PN: number;
    X: number;
    Duration: number;
    D: number;
    S: number;
    E: number;
    A: number;
    Grid: string[];
}
