import {Component, OnInit} from "@angular/core";
import {UserService} from "../../Shared/Services/user.service";
import * as _ from "lodash";
import {SharedEmail} from "../../../Services/SharedEmailService";
import {CacheService} from "../../Shared/Services/cache.service";
import {combineLatest, interval, Observable, Subscription} from "rxjs";
import {SharedEmailService} from "../../Shared/Services/shared-email.service";
import {switchMap, tap} from "rxjs/operators";
import {
    ActivatedRoute,
    NavigationEnd,
    Router
} from "@angular/router";
import {EmailRoutePaths} from "../../Email/email-route-paths";

@Component({
    selector: "app-shared-emails-notifications",
    templateUrl: "./shared-emails-notifications.component.html"
})
export class SharedEmailsNotificationsComponent implements OnInit {

    public lastAcknowledgedEmail: SharedEmail;
    public recentSharedEmail: SharedEmail;
    public unseenNotifications: boolean = false;
    public schedule: Subscription;

    sharedEmailsPath = EmailRoutePaths.SharedEmails;

    constructor(private userService: UserService,
                private cacheService: CacheService,
                private sharedEmailService: SharedEmailService,
                private route: ActivatedRoute,
                private router: Router) { }

    ngOnInit(): void {

        this.userService.getCurrentUser()
            .subscribe(user => {
                if (_.includes(user.Features, "SharedEmails")) {
                    let localStorageItem = this.cacheService.getLocalStorage("lastSharedEmail");
                    if (localStorageItem) {
                        this.lastAcknowledgedEmail = localStorageItem as SharedEmail;
                    }
                }

                combineLatest([
                    this.getRecentSharedEmail(),
                    this.route.url
                ]).subscribe(([
                    rse,
                    urls
                ]) => {
                    if (urls.filter(u => u.path === "/shared")) {
                        this.dismissAlert();
                    }
                });

                this.schedule = interval(30000).pipe(
                    switchMap(() =>
                        this.getRecentSharedEmail()
                )).subscribe();

                this.router.events.subscribe(e => {
                    if (e instanceof NavigationEnd && (e as NavigationEnd).url === "/shared") {
                        this.dismissAlert();
                    }
                });
            });
    }

    getRecentSharedEmail(): Observable<SharedEmail> {
        return this.sharedEmailService.getMostRecentSharedEmail()
            .pipe(tap(result => {
                this.recentSharedEmail = result;
                if (!this.lastAcknowledgedEmail && this.recentSharedEmail) {
                    this.unseenNotifications = true;
                } else if (this.lastAcknowledgedEmail && result.DateTimeSent > this.lastAcknowledgedEmail.DateTimeSent) {
                    this.unseenNotifications = true;
                }
            }));
    }

    dismissAlert(): void {
        this.cacheService.setLocalStorage("lastSharedEmail", this.recentSharedEmail)
        this.unseenNotifications = false;
    }

}
