<div class="modal-header">
    Select Query
</div>
<div [formGroup]="form" class="modal-body">

    <div class="form-group">
        <input formControlName="search" type="search" class="form-control input-sm" placeholder="Search" />
    </div>

    <div class="form-group" style="height: 300px;">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>

    <div class="flex-hbox flex-gap">
        <button type="button" class="flex btn btn-sm btn-primary" (click)="select()">Select</button>
        <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
    </div>
</div>