<ng-template #inactiveContactTemplate>
    <div class="modal-body">
        Contact has been deleted or moved to an account you do not have access to view.
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-sm" style="width: 100%;" (click)="modalRef.hide()">Ok</button>
    </div>
</ng-template>

<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
        <div *ngIf="notShowingAllRecords" class="small">
            <em style="font-weight: bold; color: red">(Not showing all records. Add filters.)</em>
        </div>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <div style="display: inline-block;">
            <form class="form-inline" [formGroup]="activitySearchForm">
                <div class="form-group">
                    <div class="input-group">
                        <input
                            type="search"
                            class="form-control input-sm"
                            style="width: 145px;"
                            formControlName="accountSearchTerm"
                            placeholder="Search Account"
                        />
                        <input
                            type="search"
                            class="form-control input-sm"
                            style="width: 145px;"
                            formControlName="commentSearchTerm"
                            placeholder="Search Comment"
                        />
                    </div>
                </div>

                <div class="form-group">
                    <app-date-range-picker formControlName="dateRange" [options]="datePickerOptions"></app-date-range-picker>
                </div>

                <div class="form-group">
                    <app-ees-activity-status-picker
                        formControlName="statuses"
                        style="width: 120px;"
                        placeHolder="Status"
                        [alignRight]="true"
                    >
                    </app-ees-activity-status-picker>
                </div>

                <div class="form-group">
                    <app-ees-activity-category-picker
                        formControlName="types"
                        style="width: 120px;"
                        placeHolder="Type"
                        [alignRight]="true"
                        [appEventTracker]='{"category":"Activity Category","action":"Click"}'
                    >
                    </app-ees-activity-category-picker>
                </div>

                <div class="form-group">
                    <app-clear-filters-button (click)="clearFilters()"></app-clear-filters-button>
                </div>
            </form>
        </div>
    </ng-template>
    
    <app-page-banner
        pagetitle="EES Activities"
        faicon="fa-comments"
        faiconcolor="brown"
        [bannerFormTemplate]="bannerFormTemplate"
        [subTitleTemplate]="bannerSubTitleTemplate"
    >
    </app-page-banner>
    
    <div class="page-body flex">
        <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            [rowClassRules]="rowClassRules"
            (rowClicked)="rowClicked($event)"
        >
        </ag-grid-angular>
    </div>
</div>
