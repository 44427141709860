import {Routes} from "@angular/router";
import {ContactListComponent} from "./ContactList/contact-list.component";
import {InterestListComponent} from "./InterestList/interest-list.component";
import {ContactListsComponent} from "./ContactLists/contact-lists.component";
import {ContactDetailComponent} from "./ContactDetail/contact-detail.component";
import {UserCanActivateRoute} from "../../app-routing.guards";
import {MsalGuard} from "@azure/msal-angular";
import {ContactRoutePaths} from "./contact-route-paths";

export const contactRoutes: Routes = [
    {
        path: `${ContactRoutePaths.ContactDetail}/:contactId`,
        component: ContactDetailComponent,
        canActivate: [UserCanActivateRoute, MsalGuard],
        canActivateChild: [MsalGuard],
        data: {
            title: "Contact Details",
        },
    },
    {
        path: ContactRoutePaths.Contacts,
        children: [
            {
                path: ContactRoutePaths.InterestListPathFragment,
                component: InterestListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Interest List",
                },
            },
            {
                path: ContactRoutePaths.ContactListsPathFragment,
                component: ContactListsComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Contact Lists",
                },
            },
            {
                path: "",
                component: ContactListComponent,
                canActivate: [UserCanActivateRoute],
                data: {
                    title: "Contacts",
                },
            },
        ],
    },
];
