<div class="flex flex-vbox" style="height: 100%;">
    <div style="text-align: center; background-color: #FFFFFF; height: 25px;" [formGroup]="controlGroup">
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="P" /> High Touch
        </label>
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="E" /> ETS
        </label>
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="O" /> Options
        </label>
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="C" /> Checks
        </label>
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="S" /> Syndicate
        </label>
        <label style="margin-right: 5px;">
            <input type="checkbox" formControlName="PNL" /> PnL
        </label>
    </div>
    <div style="position:relative; flex: 1; height: calc(100% - 25px);">
        <canvas class="chart-canvas" style="height: 100% !important;" #myChart></canvas>
    </div>
</div>
