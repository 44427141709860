import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import { UserService } from '../../Shared/Services/user.service';
import {CacheService} from "../../Shared/Services/cache.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {HoldingService} from "../../Shared/Services/holding.service";
import {CallDto, CallListService} from "../../Shared/Services/calllist.service";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {forkJoin} from "rxjs";
import * as moment from "moment";
import {accountTagsVoteRenderer, favoriteCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {
    accountTagComparator,
    getGridDisplayWords,
    isNoContactContact,
    isNoContactReadOnlyContact
} from "../../Shared/ag-grid-options";
import {ToastrService} from "ngx-toastr";
import {User} from "../../../Models/User";
import {ContactNameCellRenderer} from "../../Widget/CellRenderers/contact-name-renderer.component";
import {MoreFiltersModalComponent} from "../../Widget/MoreFilters/more-filters-modal.component";
import {HoldingListDocumentDefinitions} from "../../../Helpers/Pdf/HoldingListDocumentDefinition";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryService} from "../../Shared/Services/query.service";
import {debounceTime} from "rxjs/operators";
import {ContactListSaveModalComponent} from "../../Widget/ContactList/contact-list-save-modal.component";
import {Holding} from "../../../Models/Holding";
import {ColDef, RowDataUpdatedEvent} from "ag-grid-community";
import {isContactable} from "../../Shared/research-status";
import {ClipboardService} from "../../Shared/Services/clipboard.service";

@Component({
    selector: "app-call-list",
    templateUrl: "./call-list.component.html"
})
export class CallListComponent extends BaseGridComponent<CallDto> implements OnInit {

    columnDefs: ColDef[] = [
        {field: 'Contact.Account.Tags', headerName: '', width: 50, cellRenderer: accountTagsVoteRenderer, comparator: accountTagComparator, headerTooltip: 'Grid' },
        {field: 'Contact.Favorite', headerName: '', width: 50, cellRenderer: favoriteCellRenderer, headerTooltip: 'Favorites' },
        {field: 'Category', headerName: 'Cat', width: 60, cellClass: p => p.value === 'N' ? 'interest-category-no' : 'interest-category-not-no' },
        {field: 'Ticker', headerName: 'Tick', width: 70 },
        {field: 'Contact.AccountName', headerName: 'Account', flex: 3, sort: 'asc' },
        {field: 'Contact.Account.City', headerName: 'City', flex: 1 },
        {field: 'Contact', headerName: 'Name', flex: 2, cellRenderer: ContactNameCellRenderer, valueGetter: p => `${p.data.Contact?.LastName}, ${p.data.Contact?.FirstName}` },
        {field: 'Comment', flex: 4 },
        {field: 'Contact.Tier', headerName: 'Tier', width: 70 },
        {colId: 'ContactBrokerLastName', headerName: 'Pers Broker', flex: 1, headerTooltip: 'Personal Broker', valueGetter: p => `${p.data.Contact?.Broker?.LastName || ''}`, tooltipValueGetter: p => `${p.data.Contact?.Broker?.FirstName || ''} ${p.data.Contact?.Broker?.LastName || ''}` },
    ];

    rowClassRules = {
        'do-not-contact': (params) => isNoContactContact(params.data.Contact),
        'do-not-contact-readonly': (params) => isNoContactReadOnlyContact(params.data.Contact),
    };

    components = {
        contactNameCellRenderer: ContactNameCellRenderer
    };

    holdingSearchForm: UntypedFormGroup = this.fb.group({
        tickers: this.fb.control([]),
        analysts: this.fb.control([]),
        instiselSectors: this.fb.control([]),
        myAccounts: this.fb.control(false),
        favorites: this.fb.control(false),
        grid: this.fb.control(false),
        comment: this.fb.control(''),
        tiers: this.fb.control([]),
        categories: this.fb.control([]),
        personalBrokers: this.fb.control([]),
        moreFilters: this.fb.control([]),
    },
    {
        validators: [this.requiredFields()]
    });

    loading: boolean = true;
    user: User = null;

    queryGroups: QueryGroup[] = [];
    addPreview: number = null;

    includeDoNotContactContacts: boolean = true;

    constructor(private fb: UntypedFormBuilder,
                private holdingService: HoldingService,
                private callListService: CallListService,
                private userService: UserService,
                private cacheService: CacheService,
                private modalService: BsModalService,
                private toastrService: ToastrService,
                private queryService: QueryService,
                private clipboardService: ClipboardService) { super(); }

    ngOnInit(): void {

        (pdfMake.vfs as any) = pdfFonts.pdfMake.vfs;

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.user = user;
                this.includeDoNotContactContacts = this.userService.canViewContactEmailWithDoNotContact(user);
            });

        this.clearFilters();

        this.gridOptions.rowSelection = "multiple";
        this.gridOptions.suppressCellFocus = false;
        this.gridOptions.paginationPageSize = 250;

        this.gridOptions.onCellKeyDown = (e) => {
            let keyCode: number = (e.event as any).keyCode;
            if (keyCode === 46) {

                this.loading = true;

                let lowestRowIndex = this.gridApi.getSelectedNodes()
                    .map(n => n.rowIndex)
                    .reduce((prev, curr) => curr < prev ? curr : prev, Number.MAX_SAFE_INTEGER);

                let selectedRows = this.gridApi.getSelectedRows();

                let deleteCallLists$ = selectedRows
                    .map(r => r.Id)
                    .map(holdingId => this.callListService.delete(holdingId));

                forkJoin(deleteCallLists$)
                    .subscribe(_ => {
                        this.gridApi.applyTransaction({ remove: selectedRows });
                        this.gridApi.forEachNode(node => {
                            if (node.rowIndex === lowestRowIndex) {
                                node.setSelected(true, true);
                            }
                        });
                        this.gridApi.setFocusedCell(lowestRowIndex, "Contact.Account.Tags");
                        this.loading = false;
                    });

            }
        }

        this.gridOptions.navigateToNextCell = (params: any) => {
            const suggestedNextCell = params.nextCellPosition;

            // this is some code
            const KEY_UP = "ArrowUp";
            const KEY_DOWN = "ArrowDown";

            const noUpOrDownKeyPressed = params.key!==KEY_DOWN && params.key!==KEY_UP;
            if (noUpOrDownKeyPressed) {
                return suggestedNextCell;
            }

            this.gridApi.forEachNode(node => {
                if (node.rowIndex === suggestedNextCell.rowIndex) {
                    node.setSelected(true, !params.event.shiftKey);
                }
            });

            return suggestedNextCell;
        };

        this.holdingSearchForm.valueChanges.pipe(
            debounceTime(200)
        )
            .subscribe(formValues => {
                this.queryGroups = this.getQueryGroups(formValues);

                this.queryService.getQueryPreview({ QueryGroups: this.queryGroups, ExportObject: "Holding", ExportFields: []})
                    .subscribe(preview => {
                        this.addPreview = preview.Count;
                    });
            });
    }

    requiredFields(): ValidatorFn {
        return (form: UntypedFormGroup) => {
            if (0 === form.get('analysts').value.length +
                      form.get('tickers').value.length +
                      form.get('instiselSectors').value.length) {
                return { requiredFields: true }
            }
            return null;
        }
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onGridReady(params) {
        super.onGridReady(params);

        this.gridApi.showLoadingOverlay();

        const defaultSortModel = [
            { colId: 'Contact.AccountName', sort: 'asc', sortIndex: 0 },
            { colId: 'Contact', sort: 'asc', sortIndex: 1 },
        ];
        params.columnApi.applyColumnState({ state: defaultSortModel });

        this.callListService.getMyCallList()
            .subscribe(calls => {
                this.rowData = calls;
            });
    }

    addToCallList(): void {

        let formValues = this.holdingSearchForm.getRawValue();

        if (0 === formValues.tickers.length +
                  formValues.analysts.length +
                  formValues.instiselSectors.length) {
            return;
        }

        this.loading = true;
        this.gridApi.showLoadingOverlay();

        let queryGroups = this.getQueryGroups(formValues);

        this.callListService.add(queryGroups)
            .subscribe(calls => {
                this.rowData = calls;
            });
    }

    onRowDataChanged($event: RowDataUpdatedEvent) {
        if (this.gridApi) {
            if (this.gridApi.getSelectedRows().length === 0) {
                this.gridApi.forEachNode(node => {
                    if (node.rowIndex === 0) {
                        node.setSelected(true, true);
                    }
                });
                this.gridApi.setFocusedCell(0, "Contact.Account.Tags");
            }
        }
        this.loading = false;
    }

    getQueryGroups(formValues): QueryGroup[] {
        let queryGroups = [];

        if (formValues.tickers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Ticker", "contains", formValues.tickers);
        }

        if (formValues.analysts.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Analyst", "contains", formValues.analysts);
        }

        if (formValues.instiselSectors.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Sector", "contains", formValues.instiselSectors);
        }

        if (formValues.grid) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Account", "Grid", "is", formValues.grid);
        }

        if (formValues.favorites) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Contact", "Favorite", "is", formValues.favorites);
        }

        if (formValues.myAccounts) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Account", "My Account", "is", formValues.myAccounts);
        }

        if (formValues.comment) {
            QueryTranslator.AddQueryGroupValue(queryGroups, "Holding", "Comment", "contains", formValues.comment);
        }

        if (formValues.tiers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Contact Tier", "contains", formValues.tiers);
        }

        if (formValues.personalBrokers.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Contact", "Personal Broker", "contains", formValues.personalBrokers);
        }

        if (formValues.categories.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Category", "contains", formValues.categories);
        }

        queryGroups.push(...formValues.moreFilters);

        return queryGroups;
    }

    clearCallList(): void {
        let modalRef = this.modalService.show(ConfirmModalComponent, { initialState: { message: 'Are you sure you want to clear your Call List?' } });

        modalRef.content.action.subscribe(_ => {
            this.callListService.clear()
                .subscribe(_ => {
                    this.rowData = [];
                });
        });
    }

    clearFilters() {
        this.holdingSearchForm.patchValue({
            tickers: [],
            analysts: [],
            instiselSectors: [],
            grid: false,
            myAccounts: false,
            favorites: false,
            comment: '',
            categories: [],
            tiers: [],
            personalBrokers: [],
            moreFilters: [{ Queries: [{ Object: 'Account', Field: 'Contact Groups', Operation: 'not contains', Values: ['IER','PWM'] }]}]
        })
    }

    getAllRows(): CallDto[] {
        let rows: CallDto[] = [];
        this.gridApi.forEachNode(node => rows.push(node.data));
        return rows;
    }

    copyEmailToClipboard() {
        let holdings = this.getAllRows();
        if (holdings.length === 0) {
            return;
        }
        let emails = holdings
            .filter(calls => this.includeDoNotContactContacts || isContactable(calls.Contact))
            .map(r => r.Contact.Email)
            .filter(email => email)
            .filter((email, index, arr) => arr.indexOf(email) === index);

        this.clipboardService.writeText(emails.join(';'))
            .subscribe(_ => {
                this.toastrService.success(`${emails.length} Emails Copied to Clipboard`);
            });
    }

    exportToExcel() {
        if (this.rowData.length === 0) {
            return;
        }
        this.callListService.downloadExcel().subscribe();
    }

    exportToPdf() {
        let holdings = this.getAllRows();
        if (holdings.length === 0) {
            return;
        }

        let data = holdings.map(r => {
            return {
                ContactTier: r.Contact.Tier,
                AccountName: r.Contact.AccountName,
                ContactName: `${r.Contact.LastName}, ${r.Contact.FirstName}`,
                Ticker: r.Ticker,
                Comment: r.Comment,
                Category: r.Category,
                PersonalBroker: r.Contact.Broker?.LastName || '',
            };
        })
            .sort((a, b) => {
                if (a.AccountName < b.AccountName) {
                    return -1;
                }
                else if (a.AccountName > b.AccountName) {
                    return 1;
                }
                else if (a.ContactName < b.ContactName) {
                    return -1;
                }
                else if (a.ContactName > b.ContactName) {
                    return 1;
                }

                return 0;
            });

        let headerData: {property: string, headerName:string}[] = [
            {property:"ContactTier", headerName:"Tier"},
            {property:"AccountName", headerName:"Account"},
            {property:"ContactName", headerName:"Client"},
            {property:"Ticker", headerName:"Tick"},
            {property:"Comment", headerName:"Comment"},
            {property:"Category", headerName:"Cat"},
            {property:"PersonalBroker", headerName:"PersBk"}
        ];

        let docDef = HoldingListDocumentDefinitions.getSimpleTable(data, headerData, 'Call list for ' + this.user.Id);

        let now = moment();
        let fileName = `CallList_${now.format("LL")}T${now.format("LT")}.pdf`;
        pdfMake.createPdf(docDef).download(fileName);
    }

    moreFilters() {

        let queryGroups = [...this.holdingSearchForm.get('moreFilters').value];

        if (this.holdingSearchForm.get('personalBrokers').value.length > 0) {
            QueryTranslator.AddQueryGroupValues(queryGroups, 'Contact', 'Personal Broker', 'contains', this.holdingSearchForm.get('personalBrokers').value);
        }

        let initialState = {
            queryGroups: queryGroups,
            showActivityFilters: false,
        };

        let modalRef = this.modalService.show(MoreFiltersModalComponent, { initialState: initialState, ignoreBackdropClick: false, keyboard: false, backdrop: 'static', class: 'modal-lg' });

        modalRef.content.queryGroupsUpdated
            .subscribe((updatedQueryGroups: QueryGroup[]) => {

                let persBrokers = updatedQueryGroups.find(g => g.Queries[0].Field === 'Personal Broker');
                if (persBrokers) {
                    this.holdingSearchForm.patchValue({
                        personalBrokers: persBrokers.Queries[0].Values
                    });
                }

                updatedQueryGroups = updatedQueryGroups.filter(g => g !== persBrokers);

                this.holdingSearchForm.patchValue({
                    moreFilters: updatedQueryGroups
                });
            });
    }

    sendBlastEmail() {

        let moreFiltersQueryGroups = this.holdingSearchForm.get('moreFilters').value as QueryGroup[];

        let researchEmail = moreFiltersQueryGroups.find(g => g.Queries[0].Object === 'Holding' && g.Queries[0].Field === 'Research Email');
        if (researchEmail) {
            researchEmail.Queries[0].Value = 'true';
        } else {
            QueryTranslator.AddQueryGroupValue(moreFiltersQueryGroups,'Holding','Research Email', 'is', 'true');
        }

        let doNotContact = moreFiltersQueryGroups.find(g => g.Queries[0].Object === 'Contact' && g.Queries[0].Field === 'Do Not Contact');
        if (doNotContact) {
            doNotContact.Queries[0].Value = 'false';
        } else {
            QueryTranslator.AddQueryGroupValue(moreFiltersQueryGroups,'Contact','Do Not Contact', 'is', 'false');
        }

        this.holdingSearchForm.patchValue({
            tiers: ['1','2','3','4'],
            categories: ['H','I'],
            moreFilters: moreFiltersQueryGroups,
        });
    }

    saveContactList() {

        let contactIds = [... new Set( this.rowData.map(c => c.Contact.Id))];

        if (contactIds.length === 0) {
            return;
        }

        let initialState = {
            contactIds: contactIds
        };

        this.modalService.show(ContactListSaveModalComponent, { initialState: initialState, ignoreBackdropClick: false, keyboard: false, backdrop: 'static', class: 'modal-lg' });
    }
}
