import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges, TemplateRef,
  ViewChild, ViewEncapsulation
} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DatePickerOptionsBuilder} from "../../../DatePickerOptionsBuilder";
import * as daterangepicker from "bootstrap-daterangepicker";
import * as moment from "moment"
import type {DateRange} from "./date-range";
declare var $: JQueryStatic;

@Component({
    selector: "app-date-range-picker",
    template: `
        <span
            #picker
            class="btn btn-sm btn-default date-range-picker"
            [class.text-picker]="view === 'text'"
            [ngStyle]="ngStyle"
            [tooltip]="view === 'button' ? dateRangeTooltip : null"
            container="body"
        >
            <i *ngIf="view === 'button'" class="fa fa-calendar"></i>
            <span *ngIf="view==='text'" class="form-control input-sm" style="background:#fff;cursor:pointer;padding:6px 10px;border:1px solid #ccc;width:150px" placeholder="Date">{{dateRangeView}}
                <i class="pull-right fa fa-calendar" style="margin-top:2px"></i>
            </span>
            <span *ngIf="view==='custom'">
                <ng-container [ngTemplateOutlet]="triggerView"></ng-container>
            </span>
        </span>
    `,
    styles: [`
        .app-class {
            background-color: blue;
        }
    `],
    styleUrls: ["date-range-picker.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true
        }
    ]
})
export class DateRangePickerComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {

    private picker: daterangepicker;
    @Input() dates: DateRange;
    @Input() options: daterangepicker.Options;
    @Input() enableRanges: boolean = false;
    @Input() ngStyle: any;
    @Input() view: "button" | "text" | "custom" = "button";
    @Input() selectMode: "startDate" | "endDate" = "startDate";
    @Input() triggerView: TemplateRef<any>;
    @Output() rangeSelected = new EventEmitter<DateRange>();
    @ViewChild("picker", {static: true}) pickerElement: ElementRef;

    onChange = (range: DateRange) => {};
    onTouched = () => {};

    private internalValue: DateRange;

    constructor() {
        this.dates = this.internalValue = this.clone({start: moment(), end: moment()});
    }

    ngOnChanges(changes: SimpleChanges): void {
        $(this.pickerElement.nativeElement).off('click');
        $(this.pickerElement.nativeElement).off('keydown');
        this.initializeDateRangePicker();
    }

    ngOnInit(): void {
        if(this.dates){
            this.internalValue = this.clone(this.dates);
        }
    }

    ngAfterViewInit(): void {
        this.initializeDateRangePicker();
    }

    initializeDateRangePicker(): void {
        const options: daterangepicker.Options = {...this.opts(), ...this.options};

        if (this.enableRanges) {
            const currentDate = moment();
            options.ranges = {
                "Today": [currentDate, currentDate],
                "Last 30 Days": [currentDate.clone().add(-30, "day"), currentDate],
                "Month to Date (MTD)": [currentDate.clone().startOf("month"), currentDate],
                "Last 12 Months": [currentDate.clone().add(-12, "month"), currentDate],
                "Current Year (YTD)": [currentDate.clone().startOf("year"), currentDate],
                "Last Year": [
                    currentDate.clone().add(-1, "year").startOf("year"), currentDate.clone().add(-1, "year").endOf("year")
                ],
                "Current Quarter": [currentDate.clone().startOf("quarter"), currentDate],
                "Last Quarter": [
                    currentDate.clone().add(-1, "quarter").startOf("quarter"),
                    currentDate.clone().add(-1, "quarter").endOf("quarter")
                ]
            };
        }

        if (this.picker) {
            this.picker.remove();
        }

        this.picker = new daterangepicker(this.pickerElement.nativeElement, options, (start, end, label) => {
            this.internalValue = this.clone({start: start, end: end});
            this.onChange(this.internalValue);
            this.rangeSelected.emit(this.internalValue);
        });

        $(this.pickerElement.nativeElement).off('show.daterangepicker').on("show.daterangepicker", this.activateEndDate.bind(this));
        $(this.pickerElement.nativeElement).off('cancel.daterangepicker').on("cancel.daterangepicker", options.eventHandlers["cancel.daterangepicker"]);
        $(this.pickerElement.nativeElement).off('apply.daterangepicker').on("apply.daterangepicker", options.eventHandlers["apply.daterangepicker"]);
        this.picker["clickDate"] = this.clickDate;
        this.picker.setStartDate(this.internalValue.start);
        this.picker.setEndDate(this.internalValue.end);
    }

    opts(): daterangepicker.Options {
        return new DatePickerOptionsBuilder()
            .setApplyHandler((ev, picker) =>{console.log("Applied from picker", ev, picker);})
            .buildOptions();
    }

    // Allows Angular to register a function to call when the model (rating) changes.
    // Save the function as a property to call later here.
    registerOnChange(fn: (range: DateRange) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: DateRange): void {
        if(!this.picker){ // this function is called before the control is available
            this.internalValue = obj;
        } else {
            this.internalValue = obj;
            this.picker.setStartDate(obj.start);
            this.picker.setEndDate(obj.end);
        }
        this.onChange(obj);
    }

    get dateRangeView(): string {
        const start = this.internalValue.start.format("MMM Do, YYYY");
        const end = this.internalValue.end.format("MMM Do, YYYY");
        return `${start} - ${end}`;
    }
    get dateRangeTooltip(): string {
        const start = this.internalValue.start.format("L");
        const end = this.internalValue.end.format("L");
        return `${start} - ${end}`;
    }

    private activateEndDate(ev: any, picker: daterangepicker): void {
        console.log("adding event handlers");
        if(this.selectMode == "endDate"){
            picker.endDate = null;
            $(picker.container).find('input[name="daterangepicker_start"]').removeClass('active').blur();
            $(picker.container).find('input[name="daterangepicker_end"]').addClass('active').focus();
        }
    }
    private clickDate(e): void {
        console.log("clicked")
    }
    private clone(dateRange: DateRange): DateRange {
        return {start: dateRange.start.clone().startOf("day"), end: dateRange.end.clone().startOf("day")};
    }
}
