<div class="flex flex-vbox">

    <div style="margin-bottom: 5px;">
        <form [formGroup]="form">
            <div class="flex-hbox flex-gap">
                <span class="btn btn-default btn-sm" (click)="onClickAdd('Subscription')">
                    <span class="fa fa-plus"></span>
                </span>
                <span class="btn btn-default btn-sm" (click)="onClickAdd('Spec Mailing')">
                    <span class="fa fa-pencil"></span> Specialty Mailings
                </span>
                <input formControlName="search" type="search" placeholder="Search" class="flex form-control input-sm"/>
                <app-analyst-picker formControlName="analysts" [alignRight]="true"></app-analyst-picker>
            </div>
        </form>
    </div>

    <ag-grid-angular
            style="width: 100%; height: 100%;"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData$ | async"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            (rowClicked)="onRowClicked($event)"
            (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>

</div>