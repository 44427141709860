<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerSubTitleTemplate>
        <span>{{ getDisplayWords() }}</span>
    </ng-template>
    
    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="accountSearchForm">
            <div class="input-group">
                <div class="input-group-btn">
                    <div class="btn btn-primary btn-sm btn-toggle"
                         btnCheckbox
                         formControlName="myAccounts">
                        <span class="fa fa-sitemap"></span>    
                    </div>
                </div>
                <input type="search"
                       class="form-control input-sm"
                       style="width: 250px;"
                       formControlName="searchTerm"
                       placeholder="Search" />    
            </div>
        </form>
    </ng-template>
    
    <app-page-banner
            pagetitle="Account List" 
            faicon="fa-sitemap"
            faiconcolor="#2e8b57"
            [bannerFormTemplate]="bannerFormTemplate"
            [subTitleTemplate]="bannerSubTitleTemplate">
    </app-page-banner>
    
    <div class="page-body flex">

        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [frameworkComponents]="frameworkComponents"
                [rowClassRules]="rowClassRules"
                (gridReady)="onGridReady($event)"
                (selectionChanged)="onSelectionChanged()"
                rowSelection="single"
        >
        </ag-grid-angular>
        
    </div>
</div>
