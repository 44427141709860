import { Directive,HostListener,Input } from '@angular/core';
import {AnalyticsService} from "../../Shared/Services/analytics.service";

@Directive({
    selector: '[appEventTracker]'
})
export class EventTrackerDirective {

    @Input('appEventTracker') option:any;

    @HostListener('click', ['$event']) onClick($event){
        let name = this.option.name || '';
        
        if (!name) {
            console.log('Tracking is missing data');
            return;
        }
        
        this.analyticsService.logEvent(name, {});
    }

    constructor(private analyticsService: AnalyticsService) { }
}