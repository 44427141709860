import {Component, Input, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "app-do-not-contact-banner",
    templateUrl: "./do-not-contact-banner.component.html",
    styleUrls: ["do-not-contact-banner.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DoNotContactBannerComponent {
    @Input()
    reason: string;
}
