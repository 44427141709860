import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {EventService} from "../../Shared/Services/event.service";
import {CalendarEventAccount} from "../../../Components/Events/CalendarEventAccount";

@Component({
    selector: "app-multi-event-company-select",
    templateUrl: "./multi-event-company-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiEventCompanySelectComponent),
            multi: true
        }
    ]
})
export class MultiEventCompanySelectComponent implements  OnInit, ControlValueAccessor {

    @Input()
    eventId: number;

    @Input()
    maxItems: number = 999;

    @Output()
    addedTickers = new EventEmitter<string[]>();

    companies: CalendarEventAccount[];

    companiesControl: UntypedFormControl = this.fb.control([]);

    selectedCompanyIds: number[] = [];

    onChange = (companyIds: number[]) => {};
    onTouched = () => {};

    constructor(private fb: UntypedFormBuilder,
                private eventService: EventService,
    ){}

    ngOnInit(): void {
        this.eventService.getEventAccounts(this.eventId)
            .subscribe(companies => {
                this.companies = companies
                    .map(a => {a.SearchName = a.Name + " (" + a.Ticker + ")"; return a})
                    .sort((a, b) =>
                        a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 :
                        a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 :
                        0);
            });

        this.companiesControl.valueChanges
            .subscribe((companies: CalendarEventAccount[]) => {
                if (this.onChange) {
                    this.onChange(companies.map(c => c.CompanyId));
                }

                let addedCompanyIds = companies
                    .filter(c => !this.selectedCompanyIds.includes(c.CompanyId))
                    .map(c => c.CompanyId);

                this.selectedCompanyIds = companies.map(c => c.CompanyId);

                if (addedCompanyIds.length > 0 && this.companies) {
                    let tickersToSend = this.companies
                        .filter(c => addedCompanyIds.includes(c.CompanyId))
                        .map(c => c.Ticker);
                    this.addedTickers.emit(tickersToSend);
                }

            });
    }

    registerOnChange(fn: (companyIds: number[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    writeValue(companyIds: number[]): void {
        if (companyIds && companyIds.length > 0) {
            this.eventService.getEventAccounts(this.eventId)
                .subscribe(companies => {
                    let items = companies.filter(c => companyIds.includes(c.CompanyId))
                        .map(a => {a.SearchName = a.Name + " (" + a.Ticker + ")"; return a});
                    this.selectedCompanyIds.push(...(items.map(i => i.CompanyId)));
                    this.companiesControl.patchValue(items);
                });
        } else {
            this.companiesControl.patchValue([]);
        }
    }
}
