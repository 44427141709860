<i class="fa fa-address-book pointer icon" [popover]="contactListsPopover" [outsideClick]="true" (onShown)="showLists()" tooltip="Contact Lists" placement="auto"></i>

<ng-template #contactListsPopover>
    <div class="contact-list-popover">
        <div class="popover-title flex-hbox">
            <div class="flex-2">
                Contact Lists
            </div>
            <div class="flex-2 text-right">
                <a class="pointer" (click)="goToContactLists()">Manage Lists</a>
            </div>
        </div>
        <div style="margin: 10px 5px;">
        <input type="search"
               class="form-control input-sm"
               [formControl]="searchTerm"
               placeholder="Search" />
        </div>
        <checkbox-list *ngIf="options"
                       [options]="filteredOptions"
                       (checked)="onChecked($event)"
                       (unchecked)="onUnchecked($event)">
        </checkbox-list>
    </div>

</ng-template>
