<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="filterForm">
            <div class="form-group">
                <div class="btn btn-primary btn-sm btn-toggle"
                     btnCheckbox
                     formControlName="myAccounts"
                     tooltip="My Accounts"
                     placement="bottom"
                >
                    <span class="fa fa-sitemap"></span>
                </div>
            </div>
            <div class="form-group">
                <input
                    type="search"
                    class="form-control input-sm"
                    style="width: 250px;"
                    formControlName="searchAccount"
                    placeholder="Search accounts"
                />
            </div>
            <div class="form-group">
                <input
                    type="search"
                    class="form-control input-sm"
                    style="width: 250px;"
                    formControlName="searchTicker"
                    placeholder="Search tickers"
                />
            </div>
            <div class="form-group">
                <app-date-range-picker
                    [options]="dateRangeOptions"
                    [formControl]="dateRange"
                >
                </app-date-range-picker>
            </div>
            <div class="form-group">
                <app-commission-category-picker
                    [alignRight]="true"
                    formControlName="categories"
                >
                </app-commission-category-picker>
            </div>
            <div class="form-group">
                <div
                    class="btn btn-primary btn-sm btn-toggle"
                    [formControl]="groupByParentToggle"
                    btnCheckbox
                    (click)="toggleGroupByParent()"
                >
                    Group By Parent
                </div>
            </div>
            <div class="form-group">
                <span
                    class="btn btn-sm btn-default"
                    (click)="collapseAll()"
                >
                    <i
                        class="fa"
                        [class.fa-compress]="!isCollapsed"
                        [class.fa-expand]="isCollapsed"
                    >
                    </i>
                </span>
            </div>
        </form>
        <div
            style="text-align: right"
            *ngIf="filterForm.controls['categories'].value.length > 0"
        >
            <strong>Categories:</strong> {{ getSelectedCategories() }}
        </div>
    </ng-template>
    
    <app-page-banner
        [pagetitle]="pageTitle"
        faicon="fa-bar-chart"
        faiconcolor="#800080"
        [bannerFormTemplate]="bannerFormTemplate"
    >
    </app-page-banner>

    <div class="page-body flex">
        <app-split-trade-detail-grid
            [attr.hidden]="groupByParent ? '' : null"
            [splitTradeDetailRows]="splitRowData"
            [dataLoading]="dataLoading"
            [isCollapsed]="isCollapsed"
        >
        </app-split-trade-detail-grid>

        <app-non-split-trade-detail-grid
            [attr.hidden]="!groupByParent ? '' : null"
            [nonSplitTradeDetailRows]="nonSplitRowData"
            [dataLoading]="dataLoading"
            [isCollapsed]="isCollapsed"
        >
        </app-non-split-trade-detail-grid>
    </div>
</div>