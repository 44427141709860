import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {BaseHttpService, HttpOptions} from "./base-http.service";
import * as moment from "moment/moment";

@Injectable({
    providedIn: 'root',
})
export class EntitlementService {

    constructor(private baseHttp: BaseHttpService) {
    }

    getContactEntitlements(): Observable<ContactEntitlementDto[]> {
        return this.baseHttp.getData(`/contactentitlements/`);
    }

    getContactEntitlementByVendorId(vendorCode: string, vendorId: string): Observable<ContactEntitlementDto> {
        return this.baseHttp.getData(`/contactentitlements/${vendorCode}/${vendorId}`);
    }

    getBusinessUnits(forceRefresh: boolean = false): Observable<BusinessUnit[]> {
        return this.baseHttp.getCacheOrData(`/contactentitlements/businessunits`, 5 * 60 * 1000, forceRefresh);
    }

    updateVendorEntitlements(contactId: number, entitlements: any): Observable<any> {
        return this.baseHttp.postData(`/vendorentitlements/${contactId}`, entitlements);
    }
    
    getVendorEntitlementsForContact(contactId: number): Observable<VendorEntitlementDtoShow[]> {
        return this.baseHttp.getData(`/vendorentitlements/${contactId}`);
    }

    getResearchOnlineEntitlement(contactId: number): Observable<ResearchOnlineEntitlement> {
        return this.baseHttp.getData(`/vendorentitlements/${contactId}/researchonline`);
    }

    saveResearchOnlineEntitlement(entitlementToUpsert: ResearchOnlineEntitlementToUpsert): Observable<ResearchOnlineEntitlementResponse> {
        return this.baseHttp.postData(`/vendorentitlements/researchonline`, entitlementToUpsert);
    }

    saveResearchOnlineEntitlementNotes(notes: ResearchOnlineEntitlementNotes): Observable<ResearchOnlineEntitlement> {
        return this.baseHttp.putData(`/vendorentitlements/researchonline/notes`, notes);
    }

    deleteResearchOnlineEntitlement(entitlementToDelete: ResearchOnlineEntitlementToDelete): Observable<ResearchOnlineEntitlement> {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
            body: entitlementToDelete
        } as HttpOptions;
        return this.baseHttp.deleteDataWithConfig(`/vendorentitlements/researchonline`, config);
    }

    getVendorEntitlementsList(): Observable<VendorEntitlementDtoShow[]> {
        return this.baseHttp.getData(`/vendorentitlements`);
    }
    
    getContactEntitlementTemplate(): Observable<any> {
        return this.baseHttp.getData(`/contactentitlements/template`);
    }
    
    enqueueEntitlement(entitlementQueue: EntitlementQueue): Observable<any> {
        return this.baseHttp.postData(`/vendorentitlementsqueue`, entitlementQueue);
    }
    
    deleteEnqueuedEntitlement(id: number): Observable<any> {
        return this.baseHttp.deleteData(`/vendorentitlementsqueue/${id}`);
    }
    
}

export interface ContactEntitlementDto{
    ContactId: number;
    ContactFirstName: string;
    ContactLastName: string;
    AccountName: string;
    Vendor: string;
    VendorId: string;
    VendorName: string;
    EntitlementStatus: string;
    UserStatus: string;
    EntitlementType: string;
    BusinessUnit: string;
    UpdatedDate: Date | moment.Moment | string;
    VendorCode: string;
    PendingDeleteId: number | null;
}

export interface VendorEntitlementDtoShow {
    Code: string;
    Description: string;
    ShortName: string;
    EntitlementInfo: EntitlementInfo[];
}

export interface EntitlementInfo {
    VendorId: string;
    IsSubscribed: boolean;
    CreateDate: Date;
}

export interface ResearchOnlineEntitlement {
    ContactId: number;
    Username: string;
    Notes: string;
    InstiselEnteredBy: string;
    EnteredDate: Date | moment.Moment | string;
    InstiselLastUpdatedBy: string;
    LastUpdatedDate: Date | moment.Moment | string;
    IsArchived: boolean;
}

export interface ResearchOnlineEntitlementResponse {
    HasError: boolean;
    ErrorMessage: string;
    ResearchOnlineEntitlement: ResearchOnlineEntitlement
}

export interface ResearchOnlineEntitlementToUpsert {
    ContactId: number;
    Username: string;
    Notes: string;
    InstiselEnteredBy: string;
    InstiselLastUpdatedBy: string;
    Password: string;
}

export interface ResearchOnlineEntitlementToDelete {
    ContactId: number;
    Notes: string;
    InstiselEnteredBy: string;
    InstiselLastUpdatedBy: string;
}

export interface ResearchOnlineEntitlementNotes {
    ContactId: number;
    Notes: string;
    InstiselEnteredBy: string;
    InstiselLastUpdatedBy: string;
}

export interface BusinessUnit {
    Id: number;
    Name : string;
}

export interface EntitlementQueue
{
    Id: number | null;
    VendorCode: string;
    VendorId: string;
    EntitlementAction: EntitlementAction
}

export type EntitlementAction = "Insert" | "Delete";

