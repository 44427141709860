import {Component, OnInit} from "@angular/core";
import * as _ from "lodash";
import {
    getGridDisplayWords
} from "../../Shared/ag-grid-options";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import * as moment from "moment";
import {DatePickerOptionsBuilder} from "../../../DatePickerOptionsBuilder";
import {EmailCampaign, EmailService} from "../../Shared/Services/email.service";
import {objectSearchPredicate} from "../../Shared/query-operators";
import {dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {TierService} from "../../Shared/Services/tier.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ReadershipModalService} from "../../Widget/Email/ReadershipModal/readership-modal-service";

@Component({
    selector: "app-email-readership",
    templateUrl: "./readership.component.html"
})
export class ReadershipComponent extends BaseGridComponent<EmailCampaign> implements  OnInit {
    
    dateRangeOptions: any;
    
    columnDefs = [
        { field: 'CreatedAt', headerName: 'Date', sort: 'desc', flex: 1, cellRenderer: dateRenderer , tooltipValueGetter: dateRenderer },
        { field: 'Subject', headerName: 'Subject / Headline', flex: 3, tooltipField: 'Subject' },
        { field: 'SentBys', headerName: 'Sender(s)', flex: 1, valueGetter: this.renderSentBys, tooltipValueGetter: this.renderSentBys },
        { field: 'Source', headerName: 'Source', flex: 1, tooltipField: 'Source' },
        { field: 'Tickers', headerName: 'Ticker(s)', flex: 1, tooltipField: 'Tickers' },
        { field: 'Tiers', headerName: 'Tier(s)', flex: 1, cellRenderer: _.bind(this.renderTiers, this), tooltipValueGetter: _.bind(this.renderTiers, this) },
        { field: 'MyRecipientCount', headerName: 'My Recipients', flex: 1, cellRenderer: this.renderMyRecipients, tooltipValueGetter: this.renderMyRecipients },
        { field: 'MyOpenRate', headerName: 'My Open %', flex: 1, cellRenderer: this.renderRate, tooltipValueGetter: this.renderRate },
        { field: 'MyClickRate', headerName: 'My Click %', flex: 1, cellRenderer: this.renderRate, tooltipValueGetter: this.renderRate },
        { field: 'RecipientCount', headerName: 'Recipients', flex: 1, cellRenderer: this.renderRecipient, tooltipValueGetter: this.renderRecipient },
        { field: 'OpenRate', headerName: 'Open %', flex: 1, cellRenderer: this.renderRate, tooltipValueGetter: this.renderRate },
        { field: 'ClickRate', headerName: 'Click %', flex: 1, cellRenderer: this.renderRate, tooltipValueGetter: this.renderRate },
    ];

    rowData$: Observable<EmailCampaign[]>;

    emailSearchForm: UntypedFormGroup = this.fb.group({
        emailType: this.fb.control(['Email', 'BlueMatrix - Limited', 'Post Only']),
        dateRange: this.fb.control({ 
            start: moment().add(-7, "days"),
            end: moment().endOf('day') 
        }),
        searchTerm: this.fb.control(''),
        teamIds: this.fb.control([])
    });
    
    constructor(private fb: UntypedFormBuilder,
                private emailService: EmailService,
                private readershipModalService: ReadershipModalService,
                private tierService: TierService){
        super();
    }
    
    ngOnInit(): void {
        this.dateRangeOptions = new DatePickerOptionsBuilder()
            .setRangesToPredefinedList(moment())
            .buildOptions();
        
        this.setRowData();
    }
    
    setRowData(): void {
        
        const campaigns$ = this.emailSearchForm.controls['dateRange'].valueChanges.pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            startWith(this.emailSearchForm.controls['dateRange'].value),
            switchMap(dateRange => this.emailService.searchEmails(dateRange.start, dateRange.end, null)),
            map(campaigns => campaigns.map(c => {
                c.OpenRate = 100 * c.OpenCount / c.RecipientCount || 0;
                c.ClickRate = 100 * c.ClickCount / c.RecipientCount || 0;
                c.MyOpenRate = 100 * c.MyOpenCount / c.MyRecipientCount || 0;
                c.MyClickRate = 100 * c.MyClickCount / c.MyRecipientCount || 0;
                return c;
            }))
        );
        const emailTypes$ = this.emailSearchForm.controls['emailType'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['emailType'].value)
        );
        const searchTerm$ = this.emailSearchForm.controls['searchTerm'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['searchTerm'].value)
        );
        const teamIds$ = this.emailSearchForm.controls['teamIds'].valueChanges.pipe(
            startWith(this.emailSearchForm.controls['teamIds'].value)
        )
        this.rowData$ = combineLatest([campaigns$, emailTypes$, searchTerm$, teamIds$]).pipe(
            tap(() => this.gridApi?.showLoadingOverlay()),
            map(([campaigns, emailTypes, searchTerm, teamIds]) => {
                return campaigns.filter(c =>
                    objectSearchPredicate(c, searchTerm) &&
                    this.isEmailType(c, emailTypes) &&
                    this.isTeamIds(c, teamIds)
                );
            })
        );
    }

    isEmailType(campaign: EmailCampaign, emailTypes: string[]): boolean {
        if (!emailTypes) return false;
        
        return (campaign.Source === 'Email' && emailTypes.indexOf('Email') > -1) ||
            (campaign.Source === 'BlueMatrix' && campaign.IsAllTiers && emailTypes.indexOf('BlueMatrix - All') > -1) ||
            (campaign.Source === 'BlueMatrix' && !campaign.IsAllTiers && emailTypes.indexOf('BlueMatrix - Limited') > -1) ||
            (campaign.Source === 'Post Only' && emailTypes.indexOf('Post Only') > -1) ||
            false;
    }
    
    isTeamIds(campaign: EmailCampaign, teamIds: number[]): boolean {
    
        if (teamIds.length === 0) {
            return true;
        }

        const campaignSentByTeamsMatchesFilteredTeams =
            campaign.SentBys
                .map(p => p.TeamId)
                .filter(value => teamIds.includes(value)).length > 0;

        const campaignOnBehalfOfTeamsMatchesFilteredTeams =
            (campaign.SendOnBehalfOfs ?? [])
                .map(p => p.TeamId)
                .filter(value => teamIds.includes(value)).length > 0;
        
        return (
            campaignSentByTeamsMatchesFilteredTeams ||
            campaignOnBehalfOfTeamsMatchesFilteredTeams
        );
    }
    
    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    onRowClicked($event: any) {
        this.readershipModalService.openReadershipModal($event.data.Id, $event.data.Source);
    }
    
    renderSentBys(params: any): string {
        let senders = params.data.SentBys ? params.data.SentBys.map(p => p.LastName).sort().join(', ') : '';
        if (params.data.SendOnBehalfOfs && params.data.SendOnBehalfOfs.length > 0 && params.data.SendOnBehalfOfs[0]) {
            senders += ` OBO ${params.data.SendOnBehalfOfs[0].LastName}`;
        }
        return senders;
    }
    renderRate(params: any): string {
        if (params.data.Source === 'BlueMatrix' || params.data.Source === 'Post Only') return '-';

        return params.value.toFixed(0) + '%';
        
    }

    renderMyRecipients(params: any): string {
        return params.data.Source === 'Post Only' ? '-' : `${params.data.MyRecipientCount}`;
    }

    renderRecipient(params: any): string {
        return params.data.Source === 'Post Only' ? '-' : `${params.data.RecipientCount}`;
    }

    renderTiers(params: any): string {
        return params.data.Source === 'Email' ? '' :
               params.data.Source === 'Post Only' ? '':
               params.data.IsAllTiers ? 'All' :
               params.data.Tiers && this.sortAndJoinTears(params.data.Tiers) ||
               "";
    }
    
    sortAndJoinTears(tiers: string[]): string {
        let allTiers = this.tierService.getAllTiers();
        return _.chain(allTiers)
            .filter(at => _.includes(tiers, at))
            .filter(it => !isNaN(+it))
            .join(', ')
            .value();
    }
}
