import { Component, Input, OnChanges } from "@angular/core";
import { BaseGridComponent } from "../../Shared/base-grid.component";
import * as _ from "lodash";
import { RVCSummary } from "../../../Models/RVCSummary";
import * as moment from "moment";

@Component({
    selector: "app-yoy",
    templateUrl: "./yoy.component.html"
})
export class YoyComponent extends BaseGridComponent<YOYRow> implements OnChanges {

    @Input()
    rvcData: RVCSummary[];

    @Input()
    year: number;

    columnDefs = [
        { field: 'Category', rowGroup: true, hide: true },
        { 
            field: 'MeetingPercentage', 
            headerName: 'Mtgs', 
            flex: 1, 
            sortable: false, 
            tooltipValueGetter: params => params.value.Year + ": Mtgs",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    toString: () => params.data?.MeetingPercentage ?? ""
                }
            },
            aggFunc: params => {
                return {
                    Year: params.values[0]?.Year,
                    toString: () => params.values[0]?.toString() ?? ""
                }
            },
        },
        { 
            field: 'SeatPercentage', 
            headerName: 'Seats', 
            flex: 1, 
            sortable: false, 
            tooltipValueGetter: params => params.value.Year + ": Seats",
            valueGetter: params => {
                return {
                    Year: params.data?.Year,
                    toString: () => params.data?.SeatPercentage ?? ""
                }
            },
            aggFunc: params => {
                return {
                    Year: params.values[0]?.Year,
                    toString: () => params.values[0]?.toString() ?? ""
                }
            },
        },
        { field: 'Year', headerName: 'Year', flex: 1, pivot: true, hide: true },
    ];

    autoGroupColumnDef = {
        headerName: 'Category',
        sortable: false,
        cellRendererParams: { suppressCount: true },
        width: 130
    };

    constructor() {
        super();
        this.gridOptions.pagination = false;
    }

    ngOnChanges(): void {
        const yearRange = _.range(+this.year - 3, +this.year + 1);
        const rvcDataByYear = _.groupBy(this.rvcData, d => d.Year);
        this.rowData = yearRange.map(year => {
            const totalMeetingCount = _.chain(rvcDataByYear[year]).filter(d => !["P", "X", "D"].includes(d.Category)).map(d => d.MeetingCount).sum().value();
            const totalSeatCount = _.chain(rvcDataByYear[year]).filter(d => !["P", "X", "D"].includes(d.Category)).map(d => d.SeatCount).sum().value();
            const prevTotalMeetingCount = _.chain(rvcDataByYear[year - 1]).filter(d => !["P", "X", "D"].includes(d.Category)).map(d => d.MeetingCount).sum().value();
            const prevTotalSeatCount = _.chain(rvcDataByYear[year - 1]).filter(d => !["P", "X", "D"].includes(d.Category)).map(d => d.SeatCount).sum().value();

            if (!prevTotalMeetingCount && !prevTotalSeatCount) {
                return {
                    Category: "YoY %",
                    Year: year,
                    MeetingPercentage: year === yearRange[yearRange.length - 1] ? "" : "N/A",
                    SeatPercentage: year === yearRange[yearRange.length - 1] ? "" : "N/A"
                }
            }

            const currentYear = moment().year();
            const yoyMeetingPct = year === currentYear ? "" : _.round(((totalMeetingCount - prevTotalMeetingCount) / prevTotalMeetingCount) * 100).toLocaleString() + "%";
            const yoySeatPct = year === currentYear ? "" : _.round(((totalSeatCount - prevTotalSeatCount) / prevTotalSeatCount) * 100).toLocaleString() + "%";
            return {
                Category: "YoY %",
                Year: year,
                MeetingPercentage: yoyMeetingPct,
                SeatPercentage: yoySeatPct
            }
        });
    }
}

interface YOYRow {
    Category: string;
    Year: number;
    MeetingPercentage: string;
    SeatPercentage: string;
}
