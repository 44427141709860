import {Component, EventEmitter, Input, OnChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {map, tap} from "rxjs/operators";
import * as _ from "lodash";
import {AccountService} from "../../Shared/Services/account.service";
import {currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {ColDef} from "ag-grid-community";
import * as moment from "moment";

@Component({
    selector: "app-relative-pay",
    templateUrl: "./relative-pay.component.html"
})
export class RelativePayComponent extends BaseGridComponent<any> implements OnChanges {
    
    @Input()
    accountId: number;
    
    @Input()
    year: number;

    @Input()
    dataLoaded = new EventEmitter<boolean>();

    asOfDateTooltip: string;
    
    columnDefs: ColDef[] = [
        { field: 'Category', rowGroup: true, hide: true },
        { field: 'Pay', headerName: 'Pay', flex: 1, aggFunc: 'first', sortable: false, cellRenderer: (params) => currencyRenderer(params, 2), cellStyle: {fontWeight: 'bold', textAlign: 'center'} },
        { field: 'Year', headerName: 'Year', flex: 1, pivot: true, hide: true },
    ];

    autoGroupColumnDef: ColDef = {
        headerName: 'Category',
        sortable: false,
        cellRendererParams: {suppressCount: true},
        cellStyle: {fontWeight: 'bold'},
        width: 130,
        cellRenderer: params => `${params.value} <span style="font-size:12px;color:#888" class="fa fa-info-circle"></span>`,
        tooltipValueGetter: params => this.asOfDateTooltip,
    };

    constructor(private accountService: AccountService) { 
        super();
        this.gridOptions.rowStyle = { background: '#d9edf7' };
        this.gridOptions.pagination = false;
    }
    
    ngOnChanges(): void {
        let yearRange = _.range(+this.year - 3, +this.year + 1);
        this.accountService.getAccountRelativePay(yearRange, this.accountId, true ).pipe(
            tap(pay => {
                const currentYear = pay?.find(a => a.Year === this.year);
                if (currentYear?.AsOfDate) {
                    this.asOfDateTooltip = `As of ${moment(currentYear.AsOfDate).format("MM/DD/yyyy")}`;
                }
            }),
        ).subscribe(pay => {
            this.dataLoaded.emit(true);

            this.rowData = _.chain(pay ?? [])
                .filter(p => p.DateType == 1 && yearRange.includes(p.Year))
                .map(p => {
                    return {
                        Category: 'Prnt Rel Pay',
                        Pay: p.Value,
                        Year: p.Year
                    }
                })
                .value()
                .concat(_.map(yearRange, year => {
                    return {
                        Category: 'Prnt Rel Pay',
                        Pay: 0,
                        Year: year
                    }
                }));
        })
    }
}
