<div class="global-search" tabindex="0">
    <div class="search" [formGroup]="searchForm">
        <div class="input-group input-group-sm">
            <div class="input-group-addon" style="padding: 0;">
                <select class="form-control input-sm" style="width: 95px; border-width: 0; height: 28px;" formControlName="type">
                    <option *ngFor="let searchType of searchTypes | async" [value]="searchType">{{ searchType }}</option>
                </select>
            </div>
            <input type="text" 
                   class="form-control input-sm" 
                   formControlName="query"
                   placeholder="Search Instisel" />
        </div>
    </div>
    <div class="flex-hbox results" [ngClass]="resultsState === 'none' ? '' : 'has-results'">
        <div class="flex results-list" style="padding: 5px;">
            <app-loading *ngIf="resultsState === 'loading'"></app-loading>
            <div [hidden]="resultsState !== 'results'" style="overflow-y: auto; max-height: 390px;">
                <div class="result-item"
                     #result
                     *ngFor="let searchResult of searchResults$ | async; let i = index;" 
                     (click)="onClickResult(searchResult)" 
                     (mouseenter)="showDetails(searchResult, i)"
                     [ngClass]="{'selected': i == selectedIndex}">
                    <span *ngIf="searchResult.type === 'account'" class="fa fa-sitemap" style="color: #2E8B57;"></span>
                    <span *ngIf="searchResult.type === 'contact'" class="fa fa-users" style="color: #0390FF;"></span>
                    <span *ngIf="searchResult.type === 'ticker'" class="fa fa-dot-circle-o" style="color: maroon;"></span>
                    <span style="margin-left: 2px;">{{searchResult.text}}</span>
                    <span style="color: #999; margin-left: 20px; font-size: 12px;">{{searchResult.text2}}</span>
                </div>
            </div>
        </div>
        <div class="results-detail" style="border-left: 1px solid #cccccc; padding: 5px;">
            <app-loading *ngIf="detailState === 'loading'"></app-loading>
            <div *ngIf="detailState === 'account'">
                <app-do-not-contact-banner [reason]="detailAccount?.ResearchStatus?.Reason"></app-do-not-contact-banner>
                <strong>{{ detailAccount?.Name }}</strong>
                <div *ngIf="detailAccount.Name2 !== ''">{{'(' + detailAccount?.Name2 + ')'}}</div>
                <div>{{ detailAccount.City }}, {{ detailAccount.Country }}</div>
                <div>Tier: {{ detailAccount.Tier }}</div>
                <br/>
                <div>PrBk: {{ detailAccount.PrimaryBroker?.LastName }}</div>
                <div>ScBk: {{ detailAccount.SecondaryBroker?.LastName }}</div>
                <div>PrTr: {{ detailAccount.PrimaryTrader?.LastName }}</div>
                <div>ScTr: {{ detailAccount.SecondaryTrader?.LastName }}</div>
                <br/>
                <div>Region: {{ detailAccount.Market?.Region }}</div>
                <div>Market: {{ detailAccount.Market?.Market }}</div>
                <div>Instnum: {{ detailAccount.Id }}</div>
                <div>Parent Comdol Id: {{ detailAccount.ParentComdolId }}</div>
                <div>Comdol Id: {{ detailAccount.ComdolId }}</div>
            </div>
            <div *ngIf="detailState === 'contact'">
                <app-do-not-contact-banner [reason]="detailContact.Account?.ResearchStatus?.Reason || detailContact.ResearchStatus?.Reason"></app-do-not-contact-banner>
                <strong>{{ detailContact.LastName }}, {{ detailContact.FirstName }}</strong>
                <div>{{ detailContact.Account.Name }}</div>
                <div>{{ detailContact.Account.City }}, {{ detailContact.Account.Country }}</div>
                <div>Tier: {{ detailContact.Tier }}</div>
                <br/>
                <div>PersBk: {{ detailContact.Broker?.LastName }}</div>
                <div>Phone:</div>
                <div><app-click-to-dial [phone]="detailContact.PhoneNumber" [extension]="detailContact.PhoneNumberExtension"></app-click-to-dial></div>
                <div>Email:</div>
                <div><a href="mailto:{{ detailContact.Email }}">{{ detailContact.Email }}</a></div>
                <br/>
                <div>Persnum: {{ detailContact.Id }}</div>
                <br/>
                <div class="flex-hbox flex-gap">
                    <button class="flex btn btn-xs btn-primary" (click)="addActivity(detailContact.Id, detailContact.AccountId)">Add Activity</button>
                    <button class="flex btn btn-xs btn-primary" (click)="addHolding(detailContact.Id)">Add Interest</button>
                </div>
            </div>
            <div *ngIf="detailState === 'ticker'">
                <strong>Ticker: {{detailTicker.Name}}</strong>
                <div>{{detailTicker.CompanyName}}</div>
                <div>Analyst: {{detailTicker.Analyst?.LastName || ''}}</div>
                <div>MM: {{detailTicker.MarketMaker?.LastName}}</div>
                <div>Rating: {{detailTicker.Rating || ''}}</div>
                <div>Sector: {{detailTicker.Sector || ''}}</div>
                <div>Industry: {{detailTicker.Industry || ''}}</div>
            </div>
        </div>
    </div>
</div>
