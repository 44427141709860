import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, publishReplay, refCount, retry, take, tap} from "rxjs/operators";
import {ConfigService} from "./config.service";
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root',
})
export class BaseHttpService {

    observableCache: any = {};
    public readonly defaultRetryCount: number = 3;
    
    constructor(private http: HttpClient, private configService: ConfigService, private injector: Injector) { }

    private get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

    getCacheOrData<T>(path: string, window: number, forceRefresh: boolean = false): Observable<T> {
        if (!this.observableCache[path] || forceRefresh) {
            this.observableCache[path] = this.getData(path).pipe(
                publishReplay(1, window),
                refCount(),
                take(1)
            );
        }
        return this.observableCache[path];
    }
    
    getData<T>(path: string, retryCount: number = this.defaultRetryCount, showErrorToast: boolean = true): Observable<T> {
        return this.getDataWithConfig<T>(path, {}, retryCount, showErrorToast);
    }
    
    getDataWithConfig<T>(path: string, config: HttpOptions, retryCount: number = this.defaultRetryCount, showErrorToast: boolean = true): Observable<T> {
        return this.http.get<T>(this.getUrlFromPath(path), config).pipe(
            retry(retryCount),
            catchError(err => this.handleError(err, showErrorToast))
        );
    }

    getFileWithApiFileName(path: string, fileAcceptType: FileAcceptType, params: any = {}): Observable<any> {
        return this.getFileFromGet(path, null,fileAcceptType, params);
    }
    
    getFileFromGet(path: string, fileName: string, fileAcceptType: FileAcceptType, params: any = {}): Observable<any> {
        const options = {
            params,
            headers: { "Accept": fileAcceptType, "Content-Type": "application/json" },
            responseType: "blob",
            observe: 'response',
        } as any;

        return this.http.get(this.getUrlFromPath(path), options).pipe(
            tap(response => {
                let fileNameToUse = fileName ?? getFilenameFromHeaders(response.headers);
                this.downloadBlob(response.body, fileNameToUse);
            }),
        );
    }
    
    getFileFromPost(path: string, fileName: string, fileAcceptType: FileAcceptType, data: any): Observable<any> {

        let headers = {
            "Accept": fileAcceptType,
            "Content-Type": "application/json"
        };

        return this.http.post(this.getUrlFromPath(path), data, { responseType: "blob", headers: headers }).pipe(
            tap(responseBlob => this.downloadBlob(responseBlob, fileName))
        );
    }

    getFileInNewTab(path: string, fileAcceptType: FileAcceptType): Observable<any> {
        const options = {
            headers: { "Accept": fileAcceptType, "Content-Type": "application/json" },
            responseType: "blob",
            observe: 'response'
        } as any;

        return this.http.get(this.getUrlFromPath(path), options).pipe(
            tap(response => this.openFileInNewTab(response.body))
        );
    }
    
    postData<T>(path: string, data: any): Observable<T> {
        return this.postDataWithConfig(path, data, {});
    }

    postDataWithConfig<T>(path: string, data: any, config: HttpOptions): Observable<T> {
        return this.http.post<T>(this.getUrlFromPath(path), data, config).pipe(
            catchError(err => this.handleError(err))
        );
    }

    putData<T>(path: string, data: any): Observable<T> {
        return this.putDataWithConfig(path, data, {});
    }

    putDataWithConfig<T>(path: string, data: any, config: HttpOptions): Observable<T> {
        return this.http.put<T>(this.getUrlFromPath(path), data, config).pipe(
            catchError(err => this.handleError(err))
        );
    }
    
    deleteData<T>(path: string): Observable<T> {
        return this.deleteDataWithConfig<T>(path, {});
    }

    deleteDataWithConfig<T>(path: string, config: HttpOptions): Observable<T> {
        return this.http.delete<T>(this.getUrlFromPath(path), config).pipe(
            catchError(err => this.handleError(err))
        );
    }
    
    getUrlFromPath(path: string): string {
        return `${this.configService.getActiveApiUrl()}${path}`;
    }
    
    geMSGraphUrlFromPath(path: string): string {
        return `${this.configService.getAzureAdConfig().msGraphBaseUrl}${path}`
    }

    getMSGraph<T>(path: string): Observable<T> {
        return this.http.get<T>(this.geMSGraphUrlFromPath(path));
    }

    postMSGraph<T, R>(path: string, data: T): Observable<R> {
        return this.http.post<R>(this.geMSGraphUrlFromPath(path), data);
    }

    patchMSGraph<T, R>(path: string, data: T): Observable<R> {
        return this.http.patch<R>(this.geMSGraphUrlFromPath(path), data);
    }

    private openFileInNewTab(blob: any): void {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.target = "_blank";
        a.dispatchEvent(new MouseEvent('click'));
    }

    private downloadBlob(blob: any, fileName: string): void {
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;
        a.dispatchEvent(new MouseEvent('click'));
        window.URL.revokeObjectURL(a.href);
    }

    private handleError(error: HttpErrorResponse, showErrorToast: boolean = true) {
        let errorMessage = 'Something bad happened; please try again later.';
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
            errorMessage = error.error.message || errorMessage;
        } else {
            if(showErrorToast) {
                const toastrErrorConfig = {
                    closeButton: true,
                    timeOut: 0,
                    extendedTimeOut: 0
                };

                if (error.status === 403) {
                    this.toastr.error(
                        "Content has been forbidden.", "Error", toastrErrorConfig);
                } else if (error.status === 0) {
                    this.toastr.error("An unknown error has occurred.", "Error", toastrErrorConfig);
                }
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
            errorMessage = error.error || errorMessage;
        }
        // return an observable with a user-facing error message
        return throwError(errorMessage);
    };
}

export class HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    params?: HttpParams | {
        [param: string]: string | string[];
    };
}

export enum FileAcceptType {
    Excel = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Csv = "text/csv",
    Text = "text/plain",
    Email = "message/rfc822",
    Pdf = "application/pdf",
    Any = "*/*"
}

export function getFilenameFromHeaders(headers: HttpHeaders): string | null {
    const contentDisposition = headers.get('Content-Disposition');
    if (!contentDisposition) {
        return null;
    }
    
    const filename = contentDisposition.split("filename=")[1].split(";")[0] ?? null;

    return filename.split('"').join('');
}