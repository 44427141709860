import {Injectable} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {ReadershipModalComponent} from "./readership-modal.component";

@Injectable({
    providedIn: 'root',
})
export class ReadershipModalService {

    constructor(private modalService: BsModalService) {
    }

    openReadershipModal( id: number, source: string ): void {
        const initialState = {
            campaignId: id,
            source: source
        };
        this.modalService.show(ReadershipModalComponent, { initialState: initialState, animated: false });
    }
}
