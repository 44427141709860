import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {
    UntypedFormBuilder,
    Validators
} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ContactService} from "../../Shared/Services/contact.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";

@Component({
    selector: "app-contact-image-form",
    templateUrl: "./contact-image-form.component.html"
})
export class ContactImageFormComponent implements OnInit {

    @Input()
    contactId: number;

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    contactImageSrc: string = null;

    form = this.fb.group({
        file: this.fb.control(null, [Validators.required])
    });

    constructor(private fb: UntypedFormBuilder,
                private modalRef: BsModalRef,
                private modalService: BsModalService,
                private toastr: ToastrService,
                private contactService: ContactService,) { }

    ngOnInit(): void {

        this.contactService.getContactImage(this.contactId)
            .subscribe(image => {
                if (image) {
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onloadend = () => {
                        this.contactImageSrc = reader.result as string;
                    }
                }
            });
    }

    save(): void {
        if (this.form.valid) {
            let formData = new FormData();
            formData.append('file', this.form.value.file, this.form.value.file.name);

            this.contactService.setContactImage(this.contactId, formData)
                .subscribe(_ => {
                    this.toastr.success("Image Saved");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
        }
    }

    close() {
        this.modalRef.hide();
    }

    delete() {
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: {
                message: "Are you sure you want to delete this contact's picture?"
            },
            animated: false,
            keyboard: false,
            backdrop: 'static',
        });

        confirmModalRef.content.action.subscribe(_ => {
            this.contactService.deleteContactImage(this.contactId)
                .subscribe(_ => {
                    this.toastr.success("Image Deleted");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
        })
    }
}
