import {Component, Input} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {EventService} from "../../../Shared/Services/event.service";
import {map} from "rxjs/operators";

@Component({
    selector: "app-event-category-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(EventCategoryPickerComponent)
})
export class EventCategoryPickerComponent extends BasePickerComponent<any> {

    @Input()
    includeEmptyCategory: boolean = false;
    
    constructor(fb: UntypedFormBuilder, private eventService: EventService){
        super(fb);
        
        this.name = "Category(s)";
        this.placeHolder = "Category";
        this.bindValue = "Id";
        this.bindLabel = "Name";
        this.searchable = false;
    }
    
    ngOnInit() {
        super.ngOnInit();

        this.items$ = this.eventService.getEventTypes().pipe(
            map((eventTypes) => {
                return eventTypes
                    .sort((a, b) => a.Name < b.Name ? -1 : 1);
            }),
            map(eventTypes => {
                return eventTypes
                    .map(et => { return { Id: et.Name, Name: et.Name }})
                    .filter(et => this.includeEmptyCategory ? true : et.Name.trim() !== '')
                    .sort((a, b) => a.Name < b.Name ? -1 : 1);
            })
        );
    }
}
