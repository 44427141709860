import {Component, Input, OnChanges} from "@angular/core";
import {AccountProfile, AccountService} from "../../Shared/Services/account.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {AccountProfileFormComponent} from "./account-profile-form.component";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment";
import * as _ from "lodash";
import {combineLatest} from "rxjs";
import {UserPermissions} from "../../../Services/UserPermissions";
import {User} from "../../Shared/Models/user";

@Component({
    selector: "app-account-profile",
    templateUrl: "./account-profile.component.html"
})
export class AccountProfileComponent implements OnChanges {

    @Input()
    accountId: number;

    accountProfile: AccountProfile = { InvestorTypes: [] } as AccountProfile;
    canEditRateCard: boolean = false;
    canEditNotes: boolean = false;

    private user: User;

    constructor(private accountService: AccountService,
                private userService: UserService,
                private bsModalService: BsModalService) { }

    ngOnChanges(): void {
        if (this.accountId) {

            combineLatest([this.userService.getCurrentUser(), this.accountService.getAccountById(this.accountId), this.accountService.getAccountProfile(this.accountId)])
                .subscribe(([user, account, accountProfile]) => {
                    this.setAccountProfile(accountProfile);
                    this.user = user;
                    this.canEditRateCard = this.accountProfile.CanSeeRateCard &&
                                            this.userService.isUserSalesOrManagement(user) &&
                                           this.userService.isMyAccount(user, account.Tags);
                    this.canEditNotes = this.accountProfile.CanSeeNotes &&
                                        this.userService.isUserSalesOrManagement(user) &&
                                        this.userService.isMyAccount(user, account.Tags);
                });
        }
    }

    setAccountProfile(profile: AccountProfile): void {
        profile.AumDate = profile.AumDate ? moment(profile.AumDate).format("LL") : '';
        profile.InvestorTypes = _.sortBy(profile.InvestorTypes);
        this.accountProfile = profile;
    }

    openEditModal(): void {

        let modalRef = this.bsModalService.show(AccountProfileFormComponent,
            {
                initialState: { accountId: this.accountId },
                backdrop: 'static',
                keyboard: false,
                animated: false,
                class: 'modal-lg'
            });

        modalRef.content.profileEmitter.subscribe(profile => {
            this.setAccountProfile(profile);
        });
    }

    get notesReadOnlyForUser(): boolean {
        return !UserPermissions.canEditAccountAndContactNotes(this.user);
    }

}
