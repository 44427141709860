import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    UntypedFormBuilder, UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors
} from "@angular/forms";
import * as _ from "lodash";
import {Observable, Subject} from "rxjs";
import {TickerService} from "../../Shared/Services/ticker.service";
import {map} from "rxjs/operators";
import {Ticker} from "../../../Models/Ticker";
import {NgSelectComponent} from "@ng-select/ng-select";
import {BsModalService} from "ngx-bootstrap/modal";
import {AddTickersModalComponent} from "./add-tickers-modal.component";

@Component({
    selector: "app-multi-ticker-select",
    templateUrl: "../MultiTickerSelect/multi-ticker-select.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiTickerSelectComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiTickerSelectComponent),
            multi: true
        }
    ]
})
export class MultiTickerSelectComponent implements  OnInit, OnChanges, ControlValueAccessor {

    @Input()
    existingTickers: string[] = [];

    @Input()
    titleTemplate: TemplateRef<any>;

    @Input()
    showHasUncovered: boolean = false;

    @Input()
    showHasExisting: boolean = false;

    @Input()
    maximumTickers: number = 100;

    @Input()
    minimumTickers: number = 0;

    @Input()
    autoFocus: boolean = false;

    tickersControl = this.fb.control([],[_.bind(this.isTickersValid, this)]);
    onChange = (tickers: string[]) => {};
    onTouched = () => {};
    tickers$: Observable<any>;
    userInput$ = new Subject<string>();

    allTickers: Ticker[] = []
    hasExisting: boolean = false;
    hasUncovered: boolean = false;

    @ViewChild(NgSelectComponent, { static: true })
    tickersNgSelect: NgSelectComponent;

    constructor(private fb: UntypedFormBuilder,
                private tickerService: TickerService,
                private modalService: BsModalService) {
    }

    isTickersValid(control: AbstractControl): ValidationErrors | null {
        if (control.value.length < this.minimumTickers) {
            return { required: true };
        }
        return null;
    }

    validate({ value }: UntypedFormControl) {
        if (this.tickersControl.invalid) {
            return {invalidMultiTickers: true};
        }
        return null;
    }

    writeValue(tickers: string[]): void {
        if (!tickers) {
            this.tickersControl.patchValue([]);
        } else {
            this.tickersControl.patchValue(tickers);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.tickersControl.disable() : this.tickersControl.enable();
    }

    ngAfterViewInit() {
        if (this.autoFocus) {
            setTimeout(() => {
                this.tickersNgSelect.focus();
            }, 250);
        }
    }

    ngOnInit() {
        this.tickerService.getAllTickers()
            .subscribe(tickers => {
                this.allTickers = tickers;
                this.updateHasUncovered();

                this.tickers$ = this.userInput$.pipe(
                    map((userInput) => {

                        if (!userInput || userInput.length === 0) return [];

                        userInput = userInput.toUpperCase();

                        return this.allTickers.filter(t =>
                            t.Name.startsWith(userInput) ||
                            t.CompanyName?.toUpperCase().startsWith(userInput)
                            //||
                            //t.Analyst?.toUpperCase().indexOf(userInput) > -1
                        ).sort((a, b) => {
                            return a.Name === userInput ? -1 :
                                   b.Name === userInput ? 1 :

                                    0;
                        })
                    })
                )
            })

        this.tickersControl.valueChanges.subscribe(tickers => {
            this.onChange(tickers);
            this.updateHasExisting();
            this.updateHasUncovered();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.existingTickers) {
            this.updateHasExisting();
        }
        if (changes.minimumTickers) {
            setTimeout(() => { this.tickersControl.updateValueAndValidity(); })
            //this.tickersControl.updateValueAndValidity();
        }
    }

    onPaste($event: ClipboardEvent) {
        $event.stopPropagation();
        let pastedText = $event.clipboardData.getData("text");
        let splitTickers = _.chain(pastedText)
            .words(/[^,\s;|]+/g)
            .map(word => word.trim().toUpperCase())
            .concat(this.tickersControl.value)
            .uniq()
            .value();
        this.tickersControl.patchValue(splitTickers);
        this.tickersNgSelect.blur();
    }

    addTagFn(name: string): Partial<Ticker> {
        return { Name: name.toUpperCase() };
    }

    updateHasExisting(): void {
        this.hasExisting = this.tickersControl.value.filter(tick => this.existingTickers.includes(tick)).length > 0;
    }

    clearExisting() {
        let nonExistingTickers = this.tickersControl.value.filter(tick => !this.existingTickers.includes(tick));
        this.tickersControl.patchValue(nonExistingTickers);
    }

    updateHasUncovered(): void {
        if (this.allTickers.length > 0) {
            let coveredTickers = this.allTickers
                .filter(ticker => ticker.Analyst)
                .map(ticker => ticker.Name);

            this.hasUncovered = this.tickersControl.value.some(ticker => !coveredTickers.includes(ticker));
        }
    }

    clearUncovered() {
        let currentTickers = this.allTickers
            .filter(ticker => this.tickersControl.value.includes(ticker.Name))
            .filter(ticker => ticker.Analyst)
            .map(ticker => ticker.Name);

        this.tickersControl.patchValue(currentTickers);
    }

    onKeyDown($event: KeyboardEvent): boolean {

        if ($event.code === 'Space') {
            // @ts-ignore
            this._handleTab($event);

            return false;
        }

        return true;
    }


    search() {

        let modalRef = this.modalService.show(AddTickersModalComponent, {
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        modalRef.content.action
            .subscribe(tickers => {
                let updatedTickers = _.chain(tickers)
                    .concat(this.tickersControl.value)
                    .uniq()
                    .value();
                this.tickersControl.patchValue(updatedTickers);
            })

    }

    removeTicker(name: string): void {
        let tickers = this.tickersControl.value.filter(t => t !== name);
        this.tickersControl.patchValue(tickers);
    }

    isUncovered(name: any) {
        return this.showHasUncovered &&
               this.allTickers.length > 0 &&
               this.allTickers
                .filter(ticker => name === ticker.Name)
                .filter(ticker => ticker.Analyst)
                .length === 0;
    }

    isExisting(name: any) {
        return this.showHasExisting &&
            this.allTickers.length > 0 &&
            this.allTickers
                .filter(ticker => name === ticker.Name)
                .filter(ticker => this.existingTickers.includes(ticker.Name))
                .length > 0;
    }
}
