import {Component, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {combineLatest} from "rxjs";
import {map, startWith} from "rxjs/operators";
import {
    accountTagComparator,
    getGridDisplayWords,
    isNoContactAccount,
    isNoContactReadOnlyAccount
} from "../../Shared/ag-grid-options";
import {Account} from "../../../Models/Account";
import {AccountService} from "../../Shared/Services/account.service";
import * as _ from "lodash";
import {ColDef} from "ag-grid-community";
import {RoleName} from "../../../Models/Role";
import {UserService} from "../../Shared/Services/user.service";
import {AccountTagRenderer} from "../../Widget/CellRenderers/account-tag-renderer.component";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {Router} from "@angular/router";
import {AccountRoutePaths} from "../account-route-paths";

@Component({
    selector: "app-account-list",
    templateUrl: "./account-list.component.html"
})
export class AccountListComponent extends BaseGridComponent<Account> implements OnInit {

    columnDefs: ColDef[] = [
        {field: 'Tags',
            headerName: 'Votes',
            headerClass: 'hide-header',
            headerTooltip: 'Votes',
            cellRenderer: 'accountTagRenderer',
            comparator: accountTagComparator,
            width: 50},
        {field: 'Name', headerName: 'Account', sort: 'asc', flex: 3 },
        {field: 'City', flex: 1 },
        {field: 'State', flex: 1 },
        {field: 'Country', flex: 1 },
        {headerName: 'PrBk', valueGetter: function(params) {
                return params.data.PrimaryBroker != null ? params.data.PrimaryBroker.LastName : "" ;
            }, flex: 1 },
        {headerName: 'PrTr', valueGetter: function(params) {
                return params.data.PrimaryTrader != null ? params.data.PrimaryTrader.LastName : "" ;
            }, flex: 1 },
        {field: 'Tier', flex: 1 },
        {field: 'Status', headerName: 'Trading Status', headerTooltip: 'Trading Status', flex: 1 },
        {field: 'ComdolId', flex: 1 },
        {field: 'ParentComdolId', headerName: 'ParentId', flex: 1 },
        {field: 'Id', headerName: 'InstNum', flex: 1 },
    ];

    frameworkComponents = {
        accountTagRenderer: AccountTagRenderer
    };

    rowClassRules = {
        'do-not-contact': (params) => isNoContactAccount(params.data),
        'do-not-contact-readonly': (params) => isNoContactReadOnlyAccount(params.data),
    };

    accountSearchForm: UntypedFormGroup = this.fb.group({
        myAccounts: this.fb.control(false),
        searchTerm: this.fb.control('')
    });

    constructor(
        private fb: UntypedFormBuilder,
        private accountService: AccountService,
        private userService: UserService,
        private router: Router,
    ) {
        super();
    }

    ngOnInit(): void {

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.accountSearchForm.controls.myAccounts.setValue(this.determineMyAccountsDefault(user.Role.Name));
                this.pageCreator();
            });
    }

    pageCreator(): void {
        const allAccounts$ = this.accountService.getAllAccounts();
        const formUpdate$ = this.accountSearchForm.valueChanges.pipe(startWith(this.accountSearchForm.getRawValue()));

        this.rowData$ = combineLatest([allAccounts$, formUpdate$]).pipe(
            map(([accounts, searchForm]) => accounts.filter(a => this.showAccount(a, searchForm.searchTerm, searchForm.myAccounts)))
        );
    }

    showAccount(account: Account, searchTerm: string, myAccounts: boolean): boolean {

        if (myAccounts && account.Tags.indexOf("My") === -1) return false;

        return this.fanciestAccountFilter(account, searchTerm);
    }

    private fanciestAccountFilter(account: Account, searchTerm: string): boolean {
        if (_.isNil(searchTerm) || _.isEmpty(searchTerm)) {
            return true;
        }
        const search = searchTerm.toLocaleLowerCase();
        if(_.startsWith(account.Name.toLocaleLowerCase(), search)) {
            return true;
        }

        const words = _.words(search, /[^,.\s;]+/g);
        const query = (a: Account): boolean => {

            const fieldsContains = _.map([account.Name, account.City], f => (f || "").toLowerCase().trim());
            const fieldsEquals = _.map([account.ComdolId, account.ParentComdolId], f => (f || "").toLowerCase().trim());
            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                        return _.includes(prop, w);
                    }) ||
                    _.some(fieldsEquals, prop => {
                        return prop == w;
                    }));
            });
        };

        return query(account);
    }

    onSelectionChanged() {
        let selectedRows = this.gridApi.getSelectedRows();
        this.router.navigate([AccountRoutePaths.AccountDetail, selectedRows[0].Id]);
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    determineMyAccountsDefault(userRole: string):boolean {
        return (userRole === RoleName.Sales) || (userRole === RoleName.Traders);
    }
}
