import {ConfigService} from "./config.service";
import {Injectable} from "@angular/core";
import {LogLevel} from "msal";
import {BrowserCacheLocation, Configuration} from "@azure/msal-browser";
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1
    || window.navigator.userAgent.indexOf("Trident/") > -1;


@Injectable({
    providedIn: 'root',
})
export class AuthConfigService {

    azureAdConfig = this.configService.getAzureAdConfig();
    constructor(private configService: ConfigService) {
    }
    getMsalConfig(): Configuration {
        return {
            auth: {
                clientId: this.azureAdConfig.clientId,
                authority: this.azureAdConfig.authority,
                redirectUri: this.azureAdConfig.redirectUri,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
                storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Error,
                    piiLoggingEnabled: false
                }
            }
        };
    }

    getProtectedResources(): Map<string, Array<string>> {
        const protectedResourceMap = new Map<string, Array<string>>();
        const contactScopes: string[] = ["Contacts.ReadWrite"];
        const mailScopes: string[] = ["Mail.Read"];
        const apiScopes = [`api://${this.azureAdConfig.instiselApiClientId}/access_as_user`];
        protectedResourceMap.set(this.getMSGraphUrlFromPath("/me/contactFolders"), contactScopes);
        protectedResourceMap.set(this.getMSGraphUrlFromPath("/me/mailFolders"), mailScopes);
        protectedResourceMap.set(this.getMSGraphUrlFromPath("/me/messages"), mailScopes);
        protectedResourceMap.set(this.configService.getApiUrl(), apiScopes);
        protectedResourceMap.set(this.configService.getExternalApiUrl(), apiScopes);

        return protectedResourceMap;
    }

    getMSGraphUrlFromPath(path: string): string {
        return `${this.azureAdConfig.msGraphBaseUrl}${path}`
    }
}