import {Component, EventEmitter, Input, Output} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {EmailService} from "../../Shared/Services/email.service";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";
import {CallListService} from "../../Shared/Services/calllist.service";
import * as _ from "lodash";
import {ContactListsService} from "../../Contact/ContactLists/contact-lists.service";
import {forkJoin, Observable} from "rxjs";
import {switchMap, tap} from "rxjs/operators";
import {QueryDto} from "../../../Services/QueryService";
import {QueryService} from "../../Shared/Services/query.service";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact} from "../../../Models/Contact";
import {isContactable} from "../../Shared/research-status";

@Component({
    selector: "app-add-contacts-modal",
    templateUrl: "./add-contacts-modal.component.html"
})
export class AddContactsModalComponent {

    @Input()
    includeDoNotContactContacts: boolean = true;

    @Output()
    action = new EventEmitter<number[]>();

    addType: UntypedFormControl = this.fb.control('call');
    loading: boolean = true;

    //contact list
    selectedContactLists: UntypedFormControl = this.fb.control([]);

    //query list
    queryList$: Observable<QueryDto[]> = this.queryService.getAllEmailableQueries();
    selectedQuery: UntypedFormControl = this.fb.control(null);

    constructor(public bsConfirmModalRef: BsModalRef,
                private emailService: EmailService,
                private fb: UntypedFormBuilder,
                private callListService: CallListService,
                private contactListService: ContactListsService,
                private queryService: QueryService,
                private contactService: ContactService) {
    }

    save(): void {
        let saveAction$: Observable<any>;
        switch (this.addType.value) {
            case 'call': {
                saveAction$ = this.callListService.getMyCallList().pipe(
                    tap(calllist => {
                        const contacts = _.chain(calllist).map<Contact>(call => call.Contact);
                        this.emitContactIds(contacts);
                    })
                );
                break;
            }
            case 'contact': {
                saveAction$ = forkJoin(
                    this.selectedContactLists.value.map(id => this.contactListService.getListContacts(id))
                ).pipe(
                    tap((contactLists: Contact[][]) => {
                        console.log(contactLists)
                        const contacts = _.chain(contactLists)
                            .flatMap();
                        this.emitContactIds(contacts);
                    })
                );
                break;
            }
            case 'query': {
                saveAction$ = this.queryService.getQueryById(this.selectedQuery.value).pipe(
                    switchMap(query => this.contactService.getContactsByQuery(query.Query.QueryGroups)),
                    tap(queryContacts => {
                        const contacts = _.chain<Contact>(queryContacts);
                        this.emitContactIds(contacts);
                    })
                );
                break;
            }
        }

        saveAction$.subscribe(() => {
            this.bsConfirmModalRef.hide();
        });
    }

    cancel(): void {
        this.bsConfirmModalRef.hide();
    }

    private emitContactIds(contacts: _.CollectionChain<Contact>): void {
        const contactIds = contacts
            .filter(contact => !_.isEmpty(contact.Email))
            .filter(contact => this.includeDoNotContactContacts || isContactable(contact))
            .uniqBy(contact => contact.Email?.toLowerCase() ?? '')
            .map(contact => contact.Id)
            .uniq()
            .value();
        this.action.emit(contactIds);
    }
}
