import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular-legacy";
import {ActivityService} from "../../Shared/Services/activity.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Component({
    selector: 'app-contact-list',
    template: `<app-contact-popover *ngIf="contactIds$ | async as contactIds" [contactIds]="contactIds" [value]="params.value" [showEmail]="false" title="Attendee Overview" placement="left"></app-contact-popover>`
})
export class ContactListRenderer implements ICellRendererAngularComp {
    params: any;
    contactIds$: Observable<number[]>;
    
    constructor(private activityService: ActivityService) {}
    
    agInit(params: any): void {
        this.params = params;
        this.contactIds$ = this.activityService.getActivityAttendees(params.data.accountId, params.data.analyst, params.data.categoryCode, params.data.date, params.data.eventComment, params.data.type, params.data.ticker)
            .pipe(
                map(attendees => attendees.map(a => a.Id))
            );
    }

    refresh(): boolean {
        return true;
    }
}