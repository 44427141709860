<div class="flex flex-vbox form-underline">
    
    <div class="flex" style="padding: 10px; background-color: #fff; border: 1px solid #ccc; overflow-y: auto;">
        <div class="text-right" style="margin-bottom: 10px">
            <button type="button" class="btn btn-primary btn-xs" style="margin-left: 5px" (click)="openEditModal()">Edit</button>
        </div>

        <div class="flex-hbox">
            <div class="form-group" style="width: 60px">
                <label>Mr/Ms</label>
                <div>{{ contactDetails.Salutation }}</div>
            </div>
            <div class="flex-2 form-group">
                <label>First Name/MI</label>
                <div>{{ contactDetails.FirstName }}</div>
            </div>
            <div class="flex-2 form-group">
                <label>Corresp Name</label>
                <div>{{ contactDetails.Alias }}</div>
            </div>
            <div class="flex-2 form-group">
                <label>Last Name</label>
                <div>{{ contactDetails.LastName }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex form-group">
                <label>Assistant(s)</label>
                <div *ngFor="let assistant of getContactAssistants()">{{ assistant }}</div>
            </div>
            <div class="flex form-group">
                <label>Personal Broker</label>
                <div>{{ contactDetails.Broker && contactDetails.Broker.LastName }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex form-group">
                <label>Phone</label>
                <div>{{ contactDetails.PhoneNumber }}</div>
            </div>
            <div class="flex form-group">
                <label>Email</label>
                <div>{{ contactDetails.Email }}</div>
            </div>
        </div>
        <div class="flex-hbox">
            <div class="flex">
                <div class="form-group">
                    <label>Mobile Phone</label>
                    <div>{{ contactDetails.MobilePhoneNumber }}</div>
                </div>
                <div class="flex-hbox" style="margin-top: 15px">
                    <div class="flex form-group">
                        <label>Tier</label>
                        <div>{{ contactDetails.Tier }}</div>
                    </div>
                    <div class="flex form-group">
                        <label>Buy Back Client</label>
                        <div>{{ contactDetails.BuyBackClient ? 'Y' : 'N' }}</div>
                    </div>
                    <div class="flex form-group">
                        <label>Persnum</label>
                        <div>{{ contactDetails.Id }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-vbox">
                <div class="flex form-group">
                    <label>Alternate Email(s)</label>
                    <div *ngFor="let email of contactDetails.EmailAddresses">
                        {{ email }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




