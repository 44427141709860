<div class="window-body flex flex-vbox form-underline">
    <div class="flex" style="padding: 5px; background-color: #fff; border: 1px solid #ccc; overflow-y: auto;">

        <div class="text-right">
            <button type="button" class="btn btn-primary btn-xs" (click)="openEditModal()">Edit</button>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Investor Type</label>
                    <div>{{ accountProfile.InvestorTypes.join(', ') }}</div>    
                </div>
            </div>
            
            <div class="col-xs-6">
                <div class="form-group">
                    <label>AUM (Bn)</label>
                    <div>{{ accountProfile.AumTotal | currency:'USD':'symbol':'1.0-4' }}</div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Investor Strategy</label>
                    <div>{{ accountProfile.InvestorStrategy }}</div>    
                </div>
            </div>
            <div class="col-xs-6">
                <div class="form-group">
                    <label>Equity AUM (Bn)</label>
                    <div>{{ accountProfile.AumEquityTotal | currency:'USD':'symbol':'1.0-4' }}</div>    
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-6"></div>
            <div class="col-xs-6">
                <div class="form-group">
                    <label>AUM Date</label>
                    <div>{{ accountProfile.AumDate ? accountProfile.AumDate : '' }}</div>    
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-xs-12">
                <div class="form-group">
                    <label>Account Description</label>
                    <div>{{ accountProfile.Description }}</div>
                </div>
            </div>
        </div>
        
        <div class="row" *ngIf="canEditRateCard">
            <div class="col-xs-12">
                <div class="form-group">
                    <label>Rate Card</label>
                    <div>{{ accountProfile.RateCard }}</div>
                </div>    
            </div>
        </div>

        <div class="row" *ngIf="canEditNotes">
            <div class="col-xs-12">
                <div class="form-group">
                    <label>General Notes</label>
                    <div>{{ accountProfile.Notes }}</div>
                </div>
            </div>
        </div>
    </div>
</div>