<ng-template #userTemplate>
    <div class="popover-title popover-header">
        <span><span style="font-weight: bold">Team:</span> {{user.Team.Name}}</span>
    </div>
    <div *ngIf="canEditSelf()" style="padding: 5px; text-align: center;">
        <button class="btn btn-sm btn-default" (click)="openModalForm()">Edit User {{ user.Id }}</button>
    </div>
    <div *ngIf="enableJoinTeamFeature()" style="padding: 5px">
        <app-team-picker 
                [formControl]="team"
                [showResearchOnly]="true"
                [multiple]="false"
                placeHolder="Join Team"
        >
        </app-team-picker>
    </div>
    <div *ngIf="canEditSelf()" style="padding:5px;text-align:center">
        <div><button class="btn btn-sm btn-default" (click)="logout()">Logout</button></div>
    </div>
</ng-template>
<span style="padding-left: 10px; padding-right: 10px; cursor: pointer"
      [popover]= "userTemplate" 
      placement="top"
      outsideClick="true">
    {{user.FirstName}} {{user.LastName}}
</span>