import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: 'app-progress-modal',
    templateUrl: "./progress-modal.component.html",
    styleUrls: ["./progress-modal.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ProgressModalComponent {
    @Input()
    max: number;

    @Input()
    value: number;

    @Input()
    message: string;

    @Input()
    warning: string;

    @Output()
    cancel: EventEmitter<void> = new EventEmitter<void>();

    constructor(public bsModalRef: BsModalRef) {}

    onCancel(): void {
        this.cancel.emit();
        this.bsModalRef.hide();
    }
}
