
    <div class="modal-header">
        <h5 class="modal-title">MiFID Account</h5>
    </div>
    <form [formGroup]="mifidAccountForm" autocomplete="off">
        <div class="modal-body">
            <div class="form-group">
                <label for="accountName">Account</label>
                <div class="alert alert-danger" *ngIf="noResult">No Results Found</div>
                <input id="accountName"
                       formControlName="accountName" 
                       [(ngModel)]="selected"
                       (typeaheadOnSelect)="onSelect($event)"
                       [typeahead]="fidessaAccounts"
                       typeaheadOptionField="AccountName"
                       [typeaheadOptionsLimit]="7"
                       [typeaheadMinLength]="1"
                       (typeaheadNoResults)="typeaheadNoResults($event)"
                       class="form-control"
                       *ngIf="formStatus === 'new-item'">
                <input class="form-control"
                       formControlName="accountName"
                       type="text"
                       *ngIf="formStatus != 'new-item'"
                        disabled/>
            </div>
            <div class="form-group">
                <label>Client Ids</label>
                <checkbox-list [options]="clientIdsList" [selectedValues]="mifidAccount.Monikers" (toggle)="onToggle($event)"></checkbox-list>
            </div>
            <div class="form-group">
                <label>Email Recipients</label>
                <textbox-list [values]="mifidAccount.EmailRecipients"
                              [allowAdd]="true"
                              (changes)="onChanges($event)"></textbox-list>
            </div>
        </div>
        <div class="modal-footer">
            <div class="form-group">
                <div>
                    <button class="col-sm-12 btn btn-primary" type="button" style="margin-bottom: 15px" [disabled]="!mifidAccountForm.valid" (click)="submit()">Save</button>
                </div>
                <div>
                    <button class="col-sm-12 btn btn-danger" type="button" *ngIf="formStatus != 'new-item'" (click)="delete()" style="margin-bottom: 15px">Delete</button>
                </div>
                <div>
                    <button class="col-sm-12 btn btn-default" type="button" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </form>