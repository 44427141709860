import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from "@angular/core";
import { ContactService } from "../../Shared/Services/contact.service";
import { ToastrService } from "ngx-toastr";
import { iif } from "rxjs";

@Component({
    selector: "app-contact-favorite",
    templateUrl: "./contact-favorite.component.html"
})
export class ContactFavoriteComponent implements OnChanges {

    @Input()
    contactId: number;
    isFavorite: boolean;

    @Output()
    change: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private contactService: ContactService,
        private toastrService: ToastrService
    ) { }

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.contactId.currentValue !== simpleChanges.contactId.previousValue) {
            this.contactService.getContactById(this.contactId).subscribe(contact => {
                this.isFavorite = contact.Favorite;
            });
        }
    }

    toggleFavorite(): void {
        iif(
            () => this.isFavorite,
            this.contactService.unstarContact(this.contactId),
            this.contactService.starContact(this.contactId)
        ).subscribe(() => {
            this.toastrService.success("Contact Favorite Updated");

            this.isFavorite = !this.isFavorite;
            this.change.emit(this.isFavorite);
        });
    }
}
