import { Component, Input } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { BasePickerComponent, pickerProviders } from "../BasePicker/base-picker.component";
import { UserService } from "../../../Shared/Services/user.service";
import { map } from "rxjs/operators";
import { combineLatest } from "rxjs";

@Component({
    selector: "app-analyst-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(AnalystPickerComponent)
})
export class AnalystPickerComponent extends BasePickerComponent<any> {

    @Input()
    alignRight: boolean = false;

    @Input()
    multiple: boolean = true;

    @Input()
    closeOnSelect: boolean = false;

    @Input()
    showTagCountLabel: boolean = true;

    @Input()
    defaultAnalyst: boolean = false;

    constructor(fb: UntypedFormBuilder, private userService: UserService) {
        super(fb);

        this.name = "Analyst(s)";
        this.placeHolder = "Analyst(s)";
        this.bindValue = "Id";
        this.bindLabel = "LastName";
        this.customSearchable = true;
    }

    ngOnInit() {
        super.ngOnInit();
        this.showSelectClearAll = this.multiple;
        this.items$ = combineLatest([
            this.userService.getAnalysts(),
            this.userService.getCurrentUser()
        ])
            .pipe(
                map(([teams, user]) => teams.sort((a, b) => a.LastName.localeCompare(b.LastName)).map(t => {
                    const name = t.LastName.split(",")[0];
                    const analyst = this.defaultAnalyst ? user.Team.Name.split(",")[0] : "";
                    return { Id: t.Id, LastName: analyst === t.LastName ? `** ${name} **` : name, Default: analyst === t.LastName };
                })
                    .sort((a, b) => {
                        if (a.Default) return -1;
                        return a.LastName.localeCompare(b.LastName)
                    })
                )
            );
    }
}
