import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ContactEntitlementDto, EntitlementQueue, EntitlementService} from "../../Shared/Services/entitlement.service";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: "app-entitlement-form",
    template: `
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title pull-left">{{ title }}</h3>
            </div>

            <div class="modal-body">

                <app-loading loadingText="Loading" *ngIf="isLoading"></app-loading>
                <ng-container *ngIf="!isLoading">
                    <form [formGroup]="entitlementForm">
                        <div class="flex-vbox">
                            <div *ngIf="contactEntitlement.ContactFirstName ||
                                     contactEntitlement.ContactLastName ||
                                     contactEntitlement.AccountName" class="flex-vbox flex form-group">
                                <label>Contact</label>
                                <div>{{ contactEntitlement?.ContactFirstName }} {{ contactEntitlement?.ContactLastName }}</div>
                                <div>{{ contactEntitlement.AccountName }}</div>
                            </div>
                            <div class="flex-hbox flex">
                                <div class="flex-vbox flex form-group">
                                    <label>Entitlement Status</label>
                                    <div>{{ contactEntitlement.EntitlementStatus }}</div>
                                </div>
                                <div class="flex-vbox flex form-group">
                                    <label>Entitlement Type</label>
                                    <div>{{ contactEntitlement.EntitlementType }}</div>
                                </div>
                                <div class="flex-vbox flex form-group">
                                    <label>Business Unit</label>
                                    <div>{{ contactEntitlement.BusinessUnit ?? "Unassigned" }}</div>
                                </div>
                            </div>
                            <div class="flex-hbox flex">
                                <div class="flex-vbox flex form-group">
                                    <label>{{ contactEntitlement.Vendor }} Id</label>
                                    <div>{{ contactEntitlement.VendorId }}</div>
                                </div>
                                <div class="flex-vbox flex form-group">
                                    <label>Remove Entitlement</label>
                                    <div><input type="checkbox" formControlName="pendingDelete"/></div>
                                </div>
                                <div class="flex"></div>
                            </div>
                        </div>
                    </form>
                </ng-container>
            </div>

            <div class="modal-footer">
                <div class="flex-hbox flex-gap">
                    <button type="button" class="flex btn btn-primary" (click)="submit()">Save</button>
                    <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
                </div>
            </div>
        </div>
    `
})
export class EntitlementFormComponent implements OnInit {

    @Input() vendorCode: string = "";
    @Input() vendorId: string;

    @Output() dataUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

    title: string = `Edit ${this.vendorCode} Entitlement`;

    isLoading: boolean = true;

    contactEntitlement: ContactEntitlementDto;

    entitlementForm = this.fb.group({
        pendingDelete: this.fb.control(false),
    });

    constructor(private fb: FormBuilder,
                private modalRef: BsModalRef,
                private entitlementService: EntitlementService,
                private toastrService: ToastrService
                ) {
    }

    ngOnInit(): void {
        this.entitlementService.getContactEntitlementByVendorId(this.vendorCode, this.vendorId)
            .subscribe(contactEntitlement => {
                this.isLoading = false;
                this.contactEntitlement = contactEntitlement;
                this.entitlementForm.patchValue({
                    pendingDelete: !!contactEntitlement.PendingDeleteId
                });
            });
    }

    submit() {
        let pendingDelete = this.entitlementForm.get('pendingDelete').value;

        if (pendingDelete) {
            const queueItem = {
                VendorId: this.vendorId,
                VendorCode: this.vendorCode,
                EntitlementAction: "Delete",
            } as EntitlementQueue;
            this.entitlementService.enqueueEntitlement(queueItem)
                .subscribe(() => {
                    this.dataUpdated.emit(true);
                    this.toastrService.success("Entitlement has been queued for deletion.");
                    this.close();
                });
        } else if (!pendingDelete && this.contactEntitlement.PendingDeleteId) {
            this.entitlementService.deleteEnqueuedEntitlement(this.contactEntitlement.PendingDeleteId)
                .subscribe(() => {
                    this.dataUpdated.emit(true);
                    this.toastrService.success("Entitlement has been undeleted.");
                    this.close();
                });
        } else {
            this.close();
        }
    }

    close() {
        this.modalRef.hide();
    }
}
