<div class="modal-header">
    Create/Update Contact List
</div>
<div class="modal-body">

    <div class="form-group">{{ contactIds.length }} Contacts Selected</div>

    <tabset>
        <tab heading="Update Contact List">
            <div [formGroup]="form">
                <div class="form-group">
                    <input formControlName="search" type="search" class="form-control input-sm" placeholder="Search" />
                </div>

                <div class="form-group" style="height: 300px;">
                    <ag-grid-angular
                            style="width: 100%; height: 100%;"
                            class="ag-theme-balham"
                            [gridOptions]="gridOptions"
                            [rowData]="rowData$ | async"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            (gridReady)="onGridReady($event)"
                            (selectionChanged)="onSelectionChanged($event)"
                    >
                    </ag-grid-angular>
                </div>

                <div class="flex-hbox flex-gap">
                    <button type="button" class="flex btn btn-sm btn-primary" (click)="update()" [disabled]="!selectedIsEditable">Update</button>
                    <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </tab>
        <tab heading="New Contact List">

            <div [formGroup]="contactListForm">
                <div class="flex-hbox">
                    <div class="form-group flex">
                        <label>Name</label>
                        <input class="form-control input-sm" type="text" formControlName="name"/>
                    </div>
                </div>
                <div class="flex-hbox">
                    <div class="form-group flex">
                        <label>Description</label>
                        <input class="form-control input-sm" type="text" formControlName="description"/>
                    </div>
                </div>
                <div class="flex-hbox flex-gap">
                    <div class="form-group flex">
                        <label>Permission</label>
                        <select class="form-control input-sm" formControlName="permission">
                            <option *ngFor='let permission of permissions' [value]="permission.Id">{{ permission.Name }}</option>
                        </select>
                    </div>
                    <div class="form-group flex" *ngIf="contactListForm.get('permission').value !== 'P'">
                        <label>Group</label>
                        <select class="form-control input-sm" formControlName="group">
                            <option *ngFor='let group of groups$ | async' [value]="group.Id">{{ group.Name }}</option>
                        </select>
                    </div>
                </div>

                <div class="flex-hbox flex-gap">
                    <button type="button" class="flex btn btn-sm btn-primary" (click)="save()">Save</button>
                    <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </tab>
    </tabset>

</div>
