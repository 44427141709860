<div class="modal-body">
    <div class="form-group">
        {{ message }}
    </div>
    <div class="form-group">
        <app-multi-contact-select [maxItems]="1" 
                                  placeholder="Select a Contact"
                                  [accountId]="accountId" 
                                  [contactRequired]="true"
                                  [formControl]="contactIdControl">
        </app-multi-contact-select>
    </div>
</div>
<div class="modal-footer">
    <div class="flex-hbox flex-gap">
        <button type="button" class="btn btn-sm btn-primary flex" (click)="confirm()" [disabled]="contactIdControl.invalid">Select</button>
        <button type="button" class="btn btn-sm btn-default flex" (click)="decline()" >Cancel</button>
    </div>
</div>