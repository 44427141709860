import {Component, Input, OnInit, SecurityContext} from "@angular/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import * as moment from "moment";
import * as _ from "lodash";
import {map, startWith} from "rxjs/operators";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {combineLatest, Observable} from "rxjs";
import {SharedEmailService} from "../../../Shared/Services/shared-email.service";
import {EmailCampaign, EmailService} from "../../../Shared/Services/email.service";
import {BaseGridComponent} from "../../../Shared/base-grid.component";
import {CSVColumn, CSVService} from "../../../Shared/Services/csv.service";
import {Recipient} from "../../../../Models/Recipient";
import {getNameForDisplay} from "../../../../Helpers/DisplayStringHelper";
import {SimpleUser} from "../../../../Models/User";

@Component({
    selector: 'app-shared-email-form',
    templateUrl: "./readership-modal.component.html"
})
export class ReadershipModalComponent extends BaseGridComponent<Recipient> implements OnInit {

    @Input()
    campaignId: number;

    @Input()
    source: string;

    campaignDetails: CampaignDetails = {} as CampaignDetails;
    allRecipients$: Observable<Recipient[]>;
    masterDetail: boolean;

    readershipGridOptions = {
        ...this.gridOptions,
        masterDetail: true,
        detailRowAutoHeight: true,
        isRowMaster: dataItem => {
            return dataItem ? dataItem.ClickFlag === 'Y' && dataItem.OpenFlag === 'Y' : false;
        }
    }

    readershipForm: UntypedFormGroup = this.fb.group({
        my: this.fb.control(true),
        open: this.fb.control(false),
        click: this.fb.control(false),
    });

    columnDefs = [
        {
            field: 'LastName',
            headerName: 'Name',
            flex: 2,
            valueFormatter: this.LastNameFormatter,
            cellRenderer: 'agGroupCellRenderer'
        },
        {field: 'AccountName', headerName: 'Account', flex: 2},
        {
            field: 'My',
            headerName: 'My',
            flex: 1,
            valueFormatter: this.MyFormatter,
            sort: 'desc',
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'OpenFlag',
            headerName: 'Open',
            flex: 1,
            cellRenderer: _.bind(this.OpenFormatter, this),
            sortingOrder: ['desc', 'asc']
        },
        {
            field: 'ClickFlag',
            headerName: 'Click',
            flex: 1,
            cellRenderer: _.bind(this.ClickFormatter, this),
            sortingOrder: ['desc', 'asc']
        },
    ];

    detailCellRendererParams = {
        detailGridOptions: {
            ...this.gridOptions,
            columnDefs: [
                {...this.defaultColDef, field: 'Url', headerName: 'Link', cellRenderer: this.UrlFormatter, flex: 6},
                {...this.defaultColDef, field: 'ClickCount', headerName: 'Click', flex: 1},
            ],
            domLayout: "autoHeight",
            suppressHorizontalScroll: true,
        },
        getDetailRowData: params => {
            params.successCallback(params.data.Links);
        },
    }

    constructor(private emailService: EmailService,
                private sanitizer: DomSanitizer,
                private fb: UntypedFormBuilder,
                private csvService: CSVService
    ) {
        super();
    }

    ngOnInit(): void {
        this.campaignDetails.campaignId = this.campaignId;
        this.campaignDetails.source = this.source;
        this.campaignDetails.campaign = {} as any;
        this.masterDetail = true;

        if (this.campaignDetails.source === 'BlueMatrix' || this.campaignDetails.source === 'Post Only') {
            this.loadDocumentDetails();
        } else {
            this.loadCampaignDetails();
        }

        let formValues$ = this.readershipForm.valueChanges.pipe(startWith(this.readershipForm.getRawValue()));

        this.rowData$ = combineLatest([this.allRecipients$, formValues$]).pipe(
            map(([allRecipients, formValue]) => {
                allRecipients = formValue.my ? _.filter(allRecipients, r => r.My) : allRecipients;
                allRecipients = formValue.open ? _.filter(allRecipients, r => r.OpenFlag === "Y") : allRecipients;
                allRecipients = formValue.click ? _.filter(allRecipients, r => r.ClickFlag === "Y") : allRecipients;

                return allRecipients;
            })
        )
    }

    loadCampaignDetails(): void {

        this.readershipForm.patchValue({
            open: true,
        });

        this.emailService.getEmailCampaignDetails(this.campaignDetails.campaignId)
            .subscribe(campaignDetails => {
                this.campaignDetails.campaign = campaignDetails;
                this.campaignDetails.campaign.CreatedAt = moment(this.campaignDetails.campaign.CreatedAt);
                this.campaignDetails.campaign.Html = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(this.campaignDetails.campaign.Html)).toString();
            });

        this.allRecipients$ = this.emailService.getEmailCampaignRecipients(this.campaignDetails.campaignId, false)
            .pipe(map(recipients => {
                _.forEach(recipients, r => r.ClickCount = _.sum(_.map(r.Links, l => l.ClickCount)));
                return _.forEach(recipients, r => {
                    r.OpenFlag = r.ClickCount > 0 || r.OpenCount > 0 ? "Y" : "N"
                    r.ClickFlag = r.ClickCount > 0 ? "Y" : "N"
                });
            }));
    }

    loadDocumentDetails(): void {

        this.emailService.getBMDocumentDetails(this.campaignDetails.campaignId)
            .subscribe(documentDetails => {
                this.campaignDetails.campaign = documentDetails;
                this.campaignDetails.campaign.CreatedAt = moment(this.campaignDetails.campaign.CreatedAt);
                this.campaignDetails.htmlUrl = this.sanitizer.bypassSecurityTrustResourceUrl(documentDetails.HtmlUrl);
            });

        this.allRecipients$ = this.emailService.getBMDocumentRecipients(this.campaignDetails.campaignId)
            .pipe(map(recipients => {
                _.forEach(recipients, r => r.ClickCount = _.sum(_.map(r.Links, l => l.ClickCount)));
                return _.forEach(recipients, r => {
                    r.OpenFlag = r.ClickCount > 0 || r.OpenCount > 0 ? "Y" : "N"
                    r.ClickFlag = r.ClickCount > 0 ? "Y" : "N"
                });
            }));
    }

    displaySenders(campaign: EmailCampaign): string {
        let senders = campaign && _.map(campaign.SentBys, u => u && u.LastName).join(', ') || '';
        if (campaign.SendOnBehalfOfs && campaign.SendOnBehalfOfs.length > 0 && campaign.SendOnBehalfOfs[0]) {
            senders += ` OBO ${campaign.SendOnBehalfOfs[0].LastName}`;
        }
        return senders;
    }

  displaySentDate(campaignDetails: CampaignDetails): string {
    return campaignDetails?.campaign?.CreatedAt &&
    campaignDetails.campaign.CreatedAt instanceof moment
      ? moment(campaignDetails.campaign.CreatedAt).format("LLL")
      : campaignDetails.campaign.CreatedAt as string;
  }

    LastNameFormatter(params) {
        return `${params.data.LastName}, ${params.data.FirstName}`;
    }

    MyFormatter(params) {
        return params.data.My ? 'Y' : 'N';
    }

    OpenFormatter(params) {
        return this.source === 'BlueMatrix' ? '-' : params.data.OpenFlag;
    }

    ClickFormatter(params) {
        return this.source === 'BlueMatrix' ? '-' : params.data.ClickFlag;
    }

    UrlFormatter(params) {
        return `<a href="${params.data.Url}" target="_blank">${params.data.Url}</a>`
    }

    downloadReadership(): void {
        combineLatest([
            this.emailService.getEmailCampaignDetails(this.campaignDetails.campaignId),
            this.allRecipients$
        ]).subscribe(([campaignDetails, recipients]) => {
            const columns: CSVColumn<RecipientCSVData>[] = [
                {headerName: "Instnum", field: "Instnum"},
                {headerName: "Account Name", field: "AccountName"},
                {headerName: "Persnum", field: "ContactId"},
                {headerName: "Client First Name", field: "FirstName"},
                {headerName: "Client Last Name", field: "LastName"},
                {headerName: "Sender", field: "Sender"},
                {headerName: "On Behalf Of", field: "OnBehalfOf"},
                {headerName: "Subject", field: "Subject"},
                {headerName: "Email Address", field: "Email"},
                {headerName: "Region", field: "Region"},
                {headerName: "Market", field: "Market"},
                {headerName: "Tier", field: "Tier"},
                {headerName: "Broker", field: "PersonalBrokerLastName"},
                {headerName: "Opens", field: "OpenFlag"},
                {headerName: "Clicks", field: "ClickFlag"},
                {headerName: "Url", field: "Url"}
            ];

            const onBehalfOf: SimpleUser = campaignDetails.SendOnBehalfOfs.length > 0 ?
                campaignDetails.SendOnBehalfOfs[0] :
                null;

            const data: RecipientCSVData[] =
                _.flatMap(campaignDetails.SentBys, s =>
                _.flatMap(recipients, r =>
                    r.Links.length > 0
                        ? r.Links.map(l => {
                            return {
                                Instnum: r.Instnum,
                                AccountName: r.AccountName,
                                FirstName: r.FirstName,
                                LastName: r.LastName,
                                ContactId: r.ContactId,
                                Email: r.Email,
                                Region: r.Region,
                                Market: r.Market,
                                Tier: r.Tier,
                                PersonalBrokerLastName: r.PersonalBrokerLastName,
                                OpenFlag: r.OpenFlag,
                                ClickFlag: r.ClickFlag,
                                Sender: getNameForDisplay(s?.FirstName, s?.LastName),
                                OnBehalfOf: getNameForDisplay(onBehalfOf?.FirstName, onBehalfOf?.LastName),
                                Subject: campaignDetails.Subject,
                                Url: l.Url
                            }
                        })
                        : {
                            Instnum: r.Instnum,
                            AccountName: r.AccountName,
                            FirstName: r.FirstName,
                            LastName: r.LastName,
                            ContactId: r.ContactId,
                            Email: r.Email,
                            Region: r.Region,
                            Market: r.Market,
                            Tier: r.Tier,
                            PersonalBrokerLastName: r.PersonalBrokerLastName,
                            OpenFlag: r.OpenFlag,
                            ClickFlag: r.ClickFlag,
                            Sender: getNameForDisplay(s?.FirstName, s?.LastName),
                            OnBehalfOf: getNameForDisplay(onBehalfOf?.FirstName, onBehalfOf?.LastName),
                            Subject: campaignDetails.Subject,
                        }
                    )
                );

            const fileName = `ReadershipSummary_${this.campaignDetails.campaignId}_${moment().format("yyyy-MM-DDThh.mm.ss")}.csv`
            this.csvService.exportToCSV(fileName, columns, data);
        });
    }
}

export interface CampaignDetails {
    campaignId: number;
    source: string;
    campaign: EmailCampaignDetail;
    htmlUrl: SafeResourceUrl;
}

export interface EmailCampaignDetail extends EmailCampaign {
    Html: string;
    HtmlUrl: string;
}

interface RecipientCSVData {
    Instnum: number;
    AccountName: string;
    ContactId: number;
    FirstName: string;
    LastName: string;
    Email: string;
    Region: string;
    Market: string;
    Tier: string;
    PersonalBrokerLastName: string;
    Sender: string;
    OnBehalfOf: string;
    Subject: string;
    OpenFlag: string;
    ClickFlag: string;
    Url?: string
}
