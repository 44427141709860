<div class="flex flex-vbox form-underline">
    
    <div class="flex" style="padding: 10px; background-color: #fff; border: 1px solid #ccc; overflow-y: auto;">
        <div class="text-right" style="margin-bottom: 10px">
            <button type="button" class="btn btn-primary btn-xs" style="margin-right: 5px" (click)="openUnsubscribeModal()">Unsubscribe Research</button>
            <button type="button" class="btn btn-primary btn-xs" (click)="openEditModal()">Edit</button>
        </div>

        <div class="flex-hbox" style="gap: 5px">
            
            <div class="flex form-group">
                <label>BM Report Type</label>
                <div>{{preference.ReportType}} Selected</div>
            </div>
            <div class="flex form-group" style="width: 130px">
                <label>
                    BM Secure Links
                    <span class="fa fa-info-circle" tooltip="Controlled at the account level" *ngIf="preference.AccountSecureLinks"></span>
                </label>
                <div>{{preference.SecureLinks}}</div>
            </div>
            <div class="flex form-group" style="width: 130px">
                <label>BM AutoSubscribe</label>
                <div>{{preference.AutoSubscribe}}</div>
            </div>
        </div>
        <div class="flex-hbox" style="gap: 5px">
            <div class="flex form-group">
                <label>No Contact</label>
                <div>{{preference.NoContact}}</div>
            </div>
            <div class="flex form-group">
                <label>Research VMs</label>
                <div>{{preference.BlastVoiceMailAllowed}}</div>
            </div>
            <div class="flex form-group">
            <label>Override Email From Analyst</label>
            <div>{{preference.OverrideEmail}}</div>
        </div>
        </div>
        <div style="margin-bottom: 15px"><h3>Entitlements</h3></div>
        <div class="flex-hbox">
            <div class="form-group" style="flex: 0 1 calc(50% - 15px)" *ngFor="let entitlement of entitlements$ | async">
                <label>{{entitlement.Description}}</label>
                <div *ngFor="let entitlementInfo of entitlement.EntitlementInfo">
                    {{entitlementInfo.VendorId}}
                </div>
                <button *ngIf="entitlement.ModalComponent"
                        type="button"
                        class="btn btn-primary btn-xs pull-right"
                        [appIfUserFeature]="canEditResearchOnlineEntitlementFeature"
                        (click)="openCustomEditModal(entitlement.ModalComponent)">
                    Edit
                </button>
                <div>{{entitlement.VendorId}}</div>
            </div>
        </div>
    </div>
    
</div>