import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Calendar, EventInput, EventSourceInput, ViewApi } from "@fullcalendar/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import * as _ from "lodash";
import * as moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable, of } from "rxjs";
import { debounceTime, map, startWith, switchMap, tap } from "rxjs/operators";
import { CalendarEvent } from "../../../Components/Events/CalendarEvent";
import { CacheService } from "../../Shared/Services/cache.service";
import { EventService } from "../../Shared/Services/event.service";
import { DateRange } from "../../Widget/DateRangePicker/date-range";
import { EventFormComponent } from "../EventForm/event-form.component";
import {getEventDisplayName} from "../../../Helpers/DisplayStringHelper";
import {Router} from "@angular/router";
import {EventRoutePaths} from "../event-route-paths";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";

@Component({
    selector: "app-event-calendar",
    templateUrl: "./event-calendar.component.html"
})
export class EventCalendarComponent implements OnInit {

    @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;

    private oldStart: moment.Moment;
    private oldEnd: moment.Moment;
    private eventAdded: boolean = false;
    private calendarEvents: CalendarEvent[] = [];

    calendarOptions: CalendarOptions;
    calendarPlugins = [dayGridPlugin, interactionPlugin];

    green : color = {red: 91, green:174, blue:61, text: "#555"};
    blue : color = {red: 0, green: 65, blue:230, text: "#fff"};
    purple: color = {red: 83, green: 46, blue: 96, text: "#fff"};
    teal: color = {red: 57, green: 194, blue:215, text: "#000"};
    red : color = {red:223, green: 12, blue:12, text: "#fff"};
    coral : color = {red: 243, green:112, blue:33, text: "#555"};
    yellow: color = {red:243, green:218, blue:70, text: "#000"};
    gray: color = {red:200, green:200, blue:200, text: "#000"};

    eventTypes: {name: string, color: color, displayOrder: number}[] = [
        { name: "Analyst Visit", color: this.green, displayOrder: 5},
        { name: "Company Visit", color: this.purple, displayOrder: 6},
        { name: "Field Trip", color: this.purple, displayOrder: 3},
        { name: "Conference", color: this.teal, displayOrder: 1},
        { name: "Conference Call", color: this.red, displayOrder: 2},
        { name: "Corporate Access Day", color: this.teal, displayOrder: 1},
        { name: "NDR", color: this.blue, displayOrder: 4},
        { name: "Dinner", color: this.coral, displayOrder: 7},
        { name: "Reception", color: this.coral, displayOrder: 7},
        { name: "Golf Outing", color: this.coral, displayOrder: 7},
        { name: "Social", color: this.yellow, displayOrder: 7},
        { name: "", color: this.gray, displayOrder: 99},
    ];

    eventSearchForm: UntypedFormGroup = this.fb.group({
        eventAdded: this.fb.control(false),
        searchNameAndLocation: this.fb.control(''),
        searchTicker: this.fb.control(''),
        category: this.fb.control([]),
        analyst: this.fb.control([]),
        month: this.fb.control(new Date()),
        calendarDateRange: this.fb.control({ start: moment().subtract(30, 'day').clone(), end: moment().add(90, 'day').clone() } as DateRange),
        listDateRange: this.fb.control({
            start: moment().clone(),
            end: moment().add(5, 'years').clone()
        } as DateRange),
    });

    constructor(private fb: UntypedFormBuilder,
                private eventService: EventService,
                private cacheService: CacheService,
                private modalService: BsModalService,
                private router: Router,
    ) {
        // Necessary to prevent compilation errors from the fullcalendar library during client tests,
        // but functionally unnecessary
        const _name = Calendar.name;
    }

    ngOnInit(): void {

        let savedFilters = this.cacheService.getValue<any>("EventListFilters");

        if (savedFilters) {
            savedFilters.calendarDateRange.start = moment(savedFilters.calendarDateRange.start);
            savedFilters.calendarDateRange.end = moment(savedFilters.calendarDateRange.end);
            this.eventSearchForm.patchValue(savedFilters);
        }

        this.calendarOptions = {
            weekends: false,
            fixedWeekCount: false,
            height: '100%',
            handleWindowResize: true,
            eventOrder:'-duration,displayOrder,title',
            initialView: 'dayGridMonth',
            initialDate: this.eventSearchForm.value.month,
            eventClick: this.eventClicked.bind(this),
            datesSet: this.datesSet.bind(this),
            plugins: this.calendarPlugins,
        };

        this.SetupEventSources();
    }

    private SetupEventSources(): void {
        this.eventSearchForm.valueChanges.pipe(
            startWith(this.eventSearchForm.getRawValue()),
            debounceTime(500),
            tap(formValues => this.cacheService.setValue("EventListFilters", formValues)),
            switchMap(formValues => this.mapToCalendarEvents(formValues.calendarDateRange.start, formValues.calendarDateRange.end)),
            map((events: CalendarEvent[]) => _.filter(events, ev => this.showEvent(ev))),
            map((events: CalendarEvent[]) => events.map(ev => {
                ev.Type = this.eventTypes.map(et => et.name).includes(ev.Type) ? ev.Type : '';
                return ev;
            }))
        ).subscribe(calendarEvents => {
            this.calendarOptions.eventSources = this.CreateEventInput(calendarEvents);
            setTimeout(() => this.calendarComponent.getApi().render(), 100);
            this.modalService.hide(this.modalService.config.id);
        });
    }

    private CreateEventInput(calendarEvents: CalendarEvent[]): EventSourceInput[] {
        return _.map(this.eventTypes, et => this.GetEventsByType(et.name, et.color, calendarEvents, et.displayOrder));
    }

    private GetEventsByType(typeName: string, color: color, events: CalendarEvent[], displayOrder: number): EventSourceInput {
        return {
            id: typeName,
            events: events.filter(e => e.Type === typeName).map(e => this.eventForCalendarView(e,displayOrder)),
            backgroundColor: color ? `rgba(${color.red}, ${color.green}, ${color.blue}, 0.6)` : "",
            borderColor: `rgba(${color.red}, ${color.green}, ${color.blue}, 1)`,
            textColor: color.text
        };
    }

    private eventForCalendarView = (event : CalendarEvent, displayOrder: number) => {
        let e: EventInput = {};
        if (event.Type === "NDR" && event.Tickers.length > 0) {
            e.title = _.head(event.Tickers);
        } else {
            e.title = getEventDisplayName(event);
        }

        if (event.Location) {
            e.title = `${e.title} (${event.Location})`;
        }
        e.start = moment(event.BeginDate).toISOString();
        e.end = moment(event.EndDate).add(1, "days").toISOString();
        e.allDay = true;
        e.extendedProps = {requestId: event.RequestId, eventId: event.Id};
        e.displayOrder = displayOrder;
        return e;
    };

    showEvent(ev):boolean {
        return this.eventService.showEvent(ev,
            this.eventSearchForm.value.category,
            this.eventSearchForm.value.searchNameAndLocation,
            this.eventSearchForm.value.searchTicker,
            this.eventSearchForm.value.analyst);
    }

    mapToCalendarEvents(start: moment.Moment, end: moment.Moment): Observable<CalendarEvent[]> {
        if (this.eventAdded || !this.oldStart || !this.oldEnd || !start.isSame(this.oldStart, 'day') || !end.isSame(this.oldEnd, 'day')) {

            const ref = this.modalService.show(LoadingModalComponent, {
                animated: false,
                keyboard: false,
                backdrop: 'static'
            });

            this.calendarEvents = [];
            this.eventAdded = false;
            return this.eventService.searchEvents(start, end).pipe(
                tap(evs => {
                    this.calendarEvents = evs;
                    this.oldStart = start;
                    this.oldEnd = end;
                })
            );
        }

        return of(this.calendarEvents);
    }

    datesSet(arg: { view: ViewApi }): void {
        const startDate = moment(arg.view.activeStart);
        const endDate = moment(arg.view.activeEnd);
        const currentStart = arg.view.currentStart;

        if (!startDate.isSame(this.eventSearchForm.value.calendarDateRange.start, 'day') || !endDate.isSame(this.eventSearchForm.value.calendarDateRange.end, 'day')) {
            this.eventSearchForm.patchValue({
                calendarDateRange: { start: moment(startDate), end: moment(endDate) },
                month: currentStart
            });
        }
    }

    eventClicked(info: any): void {
        if (info.event.extendedProps.eventId && info.event.extendedProps.eventId !== 0) {
            this.router.navigate([EventRoutePaths.EventDetail, info.event.extendedProps.eventId]);
        } else if (info.event.extendedProps.requestId) {
            this.router.navigate([EventRoutePaths.RequestDetail], { queryParams: { requestId: info.event.extendedProps.requestId } });
        }
    }

    clearFilters() {
        this.eventSearchForm.patchValue({
            searchNameAndLocation: '',
            searchTicker: '',
            category: [],
            analyst: [],
            month: new Date(),
        });
    }

    addEvent() {
        this.openEventModal(null);
    }

    openEventModal(eventId: number): void {
        const initialState = {
            eventId: eventId
        };
        let modalRef = this.modalService.show(EventFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-xl' });

        modalRef.content.dataUpdated.subscribe(() => {
            this.eventAdded = true;
            this.eventSearchForm.patchValue({ eventAdded: !this.eventSearchForm.get('eventAdded').value });
        });
    }
}

interface color{
    red: number,
    blue: number,
    green: number,
    text: "#000" | "#fff" | "#555"
}
