import {Component, Input, ViewEncapsulation} from "@angular/core";
import {QueryGroup, QueryPredicate} from "../../../Services/QueryService";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
    selector: "app-query-groups-popover",
    template: `
        <i class="fa fa-info-circle"
           [popover]="queryGroupsPopover"
           [outsideClick]="true"
           container="body"
           placement="auto"
           [ngClass]="{'has-queries': queryGroups.length}"></i>

        <ng-template #queryGroupsPopover>
            <div style="padding: 5px; background-color: #eee;">
                Query Overview
            </div>
            <div class="query-mock">
                <div *ngFor="let group of queryGroups; index as i; last as lastGroup" style="border-bottom: 1px solid #eee;">
                    <div *ngFor="let query of group.Queries; index as j; last as lastQuery">
                        <span>{{ query.Object }}</span>
                        <span>{{ query.Field }}</span>
                        {{ query.Operation }}
                        <span>{{ getValue(query) }}</span>
                        <span *ngIf="!lastQuery" [class.and]="group.Queries.length <= 1" [class.or]="group.Queries.length > 1">{{ group.Queries.length > 1 ? "or" : "and" }}</span>
                        <span *ngIf="lastQuery && !lastGroup" class="and">and</span>
                    </div>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ["./query-groups-popover.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class QueryGroupsPopoverComponent {
    @Input()
    queryGroups: QueryGroup[];

    constructor() { }

    getValue(query: QueryPredicate): string {
        if (query.Value) {
            return `"${this.convertBoolean(query)}"`
        }

        if (!_.isNil(query.Values) && !_.isEmpty(query.Values)) {
            return `(${_.join(_.map(query.Values, it => `"${it}"`), ", ")})`;
        }

        return null;
    }

    private convertBoolean(query: QueryPredicate): string | number | moment.Moment {
        if (_.isEqual(query.Object, "Account")) {
            if (_.isEqual(query.Field, "Grid") || _.isEqual(query.Field, "My Account")) {
                return _.isEqual(query.Value, "true") ? "Y" : _.isEqual(query.Value, "false") ? "N" : query.Value;
            }
        }

        if (_.isEqual(query.Object, "Contact")) {
            if (_.includes(["Favorite", "Marketing Email", "Research Email", "Research Voicemail", "My", "Do Not Contact"], query.Field)){
                return _.isEqual(query.Value, "true") ? "Y" : _.isEqual(query.Value, "false") ? "N" : query.Value;
            }
        }

        return query.Value;
    }
}
