import {Component, OnInit } from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import { EventService, QueueMeetMaxEvent } from "../../Shared/Services/event.service";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { dateTimeRenderer } from "../../Shared/ag-grid-cell-renderers";
import { nullableValueComparator } from "../../Shared/ag-grid-cell-comparators";
import { ColDef } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";

@Component({
    selector: "app-admin-queue-meetmax-events-list",
    templateUrl: "./queue-meetmax-events-list.component.html",
})
export class QueueMeetMaxEventsListComponent extends BaseGridComponent<QueueMeetMaxEvent> implements OnInit {

    queueButtonClicked: boolean = false;
    saving: boolean = false;

    columnDefs: ColDef[] = [
        {
            colId: 'delete',
            width: 35,
            cellRenderer: (params) => `<span class="fa fa-times" style="color:red;"></span>`,
            tooltipValueGetter: (params) => 'De-Queue MeetMax ID',
            cellStyle: {cursor: 'pointer', overflow:'none'}
        },
        {
            field: 'MeetMaxId',
            headerName: 'MeetMax ID',
            width: 200
        },
        {
            field: 'CreatedOn',
            width: 200,
            sort: 'asc',
            cellRenderer: dateTimeRenderer,
            comparator: nullableValueComparator
        },
        {
            field: 'CreatedBy',
            flex: 1
        },
    ];

    meetMaxQueueForm: UntypedFormGroup = this.fb.group({
        meetMaxId: this.fb.control('', [this.meetMaxIdValidator()])
    });

    constructor(
        private fb: UntypedFormBuilder,
        private eventService: EventService,
        private toastrService: ToastrService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.setRowData();
    }

    meetMaxIdValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return (
                this.queueButtonClicked && !this.isMeetMaxIdValid()
                    ? { invalid: true }
                    : null
            );
        };
    }

    isMeetMaxIdValid(): boolean {
        const meetMaxId = this.meetMaxQueueForm.get('meetMaxId').value?.trim();

        return (!!meetMaxId && meetMaxId.length <= 100)
    }

    shouldPreventQueueing(): boolean {
        return (this.queueButtonClicked && !this.isMeetMaxIdValid()) || this.saving;
    }

    setRowData(): void {
        let meetMaxQueuedEvents$ = this.eventService.getMeetMaxQueuedEvents();
        this.rowData$ = meetMaxQueuedEvents$;
    }

    onGridReady(params) {
        super.onGridReady(params);
    }

    onCellClicked(event: any): void {
        if (event.column.colId !== 'delete') return;

        const queueMeetMaxEvent: QueueMeetMaxEvent = event.data;

        let delete$ = this.eventService.deleteMeetMaxQueuedEvents([queueMeetMaxEvent.MeetMaxId]);

        delete$.subscribe(() => {
            this.toastrService.success(`MeetMax Event ID Successfully De-Queued`);
            this.setRowData();
        });
    }

    queueMeetMaxId() {
        const meetMaxId = this.meetMaxQueueForm.get('meetMaxId').value?.trim();

        this.queueButtonClicked = true;

        if (this.isMeetMaxIdValid()) {
            let create$ = this.eventService.createMeetMaxQueuedEvent(meetMaxId);
            this.saving = true;

            create$.subscribe(() => {
                this.setRowData();
                this.saving = false;
                this.meetMaxQueueForm.get('meetMaxId').setValue('');
            })

            this.queueButtonClicked = false;
        } else {
            this.meetMaxQueueForm.get('meetMaxId').updateValueAndValidity();
        }
    }

    meetMaxIdTitle() {
        return (
            this.queueButtonClicked && !this.isMeetMaxIdValid()
                ? "Enter a valid MeetMax ID"
                : "Queue MeetMax ID"
        );
    }
}
