import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "app-page-banner",
    templateUrl: "./page-banner.component.html",
    styleUrls: ['./page-banner.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PageBannerComponent implements OnInit {

    @Input()
    pagetitle: string;

    @Input()
    faicon: string;

    @Input()
    faiconcolor: string;

    @Input()
    bannerFormTemplate: TemplateRef<any>;

    @Input()
    titleTemplate: TemplateRef<any>;

    @Input()
    subTitleTemplate: TemplateRef<any>;

    @Input()
    iconTemplate: TemplateRef<any>;

    @Input()
    bannerClass: string;

    constructor() { }

    ngOnInit(): void {

    }
}
