import {Component, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
    selector: "app-loading-modal",
    templateUrl: "./loading-modal.component.html"
})
export class LoadingModalComponent implements OnInit {

    message: string;

    constructor(public bsConfirmModalRef: BsModalRef) { }

    ngOnInit(): void { }
}
