<div class="modal-header">
    <strong>Account Votes</strong>
</div>
<form [formGroup]="voteForm">
    <div class="modal-body">
        <div class="form-group flex-hbox flex-gap">
            <div class="flex">
                <label>Start Date</label>
                <input class="form-control" type="date" formControlName="StartDate"/>
            </div>
            <div class="flex">
                <label>End Date</label>
                <input class="form-control" type="date" formControlName="EndDate"/>
            </div>
        </div>
        <div class="form-group flex-hbox flex-gap">
            <app-account-vote-input class="flex" formControlName="US" name="US"></app-account-vote-input>
            <app-account-vote-input class="flex" formControlName="Global" name="Global"></app-account-vote-input>
        </div>
        <div class="form-group flex-hbox flex-gap">
            <app-account-vote-input class="flex" formControlName="Sales" name="Sales"></app-account-vote-input>
            <app-account-vote-input class="flex" formControlName="Research" name="Research"></app-account-vote-input>
        </div>
        <div class="form-group flex-hbox flex-gap">
            <app-account-vote-input class="flex" formControlName="Corp" name="Corp Access"></app-account-vote-input>
            <app-account-vote-input class="flex" formControlName="Trader" name="Trader"></app-account-vote-input>
        </div>
        <div class="form-group">
            <label>Vote Comment</label>
            <textarea style="resize: vertical"
                      class="form-control input-sm"
                      rows="3"
                      placeholder="New Comment" 
                      formControlName="Comment">
            </textarea>
        </div>
        <div class="form-group">
            <label>Attachments</label>
            <app-files-upload formControlName="NewAttachments"></app-files-upload>
            <div *ngFor="let file of voteForm.controls['NewAttachments'].value">
                <span>{{ file.name }} </span>
                <span class="fa fa-remove" (click)="removeNew(file.name)"></span>
            </div>
            <div *ngFor="let file of voteForm.controls['ExistingAttachments'].value">
                <div *ngIf="!file.IsDeleted">
                    <span>{{ file.Filename }} </span>
                    <span class="fa fa-remove" (click)="removeExisting(file.Id)"></span>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Related Accounts</label>
            <checkbox-list [options]="relatedAccounts$ | async" 
                           [selectedValues]="voteForm.controls['RelatedAccounts'].value" 
                           (toggle)="onToggle($event, 'related')">
            </checkbox-list>
        </div>
        <div class="form-group" *ngIf="voteForm.controls['OtherAccounts'].value.length > 0">
            <label>Other Accounts</label>
            <checkbox-list [options]="otherAccounts"
                           [selectedValues]="voteForm.controls['OtherAccounts'].value"
                           (toggle)="onToggle($event, 'other')">
            </checkbox-list>
        </div>
    </div>
</form>
<div class="modal-footer">
    <div class="flex-hbox">
        <button type="button" class="flex btn btn-sm btn-primary" (click)="save()" [disabled]="voteForm.invalid" appIfUserFeature="InstiselMeetingsVoteLink">Save</button>
        <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()" [disabled]="!accountVoteId" appIfUserFeature="InstiselMeetingsVoteLink">Delete</button>
        <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
    </div>
</div>