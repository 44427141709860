import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {CheckboxItem} from "./checkbox-item";

@Component({
    selector: "checkbox-list",
    templateUrl: "./checkbox-list.component.html",
    styleUrls: ["./checkbox-list.scss"],
    encapsulation: ViewEncapsulation.None
})
export class CheckboxListComponent implements OnInit, OnChanges {
    @Input() options = Array<CheckboxItem>();
    @Input() selectedValues: string[];
    @Output() toggle = new EventEmitter<any[]>();
    @Output() checked = new EventEmitter<any>();
    @Output() unchecked = new EventEmitter<any>();
    constructor() { }
    ngOnInit() {}
    ngOnChanges() {
        if(!this.selectedValues || !this.options){
            return;
        }
        this.selectedValues.forEach(value => {
            const element = this.options.find(x => x.value === value);
            if (element) {
                element.checked = true;
            }
        });
    }

    onToggle(item: CheckboxItem) {
        if (item.checked) {
            this.checked.emit(item.value);
        } else {
            this.unchecked.emit(item.value);
        }
        const checkedOptions = this.options.filter(x => x.checked);
        this.selectedValues = checkedOptions.map(x => x.value);
        this.toggle.emit(checkedOptions.map(x => x.value));
    }
}
