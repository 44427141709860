import {Component, ViewEncapsulation} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {ConfigService} from "../../Shared/Services/config.service";
import {NetworkSwapFormComponent} from "./network-swap-form.component";

@Component({
    selector: "app-network-swap",
    templateUrl: "./network-swap.component.html",
    styleUrls: ["./network-swap.component.scss"],
    encapsulation: ViewEncapsulation.None

})
export class NetworkSwapComponent {
    constructor(private modalService: BsModalService, private configService: ConfigService) {}

    isSiteExternal(): boolean {
        return this.configService.getSiteIsExternal();
    }

    isApiExternal(): boolean {
        return this.configService.getApiIsExternal();
    }

    openModalForm(): void {
        if (this.isSiteExternal()) {
            this.modalService.show(NetworkSwapFormComponent, { animated: false, keyboard: false, backdrop: 'static'});
        }
    }
}
