<style>
    .query-tabset tab {
        height: 500px;
        max-height: 500px;
        overflow: auto;
    }
</style>

<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title">{{title}}</h3>
        <div *ngIf="!isCIAExport">Matching {{previewMatch}} {{previewType}} records</div>
    </div>
    <div class="modal-body">
        <form [formGroup]="queryForm">
            <div class="form-group flex-hbox">
                <div class="flex-2">
                    <span class="pull-right" style="padding-right: 5px" *ngIf="queryId && canEdit">
                        <label style="font-weight: normal"><input type="checkbox" formControlName="SaveAsNewQuery" /> Save as New Query</label>
                    </span>
                    <span appIfUserFeature="IsAdmin" class="pull-right" style="padding-right: 5px">
                        <label style="font-weight: normal"><input type="checkbox" formControlName="IsEmailable"/> Is Emailable</label>
                    </span>
                    <label>Name</label>
                    <input class="form-control input-sm" type="text" formControlName="Name"/>
                </div>
                <div class="flex-2" style="margin-left: 5px;">
                    <label>Description</label>
                    <input class="form-control input-sm" type="text" formControlName="Description"/>    
                </div>
                <div class="flex" style="margin: 0 5px;">
                    <label>Permission</label>
                    <select class="form-control input-sm" formControlName="Permission">
                        <option *ngFor='let permission of permissions' [value]="permission.Id">{{ permission.Name }}</option>
                    </select>
                </div>
                <div class="flex-2">
                    <label>Group</label>
                    <select class="form-control input-sm" formControlName="Group">
                        <option *ngFor='let group of groups$ | async' [value]="group.Id">{{ group.Name }}</option>
                    </select>
                </div>
            </div>
            <tabset class="query-tabset">
                <tab heading="Filters">
                    <app-query-groups-input formControlName="queryGroups"></app-query-groups-input>
                </tab>
                <tab heading="Exports">
                    <div class="query-group">
                        <div class="query-criteria">
                            <label>From</label>
                            <div class="criteria">
                                <select formControlName="selectObject" class="form-control input-sm">
                                    <option *ngFor="let objectName of fromObjectNames" [value]="objectName">{{objectName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="query-group" *ngIf="!isCIAExport">
                        <div class="query-criteria">
                            <label>Select</label>
                            <div class="criteria flex-hbox">
                                <div class="flex" style="padding: 2px; margin: 0 10px;" *ngFor="let object of availableObjects">
                                    <h4>
                                        <span class="btn btn-xs btn-primary pull-right" (click)="addAllExportFields(object.objectName)">Add All</span>
                                        {{object.objectName}}
                                    </h4>
                                    <div *ngFor="let fieldName of object.fieldNames">
                                        <button type="button" 
                                                class="btn btn-xs btn-toggle" 
                                                style="width: 100%; margin-bottom: 2px;" 
                                                (click)="addExportField(object.objectName, fieldName)"
                                                [class.active]="isExporting(object.objectName, fieldName)"
                                                [disabled]="isExporting(object.objectName, fieldName)">{{fieldName}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="actions">
                                Selected Exported Fields

                                <div *ngFor="let exportField of exportFields">
                                    <span class="fa fa-arrow-up" style="color: #999; cursor: pointer;" (click)="moveExportUp(exportField)"></span>
                                    <span class="fa fa-arrow-down" style="color: #999; cursor: pointer;" (click)="moveExportDown(exportField)"></span>
                                    {{exportField.Object}} {{exportField.Field}}
                                    <span class="fa fa-remove text-danger" style="cursor: pointer;" (click)="removeExport(exportField)"></span>
                                </div>
                                
                                <div style="margin-top: 15px;">
                                    <span class="btn btn-xs btn-danger" (click)="removeAllExport()">Remove All</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </form>
    </div>
    <div class="modal-footer">
        <div class="flex-hbox">
            <button type="button" class="flex btn btn-sm btn-success" (click)="export()" [disabled]="exporting || !canExport" appIfUserFeature="CanExportQuery">Export</button>
            <button type="button" class="flex btn btn-sm btn-primary" (click)="submit()" [disabled]="queryForm.invalid || !canEdit">Save</button>
            <button type="button" class="flex btn btn-sm btn-danger" (click)="delete()" [disabled]="!queryId || !canEdit">Delete</button>
            <button type="button" class="flex btn btn-sm btn-default" (click)="close()">Cancel</button>
        </div>
    </div>
</div>

