import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder } from "@angular/forms";
import {BsModalService} from "ngx-bootstrap/modal";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment/moment";
import {QueryGroup} from "../../../Services/QueryService";
import {EventService, StagingWebcastAttendee} from "../../Shared/Services/event.service";
import {
    CalendarEvent,
    EventConference,
    EventMeeting,
    EventPresenterType,
    EventType
} from "../../../Components/Events/CalendarEvent";
import {combineLatest, of} from "rxjs";
import {EventFormComponent} from "../EventForm/event-form.component";
import {User} from "../../../Models/User";
import {getEventDisplayName} from "../../../Helpers/DisplayStringHelper";
import {ActivatedRoute, Router} from "@angular/router";
import {EmailRoutePaths} from "../../Email/email-route-paths";

@Component({
    selector: "app-event-detail",
    templateUrl: "./event-detail.component.html",
    styleUrls: ["./event-detail.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class EventDetailComponent implements OnInit {

    eventId: number;

    event: CalendarEvent = { } as CalendarEvent;
    eventMeetings: EventMeeting[] = [];
    eventType: EventType;
    eventPresenterType: EventPresenterType;
    eventConference: EventConference;
    analysts: User[];
    coordinators: User[];
    user: User;
    webcastAttendees: StagingWebcastAttendee[];

    inviteQueryGroups: QueryGroup[];

    attendeeDataUpdated = new EventEmitter<boolean>();
    webcastAttendeeDataUpdated = new EventEmitter<boolean>();
    meetingDataUpdated = new EventEmitter<boolean>();
    companyDataUpdated = new EventEmitter<boolean>();

    constructor(
        private fb: UntypedFormBuilder,
        private eventService: EventService,
        private modalService: BsModalService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.loadEventData();
        this.userService.getCurrentUser().subscribe(user => this.user = user);
    }

    loadEventData(upToDateEvent: CalendarEvent = null): void {
        this.eventId = upToDateEvent ? upToDateEvent.Id : +this.route.snapshot.paramMap.get('eventId');
        let event$ = upToDateEvent ? of(upToDateEvent) : this.eventService.getEvent(this.eventId);
        let eventTypes$ = this.eventService.getEventTypes();
        let eventPresenterTypes$ = this.eventService.getEventPresenterTypes();
        let eventConferences$ = this.eventService.getEventConferences();
        let webcastAttendees$ = this.eventService.getStagingWebcastAttendees(this.eventId);
        let users$ = this.userService.getUsers();

        this.eventService.getEventMeetings(this.eventId).subscribe(eventMeetings => {
            this.eventMeetings = eventMeetings;
        });

        combineLatest([event$, eventTypes$, eventPresenterTypes$, eventConferences$, users$, webcastAttendees$])
            .subscribe(([event, eventTypes, eventPresenterTypes, eventConferences, users, webcastAttendees]) => {
                this.event = event;
                this.eventType = eventTypes.find(x => x.Id === event.EventTypeId);
                this.eventPresenterType = eventPresenterTypes.find(x => x.Id === event.PresenterTypeId);
                this.analysts = event.AnalystUserIds.map(x => users.find(y => y.Id === x) ?? { Id: x } as User).sort(function (a, b) { return a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase()); });
                this.coordinators = event.CoordinatorUserIds.map(x => users.find(y => y.Id === x) ?? { Id: x } as User).sort(function (a, b) { return a.LastName.toLowerCase().localeCompare(b.LastName.toLowerCase()); });
                this.eventConference = eventConferences.find(x => x.Id === event.EventConferenceId);
                this.inviteQueryGroups = event.InviteQuery.QueryGroups;
                this.webcastAttendees = webcastAttendees;
            });
    }

    getEventDateRange(): string {
        return !this.event
            ? ''
            : `${this.getFormattedDate(this.event.BeginDate)} - ${this.getFormattedDate(this.event.EndDate)}`;
    }

    hasWebcastIdOnAnyMeeting(): boolean {
        return this.eventMeetings && !!this.eventMeetings.find(x => x.WebcastId)
    }

    getLastUpdate(): string {
        return this.webcastAttendees && this.webcastAttendees.length > 0
            ? `${this.findLatestUpdate()} (CT)`
            : '';

    }

    findLatestUpdate(): string {
        return this.webcastAttendees.map(w => moment(w.UpdatedOn))
            .filter(w => w.isValid())
            .sort((a,b) => a.unix() - b.unix()).pop().format('LL LT');
    }

    getFormattedDate(date: Date | moment.Moment | string) {
        if (!date) {
            return ''
        }
        let dateToFormat = moment(date);
        return dateToFormat.isValid() ? dateToFormat.format('LL') : '';
    }

    getEventDisplayName(event: CalendarEvent): string {
        return getEventDisplayName(event);
    }

    edit() {
        const initialState = {
            eventId: this.eventId
        };
        let modalRef = this.modalService.show(EventFormComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-xl' });

        modalRef.content.dataUpdated.subscribe((updatedEvent: CalendarEvent) => {
            this.loadEventData(updatedEvent);
            this.meetingDataUpdated.emit(true);
            this.companyDataUpdated.emit(true);
        });
    }

    getAbsoluteUrl(url: string): string {
        if (!url) {
            return null;
        }

        if (url.toLowerCase().startsWith("https://") || url.toLowerCase().startsWith("http://")) {
            return url.trim();
        }

        return `//${url.trim()}`;
    }

    getListOfUsersAsDisplayString(users: User[]) : string {
        return users.map(x => `${x.LastName}`).sort().join(', ');
    }

    userIsEventAdmin(): boolean {
        return this.user && this.userService.hasUserFeature(this.user, 'EventAdmin');
    }

    onSendInviteClick(includeRecipients: boolean) {
        this.router.navigate([EmailRoutePaths.ComposeEmail, { eventId: this.eventId, includeRecipients: includeRecipients }]);
    }
}
