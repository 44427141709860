<div class="page-content no-scroll research-landing flex-vbox">
    <app-page-banner
            pagetitle="Research"
            faicon="fa-tachometer"
            faiconcolor="maroon"
            [bannerFormTemplate]="bannerFormTemplate">
    </app-page-banner>

    <ng-template #bannerFormTemplate>
        <form class="form-inline" [formGroup]="researchDashboard">
            <div *ngIf="canChangeTeam" class="form-group team-picker">
                <app-team-picker 
                        formControlName="team" 
                        [multiple]="false"
                        [showResearchOnly]="true">
                </app-team-picker>
            </div>
            <div class="form-group">
                <app-date-range-picker
                        formControlName="dateRange"
                        view="text"
                        [enableRanges]="true"
                        [options]="datePickerOptions">
                </app-date-range-picker>
            </div>
        </form>
        <div class="info">Charts may include calls no longer accessible in detailed Call Activity views.</div>
    </ng-template>
    
    <div class="page-body flex-hbox flex flex-gap" style="height: 100%;">
        <div class="flex-vbox flex flex-gap">
            <div class="flex flex-vbox">
                <tabset class="flex-tabset flex">
                    <tab>
                        <ng-template tabHeading>
                            <span tooltip="New subscribers within the past 20 days">New Subscribers</span>
                        </ng-template>
                        <app-new-subscribers class="flex" [teamId]="researchDashboard.controls['team'].value"></app-new-subscribers>
                    </tab>
                </tabset>
            </div>
            <div class="charts flex flex-vbox">
                <tabset class="flex-tabset flex">
                    <app-loading class="flex" *ngIf="isLoading"></app-loading>
                    <ng-container *ngIf="chartData$ | async as chartData">
                        <ng-container *ngIf="!isLoading">
                            <tab heading="Call Activity">
                                <app-call-activity-chart class="flex flex-vbox" [chartData]="chartData" [team]="researchDashboard.controls['team'].value"></app-call-activity-chart>
                            </tab>
                            <tab heading="Live Client Time">
                                <app-call-duration-chart class="flex flex-vbox" [chartData]="chartData"  [team]="researchDashboard.controls['team'].value"></app-call-duration-chart>
                            </tab>
                            <tab heading="Research Interaction">
                                <app-bespoke-research-interaction-chart class="flex flex-vbox" [chartData]="chartData" [team]="researchDashboard.controls['team'].value"></app-bespoke-research-interaction-chart>
                            </tab>
                            <tab heading="Activity Allocation">
                                <app-activity-allocation-chart
                                        class="flex flex-vbox"
                                        [chartData]="chartData"
                                        [team]="researchDashboard.controls['team'].value"
                                        [dateRange]="researchDashboard.controls['dateRange'].value"
                                ></app-activity-allocation-chart>
                            </tab>
                            <tab heading="Time Allocation">
                                <app-time-allocation-chart
                                        class="flex flex-vbox"
                                        [chartData]="chartData"
                                        [team]="researchDashboard.controls['team'].value"
                                        [dateRange]="researchDashboard.controls['dateRange'].value"
                                ></app-time-allocation-chart>
                            </tab>
                            <tab heading="Client Events">
                                <app-client-events-chart class="flex flex-vbox" [chartData]="chartData" [team]="researchDashboard.controls['team'].value"></app-client-events-chart>
                            </tab>
                            <tab heading="Calendar" *ngIf="canViewEventListTab">
                                <app-research-dashboard-event-list class="flex flex-vbox" [teamId]="researchDashboard.controls['team'].value"></app-research-dashboard-event-list>
                            </tab>
                        </ng-container>
                    </ng-container>
                </tabset>
            </div>
        </div>
        <div class="flex flex-vbox">
            <tabset class="flex-tabset flex">
                <tab heading="Grid">
                    <app-region-activity-summary
                            class="flex-vbox flex"
                            [teamId]="researchDashboard.controls['team'].value"
                            [dateRange]="researchDashboard.controls['dateRange'].value"
                    ></app-region-activity-summary>
                </tab>
                <tab heading="Ticker Activities" *ngIf="canViewTickerActivities">
                    <app-activities-by-ticker-chart 
                            class="flex-vbox flex"
                            [teamId]="researchDashboard.controls['team'].value"
                            [dateRange]="researchDashboard.controls['dateRange'].value"
                    ></app-activities-by-ticker-chart>
                </tab>
            </tabset>
        </div>
    </div>
</div>