import {Component, Input} from "@angular/core";
import {Contact} from "../../../Models/Contact";
import {forkJoin, from, of} from "rxjs";
import {mergeMap, switchMap, takeUntil, tap} from "rxjs/operators";
import {ExchangeContactsService} from "../../Shared/Services/exchange-contacts.service";
import {ConfigService} from "../../Shared/Services/config.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ProgressModalComponent} from "../ProgressModal/progress-modal.component";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact as GraphContact} from "@microsoft/microsoft-graph-types";
import {ToastrService} from "ngx-toastr";
import {isContactable} from "../../Shared/research-status";

@Component({
    selector: "app-export-contacts",
    templateUrl: "./export-contacts.component.html"
})
export class ExportContactsComponent {
    @Input()
    contacts: Contact[];

    @Input()
    includeDoNotContactContacts = true;

    constructor(
        private exchangeContactsService: ExchangeContactsService,
        private configService: ConfigService,
        private modalService: BsModalService,
        private contactService: ContactService,
        private toastrService: ToastrService
    ) {
    }

    exportContacts(): void {
        this.exchangeContactsService.getContactFolders().subscribe(contactFolders => {
            const folderName = this.configService.getAzureAdConfig().contactsFolder;
            const instiselFolder = contactFolders.find(f => f.displayName === folderName);
            if (instiselFolder) {
                this.addContactsToFolder(instiselFolder.id)
            } else {
                this.exchangeContactsService.addContactFolder(folderName)
                    .subscribe(folder => {
                        this.addContactsToFolder(folder.id);
                    });
            }
        })
    }

    private addContactsToFolder(folderId): void {
        const contactsEligibleToAdd = this.contacts
            .filter(c => this.includeDoNotContactContacts || isContactable(c));

        this.exchangeContactsService.getContactsInFolder(folderId).pipe(
            switchMap(existingContacts => {
                const existingContactMap = new Map<number, GraphContact>(existingContacts.map(c => [+c.officeLocation, c])); //Persnum is saved in the officeLocation field
                const contactsToAdd = contactsEligibleToAdd
                    .filter(c => !existingContactMap.has(c.Id));
                if (existingContacts.length + contactsToAdd.length > 1000) {
                    this.toastrService.error(
                        "This operation will result in exceeding the maximum (1000) exported contacts. Please delete the Instisel folder or remove contacts and try again.",
                        "Maximum folder size exceeded"
                    );
                    return;
                }
                const modalRef = this.openProgressModal(contactsEligibleToAdd.length);
                let added = 0;
                return from(contactsEligibleToAdd).pipe(
                    mergeMap(c => forkJoin([
                        of(c),
                        this.contactService.getContactNote(c.Id),
                    ]).pipe(
                        switchMap(([contact, contactNote]) => {
                            const existing = existingContactMap.get(contact.Id);
                            if (existing) {
                                if (this.isContactUpdated(existing, contact)) {
                                    return this.exchangeContactsService.updateContact(folderId, existing.id, contact, contactNote);
                                }
                                // the note field returned by the getContactsInFolder method doesn't return the full note so we must fetch it and compare
                                return this.exchangeContactsService.getFullNoteForContact(folderId, existing.id).pipe(
                                    switchMap(savedNote => {
                                        if ((savedNote && savedNote !== contactNote?.Professional) || (!savedNote && !!contactNote.Professional)) {
                                            return this.exchangeContactsService.updateContact(folderId, existing.id, contact, contactNote);
                                        }
                                        return of(null);
                                    })
                                );
                            }
                            return this.exchangeContactsService.addContact(folderId, contact, contactNote);
                        }),
                        tap(() => {
                            modalRef.content.value = ++added;
                        })
                    ), 4),
                    takeUntil(modalRef.content.cancel)
                );
            })).subscribe();
    }

    private isContactUpdated(existingContact: GraphContact, newContact: Contact): boolean {
        return existingContact.givenName !== newContact.FirstName
            || existingContact.surname !== newContact.LastName
            || existingContact.nickName !== newContact.Alias
            || existingContact.jobTitle !== newContact.Title
            || existingContact.officeLocation !== newContact.Id?.toString() // persnum is saved in the officeLocation field
            || existingContact.businessPhones[0] !== this.formatPhoneNumber(newContact)
            || existingContact.mobilePhone !== newContact.MobilePhoneNumber
            || existingContact.companyName !== newContact.Account?.Name
            || existingContact.department !== newContact.Account?.Name
            || existingContact.businessAddress.street !== newContact.Account?.MailingAddress?.Route
            || existingContact.businessAddress.city !== newContact.Account?.MailingAddress?.Locality
            || existingContact.businessAddress.state !== newContact.Account?.MailingAddress?.AdministrativeArea2
            || existingContact.businessAddress.postalCode !== newContact.Account?.MailingAddress?.PostalCode
            || existingContact.businessAddress.countryOrRegion !== newContact.Account?.MailingAddress?.Country
            || existingContact.otherAddress.street !== newContact.Account?.Address?.Route
            || existingContact.otherAddress.city !== newContact.Account?.Address?.Locality
            || existingContact.otherAddress.state !== newContact.Account?.Address?.AdministrativeArea2
            || existingContact.otherAddress.postalCode !== newContact.Account?.Address?.PostalCode
            || existingContact.otherAddress.countryOrRegion !== newContact.Account?.Address?.Country
            || (existingContact.emailAddresses[0]?.address && existingContact.emailAddresses[0]?.address !== newContact.Email)
            || (!existingContact.emailAddresses[0]?.address && !!newContact.Email)
            || existingContact.emailAddresses.slice(1, 3).some((e, i) => { // Outlook only allows a maximum of 3 email addresses
                return e.address && e.address !== newContact.EmailAddresses[i + 1]
                    || !e.address && !!newContact.EmailAddresses[i + 1]
            });
    }

    private formatPhoneNumber(contact: Contact): string {
        return [contact.PhoneNumber, contact.PhoneNumberExtension].filter(p => !!p).join(" x ")
    }

    private openProgressModal(max: number): BsModalRef {
        return this.modalService.show(ProgressModalComponent, {
            initialState: {
                message: `Adding or updating ${max} contact(s) to Instisel folder in Outlook.`,
                warning: "*Canceling will not remove contacts that have already been added to Outlook.",
                value: 0,
                max
            },
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });
    }
}
