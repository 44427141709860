import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService, FileAcceptType} from "./base-http.service";
import {Query, QueryDto, QueryGroup} from "../../../Services/QueryService";

@Injectable({
    providedIn: 'root',
})
export class QueryService {

    constructor(private baseHttp: BaseHttpService) {
    }

    getAllQueries(): Observable<QueryDto[]> {
        return this.baseHttp.getData(`/queries`);
    }
    
    getAllEmailableQueries(): Observable<QueryDto[]> {
        return this.baseHttp.getCacheOrData(`/queries?isEmailable=true`, 500);
    }
    
    getQueryById(id: number): Observable<QueryDto> {
        return this.baseHttp.getData(`/queries/${id}`);
    }

    createOrUpdateQuery(queryDto: QueryDto): Observable<QueryDto> {
        if (queryDto.Id) {
            return this.baseHttp.putData(`/queries/${queryDto.Id}`, queryDto);
        } else {
            return this.baseHttp.postData(`/queries`, queryDto);
        }
    }

    deleteQuery(queryId: number): Observable<boolean> {
        return this.baseHttp.deleteData(`/queries/${queryId}`);
    }

    getQuerySchema(): Observable<QuerySchema> {
        return this.baseHttp.getData(`/query`);
    }

    getQueryPreview(query: Query): Observable<any> {
        return this.baseHttp.postData(`/query/newpreview`, query);
    }

    exportQuery(fileName: string, query: Query): Observable<any> {
        return this.baseHttp.getFileFromPost(`/query/export`, fileName, FileAcceptType.Excel, query);
    }

    getCIAExport(fileName: string, query: Query): Observable<any> {
        return this.baseHttp.getFileFromPost(`/contact/ciaexport`, fileName, FileAcceptType.Text, query);
    }

    getDefaultQueryGroups(): QueryGroup[] {
        return [
            { Queries: [{ Object: 'Account', Field: 'Contact Groups', Operation: 'not contains', Value: '', Values: ['IER','PWM'] }] } as QueryGroup,
            { Queries: [{ Object: '', Field: '', Operation: '', Value: '', Values: [] }] } as QueryGroup,
        ];
    }

    getEventDefaultQueryGroups(eventID : number): QueryGroup[] {
        return [
            { Queries: [{ Object: 'Contact', Field: 'Event Response', Operation: 'not contains', Value: '', Values: [`${eventID}`] }] } as QueryGroup,
            { Queries: [{ Object: 'Account', Field: 'Contact Groups', Operation: 'not contains', Value: '', Values: ['IER','PWM'] }] } as QueryGroup,
            { Queries: [{ Object: 'Contact', Field: 'Contact Tier', Operation: 'contains', Value: '', Values: ['1','2','3','4'] }] } as QueryGroup,
            { Queries: [{ Object: 'Holding', Field: 'Marketing Email', Operation: 'is', Value: 'true', Values: null }] } as QueryGroup,
            { Queries: [{ Object: 'Contact', Field: 'Do Not Contact', Operation: 'is', Value: 'false', Values: null }] } as QueryGroup,
            { Queries: [{ Object: 'Contact', Field: 'Email', Operation: 'is not', Value: '', Values: null }] } as QueryGroup,
            { Queries: [{ Object: 'Holding', Field: 'Category', Operation: 'contains', Value: '', Values: ['H','I'] }] } as QueryGroup,
            { Queries: [{ Object: 'Holding', Field: 'Sector', Operation: 'contains', Value: '', Values: [] }] } as QueryGroup,
        ];
    }
}

export interface QuerySchema {
    Account: ObjectSchema;
    Activity: ObjectSchema;
    Contact: ObjectSchema;
    Holding: ObjectSchema;
    SpecialtyMailing: ObjectSchema;
}

export interface ObjectSchema {
    DefaultExportFields: { Object: string, Field: string }[];
    ExportObjects: string[];
    Fields: FieldSchema[];
}

export interface FieldSchema {
    CanExport: boolean;
    Name: string;
    Operations: string[];
    Type: string;
}
