import {Component, forwardRef, Input, OnInit, TemplateRef} from "@angular/core";
import {
    ControlValueAccessor,
    UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {NumberRange} from "./number-range";

@Component({
    selector: "app-number-range",
    templateUrl: "./number-range.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberRangeComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NumberRangeComponent),
            multi: true
        }

    ]
})

export class NumberRangeComponent implements  OnInit, ControlValueAccessor {
    
    headerTemplate: TemplateRef<any>;

    onChange = (updatedData: NumberRange) => {};
    onTouched = () => {};

    @Input()
    lowRequired: boolean = false;

    @Input()
    highRequired: boolean = false;

    @Input()
    lowMin: number = null;

    @Input()
    lowMax: number = null;

    @Input()
    highMin: number = null;

    @Input()
    highMax: number = null;

    validate({ value }: UntypedFormControl) {
        if (this.numberRangeGroup.invalid) {
            return {invalid: true};
        }
        return null;
    }


    rangeValidator = (low, high): ValidatorFn | null => (group: UntypedFormGroup) => {
        let lowValue = group.get(low).value;
        let highValue = group.get(high).value;

        if (lowValue !== null && lowValue !== "" && highValue !== null && highValue !== "" && lowValue > highValue) {
            return {rangeOrdered: true};
        }
        return null;
    }
    
    
    numberRangeGroup = this.fb.group({
        low: this.fb.control('', [Validators.min(0)]),
        high: this.fb.control('', [Validators.min(0)]),
    }, { 
        validator: this.rangeValidator("low", "high"),
    });

    constructor(private fb: UntypedFormBuilder){}

    ngOnInit(): void {
        
        let lowValidators = [];
        let highValidators = [];
        
        if (this.lowMin !== null) {
            lowValidators.push(Validators.min(this.lowMin));
        }
        if (this.lowMax !== null) {
            lowValidators.push(Validators.min(this.lowMax));
        }
        if (this.highMin !== null) {
            highValidators.push(Validators.min(this.highMin));
        }
        if (this.highMax !== null) {
            highValidators.push(Validators.min(this.highMax));
        }
        if(this.lowRequired) {
            lowValidators.push(Validators.required)
        }
        if(this.highRequired) {
            highValidators.push(Validators.required)
        }

        this.numberRangeGroup.get('low').setValidators(lowValidators);
        this.numberRangeGroup.get('high').setValidators(highValidators);

        this.numberRangeGroup.valueChanges
            .subscribe(numberRangeGroup => {
                this.onChange({
                    low: numberRangeGroup.low,
                    high: numberRangeGroup.high,
                });
        })
    }

    registerOnChange(fn: (updatedData: NumberRange) => void): void {
        this.onChange = fn;
    }

    // Allows Angular to register a function to call when the input has been touched.
    // Save the function as a property to call later here.
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // Allows Angular to update the model (rating).
    // Update the model and changes needed for the view here.
    writeValue(obj: NumberRange): void {
        this.numberRangeGroup.patchValue(obj);
    }
}
