import {Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {combineLatest} from "rxjs";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import {
    getGridDisplayWords,
    isNoContactContact, isNoContactReadOnlyContact
} from "../../Shared/ag-grid-options";
import * as moment from "moment";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import { DatePickerOptionsBuilder } from '../../../DatePickerOptionsBuilder';
import { UserService } from '../../Shared/Services/user.service';
import {participantsRenderer, dateRenderer} from "../../Shared/ag-grid-cell-renderers";
import {dateComparator} from "../../Shared/ag-grid-cell-comparators";
import {FullNameFilter} from "../../../Filters/FullNameFilter";
import {CacheService} from "../../Shared/Services/cache.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { UserTeam } from "../../../Models/UserTeam";
import {ColDef, RowClassRules} from "ag-grid-community";
import {EESActivity, EESActivityService} from "../../Shared/Services/ees-activity.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";

@Component({
    selector: "ees-activity-list",
    templateUrl: "./ees-activity-list.component.html",
    styleUrls: ["./ees-activity-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class EesActivityListComponent extends BaseGridComponent<EESActivity> implements OnInit {

    modalRef: BsModalRef;

    @ViewChild('inactiveContactTemplate', { static: true})
    public inactiveContactTemplate: TemplateRef<any>;

    columnDefs: ColDef[] = [
        { field: 'ContactLastName', headerName: 'Name', flex: 2, valueGetter: this.contactValueGetter, tooltipValueGetter: this.contactValueGetter },
        {
            field: 'EesActivityAccount',
            headerName: 'Account',
            flex: 3,
            valueGetter: (params) => params.data.EesActivityAccount.Name,
            tooltipValueGetter: (params) => params.data.EesActivityAccount.Name
        },
        {
            field: 'Baird',
            width: 75,
            cellRenderer: (params) =>
                participantsRenderer(
                    [{ UserId: params.data.Baird, TeamId: params.data.BairdTeamId }],
                    this.userTeams
                )
        },
        {
            field: 'ActivityDate',
            headerName: 'Call Date',
            sort: 'desc',
            width: 100,
            cellRenderer: dateRenderer,
            comparator: (valueA, valueB, nodeA, nodeB) => dateComparator(nodeA.data.ActivityDate, nodeB.data.ActivityDate)
        },
        { field: 'Comment', flex: 2 },
        { field: 'Status', flex: 1, cellRenderer: this.statusRenderer },
        {
            field: 'DueDate',
            headerName: 'Due Date',
            width: 100,
            cellRenderer: dateRenderer,
            comparator: (valueA, valueB, nodeA, nodeB) => dateComparator(nodeA.data.DueDate, nodeB.data.DueDate)
        },
    ];

    rowClassRules: RowClassRules = {
        'do-not-contact': params => isNoContactContact(params.data.Contact),
        'do-not-contact-readonly': params => isNoContactReadOnlyContact(params.data.Contact),
        'moved-contact': (params) => params.data.Contact && params.data.Contact.Account.Id !== params.data.EesActivityAccount.Id,
        'deleted-contact': (params) => !params.data.Contact,
    };

    datePickerOptions = new DatePickerOptionsBuilder()
        .setRangesToPredefinedList(moment())
        .setToLeftAligned()
        .buildOptions();

    activitySearchForm: UntypedFormGroup = this.fb.group({
        dateRange: this.fb.control({ start: moment().startOf('year'), end: moment().startOf('day') }),
        accountSearchTerm: this.fb.control(''),
        commentSearchTerm: this.fb.control(''),
        types: this.fb.control([]),
        statuses: this.fb.control([]),
    });

    userTeams: UserTeam[];
    notShowingAllRecords: boolean = false;


    constructor(private fb: UntypedFormBuilder,
                private eesActivityService: EESActivityService,
                private userService: UserService,
                private cacheService: CacheService,
                private modalService: BsModalService,
                private route: ActivatedRoute,
                private router: Router,
    ) { super(); }

    ngOnInit(): void {
        this.userService.getUserTeams().subscribe(userTeams => this.userTeams = userTeams);

        const cachedFilters = this.cacheService.getValue("EesActivityListFilters");

        if (Object.values(this.route.snapshot.paramMap.keys).some(p => !!p)) {

            const startDate = this.route.snapshot.paramMap.get('startDate');
            const endDate = this.route.snapshot.paramMap.get('endDate');

            if (startDate && endDate) {
                this.activitySearchForm
                    .get("dateRange")
                    .patchValue({
                        start: startDate,
                        end: endDate
                    });
            }

        } else if (cachedFilters) {
            this.activitySearchForm.patchValue(cachedFilters);
        }

        this.gridOptions.overlayNoRowsTemplate = `<span>No EES Activities Found</span>`;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        let users$ = this.userService.getUsers();

        let eesActivities$ = this.activitySearchForm.valueChanges.pipe(
            startWith(this.activitySearchForm.getRawValue()),
            debounceTime(500),
            tap(() => this.gridApi.showLoadingOverlay()),
            tap(formValues => this.cacheService.setValue("EesActivityListFilters", formValues)),
            switchMap(form =>
                this.eesActivityService.searchActivities(
                    null,
                    null,
                    form.dateRange.start,
                    form.dateRange.end,
                    form.commentSearchTerm,
                    form.accountSearchTerm,
                    form.types,
                    form.statuses,
                )
            ),
            tap(x => this.gridApi.hideOverlay()),
            tap(x => this.notShowingAllRecords = x.length >= 16000)
        );

        this.rowData$ = combineLatest([eesActivities$, users$]).pipe(
            map(([eesActivities, users]) => {
                eesActivities.forEach(a => {
                    a.BairdTeamId = users.find(u => u.Id === a.Baird)?.Team?.Id;
                    a.Status = a.Status ?? '';
                });

                return eesActivities;
            })
        );
    }

    rowClicked($event: any): void {
        if ($event.data.Contact) {
            this.router.navigate([ContactRoutePaths.ContactDetail, $event.data.Contact.Id, { defaultTab: 'EES' } ]);
        } else {
            this.modalRef = this.modalService.show(this.inactiveContactTemplate);
        }
    }

    contactValueGetter(params: any) {
        return FullNameFilter.filter(params.data.Contact?.FirstName, params.data.Contact?.LastName, params.data.Contact?.Alias);
    }

    statusRenderer(params: any): string {
        return (
            params.value === 'O' ? 'Open' :
            params.value == 'C' ? 'Completed' :
            ''
        );
    }

    getDisplayWords() : string {
        return getGridDisplayWords(this.gridApi);
    }

    clearFilters() {
        this.activitySearchForm.patchValue({
            dateRange: { start: moment().startOf('year'), end: moment().startOf('day') },
            accountSearchTerm: '',
            commentSearchTerm: '',
            types: [],
            statuses: [],
        });
    }
}
