import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "./base-http.service";

@Injectable({
    providedIn: 'root',
})
export class BlueMatrixService {

    constructor(private baseHttp: BaseHttpService) { }

    addBlueMatrixContact(contactIds: number[]): Observable<number[]> {
        return this.baseHttp.postData(`/bluematrix/sendcontacts`, contactIds);
    }

    clearAllSendContact(): Observable<void> {
        return this.baseHttp.deleteData(`/bluematrix/sendcontacts`);
    }

    getBlueMatrixSendContact(): Observable<number[]> {
        return this.baseHttp.getData(`/bluematrix/sendcontacts`);
    }
    
    getCurrentFiscalYear(): Observable<number> {
        return this.baseHttp.getData(`/research/fiscalyear`);
    }
    
    updateCurrentFiscalYear(year: number): Observable<void> {
        return this.baseHttp.postData(`/research/fiscalyear`, year);
    }
    
}
