<div class="modal-content">
    <form [formGroup]="form" id="contactForm">
        <div class="modal-header">
            Edit Contact Image
        </div>
        <div class="modal-body">
            
            <div *ngIf="contactImageSrc">
                <img [src]="contactImageSrc" alt="current contact profile image" style="max-width: 100%;" />
            </div>
            
            <app-file-upload formControlName="file"></app-file-upload>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" 
                        class="btn btn-sm btn-primary flex" 
                        [disabled]="form.invalid"
                        (click)="save()">
                    Save
                </button>
                <button type="button"
                        class="btn btn-sm btn-danger flex"
                        *ngIf="contactImageSrc"
                        (click)="delete()">
                    Delete
                </button>
                <button type="button" 
                        class="btn btn-sm btn-default flex" 
                        (click)="close()">
                    Cancel
                </button>
            </div>
        </div>
    </form>
</div>
