import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {ColDef, ICellRendererParams} from "ag-grid-community";
import {combineLatest} from "rxjs";
import {EventService} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: "app-tab-presenter-list",
    template: `
        <div class="flex-1 flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="companySearchForm">
                    <div class="flex-hbox flex-gap">
                        <input formControlName="searchName"
                               type="search"
                               placeholder="Search Name"
                               class="flex-4 form-control input-sm"/>
                    </div>
                </form>
            </div>

            <ag-grid-angular
                    style="width: 100%; height: 100%;"
                    class="ag-theme-balham presenter-grid"
                    [gridOptions]="gridOptions"
                    [rowData]="rowData$ | async"
                    [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDef"
                    (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </div>`,
    styleUrls: ["./tab-presenter-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabPresenterListComponent extends BaseGridComponent<PresenterRow> implements OnInit {

    @Input()
    requestId: number;

    companySearchForm: UntypedFormGroup = this.fb.group({
        searchName: this.fb.control(''),
    });

    columnDefs: ColDef[] = [
        {field: 'Description', hide: true, rowGroup: true, sortable: false},
        {
            field: 'Name', tooltipField: 'Name', flex: 2, cellRenderer: (params: ICellRendererParams) => {
                return `<span style="margin-left: 45px">${params.value}</span>`;
            },
        },
        {field: 'Title', tooltipField: 'Title', flex: 3},
    ];

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private eventService: EventService,
                private route: ActivatedRoute,
    ) {
        super();
    }

    public groupDefaultExpanded = 1;

    ngOnInit(): void {
        this.requestId = +this.route.snapshot.queryParamMap.get('requestId') || +this.route.snapshot.queryParamMap.get('reqId');

        this.gridOptions.overlayNoRowsTemplate = `<span>No Presenters Found</span>`;
        this.gridOptions.groupDefaultExpanded = 1;
        this.gridOptions.groupDisplayType = 'groupRows';
        this.gridOptions.groupRowRendererParams = { suppressCount: true };

        let searchForm$ = this.companySearchForm.valueChanges.pipe(
            startWith(this.companySearchForm.getRawValue()),
            debounceTime(300));

        let event$ = this.eventService.getRequest(this.requestId)

        this.rowData$ = combineLatest([searchForm$, event$]).pipe(
            map(([searchForm, event]) => {
                let presenters = [];

                let searchTerms = searchForm.searchName.split(/[\s,]+/);

                if (event.Presenters) {
                    presenters.push(...event.Presenters.map(p => {
                        return {
                            Description: "Company Management",
                            Name: `${p.LastName}, ${p.FirstName}`,
                            Title: `${p.Title}`
                        }
                    }))
                }

                if (event.Researchers) {
                    presenters.push(...event.Researchers.map(p => {
                        return {
                            Description: "Research",
                            Name: `${p.LastName}, ${p.FirstName}`,
                            Title: `${p.Title}`
                        }
                    }))
                }

                if (event.Sales) {
                    presenters.push(...event.Sales.map(p => {
                        return {
                            Description: "Sales",
                            Name: `${p.LastName}, ${p.FirstName}`,
                            Title: `${p.Title}`
                        }
                    }))
                }

                return presenters
                    .filter(a => searchTerms.every(term =>
                        a.Name.toLowerCase().indexOf(term.toLowerCase()) > -1
                    ));
            })
        )
    }
}

interface PresenterRow {
    Description: string;
    Name: string;
    Title: string;
}
