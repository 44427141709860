import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {BaseHttpService} from "./base-http.service";
import * as moment from "moment";
import { Contact } from '../../../Models/Contact';
import { Account } from '../../../Models/Account';

export interface EESActivity {
    Id: number;
    Contact: Contact;
    EesActivityAccount: Account;
    ActivityDate: string | Date | moment.Moment;
    ActivityType: String;
    Status: string;
    DueDate: string | Date | moment.Moment;
    BairdParticipants: EESActivityParticipant[];
    ClientParticipants:  EESActivityParticipant[];
    Comment: string;
    Baird: string;
    BairdTeamId: number;
    CreatedDate: string | Date | moment.Moment;
    CreatedBy: EESActivityCreateBy;
}

export interface EESActivityParticipant {
    Id: number;
}

export interface EESActivityCreateBy {
    Id: string;
}

@Injectable({
    providedIn: 'root',
})
export class EESActivityService {

    constructor(private baseHttp: BaseHttpService) { }

    getActivityById(ActivityId: number): Observable<EESActivity> {
        return this.baseHttp.getData(`/ees/activity/id/${ActivityId}`);
    }

    createActivity(activity: EESActivity): Observable<EESActivity> {
        return this.baseHttp.postData(`/ees/activity`, activity);
    }

    updateActivity(activity: EESActivity): Observable<EESActivity> {
        return this.baseHttp.putData(`/ees/activity/id/${activity.Id}`, activity);
    }

    deleteActivity(activityId: number): Observable<any> {
        return this.baseHttp.deleteData(`/ees/activity/id/${activityId}`);
    }

    searchActivities(
        accountId: number,
        contactId: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
        commentSearchTerm: string,
        accountSearchTerm: string,
        types: string[],
        statuses: string[],
    ): Observable<EESActivity[]> {

        let searchData = {
            AccountId: accountId,
            ContactId: contactId,
            StartDate: startDate,
            EndDate: endDate,
            CommentSearchTerm: commentSearchTerm,
            AccountSearchTerm: accountSearchTerm,
            Types: types,
            Statuses: statuses,
        }

        return this.baseHttp.postData(`/ees/activity/search`, searchData);
    }

    getEESActivitiesByContactId(contactId: number): Observable<EESActivity[]> {
        return this.baseHttp.getData(`/ees/activity/contact/${contactId}`);
    }

    getEESActivitiesByAccountId(accountId: number): Observable<EESActivity[]> {
        return this.baseHttp.getData(`/ees/activity/account/${accountId}`);
    }
}