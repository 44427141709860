<div class="modal-header">
    Open Contact List
</div>
<div class="modal-body">
    <div class="flex-hbox" style="margin-bottom: 10px;">
        <input type="search"
               class="form-control input-sm"
               style="width: 100%"
               [formControl]="listSearchBox"
               placeholder="Search" />
        </div>
    <div class="form-group" style="height: 300px;">
        <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>
    </div>
    <div class="flex-hbox flex-gap">
        <button type="button" class="flex btn btn-sm btn-primary" (click)="open()" [disabled]="!rowSelected">Open</button>
        <button type="button" class="flex btn btn-sm btn-default" (click)="cancel()">Cancel</button>
    </div>
</div>