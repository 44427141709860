import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from "@angular/core";
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors, Validators,
} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {ContactService} from "../../Shared/Services/contact.service";
import {Contact} from "../../../Models/Contact";
import * as _ from "lodash";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
import {User} from "../../../Models/User";
import {combineLatest} from "rxjs";
import {UserService} from "../../Shared/Services/user.service";
import * as moment from "moment";
import {TickerService} from "../../Shared/Services/ticker.service";
import {UserPermissions} from "../../../Services/UserPermissions";
import {HoldingService} from "../../Shared/Services/holding.service";
import {Holding} from "../../../Models/Holding";
import {QueryGroup} from "../../../Services/QueryService";
import {QueryTranslator} from "../../../Helpers/QueryTranslator";
import {LoadingComponent} from "../../Widget/Loading/loading.component";
import {LoadingModalComponent} from "../../Widget/Loading/loading-modal.component";

@Component({
    selector: "app-interest-form-base",
    templateUrl: "../../Interest/InterestForm/interest-form.component.html"
})
export class InterestFormComponent implements OnInit {

    @Input()
    contactId: number;

    @Input()
    holdingIds: number[] = [];

    @Output()
    dataUpdated = new EventEmitter<boolean>();

    interestForm: UntypedFormGroup = this.fb.group({
        action: this.fb.control('edit'),
        contactIds: this.fb.control([], [Validators.required]),
        tickers: this.fb.control([],[Validators.required]),
        category: this.fb.control(''),
        blueMatrix: this.fb.control([]),
        marketingEmails: this.fb.control([]),
        researchEmails: this.fb.control([]),
        fDate: this.fb.control('', [this.valid13fDate]),
        comment: this.fb.control('', [Validators.maxLength(40)]),
        createdBy: this.fb.control({value: '', disabled: true}),
        updatedBy: this.fb.control({value: '', disabled: true}),
    });

    contact: Contact;
    user: User;
    holdings: Holding[] = [];
    singleTicker: boolean = true;
    existingTickers: string[] = [];
    showHasUncovered: boolean = false;
    showHasExisting: boolean = false;
    maximumTickers: number = 20;
    maximumContacts: number = 1;

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private modalRef: BsModalRef,
                private holdingService: HoldingService,
                private userService: UserService,
                private toastr: ToastrService) { }

    ngOnInit(): void {

        let user$ = this.userService.getCurrentUser();

        let queryGroups: QueryGroup[] = [];
        QueryTranslator.AddQueryGroupValues(queryGroups, "Holding", "Id", "contains", this.holdingIds);
        let holdings$ = this.holdingService.searchHoldings(queryGroups);

        combineLatest([user$, holdings$])
            .subscribe(([user, holdings]) => {

                this.user = user;
                this.holdings = holdings;

                this.interestForm.patchValue({
                    contactIds: [this.contactId]
                });
                this.interestForm.get('contactIds').disable();

                if (this.holdings.length === 0) {
                    this.interestForm.patchValue({
                        tickers: [],
                        category: 'I',
                        blueMatrix: 'Y',
                        researchEmails: 'Y',
                        marketingEmails: 'Y',
                        fDate: null,
                        comment: '',
                        createdBy: `${this.user.Id} ${moment().format("L")}`,
                        updatedBy: '',
                    });
                } else if (this.holdings.length === 1) {
                    this.interestForm.patchValue({
                        tickers: [this.holdings[0].Ticker],
                        category: this.holdings[0].Category,
                        blueMatrix: this.holdings[0].BlueMatrix,
                        marketingEmails: this.holdings[0].MarketingEmails,
                        researchEmails: this.holdings[0].ResearchEmails,
                        fDate: moment(this.holdings[0].DateOf13F).format('yyyy-MM-DD'),
                        comment: this.holdings[0].Comment,
                        createdBy: `${this.holdings[0].CreatedBy} ${moment(this.holdings[0].CreatedDate).format("L")}`,
                        updatedBy: this.holdings[0].UpdatedBy ? `${this.holdings[0].UpdatedBy} ${moment(this.holdings[0].UpdatedDate).format("L")}` : '',
                    });
                    this.interestForm.get('tickers').disable();
                } else {
                    this.interestForm.patchValue({
                        tickers: [this.holdings[0].Ticker],
                        category: '',
                        blueMatrix: '',
                        researchEmails: '',
                        marketingEmails: '',
                        fDate: null,
                        comment: '',
                        createdBy: '',
                        updatedBy: '',
                    });
                }
            });

        if (!this.holdingIds) {
            this.showHasUncovered = true;
            this.showHasExisting = true;
            this.holdingService.getContactHoldings(this.contactId)
                .subscribe(holdings => {
                    this.existingTickers = [...new Set(holdings.map(h => h.Ticker))];
                });
        }

        this.interestForm.get('action').valueChanges
            .subscribe(action => {
                if (action === 'edit') {
                    this.interestForm.patchValue({
                        contactIds: [this.contactId]
                    });
                    this.interestForm.get('contactIds').disable();
                } else {
                    this.interestForm.patchValue({
                        contactIds: []
                    });
                    this.interestForm.get('contactIds').enable();
                    this.maximumContacts = action === 'copy' ? 20 : 1;
                }
            });
    }

    valid13fDate(control: AbstractControl): ValidationErrors | null {
        return moment(control.value).year() < 1753 ? { tooOld: true }
            : null;
    }

    save() {
        if (this.holdings.length > 0) {
            let updatedHoldings = this.holdings.map(h => this.updateHolding(h, this.interestForm.value, this.holdings.length > 1));
            let action = this.interestForm.get('action').value;
            if (action === 'edit') {
                this.holdingService.updateHoldings(updatedHoldings)
                    .subscribe(() => {
                        this.toastr.success("Holding Updated");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            } else if (action === 'copy') {
                let copies = this.interestForm.get('contactIds').value
                    .flatMap(cid => {
                        return updatedHoldings
                            .map(holding => {
                                holding.ContactId = cid;
                                holding.Id = 0;
                                return holding;
                            });
                    });
                this.holdingService.createHoldings(updatedHoldings)
                    .subscribe(() => {
                        this.toastr.success("Holding(s) Copied");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            } else if (action === 'reassign') {
                updatedHoldings.forEach(h => {
                    h.ContactId = this.interestForm.get('contactIds').value[0];
                });
                this.holdingService.updateHoldings(updatedHoldings)
                    .subscribe(() => {
                        this.toastr.success("Holding(s) Reassigned");
                        this.dataUpdated.emit(true);
                        this.modalRef.hide();
                    });
            }
        } else {
            let holdings = this.createHoldingsFromTickers()
                .map(h => this.updateHolding(h, this.interestForm.value, false));
            this.holdingService.createHoldings(holdings)
                .subscribe(() => {
                    this.toastr.success("Holding Created");
                    this.dataUpdated.emit(true);
                    this.modalRef.hide();
                });
        }
    }

    cancel() {
        this.modalRef.hide();
    }

    delete() {
        const initialState = {
            message: `Do you want to delete the Interest(s)?`,
        };
        let confirmModalRef = this.modalService.show(ConfirmModalComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: 'static'
        });

        confirmModalRef.content.action
            .subscribe(isConfirmed => {
                if (isConfirmed) {

                    let loadingModal = this.modalService.show(LoadingModalComponent, {
                        initialState: { message: 'Deleting' },
                        animated: false,
                        keyboard: false,
                        backdrop: 'static'
                    });

                    this.holdingService.deleteHoldings(this.holdingIds)
                        .subscribe(() => {
                            loadingModal.hide();
                            this.toastr.success("Holding(s) has been deleted");
                            this.dataUpdated.emit(true);
                            this.modalRef.hide();
                        })
                }
            });
    }

    createHoldingsFromTickers(): Holding[] {
        return this.interestForm.get('tickers').value
            .map(t => {
                return {
                    Ticker: t,
                    ContactId: this.contactId
                } as Holding;
            });
    }

    updateHolding(holding: Holding, formValue: any, multiEdit: boolean): Holding {

        if (formValue.category || !multiEdit) {
            holding.Category = formValue.category;
        }
        if (formValue.blueMatrix || !multiEdit) {
            holding.BlueMatrix = formValue.blueMatrix;
        }
        if (formValue.researchEmails || !multiEdit) {
            holding.ResearchEmails = formValue.researchEmails;
        }
        if (formValue.marketingEmails || !multiEdit) {
            holding.MarketingEmails = formValue.marketingEmails;
        }
        if (formValue.comment || !multiEdit) {
            holding.Comment = formValue.comment;
        }
        if (formValue.fDate || !multiEdit) {
            holding.DateOf13F = moment(formValue.fDate);
        }

        return holding;
    }

    get blueMatrixDisabledForUser(): boolean {
        return  !UserPermissions.canManageBlueMatrixOnHoldings(this.user);
    }
}
