<div class="modal-content travel-city-form">
    <div class="modal-header">
        <h3 class="modal-title pull-left">{{title}}</h3>
    </div>
    <form [formGroup]="travelCityForm">
        <div class="modal-body">
            <div class="flex-hbox">
                <div class="form-group flex-3">
                    <label>Name</label>
                    <input class="form-control input-sm" type="text" formControlName="Name" />
                </div>

                <div class="form-group flex">
                    <label>Deleted</label>
                    <select class="form-control input-sm" formControlName="Deleted">
                        <option [ngValue]="true">Yes</option>
                        <option [ngValue]="false">No</option>
                    </select>
                </div>
            </div>

            <div class="flex-hbox">
                <div class="form-group flex-2">
                    <label>Territory</label>
                    <app-travel-territory-picker
                            formControlName="TerritoryId"
                            [selectClearAll]="false"
                            [multipleSelect]="false"
                            [showTagCount]="false"
                            [closeAfterSelection]="true"
                            [setMaxItems]="1"
                            [placeHolderLabel]="'Select Territory'"
                    >
                    </app-travel-territory-picker>
                </div>
                <div class="form-group flex-2">
                    <label>Market</label>
                    <app-travel-market-picker
                            formControlName="MarketId"
                            [selectClearAll]="false"
                            [multipleSelect]="false"
                            [showTagCount]="false"
                            [closeAfterSelection]="true"
                            [setMaxItems]="1"
                            [placeHolderLabel]="'Select Market'"
                            [currentTravelTerritoryId]="currentTravelTerritoryId"
                    >
                    </app-travel-market-picker>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="flex-hbox flex-gap">
                <button type="button" class="flex btn btn-primary" [disabled]="travelCityForm.invalid" (click)="submit()">Save</button>
                <button type="button" class="flex btn btn-default" (click)="close()">Cancel</button>
            </div>
        </div>
    </form>
</div>

