<div class="page-banner banner flex-hbox" [ngClass]="bannerClass">
    <div class="media">
        <div class="media-left">
            <div class="fa fa-2x {{ faicon }} list-heading-badge" 
                 *ngIf="!iconTemplate"
                 [style.background-color]="faiconcolor" 
                 style="color: white;"></div>
            <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
        </div>
        <div class="media-body" style="width: inherit;">
            <h4 *ngIf="pagetitle" class="media-heading">{{pagetitle}}</h4>
            <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="subTitleTemplate"></ng-container>
        </div>
    </div>
    <div class="flex text-right section-right">
        <ng-container [ngTemplateOutlet]="bannerFormTemplate"></ng-container>
    </div>
</div>