import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import * as moment from "moment";
import {TickerService} from "../../Shared/Services/ticker.service";
import * as _ from "lodash";
import {ActivityFeatureManager} from "../../../ActivityFeatureManager";
import {numberRenderer, yesNoCellRenderer} from "../../Shared/ag-grid-cell-renderers";
import {debounceTime, startWith, map, switchMap} from "rxjs/operators";
import {UntypedFormBuilder} from "@angular/forms";

@Component({
    selector: "app-ticker-account-summary",
    templateUrl: "./ticker-account-summary.component.html"
})
export class TickerAccountSummaryComponent extends BaseGridComponent<TickerAccountSummaryRow> implements OnInit, OnChanges {
    
    @Input()
    ticker: string;
    
    @Input()
    backups: string[];
    
    searchForm = this.fb.group({
        searchTerm: this.fb.control(''),
        dateRange: this.fb.control({
            start: moment().add(-18, "month"),
            end: moment()
        }),
        ticker: this.fb.control(''),
        backups: this.fb.control([])
    });
    
    columnDefs = [
        {field: 'Name', headerName: 'Account', flex: 3, tooltipValueGetter: (p) => `${p.data.Name} - Parent Code ${p.data.ParentComdolId}` },
        {field: 'TotalVolume', headerName: 'Total Vol', sort: 'desc', flex: 2, cellRenderer: numberRenderer, headerTooltip: 'Total Vol', type: 'numericColumn' },
        {field: 'BuyVolume', headerName: 'Buy Vol', flex: 2, cellRenderer: numberRenderer, headerTooltip: 'Buy Vol', type: 'numericColumn' },
        {field: 'SellVolume', headerName: 'Sell Vol', flex: 2, cellRenderer: numberRenderer, headerTooltip: 'Sell Vol', type: 'numericColumn' },
        {field: 'CorpCalls', headerName: 'Corp Calls', flex: 2, cellRenderer: numberRenderer, headerTooltip: 'Corp Calls', cellStyle: { textAlign: 'center' } },
        {field: 'ResearchCalls', headerName: 'Res Calls', flex: 2, cellRenderer: numberRenderer, headerTooltip: 'Res Calls', cellStyle: { textAlign: 'center' } },
        {field: 'Interest', headerName: 'Interest', flex: 2, cellRenderer: yesNoCellRenderer, headerTooltip: 'Interest', cellStyle: { textAlign: 'center' } }
    ];
    
    constructor(private fb: UntypedFormBuilder, private tickerService: TickerService) {
        super();
        
        this.gridOptions.pagination = true;
    }

    ngOnInit(): void {
        let activityFeatureManager = new ActivityFeatureManager(null, null, null);
        let corpCategories = _.map(activityFeatureManager.getTickerCorpCategories(), c => c.Code);
        let researchCategories = _.map(activityFeatureManager.getTickerResearchCategories(), c => c.Code);
        let allCategories = corpCategories.concat(researchCategories);
        
        this.rowData$ = this.searchForm.valueChanges.pipe(
            startWith(this.searchForm.getRawValue()),
            debounceTime(200),
            switchMap(formValues => this.tickerService.getTickerAccountSummary(formValues.ticker, allCategories, formValues.dateRange.start, formValues.dateRange.end)),
            map(accounts => _.chain(accounts)
                .map(a => {
                    return {
                        Name: a.Name,
                        ParentComdolId: a.ParentComdolId,
                        TotalVolume: a.BuyVolume + a.SellVolume,
                        BuyVolume: a.BuyVolume,
                        SellVolume: a.SellVolume,
                        CorpCalls: _.chain(a.GroupedCallVolumes || [])
                            .filter(cv => _.includes(corpCategories, cv.Category))
                            .map(cv => cv.Count)
                            .sum()
                            .value(),
                        ResearchCalls: _.chain(a.GroupedCallVolumes || [])
                            .filter(cv => _.includes(researchCategories, cv.Category))
                            .map(cv => cv.Count)
                            .sum()
                            .value(),
                        Interest: a.Interest,
                    } as TickerAccountSummaryRow
                })
                .filter(a => this.showAccountSummary(a))
                .value())
        );
    }
    
    private showAccountSummary(it: TickerAccountSummaryRow): boolean {
        return (it.BuyVolume !== 0 ||
            it.SellVolume !== 0 ||
            it.CorpCalls !== 0 ||
            it.ResearchCalls !== 0 ||
            it.Interest) && _.includes(it.Name.toLowerCase(), this.searchForm.controls["searchTerm"].value.toLowerCase());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ticker) {
            this.searchForm.controls["ticker"].patchValue(changes.ticker.currentValue);
        }
        if (changes.backups) {
            this.searchForm.controls["backups"].patchValue(changes.backups.currentValue);
        }
    }
}

export interface TickerAccountSummaryRow {
    Name: string;
    ParentComdolId: string;
    TotalVolume: number;
    BuyVolume: number;
    SellVolume: number;
    CorpCalls: number;
    ResearchCalls: number;
    Interest: boolean;
}
