import {Component, ViewChild, ViewEncapsulation} from "@angular/core";
import {DocumentsService} from "../../Shared/Services/documents.service";
import {PopoverDirective} from "ngx-bootstrap/popover";
import {Router} from "@angular/router";
import {UserRoutePaths} from "../../User/user-route-paths";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent {

    @ViewChild(PopoverDirective)
    popover: PopoverDirective

    constructor(
        private documentService: DocumentsService,
        private router: Router
    ) {
    }

    getUserGuideInNewTab(): void {
        this.documentService.getDocumentInNewTab("userguide", "userGuide.pdf").subscribe();
    }

    getOutlookGuide(): void {
        this.documentService.getDocument("outlookguide", "instisel-outlook-add-in.pdf").subscribe();
    }

    goToUserList(): void {
        this.popover?.hide();
        this.router.navigate([UserRoutePaths.UserList]);
    }
}
