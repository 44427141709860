import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from "@angular/core";

@Component({
    selector: "textbox-list",
    templateUrl: "./textbox-list.component.html"
})
export class TextboxListComponent implements OnInit, OnChanges {
    @Input() allowAdd: boolean = false;
    @Input() values: string[];
    @Output() changes = new EventEmitter<string[]>();
    
    options: {
        standalone: true,
        updateOn: "blur"
    };
    state: string[];
    
    constructor(){
    }
    
    handleChange(index: number, $event: any) {
        this.state[index] = $event;
        this.changes.emit(this.state);
    }

    remove(index: number): void {
        this.state.splice(index, 1);
        this.changes.emit(this.state);
    }
    ngOnInit(): void {
        this.state = this.values;
    }
    ngOnChanges(): void {
    }
    trackByFn(index: any, item: any) {
        return index;
    }

    add() {
        if(!this.state){
            this.state = [];
        }
        this.state.push('');
    }
}
