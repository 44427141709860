import {Component, EventEmitter, OnInit, Output, TemplateRef} from '@angular/core';
import {BsModalService, BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';
import {UntypedFormBuilder,UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {MifidService} from "./mifid-accounts.service";
import * as _ from "lodash";
import {MifidAccount, FidessaAccount, AccountMonikers} from "./accounts";
import {CheckboxItem} from "../../Widget/CheckboxList/checkbox-item";
import {TypeaheadMatch} from "ngx-bootstrap/typeahead";
import {ConfirmModalComponent} from "../../Widget/ConfirmModal/confirm-modal.component";
@Component({
    selector: 'app-admin-mifid-account-form',
    templateUrl: "./mifid-account-form.component.html",
})
export class MifidAccountFormComponent implements OnInit {
    @Output() dataUpdated = new EventEmitter<boolean>();
    mifidAccount: MifidAccount;
    selected: MifidAccount;
    fidessaAccounts: AccountMonikers[];
    mifidAccountForm: UntypedFormGroup;
    formStatus: string = '';
    clientIdsList: CheckboxItem[];
    noResult: boolean = false;
    modalRef2: BsModalRef;

    constructor(public bsModalRef: BsModalRef,
                private mifidService: MifidService,
                private fb: UntypedFormBuilder,
                private toastr: ToastrService,
                private modalService: BsModalService) {}

    ngOnInit(): void {
        this.mifidAccountForm = this.fb.group({
            accountName: this.fb.control('', [
                Validators.required
            ]),
        });

        if(this.mifidAccount && this.mifidAccount.AccountId){
            this.formStatus = "clean";
            this.mifidAccount = _.cloneDeep(this.mifidAccount);
            this.mifidService.getFidessaAccounts()
                .subscribe(accounts => {
                    this.mifidAccountForm.reset();
                    const mifidAccountResult  = _.chain(accounts).filter(a => a.AccountId === this.mifidAccount.AccountId)
                        .groupBy(r => r.AccountId)
                        .map((values: FidessaAccount[], AccountId: string) => {
                            return {
                                AccountName: _.first(values).AccountName,
                                AccountId: Number(AccountId),
                                Monikers: _.orderBy(_.map(values, v => v.Moniker), m => m)
                            } as MifidAccount;
                        })
                        .value()[0] || null;
                    this.setSelectedAccount(mifidAccountResult);
                });
        } else {
            this.formStatus = "new-item";
            this.mifidAccount = new MifidAccount();
            this.mifidService.getMifidAccounts()
                .subscribe(mifidAccounts => {
                    this.mifidService.getFidessaAccounts()
                        .subscribe( (fidessaAccounts) => {
                            let mifidAccountIds = _.map(mifidAccounts, a => a.AccountId);

                            this.fidessaAccounts = _.chain(fidessaAccounts)
                                .filter(f => !_.includes(mifidAccountIds, f.AccountId))
                                .groupBy(r => r.AccountId)
                                .map((values: FidessaAccount[],  AccountId: string) => {
                                    return {
                                        AccountName: `${_.first(values).AccountName} (${_.first(values).AccountId})`,
                                        AccountId: Number(AccountId),
                                        Monikers: _.orderBy(_.map(values, v => v.Moniker), m => m)
                                    };
                                })
                                .value();
                        });
                });
        }
    }

    submit(): void {
        this.mifidAccount.DeliveryPreference = "Email";
        if(this.formStatus != "new-item"){
            this.mifidAccount.AccountName = this.mifidAccountForm.value.accountName;
            this.update();
        } else {
            this.create();
        }
    }

    create(): void {
        const success = () => {
            this.toastr.success(`Mifid account created`);
            this.dataUpdated.emit(true);
            this.bsModalRef.hide();
        };
        const error = (e) => {
            this.toastr.error(`There was an error creating the account ${this.mifidAccount.AccountName}`)
        };

        this.mifidService.createMifidAccount(this.mifidAccount)
            .subscribe(success, error);
    }

    update(): void {
        const success = () => {
            this.toastr.success(`Mifid account changes saved for ${this.mifidAccount.AccountName}`);
            this.dataUpdated.emit(true);
            this.bsModalRef.hide();
        };
        const error = (e) => {
            this.toastr.error(`There was an error saving changes to the account ${this.mifidAccount.AccountName}: ${e}`)
        };

        this.mifidService.updateMifidAccount(this.mifidAccount)
            .subscribe(success, error);
    }

    delete(): void {
        const config: ModalOptions<any> = { ignoreBackdropClick: true, backdrop: true, initialState: {
                message: `Are you sure you want to delete account "${this.mifidAccount.AccountName}"`,
            }};

        this.modalRef2 = this.modalService.show(ConfirmModalComponent, config);
        this.bsModalRef.hide();
        this.modalRef2.content.action
            .subscribe(value => {
                const success = () => {
                    this.toastr.success(`Mifid account deleted for ${this.mifidAccount.AccountName}`);
                    this.dataUpdated.emit(true);
                    this.bsModalRef.hide();
                };
                const error = (e) => {
                    this.toastr.error(`There was an error deleting account  ${this.mifidAccount.AccountName}: ${e}`)
                };
                this.mifidService.deleteMifidAccount(this.mifidAccount)
                    .subscribe(success, error);
            });
    }

    cancel(): void {
        this.bsModalRef.hide();
    }

    private setSelectedAccount(account: MifidAccount) {
        if(!account){
            return;
        }
        this.mifidAccount.AccountId = account.AccountId;
        this.clientIdsList = account.Monikers.map(m => { return {value: m, label: m} as CheckboxItem });
        if(this.formStatus === "new-item")
        {
            this.mifidAccount.Monikers = account.Monikers;
        }
        this.mifidAccountForm.patchValue({
            accountName: account.AccountName
        });
    }

    onToggle(event) {
        this.mifidAccount.Monikers = event;
    }

    onChanges(event){
        this.mifidAccount.EmailRecipients = event;
    }

    typeaheadNoResults(event: boolean): void {
        this.noResult = event;
    }

    onSelect(event: TypeaheadMatch): void {
        this.setSelectedAccount(event.item);
    }
}
