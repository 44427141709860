<div class="page-content no-scroll flex-vbox">

    <ng-template #bannerFormTemplate>
        <div class="flex-hbox ticker-header">
            <div class="flex"></div>
            <div class="flex">
                <div><label>Analyst:</label> {{tickerDetails.Analyst && tickerDetails.Analyst.LastName}}</div>
                <div><label>Trader:</label> {{tickerDetails.MarketMaker && tickerDetails.MarketMaker.LastName}}</div>
            </div>
            <div class="flex">
                <div><label>Industry:</label> {{tickerDetails.Industry}}</div>
                <div><label>Sector:</label> {{tickerDetails.Sector}}</div>
            </div>
            <div class="flex">
                <div><label>Rating:</label> {{tickerDetails.Rating}}</div>
            </div>
            <div class="flex">
                <app-backups-picker [formControl]="backups" class="pull-right"></app-backups-picker>
            </div>
        </div>
    </ng-template>
    
    <app-page-banner
            pagetitle="{{tickerDetails.CompanyName}} ({{tickerDetails.Name}})"
            [bannerFormTemplate]="bannerFormTemplate"
            faicon="fa-dot-circle-o"
            faiconcolor="maroon">
    </app-page-banner>
    
    <div class="ticker-detail flex">
        <div class="account-summary flex-vbox">
            <tabset class="flex-tabset">
                <tab>
                    <ng-template tabHeading>
                        <span style="padding-right: 5px">Account Summary</span>
                        <span class="fa fa-external-link" (click)="openTickerPopout()"></span>
                    </ng-template>
                    <app-ticker-account-summary class="flex flex-vbox" [ticker]="ticker" [backups]="backupIds"></app-ticker-account-summary>
                </tab>
            </tabset>
        </div>
        <div class="trade-volume flex-vbox">
            <tabset class="flex-tabset">
                <tab heading="Trade Volume">
                    <div class="flex" style="height: 100%; min-height: 0; min-width: 0;">
                        <app-ticker-volume-chart class="flex" [ticker]="ticker"></app-ticker-volume-chart>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="activities flex-vbox">
            <tabset class="flex-tabset">
                <tab heading="Activities">
                    <app-tab-activity-list 
                            class="flex flex-vbox"
                            [categories]="categories"
                            [dateRange]="defaultDateRange"
                            [ticker]="ticker"></app-tab-activity-list>
                </tab>
            </tabset>
        </div>
    </div>
</div>
