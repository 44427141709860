import {Component, Input} from "@angular/core";

@Component({
    selector: "app-loading",
    template: `
        <div class="flex flex-vbox" style="height: 100%; justify-content: center; align-items: center;">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw" style="color: #30426a;"></i>
            <span *ngIf="loadingText" style="margin-top: 5px;">{{ loadingText }}</span>
        </div>
    `
})
export class LoadingComponent {
    @Input()
    loadingText: string;
}