import {Component, Input} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {forkJoin} from "rxjs";
import {CheckboxItem} from "../../CheckboxList/checkbox-item";
import {ContactListsService} from "../../../Contact/ContactLists/contact-lists.service";
import {ContactService} from "../../../Shared/Services/contact.service";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../../../Contact/contact-route-paths";
import {UntypedFormBuilder, UntypedFormControl} from "@angular/forms";

@Component({
    selector: "app-contact-lists-popover",
    templateUrl: "./contact-lists-popover.component.html"
})
export class ContactListsPopoverComponent {
    @Input()
    contactId: number;

    options: CheckboxItem[];
    filteredOptions: CheckboxItem[];
    selected: number[];
    searchTerm: UntypedFormControl = this.fb.control('');

    constructor(
        private fb: UntypedFormBuilder,
        private contactListsService: ContactListsService,
        private contactService: ContactService,
        private toastrService: ToastrService,
        private router: Router,
    ) { }

    showLists(): void {
        forkJoin([
            this.contactListsService.getAllLists(),
            this.contactService.getListsForContact(this.contactId)
        ]).subscribe(([lists, contactListIds]) => {

            this.options = lists
                .filter(list => list.IsEditable)
                .map(list => {
                    let listName = list.Name;
                    if (list.Description) {
                        listName += " - " + list.Description;
                    }
                    return {
                        value: list.Id,
                        label: listName,
                        checked: contactListIds.includes(list.Id)
                    }
                });

            this.selected = contactListIds
                .filter(clId => this.options.map(o => o.value).includes(clId));

            this.filteredOptions = this.options;
            this.searchTerm.valueChanges.subscribe(
                (searchTerm) => {
                    this.filteredOptions = this.options.filter(list => list.label.toLowerCase().includes(searchTerm.toString().toLowerCase()));
                }
            );
        });
    }

    goToContactLists(): void {
        this.router.navigate([ContactRoutePaths.ContactLists]);
    }

    onChecked(contactListId: number) {
        this.contactListsService.addContactToList(contactListId, this.contactId)
            .subscribe(() => this.toastrService.success('Contact List Added'));
    }

    onUnchecked(contactListId: number) {
        this.contactListsService.removeContactFromList(contactListId, this.contactId)
            .subscribe(() => this.toastrService.success('Contact List Removed'));
    }
}
