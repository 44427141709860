import {Component} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BasePickerComponent, pickerProviders} from "../BasePicker/base-picker.component";
import {of} from "rxjs";
import {TierService} from "../../../Shared/Services/tier.service";

@Component({
    selector: "app-tier-picker",
    templateUrl: "../BasePicker/base-picker.component.html",
    providers: pickerProviders(TierPickerComponent)
})
export class TierPickerComponent extends BasePickerComponent<string> {

    constructor(fb: UntypedFormBuilder, private tierService: TierService){
        super(fb);

        this.name = "Tier(s)";
        this.placeHolder = "Tier(s)";
    }
    
    ngOnInit() {
        super.ngOnInit();
        
        this.items$ = of(this.tierService.getAllTiers());
    }
}
