import {Pipe, PipeTransform} from "@angular/core";
import {Contact, SimpleContact} from "../../../../Models/Contact";

@Pipe({
    name: 'presentationName'
})
export class PresentationNamePipe implements PipeTransform {
    transform(contact: Contact | SimpleContact, ...args): any {
        let name = "";

        if (contact) {
            if (contact.LastName) {
                name += contact.LastName.trim();
            }

            if (contact.FirstName) {
                if (name.length > 0) {
                    name += ", ";
                }

                name += contact.FirstName.trim();

                if (contact.Alias && contact.FirstName !== contact.Alias) {
                    name += ` (${contact.Alias})`;
                }
            }
        }

        return name;
    }
}