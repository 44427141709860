import {Component, EventEmitter, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {getGridDisplayWords,} from "../../Shared/ag-grid-options";
import {ContactService} from "../../Shared/Services/contact.service";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {BsModalService} from "ngx-bootstrap/modal";
import {combineLatest} from "rxjs";
import {ColDef, RowClassParams, RowClickedEvent} from "ag-grid-community";
import {EventService, StagingWebcastAttendee} from "../../Shared/Services/event.service";
import {debounceTime, map, startWith, switchMap, tap} from "rxjs/operators";
import * as _ from "lodash";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../../Models/User";
import {EventMeeting} from "../../../Components/Events/CalendarEvent";
import {WebcastAttendeeFormComponent} from "./webcast-attendee-form.component";
import {TextModalComponent} from "../../Widget/TextModal/text-modal.component";

@Component({
    selector: "app-tab-webcast-attendee-list",
    template: `
        <div class="flex flex-vbox">
            <div style="margin-bottom: 5px;">
                <form [formGroup]="attendeeSearchForm">
                    <div class="flex-hbox flex-gap">
                        <div [ngClass]="{'toggleErrors' : anyErrors}" *ngIf="anyErrors">
                            <div
                                class="btn btn-primary btn-sm btn-toggle"
                                btnCheckbox
                                formControlName="showUnmatchedAttendeesOnly"
                                tooltip="Show Errors"
                            >
                                <span class="fa fa-exclamation"></span>
                            </div>
                        </div>

                        <input
                            formControlName="searchTerm"
                            type="search"
                            placeholder="Search"
                            class="flex form-control input-sm"
                        />
                    </div>
                </form>
            </div>

            <ag-grid-angular
                style="width: 100%; height: 100%;"
                class="ag-theme-balham"
                [gridOptions]="gridOptions"
                [rowData]="rowData$ | async"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClick($event)"
                [rowClassRules]="rowClassRules"
            >
            </ag-grid-angular>
        </div>
    `,
    styleUrls: ["./tab-attendee-list.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TabWebcastAttendeeListComponent extends BaseGridComponent<WebcastAttendeeListRow> implements OnInit {

    @Input()
    eventId: number;

    @Input()
    attendeeDataUpdated = new EventEmitter<boolean>();

    @Input()
    webcastAttendeeDataUpdated = new EventEmitter<boolean>();

    user: User;

    anyErrors: boolean;

    attendeeSearchForm: UntypedFormGroup = this.fb.group({
        searchTerm: this.fb.control(""),
        showUnmatchedAttendeesOnly: this.fb.control(false)
    });

    columnDefs : ColDef[] = [
        {
            sort: "asc",
            sortIndex: 1,
            colId: "WebcastAttendeeName",
            headerName: "Attendee Name",
            headerTooltip: "Attendee Name",
            valueGetter: p => p.data.WebcastAttendeeLastName ? `${p.data.WebcastAttendeeLastName}, ${p.data.WebcastAttendeeFirstName}`: "",
            tooltipValueGetter: p => p.data.WebcastAttendeeLastName ? `${p.data.WebcastAttendeeLastName}, ${p.data.WebcastAttendeeFirstName}`: "",
            flex: 1,
        },
        {
            field: "CompanyName",
            headerName: "Company",
            headerTooltip: "Company",
            tooltipValueGetter: p => p.data.CompanyName,
            flex: 1,
        },
        {
            field: "Email",
            headerName: "Email",
            headerTooltip: "Email",
            tooltipValueGetter: p => p.data.Email,
            flex: 1,
        },
        {
            field: "ContactName",
            headerName: "Contact Name",
            headerTooltip: "Contact Name",
            tooltipField: "ContactName",
            flex: 1,
        },
        {
            field: "ContactAccountName",
            headerName: "Account",
            headerTooltip: "Account",
            tooltipField: "ContactAccountName",
            flex: 1,
        },
    ];

    rowClassRules = {
        'ignored-attendee': (params: RowClassParams<WebcastAttendeeListRow>) => {
            return params.data.IsIgnored;
        },
        'unmatched-attendee': (params: RowClassParams<WebcastAttendeeListRow>) => {
            return !params.data.ContactId;
        },
    };

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: BsModalService,
        private eventService: EventService,
        private contactService: ContactService,
        private userService: UserService
    ) {
        super();
    }

    ngOnInit(): void {
        this.userService.getCurrentUser().subscribe(user => { this.user = user; });

        this.gridOptions.overlayNoRowsTemplate = `<span>No Webcast Attendees</span>`;
    }

    onGridReady(params) {
        super.onGridReady(params);
        this.setRowData();
    }

    setRowData() {

        let eventMeetings$ = this.eventService.getEventMeetings(this.eventId);

        let webcastAttendees$ = this.webcastAttendeeDataUpdated.pipe(
            startWith(true),
            tap(() => this.gridApi.showLoadingOverlay()),
            switchMap(() => this.eventService.getStagingWebcastAttendees(this.eventId)),
        );

        let unfilteredWebcastAttendeeRows = combineLatest([webcastAttendees$, eventMeetings$]).pipe(
            tap(() => this.gridApi.showLoadingOverlay()),
            map(([webcastAttendees, eventMeetings]) => {
                return this.mapWebcastAttendeeListRows(
                    webcastAttendees,
                    eventMeetings
                );
            })
        );

        let formUpdates$ = this.attendeeSearchForm.valueChanges.pipe(
            startWith(this.attendeeSearchForm.getRawValue()),
            debounceTime(200)
        );

        this.rowData$ = combineLatest([
            unfilteredWebcastAttendeeRows,
            formUpdates$
        ]).pipe(
            tap(([webcastAttendees, searchForm]) => {
                this.anyErrors = webcastAttendees.some(w => !w.ContactId);
            }),
            map(([webcastAttendees, searchForm]) => {
                return this.filterWebcastAttendeeListRows(
                    webcastAttendees,
                    searchForm.searchTerm,
                    searchForm.showUnmatchedAttendeesOnly
                );
            }
        ));
    }

    onRowClick(rowClickedEvent: RowClickedEvent<WebcastAttendeeListRow>) {
        if (rowClickedEvent.data.HasContactAccess){
            this.openWebcastAttendeeForm(rowClickedEvent.data.Id, rowClickedEvent.data.EventMeetingId);
        }
        else {
            let initialState = { message: 'The attendee has been matched to a contact that you do not have access to view.' };
            this.modalService.show(TextModalComponent, { initialState: initialState, animated: false, keyboard: false, backdrop: 'static', class: 'modal-sm' });
        }
    }

    getDisplayWords(): string {
        return getGridDisplayWords(this.gridApi);
    }

    filterWebcastAttendeeListRows(
        webcastAttendeeListRows: WebcastAttendeeListRow[],
        searchTerm: string,
        showUnmatchedAttendeesOnly: boolean
    ): WebcastAttendeeListRow[] {

        let nonBairdWebcastAttendeeListRows = webcastAttendeeListRows.filter(x => !x.Email?.toLowerCase().trim().endsWith("@rwbaird.com"))
        if (!showUnmatchedAttendeesOnly && (_.isNil(searchTerm) || _.isEmpty(searchTerm))) {
            return nonBairdWebcastAttendeeListRows;
        }

        const searchTermQuery = (row: WebcastAttendeeListRow): boolean => {
            const search = searchTerm.toLocaleLowerCase();
            if (row.Email?.toLowerCase().trim().endsWith("@rwbaird.com")) {
                return false;
            }

            if (
                _.startsWith(row.Email?.toLocaleLowerCase(), search) ||
                _.startsWith(row.CompanyName?.toLocaleLowerCase(), search) ||
                _.startsWith(row.ContactName.toLocaleLowerCase(), search) ||
                _.startsWith(row.WebcastAttendeeFirstName?.toLocaleLowerCase(), search) ||
                _.startsWith(row.WebcastAttendeeLastName?.toLocaleLowerCase(), search)
            ) {
                return true;
            }

            let fieldsToSearch = [
                row.Email,
                row.CompanyName,
                row.WebcastAttendeeFirstName,
                row.WebcastAttendeeLastName,
            ];

            if (row.ContactId) {
                fieldsToSearch.push(row.ContactName);
                fieldsToSearch.push(row.ContactAccountName);
            }

            const words = _.words(search, /[^,.\s;]+/g);
            const fieldsContains = _.map(
                fieldsToSearch,
                f => (f || "").toLowerCase().trim());

            return _.every(words, (w) => {
                return (_.some(fieldsContains, prop => {
                    return _.includes(prop, w);
                }))
            });
        };

        return nonBairdWebcastAttendeeListRows.filter(x =>
            searchTermQuery(x) &&
            (showUnmatchedAttendeesOnly && this.anyErrors ? !x.ContactId : true)
        );
    }

    openWebcastAttendeeForm(stagingWebcastAttendeeId: number, eventMeetingId: number): void {
        const initialState = {
            stagingWebcastAttendeeId: stagingWebcastAttendeeId,
            eventMeetingId: eventMeetingId,
        };

        let modalFormRef = this.modalService.show(WebcastAttendeeFormComponent, {
            initialState: initialState,
            animated: false,
            keyboard: false,
            backdrop: "static",
            class: "modal-lg"
        });

        modalFormRef.content.dataUpdated
            .subscribe(_ => {
                this.webcastAttendeeDataUpdated.emit(true);
                this.attendeeDataUpdated.emit(true);
            });
    }

     mapWebcastAttendeeListRows(
        webcastAttendees: StagingWebcastAttendee[],
        eventMeetings: EventMeeting[]
    ) : WebcastAttendeeListRow[] {

        return webcastAttendees.map(a => {
            const eventMeeting = eventMeetings.find(m => m.MeetingId === a.ConferenceMeetingId);

            return {
                Id: a.Id,
                WebcastId: a.WebcastId,
                CompanyName: a.Company,
                Email: a.Email,
                EventMeetingId: eventMeeting ? eventMeeting.MeetingId : null,
                MeetingDisplayName: eventMeeting ? eventMeeting.DisplayName : '',
                WebcastAttendeeFirstName: a.FirstName,
                WebcastAttendeeLastName: a.LastName,
                ContactId: a.ContactId,
                ContactName: a.ContactName,
                ContactAccountName: a.ContactAccountName,
                HasContactAccess: a.HasContactAccess,
                IsIgnored: a.IsIgnored,
            } as WebcastAttendeeListRow
        });
    }
}

interface WebcastAttendeeListRow {
    Id: number;
    WebcastId: string;
    CompanyName: string;
    Email: string;
    EventMeetingId: number;
    MeetingDisplayName: string;
    WebcastAttendeeFirstName: string;
    WebcastAttendeeLastName: string;
    ContactId: number;
    ContactName: string;
    ContactAccountName: string;
    HasContactAccess: boolean;
    IsIgnored: boolean;
}
