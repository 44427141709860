import {Injectable} from "@angular/core";
import * as _ from "lodash";

@Injectable({
    providedIn: 'root',
})

export class TierService {

    getAllTiers(): string[] {
        return []
            .concat(this.getInstitutionalTiers())
            .concat(this.getResearchTiers());
    }

    private getInstitutionalTiers(): string[] {
        return _.sortBy([ "1", "2", "3", "4", "5", "6", "7", "99"]).concat("XX");
    }

    private getResearchTiers(): string[] {
        return _.sortBy([ "CO", "EC", "IN", "ME", "SV" ]).concat(["R1", "R2", "R3", "R7"]);
    }

    getActivityChartTierGroups(): string[][] {
        return [
            [ "1", "2" ],
            [ "3" ],
            [ "4" ],
            [ "5", "6", "7", "99", "XX", "CO", "EC", "IN", "ME", "SV" ]
        ];
    }
}