import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular-legacy";

@Component({
    selector: 'app-checkbox-renderer',
    template: `
    <input
      type="checkbox"
      (click)="checkedHandler($event)"
      [checked]="checked"
    />
`,
})
export class CheckBoxRenderer implements ICellRendererAngularComp {
    params: any;
    checked: boolean = false;

    agInit(params: any): void {
        this.params = params;
        this.checked = params.value;
    }

    checkedHandler(event) {
        let checked = event.target.checked;
        let colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }

    refresh(params: any): boolean {
        this.checked = params.value;
        return true;
    }
}
