import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormBuilder} from "@angular/forms";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {CalendarEvent} from "../../../Components/Events/CalendarEvent";
import {EventService} from "../../Shared/Services/event.service";
import {CalendarEventAttendee} from "../../../Components/Events/CalendarEventAttendee";
import {BsModalService} from "ngx-bootstrap/modal";
import * as moment from "moment";
import {UserService} from "../../Shared/Services/user.service";
import {ConfigService} from "../../Shared/Services/config.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: "app-request-detail",
    templateUrl: "./request-detail.component.html",
    styleUrls: ['./request-detail.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequestDetailComponent extends BaseGridComponent<EventAttendee> implements OnInit {

    requestId: number;

    event: CalendarEvent;

    pageTitle: string;

    requestUrl: string;
    isCorpTravelUser: boolean = false;

    constructor(private fb: UntypedFormBuilder,
                private modalService: BsModalService,
                private userService: UserService,
                private configService: ConfigService,
                private eventService: EventService,
                private route: ActivatedRoute,
    ) {
        super();
    }

    ngOnInit(): void {
        this.requestId = +this.route.snapshot.queryParamMap.get('requestId') || +this.route.snapshot.queryParamMap.get('reqId');

        this.userService.getCurrentUser()
            .subscribe(user => {
                this.isCorpTravelUser = user.IsCorpTravelUser;

                this.eventService.getRequest(this.requestId)
                    .subscribe(e => this.loadEvent(e));
            });
    }

    loadEvent(event: CalendarEvent): void {
        this.event = event;

        this.requestUrl = `${this.configService.getCorpTravelUrl()}/RequestDetail.aspx?ID=${this.event.RequestId}`;

        this.pageTitle = (event && event.Name + ' ' + '(' + event.Tickers[0]+ ')' || '');
    }

    getEventDateRange(): string {
        return !this.event
            ? ''
            : `${moment(this.event.BeginDate).format('LL')} - ${moment(this.event.EndDate).format('LL')}`
    }
}

interface EventAttendee {
    ContactId: number;
    AccountId: number;
    Status: string;
    StatusCount: number;
    Details: CalendarEventAttendee[];
    Name: string;
    Account: string;
    PhoneNumber: string;
    Email: string;
    Broker: string;
    Tags: string[];
}
