import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef, RowClickedEvent} from "ag-grid-community";
import {ContactService} from "../../Shared/Services/contact.service";
import {ContactRecentHolding} from "../../../Models/ContactRecentHolding";
import {
    contactNameValueGetter,
    dateRenderer
} from "../../Shared/ag-grid-cell-renderers";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {ContactRoutePaths} from "../../Contact/contact-route-paths";

@Component({
    selector: "app-new-subscribers",
    templateUrl: "./new-subscribers.component.html"
})
export class NewSubscribersComponent extends BaseGridComponent<ContactRecentHolding> implements OnChanges {

    @Input()
    teamId: number;

    columnDefs: ColDef[] = [
        {
            field: "AccountName",
            headerName: "Account",
            flex: 2,
            valueGetter: params => `${params.data.AccountName} (${[params.data.AccountCity, params.data.AccountState, params.data.AccountCountry].filter(x => !!x).join(", ")})`
        },
        {field: "Contact", headerName: "Contact", flex: 1, valueGetter: contactNameValueGetter},
        {field: "Tier", width: 50},
        {field: "PersBroker", headerName: "Personal Broker", flex: 1},
        {field: "HoldingDate", headerName: "Date", width: 85, cellRenderer: dateRenderer}
    ];

    isLoading: boolean = true;

    constructor(
        private contactService: ContactService,
        private router: Router,
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.teamId) {
            this.isLoading = true;
            this.rowData$ = this.contactService.getMostRecentSubscribers(this.teamId).pipe(
                tap(() => this.isLoading = false)
            );
        }
    }

    onRowClicked(event: RowClickedEvent): void {
        if (event.data?.Id) {
            this.router.navigate([ContactRoutePaths.ContactDetail, event.data.Id]);
        }
    }
}
