<div class="page-content no-scroll flex-vbox">

    <app-page-banner 
            pagetitle="User Groups" 
            faicon="fa-gear"
            faiconcolor="#708090">
    </app-page-banner>
    
    <div class="user-groups-page">
        <div class="user-groups">
            <form [formGroup]="selectUserGroupForm">
                <label class="control-label" style="display: block;">
                    <span class="btn btn-xs btn-primary pull-right" (click)="onAddUserGroup()">Add User Group</span>
                    Groups List
                </label>
                <select class="form-control"
                        size="30"
                        style="font-family: Arial,Helvetica,sans-serif;"
                        formControlName="userGroup">
                    <option *ngFor="let userGroup of userGroups" [ngValue]="userGroup">
                        {{ userGroup.GroupName}}
                    </option>
                </select>
            </form>
        </div>
        <div class="user-group-form" *ngIf="selectedUserGroup">
            
            <form [formGroup]="userGroupForm">
                
                <div class="form-group">
                    <label for="name">Group Name</label>
                    <input id="name" type="text" formControlName="name" class="form-control" />
                </div>

                <div class="form-group">
                    <label for="description">Description</label>
                    <input id="description" type="text" formControlName="description" class="form-control" />
                </div>
                
                <div class="form-group">
                    <label>Rep Codes ({{selectedRepCodes.length}} Selected)</label>
                    <ng-select [items]="repCodes"
                               placeholder="Select Rep Codes"
                               appendTo="body"
                               [multiple]="true"
                               [selectOnTab]="true"
                               [closeOnSelect]="false"
                               [clearSearchOnAdd]="true"
                               [addTag]="addNewRepCode"
                               formControlName="repcodes">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [value]="item" [checked]="item$.selected" /> {{item}}
                        </ng-template>
                    </ng-select>
                </div>
                
                <div class="form-group">
                    <button type="button" 
                            class="btn btn-danger pull-right"
                            (click)="onDeleteUserGroup(selectedUserGroup)"
                            *ngIf="selectedUserGroup.GroupId">Delete</button>
                    <button class="btn btn-success" 
                            type="button" 
                            (click)="onSubmit()"
                            [disabled]="!userGroupForm.valid">Save</button>
                </div>
                
            </form>
        </div>
        <div class="user-group-repcodes" *ngIf="selectedUserGroup">
            <label class="control-label">Rep Codes</label>
            <div class="well well-sm" style="overflow: auto; height: 350px;">
                <div *ngFor="let repCode of selectedRepCodes">{{ repCode }}</div>
            </div>
        </div>
        <div class="user-group-users" *ngIf="selectedUserGroup">
            <label class="control-label">Users</label>
            <div class="well well-sm" style="overflow: auto; height: 350px;">
                <div *ngFor="let user of userGroupUsers">{{ user.LastName}}, {{ user.FirstName}} ({{ user.Id }})</div>
            </div>
        </div>
    </div>
    
</div>
