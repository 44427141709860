import {Component, Input, OnChanges, OnInit} from "@angular/core";
import {BaseGridComponent} from "../../Shared/base-grid.component";
import {ColDef, RowGroupOpenedEvent} from "ag-grid-community";
import {MonthlyCommission, MonthlyExpense} from "../../../Models/AccountMonthlyCommission";
import * as _ from "lodash";
import {currencyRenderer} from "../../Shared/ag-grid-cell-renderers";
import {UserService} from "../../Shared/Services/user.service";
import {User} from "../../Shared/Models/user";
import {UserFeatureName} from "../../../Models/User";
import * as moment from "moment";

@Component({
    selector: "app-account-commission-totals",
    templateUrl: "./account-commission-totals.component.html"
})
export class AccountCommissionTotalsComponent extends BaseGridComponent<Record<string, any>> implements OnChanges, OnInit {

    @Input()
    years: number[];

    @Input()
    commissions: MonthlyCommission[];

    @Input()
    pnl: MonthlyCommission[];

    @Input()
    expenses: MonthlyExpense[];

    @Input()
    categoryGroups: Record<string, string>;
    
    @Input()
    yearsRange: number; 

    columnDefs: ColDef[];
    
    grossCommissionsOpen: boolean = true;
    expensesOpen: boolean = false;

    constructor(private userService: UserService) {
        super();
    }

    ngOnInit(): void {
        this.gridOptions.groupDisplayType = "custom";
        this.gridOptions.rowClass = "groupable-row-grey";

        this.defaultColDef.sortable = false;
        this.defaultColDef.cellRendererParams = {
            suppressCount: true
        };
        
        if(this.yearsRange) {
            this.years = this.years.slice(-this.yearsRange);
        }

        this.columnDefs = [
            {
                field: "parentCategory",
                hide: true,
                rowGroup: true,
            },
            {
                field: "sortOrder",
                aggFunc: 'min',
                hide: true,
            },
            {
                field: "category",
                headerName: "",
                showRowGroup: true,
                cellRenderer: 'agGroupCellRenderer',
                width: 165,
            },
            ...this.years.map(year => {
                return {
                    field: year.toString(),
                    headerName: year === moment().year() ? `${year.toString()} YTD` : year.toString(),
                    valueFormatter: params => params.value ?? 0,
                    cellRenderer: currencyRenderer,
                    aggFunc: 'sum',
                    type: "rightAligned",
                    flex: 1,
                };
            })
        ];
    }
    
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        
        this.gridColumnApi.applyColumnState({
            state: [{ colId: 'sortOrder', sort: 'asc' }],
        });
    }
    
    ngOnChanges(): void {
        this.gridOptions.isGroupOpenByDefault = params => (params.rowNode.key === "Gross Commissions" && this.grossCommissionsOpen) 
            || (params.rowNode.key === "Expenses" && this.expensesOpen);
        this.userService.getCurrentUser().subscribe(user => this.setRowData(user));
    }
    
    onRowGroupOpenedClosed(event: RowGroupOpenedEvent): void {
        if (event.node.key === "Gross Commissions") {
            this.grossCommissionsOpen = event.expanded;
        } else if (event.node.key === "Expenses") {
            this.expensesOpen = event.expanded;
        }
    }

    private setRowData(user: User): void {
        const hasCommissionAccountNetFeature = user.Features.includes(UserFeatureName.CommissionAccountNet);
        const commissionsByCategory = _.groupBy(this.commissions, c => c.Category);
        const categoryRecords = _.chain(this.categoryGroups)
            .entries()
            .map(([code, description]) => this.getTotalsRecordByYear(description, "Gross Commissions", commissionsByCategory[code], c => c.Commission, 1))
            .value();

        const pnlRecord: Record<string, any> = this.getTotalsRecordByYear("PnL", null, this.pnl, c => c.Commission, 2);
        const bidsRecord = this.getTotalsRecordByYear("BIDS", null, this.expenses.filter(e => e.Type === "BIDS"), e => e.Amount, 3);

        const netCommissionRecord: Record<string, any> = {
            category: "Net Commission",
            sortOrder: 4,
        };
        _.chain(this.years)
            .forEach(year => {
                netCommissionRecord[year] = _.sum([
                    ...categoryRecords.map(cc => cc[year] ?? 0),
                    pnlRecord[year] ?? 0,
                    bidsRecord[year] ?? 0
                ])
            })
            .value();

        this.rowData = [
            ...categoryRecords,
            pnlRecord,
            bidsRecord,
            netCommissionRecord
        ];
        if (hasCommissionAccountNetFeature) {
            const expenseRecords = hasCommissionAccountNetFeature ? _.chain(this.expenses)
                    .filter(e => e.Type !== "BIDS")
                    .groupBy(e => e.Type)
                    .entries()
                    .map(([expenseType, expenses]) => this.getTotalsRecordByYear(expenseType, "Expenses", expenses, e => e.Amount, 5))
                    .value()
                : [];

            let netAllRecord: Record<string, any> = {category: "Net All", sortOrder: 6};
                _.chain(this.years)
                    .forEach(year => {
                        netAllRecord[year] = _.sum([
                            ...categoryRecords.map(cr => cr[year] ?? 0),
                            ...expenseRecords.map(er => er[year] ?? 0),
                            pnlRecord[year] ?? 0,
                            bidsRecord[year] ?? 0
                        ])
                    })
                    .value();
                
              this.rowData.push(...expenseRecords, netAllRecord);  
        }
    }
    
    private getTotalsRecordByYear<T>(categoryName: string, parentCategoryName: string, collection: T[], amountCb: (record: T) => number, sortOrder: number): Record<string, any> {
        const record: Record<string, any> = {
            category: categoryName,
            sortOrder: sortOrder,
        };
        if (parentCategoryName) {
            record.parentCategory = parentCategoryName;
        }
        if (collection?.length > 0) {
            _.chain(collection)
                .groupBy(c => c["Year"])
                .entries()
                .forEach(([year, expenses]) => {
                    record[year] = _.chain(expenses)
                        .map(c => amountCb(c))
                        .sum()
                        .round(0)
                        .value();
                })
                .value();
        }
        return record;
    }
}
