import {Component, Input, OnInit, ViewEncapsulation} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import type {FieldSchema, QuerySchema} from "../../Shared/Services/query.service";
import * as _ from "lodash";
import {Observable, of} from "rxjs";
import {InvestorTypes} from "../../Account/AccountProfile/investor-types";
import {InvestorStrategies} from "../../Account/AccountProfile/investor-strategies";
import {DealPlayer} from "../../Shared/Models/deal-player";
import {Orientation} from "../../Shared/Models/orientation";
import {InvestorStyles} from "../../Shared/Models/investor-styles";

@Component({
    selector: "app-query-criteria",
    template: `
        <div [formGroup]="criteriaFormGroup">
            <select formControlName="object" class="form-control input-sm">
                <option *ngFor="let objectName of objectNames" [value]="objectName">{{ objectName }}</option>
            </select>

            <select formControlName="field" class="form-control input-sm">
                <option *ngFor="let fieldName of fieldNames" [value]="fieldName">{{ fieldName }}</option>
            </select>

            <select formControlName="operation" class="form-control input-sm" [disabled]="operations.length === 0">
                <option *ngFor="let operation of operations" [value]="operation">{{ operation }}</option>
            </select>

            <input type="text" formControlName="value" class="form-control input-sm" *ngIf="fieldType === 'String'"/>
            <input type="date" formControlName="value" class="form-control input-sm"
                   *ngIf="fieldType === 'Date' && operation !== 'between'"/>
            <app-date-between-input formControlName="values" class="picker"
                                    *ngIf="fieldType === 'Date' && operation === 'between'"></app-date-between-input>

            <select class="form-control input-sm" formControlName="value" *ngIf="fieldType === 'Boolean'">
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>
            <select class="form-control input-sm" formControlName="value" *ngIf="fieldType === 'NullableBoolean'">
                <option value="">(empty)</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </select>

            <app-activity-status-picker formControlName="values" class="picker"
                                        *ngIf="fieldType === 'ActivityStatus[]'"></app-activity-status-picker>
            <app-activity-sub-category-picker formControlName="values" class="picker"
                                              *ngIf="fieldType === 'ActivitySubCategory[]'"></app-activity-sub-category-picker>
            <app-analyst-picker formControlName="values" class="picker"
                                *ngIf="fieldType === 'Analyst[]'"></app-analyst-picker>
            <app-string-picker formControlName="values" [items$]="yesNoOptions$"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'YesNo[]'"></app-string-picker>
            <app-activity-category-picker formControlName="values" class="picker"
                                          *ngIf="fieldType === 'Category[]'"></app-activity-category-picker>
            <app-role-picker formControlName="values" class="picker"
                             *ngIf="fieldType === 'ContactRole[]'"></app-role-picker>
            <app-string-picker formControlName="values" [items$]="dealPlayerValues$" [name]="'Deal Player(s)'"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'DealPlayer[]'"></app-string-picker>
            <app-string-picker formControlName="values" [items$]="departmentValues$" [name]="'Department(s)'"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'Department[]'"></app-string-picker>
            <app-holding-category-picker formControlName="values" class="picker"
                                         *ngIf="fieldType === 'HoldingCategory[]'"></app-holding-category-picker>
            <app-string-picker formControlName="values" [items$]="investorStrategyValues$"
                               [name]="'Investor Strategy(s)'" showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'InvestorStrategy[]'"></app-string-picker>
            <app-string-picker formControlName="values" [items$]="investorStyleValues$" [name]="'Investor Style(s)'"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'InvestorStyle[]'"></app-string-picker>
            <app-string-picker formControlName="values" [items$]="investorTypeValues$" [name]="'Investor Type(s)'"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'InvestorType[]'"></app-string-picker>
            <app-market-picker formControlName="values" class="picker"
                               *ngIf="fieldType === 'Market[]'"></app-market-picker>
            <app-activity-meeting-type-picker formControlName="values" class="picker"
                                              *ngIf="fieldType === 'MeetingType[]'"></app-activity-meeting-type-picker>
            <app-string-picker formControlName="values" [items$]="orientationValues$" [name]="'Orientation(s)'"
                               showTagCountLabel="false" class="picker"
                               *ngIf="fieldType === 'Orientation[]'"></app-string-picker>
            <app-region-picker formControlName="values" class="picker"
                               *ngIf="fieldType === 'Region[]'"></app-region-picker>
            <app-specialty-mailing-code-picker formControlName="values" class="picker"
                                               *ngIf="fieldType === 'SpecialtyMailingCode[]'"></app-specialty-mailing-code-picker>
            <app-stock-call-category-picker formControlName="values" class="picker"
                                            *ngIf="fieldType === 'StockCallCategory[]'"></app-stock-call-category-picker>
            <app-string-array-input formControlName="values" class="picker"
                                    *ngIf="fieldType === 'String[]'"></app-string-array-input>
            <app-team-picker formControlName="values" class="picker" *ngIf="fieldType === 'Team[]'"
                             [includeInActive]="true"></app-team-picker>
            <app-string-array-input formControlName="values" class="picker"
                                    *ngIf="fieldType === 'Ticker[]'"></app-string-array-input>
            <app-tier-picker formControlName="values" class="picker" *ngIf="fieldType === 'Tier[]'"></app-tier-picker>
            <app-multi-user-select formControlName="values" class="picker"
                                   *ngIf="fieldType === 'User[]'"></app-multi-user-select>
            <app-meeting-meeting-type-picker formControlName="values" class="picker"
                                             *ngIf="fieldType === 'MeetingMeetingType[]'"></app-meeting-meeting-type-picker>
            <app-meeting-context-picker formControlName="values" class="picker"
                                        *ngIf="fieldType === 'MeetingContext[]'"></app-meeting-context-picker>
            <app-instisel-sector-picker formControlName="values" class="picker"
                                        *ngIf="fieldType === 'InstiselSector[]'"></app-instisel-sector-picker>
        </div>
    `,
    styleUrls: ["./query-criteria.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class QueryCriteriaComponent implements OnInit {

    @Input()
    criteriaFormGroup: UntypedFormGroup;

    @Input()
    schema: QuerySchema;

    fieldType: string;
    operation: string;

    objectNames: string[] = [];
    fieldNames: string[] = [];
    fieldMultipleValues: boolean = false;
    operations: string[] = [];

    departmentValues$ = of(["IER","PWM"]);
    yesNoOptions$ = of(["Y","N"]);
    dealPlayerValues$: Observable<string[]> = of(DealPlayer);
    orientationValues$: Observable<string[]> = of(Orientation);
    investorTypeValues$: Observable<string[]> = of(InvestorTypes);
    investorStrategyValues$: Observable<string[]> = of(InvestorStrategies);
    investorStyleValues$: Observable<string[]> = of(InvestorStyles);

    ngOnInit(): void {

        this.criteriaFormGroup.controls["object"].valueChanges
            .subscribe(value => {
                this.fieldNames = this.getFieldsForObject(value);
                let newFieldName = _.includes(this.fieldNames, this.criteriaFormGroup.controls["field"].value) ?
                    this.criteriaFormGroup.controls["field"].value :
                    this.fieldNames[0];
                this.criteriaFormGroup.controls["field"].patchValue(newFieldName);
            });

        this.criteriaFormGroup.controls["field"].valueChanges
            .subscribe(value => {
                let fieldSchema = this.getFieldSchema(this.criteriaFormGroup.controls["object"].value, value);
                this.operations = fieldSchema.Operations;
                this.fieldMultipleValues = fieldSchema.Type.indexOf("[]") > -1;
                this.fieldType = fieldSchema.Type;
                let newOperationName = _.includes(this.operations, this.criteriaFormGroup.controls["operation"].value) ?
                    this.criteriaFormGroup.controls["operation"].value :
                    this.operations[0];
                this.criteriaFormGroup.controls["operation"].patchValue(newOperationName);
                if (this.fieldType === 'Boolean') {
                    this.criteriaFormGroup.controls["value"].patchValue('true');
                } else {
                    this.criteriaFormGroup.controls["value"].patchValue('');
                }
                this.criteriaFormGroup.controls["values"].patchValue([]);
            });

        this.criteriaFormGroup.controls["operation"].valueChanges
            .subscribe(value => {
                this.operation = value;
            });

        this.objectNames = _.keys(this.schema);

        if (this.criteriaFormGroup.controls["object"].value) {
            this.fieldNames = this.getFieldsForObject(this.criteriaFormGroup.controls["object"].value);
            let fieldSchema = this.getFieldSchema(this.criteriaFormGroup.controls["object"].value, this.criteriaFormGroup.controls["field"].value);
            this.fieldType = fieldSchema.Type;
            this.operation = this.criteriaFormGroup.controls["operation"].value;
            this.operations = fieldSchema.Operations;
            this.fieldMultipleValues = fieldSchema.Type.indexOf("[]") > -1;
        } else {
            this.criteriaFormGroup.patchValue({
                object: this.objectNames[0]
            });
        }
    }

    getObjectNames(): string[] {
        return _.keys(this.schema);
    }

    getFieldsForObject(objectName: string): string[] {
        return _.chain(this.schema[objectName].Fields)
            .filter(f => f.Operations.length > 0)
            .filter(f => f.Type)
            .map(f => f.Name)
            .value();
    }

    getFieldSchema(objectName: string, fieldName: string): FieldSchema {
        return _.chain(this.schema[objectName].Fields)
            .filter(f => f.Name == fieldName)
            .value()[0];
    }
}
