import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { CommissionService } from "../../Shared/Services/commission.service";
import { BaseGridComponent } from "../../Shared/base-grid.component";
import { debounceTime, map, startWith, tap } from "rxjs/operators";
import { combineLatest, Observable, of } from "rxjs";
import { currencyRenderer } from "../../Shared/ag-grid-cell-renderers";
import * as _ from "lodash";
import { AccountMonthlyCommission, MonthlyCommission } from "../../../Models/AccountMonthlyCommission";
import { ReportService } from "../../Shared/Services/report.service";
import * as moment from "moment";
import { UserService } from "../../Shared/Services/user.service";
import { RoleName } from "../../../Models/Role";
import { nullableValueComparator } from "../../Shared/ag-grid-cell-comparators";
import { annualizeAccountMonthlyCommissions } from "../../../Helpers/AnnualizationHelper";
import { CellClassParams } from "ag-grid-community";

@Component({
    selector: "app-reports-account-summary-by-year",
    templateUrl: "./account-summary-by-year.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AccountSummaryByYearComponent extends BaseGridComponent<AccountSummaryByYearRow> implements OnInit {

    groupingIsCollapsed: boolean = true;
    sourceDataFromDefaultYearSelectionOnly: boolean = true;

    columnDefs = [];
    baseColumnDefs = [];
    autoGroupColumnDef = {}
    pinnedTopRowData = [];

    defaultYears: number[] = [...Array(5).keys()].map(n => (moment().year() - (4 - n)));
    selectableYears: number[] = [...Array(10).keys()].map(n => (moment().year() - (9 - n)));
    selectableYears$: Observable<number[]>;

    annualizedAccountSummaryByYearReportRows: AccountSummaryByYearRow[] = [];

    groupByParentColDefs = [
        {
            field: 'ParentName',
            sort: 'asc',
            aggFunc: this.firstIfAllTheSameAggFunc,
            rowGroup: true,
            rowGroupIndex: 0,
            hide: true,
        },
    ]

    nonGroupByParentColDefs = [
        {
            field: 'ParentName',
            sort: 'asc',
            aggFunc: this.firstIfAllTheSameAggFunc,
            rowGroup: true,
            rowGroupIndex: 0,
            hide: true,
        },
        {
            field: 'Name',
            tooltipValueGetter: (params) => params.data.Name ? params.data.Name + " (" + params.data.ComdolId + ")" : '',
            valueGetter: (params) => params.data.Name ? params.data.Name + " (" + params.data.ComdolId + ")" : '',
            sort: 'asc',
            rowGroup: true,
            rowGroupIndex: 1,
            hide: true,
        },
        {
            field: 'Location',
            flex: 1,
            aggFunc: this.firstIfAllTheSameAggFunc,
            valueFormatter: _.bind(this.renderBasicInfo, this),
        },
        {
            field: 'Broker',
            flex: 1,
            aggFunc: this.firstIfAllTheSameAggFunc,
            valueFormatter: _.bind(this.renderBasicInfo, this),
        },
        {
            field: 'Trader',
            flex: 1,
            aggFunc: this.firstIfAllTheSameAggFunc,
            valueFormatter: _.bind(this.renderBasicInfo, this),
        },
        {
            field: 'Tier',
            flex: 1,
            aggFunc: this.firstIfAllTheSameAggFunc,
            valueFormatter: _.bind(this.renderBasicInfo, this),
        },
    ];

    latestDate: string;

    filterForm = this.fb.group({
        my: this.fb.control(false),
        annualize: this.fb.control(false),
        groupByParent: this.fb.control(false),
        searchTerm: this.fb.control(''),
        categories: this.fb.control(['P', 'E', 'O', 'C']),
        grouping: this.fb.control(''),
        years: this.fb.control(this.defaultYears),
    });

    constructor(
        private fb: UntypedFormBuilder,
        private commissionService: CommissionService,
        private reportService: ReportService,
        private userService: UserService,
    ) {
        super();
    }

    ngOnInit(): void {

        this.gridOptions.rememberGroupStateWhenNewData =  true;
        this.gridOptions.suppressScrollOnNewData = true;

        this.selectableYears$ = of(this.selectableYears.reverse());

        this.defaultColDef.tooltipValueGetter = (params) => params.value;

        combineLatest([
            this.userService.getCurrentUser(),
            this.reportService.getLatestReleaseDate()
        ]).subscribe(([user, releaseDate]) => {
            this.filterForm.get("my").patchValue(
                user.Role.Name === RoleName.Sales || user.Role.Name === RoleName.Traders,
                { emitEvent: false }
            );

            this.latestDate = moment(releaseDate).format('L');

            this.setRowData();

            this.filterForm.controls["years"].valueChanges.pipe(
                startWith(this.filterForm.controls["years"].value),
                debounceTime(500),
            ).subscribe((years) => {
                this.configureColumnsForSelectedYears(years);
            });
        });
    }

    configureColumnsForSelectedYears(years: number[]) {
        const sortedYears = years.sort();

        this.baseColumnDefs = [
            {
                field: 'Quarter',
                flex: 1,
                cellStyle: this.colorDetailRowCell,
            }
        ];

        const currentYear = moment().year();

        sortedYears.forEach((year) => {
            const yearNumber = this.selectableYears.indexOf(year) + 1;

            this.baseColumnDefs.push({
                field: `Year${yearNumber}`,
                headerName: year === currentYear ? `${year.toString()} YTD` : year.toString(),
                flex: 1,
                cellRenderer: currencyRenderer,
                tooltipValueGetter: currencyRenderer,
                aggFunc: 'sum',
                type: 'numericColumn',
                comparator: this.groupOnlySortComparator,
                cellStyle: this.colorDetailRowCell,
            });
        });

        this.configureColumnDefsForGroupByParent(this.filterForm.get("groupByParent").value);
    }

    toggleGroupCollapse(): void {
        this.groupingIsCollapsed = !this.groupingIsCollapsed;

        if (this.groupingIsCollapsed) {
            this.gridApi?.collapseAll();
        } else {
            this.gridApi?.expandAll();
        }
    }

    groupOnlySortComparator(valueA, valueB, nodeA, nodeB) {
        if (nodeA.group) {
            return nullableValueComparator(valueA, valueB);
        }
        return 0;
    };

    colorDetailRowCell(params: CellClassParams) {
        if (!params.node.hasChildren() && !params.node.rowPinned) {
            return { backgroundColor: '#d9edf7' };
        }
    }

    splitAccountCommissionByQuarterAndMapToAccountSummaryByYearReportRow(amc: AccountMonthlyCommission): AccountSummaryByYearRow[] {
        let quarterSplitAccountSummaryByYearReportRows: AccountSummaryByYearRow[] = [];

        const location = [amc.City, amc.State, amc.Country].filter(s => s && s.length > 0).join(', ');
        const parentName = amc.ParentName || amc.Name;
        const broker = amc.PrimaryBroker || 'No Broker';
        const trader = amc.PrimaryTrader || 'No Trader';
        const tier = amc.Tier || 'No Tier';
        const my = amc.Tags && amc.Tags.indexOf("My") > -1 || false;

        for (let quarter = 1; quarter <= 4; quarter++) {

            let quarterCommissions = amc.MonthlyCommissions.filter(x => x.Quarter === quarter);

            let quarterAccountSummaryByYearReportRow = {
                Name: amc.Name,
                ComdolId: amc.ComdolId,
                ParentName: parentName,
                Location: location,
                Broker: broker,
                Trader: trader,
                Tier: tier,
                AllCommissions: quarterCommissions,
                Quarter: `Q${quarter}`,
                My: my,
                Year1: 0,
                Year2: 0,
                Year3: 0,
                Year4: 0,
                Year5: 0,
                Year6: 0,
                Year7: 0,
                Year8: 0,
                Year9: 0,
                Year10: 0,
            } as AccountSummaryByYearRow;

            quarterSplitAccountSummaryByYearReportRows.push(quarterAccountSummaryByYearReportRow);
        }

        return quarterSplitAccountSummaryByYearReportRows;
    }

    getCommissions(): Observable<AccountSummaryByYearRow[]> {
        let yearChanges$ = this.filterForm.controls["years"].valueChanges.pipe(
            startWith(this.filterForm.controls["years"].value),
            debounceTime(500),
            tap(years => {
                let yearsEqualToOrSubsetOfDefaultYears = years.filter(y => this.defaultYears.indexOf(y) !== -1).length === years.length;

                if (this.sourceDataFromDefaultYearSelectionOnly && !yearsEqualToOrSubsetOfDefaultYears) {
                    this.sourceDataFromDefaultYearSelectionOnly = false;
                }
            }),
        );

        let allSelectableYearsCommissions$ = this.commissionService.getMonthlyCommissions(this.selectableYears).pipe(startWith([]));
        let defaultYearsCommissions$ = this.commissionService.getMonthlyCommissions(this.defaultYears);

        let commissionsToUse$ = combineLatest([allSelectableYearsCommissions$, defaultYearsCommissions$, yearChanges$]).pipe(
            map(([allSelectableYearsCommissions, defaultYearsCommissions]) => {
                return this.sourceDataFromDefaultYearSelectionOnly ? defaultYearsCommissions : allSelectableYearsCommissions;
            })
        );

        let accountCommissions$ = commissionsToUse$.pipe(
            map(amcs => {
                return amcs
                    .filter(amc =>
                        amc.Status !== "Inactive" ||
                        (amc.MonthlyCommissions && amc.MonthlyCommissions.length > 0)
                    )
            }),
            tap(amcs => {
                this.annualizedAccountSummaryByYearReportRows = amcs.length > 0
                    ? annualizeAccountMonthlyCommissions(amcs, moment().year(), moment())
                        .map(amc => this.splitAccountCommissionByQuarterAndMapToAccountSummaryByYearReportRow(amc))
                        .reduce((a, b) => a.concat(b))
                    : [];
            }),
            map(amcs => {
                return amcs.length > 0
                    ? amcs
                        .map(amc => this.splitAccountCommissionByQuarterAndMapToAccountSummaryByYearReportRow(amc))
                        .reduce((a, b) => a.concat(b))
                    : [];
            })
        );

        return accountCommissions$;
    }

    setRowData(): void {
        let formChanges$ =
            this.filterForm.valueChanges.pipe(
                startWith(this.filterForm.getRawValue()),
                tap(() => { this.gridApi?.showLoadingOverlay(); }),
                debounceTime(500)
            );

        let accountCommissions$ = this.getCommissions();

        this.rowData$ = combineLatest([formChanges$, accountCommissions$]).pipe(
            map(([formValues, accountCommissions]) => {

                this.configureColumnDefsForGroupByParent(formValues.groupByParent);

                let filteredAccountCommissions = accountCommissions;

                if (formValues.annualize) {
                    filteredAccountCommissions = this.annualizedAccountSummaryByYearReportRows;
                }

                if (formValues.groupByParent) {
                    filteredAccountCommissions = this.aggregateAccountSummaryByYearReportRowsByParentAccount(filteredAccountCommissions);
                }

                filteredAccountCommissions = filteredAccountCommissions.filter(ac =>
                    (
                        !formValues.searchTerm ||
                        ac.Name?.toLowerCase().indexOf(formValues.searchTerm?.toLowerCase()) > -1 ||
                        ac.ParentName?.toLowerCase().indexOf(formValues.searchTerm?.toLowerCase()) > -1
                    ) &&
                    (!formValues.my || ac.My)
                );

                filteredAccountCommissions.forEach(ac => {
                    ac.Year1 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[0], formValues.categories);
                    ac.Year2 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[1], formValues.categories);
                    ac.Year3 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[2], formValues.categories);
                    ac.Year4 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[3], formValues.categories);
                    ac.Year5 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[4], formValues.categories);
                    ac.Year6 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[5], formValues.categories);
                    ac.Year7 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[6], formValues.categories);
                    ac.Year8 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[7], formValues.categories);
                    ac.Year9 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[8], formValues.categories);
                    ac.Year10 = this.getYearCommissions(ac.AllCommissions, this.selectableYears[9], formValues.categories);
                });

                return filteredAccountCommissions;
            }),
            tap((data: AccountSummaryByYearRow[]) => {
                this.pinnedTopRowData = [{
                    Year1: _.sumBy(data, d => d.Year1),
                    Year2: _.sumBy(data, d => d.Year2),
                    Year3: _.sumBy(data, d => d.Year3),
                    Year4: _.sumBy(data, d => d.Year4),
                    Year5: _.sumBy(data, d => d.Year5),
                    Year6: _.sumBy(data, d => d.Year6),
                    Year7: _.sumBy(data, d => d.Year7),
                    Year8: _.sumBy(data, d => d.Year8),
                    Year9: _.sumBy(data, d => d.Year9),
                    Year10: _.sumBy(data, d => d.Year10),
                }];

                this.gridApi?.hideOverlay();
            }),
        );
    }

    aggregateAccountSummaryByYearReportRowsByParentAccount(accountSummaryByYearReportRows: AccountSummaryByYearRow[]): AccountSummaryByYearRow[] {
        let commissionsDictionary = {};

        accountSummaryByYearReportRows.forEach(ac => {
            let key = `${ac.ParentName || ac.Name}${ac.Quarter}`;

            let dictionaryEntry: AccountSummaryByYearRow = commissionsDictionary[key];

            if (!dictionaryEntry) {
                commissionsDictionary[key] = {
                    Name: null,
                    ComdolId: null,
                    ParentName: ac.ParentName,
                    Location: null,
                    Broker: null,
                    Trader: null,
                    Tier: null,
                    AllCommissions: ac.AllCommissions,
                    Quarter: ac.Quarter,
                    My: ac.My,
                    Year1: ac.Year1,
                    Year2: ac.Year2,
                    Year3: ac.Year3,
                    Year4: ac.Year4,
                    Year5: ac.Year5,
                    Year6: ac.Year6,
                    Year7: ac.Year7,
                    Year8: ac.Year8,
                    Year9: ac.Year9,
                    Year10: ac.Year10,
                } as AccountSummaryByYearRow;
            } else {
                commissionsDictionary[key].AllCommissions = dictionaryEntry.AllCommissions.concat(ac.AllCommissions);
            }
        });

        let groupedCommissions: AccountSummaryByYearRow[] = Object.entries(commissionsDictionary).map(([key, value]) => value as AccountSummaryByYearRow);

        return groupedCommissions ?? [];
    }

    configureColumnDefsForGroupByParent(groupByParent: boolean) {

        let groupFlexSize;

        if (groupByParent) {
            this.columnDefs = [
                ...this.groupByParentColDefs,
                ...this.baseColumnDefs
            ];
            groupFlexSize = 7;
        } else {
            this.columnDefs = [
                ...this.nonGroupByParentColDefs,
                ...this.baseColumnDefs
            ];
            groupFlexSize = 3;
        }

        this.autoGroupColumnDef = {
            headerName: 'Account',
            flex: groupFlexSize,
            sort: 'asc',
            cellRendererParams: {
                suppressCount: true
            },
        };

        this.gridApi?.setColumnDefs(this.columnDefs);
    }

    firstIfAllTheSameAggFunc(params: any): any {
        return params.values.length > 0 && params.values.every((val, i, arr) => val === arr[0]) ? params.values[0] : null;
    }

    renderBasicInfo(params: any): string {
        if (!this.filterForm.get('groupByParent').value && params.node.level === 1) {
            return params.value;
        }
        return '';
    }

    getYearCommissions(commissions: MonthlyCommission[], year: number, categories: string[]): number {
        return commissions
            .filter(mc => mc.Year === year)
            .filter(mc => categories.indexOf(mc.Category) > -1)
            .map(mc => mc.Commission)
            .reduce((a, b) => a + b, 0);
    }
}

export interface AccountSummaryByYearRow {
    Name: string;
    ComdolId: string;
    ParentName: string;
    Location: string;
    Broker: string;
    Trader: string;
    Tier: string;
    Quarter: string;
    Year1: number;
    Year2: number;
    Year3: number;
    Year4: number;
    Year5: number;
    Year6: number;
    Year7: number;
    Year8: number;
    Year9: number;
    Year10: number;
    My: boolean;
    AllCommissions: MonthlyCommission[];
}
